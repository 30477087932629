import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoryFor } from 'src/app/helpers/enums/history/historyFor';

@Component({
  selector: 'app-client-location-history',
  templateUrl: './client-location-history.component.html',
  styleUrls: ['./client-location-history.component.scss']
})
export class ClientLocationHistoryComponent implements OnInit {
  entityId: string;
  moduleId: number;
   constructor(private route: ActivatedRoute, private router: Router) { }

               ngOnInit(): void {
                 this.moduleId = HistoryFor.ClientLocation;
                 this.route.parent.params.subscribe(param => this.entityId = param.locationId);
               }
}
