import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { StaffService } from 'src/app/services/staff.serrvice';

@Component({
  selector: 'app-case-updates-detail',
  templateUrl: './case-updates-detail.component.html',
  styleUrls: ['./case-updates-detail.component.css']
})
export class CaseUpdatesDetailComponent implements OnInit, OnDestroy {


  activeTab = 1;
  tabDetails = [];
  staffSubscription: Subscription;
  staffName: string;
  currentPath: string;
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  loadBreadCrumb = false;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private staffService: StaffService) {
  }



  ngOnInit(): void {
    this.tabDetails = [
      { id: 1, title: 'Overview', path: 'overview' },
      { id: 2, title: 'History', path: 'history' }
    ];
    this.staffSubscription = this.staffService.staffDetai$.subscribe((data) => {
      if (data === undefined) {
        return;
      }
      this.breadCrumbInputs = [{ key: 'id', value: [data?.id, data?.name] }];
      this.loadBreadCrumb = true;
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.activeTab = changeEvent.nextId;
  }

  // sticky money
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (document.getElementById('stickyoney')) {
      if (document.body.scrollTop > 132 ||
        document.documentElement.scrollTop > 132) {
        document.getElementById('stickyoney').classList.add('stick_me');
      } else {
        document.getElementById('stickyoney').classList.remove('stick_me');
      }
    }
  }
  componentAdded($evt): void {
    if ($evt.route) {
      this.currentPath = $evt.route.snapshot.routeConfig.path;
    }
    if (!$evt.route) {

      this.route.params.subscribe((params: Params) => {
        // Do something
        this.currentPath = params.id;
      });
    }
  }

  ngOnDestroy(): void {
    this.staffSubscription.unsubscribe();
  }

}
