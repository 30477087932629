import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CompanyPolicyFilterOptionsDetails } from 'src/app/helpers/constants/filter-and-search/company.policy.filter.options';
import { FilterInputModel } from 'src/app/models/shared/filters.model';

@Component({
  selector: 'app-task-list-filter',
  templateUrl: './task-list-filter.component.html',
  styleUrls: ['./task-list-filter.component.css']
})
export class TaskListFilterComponent implements OnInit {

  filterBy: FilterInputModel[] = [];
  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  onApplyFilter = new EventEmitter();

  @Output()
  resetFilters = new EventEmitter();


  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();


  constructor(){

  }

ngOnInit(): void {
  CompanyPolicyFilterOptionsDetails.forEach((x, y) => {
      this.filterBy.push({ name: x, id: y });
    });
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onResetFilters(): void {
    this.resetFilters.emit();
  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }

}
