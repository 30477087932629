export enum ClientSearchOptions {
    Name = 1,
    Address = 2,
    CaseManager = 3,
    Salesperson = 4,
    AccountCode = 5,
    Tag = 6,
}

export enum ClientFilterByOptions {
    None = 0,
    DeletedClients = 1,
    Salesperson = 2
}