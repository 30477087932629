<div [formGroup]="form">
    <table class="table table-striped border">
        <thead>
            <tr>
                <th scope="col">Contact Number</th>
                <th scope="col">Extension / Note</th>
                <th scope="col">Contact Type</th>
                <th></th>
            </tr>
        </thead>
        <tbody formArrayName="dynamicRows">
            <tr *ngFor="let itemrow of form.controls.dynamicRows['controls']; index as i; let l = last"
                [formGroupName]="i">
                <td>
                    <input type="text" class="form-control" appPhoneMask maxlength="14" formControlName="phoneNum"
                    (keypress)="_contactTele($event)"
                    [ngClass]="{
                        'is-invalid':
                          isSubmitted &&
                          form.controls.dynamicRows.controls[i].controls.phoneNum.errors &&
                            (form.controls.dynamicRows.controls[i].controls.phoneNum.errors.required ||
                            form.controls.dynamicRows.controls[i].controls.phoneNum.errors.minlength ||
                            form.controls.dynamicRows.controls[i].controls.phoneNum.errors.maxlength ||
                            this.form.controls.dynamicRows.controls[i].controls.phoneNum.errors.unique)
                      }" />
                    <input type="hidden" formControlName="id">
                </td>

                <td>
                    <input type="text" class="form-control" formControlName="extn" maxlength="25" placeholder=" Maximum 25 characters is required">

                </td>

                <td>
                    <select class="form-control" formControlName="phoneType" [ngClass]="{
                        'is-invalid':
                          isSubmitted &&
                          form.controls.dynamicRows.controls[i].controls.phoneType.errors &&
                          form.controls.dynamicRows.controls[i].controls.phoneType.errors.required
                      }">
                        <option [value]="contactType.id" *ngFor="let contactType of contactTypesEnum">{{contactType.value}}
                        </option>
                    </select>
                    {{itemrow.contactNos}}
                </td>
                <td>
                    <button  (click)="deleteRow(i)"
                        class=" border-0 btn btn-danger"><i class="fas fa-trash-alt"></i></button>
                </td>
                <td>
                    <div *ngIf="isSubmitted && form.controls.dynamicRows.controls[i].controls.phoneNum.errors"
                        class="text-danger w-100 d-block">
                        <div *ngIf="form.controls.dynamicRows.controls[i].controls.phoneNum.errors.required">
                            Contact number is required
                        </div>
                        <div *ngIf="form.controls.dynamicRows.controls[i].controls.phoneNum.errors.maxlength">
                            Only 14 digits are allowed
                        </div>
                        <div *ngIf="form.controls.dynamicRows.controls[i].controls.phoneNum.errors.minlength">
                            Minimum 14 digits are needed
                        </div>
                        <div *ngIf="this.form.controls.dynamicRows.controls[i].controls.phoneNum.errors.unique">
                            Duplicate numbers are not allowed
                        </div>
                    </div>
                    <div *ngIf="isSubmitted && form.controls.dynamicRows.controls[i].controls.phoneType.errors"
                        class="text-danger w-100 d-block">
                        <div *ngIf="form.controls.dynamicRows.controls[i].controls.phoneType.errors.required">
                           Contact type is required
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
    <!-- add new contact number button -->
    <div class="row">
        <div class="col-md-12">
            <button type="button" class="btn btn-primary" (click)="addNewRows()">Add Contact Number</button>
        </div>
    </div>
</div>
