import { Component, OnInit, ChangeDetectorRef, ViewChild, Renderer2 } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';


import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { ClientService } from 'src/app/services/client.service';
import { ClientDtColumnName } from 'src/app/helpers/constants/client/client.column.name';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';
import { HistoryFor } from 'src/app/helpers/enums/history/historyFor';

@Component({
  selector: 'app-contact-history',
  templateUrl: './contact-history.component.html',
  styleUrls: ['./contact-history.component.css']
})
export class ContactHistoryComponent implements OnInit {
  entityId: string;
  moduleId: number;
   constructor(private route: ActivatedRoute, private router: Router) { }

               ngOnInit(): void {
                 this.moduleId = HistoryFor.ClientContact;
                 this.route.parent.params.subscribe(param => this.entityId = param.contactId);
               }
}
