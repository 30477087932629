import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GoogleLoginProvider, SocialAuthService, SocialUser } from 'angularx-social-login';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { AuthenticateRequestModel } from 'src/app/models/authenticate/authenticate.request.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, OnDestroy {

  @BlockUI('blockui-frm-login') blockUI: NgBlockUI;
  returnUrl: string;
  user: SocialUser;
  subscriptions: Subscription[] = [];

  loginImage = 'assets/images/login-img.png';
  loginImage2 = 'assets/images/login-img2.png';
  logo = 'assets/images/Rakon_Logo.png';

  form: FormGroup;

  forgotPasswordForm: FormGroup;
  submitted = false;
  isforgotPasswordFormSubmitted = false;
  showForgotPasswordMessage = false;

  constructor(private router: Router,
              private route: ActivatedRoute,
              private tosterService: ToasterService,
              private authenticationService: AuthenticationService,
              private formBuilder: FormBuilder,
              private socialauthService: SocialAuthService) {

    this.route.queryParams.subscribe(params => {
      this.returnUrl = params.returnUrl;
    });
  }

  ngOnInit() {
    this.form = this.formBuilder.group({
      userName: ['', [
        Validators.required
      ]],
      password: ['', [
        Validators.required
      ]]
    });

    this.forgotPasswordForm = this.formBuilder.group({
          email: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]]
    });


    const socialauthSubscription = this.socialauthService.authState.subscribe((user) => {
      this.user = user;
      if (this.user != null) {
         this.blockUI.start();
         this.authenticationService.externalLogin(this.user)
          .subscribe((isLoggedIn: boolean) => {
            this.socialauthService.signOut();
            if (!isLoggedIn) {
              return;
            }

            if (this.returnUrl) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.router.navigate(['/']);
            }
            this.blockUI.stop();
          }, error => {
            this.tosterService.ProcessErrorResponse(error);
            this.blockUI.stop();
          });
      }
    });
    this.subscriptions.push(socialauthSubscription);
  }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid.
    if (this.form.invalid) {
      return;
    }

    this.blockUI.start();
    const authenticateRequestModel = new AuthenticateRequestModel();
    authenticateRequestModel.userName = this.form.value.userName;
    authenticateRequestModel.password = this.form.value.password;
    this.authenticationService.login(authenticateRequestModel).subscribe((isLoggedIn: boolean) => {
      if (!isLoggedIn) {
        return;
      }

      if (this.returnUrl) {
        this.router.navigate([this.returnUrl]);
      } else {
        this.router.navigate(['/']);
      }
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  onForgotPasswordSubmit(){
    this.isforgotPasswordFormSubmitted = true;

    // stop here if form is invalid.
    if (this.forgotPasswordForm.invalid) {
      return;
    }

    this.blockUI.start();
    this.authenticationService.forgotPassword(this.forgotPasswordForm.value.email).subscribe(() => {
      this.showForgotPasswordMessage = true;
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }
  signInWithGoogle(){
    this.socialauthService.signIn(GoogleLoginProvider.PROVIDER_ID);
  }

  private navigate() {
    if (this.returnUrl) {
      this.router.navigate([this.returnUrl]);
    } else {
      this.router.navigate(['']);
    }

  }
  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe());
  }
  // for smooth scroll to forgot and login
  scrollToElement($element): void {
    $element.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
  }
}
