export const SettingsBreadCrumb = {
  SETTINGS: 'Settings',

  // User and Roles

  USER_ACCESS_GROUPS: 'User Access Groups',
  NEW_USER_ACCESS_GROUPS: 'New User Access Group',
  EDIT_USER_ACCESS_GROUP: 'Edit User Access Group',
  USER_ROLES: 'User Roles',
  NEW_USER_ROLE: 'New User Role',
  EDIT_USER_ROLE: 'Edit User Role',

  // case types
  CASE_TYPE_LIST: 'Case Types',
  NEW_CASE_TYPE: 'New Case Type',
  EDIT_CASE_TYPE: 'Edit Case Type',

  // case region
  CASE_REGION_LIST: 'Case Regions',
  NEW_CASE_REGION: 'New Case Region',
  EDIT_CASE_REGION: 'Edit Case Region',

  // Case flags
  CASE_FLAG_LIST: 'Case Flags',
  NEW_CASE_FLAG: 'New Case Flag',
  EDIT_CASE_FLAG: 'Edit Case Flag',

  // Case Services
  CASE_SERVICE_LIST: 'Case Services',
  NEW_CASE_SERVICE: 'New Case Service',
  EDIT_CASE_SERVICE: 'Edit Case Service',

  // Case Assignment Types
  CASE_ASSIGNMENT_TYPE: 'Case Assignment Types',
  EDIT_CASE_ASSIGNMENT_TYPE: 'Edit Assignment Type',

  // CustomFields
  CUSTOM_FIELD_LIST: 'Custom Field List',
  EDIT_CUSTOM_FIELD: 'Edit Custom Field',
  NEW_CUSTOM_FIELD: 'New Custom Field',

  // Folder Template :
  FOLDER_TEMPLATE_lIST: 'Folder Template List',
  NEW_FOLDER_TEMPLATE: 'New Folder Template',
  EDIT_FOLDER_TEMPLATE: 'Edit Folder Template',

  // subject types
  SUBJECT_TYPE_LIST: 'Subject Type List',
  NEW_SUBJECT_TYPE: 'New Subject Type',
  EDIT_SUBJECT_TYPE: 'Edit Subject Type',

  // referral sources
  REFERRAL_SOURCE_LIST: 'Referral Type List',
  NEW_REFERRAL_SOURCE: 'New Referral Type',
  EDIT_REFERRAL_SOURCE: 'Edit Referral Type',
  // referral sources
  CASE_STATUS_LIST: 'Case Status List',
  NEW_CASE_STATUS: 'New Case Status',
  EDIT_CASE_STATUS: 'Edit Case Status',
  // case review
  CASE_REVIEWS_LIST: 'Case Review List',
  NEW_CASE_REVIEWS: 'New Review',
  EDIT_CASE_REVIEWS: 'Edit Review',

  // case review
  CASE_UPDATE_PRESET_LIST: 'Case Update Preset',
  NEW_CASE_UPDATE_PRESET: 'New Case Update Preset',
  EDIT_CASE_UPDATE_PRESET: 'Edit Case Update Preset',
  // setting
  EDIT_CASE_STATUS_TRIGGERS: 'Edit Case Status Trigger',
  // Document Template :
  DOCUMENT_TEMPLATE_lIST: 'Document Template List',
  NEW_DOCUMENT_TEMPLATE: 'New Document Template',
  EDIT_DOCUMENT_TEMPLATE: 'Edit Document Template',

  // Company Policy
  COMPANY_POLICY_LIST: 'Company Policies List',
  COMPANY_POLICY_DETAIL: 'Company Policy Detail',
  NEW_COMPANY_POLICY: 'New Policy',
  EDIT_COMPANY_POLICY: 'Edit Policy',
  COMPANY_POLICY_OVERVIEW: 'Overview',
  COMPANY_POLICY_HISTORY: 'History',


    // Company Policy Version
    NEW_COMPANY_POLICY_VERSION: 'New Policy Version',
    COMPANY_POLICY_VERSION_DETAIL: 'Company Policy Version Detail',
    EDIT_COMPANY_POLICY_VERSION: 'Edit Policy Version',

  // Upload Logo
  UPLOAD_LOGO_EDIT: 'Upload Logos',
  // user security settings
  USER_SECURITY_SETTINGS: 'User Security Settings',
  // company location
  NEW_COMPANY_LOCATION: 'New Location (Argus West, Inc.)',
  EDIT_COMPANY_LOCATION: 'Edit Location (Argus West, Inc.)',
  COMPANY_LOCATION_LIST: 'Company Locations List',
  // general global setting
  GENERAL_GLOBAL_SETTING: 'General Global Settings'
} as const;
