import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {
  private static readonly FORMAT = 'yyyy-MM-dd';
  constructor(private datePipe: DatePipe) {
  }
  transform(value: unknown, ...args: unknown[]): unknown {
    if (value === null || value === undefined) {
      return '';
    }

    return this.datePipe.transform(value, DateFormatPipe.FORMAT);
  }

}
