<main class="content py-0">
  
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto d-none d-sm-block mb-3">
                            <h3 class="page-title"><strong>New Expense Entry</strong> </h3>
                            <p>Create new expense entry here.</p>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <div class="row">
                                <div class="col-md-3 col-xl-3 col-xxl-3">
                                    <div class="card flex-fill w-100 mb-4">
                                        <div class="card-header rounded p-4 mob-px-4">
                                            <div class="user-left-column d-flex align-items-center">
                                                <div class="tab-icon-expense bg-pink rounded-circle text-center">
                                                    <i class="align-middle fas-icon fas fa-hourglass-half text-white"></i>
                                                </div>
                                                <div class="tab-icon-expense-text ml-4">
                                                    <h1 class="font-weight-bold mb-0">0.20 </h1>
                                                    <p class="mb-0">Hours Counted</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-xl-3 col-xxl-3">
                                    <div class="card flex-fill w-100 mb-4">
                                        <div class="card-header rounded p-4 mob-px-4">
                                            <div class="user-left-column d-flex align-items-center">
                                                <div class="tab-icon-expense bg-primary rounded-circle text-center">
                                                    <i class="align-middle fas-icon fas fa-stopwatch text-white"></i>
                                                </div>
                                                <div class="tab-icon-expense-text ml-4">
                                                    <h1 class="font-weight-bold mb-0">$ 5.70 </h1>
                                                    <p class="mb-0">Time Counted</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-xl-3 col-xxl-3">
                                    <div class="card flex-fill w-100 mb-4">
                                        <div class="card-header rounded p-4 mob-px-4">
                                            <div class="user-left-column d-flex align-items-center">
                                                <div class="tab-icon-expense bg-warning rounded-circle text-center">
                                                    <i class="align-middle fas-icon fas fa-file-invoice-dollar text-white"></i>
                                                </div>
                                                <div class="tab-icon-expense-text ml-4">
                                                    <h1 class="font-weight-bold mb-0">$ 4,575 </h1>
                                                    <p class="mb-0">Expenses Counted</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-3 col-xl-3 col-xxl-3">
                                    <div class="card flex-fill w-100 mb-4">
                                        <div class="card-header rounded p-4 mob-px-4">
                                            <div class="user-left-column d-flex align-items-center">
                                                <div class="tab-icon-expense bg-success rounded-circle text-center">
                                                    <i class="align-middle fas-icon fas fa-hourglass-half text-white"></i>
                                                </div>
                                                <div class="tab-icon-expense-text ml-4">
                                                    <h1 class="font-weight-bold mb-0">$ 6,784 </h1>
                                                    <p class="mb-0">Total Counted</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <form class="new-case-form">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="row">
                                                    <div class="w-100 d-flex align-items-center mb-3">
                                                        <div class="col-md-2">
                                                            <div class="form-group has-search">
                                                                <label for="role">Staff</label>
                                                                <input type="text" class="form-control" id="role" name="role" value="Namkedik Fredrick">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <div class="form-group has-search">
                                                                <label>Expense Date <sup class="text-danger">*</sup></label>
                                                               
                                                                    <input  class="form-control border-0 bg-transparent" type="date" >
                                                                    
                                                            </div>
                                                        </div>
                                                        <div class="col-md-2">
                                                            <div class="form-group has-search">
                                                                <label for="client">Activity</label>
                                                                <div class="input-group position-relative">
                                                                    <select id="search1">
                                                                        <option value="" selected>SHG457845</option>
                                                                        <option value="AD">Associates</option>
                                                                        <option value="BD">Bachelors</option>
                                                                    </select>
                                                                    <div class="input-group-append position-absolute append-btn">
                                                                        <button class="border-0 bg-transparent" type="button">
                                                                        <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-3">
                                                            <div class="form-group has-search">
                                                                <label for="client">Category</label>
                                                                <div class="input-group position-relative">
                                                                    <select id="search2">
                                                                        <option value="" selected>AOD Final Distribution</option>
                                                                        <option value="AD">Lomdon</option>
                                                                        <option value="BD">Europe</option>
                                                                    </select>
                                                                    <div class="input-group-append position-absolute append-btn">
                                                                        <button class="border-0 bg-transparent" type="button">
                                                                        <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <p class="detail-client-heading text-light-black mb-0">Case</p>
                                                        <h6 class="detail-client-title black-light-text font-weight-500">SMF457812 
                                                        <span class="subject-dropdown">
                                                            <a href="#" class="ml-2 btn text-orange dropdown-toggle border-0 dropdown-no-caret"><i class="align-middle fas fa-pen"></i> change</a>
                                                        </span></h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-body">
                                                <div class="user-header mb-4 pb-0 border-0">
                                                    <h4 class="user-title font-weight-bold mb-0">Time Items</h4>
                                                </div>
                                                <div class="case-table table-responsive">
                                                    <table id="myTable2"  class="table table-billing">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" class="border-0 bg-light">Code</th>
                                                                <th scope="col" class="border-0 bg-light">Item</th>
                                                                <th scope="col" class="border-0 bg-light">Notes </th>
                                                                <th scope="col" class="border-0 bg-light w-2">Hrs</th>
                                                                <th scope="col" class="border-0 bg-light w-6">Rate</th>
                                                                <th scope="col" class="border-0 bg-light text-right w-6">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="customtable">
                                                            <tr class="">
                                                                
                                                                <td class="align-middle border-0">
                                                                    <div class="form-group has-search">
                                                                        <div class="input-group position-relative">
                                                                            <select id="search6">
                                                                                <option value="" selected="">Alive & Well Checked</option>
                                                                                <option value="AD">Associates</option>
                                                                                <option value="BD">Bachelors</option>
                                                                            </select>
                                                                            <div class="input-group-append position-absolute append-btn">
                                                                                <button class="border-0 bg-transparent" type="button">
                                                                                
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle border-0">
                                                                    <div class="form-group has-search">
                                                                        <div class="input-group position-relative">
                                                                            <select id="search6">
                                                                                <option value="" selected="">Alive & Well Checked</option>
                                                                                <option value="AD">Associates</option>
                                                                                <option value="BD">Bachelors</option>
                                                                            </select>
                                                                            <div class="input-group-append position-absolute append-btn">
                                                                                <button class="border-0 bg-transparent" type="button">
                                                                                
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle border-0">
                                                                    <div class="form-group has-search">
                                                                        <input type="text" class="form-control valid" name="Product" value="Write note here...">
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle border-0">
                                                                    <div class="form-group has-search">
                                                                        <input type="text" class="form-control valid text-right" name="Product" value="05">
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle border-0">
                                                                    <div class="form-group has-search">
                                                                        <input type="text" class="form-control valid text-right" name="Product" value="45">
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle border-0">
                                                                    <div class="form-group has-search d-flex align-items-center">
                                                                        <input type="text" class="form-control valid w-6" name="Product" value="$84,000"> <i class="ml-2 fas fa-minus text-white bg-danger rounded-circle p-1 cursor-pointer"></i>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div class="col-12">
                                                        <a class="text-orange font-weight-500" href="#"><i class="mr-2 fas fa-plus-circle text-orange"></i>Add More Time</a>
                                                    </div>
                                                    <div class="col-3 float-right">
                                                        <ul class="list-unstyled mb-4">
                                                            <li class="d-flex justify-content-between py-3"><h4 class="font-weight-bold text-dark mb-0">Time Total</h4>
                                                            <h4 class="font-weight-bold text-orange mb-0">1083,00 USD</h4>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-body">
                                                <div class="user-header mb-4 pb-0 border-0">
                                                    <h4 class="user-title font-weight-bold mb-0">Expense Items</h4>
                                                </div>
                                                <div class="case-table table-responsive">
                                                    <table id="myTable2"  class="table table-billing">
                                                        <thead>
                                                            <tr>
                                                                <th scope="col" class="border-0 bg-light">Code</th>
                                                                <th scope="col" class="border-0 bg-light">Item</th>
                                                                <th scope="col" class="border-0 bg-light">Notes </th>
                                                                <th scope="col" class="border-0 bg-light w-2">Hrs</th>
                                                                <th scope="col" class="border-0 bg-light w-6">Rate</th>
                                                                <th scope="col" class="border-0 bg-light text-right w-6">Total</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody class="customtable">
                                                            <tr class="">
                                                                <td class="align-middle border-0">
                                                                    <div class="form-group has-search">
                                                                        <div class="input-group position-relative">
                                                                            <select id="search7">
                                                                                <option value="" selected="">Alive & Well Checked</option>
                                                                                <option value="AD">Associates</option>
                                                                                <option value="BD">Bachelors</option>
                                                                            </select>
                                                                            <div class="input-group-append position-absolute append-btn">
                                                                                <button class="border-0 bg-transparent" type="button">
                                                                                
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>

                                                                <td class="align-middle border-0">
                                                                    <div class="form-group has-search">
                                                                        <div class="input-group position-relative">
                                                                            <select id="search7">
                                                                                <option value="" selected="">Alive & Well Checked</option>
                                                                                <option value="AD">Associates</option>
                                                                                <option value="BD">Bachelors</option>
                                                                            </select>
                                                                            <div class="input-group-append position-absolute append-btn">
                                                                                <button class="border-0 bg-transparent" type="button">
                                                                                
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle border-0">
                                                                    <div class="form-group has-search">
                                                                        <input type="text" class="form-control valid" name="Product" value="Write note here...">
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle border-0">
                                                                    <div class="form-group has-search">
                                                                        <input type="text" class="form-control valid text-right" name="Product" value="05">
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle border-0">
                                                                    <div class="form-group has-search">
                                                                        <input type="text" class="form-control valid text-right" name="Product" value="45">
                                                                    </div>
                                                                </td>
                                                                <td class="align-middle border-0">
                                                                    <div class="form-group has-search d-flex align-items-center">
                                                                        <input type="text" class="form-control valid w-6" name="Product" value="$84,000"> <i class="ml-2 fas fa-minus text-white bg-danger rounded-circle p-1 cursor-pointer"></i>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <div class="col-12">
                                                        <a class="text-orange font-weight-500" href="#"><i class="mr-2 fas fa-plus-circle text-orange"></i>Add More Expenses</a>
                                                    </div>
                                                    <div class="col-3 float-right">
                                                        <ul class="list-unstyled mb-4">
                                                            <li class="d-flex justify-content-between py-3"><h4 class="font-weight-bold text-dark mb-0">Expense Total</h4>
                                                            <h4 class="font-weight-bold text-orange mb-0">1083,00 USD</h4>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="row">
                                                    <div class="col-md-12 mb-4">
                                                        <div class="user-header mb-2 pb-0 border-0">
                                                            <h4 class="user-title font-weight-bold mb-0">Add Notes</h4>
                                                        </div>
                                                        <textarea class="form-control p-3" name="addComment" id="characteristics" rows="5" placeholder="">Add terms &amp; conditions here...</textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="user-header mb-4 border-0">
                                                    <h4 class="user-title font-weight-bold mb-0">Add Receipt</h4>
                                                </div>
                                                <div class="col-md-4">
                                                    <div class="row mb-3 mt-3 ">
                                                  
                                                        <div class="col-md-12 mt-3 mb-2 text-center">

                                                          
                                                              <input
                                                              class="form-control pt-2"
                                                              type="file"
                                                              id="print_logo3"
                                                              name="attachment"
                                                             
                                                            />
                                                        </div>
                                                    </div>
                                                    
                                                    <p>Receipt must be an image or PDF file.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="button">Save Expense Entry <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>