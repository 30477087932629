<main class="content py-0">

    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-auto d-none d-sm-block">
                            <div class="d-flex mb-2">
                                <h3 class="page-title"><strong>Case list &nbsp;</strong> </h3>

                            </div>
                        </div>

                        <div class="col-auto ml-auto text-right mt-n1">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                    <li class="filter-item">
                                        <div ngbDropdown class="d-inline-block">
                                            <button class="btn btn-outline-primary btn-lg bulkaction-dropdown"
                                                id="dropdownBasic1" ngbDropdownToggle [disabled]="isBulkActionDisabled"
                                                *ngIf="filterByOption == 1 ? hasPermissionType.validate([permissionType.DeleteClients]) : hasPermissionType.validate([permissionType.DeleteClients, permissionType.AssignOrUnassignSalespeople])">Bulk
                                                Action</button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                <!-- <button ngbDropdownItem (click)="OnBulkAssignSalesperson()"  *ngIf="filterByOption != 1 && hasPermissionType.validate([permissionType.AssignOrUnassignSalespeople])">
                                                    Assign Salesperson</button> -->
                                                <button ngbDropdownItem (click)="onBulkDelete()"
                                                    *ngIf="filterByOption != 1 && hasPermissionType.validate([permissionType.DeleteClients])">Delete</button>
                                                <button ngbDropdownItem (click)="onBulkUnDelete()"
                                                    *ngIf="filterByOption == 1 && hasPermissionType.validate([permissionType.AssignOrUnassignSalespeople])">Undelete</button>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="filter-item" *hasPermissions='[permissionType.CreateClients]'>
                                        <button class="btn btn-lg bg-blue text-white"
                                            [routerLink]="['/add-case-first-steps']">
                                            <i class="align-middle fas fa-plus-circle"></i> New Case
                                        </button>
                                    </li>
                                    <li class="filter-item">
                                        <button class="btn btn-lg bg-blue text-white">
                                            <i class="align-middle fas fa-external-link-alt"></i> Export
                                        </button>
                                    </li>
                                    <li class="filter-item px-0 filter-icon" (click)="filterNavStatus = true">
                                        <button class="btn btn-lg bg-blue text-white">
                                            <i class="align-middle fas fa-filter"></i>
                                        </button>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <!-- Filter right sidenav -->
                        <app-case-list-filter (onApplyFilter)="applyFilter($event)" (resetFilters)="onResetFilters()"
                            [filterStatusParent]="filterNavStatus" (closeBtnFilterStatus)="closeFilterEvent($event)">
                        </app-case-list-filter>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-10"></div>
                    <div class="col-md-2  ">
                        <div class="form-group form-inline float-right">
                            <button class="dropdown-toggle btn  btn-lg  bulkaction-dropdown"
                                (click)="openCustomizedListModal()"> Customize List&nbsp; </button>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="case-table table-responsive" *blockUI="'container-blockui-manage'">
                            <div>
                                <table class="neu-table table table-striped neu-table"
                                    [ngStyle]="{'visibility' : isDtVisible ? 'visible' : 'hidden'  }" datatable
                                    [dtOptions]="dtOptions">
                                </table>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>