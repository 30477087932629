import { CaseTypeCustomFieldModel } from '../caseTypeCustomField/caseTypeCustomFieldModel';
import { CaseTypeReferenceLabelModel } from '../caseTypeReferenceLabel/caseTypeReferenceLabelModel';
import { CaseTypeModel } from './caseTypeModel';

export class CaseTypeAddModel extends CaseTypeModel {
    public caseTypeReferences: CaseTypeReferenceLabelModel[];
    public folderTemplateIds: number[];
    public documentTemplateIds: number[];
    public caseTypeCustomFields: CaseTypeCustomFieldModel[];
    public caseFlagIds: number[];
    public caseServiceIds: number[];
    public subjectTypeIds: number[];
    public caseReviewIds: number[];
    public defaultSubjectTypeId?: number;
}
