import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NoteFor } from 'src/app/helpers/enums/notes/noteFor';

@Component({
  selector: 'app-profile-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class ProfileNotesComponent implements OnInit {

  moduleId: number;
  staffId: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.moduleId = NoteFor.Staff;
  }

}
