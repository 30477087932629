import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { ClientService } from 'src/app/services/client.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-payment-slip-enter-payment-popup',
  templateUrl: './payment-slip-enter-payment-popup.component.html',
  styleUrls: ['./payment-slip-enter-payment-popup.component.css']
})
export class PaymentSlipEnterPaymentPopupComponent implements OnInit {



  constructor(
    private clientService: ClientService,
    private ngbActiveModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private router: Router) { }

  clientList: SelectListItem<number>[];
  form: FormGroup;
  isSubmitted = false;

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      amount: ['', Validators.required],
      date: [''],
      checkRef: [''],
      notes: ['']
    });
    this.loadClients();
  }

  private loadClients(): void {
    this.clientService.getClientList().subscribe((data: any) => {
      this.clientList = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
    });
  }

  onSubmit(): void {
    this.isSubmitted = true;
    if (this.form.valid) {
      const clientId = this.form.value.client;
      const clientName = this.clientList.find(x => x.id == clientId).value;
      this.clientService.clientDetailSubject$.next({ id: clientId, name: clientName });
      this.activeModal.close('save click');
      this.router.navigate([`client/${clientId}/contact/add`]);
    }
  }

  get activeModal(): any {
    return this.ngbActiveModal;
  }

}
