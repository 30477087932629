<main class="content py-0 changepwd">
  <div class="container-fluid p-0">
    <div class="row mt-5 h-100 justify-content-center align-items-center">
      <form
        class="col-5 mt-5 new-case-form"
        [formGroup]="form"
        (ngSubmit)="form.valid && onSubmit()"
      >
        <div class="card">
          <div class="card-body">
            <h2 class="card-title font-weight-bold mb-4">Change Password</h2>
            <h5 class="card-subtitle mb-2 text-muted">
              In order to protect your account, make sure your password is
              strong.
            </h5>
            <div class="card-text">
              <div class="form-groupx"></div>
              <div class="has-search">
                <div class="row">
                  <div class="col-12 mt-3">
                    <div class="row">
                      <div class="col-md-10">
                        <input
                          type="{{ toggleEye ? 'text' : 'password' }}"
                          class="form-control newclp w-100"
                          style="max-width: 100% !important"
                          placeholder="Current Password"
                          formControlName="currentPassword"
                        />
                      </div>
                      <div class="col-md-2 text-center my-auto fs-3">
                        <button
                          type="button"
                          class="btn shadow-none"
                          (click)="changeEye()"
                          title="Show/Hide Passowrd"
                        >
                          <i
                            class="fas"
                            [ngClass]="toggleEye ? 'fa-eye' : 'fa-eye-slash'"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-3">
                    <div class="row">
                      <div class="col-md-10">
                        <input
                          type="{{ toggleEye2 ? 'text' : 'password' }}"
                          formControlName="newPassword"
                          (keyup)="onKeyup($event)"
                          (keypress)="allow_somespecial_char($event)"
                          name="password1"
                          autocomplete="off"
                          class="form-control w-100"
                          style="max-width: 100% !important"
                          placeholder="New Password"
                        />
                      </div>
                      <div class="col-md-2 text-center my-auto fs-3">
                        <button
                          type="button"
                          class="btn shadow-none"
                          (click)="changeEye2()"
                          title="Show/Hide Passowrd"
                        >
                          <i
                            class="fas"
                            [ngClass]="toggleEye2 ? 'fa-eye' : 'fa-eye-slash'"
                          ></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 mt-3 w-100"
                    style="max-width: 100% !important"
                  >
                    <div id="message">
                      <div id="letter">
                        <span
                          [ngClass]="chklow == true ? 'valid' : 'invalid'"
                        ></span>
                        <p>Contains at least one <b>lower character</b></p>
                      </div>
                      <div id="capital">
                        <span
                          [ngClass]="chkcaps == true ? 'valid' : 'invalid'"
                        ></span>
                        <p>Contains at least one <b>upper character</b></p>
                      </div>
                      <div id="number">
                        <span
                          [ngClass]="chknum == true ? 'valid' : 'invalid'"
                        ></span>
                        <p>Contains at least one <b>number</b></p>
                      </div>
                      <div id="length">
                        <span
                          [ngClass]="chkSpec == true ? 'valid' : 'invalid'"
                        ></span>
                        <p>
                          Contains at least one of the
                          <b>special characters !@#$%^&*()</b>
                        </p>
                      </div>
                      <div id="length">
                        <span
                          [ngClass]="chklen == true ? 'valid' : 'invalid'"
                        ></span>
                        <p>Contains at least <b>12 characters</b></p>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 mt-3 mb-3">
                    <div class="row">
                      <div class="col-md-10">
                        <input
                          type="{{ toggleEye3 ? 'text' : 'password' }}"
                          formControlName="confirmPassword"
                          [attr.disabled]="
                            chklen == true &&
                            chknum == true &&
                            chklow == true &&
                            chkcaps == true &&
                            chkSpec == true
                              ? null
                              : ''
                          "
                          class="form-control w-100"
                          style="max-width: 100% !important"
                          placeholder="Confirm Password"
                          (keypress)="allow_somespecial_char($event)"
                          id="confirmpwd"
                          name="confirmpwd"
                        />
                      </div>
                      <div class="col-md-2 text-center my-auto fs-3">
                        <button
                          type="button"
                          class="btn shadow-none"
                          (click)="changeEye3()"
                          title="Show/Hide Passowrd"
                        >
                          <i
                            class="fas"
                            [ngClass]="toggleEye3 ? 'fa-eye' : 'fa-eye-slash'"
                          ></i>
                        </button>
                      </div>
                    </div>
                    <div
                      *ngIf="
                        isPasswordValid &&
                        form.controls.confirmPassword.touched &&
                        form.controls.confirmPassword.errors &&
                        !form.controls.currentPassword.errors
                      "
                      class="text-danger"
                    >
                      <div
                        *ngIf="form.controls.confirmPassword.errors.required"
                      >
                        Confirm Password is required
                      </div>
                      <div *ngIf="form.controls.confirmPassword.errors.match">
                        New Password and Confirm Password must matched
                      </div>
                    </div>
                    <div
                      *ngIf="
                        form.controls.newPassword.touched &&
                        !isPasswordValid &&
                        !form.controls.currentPassword.errors
                      "
                      class="text-danger"
                    >
                      Password must conform to all the listed criteria
                    </div>
                    <div
                      *ngIf="
                        form.controls.currentPassword.touched &&
                        form.controls.currentPassword.errors &&
                        (!form.controls.newPassword.errors ||
                          !form.controls.newPassword.touched)
                      "
                      class="text-danger"
                    >
                      Current Password is required
                    </div>
                    <div
                      *ngIf="
                        form.controls.currentPassword.touched &&
                        form.controls.currentPassword.errors &&
                        form.controls.newPassword.errors &&
                        form.controls.newPassword.touched
                      "
                      class="text-danger"
                    >
                      Current and New Password is required
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button
              type="submit"
              class="btn btn-primary btn-lg changebtn w-100"
            >
              Change Password
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</main>
