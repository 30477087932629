import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SelectListItem } from '../models/shared/select.list.item';
import { ManagerAssignModel } from '../models/staff/assign.managers.model';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';
import { EmployeeAddModel } from '../models/staff/employee.add.model';
import { EmployeeEditModel } from '../models/staff/employee.edit.model';
import { StaffDetailModel } from '../models/staff/staff.detail.model';

@Injectable({
    providedIn: 'root'
})
export class StaffService {
    constructor(private http: HttpClient) {
    }

    public staffDetailSubject$ = new BehaviorSubject<{ id: string, name: string }>(null);
    public staffDetai$ = this.staffDetailSubject$.asObservable();

    public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/staff/query`, dataTablesParameters, {});
    }

    public get(id: string): Observable<any> {
        return this.http.get<any>(`${environment.apiBaseUrl}/staff/${id}`);
    }

   public  getstafflist(): Observable<StaffDetailModel[]> {
        return this.http.get<StaffDetailModel[]>(`${environment.apiBaseUrl}/staff/get-stafflist`);
    }
    public post(model: EmployeeAddModel): Observable<{ isSuccess: boolean, message: string }> {
        const formData = new FormData();
        formData.append('firstName', model.firstName);
        formData.append('middleName', model.middleName);
        formData.append('lastName', model.lastName);
        formData.append('email', model.email);
        formData.append('title', model.title);
        formData.append('managerId', model.managerId);
        formData.append('role', model.role);
        formData.append('address1', model.address1);
        formData.append('address2', model.address2);
        formData.append('address3', model.address3);
        formData.append('country', model.country);
        formData.append('city', model.city);
        formData.append('state', model.state);
        formData.append('zip', model.zip);
      //formData.append('contactNumber', model.contactNumber);
      //formData.append('homePhone', model.homePhone);
        formData.append('fax', model.fax);
        formData.append('dob', model.dob);
        formData.append('accountCode', model.accountCode);
        if (model.tags !== null && model.tags !== undefined) {
            model.tags.forEach((tag) => formData.append('tags[]', tag));
        }
        if (model.contactNos !== null && model.contactNos !== undefined) {
          for (let i = 0; i < model.contactNos.length; i++) {
              formData.append(`contactNos[${i}].contactTypeId`, JSON.stringify(model.contactNos[i].contactTypeId));
              formData.append(`contactNos[${i}].contactNumber`, model.contactNos[i].contactNumber);
              formData.append(`contactNos[${i}].extensionNote`, model.contactNos[i].extensionNote);
          }
        }
        formData.append('generalNotes', model.generalNotes);
        formData.append('adminNotes', model.adminNotes);
        formData.append('emailSignature', model.emailSignature);
        formData.append('preferedServices', model.preferedServices);
        formData.append('preferedRegions', model.preferedRegions);
        formData.append('isTrackLastLocation', JSON.stringify(model.isTrackLastLocation));
        if (model.profileImg != null && model.profileImg !== undefined) {
            formData.append('profileImgFile', model.profileImg, model.profileImg.name);
        }
        return this.http.post<{ isSuccess: boolean, message: string }>(`${environment.apiBaseUrl}/staff`, formData, {});
    }

    public put(model: EmployeeEditModel): Observable<any> {
        debugger;
        const formData = new FormData();
        formData.append('id', model.id);
        formData.append('firstName', model.firstName);
        formData.append('middleName', model.middleName);
        formData.append('lastName', model.lastName);
        formData.append('email', model.email);
        formData.append('title', model.title);
        formData.append('managerId', model.managerId);
        formData.append('role', model.role);
        formData.append('address1', model.address1);
        formData.append('address2', model.address2);
        formData.append('address3', model.address3);
        formData.append('country', model.country);
        formData.append('city', model.city);
        formData.append('state', model.state);
        formData.append('zip', model.zip);
        formData.append('fax', model.fax);
        formData.append('dob', model.dob);
        formData.append('accountCode', model.accountCode);
        if (model.tags !== null && model.tags !== undefined && model.tags?.length > 0) {
            model.tags.forEach((tag) => formData.append('tags[]', tag));
        }
        if (model.contactNos !== null && model.contactNos !== undefined) {
          for (let i = 0; i < model.contactNos.length; i++) {
              formData.append(`contactNos[${i}].contactTypeId`, JSON.stringify(model.contactNos[i].contactTypeId));
              formData.append(`contactNos[${i}].contactNumber`, model.contactNos[i].contactNumber);
              formData.append(`contactNos[${i}].id`, JSON.stringify(model.contactNos[i].id));
              formData.append(`contactNos[${i}].extensionNote`, model.contactNos[i].extensionNote);
          }
        }
        formData.append('generalNotes', model.generalNotes);
        formData.append('adminNotes', model.adminNotes);
        formData.append('emailSignature', model.emailSignature);
        formData.append('preferedServices', model.preferedServices);
        formData.append('preferedRegions', model.preferedRegions);

        formData.append('isActive', JSON.stringify(model.isActive));
        
        if (model.isTrackLastLocation) {
            formData.append('isTrackLastLocation', JSON.stringify(model.isTrackLastLocation));
        }
        if (model.profileImg != null && model.profileImg !== undefined) {
            formData.append('profileImgFile', model.profileImg, model.profileImg.name);
        }
        return this.http.put<any>(`${environment.apiBaseUrl}/staff`, formData, {});
    }
    public delete(userIds: string[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/staff?staffIds=${userIds.join('&staffIds=')}`);
    }
    public undelete(userIds: string[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/staff/undelete-staffs?staffIds=${userIds.join('&staffIds=')}`);
    }

    public getOverview(id: string): Observable<StaffDetailModel> {
        return this.http.get<StaffDetailModel>(`${environment.apiBaseUrl}/staff/overview/${id}`);
    }

    public getManagers(): Observable<SelectListItem<string>[]> {
        return this.http.get<SelectListItem<string>[]>(`${environment.apiBaseUrl}/staff/get-managers`);
    }

    public getStaffTags(text: string): Observable<Array<string>> {
        return this.http.get<Array<string>>(`${environment.apiBaseUrl}/staff/get-tags/${text}`);
    }

    public getSalespersons(): Observable<SelectListItem<string>[]> {
        return this.http.get<SelectListItem<string>[]>(`${environment.apiBaseUrl}/staff/get-salespersons`);
    }
    public getinvestigators(): Observable<SelectListItem<string>[]> {
        return this.http.get<SelectListItem<string>[]>(`${environment.apiBaseUrl}/staff/get-investigators`);
    }

    public getCaseManagers(): Observable<SelectListItem<string>[]> {
        return this.http.get<SelectListItem<string>[]>(`${environment.apiBaseUrl}/staff/get-case-managers`);
    }

    public getStaffPermissions(id: string): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/staff/permission/${id}`);
    }
    public assignManagers(model: ManagerAssignModel): Observable<any> {
        return this.http.patch(`${environment.apiBaseUrl}/staff/assign-manager`, model);
    }
}
