import { Component, EventEmitter, OnInit, Output, Input, OnChanges } from '@angular/core';
import { AppUtils } from 'src/app/helpers';
import { ClientFilterByOptionsMapping, ClientSearchOptionsMapping } from 'src/app/helpers/constants/filter-and-search/client.search&filter.option.mapping';
import { ClientFilterByOptions } from 'src/app/helpers/enums/filters-and-searchs/client.search&filter.option';
import { FilterBy, FilterInputModel, FilterModel, SearchByModel } from 'src/app/models/shared/filters.model';
import { SearchModel } from 'src/app/models/shared/search.model';
import { ClientService } from 'src/app/services/client.service';
import { StaffService } from 'src/app/services/staff.serrvice';

@Component({
  selector: 'client-filter-and-search',
  templateUrl: './filter-and-search.component.html',
  styleUrls: ['./filter-and-search.component.css']
})
export class ClientFilterAndSearchComponent implements OnInit, OnChanges {

  filterBy: FilterInputModel[] = [];
  filterIn: FilterInputModel[] = [];
  isFilterInVisible = false;

  @Output() onApplyFilter = new EventEmitter();
  @Output() resetFilters = new EventEmitter();

  searchBy: SearchByModel[] = [];

  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();

  constructor(
    private appUtils: AppUtils,
    private staffService: StaffService,
    private clientService: ClientService) {
    ClientSearchOptionsMapping.forEach((key, value) => this.searchBy.push({ id: value, name: key }));
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.loadFilterOptions();
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onResetFilters(): void {
    this.loadFilterOptions();
    this.resetFilters.emit();
  }

  private loadFilterOptions(): void {
    this.clientService.getClientSalespersonList().subscribe((data: any) => {
      const filterOptions = [];
      ClientFilterByOptionsMapping.forEach((key, value) =>
        filterOptions.push({ name: key, id: `${value}`, group: undefined, isSelected: false })
      );
      data.forEach(user =>
        filterOptions.push({
          name: user.value,
          id: `${ClientFilterByOptions.Salesperson}_` + user.id,
          group: 'Salesperson', isSelected: false
        })
      );

      this.filterBy = filterOptions;
      if (this.filterBy.length >= 1) {
        this.filterBy[0].isSelected = true;
      }
    });
  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }

}
