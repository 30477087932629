export enum NoteFilterByOptions {
    None = -1,
    PinnedNotes= 1,
    WithAttachments = 2,
    DeletedNotes = 3,
    AttachmentType = 4,
}

export enum NoteSearchOptions {
    Body = 1,
    AuthorName = 2,
    AttachmentName = 3
}

export enum AttachmentTypeOptions {
    Audio = 1,
    Document = 2,
    Image = 3,
    Pdf = 4,
    SpreadSheet = 5,
    Video = 6
}