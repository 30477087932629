<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>New Case Service</strong> </h3>
                        </div>

                        <!-- <div class="col-md-auto fa-client-title">
                            1213
                        </div> -->
                    </div>


                    <div class="row mx-0" *blockUI="'container-blockui-new-case-service'">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form"  [formGroup]="form" (ngSubmit)="onSubmit()" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
                                          
                                            <div class="card-body">
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="name">Name<sup class="clsStric">*</sup></label>
                                                                    
                                                                    <input type="text" formControlName="name" class="form-control mb-3" id="name" name="name" autocomplete="off">
                                                                    <div *ngIf="submitted && form.controls.name.errors">
                                                                        <div *ngIf="submitted && form.controls.name.errors.required" class="text-danger">
                                                                                Name is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="description">Description</label>
                                                                    
                                                                    <input type="text" formControlName="description" class="form-control mb-3" id="description" name="description" autocomplete="off">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <hr>


                                                       <div class="row">
                                                        <div class="col-md-6 mb-2">
                                                            <label><b><sup class="clsStric">*</sup> Make this service available on the following case types:</b></label>
                                                            <div class="form-check mt-2" formArrayName="case_types">
                                                                <div class="form-check mt-2"
                                                                    *ngFor="let ct of caseTypesToFormArray.controls; let i = index"
                                                                    [formGroupName]="i">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        formControlName="isSelected" id="casetypes_{{i}}">
                                                                    <label class="form-check-label" for="lbl_casetypes_{{i}}">
                                                                        {{ct.value.name}}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="submitted && form.controls.case_types.errors">
                                                                <div *ngIf="submitted && form.controls.case_types.errors.required"
                                                                    class="text-danger">
                                                                    Please select atleast one case type
                                                                </div>
                                                            </div> 
                                                        </div>
                                                       </div>

                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>


                                
                                  
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  routerLink ="/setting/cases/services-list"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="sub">Save Case Service <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
