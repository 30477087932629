import { Component, OnInit } from '@angular/core';

import { Form, FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

@Component({
  selector: 'app-general-case-settings',
  templateUrl: './general-case-settings.component.html',
  styleUrls: ['./general-case-settings.component.css']
})
export class GeneralCaseSettingsComponent implements OnInit {

  constructor(private formBuilder: FormBuilder) { }

  form: FormGroup;
  submitted: any;

  users = [{

    "id": 1,
    "name": "Paul"
  },
  {
    "id": 2,
    "name": "rahul"
  }];

  ngOnInit(): void {
    this.form = new FormGroup({
      casenumber: new FormControl(),
      case_series_number: new FormControl(),
      recent_case_count: new FormControl(),
      case_request_inst: new FormControl(),

      case_request_num_format: new FormControl(),
      case_request_series_num: new FormControl(),
      public_case_request_aceess: new FormControl(),
      default_include_attachment: new FormControl(),

      inbound_email_update: new FormControl(),
      inbound_email_catch: new FormControl(),
      image_attach_style: new FormControl(),
      physcially_attach_files: new FormControl(),

      file_download: new FormControl()

    })
  }

  onSubmit() {

  }

}
