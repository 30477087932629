import { EventEmitter } from '@angular/core';
import { Component, Input, OnInit, Output } from '@angular/core';
import { Validators } from '@angular/forms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-assign-contact-salesperon',
  templateUrl: './assign-contact-salesperson.component.html',
  styleUrls: ['./assign-contact-salesperson.component.css']
})
export class AssignContactSalespersonComponent implements OnInit {

  constructor(
    private ngbActiveModal: NgbActiveModal,
    private staffService: StaffService,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder
  ) {}

  salesPersonSelectListItem: SelectListItem<string>[] = [];
  form: FormGroup;
  isSubmitted = false;

  @BlockUI() blockUI: NgBlockUI;

  @Input() public selectedContactsCount: number;

  @Output() salesPersonId = new EventEmitter<string>();

  get activeModal(): any {
    return this.ngbActiveModal;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      salesperson: ['', [Validators.required]]
    });
    this.loadSalesPerson();
  }

  private loadSalesPerson(): void {
    this.blockUI.start();
    this.staffService.getSalespersons().subscribe((data: any) => {
      this.salesPersonSelectListItem = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  onSubmit(): void {
    this.isSubmitted = true;
    if (this.form.invalid) {
      return;
    }
    this.salesPersonId.emit(this.form.value.salesperson);
  }

}
