import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, mapTo, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { AppUtils } from "../helpers";
import { DataTableResponseModel } from "../models/shared/datatable.response.model";
import { StaffLicenseRenewModel } from "../models/staff-license/staff.license.renew.model";

@Injectable({
    providedIn: 'root'
})
export class StaffLicenseService {

    private readonly baseUrl = `${environment.apiBaseUrl}/staff-license`;
    private returnUrl: string;
    public staffDetailSubject = new BehaviorSubject<{ licenseId: number, licenseName: string, staffId: string, staffName: string }>(null);
    public staffDetai$ = this.staffDetailSubject.asObservable();

    public setReturnUrl = (url: string) => this.returnUrl = url;

    public getReturnUrl = () => this.returnUrl;

    constructor(private http: HttpClient, private appUtils: AppUtils) {
    }

    public query(dataTablesParameters: any, staffId: string = null): Observable<DataTableResponseModel> {
        let url = '/query';
        if (staffId !== null) {
            url = `${url}?staffId=${staffId}`;
        }
        return this.http.post<DataTableResponseModel>(`${this.baseUrl}${url}`, dataTablesParameters, {});
    }
    public get(id: number): Observable<any> {
        return this.http.get<any>(`${this.baseUrl}/${id}`);
    }

    public post(formData: FormData): Observable<any> {
        return this.http.post<any>(`${this.baseUrl}`, formData, {});
    }

    public put(formData: FormData): Observable<any> {
        return this.http.put<any>(`${this.baseUrl}`, formData, {});
    }
    public delete(ids: number[]): Observable<any> {
        return this.http.delete(`${this.baseUrl}?ids=${ids.join('&ids=')}`);
    }
    public unDelete(ids: number[]): Observable<any> {
        return this.http.delete(`${this.baseUrl}/undelete?ids=${ids.join('&ids=')}`);
    }

    public patch(model: StaffLicenseRenewModel): Observable<any> {
        return this.http.patch(`${this.baseUrl}`, model);
    }
    public downloadAttachment(id: number, fileName: string): Observable<any> {
        return this.http.get(`${this.baseUrl}/DownloadAttachment/${id}`, { responseType: 'blob' })
            .pipe(tap((blob: Blob) => {
                this.appUtils.downloadFile(blob, fileName);
            }),
                mapTo(true),
                catchError(error => {
                    throw error;
                }));
    }

    public downloadAttachmentByUrl(url: string): Observable<any> {
        return this.http.get(url, { responseType: 'blob' });
    }
}

