import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ViewChild,
  Renderer2,
  OnDestroy,
} from '@angular/core';

import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';
import { CompanyLocationFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/company.location.search&filter.options';
import { Subscription } from 'rxjs/internal/Subscription';
import { ComponentDestoryHelper } from 'src/app/helpers/component-destory.helper';
import { CompanyLocationService } from 'src/app/services/company-location.service';
import { CompanyPolicyVersionHistoryFilterOptionDetails } from 'src/app/helpers/constants/filter-and-search/companypolicy.versionhistory.Filter&SearchOptions';
import { CompanyLocationFilterOptionsDetails } from 'src/app/helpers/constants/filter-and-search/company.location.filter&search.options.detail';
import { join } from 'path';

@Component({
  selector: 'app-company-location-list',
  templateUrl: './company-location-list.component.html',
  styleUrls: ['./company-location-list.component.css'],
})
export class CompanyLocationListComponent
  extends ComponentDestoryHelper
  implements OnInit, OnDestroy
{
  @BlockUI('company-location') blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false })
  datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;
  dtRendered = true;
  visibleColumns = [];
  isDtVisible = false;
  isDtInitialized = false;
  permissionType = Permission;
  hasPermissionType = HasPermission;
  users: any = [];
  isBulkActionDisabled = true;
  companyPolicyId = 0;
  // for filters
  public filterOptionId: number = CompanyLocationFilterOptions.None;
  private filterByItemId = '';
  private searchBy = -1;
  private searchFor = '';
  filterBy: any;
  formodel: string;
  frm: FormGroup;
  filterOptions: any;
  subscriptions: Subscription[];
  // capture filter open and close events
  filterNavStatus = false;
  constructor(
    private companyLocationService: CompanyLocationService,
    private tosterService: ToasterService,
    private route: ActivatedRoute,
    private renderer: Renderer2,
    private router: Router,
    public cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.ngAfterOnDestroy();
  }
  // tslint:disable-next-line: typedef
  get companyLocationFilterOptions() {
    return CompanyLocationFilterOptions;
  }
  closeFilterEvent(event: any): void {
    this.filterNavStatus = event;
  }
  private getSelectedIds(): any[] {
    const Ids = [];
    const rows = this.dtInstance.rows().nodes();
    [...rows.$('.checked')].forEach((item) => Ids.push($(item).val()));
    return Ids;
  }
  ngOnInit(): void {
    this.initDataTable();
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtInstance = dtInstance;
      const header = dtInstance.columns().header();
      $(header)
        .find('[action-type="select-unSelect-all"]')
        .off('change')
        .on('change', (e: any) => {
          this.onSelectAllRow(e.target.checked);
        });
    });
  }
  // private methods
  private initDataTable(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      destroy: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing:
          '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">',
        },
      },
      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,

      order: [[1, 'asc']],
      autoWidth: false,
      ajax: (dataTablesParameters: any, callback) => {
        self.blockUI.start();
        dataTablesParameters.filterOptionId = this.filterOptionId;
        dataTablesParameters.filterItemId = this.filterByItemId;
        dataTablesParameters.searchBy = this.searchBy;
        dataTablesParameters.searchFor = this.searchFor;
        const subscription = self.companyLocationService
          .query(dataTablesParameters)
          .subscribe(
            (resp) => {
              callback({
                recordsTotal: resp.recordsTotal,
                recordsFiltered: resp.recordsFiltered,
                data: resp.data,
              });
              self.blockUI.stop();
            },
            (error) => {
              self.tosterService.ProcessErrorResponse(error);
              self.blockUI.stop();
            },
            () => {
              self.toggleControls();
            }
          );
        self.subscriptions.push(subscription);
      },
      columns: [
        {
          data: null,
          title: `<input type="checkbox" action-type="select-unSelect-all" />`,
          name: 'select',
          orderable: false,
          render: (data, type, row) => {
            return `<input type='checkbox' value = '${row.id}' action-type= "select-row"/>`;
          },
        },
        {
          data: 'name',
          name: 'name',
          title: 'Name',
          render: (data, type, row) => {
            const html = `<span>${row.name} ${
              row.isPrimary === true
                ? '<span style="background-color: #fff7d7;">[Primary]</span>'
                : ''
            }<span>`;
            return html;
          },
        },
        {
          data: null,
          name: null,
          title: 'Address',
          orderable: false,
          render: (data, type, row) => {
            const address = [];
            const address2 = [];
            if (row.address1) {
              address.push(row.address1);
            }
            if (row.address2) {
              address.push(row.address2);
            }
            if (row.address3) {
              address.push(row.address3);
            }
            if (row.city) {
              address2.push(row.city);
            }
            if (row.state || row.zipCode) {
              let zipState = '';
              if (row.state) {
                zipState = row.state + ' ';
              }
              if (row.zipCode) {
                zipState += row.zipCode;
              }
              address2.push(zipState);
            }
            if (address2.length > 0) {
              address.push(address2.join(', '));
            }
            address.push(row.country);

            const html = `<span>${address.join('<br>')}</span>`;
            return html;
          },
        },
        {
          data: 'name',
          title:
            HasPermission.validate([Permission.DeleteClients]) ||
            HasPermission.validate([Permission.EditClients])
              ? 'Action'
              : '',
          name: 'action',
          orderable: false,
          render: (data, type, row) => {
            const disabled = '';
            const deleteButton = `<button  class='btn del-btn btn-lg btn-table' action-type="delete" ${disabled}>
            <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
            </button>`;
            const undeleteButton = `<button  class='btn del-btn btn-lg btn-table' action-type="un-delete">
            <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-restore-alt  fa-1x p-2 text-danger'></i>
            </button>`;
            const editButton =
              this.filterOptionId !== CompanyLocationFilterOptions.Deleted
                ? `<button  class='btn del-btn btn-lg btn-table' alt="undelete">
            <i action-type='edit' class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
            </button>`
                : '';

            return `<div class='fa-edit'>${editButton} ${
              this.filterOptionId !== CompanyLocationFilterOptions.Deleted
                ? deleteButton
                : undeleteButton
            }
              </div>`;
          },
        },
      ],
      rowCallback: (row, data: any) => {
        // tslint:disable-next-line: variable-name
        const _row = $(row);
        _row
          .find('[action-type="edit"]')
          .off('click')
          .on('click', () => {
            self.router.navigate([
              'setting/global/edit-company-location',
              data.id,
            ]);
            // self.edit(data.id);
          });
        _row
          .find('[action-type="delete"]')
          .off('click')
          .on('click', () => {
            self.delete([data.id]);
          });
        _row
          .find('[action-type="un-delete"]')
          .off('click')
          .on('click', () => {
            self.unDelete([data.id]);
          });
        _row
          .find('[action-type = "select-row"]')
          .off('click')
          .on('click', (e: any) => {
            this.onSelectedOrUnselectRow(e);
          });
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
    };
  }
  // for filters
  applyFilter(filterData: any): void {
    this.searchBy = Number(filterData.searchBy);
    this.searchFor = filterData.searchFor;
    const filterIdArr = filterData.filter?.split('_');
    if (filterIdArr && filterIdArr.length > 0) {
      this.filterOptionId = Number(filterIdArr[0]);
      if (filterIdArr.length > 1) {
        this.filterByItemId = filterIdArr[1];
      }
    }
    this.dtInstance.ajax.reload();
  }

  onResetFilters(): void {
    this.filterOptionId = CompanyLocationFilterOptions.None;
    this.filterByItemId = '';
    this.searchBy = -1;
    this.searchFor = '';
    this.dtInstance.ajax.reload();
  }

  onSelectedOrUnselectRow(event: any): void {
    if (event.target.checked) {
      this.renderer.addClass(event.target, 'checked');
    } else {
      this.renderer.removeClass(event.target, 'checked');
    }
    this.toggleControls();
  }

  private toggleControls(): void {
    this.setSelectAllCheckbox();
    this.enableOrDisableDeleteButton();
  }
  private setSelectAllCheckbox(): void {
    const rows = this.dtInstance.rows().nodes();
    if (rows !== null) {
      const header = this.dtInstance.columns().header();
      const selectAllElement = $(header).find(
        '[action-type="select-unSelect-all"]'
      )[0] as any;
      const selelectedRows = [...rows.$('[action-type= "select-row"]')];
      selectAllElement.checked =
        selectAllElement != null &&
        selelectedRows.length > 0 &&
        selelectedRows.every((row) => row.classList.contains('checked'));
    }
  }

  onBulkDelete(): void {
    this.delete(this.getSelectedIds());
  }

  onBulkUnDelete(): void {
    this.unDelete(this.getSelectedIds());
  }

  private reloadDataTable(): void {
    this.dtInstance.ajax.reload();
  }

  delete(ids: number[]): void {
    if (
      !confirm('Are you sure you want to delete selected Company location(s)?')
    ) {
      return;
    }
    this.blockUI.start();
    this.companyLocationService.delete(ids).subscribe(
      () => {
        this.reloadDataTable();
        this.tosterService.success(
          'Company location(s) has been deleted successfully.'
        );
        this.blockUI.stop();
      },
      (error) => {
        this.tosterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }

  unDelete(ids: number[]): void {
    if (
      !confirm(
        'Are you sure you want to undelete selected Company location(s)?'
      )
    ) {
      return;
    }
    this.blockUI.start();
    this.companyLocationService.undelete(ids).subscribe(
      () => {
        this.reloadDataTable();
        this.tosterService.success(
          'Company location(s) has been undeleted successfully.'
        );
        this.blockUI.stop();
      },
      (error) => {
        this.tosterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }

  onSelectAllRow(isChecked: boolean): void {
    const rows = this.dtInstance.rows().nodes();
    const checkBoxes = [...rows.$('[action-type= "select-row"]')];
    if (isChecked) {
      checkBoxes.forEach((row) => {
        this.renderer.addClass(row, 'checked');
        (row as HTMLInputElement).checked = true;
      });
    } else {
      checkBoxes.forEach((row) => {
        this.renderer.removeClass(row, 'checked');
        (row as HTMLInputElement).checked = false;
      });
    }
    this.enableOrDisableDeleteButton();
  }
  private enableOrDisableDeleteButton(): void {
    const rows = this.dtInstance.rows().nodes();
    this.isBulkActionDisabled = [...rows.$('.checked')].length === 0;
  }

  edit(id: number): void {
    this.router.navigate(['/setting/global/edit-company-location', id]);
  }
}
