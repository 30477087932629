import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AddCompanyLocationModel } from '../models/company-location/addCompanyLocationModel';
import { CompanyLocationViewModel } from '../models/company-location/companyLocationViewModel';
import { EditCompanyLocationModel } from '../models/company-location/editCompanyLocationModel';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyLocationService {

  constructor(private http: HttpClient) { }
  public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
    return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/companylocation/query`, dataTablesParameters, {});
  }

  public post(model: AddCompanyLocationModel): Observable<string> {
    return this.http.post<string>(`${environment.apiBaseUrl}/companylocation/post`, model, {});
  }

  public get(id: number): Observable<CompanyLocationViewModel> {
    return this.http.get<CompanyLocationViewModel>(`${environment.apiBaseUrl}/companylocation/${id}`);
  }
  public getAll(): Observable<CompanyLocationViewModel[]> {
    return this.http.get<CompanyLocationViewModel[]>(`${environment.apiBaseUrl}/companylocation/get`);
  }
  public put(model: EditCompanyLocationModel): Observable<CompanyLocationViewModel> {
    return this.http.put<CompanyLocationViewModel>(`${environment.apiBaseUrl}/companylocation/put`, model, {});
  }
  public delete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/companylocation/delete?ids=${ids.join('&ids=')}`);
  }
  public undelete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/companylocation/undeleted?ids=${ids.join('&ids=')}`);
  }
}
