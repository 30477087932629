export enum CaseSearchOptions {
        SubjectName = 1,
        CaseNumber = 2,
        ClaimNumber = 3, 
        DOL = 4,
        Insured = 5,
        InsuredPolicy = 6,
        SIUNumber=7,
        AssignedUserAll=8,
        AssignedUserPrimary=9,
        PrimaryClientContact=10,
        PrimaryClientContactTag=11,
        PrimaryInvestigatorName=12,
        PrimaryInvestigatorTag=13,
        PrimaryCaseManagerName=14,
        PrimaryCaseManagerTag=15,
        BudgetAddedBy=16,
        CaseLocation=17,
        ClientName=18,
        ClientLocationName=19,
        ClientState=20,
       // ClientTag=21,
        InstructionNotes=22,
        ReferralSource=23,
        SchedulingNotes=24
    
}

export enum CaseFilterByOptions {
    None = 0,
    Pastdue=1,
    Duetoday=2,
    Duetomorrow=3,
    Duenextseven=4,
    Duefuture=5,
    Noduedate=6,
    Reopenedcases=7,
    Unsavedcases=8,
    Unassignedinvestigator=9,
    Unassignedcasemanager=10,
    Unassignedsalesperson=11,
    Withvendorassigned=12,
    Unbilledexpense=13,
    Uninvoiced=14,
    Deletedcases=15,
    Casemanager=16
   
}