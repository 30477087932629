import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { fromEventPattern } from 'rxjs';
import { AppUtils } from 'src/app/helpers';
import { ComponentDestoryHelper } from 'src/app/helpers/component-destory.helper';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { CaseService } from 'src/app/services/case.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-add-case-third',
  templateUrl: './add-case-third.component.html',
  styleUrls: ['./add-case-third.component.css']
})
export class AddCaseThirdComponent extends ComponentDestoryHelper  implements OnInit, OnDestroy {
  @BlockUI('add-case-supporting-files') blockUI: NgBlockUI;
  myFiles: File[] = [];
  sMsg = '';
  accessGroupSelectedListItem: SelectListItem<number>[];
  submitted = false;
  form: FormGroup;
  caseTypeId = 0;
  caseId = 0;
  constructor(private appUtils: AppUtils, private route: ActivatedRoute,  private accessGroupService: AccessGroupService,
              private router: Router, private toasterService: ToasterService, private formBuilder: FormBuilder,
              private caseService: CaseService) {
    super();
    this.route.queryParams.subscribe((parmas) => {
      this.caseTypeId = parmas.caseTypeId;
      this.caseId = parmas.caseId;
    });
    this.accessGroupSelectedListItem = [];
   }
  ngOnDestroy(): void {
    this.ngAfterOnDestroy();
  }

  ngOnInit(): void {
    this.getAccessGroups();
    this.form = this.formBuilder.group({
      accessGroupId: new FormControl(null, Validators.required),
      supportingFiles: new FormArray([])
    });
  }

  getAccessGroups(): void{
    this.blockUI.start();
    const subscription =  this.accessGroupService.getAccessGroups().subscribe((accessGroupsData) => {
      if (accessGroupsData){
      this.accessGroupSelectedListItem = accessGroupsData;
      }
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    },
    () => {
      this.blockUI.stop();
    });
    this.subscriptions.push(subscription);
  }
  removefile(index: number): void{
    this.myFiles.splice(index, 1);
  }

  getFileDetails(e): void {
    // console.log (e.target.files);
    const files = e.target.files;
    for (let i = 0; i < files.length; i++) {
      const targetFiles = this.myFiles.filter(x => x.name === files[i].name && x.size === files[i].size);
      if (targetFiles.length === 0){
      this.myFiles.push(files[i]);
      }
      else{
        this.toasterService.error('Alreay added!');
      }
    }
    e.target.value = null;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid || this.myFiles.length === 0){
      this.toasterService.error('Please fill all required fields');
      return;
    }
    
    const formData = new FormData();
    formData.append('accessGroupId', this.form.controls.accessGroupId.value.toString());
    formData.append('caseId', this.caseId.toString());
    for (let i = 0; i < this.myFiles.length; i++) {
      formData.append(`files`, this.myFiles[i]);
    }
    this.blockUI.start();
    const subscription = this.caseService.addSupportingFiles(formData)
                            .subscribe((result) => {
                              this.toasterService.success(result);
                              this.NavigateToCaseSummary();
                            }, (error) => {
                              this.toasterService.ProcessErrorResponse(error);
                            }, () => {
                              this.blockUI.stop();
                            });
    this.subscriptions.push(subscription);

  }

  NavigateToCaseSummary(): void{
    this.router.navigate(['/add-case-summary'], {
      queryParams: {
        caseTypeId: this.caseTypeId,
        caseId: this.caseId
      }
    });
  }

}
