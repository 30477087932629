import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-permission',
  templateUrl: './permission.component.html',
  styleUrls: ['./permission.component.css']
})
export class PermissionComponent implements OnInit {

  permissionsToDisplay = [];
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService,
    private toasterService: ToasterService) {
  }

  ngOnInit(): void {
    this.loadUserPermisisons();
  }

  private loadUserPermisisons(): void {
    this.blockUI.start();
    this.authService.permissions().subscribe((data) => {
      Object.entries(data).forEach(
        ([key, values], index) => {
          this.permissionsToDisplay.push({
            id: index,
            permissionCategory: key,
            permissions: (values as Array<any>).map(x => ({
              permission: x.permission,
              hasPermission: x.hasPermission,
              name: x.name
            }))
          });
        });
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate(['/']);
      this.blockUI.stop();
    }, () => {
    });

  }

  private gerPermmissionsArray(values: Array<any>): Array<any> {
    return values.map(x => ({
      permission: x.permission,
      hasPermisison: x.hasPermisison,
      name: x.name
    }));

  }

}
