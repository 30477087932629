import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddReferralSourceModel } from '../models/referral-source/addReferralSourceModel';
import { EditReferralSourceModel } from '../models/referral-source/editReferralSourceModel';
import { ReferralSourceViewModel } from '../models/referral-source/referralSourceViewModel';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';

@Injectable({
  providedIn: 'root'
})
export class ReferralsourceService {

  constructor(private http: HttpClient) { }
  public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
    return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/referralsource/query`, dataTablesParameters, {});
  }

  public post(model: AddReferralSourceModel): Observable<string> {
    return this.http.post<string>(`${environment.apiBaseUrl}/referralsource/post`, model, {});
  }

  public get(id: number): Observable<ReferralSourceViewModel> {
    return this.http.get<ReferralSourceViewModel>(`${environment.apiBaseUrl}/referralsource/${id}`);
  }
  public getAll(): Observable<ReferralSourceViewModel[]> {
    return this.http.get<ReferralSourceViewModel[]>(`${environment.apiBaseUrl}/referralsource/get`);
  }
  public put(model: EditReferralSourceModel): Observable<ReferralSourceViewModel> {
    return this.http.put<ReferralSourceViewModel>(`${environment.apiBaseUrl}/referralsource/put`, model, {});
  }
  public delete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/referralsource/delete?ids=${ids.join('&ids=')}`);
  }
  public undelete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/referralsource/undeleted?ids=${ids.join('&ids=')}`);
  }

}
