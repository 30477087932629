export class StaffDtColumnName {
    public static readonly accountCode = 'accountCode';
    public static readonly address1 = 'address1';
    public static readonly address2 = 'address2';
    public static readonly address3 = 'address3';
    public static readonly avgRating = 'avgRating';
    public static readonly city = 'city';
    public static readonly contactNumber = 'contactNumber';
    public static readonly country = 'country';
    public static readonly created = 'createdDate';
    public static readonly dob = 'dob';
    public static readonly email = 'email';
    public static readonly fax = 'fax';
    public static readonly fullAddress = 'fullAddress';
    public static readonly homePhone = 'homePhone';
    public static readonly id = 'id';
    public static readonly isActiveLogin = 'isActiveLogin';
    public static readonly isActive = 'isActive';
    public static readonly lastLoggedOn = 'lastLoggedOn';
    public static readonly lastPasswordChange = 'lastPasswordChange';
    public static readonly manager = 'managerName';
    public static readonly StaffName = 'name';
    public static readonly role = 'role';
    public static readonly state = 'state';
    public static readonly title = 'title';
    public static readonly zip = 'zip';
}
