import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-general-finance-setting',
  templateUrl: './general-finance-setting.component.html',
  styleUrls: ['./general-finance-setting.component.css']
})
export class GeneralFinanceSettingComponent implements OnInit {



  constructor(private formBuilder: FormBuilder) { }

  form: FormGroup;
  submitted = false;
  isSelected = false;

  users = [{
    id: 1,
    name: 'Paul'
  }];

  controls = this.users.map(c => new FormControl(false));
  ngOnInit(): void {
    this.form = new FormGroup({
      expense_entry: new FormControl(),
      overtime_calculation: new FormControl(),
      customRadio_over1: new FormControl(),
      customRadio_over2: new FormControl(),
      overtimeRate: new FormControl(),
      overtimeThreshold: new FormControl(),
      paymentSlip: new FormControl(),
      billingAddress: new FormControl(),
      defaultCurrency: new FormControl(),
      defaultInvoice: new FormControl(),
      // paymentSlipNumber: new FormControl(),
      defaultNotes: new FormControl(),
      defaultInvoiceNotes: new FormControl(),
      includeInternalSwitch1: new FormControl(),
      includeInternalSwitch2: new FormControl(),
      invoiceNumberFormat: new FormControl(),
      lineDateFormat: new FormControl(),
      lineItemStaff: new FormControl(),

      showItemCodeSwitch1: new FormControl(),
      showItemCodeSwitch2: new FormControl(),

      shoWLineItemSwitch1: new FormControl(),
      shoWLineItemSwitch2: new FormControl(),

      showPrimarySubjectSwitch1: new FormControl(),
      showPrimarySubjectSwitch2: new FormControl(),
      retainerNumberFormat: new FormControl(),

      alwaysSalespersonSwitch1: new FormControl(),
      alwaysSalespersonSwitch2: new FormControl(),


      orders: new FormArray(this.controls),

    });
  }

  onSubmit() {

  }


}
