import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { CaseTypeAddModel } from '../models/casetypes/caseTypeAddModel';
import { CaseTypeViewModel } from '../models/casetypes/caseTypeViewModel';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';
import { SelectListItem } from '../models/shared/select.list.item';

@Injectable({
  providedIn: 'root',
})
export class CasetypeService {
  constructor(private http: HttpClient) {}

  public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
    return this.http.post<DataTableResponseModel>(
      `${environment.apiBaseUrl}/CaseType/query`,
      dataTablesParameters,
      {}
    );
  }
  public get(): Observable<CaseTypeViewModel[]> {
    return this.http.get<CaseTypeViewModel[]>(
      `${environment.apiBaseUrl}/CaseType/get`
    );
  }
  public getCaseTypes(): Observable<CaseTypeViewModel[]> {
    return this.http.get<CaseTypeViewModel[]>(
      `${environment.apiBaseUrl}/CaseType/get-case-types`
    );
  }
  public getSelectListItem(): Observable<SelectListItem<number>[]>{
    return this.http.get<SelectListItem<number>[]>(`${environment.apiBaseUrl}/CaseType/get-selelecte-item`);
  }

  public getById(id: number): Observable<CaseTypeViewModel> {
    return this.http.get<CaseTypeViewModel>(
      `${environment.apiBaseUrl}/CaseType/${id}`
    );
  }
  public post(model: CaseTypeAddModel): Observable<string> {
    return this.http.post<string>(
      `${environment.apiBaseUrl}/CaseType/post`,
      model,
      {}
    );
  }
  public put(model: any): Observable<string> {
    return this.http.put<string>(
      `${environment.apiBaseUrl}/CaseType/put`,
      model,
      {}
    );
  }
  public delete(ids: number[]): Observable<any> {
    return this.http.delete(
      `${environment.apiBaseUrl}/CaseType/delete?ids=${ids.join('&ids=')}`
    );
  }

  public undelete(ids: number[]): Observable<any> {
    return this.http.delete(
      `${environment.apiBaseUrl}/CaseType/undeleted?ids=${ids.join('&ids=')}`
    );
  }
}
