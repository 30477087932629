import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoteFor } from 'src/app/helpers/enums/notes/noteFor';
import { ClientService } from 'src/app/services/client.service';


@Component({
  selector: 'app-client-new-note',
  templateUrl: './client-new-note.component.html',
  styleUrls: ['./client-new-note.component.css']
})
export class ClientNewNoteComponent implements OnInit, OnDestroy {

  clientId: string;
  moduleId: number;
  clientSubscription: Subscription;
  breadCrumbInputs: { key: string, value: string[] }[] = [];

  constructor(private route: ActivatedRoute,
              private clientService: ClientService) {
    this.route.params.subscribe(param => this.clientId = param.id);
    this.moduleId = NoteFor.Client;
    this.clientSubscription = this.clientService.clientListDetail$.subscribe((data) => {
      if (data == null || data === undefined) {
        return;
      }
      this.breadCrumbInputs.push({ key: 'id', value: [this.clientId, data.name] });
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.clientSubscription.unsubscribe();
  }

}
