import { AddOrEditCustomFieldSubjectTypeInCaseViewModel } from '../custom-field-subject-type-in-case/addOrEditCustomFieldSubjectTypeInCaseViewModel';
import { SubjectTypeInCaseConnectionModel } from '../subject-type-in-case-connection/subjectTypeInCaseConnectionModel';

export class AddOrEditSubjectTypeInCaseModel {
    public id: number;
    public isPinned: boolean;
    public isPrimary: boolean;
    public caseId: number;
    public subjectTypeId: number;
    public photoUrl: File;
    public displayValue: string;
    public customFieldSubjectTypeInCases: AddOrEditCustomFieldSubjectTypeInCaseViewModel[];
    public subjectTypeInCaseConnections: SubjectTypeInCaseConnectionModel[];

}
