import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';
import { CompanyPolicyService } from 'src/app/services/company-policy.service';
import { ToasterService } from 'src/app/services/toater.service';
import { CompanyPolicyVersionHistoryService } from 'src/app/services/companypolicy.versionhistory.service';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

@Component({
  selector: 'app-company-policy-overview',
  templateUrl: './company-policy-overview.component.html',
  styleUrls: ['./company-policy-overview.component.css'],
})
export class CompanyPolicyOverviewComponent implements OnInit, OnDestroy {
  constructor(
    private router: Router,
    private companyPolicyVersionHistory: CompanyPolicyVersionHistoryService,
    private toasterService: ToasterService,
    private route: ActivatedRoute,
    private companypolicyService: CompanyPolicyService
  ) {
    this.route.params.subscribe((params) => (this.id = Number(params.id)));
    this.subscriptions = [];
  }
  @BlockUI('container-company-policy-overview') blockUI: NgBlockUI;
  selectedTags = [];
  active = 1;
  tabDetails = [];
  currentPath: string;
  activeTab = 1;
  subscriptions: Subscription[];
  policyName: string;
  breadCrumbInputs: { key: string; value: string[] }[] = [];
  loadBreadCrumb = false;
  id: number;
  permissionType = Permission;
  hasPermissionType = HasPermission;
  policyId: number;

  name = 'Angular';

  ngOnInit(): void {
    // this.loadDefaultData();
    this.tabDetails = [
      { id: 1, title: 'Overview', path: 'overview' },
      { id: 2, title: 'History', path: 'history' },
    ];
    const subscription =
      this.companyPolicyVersionHistory.policyListDetail$.subscribe((data) => {
        if (data == null || data === undefined) {
          return;
        }
        this.policyName = data.name;
        this.breadCrumbInputs.push(...[
          { key: 'id', value: [data.id.toString(), data.name] },
        ]);
        this.loadBreadCrumb = true;
      });
    this.subscriptions.push(subscription);
  }

  onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.activeTab = changeEvent.nextId;
  }

  componentAdded($evt): void {
    this.currentPath = $evt.route.snapshot.routeConfig.path;
  }
  // sticky money
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (document.getElementById('stickyoney')) {
      if (
        document.body.scrollTop > 132 ||
        document.documentElement.scrollTop > 132
      ) {
        document.getElementById('stickyoney').classList.add('stick_me');
      } else {
        document.getElementById('stickyoney').classList.remove('stick_me');
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.every((subscription: Subscription) =>
      subscription.unsubscribe()
    );
  }

  onNewVersionClick(): void {
    this.companyPolicyVersionHistory.policyDetailSubject$.next({
      id: this.id,
      name: this.policyName,
    });
    this.router.navigate([`setting/role/policy/${this.id}/new-policy-version`]);
  }

  onEditCompanyPolicy(): void {
    this.router.navigate([`/setting/role/edit-company-policy/${this.id}`]);
  }
  onDeleteCompanyPolicy(): void {
    if (
      !confirm(
        'Are you sure you want to delete company policy and its related versions?'
      )
    ) {
      return;
    }
    this.blockUI.start();
    const subscription = this.companypolicyService.delete([this.id]).subscribe(
      (data: any) => {
        this.toasterService.success('Company policy deleted successfully');
        this.router.navigate(['/setting/role/company-policies']);
        this.blockUI.stop();
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
    this.subscriptions.push(subscription);
  }
}
