import { Component, OnInit } from '@angular/core';
import { NoteFor } from 'src/app/helpers/enums/notes/noteFor';

@Component({
  selector: 'app-new-profile-note',
  templateUrl: './new-note.component.html',
  styleUrls: ['./new-note.component.css']
})
export class NewProfileNoteComponent implements OnInit {

  staffId: string;
  moduleId: number;

  constructor() {
    this.moduleId = NoteFor.Staff;
  }

  ngOnInit(): void {
  }

}
