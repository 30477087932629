import { Injectable } from "@angular/core";
import { of } from "rxjs";
import { Permission } from "./enums/roles/permission";

export class HasPermission {

   private static readonly PERMISSION = 'PERMISSIONS';

   public static validate(permissions: Permission[]): boolean {

      const userPermission = localStorage.getItem(this.PERMISSION);
      if (userPermission == null) {
         return false;
      }

      const userPermissions = JSON.parse(userPermission) as Array<Permission>;
      if (userPermissions == null || userPermissions.length === 0) {
         return false;
      }
      return userPermissions.some(permission => permissions.includes(permission));

   }

   public static storePermissions(permisisons: Permission[]): void {
      localStorage.setItem(this.PERMISSION, JSON.stringify(permisisons));
   }

   public static deletePermissions(): void {
      localStorage.removeItem(this.PERMISSION);
   }

}
