<main class="content py-0">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-md-12 pt-7">
        <div class="w-100 pt-4">
          <div class="row">
            <div class="col-md-12 px-0 pb-3">
              <div class="col-auto mt-n1">
                <app-breadcrumb
                  *ngIf="loadBreadCrumb"
                  [breadCrumbInputs]="breadCrumbInputs"
                >
                </app-breadcrumb>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-auto d-none d-sm-block mb-3">
              <h3 class="page-title"><strong>New Contact</strong></h3>
              <p>Create new contact detail here.</p>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-12 px-0 mb-5">
              <form
                class="new-case-form"
                name="form"
                [formGroup]="form"
                (ngSubmit)="onSubmit()"
              >
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-header pb-0">
                        <div class="user-header mb-4">
                          <h3 class="font-weight-bold mb-0">
                            Basic Information
                          </h3>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="card-text">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-12">
                                  <div
                                    class="uploder d-flex align-items-center"
                                  >
                                    <app-profile-pic-uploader
                                      class="w-100"
                                      [imgSrc]="null"
                                      (profileImgCropped)="
                                        onImageCropped($event)
                                      "
                                    >
                                    </app-profile-pic-uploader>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <div class="input-group position-relative">
                                      <label for="name"
                                        >First Name<sup class="clsStric"
                                          >*</sup
                                        ></label
                                      >

                                      <div
                                        class="input-group position-relative"
                                      >
                                        <input
                                          name="name"
                                          formControlName="firstName"
                                          type="text"
                                          class="form-control mb-1"
                                          placeholder=" First Name"
                                          autocomplete="off"
                                          [ngClass]="{
                                            'is-invalid':
                                              submitted &&
                                              form.controls.firstName.errors
                                          }"
                                        />
                                        <div
                                          *ngIf="
                                            submitted &&
                                            form.controls.firstName.errors
                                          "
                                          class="text-danger d-block w-100"
                                        >
                                          <div
                                            *ngIf="
                                              form.controls.firstName.errors
                                                .required
                                            "
                                          >
                                            First name is required
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="middlename">Middle Name</label>
                                    <input
                                      name="middlename"
                                      type="text"
                                      class="form-control mb-1"
                                      formControlName="middleName"
                                      placeholder="Middle Name"
                                      autocomplete="off"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="lastname"
                                      >Last Name<sup class="clsStric"
                                        >*</sup
                                      ></label
                                    >
                                    <input
                                      name="lastname"
                                      type="text"
                                      class="form-control mb-1"
                                      formControlName="lastName"
                                      placeholder="Last Name"
                                      autocomplete="off"
                                      [ngClass]="{
                                        'is-invalid':
                                          submitted &&
                                          form.controls.lastName.errors
                                      }"
                                    />
                                    <div
                                      *ngIf="
                                        submitted &&
                                        form.controls.lastName.errors
                                      "
                                      class="text-danger"
                                    >
                                      <div
                                        *ngIf="
                                          form.controls.lastName.errors.required
                                        "
                                      >
                                        Last name is required
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="title"
                                      >Title<sup class="clsStric">*</sup></label
                                    >
                                    <input
                                      name="title"
                                      formControlName="title"
                                      type="text"
                                      class="form-control mb-1"
                                      placeholder="Title"
                                      autocomplete="off"
                                      [ngClass]="{
                                        'is-invalid':
                                          submitted &&
                                          form.controls.title.errors
                                      }"
                                    />
                                    <div
                                      *ngIf="
                                        submitted && form.controls.title.errors
                                      "
                                      class="text-danger"
                                    >
                                      <div
                                        *ngIf="
                                          form.controls.title.errors.required
                                        "
                                      >
                                        Title is required
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="role"
                                      >Role<sup class="clsStric">*</sup></label
                                    >
                                    <div class="input-group position-relative">
                                      <select
                                        name="selectrole"
                                        placeholder="Title"
                                        autocomplete="off"
                                        [ngClass]="{
                                          'is-invalid':
                                            submitted &&
                                            form.controls.role.errors
                                        }"
                                        formControlName="role"
                                        class="mb-1"
                                      >
                                        <option
                                          value="{{ list.id }}"
                                          *ngFor="
                                            let list of rolesSelectListItem
                                          "
                                        >
                                          {{ list.value }}
                                        </option>
                                      </select>
                                      <div
                                        class="
                                          input-group-append
                                          position-absolute
                                          append-btn
                                        "
                                      >
                                        <button
                                          class="border-0 bg-transparent"
                                          type="button"
                                        >
                                          <i
                                            class="
                                              text-white
                                              rounded-circle
                                              bg-orange
                                              align-middle
                                            "
                                            data-feather="chevron-down"
                                          ></i>
                                        </button>
                                      </div>
                                      <div
                                        *ngIf="
                                          submitted && form.controls.role.errors
                                        "
                                        class="text-danger d-block w-100"
                                      >
                                        <div
                                          *ngIf="
                                            form.controls.role.errors.required
                                          "
                                        >
                                          Role is required
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="location"
                                      >Location<sup class="clsStric"
                                        >*</sup
                                      ></label
                                    >
                                    <div class="input-group position-relative">
                                      <select
                                        name="selectrole"
                                        [ngClass]="{
                                          'is-invalid':
                                            submitted &&
                                            form.controls.location.errors
                                        }"
                                        formControlName="location"
                                      >
                                        <option
                                          value="{{ list.id }}"
                                          *ngFor="
                                            let list of locationSelectListItem
                                          "
                                        >
                                          {{ list.value }}
                                        </option>
                                      </select>
                                      <div
                                        class="
                                          input-group-append
                                          position-absolute
                                          append-btn
                                        "
                                      >
                                        <button
                                          class="border-0 bg-transparent"
                                          type="button"
                                        >
                                          <i
                                            class="
                                              text-white
                                              rounded-circle
                                              bg-orange
                                              align-middle
                                            "
                                            data-feather="chevron-down"
                                          ></i>
                                        </button>
                                      </div>
                                      <div>
                                        <button
                                          type="button"
                                          (click)="
                                            openAddLocationContactPopup()
                                          "
                                          class="
                                            btn
                                            bg-blue
                                            text-white
                                            px-4
                                            py-2
                                            font-weight-500
                                            ml-2
                                            mt-2
                                          "
                                        >
                                          <i class="fa fa-plus"></i>
                                          Add Location
                                        </button>
                                      </div>
                                      <div
                                        *ngIf="
                                          submitted &&
                                          form.controls.location.errors
                                        "
                                        class="text-danger w-100 d-block"
                                      >
                                        <div
                                          *ngIf="
                                            form.controls.location.errors
                                              .required
                                          "
                                        >
                                          Location is required
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-header pb-0">
                        <div class="user-header mb-4">
                          <h3 class="font-weight-bold mb-0">
                            Contact Information
                          </h3>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="card-text">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="email"
                                      >Email<sup class="clsStric">*</sup></label
                                    >
                                    <input
                                      name="email"
                                      formControlName="email"
                                      type="text"
                                      class="form-control mb-1"
                                      placeholder="Email"
                                      autocomplete="off"
                                      [ngClass]="{
                                        'is-invalid':
                                          submitted &&
                                          form.controls.email.errors
                                      }"
                                    />
                                    <div
                                      *ngIf="
                                        submitted && form.controls.email.errors
                                      "
                                      class="text-danger w-100 d-block"
                                    >
                                      <div
                                        *ngIf="
                                          form.controls.email.errors.required
                                        "
                                      >
                                        Email is required
                                      </div>
                                      <div
                                        *ngIf="
                                          !form.controls.email.errors.required
                                        "
                                      >
                                        Email should be in correct format
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <div class="row">
                                <div class="col-md-6 mb-1">
                                  <div class="form-group has-search">
                                    <label id="Label-16">Fax </label>
                                    <!-- <div class="row form-group">
                                      <div class="col-md-12 mt-2">
                                        <input
                                          name="mobilephone"
                                          type="text"
                                          class="form-control"
                                          formControlName="contactNumber"
                                          class="form-control"
                                          autocomplete="off"
                                          placeholder="Mobile Phone"
                                        />
                                      </div>
                                    </div>

                                    <input
                                      name="homephone"
                                      type="text"
                                      class="form-control mb-3"
                                      formControlName="homePhone"
                                      class="form-control"
                                      autocomplete="off"
                                      placeholder="Home Phone"
                                    />
                                    <br /> -->
                                    <input
                                      name="fax"
                                      type="text"
                                      class="form-control mb-3"
                                      formControlName="fax"
                                      class="form-control"
                                      autocomplete="off"
                                      placeholder="Fax"
                                    />
                                  </div>
                                </div>

                              </div>

                              <!-- new contact number ui -->
                              <app-contact-number-widget [form]="form" [isSubmitted]="submitted"></app-contact-number-widget>
                              <!-- end -->

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-header pb-0">
                        <div class="user-header mb-4">
                          <h3 class="font-weight-bold mb-0">
                            Other Information
                          </h3>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="card-text">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="birthday">Bithday</label>
                                    <div class="input-group">
                                      <input
                                        class="form-control"
                                        placeholder="yyyy-mm-dd"
                                        formControlName="dob"
                                        ngbDatepicker
                                        #d="ngbDatepicker"
                                        [minDate]="{
                                          year: 1920,
                                          month: 1,
                                          day: 1
                                        }"
                                      />
                                      <div class="input-group-append">
                                        <button
                                          class="
                                            btn btn-outline-secondary
                                            calendar
                                          "
                                          (click)="d.toggle()"
                                          type="button"
                                        ></button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="country"
                                      >Country<sup class="clsStric"
                                        >*</sup
                                      ></label
                                    >
                                    <select
                                      formControlName="country"
                                      class="form-control mb-3"
                                      name="country"
                                      id="country"
                                      autocomplete="off"
                                      placeholder="Title"
                                      autocomplete="off"
                                      [ngClass]="{
                                        'is-invalid':
                                          submitted &&
                                          form.controls.country.errors
                                      }"
                                    >
                                      <option
                                        *ngFor="
                                          let country of countrySelectListItem
                                        "
                                        [value]="country.name"
                                      >
                                        {{ country.name }}
                                      </option>
                                    </select>
                                    <div
                                      *ngIf="
                                        submitted &&
                                        form.controls.country.errors
                                      "
                                      class="text-danger d-block w-100"
                                    >
                                      <div
                                        *ngIf="
                                          form.controls.country.errors.required
                                        "
                                      >
                                        Country is required
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="account_code"
                                      >Account Code
                                      <i
                                        class="
                                          align-middle
                                          fas
                                          fa-info-circle
                                          ml-2
                                          text-light-black
                                        "
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        placement="top"
                                        ngbTooltip="An internal customer number or reference ID for this contact."
                                      ></i
                                    ></label>

                                    <input
                                      type="text"
                                      formControlName="accountCode"
                                      class="form-control mb-3"
                                      id="account_code"
                                      name="account_code"
                                      autocomplete="off"
                                      placeholder="Account code"
                                    />
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="salesperson"
                                      >Salesperson
                                      <i
                                        class="
                                          align-middle
                                          fas
                                          fa-info-circle
                                          ml-2
                                          text-light-black
                                        "
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        placement="top"
                                        ngbTooltip="The selected salesperson will be assigned to new cases for this contact."
                                      ></i
                                    ></label>

                                    <select
                                      name="salesperson"
                                      id="salesperson"
                                      class="form-control"
                                      formControlName="salesPerson"
                                    >
                                      <option value="" selected>
                                        Select Person
                                      </option>
                                      <option
                                        value="{{ list.id }}"
                                        *ngFor="
                                          let list of salespersonSelectListItem
                                        "
                                      >
                                        {{ list.value }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="casemanager"
                                      >Case Manager
                                      <i
                                        class="
                                          align-middle
                                          fas
                                          fa-info-circle
                                          ml-2
                                          text-light-black
                                        "
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        placement="top"
                                        ngbTooltip="The selected case manager will be assigned to new cases for this contact."
                                      ></i
                                    ></label>
                                    <select
                                      name="casemanager"
                                      id="casemanager"
                                      class="form-control"
                                      formControlName="caseManager"
                                    >
                                      <option selected value="">
                                        Select Manager
                                      </option>
                                      <option
                                        value="{{ list.id }}"
                                        *ngFor="
                                          let list of managersSelectListItem
                                        "
                                      >
                                        {{ list.value }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="tags"
                                      >Tags
                                      <i
                                        class="
                                          align-middle
                                          fas
                                          fa-info-circle
                                          ml-2
                                          text-light-black
                                        "
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        placement="top"
                                        ngbTooltip="A tag is a keyword or label that categorizes this person with other, similar people."
                                        container="body"
                                      ></i
                                    ></label>
                                    <ng-select
                                      [items]="tags$ | async"
                                      name="car2"
                                      [hideSelected]="true"
                                      multiple="true"
                                      [typeahead]="tagsInput$"
                                      bindLabel="name"
                                      [addTag]="addCustomTag"
                                      class="custom-select mr-0 p-0 m-0"
                                      formControlName="tags"
                                    >
                                    </ng-select>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-header pb-0">
                        <div class="user-header mb-4">
                          <h3 class="font-weight-bold mb-0">
                            Client Contact Notes
                          </h3>
                        </div>
                      </div>
                      <div class="card-body">
                        <div class="card-text">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-6 mb-4">
                                  <label>General Notes:</label>
                                  <textarea
                                    class="form-control p-3"
                                    name="generalNotes"
                                    id="generalNotes"
                                    rows="5"
                                    placeholder="General Notes"
                                    formControlName="generalNotes"
                                  ></textarea>
                                </div>
                                <div class="col-md-6 mb-4">
                                  <label>Admin Notes </label>
                                  <textarea
                                    class="form-control p-3"
                                    name="adminnotes"
                                    id="adminnotes"
                                    rows="5"
                                    placeholder="Admin Notes"
                                    formControlName="adminNotes"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group w-100">
                    <div class="col-lg-12 text-right">
                      <button
                        class="
                          btn
                          font-weight-500
                          px-4
                          py-2
                          bg-white
                          text-dark-blue
                          border-btn
                        "
                        type="button"
                        (click)="onCancel()"
                      >
                        Cancel
                      </button>
                      <button
                        class="
                          btn
                          bg-blue
                          text-white
                          px-4
                          py-2
                          font-weight-500
                          ml-2
                        "
                        type="submit"
                      >
                        Save Contact <span class="fa fa-arrow-right"></span>
                      </button>
                      <!-- {{form.value | json}} -->
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
