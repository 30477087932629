import { Component, OnInit } from '@angular/core';

import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AddOrEditAccessGroupModel } from 'src/app/models/access-groups/add.access-group.model';
import { AddOrEditCaseAssignmentType } from 'src/app/models/case-settings/case-assignment-type/case.assignment.type.edit.model';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { CaseAssignmentTypeService } from 'src/app/services/case.assignment.types.service';
import { RoleService } from 'src/app/services/role.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';

@Component({
  selector: 'app-edit-case-assignment-types',
  templateUrl: './edit-case-assignment-types.component.html',
  styleUrls: ['./edit-case-assignment-types.component.css']
})
export class EditCaseAssignmentTypesComponent implements OnInit {


  @BlockUI('container-blockui-new-access-group') blockUI: NgBlockUI;
  model: AddOrEditCaseAssignmentType;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private caseAssignmentTypeService: CaseAssignmentTypeService,
    private roleService: RoleService) {
    this.model = new AddOrEditCaseAssignmentType();
    this.activeRoute.params.subscribe(params => this.model.id = params.id);
  }

  form: FormGroup;
  submitted = false;
  defaultName: string;

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [''],
      isUpdateNoticesEnabled: false,
      isAssignmentNoticesEnabled: [false],
      isConfirmAssignmentNoticesEnabled: [false],
      isAssignToReopenEnabled: [false]
    });
    this.loadData();
  }

  private loadData(): void {
    this.blockUI.start();
    this.caseAssignmentTypeService.get(this.model.id).subscribe((data) => {
      this.model = data;
      this.form.controls.name.setValue(this.model.name);
      this.defaultName = this.model.defaultName;
      this.form.controls.isUpdateNoticesEnabled.setValue(this.model.isUpdateNoticesEnabled);
      this.form.controls.isAssignmentNoticesEnabled.setValue(this.model.isAssignmentNoticesEnabled);
      this.form.controls.isConfirmAssignmentNoticesEnabled.setValue(this.model.isConfirmAssignmentNoticesEnabled);
      this.form.controls.isAssignToReopenEnabled.setValue(this.model.isAssignToReopenEnabled);
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate(['/setting/cases/assigntype']);
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.name = this.form.controls.name.value;
    this.model.isUpdateNoticesEnabled = this.form.controls.isUpdateNoticesEnabled.value;
    this.model.isAssignmentNoticesEnabled = this.form.controls.isAssignmentNoticesEnabled.value;
    this.model.isConfirmAssignmentNoticesEnabled = this.form.controls.isConfirmAssignmentNoticesEnabled.value;
    this.model.isAssignToReopenEnabled = this.form.controls.isAssignToReopenEnabled.value;

    this.blockUI.start();
    this.caseAssignmentTypeService.put(this.model).subscribe(resposne => {
      setTimeout(() => {
        this.router.navigate(['/setting/cases/assigntype']);
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Case assignment type has been updated successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });

  }

}
