<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>New Tax Rate</strong> </h3>
                        </div>

                        <!-- <div class="col-md-auto fa-client-title">
                            1213
                        </div> -->
                    </div>


                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form"  [formGroup]="form" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
                                          
                                            <div class="card-body">
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="name">Name<sup class="clsStric">*</sup></label>
                                                                    
                                                                    <input type="text" formControlName="name" class="form-control mb-3" id="name" name="name" autocomplete="off">
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="country">Country<sup class="clsStric">*</sup></label>
                                                                    
                                                                    <select class="form-control" formControlName="country">
                                                                        <option *ngFor="let items of orders; let i=index">
                                                                            {{i}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-md-12">

                                                                <div class="card-column">
                                                                    <div class="cardx" >
                                                                     
                                                                      <div class="card-bodyx">
                                                        
                                                                        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-1"   class="da-setting-card">
                                                                            <ngb-panel title="Tax Rate Components" id="ngb-panel-1">
                                                        
                                                                               <ng-template ngbPanelHeader let-opened="opened">
                                                                                    <div class="d-flex align-items-center justify-content-between">
                                                                                      <h5 class="m-0 font-weight-bold">Tax Rate Components </h5>
                                                                                      <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                                                                    </div>
                                                                               </ng-template>
                                                        
                                                                              <ng-template ngbPanelContent >
                                                                                <div class="form-group has-search">
                                                                                    <div formArrayName="tax_array"
                                                                                    *ngFor="let phone of form.get('tax_array').controls; let i = index;">

                                                                                        <div class="row mb-2" [formGroupName]="i">
                                                                                            <div class="col-md-3">
                                                                                                <input type="text" class="form-control  has-search mb" formControlName="tax_compo_name" placeholder="" />
                                                                                            </div>
                                                                                            <div class="col-md-3 my-auto mx-auto" >
                                                                                                <div class="custom-control custom-switch" *ngIf="form.get('tax_array').controls.length > 1">
                                                                                                    <input type="radio" formControlName="customSwitch1" class="custom-control-input" id="customSwitch{{i}}" name="customSwitch1">
                                                                                                    <label class="custom-control-label"  for="customSwitch{{i}}" (click)="enableCompound()"><b>Compound</b>
                                                                                                        <br><Small>(apply to taxed subtotal)</Small></label>
                                                                                                </div>
                                                                                            </div>
                                                                                            <div class="col-md-4">
                                                                                                

                                                                                                <div class="row">
                                                                                                    <div class="col-md-10">
                                                                                                        <input type="number" onkeydown="return event.keyCode !== 69" class="form-control  has-search"  formControlName="tax_compo_percentage" placeholder="" (change)="saveTotal($event)"  />
                                                                                                    </div>
                                                                                                    <div class="col-md-2 my-auto mx-auto">
                                                                                                        <h4 class="pb-0">%</h4>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>

                                                                                            <div class="col-md-2">
                                                                                                <button class="btn del-btn border-0 "><i (click)="removePhone(i)"  class="align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger text-center"></i></button>
                                                                                            </div>
                                                                                        </div>
                                                                                </div>

                                                                                <div class="row justify-content-between mt-3">
                                                                                    <div class="col-md-4">
                                                                                        <button type="button" (click)="addPhone()" class="btn btn-primary"><i class="fas fa-plus"></i> Add Component</button>
                                                                                    </div>
                                                                                    <div class="col-md-6">

                                                                                        <div class="row font-weight-bold">
                                                                                            <div class="col-md-6 font-weight-bold">Tax Rate:</div>

                                                                                            <div class="col-md-6">
                                                                                                <ng-template class="font-weight-bold" [ngIf]="!taxSum"><div>0 %</div></ng-template> 
                                                                                                <p  *ngIf="taxSum" class="font-weight-bold">{{ taxSum }} %</p> 
                                                                                            
                                                                                            </div>


                                                                                            <div class="col-md-6 font-weight-bold" *ngIf="toggleCompound">Effective Tax Rate: </div>

                                                                                            <div class="col-md-6" *ngIf="toggleCompound">
                                                                                                <ng-template [ngIf]="!taxSum"><div>0</div></ng-template> 
                                                                                                <p *ngIf="taxSum"  class="font-weight-bold">{{ taxSum }} %</p> 
                                                                                            
                                                                                            </div>
                                                                                        </div>

                                                                                        
                                                                                    </div>
                                                                                </div>

                                                                         

                                                                                </div>
                                                                                
                                                                           
                                                                              </ng-template>
                                                                            </ngb-panel>
                                                                          
                                                                          </ngb-accordion>
                                                        
                                                                      </div>
                                                                    </div>
                                                                   
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                
                                                              
                                                                    <div class="form-group has-search">
                                                                        <label for="reference_id">Reference ID</label>
                                                                        
                                                                        <input type="text" formControlName="reference_id" class="form-control mb-3" id="reference_id" name="reference_id" autocomplete="off">
                                                                    
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6"></div>
                                                        </div>

                                                   


                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>


                                
                                  
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  [routerLink]="['..']"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="sub">Save Tax Rate <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
