import { Component, OnInit, ViewChild, Renderer2, AfterViewInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

// for datatable
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { ClientService } from 'src/app/services/client.service';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-case-subject-detail-connection',
  templateUrl: './case-subject-detail-connection.component.html',
  styleUrls: ['./case-subject-detail-connection.component.css']
})
export class CaseSubjectDetailConnectionComponent implements OnInit {


  // for angular datatable
  @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  clientId: number;

  constructor(private locationService: LocationService,
    private tosterService: ToasterService,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute) {
    this.route.parent.params.subscribe(param => this.clientId = Number(param.id));
  }

  ngOnInit(): void {
    this.initDataTable();
  }

  // ******  destroy/unsubsribe listening to the delete btn */

  private initDataTable(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },

      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,
      order: [[0, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.clientId = self.clientId;
        self.blockUI.start();
        self.locationService.query(dataTablesParameters)
          .subscribe(resp => {
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            self.router.navigate(['/client']);
            self.blockUI.stop();
          });
      },
      columns: [
        {
          data: 'name',
          title: 'Name',
          render: (data, type, row) => {
            return `<a class='link m-r-10' action-type='link-details'>${row.name}</a>`;
          }
        },
        {
          data: 'contactCount',
          title: 'Contacts',
          orderable: false
        },
        {
          data: 'lastCase',
          title: 'Last Case',
          orderable: false
        },
        {
          data: 'id',
          title: 'Action ',
          orderable: false,
          render: (data, type, row) => {
            return `<div class='fa-edit'>
                <button  class='btn del-btn btn-lg btn-table'  >
                <i action-type='edit' class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
                </button>

                <button  class='btn del-btn btn-lg btn-table'   >
                <i action-type='del-person' class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
                </button>
                </div>
                `;
          }
        },
      ],
      rowCallback: (row, data: any) => {
        const _row = $(row);
        _row.find('[action-type="link-details"]')
          .off('click')
          .on('click', () => {
            self.viewDetail(data.id);
          });
        _row.find('[action-type="edit"]')
          .off('click')
          .on('click', () => {
            self.edit(data.id);
          });
        _row.find('[action-type="del-person"]')
          .off('click')
          .on('click', () => {
            self.delete(data.id, row.firstChild.textContent);
          });
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
    };
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtInstance = dtInstance;
    });
  }

  private viewDetail(id: number): void {
    this.router.navigate(['client/location', id]);
  }

  private delete(id: number, name: string): void {
    const result = confirm("Are you sure you want to delete '" + name + "' Location?");
    if (result === true) {
      this.deleteLocation(id);
    }
  }

  private edit(id: number): void {
    this.locationService.setReturnUrl(this.router.url);
    this.router.navigate(['client/location/edit', id]);
  }

  private deleteLocation(id): void {
    this.locationService.delete(id).subscribe((data: any) => {
      this.dtInstance.ajax.reload(null, false);
      this.tosterService.success(data);
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
    }, () => {
    });
  }

}
