<main class="content py-0">
  <div class="container-fluid p-0">
      <div class="row">
          <div class="col-md-12 pt-7">
              <div class="w-100 pt-4">
                  <div class="row">
                      <div class="col-md-12">
                          <app-breadcrumb></app-breadcrumb>
                      </div>
                  </div>

                  <div class="row mt-4 mb-4">
                      <div class="col-auto d-none d-sm-block">
                          <h3 class="page-title"><strong>New Company Location</strong> </h3>
                      </div>

                      <!-- <div class="col-md-auto fa-client-title">
                          1213
                      </div> -->
                  </div>


                  <div class="row mx-0" *blockUI="'blockui-edit-company-location-ui'">
                      <div class="col-12 px-0 mb-5">
                          <form class="new-case-form" name="form"  [formGroup]="form" >
                              <div class="row">
                                  <div class="col-md-12">
                                      <div class="card" >
                                        
                                          <div class="card-body">
                                            
                                            <div class="card-text">

                                              <div class="row">

                                                  <div class="col-md-12">

                                                      <div class="row">
                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="name">Name<sup class="clsStric">*</sup></label>
                                                                  
                                                                  <input type="text" formControlName="name" class="form-control mb-3" id="name" name="name" autocomplete="off">
                                                                  <div
                                                                  *ngIf="submitted && form.controls.name.errors">
                                                                  <div
                                                                    *ngIf="submitted && form.controls.name.errors.required"
                                                                    class="text-danger"
                                                                  >
                                                                    Name is required
                                                                  </div>
                                                                </div>
                                                              </div>
                                                          </div>

                                                          <div class="col-md-6 mb-2">

                                                              <div class="form-group has-search" *ngIf="!isPrimaryVisible">
                                                                  <label for="make_primary"> Make Primary ?</label>
                                                                  <div>
                                                                      <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                          <input type="radio" id="customRadio3" class="custom-control-input" formControlName="make_primary"  name="make_primary" value="true">
                                                                          <label class="custom-control-label" for="customRadio3">Yes</label>
                                                                      </div>
                                                                      <div class="custom-control custom-radio custom-control-inline">
                                                                          <input type="radio" id="customRadio4" class="custom-control-input" formControlName="make_primary"  name="make_primary" value="false">
                                                                          <label class="custom-control-label" for="customRadio4">No</label>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                              
                                                          </div>
                                                      </div>

                                                      <hr>
                                                      <div class="row">
                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="country">Country<sup class="clsStric">*</sup></label>
                                                                  
                                                                  <select class="form-control" (change)="loadStates($event.target.value)" formControlName="country">
                                                                    <option *ngFor="let country of countrySelectListItem" [value]="country.name">
                                                                          {{country.name}}
                                                                        </option>
                                                                  </select>
                                                                  <div
                                                                  *ngIf="submitted && form.controls.country.errors" class="mt-3">
                                                                  <div
                                                                    *ngIf="submitted && form.controls.country.errors.required"
                                                                    class="text-danger"
                                                                  >
                                                                    Country is required
                                                                  </div>
                                                                </div>
                                                              </div>
                                                          </div>

                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="address_1">Address 1
                                                                      </label>

                                                                      <input type="text" formControlName="address1" class="form-control" placeholder="Address 1" name="address_1" id="address_1">
                                                              </div>
                                                          </div>

                                                        
                                                      </div>

                                                      <div class="row">
                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="address_2">Address 2
                                                                  </label>

                                                                  <input type="text" formControlName="address2" class="form-control" placeholder="Address 2" name="address_2" id="address_2">
                                                              </div>
                                                          </div>

                                                          <div class="col-md-6 mb-2">
                                                              <div class="form-group has-search">
                                                                  <label for="address_3">Address 3</label>
                                                                  <input type="text" formControlName="address3" class="form-control" placeholder="Address 3" name="address_3" id="address_3">
                                                              </div>
                                                          </div>
                                                      </div>


                                                      <div class="row">
                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="city">City
                                                                  </label>

                                                                  <input type="text" formControlName="city" name="city" id="city" class="form-control" placeholder="">
                                                              </div>
                                                          </div>

                                                          <div class="col-md-6 mb-2">
                                                              <div class="form-group has-search">
                                                                  <label for="select_state">State/Province</label>
                                                                  
                                                                  <select name="select_state" id="select_state" type="text" class="form-control mb-3" formControlName="state" placeholder="State" class="form-control" >
                                                                      <option value="" selected="selected">Select State</option>
                                                                      <option *ngFor="let state of stateSelectListItem" [value]="state.name">{{state.name}}</option>
                                                                      </select>
                                                              </div>
                                                          </div>
                                                      </div>


                                                      <div class="row">
                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="zip_code">Zip/Postal Code
                                                                  </label>

                                                                  <input type="text" formControlName="zip_code" class="form-control" placeholder="" id="zip_code" name="zip_code">
                                                              </div>
                                                          </div>

                                                          <div class="col-md-6 mb-2">
                                                              
                                                          </div>
                                                      </div>

                                                      <hr>

                                                      <div class="row  mt-2">
                                                          <div class="col-md-6 mb-2">
                                                              <div class="form-group has-search">
                                                                  <label id="Label-16">Phone </label>
                                                                  <input name="primaryPhone" appPhoneMask maxlength="14" type="text" class="form-control mb-3" formControlName="primaryPhone" class="form-control" autocomplete="off"   placeholder="Primary Phone"/>
                                                                  <br>
                                                                  <input name="secondaryPhone" appPhoneMask maxlength="14" type="text" class="form-control mb-3" formControlName="secondaryPhone" class="form-control" autocomplete="off"   placeholder="Secondary Phone"/>
                                                                  <br>
                                                                  <input name="fax" type="text" appPhoneMask maxlength="14" class="form-control mb-3" formControlName="fax" class="form-control" autocomplete="off"   placeholder="Fax"/>
                                                              </div>
                                                          </div>
                                                          <div class="col-md-6 mb-2">
                                                              <div class="form-group has-search">
                                                                  <label id="Label-16">Billing Address/Info </label>
                                                                  <br>
      <textarea class="form-control p-3" name="billing_address" id="billing_address" rows="7" formControlName="billing_address" placeholder="The billing address and other related info that you want to appear on invoices. Leave blank to use the default billing info."></textarea>

                                                                   </div>
                                                          </div>
                                                      </div>
                                                     
                                                  </div>
                                              </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>


                              
                                
                                  <div class="form-group w-100">
                                      <div class="col-lg-12 text-right">
                                          <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  (click)="onCancel()"> Cancel</button> 
                                          <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="submit" (click)="onSubmit()">Save Location <span class="fa fa-arrow-right"></span></button> 
                                      </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</main>
