import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddCompanyPolicyVersionHistoryModel } from '../models/company-policy-version-history/addCompanyPolicyVersionHistoryModel';
import { CompanyPolicyVersionHistoryViewModel } from '../models/company-policy-version-history/companyPolicyVersionHistoryViewModel';
import { EditCompanyPolicyVersionHistoryModel } from '../models/company-policy-version-history/editCompanyPolicyVersionHistoryModel';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyPolicyVersionHistoryService {
  public policyDetailSubject$ = new BehaviorSubject<{ id: number, name: string }>(null);
  public policyListDetail$ = this.policyDetailSubject$.asObservable();
  constructor(private http: HttpClient) { }

  public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
    return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/companypolicyversionhistory/query`, dataTablesParameters, {});
  }

  public post(model: AddCompanyPolicyVersionHistoryModel): Observable<string> {
    const formData = new FormData();
    formData.append('id', JSON.stringify(model.id));
    formData.append('companyPolicyId', JSON.stringify(model.companyPolicyId));
    formData.append('isCurrentVersion', JSON.stringify(model.isCurrentVersion));
    formData.append('versionNotes', model.versionNotes ? model.versionNotes : '');
    formData.append('version', model.version);

    if (model.versionFile != null && model.versionFile !== undefined) {
        formData.append('versionFile', model.versionFile, model.versionFile.name);
    }
    return this.http.post<string>(`${environment.apiBaseUrl}/companypolicyversionhistory/post`, formData, {});
  }

  public get(id: number): Observable<CompanyPolicyVersionHistoryViewModel> {
    return this.http.get<CompanyPolicyVersionHistoryViewModel>(`${environment.apiBaseUrl}/companypolicyversionhistory/${id}`);
  }
  public getAll(): Observable<CompanyPolicyVersionHistoryViewModel[]> {
    return this.http.get<CompanyPolicyVersionHistoryViewModel[]>(`${environment.apiBaseUrl}/companypolicyversionhistory/get`);
  }
  public put(model: EditCompanyPolicyVersionHistoryModel): Observable<CompanyPolicyVersionHistoryViewModel> {
    const formData = new FormData();
    formData.append('id', JSON.stringify(model.id));
    formData.append('companyPolicyId', JSON.stringify(model.companyPolicyId));
    formData.append('isCurrentVersion', JSON.stringify(model.isCurrentVersion));
    formData.append('versionNotes', model.versionNotes);
    formData.append('version', model.version);

    if (model.versionFile != null && model.versionFile !== undefined) {
        formData.append('versionFile', model.versionFile, model.versionFile.name);
    }
    return this.http.put<CompanyPolicyVersionHistoryViewModel>(`${environment.apiBaseUrl}/companypolicyversionhistory/put`, formData, {});
  }
  public delete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/companypolicyversionhistory/delete?ids=${ids.join('&ids=')}`);
  }
  public undelete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/companypolicyversionhistory/undeleted?ids=${ids.join('&ids=')}`);
  }
  public downloadAttachment(id: number): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/companypolicyversionhistory/downloadAttachment/${id}`, { responseType: 'blob' });
}

}
