import { ContatcNoInEntity } from "../shared/contactNoInEntity";

export class LocationAddModel {
    public id: number;
    public name: string;
    public isPrimary: boolean;
    public country: string;
    public address1: string;
    public address2: string;
    public address3: string;
    public city: string;
    public state: string;
    public zipCode: string;
    public primaryPhone: string;
    public secondaryPhone: string;
    public fax: string;
    public salesPersonId: string;
    public caseManagerId: string;
    public billingEmail: string;
    public generalNotes: string;
    public casePolicies: string;
    public updatePolicies: string;
    public invoicePolicies: string;
    public clientId: number;
    public clientName: string;
    public contactNos: ContatcNoInEntity<number>[] = [];
}
