import { DatePipe } from '@angular/common';
import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StaffLicenseDtColumnName } from 'src/app/helpers/constants/staff/staff.licenses.column.name';
import { Page } from 'src/app/helpers/enums/customize-column';
import { StaffLicenseFilterByOptions, StaffLicenseSearchOptions } from 'src/app/helpers/enums/filters-and-searchs/staff.license.search.and.filter.options';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';
import { DateFormatPipe } from 'src/app/helpers/pipes/date-format.pipe';
import { StaffLicenseRenewModel } from 'src/app/models/staff-license/staff.license.renew.model';
import { ModalService } from 'src/app/services/modal.service';
import { StaffLicenseService } from 'src/app/services/staff.license.service';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
declare const exportNav: any;

@Component({
    selector: 'app-licenses',
    templateUrl: './licenses.component.html',
    styleUrls: ['./licenses.component.css']
})

export class LicensesComponent implements OnInit, AfterViewInit {
    staffId: string;
    constructor(
        private licenseService: StaffLicenseService,
        private tosterService: ToasterService,
        private router: Router,
        private route: ActivatedRoute,
        private renderer: Renderer2,
        private modalService: ModalService,
        private datePipe: DatePipe) {
    }


    @BlockUI() blockUI: NgBlockUI;
    @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
    dtOptions: ADTSettings = {};
    dtInstance: DataTables.Api;
    rowIndex = 0;
    pipeInstance: any;
    private searchBy?: StaffLicenseSearchOptions;
    private searchFor: string;
    filterBy?: StaffLicenseFilterByOptions = null;
    filterByValue?: string = null;
    isBulkOperationButtonDisabled = true;
    isDtVisible = false;
    isDtInitialized = false;
    visibleColumns = [];
    // capture filter open and close events
    filterNavStatus = false;
    permissionType = Permission;

    get staffLicenseFilterOptions(): any {
        return StaffLicenseFilterByOptions;
    }

    ngOnInit(): void {
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },

            displayStart: 0,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[1, 'asc']],
            ajax: (dataTablesParameters: any, callback) => {
                self.blockUI.start();
                dataTablesParameters.filterBy = self.filterBy;
                dataTablesParameters.filterByValue = self.filterByValue;
                dataTablesParameters.searchBy = self.searchBy;
                dataTablesParameters.searchFor = self.searchFor;
                dataTablesParameters.isDtInitialized = self.isDtInitialized;
                self.licenseService.query(dataTablesParameters)
                    .subscribe(resp => {
                        this.visibleColumns = resp.visibleColumns;
                        self.dtInstance.page.len(resp.pageLength);
                        self.isDtInitialized = true;
                        self.showColumns();
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                        this.toggleControls();
                        self.blockUI.stop();
                    }, error => {
                        self.tosterService.ProcessErrorResponse(error);
                        self.blockUI.stop();
                    });
            },

            columns: [
                {
                    data: null,
                    title: `<input type="checkbox" action-type="select-unSelect-all"/>`,
                    name: 'select',
                    orderable: false,
                    render: (data, type, row) => {
                        return `<input type='checkbox' value = '${row.id}' action-type= "select-row" />`;
                    }
                },
                {
                    data: StaffLicenseDtColumnName.licenseName,
                    name: StaffLicenseDtColumnName.licenseName.toLocaleLowerCase(),
                    title: 'Name',
                    render: (data, type, row) => {
                        const hrml = this.filterBy !== StaffLicenseFilterByOptions.DeletedLicenses
                            && HasPermission.validate([Permission.ViewStaffLicenses]) ?
                            `<a  detail-person-id="${row[StaffLicenseDtColumnName.id]}" class='link m-r-10' action-type='staff-license-detail'>${row[StaffLicenseDtColumnName.licenseName]}</a>`
                            : `${row.name}`;
                        return hrml;
                    }
                },
                {
                    data: StaffLicenseDtColumnName.staff,
                    name: StaffLicenseDtColumnName.staff.toLocaleLowerCase(),
                    title: 'Staff'
                },
                {
                    data: StaffLicenseDtColumnName.license,
                    name: StaffLicenseDtColumnName.license.toLocaleLowerCase(),
                    title: 'License'
                },
                {
                    data: StaffLicenseDtColumnName.state,
                    name: StaffLicenseDtColumnName.state.toLocaleLowerCase(),
                    title: 'State/Provience'
                },
                {
                    data: StaffLicenseDtColumnName.country,
                    name: StaffLicenseDtColumnName.country.toLocaleLowerCase(),
                    title: 'Country'
                },
                {
                    data: StaffLicenseDtColumnName.expiration,
                    name: StaffLicenseDtColumnName.expiration.toLocaleLowerCase(),
                    title: 'Expiration',
                    render: (data, type, row) => {
                        return `<span>${self.datePipe.transform(data, environment.dateTimeFormat.date)}</span>`;
                    }
                },
                {
                    data: StaffLicenseDtColumnName.lastRenewed,
                    name: StaffLicenseDtColumnName.lastRenewed.toLocaleLowerCase(),
                    title: 'Last Renewed',
                    render: (data, type, row) => {
                        return `<span>${data !== null ? self.datePipe.transform(data, environment.dateTimeFormat.date) : ''}</span>`;
                    }
                },
                {
                    data: StaffLicenseDtColumnName.created,
                    name: StaffLicenseDtColumnName.created.toLocaleLowerCase(),
                    title: 'Created',
                    render: (data, type, row) => {
                        return `<span>${self.datePipe.transform(data, environment.dateTimeFormat.dateTime)}</span>`;
                    }
                },
                {
                    data: null,
                    title: 'Action ',
                    orderable: false,
                    name: 'action',
                    render: (data, type, row) => {
                        const editHtml = HasPermission.validate([Permission.EditStaffLicenses])
                            && this.filterBy !== StaffLicenseFilterByOptions.DeletedLicenses ?
                            `<button  class='btn del-btn btn-lg btn-table'>
                                        <i action-type="edit" class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
                                        </button>` : '';

                        const renewHtml = HasPermission.validate([Permission.ViewStaffLicenses])
                            && this.filterBy !== StaffLicenseFilterByOptions.DeletedLicenses ?
                            ` <button  class='btn del-btn btn-lg btn-table'>
                                    <i action-type="renew"  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-redo-alt fa-1x p-2 text-danger'></i>
                                    </button>` : ``;
                        const deleteHtml = HasPermission.validate([Permission.DeleteStaffLicenses]) ? `<button  class='btn del-btn btn-lg btn-table'>
                          <i action-type="${this.filterBy !== StaffLicenseFilterByOptions.DeletedLicenses ? 'delete' : 'un-delete'}"
                          class='align-middle rounded-circle bg-delete bg-delete-btn fas ${this.filterBy !== StaffLicenseFilterByOptions.DeletedLicenses ? 'fa-trash-alt' : 'fa-trash-restore-alt'}
                           fa-1x p-2 text-danger'></i>
                          </button>` : '';

                        return `<div class='fa-edit'>${editHtml} ${renewHtml} ${deleteHtml}</div>`;
                    }
                },
            ],
            rowCallback: (row, data: any) => {
                const _row = $(row);
                _row.find('[action-type="staff-license-detail"]')
                    .off('click')
                    .on('click', () => {
                        self.router.navigate(['/staff/license/show', data.id]);
                    });
                _row.find('[action-type="renew"]')
                    .off('click')
                    .on('click', () => {
                        self.renewicense(data[StaffLicenseDtColumnName.id], `${data[StaffLicenseDtColumnName.licenseName]} (${data[StaffLicenseDtColumnName.staff]})`, data[StaffLicenseDtColumnName.expiration]);
                    });

                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', () => {
                        self.licenseService.setReturnUrl(self.router.url);
                        self.router.navigate([`/staff/${data[StaffLicenseDtColumnName.staffId]}/license/edit`,
                        data[StaffLicenseDtColumnName.id]]);
                    });
                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', () => {
                        self.deleteLicense([data[StaffLicenseDtColumnName.id]]);
                    });
                _row.find('[action-type="un-delete"]')
                    .off('click')
                    .on('click', () => {
                        self.unDeleteLicense([data[StaffLicenseDtColumnName.id]]);
                    });
                _row.find('[action-type = "select-row"]')
                    .off('click').on('click', (e: any) => {
                        this.onSelectedOrUnselectRow(e);
                    });
            },
            // Use this attribute to enable the responsive extension
            responsive: true,
        };
    }
    // capture filter open and close events
    closeFilterEvent(event: any): void {
        this.filterNavStatus = event;
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            this.dtInstance = dtInstance;
            const header = dtInstance.columns().header();
            $(header).find('[action-type="select-unSelect-all"]')
                .off('change').on('change', (e: any) => {
                    this.onSelectAllRow(e.target.checked);
                });
        });
    }
    applyFilter(filterData: any): void {
        const filterIdArr = filterData.filter?.split('_');
        if (filterIdArr && filterIdArr.length > 0) {
            this.filterBy = Number(filterIdArr[0]);
            if (filterIdArr.length > 1) {
                this.filterByValue = filterIdArr[1];
            }
        }
        this.searchBy = filterData.searchBy;
        this.searchFor = filterData.searchFor;
        this.reload();
    }


    onResetFilters(): void {
        this.filterBy = null;
        this.filterByValue = null;
        this.searchBy = null;
        this.searchFor = '';
        this.reload();
    }
    onBulkDelete(): void {
        this.deleteLicense(this.getSelectedIds());
    }

    onBulkUnDelete(): void {
        this.unDeleteLicense(this.getSelectedIds());
    }

    private deleteLicense(ids: number[]): void {
        if (!confirm('Are you sure you want to delete selected license(s)?')) {
            return;
        }
        this.blockUI.start();
        this.licenseService.delete(ids).subscribe((response) => {
            this.reload();
            this.tosterService.success(response);
            this.blockUI.stop();
        }, error => {
            this.tosterService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }

    private unDeleteLicense(ids: number[]): void {
        if (!confirm('Are you sure you want to undelete selected license(s)?')) {
            return;
        }
        this.blockUI.start();
        this.licenseService.unDelete(ids).subscribe((response) => {
            this.reload();
            this.tosterService.success(response);
            this.blockUI.stop();
        }, error => {
            this.tosterService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }
    private reload(): void {
        this.dtInstance.ajax.reload();
    }

    openCustomizedListModal(): void {
        this.modalService.openModalForConfiguringCustomizedList(Page.StaffLicenseList).subscribe(() => {
            this.isDtVisible = false;
            this.isDtInitialized = false;
            this.dtInstance.ajax.reload(null, false);
            this.tosterService.success('Customized list has been updated successfully');
        });
    }
    private showColumns(): void {
        const visibleColumnsSelector = this.visibleColumns.map(item => `${item.toLowerCase()}:name`)
            .concat(['action:name', 'select:name']);
        const visibleIndexes = this.dtInstance.columns(visibleColumnsSelector).indexes().toArray();
        this.dtInstance.columns().every(function () {
            const colIndex = this.index();
            this.visible(visibleIndexes.includes(colIndex));
        });
        this.isDtVisible = true;
    }

    private renewicense(id: number, modalHeader: string, expiresOn: string): void {
        this.modalService.openModalForRenewStaffLicense(modalHeader, expiresOn)
            .subscribe((model: StaffLicenseRenewModel) => {
                model.id = id;
                this.blockUI.start();
                this.licenseService.patch(model).subscribe((response) => {
                    this.modalService.closeAllModals();
                    this.dtInstance.ajax.reload(null, false);
                    this.tosterService.success(response);
                    this.blockUI.stop();
                });
            });
    }

    onSelectAllRow(isChecked: boolean): void {
        const rows = this.dtInstance.rows().nodes();
        const checkBoxes = [...rows.$('[action-type= "select-row"]')]
            .filter(row => !row.hasAttribute('disabled'));
        if (isChecked) {
            checkBoxes.forEach(row => {
                this.renderer.addClass(row, 'checked');
                (row as HTMLInputElement).checked = true;
            });
        } else {
            checkBoxes.forEach(row => {
                this.renderer.removeClass(row, 'checked');
                (row as HTMLInputElement).checked = false;
            });
        }
        this.enableOrDisableDeleteButton();
    }

    onSelectedOrUnselectRow(event: any): void {
        if (event.target.checked) {
            this.renderer.addClass(event.target, 'checked');
        } else {
            this.renderer.removeClass(event.target, 'checked');
        }
        this.toggleControls();
    }
    private getSelectedIds(): any[] {
        const ids = [];
        const rows = this.dtInstance.rows().nodes();
        [...rows.$('.checked')].forEach(item => ids.push($(item).val()));
        return ids;
    }

    private toggleControls(): void {
        this.setSelectAllCheckbox();
        this.enableOrDisableDeleteButton();
    }

    private setSelectAllCheckbox(): void {
        const rows = this.dtInstance.rows().nodes();
        if (rows !== null) {
            const header = this.dtInstance.columns().header();
            const selectAllElement = $(header).find('[action-type="select-unSelect-all"]')[0] as any;
            const selelectedRows = [...rows.$('[action-type= "select-row"]')]
                .filter(row => !row.hasAttribute('disabled'));
            selectAllElement.checked = selectAllElement != null && selelectedRows.length > 0
                && selelectedRows.every(row => row.classList.contains('checked'));
        }
    }

    private enableOrDisableDeleteButton(): void {
        const rows = this.dtInstance.rows().nodes();
        this.isBulkOperationButtonDisabled = [...rows.$('.checked')].length === 0;
    }
}


