<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>New Invoice Rule</strong> </h3>
                        </div>

                        <!-- <div class="col-md-auto fa-client-title">
                            1213
                        </div> -->
                    </div>



                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form"  [formGroup]="form" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
                                          
                                            <div class="card-body">
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="name">Name<sup class="clsStric">*</sup></label>

                                                                    <input type="text" name="name" class="form-control" autocomplete="off" formControlName="name"  required>
                                                                   
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="row">
                                                                    <div class="col-md-4">

                                                                        <div class="form-group has-search">
                                                                            <label for="condition">Condition<small> The quantity of</small><sup class="clsStric">*</sup></label>
        
                                                                            <div class="input-group position-relative">
                                                                                <select name="condition" formControlName="condition" required>
                                                                                    <option value="Select Role" selected>Select </option>
                                                                                    <option value="{{list.id}}" *ngFor="let list of users">{{list.name}}</option>
                                                                                </select>
        
                                                                                <div class="input-group-append position-absolute append-btn">
                                                                                    <button class="border-0 bg-transparent" type="button">
                                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                           
                                                                        </div>

                                                                    </div>
                                                                    <div class="col-md-3">

                                                                        <div class="form-group has-search">
                                                                            <label for="is">Is<sup class="clsStric">*</sup></label>
        
                                                                            <div class="input-group position-relative">
                                                                                <select name="is" formControlName="is">
                                                                                    <option value="Select Role" selected>Select </option>
                                                                                    <option value="{{list.id}}" *ngFor="let list of compare">{{list.name}}</option>
                                                                                </select>
        
                                                                                <div class="input-group-append position-absolute append-btn">
                                                                                    <button class="border-0 bg-transparent" type="button">
                                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                           
                                                                        </div>
                                                                        
                                                                    </div>
                                                                    <div class="col-md-2">

                                                                        <div class="form-group has-search">
                                                                            <label for="zero"><sup class="clsStric">*</sup></label>
        
                                                                            <input type="text" name="zero" formControlName="zero" class="form-control" value="" placeholder="0">
                                                                            
                                                                          
                                                                        </div>
                                                                        
                                                                    </div>

                                                                    <div class="col-md-3">

                                                                        <div class="form-group has-search">
                                                                            <label for="perday">Day/Invoice<sup class="clsStric">*</sup></label>
        
                                                                            <div class="input-group position-relative">
                                                                                <select name="perday" formControlName="perday">
                                                                                    <option value="Select Role" selected>Select Role</option>
                                                                                    <option value="{{list.id}}" *ngFor="let list of perdaydata">{{list.name}}</option>
                                                                                </select>
        
                                                                                <div class="input-group-append position-absolute append-btn">
                                                                                    <button class="border-0 bg-transparent" type="button">
                                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                            
                                                                           
                                                                        </div>
                                                                        
                                                                    </div>
                                                                </div>

                                                                
                                                            </div>
                                                         
                                                        </div>

                                                    

                                                        <div class="row">
                                                            <div class="col-md-6 mb-4">
                                                                <label>Help Text</label>
                                                                <textarea class="form-control p-3" name="generalNotes" id="generalNotes" rows="5" placeholder="Briefly include an instruction or recommendation on how to handle an invoice that meets the above condition." formControlName="generalNotes"></textarea>
                                                            </div>


                                                            
                                                        </div>
                                                        
                                                        <hr>

                                                        <div class="row">

                                                            <div class="col-md-6 mb-2">
                                                                <label>Validate this rule on invoices for the following case types<sup class="clsStric">*</sup></label>
                                                                <div class="form-check mt-2" formArrayName="validatethis"
                                                                     *ngFor="let limitAccess of users; index as i">
                                                                    <input class="form-check-input" type="checkbox" [formControlName]="i" id="defaultCheck-i">
                                                                    <label class="form-check-label text-capitalize" for="defaultCheck1">
                                                                        {{limitAccess.name}}
                                                                    
                                                                    </label>
                                                                  </div>
                                                                  <small class="mt-2">Invoice rules will also be applied to invoices that are not connected to a case.</small>
                                                                
                                                            </div>

                                                        </div>

                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>


                                
                                  
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  [routerLink]="['..']"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="sub">Save Invoice Rule <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
