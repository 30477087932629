<main class="content py-0">
    <div class="position-fixed menu-btn">
        <span class="curve"></span>
        <a class="sidebar-toggle justify-content-center d-flex rounded-circle bg-orange position-absolute">
            <i class="text-white fas fa-chevron-left align-self-center"></i>
        </a>
    </div>
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-auto d-none d-sm-block">
                            <div class="d-flex mb-2">
                                <h3 class="page-title"><strong>Investigator Snapshot</strong> </h3>
                                <span class="dropdown subject-dropdown show ml-2">
                                    <a class="btn text-orange dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        All Investigators
                                    </a>
                                
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </span>
                            </div>
                            <p>Get all the Staff investigators list info at one place</p>
                        </div>

                        <div class="col-auto ml-auto text-right mt-n1">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                    <li class="filter-item">
                                        <button class="btn btn-lg bg-blue text-white" data-toggle="modal" data-target="#export">
                                            <i class="align-middle fas fa-external-link-alt"></i> Export Data
                                        </button>
                                    </li>
                                    <li class="filter-item px-0 filter-icon" onclick="openNav()">
                                        <button class="btn btn-lg bg-blue text-white">
                                            <i class="align-middle fas fa-filter"></i>
                                        </button>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <!-- Filter right sidenav -->
                        <div id="mySidenav" class="sidenavbar">
                            <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                            <div id="sidenavForm" class="sidenav-form sidenav-bottom-btn d-flex flex-column">
                                <h3 class="text-dark-blue br-bottom-border">Filter Results</h3>
                                <form>
                                    <div class="form-group has-search pt-3 position-relative">
                                        <label for="filterBy">Filter By</label>
                                        <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                                        <div class="input-group position-relative">
                                            <select id="search1">
                                                <option value="" selected>Choose a Filter</option>
                                                <option value="AD">Associates</option>
                                                <option value="BD">Bachelors</option>
                                            </select>
                                            <div class="input-group-append position-absolute append-btn">
                                                <button class="border-0 bg-transparent" type="button">
                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <form>
                                    <div class="form-group has-search">
                                        <label for="In">In</label>
                                        <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                                        <div class="input-group position-relative">
                                            <select id="search2">
                                                <option value="" selected>Name</option>
                                                <option value="AD">Associates</option>
                                                <option value="BD">Bachelors</option>
                                            </select>
                                            <div class="input-group-append position-absolute append-btn">
                                                <button class="border-0 bg-transparent" type="button">
                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container px-0">
                                        <div class="row">
                                            <div class="col-md-12">
                                                <!-- Date Picker Input -->
                                                <div class="form-group mb-4 position-relative">
                                                    <div class="datepicker date input-group p-0 border d-flex flex-column justify-content-center py-1 px-2 mb-0">
                                                        <span class="icon-date position-absolute"><i class="align-middle far fa-calendar-alt mr-2"></i></span>
                                                        <div class="d-flex flex-column justify-content-center align-items-start pl-4">
                                                            <label class="mb-0" for="reservationDate">Form</label>
                                                            <input type="text" class="form-control border-0 p-0" id="reservationDate">
                                                            <div class="input-group-append"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group has-search">
                                        <label for="searchBy">Search by</label>
                                        <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                                        <div class="input-group position-relative">
                                            <select id="search3">
                                                <option value="" selected>Name</option>
                                                <option value="AD">Associates</option>
                                                <option value="BD">Bachelors</option>
                                            </select>
                                            <div class="input-group-append position-absolute append-btn">
                                                <button class="border-0 bg-transparent" type="button">
                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group has-search">
                                        <label for="searchFor">For</label>
                                        <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                                        <div class="input-group position-relative">
                                            <select id="search4">
                                                <option value="" selected>Search</option>
                                                <option value="AD">Associates</option>
                                                <option value="BD">Bachelors</option>
                                            </select>
                                            <div class="input-group-append position-absolute append-btn">
                                                <button class="border-0 bg-transparent" type="button">
                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div class="text-right filter-btn filter-btn-bottom d-flex justify-content-end align-items-end">
                                <ol class="list-unstyled d-flex align-items-center justify-content-end">
                                    <li>
                                        <a href="javascript:void(0);">Reset Filters</a>
                                    </li>
                                    <li>
                                        <button class="btn btn-lg bg-blue text-white ml-2">
                                            <i class="align-middle fas fa-check-circle"></i> Apply Filters
                                        </button>
                                    </li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="case-table table-responsive">
                            <table id="myTable"  class="table table-borderless table-borderless-padding table-condensed">
                                <thead>
                                    <tr>
                                        <th> <label class="customcheckbox m-b-20"> <input type="checkbox" id="mainCheckbox"> <span class="checkmark"></span> </label> </th>
                                        <th scope="col">Name</th>
                                        <th scope="col">Active Cases</th>
                                        <th scope="col">Scheduled Events</th>
                                        <th scope="col">Scheduled Hours</th>
                                        <th scope="col">All-Day Events</th>
                                        <th scope="col">Expensed Hours</th>
                                        <th scope="col">Tasks Due</th>
                                        <th scope="col">Rating</th>
                                    </tr>
                                </thead>
                                <tbody class="customtable">
                                    <tr class="active">
                                        <th> <label class="customcheckbox"> <input type="checkbox" class="listCheckbox"> <span class="checkmark"></span> </label> </th>
                                        <td class="pt-4 position-relative"><i class="indicator bg-success"></i><a class="text-orange" href="#"><img src="img/avatars/avatar.jpg" class="avatar img-fluid rounded-circle mr-2" alt=""> Ahumada, Arturo</a></td>
                                        <td>01</td>
                                        <td>01</td>
                                        <td>01</td>
                                        <td>01</td>
                                        <td>01</td>
                                        <td>01</td>
                                        <td>60%
                                            <div class="starrating risingstar d-flex pt-2">
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th> <label class="customcheckbox"> <input type="checkbox" class="listCheckbox"> <span class="checkmark"></span> </label> </th>
                                        <td class="pt-4 position-relative"><i class="indicator bg-success"></i><a class="text-orange" href="#"><img src="img/avatars/avatar-2.jpg" class="avatar img-fluid rounded-circle mr-2" alt=""> Alva, William</a></td>
                                        <td>05</td>
                                        <td>05</td>
                                        <td>05</td>
                                        <td>05</td>
                                        <td>05</td>
                                        <td>05</td>
                                        <td>60%
                                            <div class="starrating risingstar d-flex pt-2">
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th> <label class="customcheckbox"> <input type="checkbox" class="listCheckbox"> <span class="checkmark"></span> </label> </th>
                                        <td class="pt-4 position-relative"><i class="indicator bg-warning away"></i><a class="text-orange" href="#"><img src="img/avatars/avatar-3.jpg" class="avatar img-fluid rounded-circle mr-2" alt="">Baltierra, Luciano</a></td>
                                        <td>20</td>
                                        <td>20</td>
                                        <td>20</td>
                                        <td>20</td>
                                        <td>20</td>
                                        <td>20</td>
                                        <td>40%
                                            <div class="starrating risingstar d-flex pt-2">
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th> <label class="customcheckbox"> <input type="checkbox" class="listCheckbox"> <span class="checkmark"></span> </label> </th>
                                        <td class="pt-4 position-relative"><i class="indicator bg-danger busy"></i><a class="text-orange" href="#"><img src="img/avatars/avatar-4.jpg" class="avatar img-fluid rounded-circle mr-2" alt=""> Baltierra, Luciano</a></td>
                                        <td>08</td>
                                        <td>08</td>
                                        <td>08</td>
                                        <td>08</td>
                                        <td>08</td>
                                        <td>08</td>
                                        <td>60%
                                            <div class="starrating risingstar d-flex pt-2">
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star"></span>
                                                <span class="fa fa-star"></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th> <label class="customcheckbox"> <input type="checkbox" class="listCheckbox"> <span class="checkmark"></span> </label> </th>
                                        <td class="pt-4 position-relative"><i class="indicator bg-danger busy"></i><a class="text-orange" href="#"><img src="img/avatars/avatar-5.jpg" class="avatar img-fluid rounded-circle mr-2" alt=""> Baltierra, Luciano</a></td>
                                        <td>32</td>
                                        <td>32</td>
                                        <td>32</td>
                                        <td>32</td>
                                        <td>32</td>
                                        <td>32</td>
                                        <td>100%
                                            <div class="starrating risingstar d-flex pt-2">
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th> <label class="customcheckbox"> <input type="checkbox" class="listCheckbox"> <span class="checkmark"></span> </label> </th>
                                        <td class="pt-4 position-relative"><i class="indicator bg-success"></i><a class="text-orange" href="#"><img src="img/avatars/avatar-s-7.png" class="avatar img-fluid rounded-circle mr-2" alt="">Alva, William</a></td>
                                        <td>02</td>
                                        <td>02</td>
                                        <td>02</td>
                                        <td>02</td>
                                        <td>02</td>
                                        <td>02</td>
                                        <td>80%
                                            <div class="starrating risingstar d-flex pt-2">
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star checked"></span>
                                                <span class="fa fa-star"></span>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="row mx-0 py-3">
                                <div class="col-auto pl-0">
                                    <div class="btn-group page-btn page-line-height text-dark-blue">
                                        <a class="mr-2 btn btn-light dropdown-toggle text-dark-blue" data-toggle="dropdown" href="#">1 <span class="caret"></span></a>
                                        <ul class="dropdown-menu">
                                            <li class="dropdown-item"><a href="#">2</a></li>
                                            <li class="dropdown-item"><a href="#">3</a></li>
                                            <li class="dropdown-item"><a href="#">4</a></li>
                                        </ul>
                                        Showing 1 - 10 of 350
                                    </div>
                                </div>
                                <div class="col-auto ml-auto text-right mt-n1 pr-0">
                                    <nav aria-label="Page navigation example">
                                        <ul class="pagination rounded-0">
                                            <li class="page-item disabled">
                                                <a class="page-link border-0 rounded-0" href="#" aria-label="Previous">
                                                <span aria-hidden="true"><i class="align-middle fas fa-chevron-left"></i></span>
                                                <span class="sr-only">Previous</span>
                                                </a>
                                            </li>
                                            <li class="page-item active"><a class="page-link border-0" href="#">1</a></li>
                                            <li class="page-item"><a class="page-link border-0" href="#">2</a></li>
                                            <li class="page-item"><a class="page-link border-0" href="#">3</a></li>
                                            <li class="page-item"><a class="page-link border-0" href="#">4</a></li>
                                            <li class="page-item"><a class="page-link border-0" href="#">5</a></li>
                                            <li class="page-item">
                                                <a class="page-link border-0 rounded-0" href="#" aria-label="Next">
                                                <span aria-hidden="true"><i class="align-middle fas fa-chevron-right"></i></span>
                                                <span class="sr-only">Next</span>
                                                </a>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>

<!-- Export Data Modal -->
<!-- <div class="modal fade" id="export" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog modal-sm modal-dialog-centered" role="document">
        <div class="modal-content">
            <form>
                <div class="modal-body py-5">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group has-search text-center">
                                <i class="align-middle fas fa-file-export text-warning fa-3x mb-4"></i>
                                <img class="mb-4" src="img/icons/rectangle.png" alt="">
                                <h2 class="font-weight-bold mb-4">Are You Sure</h2>
                                <h6 class="detail-client-title black-light-text font-weight-500">Are you sure you want to export the invoice payment list, using the selected criteria? </h6>
                            </div>
                        </div>
                </div>
                <div class="modal-footer border-0">
                    <div class="row w-100 mb-3 align-items-center">
                        <div class="col-md-12 text-center">
                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button" data-dismiss="modal" aria-label="Close">Not, Sure!</button>
                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-3" type="button">Yes, Sure!</button> 
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div> -->
