<div class="container-fluid">
  <div class="row no-gutter">
    <div class="col-md-6 loginleft">
      <div class="login d-flex align-items-center py-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-device col-md-9 col mx-auto">
              <div class="text-center mb-5">
                <img src="../../../assets/img/arguswest-logo.png" width="200" alt="" />
              </div>
<div *blockUI="'blockui-frm-login'">
              <ul class="swiperwrap">
                <li #target2  id="login">
                <div class="card card-device-tab flex-fill border-0 px-4 py-5">
                  <form name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                    <label class="loginLabel" for="userName" >User Name</label>
                    <input name="userName" type="text" class="form-control mb-3" formControlName="userName" class="form-control" autocomplete="off" required/>
                    <div *ngIf="submitted && form.controls.userName.errors" class="invalid-feedback">
                      <div *ngIf="form.controls.userName.errors.required">User Name/Email Id is required</div>
                    </div>
                    <label class="loginLabel" for="password">Password</label>
                    <input type="password" formControlName="password"   class="form-control mb-3" autocomplete="off"/>
                  <div *ngIf="submitted && form.controls.password.errors" class="invalid-feedback">
                    <div *ngIf="form.controls.password.errors.required">Password is required</div>
                  </div>
                  <button  type="submit"  class="login-btn w-100 mb-3" mat-flat-button>Log In</button>
                    <div class="d-flex justify-content-between">
                      <div class="custom-control custom-checkbox mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          name="rememberMe"
                          id="rememberMe"
                        />
                        <label
                          class="custom-control-label inputLabel"
                          for="rememberMe"
                          >Remember Me</label
                        >
                      </div>
                      <a
                        class="primary-text inputLabel"  (click)="scrollToElement(target)"
                        >Forgot Password?</a
                      >
                    </div>
                    <div class="or-container">
                      <div class="line-separator"></div>
                      <div class="or-label">OR</div>
                      <div class="line-separator"></div>
                    </div>
                    <div class="row">
                      <div class="col-md-12">
                        <a
                          class="btn btn-lg btn-google btn-block btn-outline"
                          (click)="signInWithGoogle()"
                          ><img class="mr-2" src="../../../assets/img/icons/google.png" />
                          Login with Google</a
                        >
                      </div>
                    </div>
                  </form>
                </div>
              
              </li>
                <li #target  id="forgot">
                  <div class="card card-device-tab flex-fill border-0 px-4 py-5">
                    <form name="showForgotPasswordMessage" [formGroup]="forgotPasswordForm" (ngSubmit)="onForgotPasswordSubmit()">
                      <h5 class="forgot-pwd">Forgot password?</h5>
                      <label class="loginLabel" for="email">Email</label>
                      <input
                      formControlName="email" 
                        type="email"
                        class="form-control mb-3"
                        required
                      />
                      <div *ngIf="isforgotPasswordFormSubmitted && forgotPasswordForm.controls.email.errors" class="text-danger">
                        <div *ngIf="forgotPasswordForm.controls.email.errors.required">Email Id is required</div>
                        <div *ngIf="forgotPasswordForm.controls.email.errors.email || forgotPasswordForm.controls.email.errors.pattern">Please enetr a valid email</div>
                      </div>
                     
                      <button  type="submit"  class="login-btn w-100 mb-3" mat-flat-button>Forgot</button>
    
    
                      <div class="d-flex justify-content-between">
                        <div class="custom-control custom-checkbox mb-3">
                         
                        </div>
                        <a
                          class="primary-text inputLabel" 
                           (click)="scrollToElement(target2)"
                          >Login?</a
                        >
                      </div>
                      <div class="form-group" *ngIf="showForgotPasswordMessage">
                        <p class="text-success">A link has been sent to your registered email. Please click on the link
                          to proceed with the verification process</p>
                      </div>
                      
                    </form>
                  </div>

                </li>
              </ul>
            </div>
            </div>
          </div>
        </div>
      </div>
      <p class="privacy-text">
        © 2020 CMP, inc. All rights reserved. |
        <a class="text-voilet" routerLink="">Privacy Policy</a>
      </p>
    </div>
    <div class="d-none d-md-flex col-md-6 bg-image px-6">
      <div
        class="content-login-bg text-white d-flex flex-column justify-content-center align-items-start"
      >
        <h1 class="loginTitle text-white font-weight-normal">
          Welcome to <strong>Arguswest</strong> Investigation
        </h1>
        <p class="loginPara mb-5">
          Since 1981, Argus West has been a leading provider of professional investigative services throughout California. While Argus West’s predominant work force are employees in California, additional states are becoming a part of our growth. Our SIU program incorporates annual SIU compliance, anti-fraud plans and reporting in all states.
        </p>
        <a href="https://www.arguswest.com/company-profile/"> <button class="btn btnRead">Read more...</button></a>
      </div>
      <video autoplay="" muted="" loop="" poster="../../../assets/img/arguswest-poster.jpg" id="myVideo">
        <source src="../../../assets/img/argus-west-video.mp4" type="video/mp4">
    </video>
    </div>
  </div>
</div>
