<main class="content pt-7">
    <section>
        <div>
            <p>
               <strong> 403: Access Denied</strong>
            </p>
            <p>
                You don't have permission to access this page.
            </p>
        </div>
    </section>
</main>