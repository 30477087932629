import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { AppUtils } from 'src/app/helpers';
import { CaseTypeFilterOptionsDetail } from 'src/app/helpers/constants/filter-and-search/casetype.search&filters.option';
import { clientListColumnDetails } from 'src/app/helpers/constants/staff/grid.column.detail';
import { FilterBy, FilterInputModel, FilterModel, SearchByModel } from 'src/app/models/shared/filters.model';
import { SearchModel } from 'src/app/models/shared/search.model';
import { StaffService } from 'src/app/services/staff.serrvice';

@Component({
  selector: 'app-case-type-list-filter',
  templateUrl: './case-type-list-filter.component.html',
  styleUrls: ['./case-type-list-filter.component.css']
})
export class CaseTypeListFilterComponent implements OnInit {
  constructor(
    private appUtils: AppUtils,
    private staffService: StaffService) {
    clientListColumnDetails.forEach((key, value) => this.searchBy.push({ id: value, name: key }));
  }



  filterBy: FilterInputModel[] = [];
  filterIn: FilterInputModel[] = [];
  isFilterInVisible = false;

  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  onApplyFilter = new EventEmitter();

  @Output()
  resetFilters = new EventEmitter();

  searchBy: SearchByModel[] = [];

  sendFilterStat: string;

  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.loadFilterOptions();
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onResetFilters(): void {
    this.resetFilters.emit();
  }


  private loadFilterOptions(): void {
    CaseTypeFilterOptionsDetail.forEach((x, y) => {
      this.filterBy.push({ name: x, id: y });
    });

  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }

}
