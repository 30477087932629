import { Component, OnInit, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';

import { NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { param } from 'jquery';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { CaseService } from 'src/app/services/case.service';
import { StaffService } from 'src/app/services/staff.serrvice';
import { CaseBreadCrumb } from 'src/app/helpers/constants/breadcrumbs/case-breadcrumb'; 
@Component({
  selector: 'app-case-detail',
  templateUrl: './case-detail.component.html',
  styleUrls: ['./case-detail.component.css']
})
export class CaseDetailComponent implements OnInit, OnDestroy{

  activeTab = 1;
  tabDetails = [];
  caseSubscription: Subscription;
  Name: string;
  currentPath: string;
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  loadBreadCrumb = false;
  id: string;
  caseId: string;
  casetypeid:string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private caseService: CaseService) {
  }



  ngOnInit(): void {
    this.tabDetails = [
      { id: 1, title: 'Overview', path: 'overview' },
      { id: 2, title: 'Updates', path: 'updates' },
      { id: 3, title: 'Subjects', path: 'subjects' },
      { id: 4, title: 'Files', path: 'file' },
      { id: 5, title: 'Assigned', path: 'assigned' },

      { id: 6, title: 'Reviews', path: 'reviews' },
      { id: 7, title: 'Agenda', path: 'agenda' },
      { id: 8, title: 'Expenses', path: 'expenses' },
      { id: 9, title: 'Billing', path: 'billing' },
      { id: 10, title: 'History', path: 'history' }
    ];
    this.caseSubscription = this.caseService.caseDetai$.subscribe((data) => {
      debugger;
      if (data) {
        this.caseId = data.id;
        this.Name=data.name;
        this.casetypeid=data.casetypeid;
        this.breadCrumbInputs = [{ key: 'id', value: [data?.id, data?.name] }];
        this.loadBreadCrumb = true;
      }
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.activeTab = changeEvent.nextId;
  }

  // sticky money
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (document.getElementById('stickyoney')) {
      if (document.body.scrollTop > 132 ||
        document.documentElement.scrollTop > 132) {
        document.getElementById('stickyoney').classList.add('stick_me');
      } else {
        document.getElementById('stickyoney').classList.remove('stick_me');
      }
    }
  }
  componentAdded($evt): void {
    if ($evt.route) {
      this.currentPath = $evt.route.snapshot.routeConfig.path;
    }
    if (!$evt.route) {

      this.route.params.subscribe((params: Params) => {
        // Do something
        this.currentPath = params.id;
        this.currentPath=params.casetypeid;
        console.log('id',params.id);
        console.log('id',params.casetypeid);
      });
    }
  }

  ngOnDestroy(): void {
    this.caseSubscription.unsubscribe();
  }

}
