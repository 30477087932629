import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';

import { Router } from '@angular/router';

// for datatable
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { ClientService } from 'src/app/services/client.service';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { UserRoleFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/user.roles.filter.options';
import { AccessGroupFilterOptionsDetail } from 'src/app/helpers/constants/filter-and-search/user.roles.and.access-groups.options';
import { AccessGroupFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/access.group.filter.options';

declare const exportNav: any;
@Component({
  selector: 'app-user-access-groups',
  templateUrl: './user-access-groups.component.html',
  styleUrls: ['./user-access-groups.component.css']
})
export class UserAccessGroupsComponent implements OnInit {

  @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;
  isDeleteButtonDisabled = true;
  filterBy: any;

  constructor(
    private clientService: ClientService,
    private tosterService: ToasterService,
    private renderer: Renderer2,
    private router: Router,
    private accessGroupService: AccessGroupService) { }

  get userRoleFilterOptions(): any {
    return UserRoleFilterOptions;
  }

  // capture filter open and close events
  filterNavStatus = false;
  closeFilterEvent(event: any): void {
    this.filterNavStatus = event;
  }

  ngOnInit(): void {
    this.filterBy = UserRoleFilterOptions.None;
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },

      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,
      order: [[1, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {

        self.blockUI.start();
        dataTablesParameters.filterOption = this.filterBy ?? AccessGroupFilterOptions.None;
        self.accessGroupService.query(dataTablesParameters)
          .subscribe(resp => {
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            self.blockUI.stop();
          }, () => { this.toggleControls(); }
          );
      },

      columns: [
        {
          data: null,
          title: `<input type="checkbox" action-type="select-unSelect-all"/>`,
          orderable: false,
          render: (data, type, row) => {
            const disabled = row.isAssociatedWithUsers ? 'disabled' : '';
            return `<input type='checkbox' value = '${row.id}' action-type= "select-row" ${disabled} />`;
          }
        },
        {
          data: 'name',
          title: 'Name',
          render: (data, type, row) => {
            return `<a  detail-person-id="${row.id}" class='link m-r-10' action-type='link-detail'>${row.name}</a>`;
          }
        },
        {
          data: 'description',
          title: 'Description'
        },
        {
          data: 'roles',
          title: 'Included Roles',
          orderable: false,
          render: (data, type, row) => {
            return `${row.roles.join(', ')}`;
          }
        },
        {
          data: 'name',
          title: 'Action ',
          orderable: false,
          render: (data, type, row) => {

            const disabled = '';
            const deleteButton = `<button  class='btn del-btn btn-lg btn-table' action-type="delete" ${disabled}>
            <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
            </button>`;
            const undeleteButton = `<button  class='btn del-btn btn-lg btn-table' action-type="un-delete">
            <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-restore-alt  fa-1x p-2 text-danger'></i>
            </button>`;
            const editButton = this.filterBy !== AccessGroupFilterOptions.DeletedAccessGroups
              ? `<button  class='btn del-btn btn-lg btn-table' alt="undelete">
            <i action-type='edit' class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
            </button>` : '';

            return `<div class='fa-edit'>${editButton} ${this.filterBy !== AccessGroupFilterOptions.DeletedAccessGroups
              ? deleteButton : undeleteButton}
              </div>`;
          }
        },
      ],
      rowCallback: (row, data: any) => {
        const _row = $(row);

        _row.find('[action-type="edit"]')
          .off('click')
          .on('click', () => {
            self.router.navigate(['/setting/accessgroup/edit', data.id]);
          });
        _row.find('[action-type="delete"]')
          .off('click')
          .on('click', () => {
            self.delete([data.id]);
          });
        _row.find('[action-type="un-delete"]')
          .off('click')
          .on('click', () => {
            self.unDelete([data.id]);
          });
        _row.find('[action-type = "select-row"]')
          .off('click').on('click', (e: any) => {
            this.onSelectedOrUnselectRow(e);
          });
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
    };

  }


  reload(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }

  delete(ids: number[]): void {
    if (!confirm('Are you sure you want to delete selected access-group(s)?')) {
      return;
    }
    this.blockUI.start();
    this.accessGroupService.delete(ids).subscribe(() => {
      this.reload();
      this.tosterService.success('Access Group(s) has been deleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  unDelete(ids: number[]): void {
    if (!confirm('Are you sure you want to undelete selected access group(s)?')) {
      return;
    }
    this.blockUI.start();
    this.accessGroupService.undelete(ids).subscribe(() => {
      this.reload();
      this.tosterService.success('Access group(s) has been undeleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtInstance = dtInstance;
      const header = dtInstance.columns().header();
      $(header).find('[action-type="select-unSelect-all"]')
        .off('change').on('change', (e: any) => {
          this.onSelectAllRow(e.target.checked);
        });
    });
  }

  onSelectAllRow(isChecked: boolean): void {
    const rows = this.dtInstance.rows().nodes();
    const checkBoxes = [...rows.$('[action-type= "select-row"]')]
      .filter(row => !row.hasAttribute('disabled'));
    if (isChecked) {
      checkBoxes.forEach(row => {
        this.renderer.addClass(row, 'checked');
        (row as HTMLInputElement).checked = true;
      });
    } else {
      checkBoxes.forEach(row => {
        this.renderer.removeClass(row, 'checked');
        (row as HTMLInputElement).checked = false;
      });
    }
    this.enableOrDisableDeleteButton();
  }

  onSelectedOrUnselectRow(event: any): void {
    if (event.target.checked) {
      this.renderer.addClass(event.target, 'checked');
    } else {
      this.renderer.removeClass(event.target, 'checked');
    }
    this.toggleControls();
  }

  onBulkDelete(): void {
    this.delete(this.getSelectedRoleIs());
  }

  onBulkUnDelete(): void {
    this.unDelete(this.getSelectedRoleIs());
  }

  applyFilter(filterData: any): void {
    this.filterBy = filterData.filter;
    this.dtInstance.ajax.reload();
  }

  onResetFilters(): void {
    this.filterBy = UserRoleFilterOptions.None;
    this.dtInstance.ajax.reload();
  }
  private getSelectedRoleIs(): any[] {
    const roleIds = [];
    const rows = this.dtInstance.rows().nodes();
    [...rows.$('.checked')].forEach(item => roleIds.push($(item).val()));
    return roleIds;
  }

  private toggleControls(): void {
    this.setSelectAllCheckbox();
    this.enableOrDisableDeleteButton();
  }

  private setSelectAllCheckbox(): void {
    const rows = this.dtInstance.rows().nodes();
    if (rows !== null) {
      const header = this.dtInstance.columns().header();
      const selectAllElement = $(header).find('[action-type="select-unSelect-all"]')[0] as any;
      const selelectedRows = [...rows.$('[action-type= "select-row"]')]
        .filter(row => !row.hasAttribute('disabled'));
      selectAllElement.checked = selectAllElement != null && selelectedRows.length > 0
        && selelectedRows.every(row => row.classList.contains('checked'));
    }
  }

  private enableOrDisableDeleteButton(): void {

    const rows = this.dtInstance.rows().nodes();
    this.isDeleteButtonDisabled = [...rows.$('.checked')].length === 0;
  }
}

