import { Component, OnInit } from '@angular/core';

import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ChangePasswordRequestModel } from 'src/app/models/authenticate/change.password.request.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToasterService } from 'src/app/services/toater.service';
import { Match } from 'src/app/validators';


@Component({
  selector: 'app-change-passwod',
  templateUrl: './change-passwod.component.html',
  styleUrls: ['./change-passwod.component.css']
})


export class ChangePasswordComponent implements OnInit {

  showDetails = true;

  chklow = false;
  chkcaps = false;
  chknum = false;
  chklen = false;
  chkSpec = false;
  model: ChangePasswordRequestModel = new ChangePasswordRequestModel();

  @BlockUI() blockUI: NgBlockUI;
  form: FormGroup;

  toggleEye: boolean;
  toggleEye2: boolean;
  toggleEye3: boolean;

  constructor(private formBuilder: FormBuilder,
    private router: Router,
    private toasterService: ToasterService,
    private authenticationService: AuthenticationService) {
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ['', Validators.required]
    }, {
      validator: [
        Match('newPassword', 'confirmPassword')
      ]
    });
  }
  public allow_somespecial_char(e: any) {
    try {
      if (/[a-zA-Z0-9!@#\$%\^&\*\(\)]/g.test(e.key)) {
        return true;
      } else {
        e.preventDefault();
        return false;
      }
    } catch (e) {
    }
  }
  onSubmit(): void {
    // stop here if form is invalid.
    if (this.form.invalid || !(this.chklen && this.chknum && this.chklow && this.chkcaps && this.chkSpec)) {
      return;
    }

    this.model.currentPassword = this.form.controls.currentPassword.value;
    this.model.newPassword = this.form.controls.newPassword.value;
    this.model.confirmPassword = this.form.controls.confirmPassword.value;

    this.blockUI.start();
    this.authenticationService.changePassword(this.model)
      .subscribe(
        () => {
          this.blockUI.stop();
          setTimeout(() => {
            this.router.navigate(['/account/logout']);
            this.toasterService.success('Your password has been changed successfully, Please login again!');
          }, 100);
        },
        error => {
          this.blockUI.stop();
          this.toasterService.ProcessErrorResponse(error);
        });
  }

  onKeyup(event): void {
    // Validate lowercase letters
    const lowerCaseLetters = /[a-z]/g;
    if (this.form.value.newPassword.match(lowerCaseLetters)) {
      this.chklow = true;
    } else {
      this.chklow = false;
    }

    const upperCaseLetters = /[A-Z]/g;
    if (this.form.value.newPassword.match(upperCaseLetters)) {
      this.chkcaps = true;
    } else {
      this.chkcaps = false;
    }

    const num = /[0-9]/g;
    if (this.form.value.newPassword.match(num)) {
      this.chknum = true;
    } else {
      this.chknum = false;
    }

    // check len
    if (this.form.value.newPassword.length >= 12) {
      this.chklen = true;
    } else {
      this.chklen = false;
    }

    const specialChars = /[!@#\$%\^&\*\(\)]/g;
    if (this.form.value.newPassword.match(specialChars)) {
      this.chkSpec = true;
    } else {
      this.chkSpec = false;
    }
  }

  changeEye(): void {
    this.toggleEye = !this.toggleEye;
  }

  changeEye2(): void {
    this.toggleEye2 = !this.toggleEye2;
  }

  changeEye3(): void {
    this.toggleEye3 = !this.toggleEye3;
  }

  get isPasswordValid(): boolean {
    return this.chklen && this.chknum && this.chklow && this.chkcaps && this.chkSpec;
  }
}
