import { debugOutputAstAsTypeScript } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { CountryModel } from 'src/app/models/shared/country.model';
import { StateModel } from 'src/app/models/shared/state.model';
import { StaffLicenseEditModel } from 'src/app/models/staff-license/staff.license.edit.model';
import { CountryService } from 'src/app/services/country.service';
import { StaffLicenseService } from 'src/app/services/staff.license.service';
import { ToasterService } from 'src/app/services/toater.service';

type NewType = FormGroup;

@Component({
  selector: 'app-edit-staff-license',
  templateUrl: './edit-staff-license.component.html',
  styleUrls: ['./edit-staff-license.component.css']
})
export class EditStaffLicenseComponent implements OnInit {

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private licenseService: StaffLicenseService,
    private countryService: CountryService,
    private route: ActivatedRoute) {
    this.route.params.subscribe(params => this.model.id = params.licenseId);
    this.returnUrl = this.licenseService.getReturnUrl();
    if (!this.returnUrl) {
      this.router.navigate(['/licenses']);
    }
  }

  form: NewType;
  submitted = false;
  countrySelectListItem: CountryModel[] = [];
  stateSelectListItem: StateModel[] = [];
  model: StaffLicenseEditModel = new StaffLicenseEditModel();
  hasAttachment = false;
  returnUrl: string;
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  loadBreadCrumb = false;

  @BlockUI() blockUI: NgBlockUI;


  ngOnInit(): void {

    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      expiresOn: [null, [Validators.required]],
      license: [null],
      notes: [null],
      attachment: null,
      state: [null],
      country: [null]

    });

    this.loadData();
  }

  private loadData(): void {
    forkJoin([
      this.countryService.getCountries(),
      this.licenseService.get(this.model.id)
    ]).subscribe(([
      countries,
      model
    ]) => {
      this.breadCrumbInputs.push({
        key: 'licenseId', value: [model.id,
        model.name]
      });
      this.loadBreadCrumb = true;
      this.model = model;
      this.countrySelectListItem = countries;
      this.loadStates(this.model.country);
      this.form.controls.name.setValue(this.model.name);
      this.form.controls.expiresOn.setValue(this.model.expiresOn);
      this.form.controls.license.setValue(this.model.licenseNumber);
      this.form.controls.notes.setValue(this.model.notes);
      this.form.controls.state.setValue(this.model.state);
      this.form.controls.country.setValue(this.model.country);
      this.hasAttachment = this.model.fileName && this.model.fileName.length > 0;

      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate([this.returnUrl]);
      this.blockUI.stop();
    });
  }

  public loadStates(countryName: any): void {
    if (countryName === null || countryName === undefined || countryName.length === 0) {
      return;
    }
    const country = this.countrySelectListItem.find(x => x.name === countryName);
    if (!country) {
      return;
    }

    this.blockUI.start();
    this.countryService.getStates(country.id)
      .subscribe((data: any) => {
        this.stateSelectListItem = data;
      }, error => {
        this.toasterService.ProcessErrorResponse(error);
      }, () => {
        this.blockUI.stop();
      });

  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    const formData = new FormData();
    formData.append('id', JSON.stringify(this.model.id));
    formData.append('name', this.form.controls.name.value);
    formData.append('licenseNumber', this.form.controls.license.value);
    formData.append('country', this.form.controls.country.value);
    formData.append('state', this.form.controls.state.value);
    formData.append('expiresOn', this.form.controls.expiresOn.value);
    formData.append('notes', this.form.controls.notes.value);
    formData.append('isFileRemoved', JSON.stringify(this.model.isFileRemoved));
    formData.append('staffId', this.model.staffId);
    const attachment = this.form.controls.attachment.value;

    if (attachment !== null && attachment !== undefined) {
      formData.append('attachment', attachment, attachment?.name);
    }

    this.blockUI.start();
    this.licenseService.put(formData).subscribe(
      (response) => {
        this.toasterService.success(response);
        this.router.navigate([this.returnUrl]);
        this.blockUI.stop();
      },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });


  }

  onFileSelected(event: any): void {
    const fileList = event.target.files as FileList;
    if (!fileList || fileList.length === 0) {
      this.form.controls.attachment.setValue(null);
      return;
    }
    this.form.controls.attachment.setValue(fileList.item(0));
  }

  removeAttachment(): void {
    this.hasAttachment = false;
    this.model.isFileRemoved = true;
  }

  downloadAttachment(): void {
    this.blockUI.start();
    this.licenseService.downloadAttachment(this.model.id, this.model.fileName)
      .subscribe((isSuccess: boolean) => {
        this.blockUI.stop();
      },
        error => {
          this.toasterService.ProcessErrorResponse(error);
          this.blockUI.stop();
        });
  }

}
