export class ClientDtColumnName {
    public static readonly accountCode = 'accountCode';
    public static readonly affiliateCount = 'affiliateCount';
    public static readonly caseManagerName = 'caseManagerName';
    public static readonly clientName = 'name';
    public static readonly contactCount = 'contactCount';
    public static readonly creationDate = 'creationDate';
    public static readonly locationCount = 'locationCount';
    public static readonly salesPersonName = 'salesPersonName';
}

