import { Component, OnInit, ChangeDetectorRef, ViewChild, Renderer2, OnDestroy } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';


import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';
import { CompanyPolicyFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/company-poicy.filter.options';
import { CompanyPolicyVersionHistoryService } from 'src/app/services/companypolicy.versionhistory.service';
import { CompanyPolicyVersionHistoryFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/companypolicy.versionhistory.search&filter.options';
import { CompanyPolicyService } from 'src/app/services/company-policy.service';
import { CompanyPolicyViewModel } from 'src/app/models/company-policy/companyPolicyViewModel';
import { AppUtils } from 'src/app/helpers';
import { DateFormatPipe } from 'src/app/helpers/pipes/date-format.pipe';
import { DatePipe } from '@angular/common';
import { Subscription } from 'rxjs';


import { ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';

@Component({
  selector: 'app-policy-overview',
  templateUrl: './policy-overview.component.html',
  styleUrls: ['./policy-overview.component.css']
})
export class PolicyOverviewComponent implements OnInit, OnDestroy {

   // Pie
   public pieChartOptions: ChartOptions = {
    responsive: true,
    legend: {
      position: 'top',
    },
    plugins: {
      datalabels: {
        formatter: (value, ctx) => {
          const label = ctx.chart.data.labels[ctx.dataIndex];
          return label;
        },
      },
    }
  };

  public pieChartLabels: Label[] = [['Download', 'Sales'], ['In', 'Store', 'Sales'], 'Mail Sales'];
  public pieChartData: number[] = [300, 500, 100];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = false;
  public pieChartColors = [
    {
      backgroundColor: ['rgba(255,0,0,0.3)', 'rgba(0,255,0,0.3)', 'rgba(0,0,255,0.3)'],
    },
  ];
  // end pie

  @BlockUI('version-history-policy-overview') blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  companyPolicyDetailData: CompanyPolicyViewModel;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;
  dtRendered = true;
  visibleColumns = [];
  isDtVisible = false;
  isDtInitialized = false;
  permissionType = Permission;
  hasPermissionType = HasPermission;
  users: any = [];
  isBulkActionDisabled = true;
  companyPolicyId = 0;
  // for filters
  public filterOptionId: number = CompanyPolicyFilterOptions.None;
  private filterByItemId = '';
  private searchBy = -1;
  private searchFor = '';
  filterBy: any;
  formodel: string;
  frm: FormGroup;
  filterOptions: any;
  subscriptions: Subscription[];
  // capture filter open and close events
  filterNavStatus = false;
  currentVersion: 'None';
  constructor(private companyPolicyVersionHistoryService: CompanyPolicyVersionHistoryService,
              private tosterService: ToasterService,
              private renderer: Renderer2, private datePipe: DatePipe,
              private appUtils: AppUtils,
              private route: ActivatedRoute,
              private companypolicyService: CompanyPolicyService,
              private router: Router,
              public cdr: ChangeDetectorRef) {
                this.companyPolicyDetailData = new CompanyPolicyViewModel();
                this.route.parent.params.subscribe(params => this.companyPolicyId = +params.id);
                this.subscriptions = [];
              }

  ngOnDestroy(): void {
    this.subscriptions.every((subscription: Subscription) => subscription.unsubscribe());
  }

  get companyPolicyVersionHistoryFilterOptions(): any {
    return CompanyPolicyVersionHistoryFilterOptions;
  }

  closeFilterEvent(event: any): void {
    this.filterNavStatus = event;
  }

  ngOnInit(): void {
    this.initDataTable();
    this.loadCompanyPolicy();
  }
  loadCompanyPolicy(): void{
    this.blockUI.start();
    const subscription = this.companypolicyService.get(this.companyPolicyId).subscribe(
      (data: CompanyPolicyViewModel) => {
        this.companyPolicyDetailData = data;
        this.companyPolicyVersionHistoryService.policyDetailSubject$.next({
          id: this.companyPolicyId,
          name: data.name,
        });
        this.blockUI.stop();
      },
      (error: any) => {
        this.blockUI.stop();
        this.router.navigate(['/error/404']);
        this.tosterService.ProcessErrorResponse(error);
      }
    );
    this.subscriptions.push(subscription);
  }

  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtInstance = dtInstance;
      const header = dtInstance.columns().header();
      $(header).find('[action-type="select-unSelect-all"]')
        .off('change').on('change', (e: any) => {
          this.onSelectAllRow(e.target.checked);
        });
    });
  }
  // private methods
  private initDataTable(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      destroy: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },
      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,

      order: [[5, 'desc']],
      autoWidth: false,
      ajax: (dataTablesParameters: any, callback) => {
        self.blockUI.start();
        this.currentVersion = 'None';
        dataTablesParameters.filterOptionId = this.filterOptionId;
        dataTablesParameters.filterItemId = this.filterByItemId;
        dataTablesParameters.companyPolicyId = this.companyPolicyId;
        dataTablesParameters.searchBy = this.searchBy;
        dataTablesParameters.searchFor = this.searchFor;
        self.companyPolicyVersionHistoryService.query(dataTablesParameters)
          .subscribe(resp => {
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            self.blockUI.stop();
          }, () => { this.toggleControls(); });
      },
      columns: [
        {
          data: null,
          title: `<input type="checkbox" action-type="select-unSelect-all" />`,
          name: 'select',
          orderable: false,
          render: (data, type, row) => {
            return `<input type='checkbox' value = '${row.id}' action-type= "select-row"/>`;
          }
        },
        {
          data: null,
          name: null,
          title: '',
          orderable: false,
          render: (data, type, row) => {
            if (row.isCurrentVersion) {
             this.currentVersion = row.version;
            }
            const html =  row.isCurrentVersion ?
            `<span><i title="current version" class="fa fa-star" style="color:yellow" aria-hidden="true"></i></span>` :
            `<span></span>`;
            return html;
          }
        },
        {
          data: 'version',
          name: 'version',
          title: 'Version',
          render: (data, type, row) => {
           return `<span>${row.version}</span>`;
          }
        },
        {
          data: 'versionNotes',
          name: 'versionNotes',
          title: 'Version Notes'
        },
        {
          data: 'fileUrl',
          name: 'fileUrl',
          title: 'Version File',
          render: (data, type, row) => {
            const html = `${self.appUtils.genrateIconExtension(
                row.fileName
              )} <a title="download attachment" style="color:blue" action-type="attachment-download"> ${row.fileName} </a>`;
            return html;
           }
        },
        {
          data: 'createdDate',
          name: 'createdDate',
          title: 'Created At',
          render: (data, type, row) => {
            const html = `<span>${self.datePipe.transform(data, environment.dateTimeFormat.dateTime)}<span>`;
            return html;
           }
        },
        {
          data: 'name',
          title: (HasPermission.validate([Permission.DeleteClients]) || HasPermission.validate([Permission.EditClients])) ? 'Action' : '',
          name: 'action',
          orderable: false,
          render: (data, type, row) => {
            const disabled = '';
            const deleteButton = `<button  class='btn del-btn btn-lg btn-table' action-type="delete" ${disabled}>
            <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
            </button>`;
            const undeleteButton = `<button  class='btn del-btn btn-lg btn-table' action-type="un-delete">
            <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-restore-alt  fa-1x p-2 text-danger'></i>
            </button>`;
            const editButton = this.filterOptionId !== CompanyPolicyFilterOptions.Deleted
              ? `<button  class='btn del-btn btn-lg btn-table' alt="undelete">
            <i action-type='edit' class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
            </button>` : '';

            return `<div class='fa-edit'>${editButton} ${this.filterOptionId !== CompanyPolicyFilterOptions.Deleted
              ? deleteButton : undeleteButton}
              </div>`;
          }
        },
      ],
      rowCallback: (row, data: any) => {
        const _row = $(row);

        _row.find('[action-type="attachment-download"]')
          .off('click')
          .on('click', () => {
            self.downloadAttachment(data.id, data.fileName);
          });
        _row.find('[action-type="edit"]')
          .off('click')
          .on('click', () => {
            self.edit(data.id);
          });
        _row.find('[action-type="delete"]')
          .off('click')
          .on('click', () => {
            self.delete([data.id]);
          });
        _row.find('[action-type="un-delete"]')
          .off('click')
          .on('click', () => {
            self.unDelete([data.id]);
          });
        _row.find('[action-type = "select-row"]')
          .off('click').on('click', (e: any) => {
            this.onSelectedOrUnselectRow(e);
          });
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
    };
  }

  private toggleControls(): void {
    this.setSelectAllCheckbox();
    this.enableOrDisableDeleteButton();
  }

  private setSelectAllCheckbox(): void {
    const rows = this.dtInstance.rows().nodes();
    if (rows !== null) {
      const header = this.dtInstance.columns().header();
      const selectAllElement = $(header).find('[action-type="select-unSelect-all"]')[0] as any;
      const selelectedRows = [...rows.$('[action-type= "select-row"]')];
      selectAllElement.checked = selectAllElement != null && selelectedRows.length > 0
        && selelectedRows.every(row => row.classList.contains('checked'));
    }
  }

  private reloadDataTable(): void {
    this.dtInstance.ajax.reload();
  }

  private getSelectedIds(): any[] {
    const customFieldIds = [];
    const rows = this.dtInstance.rows().nodes();
    [...rows.$('.checked')].forEach(item => customFieldIds.push($(item).val()));
    return customFieldIds;
  }

  private enableOrDisableDeleteButton(): void {
    const rows = this.dtInstance.rows().nodes();
    this.isBulkActionDisabled = [...rows.$('.checked')].length === 0;
  }

  onSelectedOrUnselectRow(event: any): void {
    if (event.target.checked) {
      this.renderer.addClass(event.target, 'checked');
    } else {
      this.renderer.removeClass(event.target, 'checked');
    }
    this.toggleControls();
  }
  // for filters
  applyFilter(filterData: any): void {
    this.searchBy = Number(filterData.searchBy);
    this.searchFor = filterData.searchFor;
    const filterIdArr = filterData.filter?.split('_');
    if (filterIdArr && filterIdArr.length > 0) {
      this.filterOptionId = Number(filterIdArr[0]);
      if (filterIdArr.length > 1) {
        this.filterByItemId = filterIdArr[1];
      }
    }
    this.dtInstance.ajax.reload();
  }

  onResetFilters(): void {
    this.filterOptionId = CompanyPolicyFilterOptions.None;
    this.filterByItemId = '';
    this.searchBy = -1;
    this.searchFor = '';
    this.dtInstance.ajax.reload();
  }
  downloadAttachment(id: number, fileName: string): void{
    this.blockUI.start();
    const subscription = this.companyPolicyVersionHistoryService.downloadAttachment(id).subscribe((blob: File) => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = fileName;
      link.style.display = 'none';
      link.href = blobUrl;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobUrl);
        link.remove();
      }, 100);
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
    this.subscriptions.push(subscription);
  }

  delete(ids: number[]): void {
    if (!confirm('Are you sure you want to delete selected Version history/histories?')) {
      return;
    }
    this.blockUI.start();
    const subscription = this.companyPolicyVersionHistoryService.delete(ids).subscribe(() => {
      this.reloadDataTable();
      this.tosterService.success('Version history/histories(s) has been deleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
    this.subscriptions.push(subscription);
  }

  unDelete(ids: number[]): void {
    if (!confirm('Are you sure you want to undelete selected Version history/histories?')) {
      return;
    }
    this.blockUI.start();
    const subscription = this.companyPolicyVersionHistoryService.undelete(ids).subscribe(() => {
      this.reloadDataTable();
      this.tosterService.success('Version history/histories has been undeleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
    this.subscriptions.push(subscription);
  }

  onSelectAllRow(isChecked: boolean): void {
    const rows = this.dtInstance.rows().nodes();
    const checkBoxes = [...rows.$('[action-type= "select-row"]')];
    if (isChecked) {
      checkBoxes.forEach(row => {
        this.renderer.addClass(row, 'checked');
        (row as HTMLInputElement).checked = true;
      });
    } else {
      checkBoxes.forEach(row => {
        this.renderer.removeClass(row, 'checked');
        (row as HTMLInputElement).checked = false;
      });
    }
    this.enableOrDisableDeleteButton();
  }

  edit(id: number): void {
    this.router.navigate([`/setting/role/policy/${this.companyPolicyId}/edit-policy-version/${id}`]);
  }

  onBulkDelete(): void {
    this.delete(this.getSelectedIds());
  }

  onBulkUnDelete(): void {
    this.unDelete(this.getSelectedIds());
  }






  // ng chart
  // events
  public chartClicked({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

  public chartHovered({ event, active }: { event: MouseEvent, active: {}[] }): void {
    console.log(event, active);
  }

}
