import { Component, OnInit, Input } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { AuthenticateResponseModel } from './models/authenticate/authenticate.response.model';
import { AuthenticationService } from './services/authentication.service';


//global right nav
//import { createInititals } from './../app/components/shared/globalMethods';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  //openNav = new createInititals();

  title = 'CMP';
  isValidUser: boolean;
  user: AuthenticateResponseModel;
  isResetOrCreatePassword: boolean;

  sidenavStatus: boolean;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private route: ActivatedRoute) {
    this.authenticationService.user$.subscribe((user) => {
      this.isValidUser = user != null && !this.authenticationService.isTokenExpired();
      this.route.url.subscribe(t =>
        this.isResetOrCreatePassword = t.some(x => x.path.indexOf('/account/create-password/')
          || x.path.indexOf('/account/reset/')));
      if (user === null && !this.isResetOrCreatePassword) {
        this.router.navigate(['/account/logout']);
      }
      this.user = user;
    });
  }



  public isRead: boolean = false;


  public isStatus(): boolean {
    //this.router.navigate(['/login']);

    return false;

  }
  ngOnInit(): void {

    // listen route changes and scroll on top if router changes
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });

  }

  // side nav event
  toggleSidenav(): void {
    this.sidenavStatus = !this.sidenavStatus;
  }

}
