<main class="content py-0">
    <div class="position-fixed menu-btn">
        <span class="curve"></span>
        <a class="sidebar-toggle justify-content-center d-flex rounded-circle bg-orange position-absolute">
            <i class="text-white fas fa-chevron-left align-self-center"></i>
        </a>
    </div>
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-auto d-none d-sm-block">
                            <div class="d-flex mb-2">
                                <h3 class="page-title"><strong>Calendar</strong> </h3>
                                <span class="dropdown subject-dropdown show ml-2">
                                    <a class="btn text-orange dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        All Invoices
                                    </a>
                                
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </span>
                            </div>
                            <p>Get all the Expenses list at one place</p>
                        </div>

                        <div class="col-auto ml-auto text-right mt-n1">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                    <li class="filter-item px-0 filter-icon" onclick="openNav()">
                                        <button class="btn btn-lg bg-blue text-white">
                                            <i class="align-middle fas fa-filter"></i>
                                        </button>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <!-- Filter right sidenav -->
                        <div id="mySidenav" class="sidenavbar">
                            <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a>
                            <div id="sidenavForm" class="sidenav-form sidenav-bottom-btn d-flex flex-column">
                                <h3 class="text-dark-blue br-bottom-border">Filter Results</h3>
                                <form>
                                    <div class="form-group has-search pt-3 position-relative">
                                        <label for="filterBy">Filter By</label>
                                        <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                                        <div class="input-group position-relative">
                                            <select id="search1">
                                                <option value="" selected>Choose an Option</option>
                                                <option value="AD">Associates</option>
                                                <option value="BD">Bachelors</option>
                                            </select>
                                            <div class="input-group-append position-absolute append-btn">
                                                <button class="border-0 bg-transparent" type="button">
                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                <form>
                                    <div class="form-group has-search">
                                        <label for="searchBy">Search by</label>
                                        <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                                        <div class="input-group position-relative">
                                            <select id="search2">
                                                <option value="" selected>Slip Number</option>
                                                <option value="AD">Associates</option>
                                                <option value="BD">Bachelors</option>
                                            </select>
                                            <div class="input-group-append position-absolute append-btn">
                                                <button class="border-0 bg-transparent" type="button">
                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group has-search">
                                        <label for="searchFor">Search For</label>
                                        <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                                        <div class="input-group position-relative">
                                            <select id="search3">
                                                <option value="" selected>Search</option>
                                                <option value="AD">Associates</option>
                                                <option value="BD">Bachelors</option>
                                            </select>
                                            <div class="input-group-append position-absolute append-btn">
                                                <button class="border-0 bg-transparent" type="button">
                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group has-search">
                                        <label for="In">Client</label>
                                        <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                                        <div class="input-group position-relative">
                                            <select id="search4">
                                                <option value="" selected>All Client</option>
                                                <option value="AD">Associates</option>
                                                <option value="BD">Bachelors</option>
                                            </select>
                                            <div class="input-group-append position-absolute append-btn">
                                                <button class="border-0 bg-transparent" type="button">
                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group has-search">
                                        <label for="caseFlag">Status</label>
                                        <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                                        <div class="input-group position-relative">
                                            <select id="search5">
                                                <option value="" selected>All Status</option>
                                                <option value="AD">Associates</option>
                                                <option value="BD">Bachelors</option>
                                            </select>
                                            <div class="input-group-append position-absolute append-btn">
                                                <button class="border-0 bg-transparent" type="button">
                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group has-search">
                                        <label for="caseService">Date Range by</label>
                                        <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                                        <div class="input-group position-relative">
                                            <select id="search6">
                                                <option value="" selected>Date Created</option>
                                                <option value="AD">Associates</option>
                                                <option value="BD">Bachelors</option>
                                            </select>
                                            <div class="input-group-append position-absolute append-btn">
                                                <button class="border-0 bg-transparent" type="button">
                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group has-search">
                                        <label for="caseService">Salesperson</label>
                                        <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                                        <div class="input-group position-relative">
                                            <select id="search7">
                                                <option value="" selected>All Salesperson</option>
                                                <option value="AD">Associates</option>
                                                <option value="BD">Bachelors</option>
                                            </select>
                                            <div class="input-group-append position-absolute append-btn">
                                                <button class="border-0 bg-transparent" type="button">
                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="container px-0">
                                        <label for="forAll">Date Range</label>
                                        <div class="row">
                                            <div class="col-md-6">
                                                <!-- Date Picker Input -->
                                                <div class="form-group mb-4 position-relative">
                                                    <div class="datepicker date input-group p-0 border d-flex flex-column justify-content-center p-2">
                                                        <span class="icon-date position-absolute"><i class="align-middle far fa-calendar-alt mr-2"></i></span>
                                                        <div class="d-flex flex-column justify-content-center align-items-start pl-4">
                                                            <label class="mb-0" for="reservationDate">Form</label>
                                                            <input type="text" class="form-control border-0 p-0" id="reservationDate">
                                                            <div class="input-group-append"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6">
                                                <!-- Date Picker Input -->
                                                <div class="form-group mb-4 position-relative">
                                                    <div class="datepicker date input-group p-0 border d-flex flex-column justify-content-center p-2">
                                                        <span class="icon-date position-absolute"><i class="align-middle far fa-calendar-alt mr-2"></i></span>
                                                        <div class="d-flex flex-column justify-content-center align-items-start pl-4">
                                                            <label class="mb-0" for="reservationDate">To</label>
                                                            <input type="text" class="form-control border-0 p-0" id="reservationDateto">
                                                            <div class="input-group-append"></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="text-right filter-btn">
                                        <ol class="list-unstyled d-flex align-items-center justify-content-end">
                                            <li>
                                                <a href="javascript:void(0);">Reset Filters</a>
                                            </li>
                                            <li>
                                                <button class="btn btn-lg bg-blue text-white ml-2">
                                                    <i class="align-middle fas fa-check-circle"></i> Apply Filters
                                                </button>
                                            </li>
                                        </ol>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row pt-4">
                    <div class="col-6 col-md-3">
                        <div class="card d-block">
                            <div id="datetimepicker-calendar"></div>
                        </div>
                        <div class="card d-block">
                            <div class="card-body">
                                <h4 class="mb-4 calendar-title">Calendars</h4>
                                <div class="calendarList">
                                    <div class="calendar-event w-100 d-flex justify-content-between py-2">
                                        <div class="event-icon">
                                            <i class="align-middle fas fa-calendar-day mr-2"></i> Event
                                        </div>
                                        <div class="event-checkbox">
                                            <form>
                                                <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="customCheck" name="event" checked>
                                                <label class="custom-control-label" for="customCheck"></label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="calendar-event w-100 d-flex justify-content-between py-2">
                                        <div class="event-icon">
                                            <i class="align-middle fas fa-clipboard-list mr-2"></i> Reminders
                                        </div>
                                        <div class="event-checkbox">
                                            <form>
                                                <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="customCheck1" name="reminder">
                                                <label class="custom-control-label" for="customCheck1"></label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                    <div class="col-12 col-md-9">
                        <div class="card">
                            <div class="card-body">
                                <div id="calendar" class="schedule"></div>
                                <div class="slick-content pb-5 slider-scrolls">
                                    <div class="slider">
                                        <div>
                                            <div class="image">
                                                <div class="right-border">
                                                <img class="rounded-circle" src="img/avatars/avatar.jpg" alt="">
                                                </div>
                                            <p class="slider-img-title">Dr. Rushangan Yogar</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="image">
                                                <div class="right-border">
                                                    <img class="rounded-circle" src="img/avatars/avatar-2.jpg" alt="">
                                                    </div>
                                            <p class="slider-img-title">Aguijat, Joey</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="image">
                                                <div class="right-border">
                                                    <img class="rounded-circle" src="img/avatars/avatar-3.jpg" alt="">
                                                </div>
                                            <p class="slider-img-title">Dr. Kenin Mark</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="image">
                                                <div class="right-border">
                                                    <img class="rounded-circle" src="img/avatars/avatar-4.jpg" alt="">
                                                </div>
                                            <p class="slider-img-title">Allva, William</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="image">
                                                <div class="right-border">
                                                    <img class="rounded-circle" src="img/avatars/avatar-5.jpg" alt="">
                                                </div>
                                            <p class="slider-img-title">August, Bran</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="image">
                                                <div class="right-border">
                                                    <img class="rounded-circle" src="img/avatars/avatar-s-7.png" alt="">
                                                </div>
                                            <p class="slider-img-title">Avila, Joseph</p>
                                            </div>
                                        </div>
                                      <div>
                                        <div class="image">
                                            <div class="right-border">
                                                <img class="rounded-circle" src="img/avatars/avatar-s-8.png" alt="">
                                            </div>
                                          <p class="slider-img-title">Beginim, Bryan</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="image">
                                                <div class="right-border">
                                                    <img class="rounded-circle" src="img/avatars/avatar-s-9.png" alt="">
                                                </div>
                                            <p class="slider-img-title">August, Bran</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="image">
                                                <div class="right-border">
                                                    <img class="rounded-circle" src="img/avatars/avatar-s-9.png" alt="">
                                                </div>
                                            <p class="slider-img-title">Avila, Joseph</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="image">
                                                <div class="right-border">
                                                    <img class="rounded-circle" src="img/avatars/avatar-s-9.png" alt="">
                                                </div>
                                            <p class="slider-img-title">Avila, Joseph</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="image">
                                                <div class="right-border">
                                                    <img class="rounded-circle" src="img/avatars/avatar-s-9.png" alt="">
                                                </div>
                                            <p class="slider-img-title">Avila, Joseph</p>
                                            </div> 
                                        </div>
                                        <div>
                                            <div class="image">
                                                <div class="right-border">
                                                    <img class="rounded-circle" src="img/avatars/avatar-2.jpg" alt="">
                                                    </div>
                                            <p class="slider-img-title">Aguijat, Joey</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="image">
                                                <div class="right-border">
                                                    <img class="rounded-circle" src="img/avatars/avatar-2.jpg" alt="">
                                                    </div>
                                            <p class="slider-img-title">Aguijat, Joey</p>
                                            </div>
                                        </div>
                                        <div>
                                            <div class="image">
                                                <div class="right-border">
                                                    <img class="rounded-circle" src="img/avatars/avatar-2.jpg" alt="">
                                                    </div>
                                            <p class="slider-img-title">Aguijat, Joey</p>
                                            </div>
                                        </div>
                                    </div> <!-- end slider -->
                                    <!-- <div class="progress" role="progressbar" aria-valuemin="0" aria-valuemax="100">
                                      <span class="slider__label sr-only"></span>
                                    </div> -->
                                </div> <!-- end content -->			
                                <div class="schedule-content">
                                    <ul class="list-unstyled">
                                        <li class="d-flex">
                                            <div class="col-1 time">
                                                <p class="text-light-black mb-0">09:00 AM</p>
                                            </div>
                                            <div class="col-11 d-flex justify-content-between bg-schedule p-3 pl-5">
                                                <div class="scheduleText">
                                                    <h5 class="mb-0 text-success">Case Assignment</h5>
                                                    <p class="mb-0">Consultation - Having pain and tightness in the chest.</p>
                                                </div>
                                                <div class="fullTime">
                                                    <small>09:00 - 09:30</small>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="d-flex mb-5">
                                            <div class="col-1 time d-flex align-items-end">
                                                <p class="text-light-black mb-0">10:00 AM</p>
                                            </div>
                                            <div class="col-11 d-flex justify-content-between bg-schedule-orange p-3 pl-5">
                                                <div class="scheduleText">
                                                    <h5 class="text-orange mb-0">Case Assignment</h5>
                                                    <p class="mb-0">Post Operation - Routine Check</p>
                                                </div>
                                                <div class="fullTime">
                                                    <small>09:30 - 10:00</small>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="d-flex mb-7">
                                            <div class="col-1 time">
                                                <p class="text-light-black mb-0">11:00 AM</p>
                                            </div>
                                            <div class="col-11 d-flex justify-content-between bg-schedule p-3 pl-5">
                                                <div class="scheduleText">
                                                    <h5 class="mb-0 text-success">Case Assignment</h5>
                                                    <p class="mb-0">Post Operation - Routine Check</p>
                                                </div>
                                                <div class="fullTime">
                                                    <small>10:30 - 11:00</small>
                                                </div>
                                            </div>
                                        </li>
                                        <li class="d-flex">
                                            <div class="col-1 time d-flex justify-content-between flex-column">
                                                <p class="text-light-black mb-0">12:00 AM</p>
                                                <p class="text-light-black mb-0">01:00 AM</p>
                                            </div>
                                            <div class="col-11 d-flex justify-content-between break pl-3 pt-5 pr-5">
                                                <h4 class="mb-2 text-dark">Lunch Break</h4>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>


<!--Calendar Event Date Modal -->

<!-- <div id="modal-view-event-add" class="modal modal-top fade calendar-modal">
    <div class="modal-dialog modal-dialog-centered calendar-modal-dialog">
        <div class="modal-content">
            <form id="add-event">
                <div class="modal-body calendar-body">
                    <h4 class="mb-0"><a class="text-dark-blue font-weight-bold" href="case_list_detail.html">Case assignment</a></h4>
                    <small>(Surveillance)</small> 
                    <hr>       
                    <div class="form-group">
                        <label class="text-dark d-block font-weight-bold">6/29/20</label>
                        <i class="fas fa-circle text-success mr-2"></i>In progress
                    </div>
                    <hr>
                    <div class="form-group">
                        <label class="text-dark mb-0">6:30 a.m. start time</label>
                    </div> 
                    <hr>
                    <div class="row">     
                        <div class="form-group col-md-6">
                            <h5 class="font-weight-bold">Case No:</h5>
                            <hr>
                            <a href="#" class="text-dark-blue">SV18149-02</a>
                        </div>
                        <div class="form-group col-md-6">
                            <h5 class="font-weight-bold">Subject:</h5>
                            <hr>
                            <a href="#" class="text-dark-blue">Ramos, Lanette</a>
                        </div>
                    </div> 
                    <div class="form-group">
                        <h5 class="font-weight-bold">Address:</h5>
                        <hr>
                        <label class="text-dark mb-0 d-block">14719 Terry Knoll Drive</label>
                        <label class="text-dark mb-0 d-block">Whittier, CA 90604</label>
                        <a class="text-dark-blue" href="">(view map)</a>
                    </div>
                    <div class="form-group">
                        <h5 class="font-weight-bold">Assigned to:</h5>
                        <hr>
                        <label class="text-dark mb-0 d-block">CHRIS PETITTI</label>
                        <label class="text-dark mb-0 d-block">DYANA SPELLMAN</label>
                    </div>     
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-blue text-white" >Edit</button>
                    <button type="button" class="btn bg-blue text-white" >Duplicate</button>
                    <button type="button" class="btn bg-blue text-white" >Create Case Update</button>      
                </div>
            </form>
        </div>
    </div>
</div> -->