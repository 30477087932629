import { Component, OnInit } from '@angular/core';





declare var require: any;
const Highcharts = require('highcharts');


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
    imports: [

      ];

      selectedDate = '11-06-2017';
      checkme = 'lol';

  constructor() { }

  ngOnInit(): void {


    // Creating chart 1
    // used under class="align-self-center chart"
    Highcharts.chart('chartjs-dashboard-bar', {
      colors: ['#FF8C00'],
      chart: {
          type: 'column'
      },

      title: false,
      subtitle: false,

      xAxis: {
          categories: ['2016', '2017', '2018', '2019', '2020', '2021']
      },
      yAxis: {
          title: {
              text: null
          },
      },
      legend: {
          enabled: false
      },
      credits: {
          enabled: false
      },
      series: [{
          data: [2, 1, 5, 4, 4, 3]
      }],

      responsive: {
          rules: [{
              condition: {
                  maxWidth: 500
              },
              chartOptions: {
                  chart: {
                      className: 'small-chart'
                  }
              }
          }]
      }
    });

    // Creating chart 2
    // Used under class="align-self-center chart chart-sm"
    Highcharts.chart('dashboard-bar', {
          chart: {
            type: 'column'
        },
        title: false,
        subtitle: false,
        colors: [
            '#ff7c49',
            '#65376a'
        ],
        legend: {
            layout: 'horizontal',
            align: 'center',
            verticalAlign: 'bottom',
            borderWidth: 0,
            backgroundColor: '#FFFFFF'
        },
        xAxis: {
            categories: [
                '2016',
                '2017',
                '2018',
                '2019',
                '2020',
                '2021'
            ]
        },
        yAxis: {
            title: {
                text: ''
            }
        },
        tooltip: {
            shared: false,
            valueSuffix: 'points'
        },
        credits: {
            enabled: false
        },
        plotOptions: {
            areaspline: {
                fillOpacity: 0.1
            },
        series: {
            groupPadding: .15,
            showInLegend: false,
            pointPadding: 0,
        }
        },
        series: [{
            name: 'Data 1',
            data: [3, 4, 4, 5, 3, 6]
        }, {
            name: 'Data 2',
            data: [4, 2, 5, 3, 5, 2]
        }, ],

        responsive: {
          rules: [{
              condition: {
                  maxWidth: 500
              },
              chartOptions: {
                  chart: {
                      className: 'small-chart'
                  }
              }
          }]
      }
    });

  }

 // for calendar

 onSelect(event){
    this.selectedDate = event;
  }

}
