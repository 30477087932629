import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { FilterInputModel, FilterModel, SearchByModel, SelectedFilterModel } from 'src/app/models/shared/filters.model';
import { SearchModel } from 'src/app/models/shared/search.model';

@Component({
  selector: 'app-casefilter-results',
  templateUrl: './casefilter-results.component.html',
  styleUrls: ['./casefilter-results.component.css']
})
export class CasefilterResultsComponent implements OnInit {

  form: FormGroup;

  @Input() filterBy: FilterInputModel[];

  @Input() filterIn: FilterInputModel[];

  @Input() isFilterInAvailable: boolean;

  @Input() searchBy: SearchByModel[];

  @Input() isSearchingAvailable: boolean;

  isvisible: boolean;
  isforvisible:boolean;

  @Output() onApplyFilter = new EventEmitter();
 
  @Output()
  resetFilters = new EventEmitter();

  @Output()
  closeNavEvent = new EventEmitter();


  @Input() filterOpen: boolean;
  private appliedFilter: SelectedFilterModel;


  constructor(private formBuilder: FormBuilder) { }


  ngOnInit(): void {
    debugger;
    this.form = this.formBuilder.group({
      searchBy: [],
      searchFor: [],
      searchFrom:[],
      filter: [],
      filterIn: []
    });

  }

  ngOnChanges(): void {
   
    this.manageFilterState();
  }

  onSubmit(): void {
    debugger;
    this.appliedFilter = {
      filter: this.form.controls.filter.value,
      filterIn: this.form.controls.filterIn.value,
      searchBy: this.form.controls.searchBy.value,
      searchFor:  typeof this.form.controls.searchFor.value=='object'
      ?JSON.stringify(this.form.controls.searchFor.value)
      :this.form.controls.searchFor.value?.toString(),
      searchFrom:  typeof this.form.controls.searchFrom.value=='object'
      ?JSON.stringify(this.form.controls.searchFrom.value)
      :this.form.controls.searchFrom.value?.toString()  
    };

    this.onApplyFilter.emit(this.appliedFilter);
  }

  onResetFilters(): void {
    this.appliedFilter = {
      filter: null,
      filterIn: null,
      searchBy: null,
      searchFor: '',
      searchFrom: ''
    };
    this.resetFilters.emit();
  }

  closeFilterNav(event: boolean): void {
    this.filterOpen = false;
    this.closeNavEvent.emit(event);
  }
  onOptionsSelected(event):any{
    debugger;
    console.log(event);
    this.isvisible=false;
    var target = event.id;
    
   if(target==4)
    {
      this.isvisible=true;
      this.isforvisible=false;
      
    }
    else{
      this.isvisible=false;
      this.isforvisible=true;
    }
    
    
   }
  manageFilterState(): void {
   
    if (!this.form) {
      return;
    }

    const selectedFilterBy = this.filterBy.filter(x => x.isSelected);
    if (this.appliedFilter && this.appliedFilter.filter && this.filterBy.some(x => x.id == this.appliedFilter.filter)) {
      this.form.controls.filter.setValue(this.appliedFilter.filter);
    }
    else if (selectedFilterBy && selectedFilterBy.length > 0) {
      this.form.controls.filter.setValue(selectedFilterBy[0].id);
    }

    const selectedFilterIn = this.filterIn?.filter(x => x.isSelected);
    if (this.appliedFilter && this.appliedFilter.filterIn && this.filterIn.some(x => x.id == this.appliedFilter.filterIn)) {
      this.form.controls.filterIn.setValue(this.appliedFilter.filterIn);
    }
    else if (selectedFilterIn && selectedFilterIn.length > 0) {
      this.form.controls.filterIn.setValue(selectedFilterIn[0].id);
    }

    if (this.appliedFilter && this.appliedFilter.searchBy && this.searchBy.some(x => x.id == this.appliedFilter.searchBy)) {
      this.form.controls.searchBy.setValue(this.appliedFilter.searchBy);
    }
    else {
      this.form.controls.searchBy.setValue(null);
    }

    if (this.appliedFilter && this.appliedFilter.searchFor) {
      this.form.controls.searchFor.setValue(this.appliedFilter.searchFor);
    }
    else {
      this.form.controls.searchFor.setValue('');
    }
    if (this.appliedFilter && this.appliedFilter.searchFrom) {
      this.form.controls.searchFrom.setValue(this.appliedFilter.searchFrom);
    }
    else {
      this.form.controls.searchFrom.setValue('');
    }
  }
}
