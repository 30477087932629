<div class="card  bg-warning">
    <div class="card-body">
        <div class="row justify-content-start align-content-center  align-items-center">
            <div class="col-3">
                    <div class="row">
                        <div class="col-md-6 col-md-3 da-font-bold font-weight-bold">Staff</div>
                        <div class="col-md-6"> HOLDEN, ROBERT</div>
                    </div>
            </div>
            <div class="col-3 border-left">
                <div class="row">
                    <div class="col-md-6 col-md-3 da-font-bold font-weight-bold">Period</div>
                    <div class="col-md-6"> N/A</div>
                </div>
            </div>
            <div class="col-3 border-left">
                <div class="row">
                    <div class="col-md-6 col-md-3 da-font-bold font-weight-bold">Overtime Enabled</div>
                    <div class="col-md-6"> No</div>
                </div>
            </div>

            <div class="col-3 border-left">
                <div class="row">
                    <div class="col-md-6 col-md-3 da-font-bold font-weight-bold">Case(s)</div>
                    <div class="col-md-6"> 03651SV-01</div>
                </div>
            </div>
        </div>
    </div>
    
</div>

<div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-3">
            <ngb-panel id="custom-panel-3">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold">Time Summary</h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <table class="table table-striped border">
                  <thead>
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Hours</th>
                    <th scope="col" class="text-right">Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let country of countries; index as i">
                 
                    <td>
                   
                      {{ country.name }}
                    </td>
                    <td>{{ country.area | number }}</td>
                    <td  class="text-right">${{ country.population | number }}</td>
                  </tr>
                  </tbody>
                </table>

                <div class="row justify-content-end">
                  <div class="col-md-1">
                    <h5 class="font-weight-bold">$137.75</h5>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-5">
            <ngb-panel id="custom-panel-5">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold"><i
                      class="align-middle far fa-file-alt mr-2 fx-2 text-orange"></i>Expense Summary</h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <table class="table table-striped border">
                  <thead>
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Hours</th>
                    <th scope="col"  class="text-right">Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let country of countries; index as i">
                 
                    <td>
                   
                      {{ country.name }}
                    </td>
                    <td>{{ country.area | number }}</td>
                    <td  class="text-right">${{ country.population | number }}</td>
                  </tr>
                  </tbody>
                </table>

                <div class="row justify-content-end">
                  <div class="col-md-3">
                    
                    <div class="row">
                      <div class="col-md-6"><h5 class="font-weight-bold">Wage Total:</h5></div>
                      <div class="col-md-6"><h5 class="font-weight-bold">$137.75</h5></div>

                      <div class="col-md-6"><h5 class="font-weight-bold">Expense Total:</h5></div>
                      <div class="col-md-6"><h5 class="font-weight-bold">$57.75</h5></div>

                      <div class="col-md-6"><h5 class="font-weight-bold">Payment Slip Total:</h5></div>
                      <div class="col-md-6"><h5 class="font-weight-bold">$818.05</h5></div>

                    </div>


                    <div class="row border-top pt-3  border-4">
                      <div class="col-md-6"><h5 class="font-weight-bold">Amount Due:</h5></div>
                      <div class="col-md-6"><h5 class="font-weight-bold">$818.75</h5></div>

                    </div>
                  </div>
                 
                 
                </div>
                

              </ng-template>
            </ngb-panel>
          </ngb-accordion>
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-4">
            <ngb-panel id="custom-panel-4">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold"><i class="align-middle fas fa-tools mr-2 fx-2 text-orange"></i>Payments</h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <table class="table table-striped border">
                  <thead>
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Hours</th>
                    <th scope="col"  class="text-right">Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let country of countries; index as i">
                 
                    <td>
                   
                      {{ country.name }}
                    </td>
                    <td>{{ country.area | number }}</td>
                    <td  class="text-right">${{ country.population | number }}</td>
                  </tr>
                  </tbody>
                </table>
              
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>
  
  </div>
