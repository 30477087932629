import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HistoryFor } from 'src/app/helpers/enums/history/historyFor';

@Component({
  selector: 'app-policy-history',
  templateUrl: './policy-history.component.html',
  styleUrls: ['./policy-history.component.css']
})
export class PolicyHistoryComponent implements OnInit{

  entityId: string;
  moduleId: number;
   constructor(private route: ActivatedRoute, private router: Router) { }

               ngOnInit(): void {
                 this.moduleId = HistoryFor.CompanyPolicyHistory;
                 this.route.parent.params.subscribe(param => this.entityId = param.id);
        
               }

}
