export class CaseReviewModel {
  public id: number;
  public name: string;
  public description: string;
  public calendarId?: number;
  public activityStatusId?: number;
  public accessGroupId: number;
  public reviewTypeId: number;
  public caseStatusId: number;
}
