import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { NoteAddModel } from "../models/note/note.add.model";
import { NoteEditModel } from "../models/note/note.edit.model";
import { DataTableResponseModel } from "../models/shared/datatable.response.model";

@Injectable({
    providedIn: 'root'
})
export class NoteService {
    constructor(private http: HttpClient) {
    }

    private returnUrl: string;

    public setReturnUrl  = (url: string) => this.returnUrl = url;

    public getReturnUrl = () => this.returnUrl;

    public post(model: NoteAddModel): Observable<any> {
        const formData = new FormData();
        formData.append('id', JSON.stringify(model.id));
        formData.append('accessGroupId', JSON.stringify(model.accessGroupId));
        formData.append('module', JSON.stringify(model.module));
        formData.append('noteText', model.noteText);
        formData.append('date', model.date);
        formData.append('entityId', model.entityId);
        formData.append('isPinnedNote', JSON.stringify(model.isPinnedNote));
        formData.append('isCurrentUser', JSON.stringify(model.isCurrentUser));
        if (model.attachment != null && model.attachment !== undefined) {
            formData.append('attachment', model.attachment, model.attachment.name);
        }
        return this.http.post<any>(`${environment.apiBaseUrl}/note`, formData, {});
    }

    public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/note/query`, dataTablesParameters, {});
    }

    public get(id: number): Observable<any> {
        return this.http.get<any>(`${environment.apiBaseUrl}/note/${id}`);
    }

    public put(model: NoteEditModel): Observable<any> {
        const formData = new FormData();
        formData.append('id', JSON.stringify(model.id));
        formData.append('accessGroupId', JSON.stringify(model.accessGroupId));
        formData.append('module', JSON.stringify(model.module));
        formData.append('noteText', model.noteText);
        formData.append('date', model.date);
        formData.append('entityId', model.entityId);
        formData.append('isPinnedNote', JSON.stringify(model.isPinnedNote));
        formData.append('isAttachmentDeleted', JSON.stringify(model.isAttachmentDeleted));
        formData.append('fileUrl', JSON.stringify(model.fileUrl));
        formData.append('fileName', JSON.stringify(model.fileName));
        if (model.attachment != null && model.attachment !== undefined) {
            formData.append('attachment', model.attachment, model.attachment.name);
        }
        return this.http.put<any>(`${environment.apiBaseUrl}/note`, formData, {});
    }

    public downloadAttachment(noteId: number): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/note/downloadAttachment/${noteId}`, { responseType: 'blob' });
    }

    public delete(noteIds: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/note?noteIds=${noteIds.join('&noteIds=')}`);
    }
    public undelete(noteIds: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/note/undelete-notes?noteIds=${noteIds.join('&noteIds=')}`);
    }
}