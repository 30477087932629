<div *blockUI="'container-blockui-cutomize-list'">
    <div>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Customize List</h4>
            <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="form-group">
                <form [formGroup]="form" (ngSubmit)="submit()">
                    <div class="form-group row">
                        <label for="staticEmail" class="col-sm-4 col-form-label  my-auto mx-auto">Columns to display:</label>
                        <div class="col-sm-8">
                            <div class="row"  formArrayName="columns">
                                <div class="">
                                    <div class="row pl-4">
                                        <div class="col-4 mb-3 da-customize-col" *ngFor="let column of columns.controls; let i = index" [formGroupName]="i">
                                      
                                            <input class="form-check-input" type="checkbox" formControlName="isSelected"
                                            id="column_{{i}}">
                                            <label class="form-check-label" for="chkPrevillage{{i}}">
                                                {{column.value.name}}
                                            </label>
                                       
                                        
                                    </div>
                                    </div>
                                    
                                </div>
                                <div *ngIf="submitted && form.controls.columns.errors">
                                    <div *ngIf="submitted && form.controls.columns.errors.required" class="text-danger">
                                        Please select atleast one column
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-group row">
                        <label class="col-sm-4 col-form-label  my-auto mx-auto" for="exampleInputPassword1">Lines per page: </label>
                        <div class="col-sm-8">
                            <select class="dropdown-toggle btn  btn-lg" formControlName="pageSize" name="user_view[limit]" id="user_view_limit">
                                <option *ngFor="let size of pageSizes" [value]="size">{{size}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-outline-secondary"
                            (click)="activeModal.dismiss('cancel click')">Cancel</button>
                        <button class="btn btn-primary">Save</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>