<div class="container-fluid">
  <div class="row text-muted">
    <div class="col-12 text-center">
      <p class="mb-0">
        Argus &copy; All Rights Reserved. Developed By
        <a href="index.html" class="text-muted"><strong>Argus.com</strong></a>
      </p>
    </div>
  </div>
</div>
