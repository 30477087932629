export class CaseViewModel {
    public id: number;
    public clientId: number;
    public locationInClientId: number;
    public caseTypeId: number;
    public caseRegionId: number;
    public caseStatusId?:number;
    public companyLocationId?: number;
    public referralSourceId?: number;
    public contactId: string;
    public assignedMyselfAs: string;
    public caseLocation: string;
    public instructionNote: string;
    public schedulingNote: string;
    public adminNote: string;
    public budget: string;
    public hours: string;
    public createdOn: Date;
    public dueDate?: Date;
    public caseId:string;
    public caseNumber:string;
    public subjects:any[];
}
