import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { AppUtils } from 'src/app/helpers';
import { AccessGroupFilterOptionsDetail } from 'src/app/helpers/constants/filter-and-search/user.roles.and.access-groups.options';
import { clientListColumnDetails } from 'src/app/helpers/constants/staff/grid.column.detail';
import { FilterBy, FilterInputModel, FilterModel } from 'src/app/models/shared/filters.model';
import { SearchModel } from 'src/app/models/shared/search.model';
import { StaffService } from 'src/app/services/staff.serrvice';

@Component({
  selector: 'app-user-access-group-search-and-filter',
  templateUrl: './user-access-group-search-and-filter.component.html',
  styleUrls: ['./user-access-group-search-and-filter.component.css']
})
export class UserAccessGroupSearchAndFilterComponent implements OnInit {

  filterBy: FilterInputModel[] = [];

  @Output()
  onApplyFilter = new EventEmitter();

  @Output()
  resetFilters = new EventEmitter();


  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();

  constructor() {
  }

  ngOnInit(): void {
    AccessGroupFilterOptionsDetail.forEach((x, y) => {
      this.filterBy.push({ name: x, id: y });
    });
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onResetFilters(): void {
    this.resetFilters.emit();
  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }
}
