import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddorEditUserSecuritySettingModel } from '../models/user-security-setting/addorEditUserSecuritySettingModel';
import { UserSecuritySettingViewModel } from '../models/user-security-setting/userSecuritySettingViewModel';

@Injectable({
  providedIn: 'root'
})
export class UserSecuritySettingService {

  constructor(private http: HttpClient) { }

  public get(): Observable<UserSecuritySettingViewModel>{
    return this.http.get<UserSecuritySettingViewModel>(`${environment.apiBaseUrl}/usersecuritysetting/get`);
  }
  public post(model: AddorEditUserSecuritySettingModel): Observable<string>{
    return this.http.post<string>(`${environment.apiBaseUrl}/usersecuritysetting/post`, model, {});
  }
}
