import { ClientListColumnDetail, ColumnDetail } from "../../enums/staff";


export const staffColumnDetails = new Map<number, string>([
    [ColumnDetail.Name, 'Name'],
    [ColumnDetail.Email, 'Email'],
    [ColumnDetail.Title, 'Title'],
    [ColumnDetail.officePhone, 'Office Phone'],
    [ColumnDetail.Tags, 'Tag'],
    [ColumnDetail.AccountCode, 'Account Code'],
    [ColumnDetail.BirthDay, 'Date Of Birth'],
    [ColumnDetail.StateOrProvince, 'State/Province']
  ]);

export const clientListColumnDetails = new Map<number, string>([
  [ClientListColumnDetail.Name, 'Name'],
  [ClientListColumnDetail.Address, 'Address'],
  [ClientListColumnDetail.CaseManager, 'Case Manager'],
  [ClientListColumnDetail.Salesperson, 'Salesperson'],
  [ClientListColumnDetail.AccountCode, 'Account Code'],
  [ClientListColumnDetail.Tag, 'Tag']
]);