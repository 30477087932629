import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { concat, forkJoin, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AppUtils } from 'src/app/helpers';
import { RoleAvailableFor } from 'src/app/helpers/enums/roles/role.available.for';
import { NgbDateFRParserFormatter } from 'src/app/helpers/ngb.date.parser';
import { CountryModel } from 'src/app/models/shared/country.model';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { StateModel } from 'src/app/models/shared/state.model';
import { EmployeeEditModel } from 'src/app/models/staff/employee.edit.model';
import { CountryService } from 'src/app/services/country.service';
import { RoleService } from 'src/app/services/role.service';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-edit-employee',
  templateUrl: './edit-employee.component.html',
  styleUrls: ['./edit-employee.component.css']
})
export class EditEmployeeComponent implements OnInit {

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private staffService: StaffService,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private countryService: CountryService,
    private ngbDateParserFormatter: NgbDateFRParserFormatter,
    public appUtil: AppUtils
  ) {
    this.model = new EmployeeEditModel();
    this.activeRoute.params.subscribe(params => this.model.id = params.id);
  }

  @BlockUI('container-blockui-employee') blockUI: NgBlockUI;
  form: FormGroup;
  submitted = false;
  model: EmployeeEditModel;
  managersSelectListItem: SelectListItem<string>[] = [];
  rolesSelectListItem: SelectListItem<string>[] = [];
  countrySelectListItem: CountryModel[] = [];
  stateSelectListItem: StateModel[] = [];
  tags$: Observable<any>;
  tagsInput$ = new Subject<string>();
  imgSrc: string;
  isImageRender = false;
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  loadBreadCrumb = false;
  // for dropdowns
  countries = [
    { id: 'US', name: 'US' },
    { id: 'India', name: 'India' },
  ];
  selectedValue = 1;
  Selectedcountries = 1;

  dateStruct: NgbDateStruct;

  public profileimg: string;
  public currentInput: string;
  fileBlob: string;
  isContactLoaded = false;


  // for tags
  selectedCars2 = [];



  public managermodel: any;

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      middleName: [''],
      lastName: [''],
      manager: [''],
      role: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      title: ['', [Validators.required]],
      address1: [''],
      address2: [''],
      address3: [''],

      country: new FormControl(null),
      state: [''],
      zip: [''],
      city: [''],

      contactNumber: [''],

      homePhone: [''],
      fax: [''],
      dob: null,
      accountCode: [''],
      tags: null,
      generalNotes: [''],
      adminNotes: [''],
      emailSignature: [''],
      preferedServices: [''],
      preferedRegions: [''],
      isTrackLastLocation: false,
      profileImg: null,
      is_active: true,
    });

    this.loadData();
    this.loadTags();


  }

  onImageCropped(file: File): void {
    this.form.controls.profileImg.setValue(file);
  }

  private loadData(): void {
    debugger;
    this.blockUI.start();
    forkJoin([
      this.staffService.getManagers(),
      this.roleService.getRolesByAvailableFor(RoleAvailableFor.employee),
      this.countryService.getCountries(),
      this.staffService.get(this.model.id)
    ]).subscribe(([
      managers,
      roles,
      countries,
      employeeEditModel
    ]) => {
      this.breadCrumbInputs.push({
        key: 'id', value: [employeeEditModel.id,
        `${employeeEditModel.firstName} ${employeeEditModel.middleName ?? ''} ${employeeEditModel.lastName ?? ''}`.replace('  ', ' ')]
      });
      this.loadBreadCrumb = true;
      this.model = employeeEditModel;
      this.managersSelectListItem = managers;
      this.rolesSelectListItem = roles;
      this.countrySelectListItem = countries;
      this.loadStates(this.model.country);

      this.imgSrc = this.model.profileImgUrl;
      this.isImageRender = true;


      this.form.controls.firstName.setValue(this.model.firstName);
      this.form.controls.middleName.setValue(this.model.middleName);
      this.form.controls.lastName.setValue(this.model.lastName);
      this.form.controls.accountCode.setValue(this.model.accountCode);
      this.form.controls.address1.setValue(this.model.address1);
      this.form.controls.address2.setValue(this.model.address2);
      this.form.controls.address3.setValue(this.model.address3);
      this.form.controls.adminNotes.setValue(this.model.adminNotes);
      this.form.controls.city.setValue(this.model.city);
      this.form.controls.country.setValue(this.model.country);
      this.form.controls.dob.setValue(this.ngbDateParserFormatter.parse(this.model.dob));
      this.form.controls.email.setValue(this.model.email);
      this.form.controls.emailSignature.setValue(this.model.emailSignature);
      this.form.controls.fax.setValue(this.model.fax);
      this.form.controls.generalNotes.setValue(this.model.generalNotes);
      this.form.controls.isTrackLastLocation.setValue(this.model.isTrackLastLocation);
      this.form.controls.manager.setValue(this.model.managerId);
      this.form.controls.preferedRegions.setValue(this.model.preferedRegions);
      this.form.controls.preferedServices.setValue(this.model.preferedServices);
      this.form.controls.role.setValue(this.model.role);
      this.form.controls.state.setValue(this.model.state);
      this.form.controls.tags.setValue(this.model.tags);
      this.form.controls.title.setValue(this.model.title);
      this.form.controls.zip.setValue(this.model.zip);
      this.isContactLoaded = true;
      this.form.controls.is_active.setValue(this.model.isActive);  
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate(['/staff']);
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });

  }

  onSubmit(): void {
    debugger;
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.contactNos = []; // reset values for phone numbers
    this.model.firstName = this.form.controls.firstName.value ?? '';
    this.model.middleName = this.form.controls.middleName.value ?? '';
    this.model.lastName = this.form.controls.lastName.value ?? '';
    this.model.accountCode = this.form.controls.accountCode.value ?? '';
    this.model.address1 = this.form.controls.address1.value ?? '';
    this.model.address2 = this.form.controls.address2.value ?? '';
    this.model.address3 = this.form.controls.address3.value ?? '';
    this.model.adminNotes = this.form.controls.adminNotes.value ?? '';
    this.model.city = this.form.controls.city.value ?? '';
    this.model.country = this.form.controls.country.value ?? '';
    this.model.dob = this.ngbDateParserFormatter.format(this.form.controls.dob.value) ?? '';
    this.model.email = this.form.controls.email.value ?? '';
    this.model.emailSignature = this.form.controls.emailSignature.value ?? '';
    this.model.fax = this.form.controls.fax.value ?? '';
    this.model.generalNotes = this.form.controls.generalNotes.value ?? '';
    this.model.isTrackLastLocation = this.form.controls.isTrackLastLocation.value as boolean;
    this.model.managerId = this.form.controls.manager.value ?? '';
    this.model.preferedRegions = this.form.controls.preferedRegions.value ?? '';
    this.model.preferedServices = this.form.controls.preferedServices.value ?? '';
    this.model.profileImg = this.form.controls.profileImg.value;
    this.model.role = this.form.controls.role.value ?? '';
    this.model.state = this.form.controls.state.value ?? '';
    this.model.tags = this.form.controls.tags.value;
    this.model.title = this.form.controls.title.value ?? '';
    this.model.zip = this.form.controls.zip.value ?? '';
    this.model.isActive = this.form.controls.is_active.value;
    this.form.value.dynamicRows.map((x: { id: number, entityId: string,
      phoneType: string; phoneNum: string; extn: string; }) =>
    this.model.contactNos.push({
        contactTypeId: Number(x.phoneType),
        contactNumber: x.phoneNum,
        extensionNote: (x.extn === 'null' || !x.extn) ? '' : x.extn,
        id: x.id > 0 ? x.id : 0,
        entityId:  0
      })
    );
    this.blockUI.start();
    this.staffService.put(this.model).subscribe(
      (data: { isSuccess: boolean, message: string }) => {
        setTimeout(() => {
          this.router.navigate(['/staff']);
        }, 10);
        setTimeout(() => {
          this.toasterService.success('Employee has been updated successfully');
        }, 300);
        this.blockUI.stop();
      },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }


  addCustomTag = (term) => term;


  private loadTags(): void {
    this.tags$ = concat(
      of([]), // default items
      this.tagsInput$.pipe(
        debounceTime(200),
        filter(res => {
          return res !== null && res.length >= 0;
        }),
        distinctUntilChanged(),
        switchMap(term => {
          return this.staffService.getStaffTags(term).pipe(
            catchError(() => of([])) // empty list on error
          );
        })
      )
    );
  }

  private loadStates(countryName: any): void {
    if (countryName === null || countryName === undefined || countryName.length === 0) {
      return;
    }
    const country = this.countrySelectListItem.find(x => x.name === this.model.country);
    if (!country) {
      return;
    }

    this.blockUI.start();
    this.countryService.getStates(country.id)
      .subscribe((data: any) => {
        this.stateSelectListItem = data;
      }, error => {
        this.toasterService.ProcessErrorResponse(error);
      }, () => {
        this.blockUI.stop();
      });

  }

  getFormValidationErrors(): void {
    Object.keys(this.form.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
        });
      }
    });
  }

}
