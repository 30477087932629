import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AssignSalespersonModel } from 'src/app/models/client/assign-salesperson';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { ClientService } from 'src/app/services/client.service';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';

@Component({
  selector: 'app-assign-salesperson',
  templateUrl: './assign-salesperson.component.html',
  styleUrls: ['./assign-salesperson.component.css']
})
export class AssignSalespersonComponent implements OnInit {
  @Input() public clientIds: number[];
  @Output() assignClick = new EventEmitter();
  modalConfig: any;
  form: FormGroup;
  isSubmitted = false;
  @BlockUI('container-blockui-assign-salesperson') blockUI: NgBlockUI;
  salesperson: SelectListItem<string>[];

  constructor(private staffService: StaffService,
              private toasterService: ToasterService,
              private clientService: ClientService,
              private ngbActiveModal: NgbActiveModal,
              private formBuilder: FormBuilder) {
    this.modalConfig = {
      modalTitle: 'Assign Salesperson',
      dismissButtonLabel: 'Cancel',
      closeButtonLabel: 'Assign',
      shouldClose: () => {
        this.AssingSalesperson();
      },
      shouldDismiss: () => {
        this.closeModal();
      }
    };
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      salesperson: ['', Validators.required],
    });
    this.loadSalespersons();
  }

  private loadSalespersons(): void {
    this.blockUI.start();
    this.staffService.getSalespersons().subscribe(next => {
      this.salesperson = next;
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  AssingSalesperson(): void {
    this.isSubmitted = true;
    if (this.form.invalid){
      return;
    }

    const model = new AssignSalespersonModel();
    model.clientIds = this.clientIds;
    model.salespersonId = this.form.value.salesperson;
    this.blockUI.start();
    this.clientService.assignSalesperson(model).subscribe(next => {
      this.toasterService.success(next);
      this.assignClick.emit(next);
      this.ngbActiveModal.dismiss('save click');
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  closeModal(): void{
    this.ngbActiveModal.close('cancel click');
  }
}
