<div id="mySidenav" class="sidenavbar"  [ngClass]="  filterOpen == true ? 'openFilter' : 'closeFilter'  ">
    
    <div class="closebtn" (click)="closeFilterNav(false)" >&times;</div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div id="sidenavForm" class="sidenav-form sidenav-bottom-btn d-flex flex-column">
            <h3 class="text-dark-blue br-bottom-border">Filter Results</h3>
            <div class="form-group has-search pt-3 position-relative">
                <label for="filterBy">Filter By</label>
                <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                <div class="input-group position-relative">
                    <ng-select ngClas="w-100" [items]="filterBy" bindLabel="name" bindValue="id" groupBy="group"
                        formControlName="filter" name="select-filter1">
                        <ng-template ng-optgroup-tmp let-item="item">
                            {{item.group || json}}
                        </ng-template>
                    </ng-select>
                    <br>
                </div>
            </div>
            <div class="form-group has-search" *ngIf="isFilterInAvailable">
                <label for="In">In</label>
                <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                <div class="input-group position-relative">
                    <ng-select ngClas="w-100" [items]="filterIn" bindLabel="name" bindValue="id" groupBy="group"
                        formControlName="filterIn"></ng-select>
                    <div class="input-group-append position-absolute append-btn">
                        <button class="border-0 bg-transparent" type="button">
                            <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="isSearchingAvailable">
                <div class="form-group has-search">
                    <label for="searchBy">Search by</label>
                    <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                    <div class="input-group position-relative">
                        <ng-select  ngClas="w-100" [items]="searchBy" bindLabel="name" bindValue="id" groupBy="group"
                            formControlName="searchBy"></ng-select>
                            <ng-template ng-optgroup-tmp let-item="item">
                                {{item.group || json}}
                            </ng-template>
                        <!-- <div class="input-group-append position-absolute append-btn">
                            <button class="border-0 bg-transparent" type="button">
                                <i class="text-white rounded-circle bg-orange align-middle"
                                    data-feather="chevron-down"></i>
                            </button>
                        </div> -->
                    </div>
                </div>
                <div class="form-group has-search">
                    <label for="searchFor">For</label>
                    <span class="formisFilterCalendar-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                    <div class="input-group position-relative">
                        <i class="fas fa-search"></i>
                        <input name="for" type="text" class="form-control" placeholder="For"
                            formControlName="searchFor" />
                    </div>
                </div>
                <div class="form-group has-search" *ngIf="isFilterCalendar">
                    <label for="In">All Calendar</label>
                    <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                    <div class="input-group position-relative">
                    <ng-select ngClas="w-100" [items]="filterCalendar" bindLabel="name" bindValue="id" groupBy="group" formControlName="filterCalendar"></ng-select>
                    <div class="input-group-append position-absolute append-btn">
                    <button class="border-0 bg-transparent" type="button">
                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                    </button>
                    </div>
                    </div>
                </div>
                    
                    <div class="form-group has-search" *ngIf="isFilterServices">
                    <label for="In">All Services</label>
                    <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                    <div class="input-group position-relative">
                    <ng-select ngClas="w-100" [items]="filterServices" bindLabel="name" bindValue="id" groupBy="group" formControlName="filterServices"></ng-select>
                    <div class="input-group-append position-absolute append-btn">
                    <button class="border-0 bg-transparent" type="button">
                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                    </button>
                    </div>
                    </div>
                    </div>
                    
                    <div class="form-group has-search" *ngIf="isFilterClients">
                    <label for="In">All Clients</label>
                    <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                    <div class="input-group position-relative">
                    <ng-select ngClas="w-100" [items]="filterIn" bindLabel="name" bindValue="id" groupBy="group" formControlName="filterIn"></ng-select>
                    <div class="input-group-append position-absolute append-btn">
                    <button class="border-0 bg-transparent" type="button">
                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                    </button>
                    </div>
                    </div>
                    </div>
                    
                    <div class="form-group has-search" *ngIf="isFilterCaseManagers">
                    <label for="In">All Case Managers</label>
                    <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                    <div class="input-group position-relative">
                    <ng-select ngClas="w-100" [items]="filterIn" bindLabel="name" bindValue="id" groupBy="group" formControlName="filterIn"></ng-select>
                    <div class="input-group-append position-absolute append-btn">
                    <button class="border-0 bg-transparent" type="button">
                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                    </button>
                    </div>
                    </div>
                    </div>
                    
                    
                
                        
            </div>

            <div class="text-right filter-btn filter-btn-bottom d-flex justify-content-end align-items-end">
                <ol class="list-unstyled d-flex align-items-center justify-content-end">
                    <li>
                        <button type="reset" (click)="onResetFilters()" class="resbtn">
                            Reset Filters
                        </button>
                    </li>
                    <li>
                        <button type="submit" class="btn btn-lg bg-blue text-white ml-2">
                            <i class="align-middle fas fa-check-circle"></i> Apply Filters
                        </button>
                    </li>
                </ol>
            </div>
        </div>
    </form>
</div>