<div class="row">
    <div class="col-md-12">
      
        <button class="btn btn-lg bg-blue text-white float-right ml-3"  (click)="filterNavStatus = true">
            <i class="align-middle fas fa-filter"></i>
        </button>

        <button class="btn btn-lg bg-white float-right"  [routerLink]="['/case-updates/13/new-case-update']">
            <i class="align-middle fas fa-plus-circle"></i> New Update
        </button>

        

    </div>
</div>


<div class="row mt-4">
    <div class="col-md-12">

        <div class="card">
            <div class="card-body">
        
                <table>
                    <tr>
                        <td>
        
                        </td>
                    </tr>
                </table>
        
                <div class="row mx-0">
                    <div class="col-12 p-4 bg-white rounded shadow-sm mb-5">
                        <div class="d-flex justify-content-between align-items-center mb-5">
                            <div class="d-flex align-items-center">
                                

                                <img [src]="profileImgUrl" class="img-fluid rounded-circle mr-3 " width="70">

                                <div class="">
                                    <h4 class="mb-0 text-dark-blue font-weight-bold">Tyler Jackson <span class="font-weight-normal text-muted">&lt;jackson_t@gmail.com&gt;</span></h4>
                                    <div class="nav-link p-0 pt-2"  aria-expanded="false">
                                        <span class="text-dark"><small class="text-muted">to</small> 
                                            Nathaniel.haydon@sedgwick.com

                                            <div ngbDropdown class="d-inline-block">
                                                <div class="btn btn-transparent"  id="dropdownBasic1" ngbDropdownToggle></div>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                  <button ngbDropdownItem>Action - 1</button>
                                                  <button ngbDropdownItem>Another Action</button>
                                                  <button ngbDropdownItem>Something else is here</button>
                                                </div>
                                              </div>

                                        </span>

                                        
                                    </div>

                                    
                                    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right py-0" aria-labelledby="messagesDropdown" style="">
                                        <div class="p-3">
                                            <table cellpadding="0">
                                                <tbody>
                                                <tr>
                                                    <td colspan="2" tabindex="0"><span>from:</span></td>
                                                    <td colspan="2" tabindex="0" class="pl-2"><span><span role="gridcell" tabindex="-1"><span class="font-weight-bold text-dark" email="jackson_t@gmail.com" name="Tyler Jackson" data-hovercard-id="jackson_t@gmail.com">Tyler Jackson</span> <span><span aria-hidden="true">&lt;</span>jackson_t@gmail.com<span aria-hidden="true">&gt;</span></span> </span></span></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" tabindex="0"><span>to:</span></td>
                                                    <td colspan="2" tabindex="0" class="pl-2 text-dark"><span></span></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" tabindex="0"><span>bcc:</span></td>
                                                    <td colspan="2" tabindex="0" class="pl-2 text-dark"><span><span email="Nathaniel.haydon@sedgwick.com" data-hovercard-id="Nathaniel.haydon@sedgwick.com">Nathaniel.haydon@sedgwick.com</span><br></span></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" tabindex="0"><span>date:</span></td>
                                                    <td colspan="2" tabindex="0" class="pl-2 text-dark"><span>Jul 15, 2020, 11:33 AM</span></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" tabindex="0"><span>subject:</span></td>
                                                    <td colspan="2" tabindex="0" class="pl-2 text-dark"><span>Lorem Ipsum Text</span></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" tabindex="0"><span>mailed-by:</span></td>
                                                    <td colspan="2" tabindex="0" class="pl-2 text-dark"><span>xyzabc.tech</span></td>
                                                </tr>
                                                <tr>
                                                    <td colspan="2" tabindex="0"><span>signed-by:</span></td>
                                                    <td colspan="2" tabindex="0" class="pl-2 text-dark"><span>xyzabc.tech</span></td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="time-right">
                                <p class="text-light-black mb-0">28 Dec 11:49 PM</p>
                            </div>
                        </div>
                        <div class="main-mail-column mb-5">
                            <h1 class="text-dark-blue mb-4 font-weight-bold">New Designer Meetup Group!</h1>
                            <div class="mail-text-area">
                                <p class="text-light-black mb-3">Hi Anthony Miller,</p>
                                <p class="text-light-black mb-3">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum. Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidun.</p>
                                <p class="text-light-black mb-0">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, Have a nice day <i class="align-middle far fa-smile"></i></p>
                                <p class="text-light-black mb-3">Tyler Jackson.</p>
                                <div class="mail-signature">
                                    <p class="text-light-black mb-0">Shereen Fahrai</p>
                                    <p class="text-light-black">Case Management</p>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="d-flex align-items-center justify-content-between attachment pt-3 mb-4">
                            <h4 class="mb-0 text-dark-blue font-weight-500">Attachments <span class="attachment-badge badge rounded-circle bg-orange text-white">3</span></h4>
                            <button class="btn mb-0 text-orange font-weight-500 shadow-none btn-transparent"><i class="align-middle fas fa-download mr-2 "></i>Download All</button>
                        </div>
                        <div class="image-attachment d-flex">
                            <div>
                               
                                <div class="bigSize"  [innerHTML]="appUtil.genrateIconExtension('asd.txt')">
                                </div>
                                
                            </div>
                            <div>
                                <div class="bigSize"  [innerHTML]="appUtil.genrateIconExtension('asd.pdf')">
                                </div>
                            </div>
                            <div>
                                <div class="bigSize"  [innerHTML]="appUtil.genrateIconExtension('asd.jpg')">
                                </div>
                            </div>
                        </div>
                        <div class="user-header py-4 mb-4"></div>
                        <div class="add-comment">
                            <form action="#" method="post" class="form-horizontal" id="commentForm" role="form"> 
                                <div class="form-group d-flex">
                                  

                                    <img [src]="profileImgUrl" class="avatar img-fluid rounded-circle mr-3 " width="70">

                                    <div class="col-sm pr-0">
                                        <textarea class="form-control p-3 w-100" name="addComment" id="addComment" rows="8" placeholder="Type a message..."></textarea>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-sm-offset-2 col-sm-12 text-right pr-0">                    
                                        <button class="py-0 btn bg-orange btn-circle text-white font-weight-500" type="submit" id="submitComment"><i class="fas fa-paper-plane"></i> Send Message</button>
                                    </div>
                                </div>            
                            </form>
                        </div>
                    </div>
                </div>
        
        
            </div>
        </div>





    </div>
</div>

