import { CompanyLocationFilterOptions, CompanyLocationSearchOptions } from '../../enums/filters-and-searchs/company.location.search&filter.options';

export const CompanyLocationSearchOptionsDetails = new Map<number, string>([
    [CompanyLocationSearchOptions.Name, 'Name'],
    [CompanyLocationSearchOptions.Address, 'Address'],
    [CompanyLocationSearchOptions.CaseManager, 'Case Manager'],
    [CompanyLocationSearchOptions.SalesPerson, 'Salesperson'],
    [CompanyLocationSearchOptions.AccountCode, 'Account Code'],
    [CompanyLocationSearchOptions.Tag, 'Tag']
]);

export const CompanyLocationFilterOptionsDetails = new Map<number, string>([
    [CompanyLocationFilterOptions.None, 'None'],
    [CompanyLocationFilterOptions.Deleted, 'Deleted Locations'],
]);
