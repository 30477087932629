import { Component, OnInit } from '@angular/core';


interface Country {
  name: string;
  area: number;
  population: number;
}

const COUNTRIES: Country[] = [
  {
    name: 'Travel time',
    area: 1.0,
    population: 108.75
  },
  {
    name: 'SV - Investigation Time',
    area: 3.75,
    population: 29.00
  }
];

@Component({
  selector: 'app-payment-slip-overview',
  templateUrl: './payment-slip-overview.component.html',
  styleUrls: ['./payment-slip-overview.component.css']
})
export class PaymentSlipOverviewComponent implements OnInit {

  opened: boolean;
  countries = COUNTRIES;

  constructor() { }

  ngOnInit(): void {
  }

}
