<div class="row">
    <div class="col-md-9">
      <div class="row">
        <div class="col-md-12">
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-3">
            <ngb-panel id="custom-panel-3">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold">Request Details</h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Due Date</div>
                  <div class="col-md-9">Jun 18, 2018 (over 3 years)</div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Case Type</div>
                  <div class="col-md-9">{{primaryLocation.locationName}}</div>
                </div>
                
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Case Services</div>
                  <div class="col-md-9">{{model.salesPersonName}} </div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Claim Number</div>
                  <div class="col-md-9">{{model.caseManagerName}}</div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Insured</div>
                  <div class="col-md-9">{{model.accountCode}} </div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">DOL</div>
                  <div class="col-md-9"><a href="{{model.webSite}}">{{model.webSite}}</a></div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Budget</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <hr>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Referral Source</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Defense Atty</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Depo Date</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">HD</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Dec. Date</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">SIU File Review</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Success Story</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Success Story Summary</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Estimated Claims Savings</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Policy Number</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>


                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Policy Period</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Origin</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <hr>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Admin Notes</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Scheduling Notes</div>
                  <div class="col-md-9">Please contact the Supervisor Donavan Philby @ 619-346-0971 and set meeting to take his statement and obtain any records he has to support actions taken that lead up to the suspension of the employee. Also inquire if there are others that may be aware of the issues and would be of benefit to obtain their statements also.</div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Notes & Instructions</div>
                  <div class="col-md-9">Delayed litigated claim for CT injury to his back, neck, shoulder waist, knees, neuro, stress & sleep disorder. I need complete background check, social media and medical canvass. I also need recorded statements of Supervisor and possible coworkers</div>
                </div>

              
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
       
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="row " id="stickyoney">

        <div class="col-md-12">
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-2">
            <ngb-panel id="custom-panel-2">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0  font-weight-bold">Status</h5>
  
  
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
  
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row">
                  <div class="col-md-12 mb-3">
  
                   
                   <span  class="text-success font-weight-bold">Accepted </span>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
  
  
          </ngb-accordion>
        </div>

        <div class="col-md-12">
            <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-0">
              <ngb-panel id="custom-panel-0">
                <ng-template ngbPanelHeader let-opened="opened">
                  <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0  font-weight-bold">Requested By</h5>
    
    
                    <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                        class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
    
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="row">
                    <div class="col-md-12 mb-3">
    
                     <p>
                       
<b>Norma Jean Tsutsui</b>
ntsutsui@semprautilities.com
(858) 636-5518 (office)
(866) 402-6718 (fax)<br><br>
<b> San Diego Gas & Electric (SDG&E) (San Diego Gas & Electric)</b>
PO Box 12349
San Diego, CA 92112
                     </p>
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
    
    
            </ngb-accordion>
          </div>
          
        
        <div class="col-md-12 mt-3">
          <ngb-accordion #a="ngbAccordion">
            <ngb-panel id="custom-panel-2">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0  font-weight-bold">Related Cases</h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row quicklinks">
                  <div class="col-md-12 mb-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="list-group mb-3" *ngFor="let quick of quicklink; index as i; ">
                          <div class="list-group quickhead">
                            {{quick.mainmenu}}
                          </div>
                          <ul class="list-group">
                            <li class="list-group-item border-0" *ngFor="let submen of quicklink[i].submenu; index as x">
                              <a class="link" href="{{submen.link}}">{{submen.name}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>
  </div>