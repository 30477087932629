export enum StaffFilterOptions {
    None = 1,
    WithoutMFA = 2,
    WithStaleAccount = 3,
    WithStalePassword = 4,
    WithVulnerablePasswords = 5,
    DeletedStaffs = 6,
    AccessLevel = 7,
    StaffManagers = 8
}

export enum StaffSearchOptions {
    Name = 1,
    Title = 2,
    Email = 3,
    officePhone = 4,
    Tags = 5,
    AccountCode = 6,
    BirthDay = 7,
    StateOrProvince = 8
}