<main class="content py-0 pr-0">
  
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-md-8 col-lg-7 col-xl-8 col-xxl-8 pt-7">
        <div class="w-100 pt-4">
          <div class="row mb-2 mb-xl-3">
            <div class="col-auto d-none d-sm-block">
              <h3 class="page-title"><strong>Dashboard</strong></h3>
              <p>Get all the customers info at all one place</p>
            </div>

            <!-- <div class="col-auto ml-auto text-right mt-n1">
                <nav aria-label="breadcrumb">
                  <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                    <li class="breadcrumb-item"><a href="#">AdminKit</a></li>
                    <li class="breadcrumb-item"><a href="#">Dashboards</a></li>
                    <li class="breadcrumb-item active" aria-current="page">Analytics</li>
                  </ol>
                </nav>
              </div> -->
          </div>
          <div class="row">
            <div class="col mob-pr-2">
              <div class="card">
                <div class="card-body p-3 mob-p-3 mob-px-2">
                  <a
                    class="rounded-circle bg-success card-icon d-block text-center mb-3"
                  >
                    <i class="text-white align-middle fas fa-envelope"></i>
                  </a>
                  <h1 class="display-5 font-weight-bold">105</h1>
                  <div class="mb-1">
                    <span class="text-muted d-block mb-2 mob-text-muted"
                      >Total Messages</span
                    >
                    <span class="text-success">
                      <i class="fas fa-arrow-up dashboard-rotate-up-arrow"></i>
                      +3.49%
                    </span>
                  </div>
                </div>
              </div>
              
            </div>
            <div class="col mob-pr-2">
              <div class="card">
                <div class="card-body p-3 mob-p-3 mob-px-2">
                  <a
                    class="rounded-circle bg-primary card-icon d-block text-center mb-3"
                  >
                    <i class="text-white align-middle fas fa-file"></i>
                  </a>
                  <h1 class="display-5 font-weight-bold">755</h1>
                  <div class="mb-1">
                    <span class="text-muted d-block mb-2 mob-text-muted"
                      >Total Cases</span
                    >
                    <span class="text-success">
                      <i class="fas fa-arrow-up dashboard-rotate-up-arrow"></i>
                      +3.49%
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col mob-pr-2">
              <div class="card">
                <div class="card-body p-3 mob-p-3 mob-px-2">
                  <a
                    class="rounded-circle bg-warning card-icon d-block text-center mb-3"
                  >
                    <i class="text-white align-middle fas fa-users"></i>
                  </a>
                  <h1 class="display-5 font-weight-bold">06</h1>
                  <div class="mb-1">
                    <span class="text-muted d-block mb-2 mob-text-muted"
                      >Total Clients</span
                    >
                    <span class="text-success">
                      <i class="fas fa-arrow-up dashboard-rotate-up-arrow"></i>
                      +3.49%
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col mob-pr-2">
              <div class="card">
                <div class="card-body p-3 mob-p-3 mob-px-2">
                  <a
                    class="rounded-circle bg-orange-two card-icon d-block text-center mb-3"
                  >
                    <i
                      class="text-white align-middle fas fa-money-bill-alt"
                    ></i>
                  </a>
                  <h1 class="display-5 font-weight-bold">45</h1>
                  <div class="mb-1">
                    <span class="text-muted d-block mb-2 mob-text-muted"
                      >Total Investigation</span
                    >
                    <span class="text-success">
                      <i class="fas fa-arrow-up dashboard-rotate-up-arrow"></i>
                      +3.49%
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col">
              <div class="card">
                <div class="card-body p-3 mob-p-3 mob-px-2">
                  <a
                    class="rounded-circle bg-pink card-icon d-block text-center mb-3"
                  >
                    <i class="text-white align-middle fas fa-user-friends"></i>
                  </a>
                  <h1 class="display-5 font-weight-bold">95</h1>
                  <div class="mb-1">
                    <span class="text-muted d-block mb-2 mob-text-muted"
                      >Total Distributors</span
                    >
                    <span class="text-danger">
                      <i class="fas fa-arrow-up dashboard-rotate-down-arrow"></i>
                      -1.8%
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-6 col-xl-6 col-xxl-6">
            <div class="card flex-fill w-100 mb-4">
              <div class="card-header rounded p-4 mob-px-4">
                <h4 class="team-title font-weight-bold mb-0">
                  Total HR <small>(256)</small>
                </h4>
                <div class="card-body pl-0">
                  <div class="hr-image position-relative">
                    <img 
                      class="avatar avatar-md media-object rounded-circle"
                      src="img/avatars/avatar-2.jpg"
                      alt="Generic placeholder image"
                    />
                    <img 
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-1"
                      src="img/avatars/avatar-3.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-2"
                      src="img/avatars/avatar-4.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-3"
                      src="img/avatars/avatar-5.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-4"
                      src="img/avatars/avatar.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-5"
                      src="img/avatars/avatar-3.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-6"
                      src="img/avatars/avatar-4.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-7"
                      src="img/avatars/avatar-5.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-8"
                      src="img/avatars/avatar.jpg"
                      alt="Generic placeholder image"
                    />
                    <a
                      href="javascript:void(0);"
                      class="text-white text-decoration-none position-absolute avatar avatar-md media-object bg-orange rounded-circle text-center hr-9"
                      ><i class="align-middle fas fa-plus text-white"></i>250</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xl-6 col-xxl-6">
            <div class="card flex-fill w-100 mb-0">
              <div class="card-header rounded p-4 mob-px-4">
                <h4 class="team-title font-weight-bold mb-0">
                  Total Quality Controller <small>(45)</small>
                </h4>
                <div class="card-body pl-0">
                  <div class="hr-image position-relative">
                    <img onerror=""
                      class="avatar avatar-md media-object rounded-circle"
                      src="img/avatars/avatar-2.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror="" 
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-1"
                      src="img/avatars/avatar-3.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-2"
                      src="img/avatars/avatar-4.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-3"
                      src="img/avatars/avatar-5.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-4"
                      src="img/avatars/avatar.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-5"
                      src="img/avatars/avatar-3.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-6"
                      src="img/avatars/avatar-4.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-7"
                      src="img/avatars/avatar-5.jpg"
                      alt="Generic placeholder image"
                    />
                    <img onerror=""
                      class="position-absolute avatar avatar-md media-object rounded-circle hr-8"
                      src="img/avatars/avatar.jpg"
                      alt="Generic placeholder image"
                    />
                    <a
                      href="javascript:void(0);"
                      class="text-white text-decoration-none bg-blue position-absolute avatar avatar-md media-object bg-orange rounded-circle text-center hr-9"
                      ><i class="align-middle fas fa-plus text-white"></i>38</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col-xl-12 col-xxl-12">
            <div class="card flex-fill w-100">
              <div
                class="card-header d-flex justify-content-between align-items-center"
              >
                <h3 class="chart-title font-weight-bold mb-0">
                  Monthly User Registered
                </h3>

                <div class="btn-group">
                  <a
                    class="btn btn-light dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    >2016 -2017 <span class="caret"></span
                  ></a>
                  <ul class="dropdown-menu">
                    <li class="dropdown-item"><a href="#">2017 - 2018</a></li>
                    <li class="dropdown-item"><a href="#">2019 - 2020</a></li>
                    <li class="dropdown-item"><a href="#">2020 - 2021</a></li>
                  </ul>
                </div>
              </div>
              <div class="card-body d-flex w-100">
                <div class="align-self-center chart">
                  <div id="chartjs-dashboard-bar"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="card flex-fill w-100">
              <div
                class="card-header d-flex justify-content-between align-items-center"
              >
                <h4 class="chart-title font-weight-bold mb-0">
                  Monthly vs. Daily Case Registered
                </h4>
                <div class="btn-group">
                  <a
                    class="btn btn-light dropdown-toggle"
                    data-toggle="dropdown"
                    href="#"
                    >2016 -2017 <span class="caret"></span
                  ></a>
                  <ul class="dropdown-menu">
                    <li class="dropdown-item"><a href="#">2017 - 2018</a></li>
                    <li class="dropdown-item"><a href="#">2019 - 2020</a></li>
                    <li class="dropdown-item"><a href="#">2020 - 2021</a></li>
                  </ul>
                </div>
              </div>
              <div class="p-4 d-flex leads">
                <h2
                  class="title-ml-spacing text-right font-weight-bold text-orange border-right pr-4"
                >
                  117
                  <span
                    class="font-weight-normal spacing-0 lead d-block text-muted"
                    >Leads</span
                  >
                </h2>
                <h2 class="font-weight-bold text-dark-blue pl-4">
                  06
                  <span
                    class="font-weight-normal spacing-0 lead d-block text-muted"
                    >Policies in Progress</span
                  >
                </h2>
              </div>
              <div class="card-body d-flex w-100">
                <div class="align-self-center chart chart-sm">
                  <div id="dashboard-bar"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4 col-lg-5 col-xl-4 sidebar-right pt-6">
        <div class="col-12 h-100 pr-0">
          <div class="bg-white h-100 right-sidebar">
            <div class="flex-fill">
              <div class="card-body d-flex mob-px-4">
                <div class="align-self-center w-100">
                  <div class="calendar">
                    
                   
                    <ngb-datepicker #d ngClass="newdatepick"></ngb-datepicker>
                      
                    

                  </div>
                </div>
              </div>
              <div class="px-4 mob-px-4">
                <hr />
              </div>
              <div
                class="d-flex justify-content-between align-items-center p-4 mob-p-4"
              >
                <h4 class="mb-0 font-weight-bold">
                  Upcoming Tasks <small>(14)</small>
                </h4>
                <a class="text-orange" href="javascript:void(0);"
                  >View all
                  <i class="ml-2 align-middle fas fa-chevron-right"></i
                ></a>
              </div>
              <div class="taskTime px-4 mob-px-4">
                <ul class="list-unstyled">
                  <li>08:00</li>
                  <li>
                    08:30
                    <div class="position-relative task-primary">
                      <span class="bg-light-blue">
                        <h4 class="text-light-blue taskTitle">
                          Award Show Discussion
                        </h4>
                        <small class="text-Exlight-blue"
                          >09:00 AM — 10:00 AM</small
                        >
                      </span>
                    </div>
                  </li>
                  <li>
                    09:00
                    <div class="position-relative task-primary active">
                      <span class="left-5 bg-light-blue">
                        <h4 class="taskTitle text-light-blue">
                          New Branding work Averics
                        </h4>
                        <small class="text-Exlight-blue"
                          >11:00 AM — 12:30 AM</small
                        >
                      </span>
                    </div>
                  </li>
                  <li>09:30</li>
                  <li>10:00</li>
                  <li>
                    10:30
                    <div class="position-relative task-primary">
                      <span class="bg-acqua border-acqua">
                        <h4 class="text-acqua taskTitle">
                          Speakers Allotment Jackos
                        </h4>
                        <small class="text-light-acqua"
                          >12:00 PM — 03:30 AM</small
                        >
                      </span>
                    </div>
                  </li>
                  <li>11:00</li>
                  <li>11:30</li>
                  <li>
                    12:00
                    <div class="position-relative task-primary">
                      <span class="left-7 bg-light-blue">
                        <h4 class="taskTitle text-light-blue">
                          AmTrust-ER-Pasquale-IC Status
                        </h4>
                        <small class="text-Exlight-blue"
                          >12:00 AM — 12:30 AM</small
                        >
                      </span>
                    </div>
                  </li>
                  <li>12:30</li>
                  <li>
                    01:00
                    <div class="position-relative task-primary">
                      <span class="bg-acqua border-acqua">
                        <h4 class="text-acqua taskTitle">
                          Speakers Allotment Jackos
                        </h4>
                        <small class="text-light-acqua"
                          >12:00 PM — 03:30 AM</small
                        >
                      </span>
                    </div>
                  </li>
                  <li>01:30</li>
                  <li>02:00</li>
                  <li>02:30</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
