<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>New Payment Method</strong> </h3>
                        </div>

                    </div>


                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form"  [formGroup]="form" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
                                          
                                            <div class="card-body">
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="name">Name<sup class="clsStric">*</sup></label>
                                                                    
                                                                    <input type="text" formControlName="name" class="form-control mb-3" id="label" name="name" autocomplete="off">
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="description">Description</label>
                                                                    
                                                                    <input type="text" formControlName="description" class="form-control mb-3" id="description" name="description" autocomplete="off">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <hr>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="custom-control custom-switch">
                                                                    <input type="checkbox" formArrayName="orders" formControlName="customSwitch1" class="custom-control-input" id="customSwitch1">
                                                                    <label class="custom-control-label"  for="customSwitch1"><b>Apply as Credit</b> <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="If checked, these payments will not count toward income received."></i></label>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="custom-control custom-switch">
                                                                    <input type="checkbox" formArrayName="orders" formControlName="customSwitch2" class="custom-control-input" id="customSwitch2">
                                                                    <label class="custom-control-label"  for="customSwitch2"><b>Set as Default Method</b> <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="If checked, payments will have this method selected by default."></i></label>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <hr>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="reference_id">Reference ID <sup class="clsStric">*</sup>  <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="An optional reference number or lookup code for this item."></i></label>
                                                                    
                                                                    <input type="text" formControlName="reference_id" class="form-control mb-3" id="reference_id" name="reference_id" autocomplete="off">
                                                                </div>
                                                            </div>

                                                          
                                                        </div>

                                                  


                                                        
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>


                                
                                  
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  [routerLink]="['..']"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="sub">Save Payment Method  <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
