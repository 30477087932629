import { CompanyPolicyVersionHistoryFilterOptions, CompanyPolicyVersionHistorySearchOptions } from '../../enums/filters-and-searchs/companypolicy.versionhistory.search&filter.options';

export const CompanyPolicyVersionHistoryFilterOptionDetails = new Map<number, string>([
[CompanyPolicyVersionHistoryFilterOptions.None, 'None'],
[CompanyPolicyVersionHistoryFilterOptions.Deleted, 'Deleted version history']
]);

export const CompanyPolicyVersionHistorySearchOptionDetails = new Map<number, string>([
[CompanyPolicyVersionHistorySearchOptions.Version, 'Version'],
[CompanyPolicyVersionHistorySearchOptions.VersionNotes, 'Version Notes']
]);
