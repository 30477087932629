<div class="row mt-4">
    <div class="col-md-9">
  
      <div class="row">
        <div class="col-md-12">
  
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-31">
            <ngb-panel id="custom-panel-31">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold">Quick Snapshot
                  </h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i>
                  </button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row so-cards">
                  <div class="col-md-4">
                    <div class="card flex-fill w-100 mb-4 shadow-none">
                      <div class="card-header rounded border p-4 mob-px-4">
                        <div class="user-left-column d-flex align-items-center">
                          <div class="tab-icon-expense bg-warning rounded-circle text-center">
                            <i class="align-middle fas-icon fas fa-file-invoice-dollar text-white"></i>
                          </div>
                          <div class="tab-icon-expense-text ml-4">
                            <h1 class="font-weight-bold mb-0">$ 4,575 </h1>
                            <p class="mb-0">Expenses Counted</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4">
                    <div class="card flex-fill w-100 mb-4 shadow-none">
                      <div class="card-header rounded border p-4 mob-px-4">
                        <div class="user-left-column d-flex align-items-center">
                          <div class="tab-icon-expense bg-pink rounded-circle text-center">
                            <i class="align-middle fas-icon fas fa-hourglass-half text-white"></i>
                          </div>
                          <div class="tab-icon-expense-text ml-4">
                            <h1 class="font-weight-bold mb-0">5.4 </h1>
                            <p class="mb-0">Hours Counted</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-4">
                    <div class="card flex-fill w-100 mb-4 shadow-none">
                      <div class="card-header rounded border p-4 mob-px-4">
                        <div class="user-left-column d-flex align-items-center">
                          <div class="tab-icon-expense badge-success rounded-circle text-center">
                            <i class="align-middle fas-icon fas fa-star text-white"></i>
                          </div>
                          <div class="tab-icon-expense-text ml-4">
                            <h1 class="font-weight-bold mb-0">4.5 <small class="lead">(100%)</small></h1>
                            <p class="mb-0">Average Rating</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
  
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-3">
            <ngb-panel id="custom-panel-3">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold"><i
                      class="align-middle far fa-file-alt mr-2 fx-2 text-orange"></i>Account Information
                  </h5>
  
                  <div class="so-topright d-flex align-items-baseline">
  
  
  
                    <div class="detail-client-title font-weight-bold mr-3"><span class="">Access Level: </span>
                      <span class="badge {{ accesslevel ? 'badge-success':' badge-danger'}}">{{ accesslevel ?
                        "Enabled":"Disabled"}} </span>
                    </div>
                    <button ngbPanelToggle class="btn btn-link p-0 shadow-none">
  
                      <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                  </div>
  
  
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Name</div>
                  <div class="col-md-9">
                    {{ model.name }}
                  </div>
                </div>
  
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Role</div>
                  <div class="col-md-9">{{model.role}} </div>
                </div>
  
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Manager</div>
                  <div class="col-md-9">
                    <a [routerLink]="['/staff', model.managerId]">
                      {{model.managerName}}</a>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-3x">
            <ngb-panel id="custom-panel-3x">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold"><i
                      class="align-middle far fa-file-alt mr-2 fx-2 text-orange"></i>Contact Information
                  </h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
  
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Primary Email</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.email}}</a></div>
                </div>
  
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Address</div>
                  <div class="col-md-9">
                    <div *ngIf="model.address1">{{model.address1}}</div>
                    <div *ngIf="model.address2">{{model.address2}}</div>
                    <div *ngIf="model.address3">{{model.address3}}</div>
                    <div>
                      <span *ngIf="model.city">{{model.city}},</span>
                      <span *ngIf="model.state"> {{model.state}}</span>
                      <span *ngIf="model.zipCode"> {{model.zipCode}}</span>
                    </div>
                  </div>
                </div>
  
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Last Location</div>
                  <div class="col-md-9"></div>
                </div>
  
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Home Phone</div>
                  <div class="col-md-9">{{model.homePhone}} </div>
                </div>
  
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Contact Number</div>
                  <div class="col-md-9">{{model.contactNumber}}</div>
                </div>
  
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Fax</div>
                  <div class="col-md-9">{{model.fax}}</div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
  
  
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-5">
            <ngb-panel id="custom-panel-5">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold"><i class="align-middle far fa-file-alt mr-2 fx-2 text-orange"></i>Other
                    Information</h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
  
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Date of Birth</div>
                  <div class="col-md-9">{{model.dob}}</div>
                </div>
  
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Account Code</div>
                  <div class="col-md-9">{{model.accountCode}}</div>
                </div>
  
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Tags</div>
                  <div class="col-md-9">
  
                    <ng-select [multiple]="true" [(ngModel)]="model.tags" [ngClass]="'ngselectview'" [clearable]="false"
                      [disabled]="true" [searchable]="false">
                    </ng-select>
  
                  </div>
                </div>
  
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
  
  
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-4">
            <ngb-panel id="custom-panel-4">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold"><i class="align-middle far fa-file-alt mr-2 fx-2 text-orange"></i>Notes
                  </h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
  
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row mb-4" *ngIf="model.pinnedNotes && model.pinnedNotes.length > 0">
                  <div class="col-md-12">
                    <app-pinned-notes-list-view [pinnedNotes]="model.pinnedNotes"></app-pinned-notes-list-view>
                  </div>
                </div>
                
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">General Notes</div>
                  <div class="col-md-9">
                    <p>{{model.generalNotes}}</p>
                  </div>
                </div>
  
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Admin Notes</div>
                  <div class="col-md-9">
                    <p>{{model.adminNotes}}</p>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
  
  
  
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-7">
            <ngb-panel id="custom-panel-7">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold"><i class="align-middle far fa-file-alt mr-2 fx-2 text-orange"></i>Work
                    Preferences</h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
  
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Preferred Regions</div>
                  <div class="col-md-9">{{model.preferedRegions}}</div>
                </div>
  
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Preferred Services</div>
                  <div class="col-md-9">{{model.preferedServices}}</div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
  
        </div>
  
      </div>
  
    </div>
    <div class="col-md-3">
  
      <div class="row " id="stickyoney">

        <div class="col-md-12">
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-0">
            <ngb-panel id="custom-panel-0">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0  font-weight-bold">Photo</h5>
  
  
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
  
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row">
                  <div class="col-md-12 mb-3">
  
                    <!-- for initials avatar -->
                    <!-- <app-avatar-photo  [photoUrl]="userPhoto" [name]="staffName"></app-avatar-photo> -->
  
                    <img [src]="profileImgUrl" class="w-100">
  
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
  
  
          </ngb-accordion>
        </div>
        <div class="col-md-12">
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
            <ngb-panel id="custom-panel-1">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0  font-weight-bold">Expense Snapshot</h5>
  
  
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
  
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row">
                  <div class="col-md-12 mb-3">
                    <div class="row">
                      <div class="col-md-6 font-weight-bold">Today</div>
                      <div class="col-md-6">$0.00</div>
                    </div>
                  </div>
                  <div class="col-md-12 mb-3">
                    <div class="row">
                      <div class="col-md-6 font-weight-bold">This Week</div>
                      <div class="col-md-6">$0.00</div>
                    </div>
                  </div>
                  <div class="col-md-12 mb-3">
                    <div class="row">
                      <div class="col-md-6 font-weight-bold">Last Week</div>
                      <div class="col-md-6">$0.00</div>
                    </div>
                  </div>
  
                  <div class="col-md-12 mb-3">
                    <div class="row">
                      <div class="col-md-6 font-weight-bold">This Month</div>
                      <div class="col-md-6">$0.00</div>
                    </div>
                  </div>
  
                </div>
              </ng-template>
            </ngb-panel>
  
  
          </ngb-accordion>
        </div>
        <div class="col-md-12 mt-3">
  
          <ngb-accordion #a="ngbAccordion">
            <ngb-panel id="custom-panel-2">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0  font-weight-bold">Quick Links</h5>
  
  
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
  
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row quicklinks">
                  <div class="col-md-12 mb-3">
                    <div class="row">
                      <div class="col-md-12">
  
                        <div class="list-group mb-3" *ngFor="let quick of quicklink; index as i; ">
                          <div class="list-group quickhead">
                            {{quick.mainmenu}}
                          </div>
                          <ul class="list-group">
                            <li class="list-group-item border-0" *ngFor="let submen of quicklink[i].submenu; index as x">
                              <a class="link" href="{{submen.link}}">{{submen.name}}</a>
                            </li>
  
                          </ul>
  
                        </div>
  
                      </div>
                    </div>
                  </div>
  
  
                </div>
              </ng-template>
            </ngb-panel>
  
  
          </ngb-accordion>
        </div>
      </div>
  
  
    </div>
  </div>