

<div class="row">
    <div class="col-md-12 mb-3">
        <h4 class="font-weight-bold">Expense Entries </h4>
    </div>
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">

            <div class="card" *ngFor="let item of dataEntries; index as i">
              

                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-{{i}}">
                    <ngb-panel title="{{item.title}}" id="ngb-panel-{{i}}">
                        <ng-template ngbPanelHeader let-opened="opened">
                            <div class="d-flex align-items-center justify-content-between">
                              <h5 class="m-0 font-weight-bold">{{item.title}} </h5>
                              <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                            </div>
                       </ng-template>

                        <ng-template ngbPanelContent>
                            <table class="table table-striped border">
                                <thead>
                                <tr>
                                  <th scope="col">Item</th>
                                  <th scope="col">Hours</th>
                                  <th scope="col"  class="text-right">Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr *ngFor="let sitem of dataEntries[i].submenu; index as j">
                               
                                  <td>
                                 
                                    {{ sitem.name }}
                                  </td>
                                  <td>{{ sitem.hours | number }}</td>
                                  <td  class="text-right">${{ sitem.total | number }}</td>
                                </tr>
                                </tbody>
                              </table>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>

          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-3">
            <ngb-panel id="custom-panel-3">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold">Time Summary</h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <table class="table table-striped border">
                  <thead>
                  <tr>
                    <th scope="col">Item</th>
                    <th scope="col">Hours</th>
                    <th scope="col"  class="text-right">Total</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr *ngFor="let country of countries; index as i">
                 
                    <td>
                   
                      {{ country.name }}
                    </td>
                    <td>{{ country.area | number }}</td>
                    <td  class="text-right">${{ country.population | number }}</td>
                  </tr>
                  </tbody>
                </table>

                <div class="row justify-content-end">
                  <div class="col-md-1">
                    <h5 class="font-weight-bold">$137.75</h5>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>
  
  </div>
