<main class="content py-0">
    <div class="position-fixed menu-btn">
        <span class="curve"></span>
        <a class="sidebar-toggle justify-content-center d-flex rounded-circle bg-orange position-absolute">
            <i class="text-white fas fa-chevron-left align-self-center"></i>
        </a>
    </div>
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12 px-0 pb-5">
                            <div class="col-auto mt-n1">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb bg-transparent custom-breadcrumb p-0 mt-1 mb-0">
                                        <li class="breadcrumb-item"><a href="cases.html">Vendor</a></li>
                                        <li class="breadcrumb-item"><a href="case_list_detail.html">Vendor List</a></li>
                                        <li class="breadcrumb-item active" aria-current="page">New Vendor</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto d-none d-sm-block mb-3">
                            <h3 class="page-title"><strong>New Vendor</strong> </h3>
                            <p>Create new Vendor detail here.</p>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="uploder d-flex align-items-center">
                                                            <span class="pip mr-7">
                                                                <img class="rounded-circle border border-orange-color img-thumbnail mb-4" src="" alt="" width="95">
                                                            </span>
                                                            <div class="field ml-4">
                                                                <!-- <label for="files" button type="file" name="image" class="btn bg-blue text-white px-4 py-2 font-weight-500 mb-0">Upload Photo</button> </label> -->
                                                                <input style="display: none;" type="file" id="files" name="files[]" multiple />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-7">Full Name</label>
                                                            <input type="text" class="form-control" id="Label-7" name="Full Name" value="Namkedik Fredrick">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-8">Title</label>
                                                            <input type="text" class="form-control" id="Label-8" name="Title" value="Namkedik Fredrick">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-9">Manager</label>
                                                            <input type="text" class="form-control" id="Label-9" name="Manager" value="Breedelove Fredrick">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2">
                                                        <div class="form-group has-search">
                                                            <label for="role">Role</label>
                                                            <div class="input-group position-relative">
                                                                <select id="search13">
                                                                    <option value="" selected>Salesperson</option>
                                                                    <option value="AD">Female</option>
                                                                    <option value="BD">Others</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="user-header mb-4">
                                                    <h4 class="user-title font-weight-bold mb-0">Contact Information</h4>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-26">Email</label>
                                                            <input type="email" class="form-control" id="Label-26" name="Email" value="accounting@antrevents.com">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 mb-4">
                                                        <div class="form-group has-search">
                                                            <label for="country">Country</label>
                                                            <div class="input-group position-relative">
                                                                <select id="search12">
                                                                    <option value="" selected>United States</option>
                                                                    <option value="AD">America</option>
                                                                    <option value="BD">Denmark</option>
                                                                    <option value="BD">Others</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-18">Address Line 1</label>
                                                            <input type="text" class="form-control" id="Label-18" name="Address Line 1" value="4051  Hardman Road, Michigan">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-19">Address Line 2</label>
                                                            <input type="text" class="form-control" id="Label-19" name="Address Line 2" value="4051  Hardman Road, Michigan">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-20">Address Line 3</label>
                                                            <input type="text" class="form-control" id="Label-20" name="Address Line 3" value="4051  Hardman Road, Michigan">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-21">City</label>
                                                            <input type="text" class="form-control" id="Label-21" name="City" value="Hardman Road">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-22">State</label>
                                                            <input type="text" class="form-control" id="Label-22" name="state" value="Hardman Road">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-2 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-23">Zip</label>
                                                            <input type="text" class="form-control" id="Label-23" name="zip code" value="20152">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-3 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-16">Phone Number</label>
                                                            <input type="text" class="form-control" id="Label-16" name="Phone Number" value="+1 985 245 8745">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-17">Mobile Number</label>
                                                            <input type="text" class="form-control" id="Label-17" name="Mobile Number" value="+1 985 245 8745">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-27">Home Phone </label>
                                                            <input type="text" class="form-control" id="Label-27" name="Mobile Number" value="+1 985 245 8745">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-28">Fax</label>
                                                            <input type="text" class="form-control" id="Label-28" name="Mobile Number" value="+1 985 245 8745">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="user-header mb-4">
                                                    <h4 class="user-title font-weight-bold mb-0">Other Information</h4>
                                                </div>
                                                <div class="row mb-4">
                                                    <div class="col-md-2">
                                                        <div class="form-group has-search">
                                                            <label>Date of Injury</label>
                                                            <div id="newCasedatepicker8" class="input-group date case-new-date" data-date-format="d-mm-yyyy">
                                                                <input class="form-control border-0 bg-transparent" type="text" readonly="">
                                                                <span class="input-group-addon"><i class="far fa-calendar-alt"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group has-search">
                                                            <label for="language">Language</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Language"></i>
                                                            <div class="input-group position-relative">
                                                                <select id="search16">
                                                                    <option value="" selected>English</option>
                                                                    <option value="AD">Dutch</option>
                                                                    <option value="BD">Spanish</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group has-search">
                                                            <label id="Label-18">Account Code</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Account code info"></i>
                                                            <input type="text" class="form-control" id="Label-18" name="Address Line 1" value="4051  Hardman Road, Michigan">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-19">Tags</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Tag"></i>
                                                            <input type="text" class="form-control" id="Label-19" name="Address Line 1" value="4051  Hardman Road, Michigan">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="user-header mb-4">
                                                    <h4 class="user-title font-weight-bold mb-0">Notes</h4>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 mb-4">
                                                        <label>General Notes:</label>
                                                        <textarea class="form-control p-3" name="addComment" id="characteristics" rows="5" placeholder="">Add terms & conditions here...</textarea>
                                                    </div>
                                                    <div class="col-md-6 mb-4">
                                                        <label>Admin Notes</label>
                                                        <textarea class="form-control p-3" name="addComment" id="notes" rows="5" placeholder="Add terms & conditions here..."></textarea>
                                                    </div>
                                                    <div class="col-md-6 mb-4">
                                                        <label>Email signature:</label>
                                                        <textarea class="form-control p-3" name="injury" id="notes" rows="5" placeholder="Thank you for giving us the opportunity to provide our services as we truly appreciate your business."></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="user-header mb-4">
                                                    <h4 class="user-title font-weight-bold mb-0">Work Preferences</h4>
                                                </div>
                                                <div class="row mb-4">
                                                    <div class="col-md-3">
                                                        <div class="form-group has-search">
                                                            <label for="service">Preferred Services</label>
                                                            <div class="input-group position-relative">
                                                                <select id="search17">
                                                                    <option value="" selected>Select</option>
                                                                    <option value="AD">No</option>
                                                                    <option value="BD">Others</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group has-search">
                                                            <label for="region">Preferred Regions</label>
                                                            <div class="input-group position-relative">
                                                                <select id="search18">
                                                                    <option value="" selected>Select</option>
                                                                    <option value="AD">No</option>
                                                                    <option value="BD">Others</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-4">
                                                    <div class="col-md-12">
                                                        <label for="track">Track Last Location:</label>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="custom-control custom-checkbox custom-control-inline mr-5">
                                                            <input type="checkbox" class="custom-control-input" id="yes" name="yes">
                                                            <label class="custom-control-label" for="yes">Yes</label>
                                                        </div>
                                                        <div class="custom-control custom-checkbox custom-control-inline">
                                                            <input type="checkbox" class="custom-control-input" id="no" name="no">
                                                            <label class="custom-control-label" for="no">No</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="button">Save Vendor <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>