<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12 px-0 pb-5">
                            <div class="col-auto mt-n1">
                                <app-breadcrumb></app-breadcrumb>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto d-none d-sm-block mb-3">
                            <h3 class="page-title"><strong>New Retainer</strong> </h3>
                            <!-- <p>Create new client detail here.</p> -->
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
                                          
                                            <div class="card-body">
                                               
                                              <div class="card-text">

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                   
                                                        <div class="input-group position-relative">
                                                            
                                                            <label id="Label-24">Retainer Number<sup class="clsStric">*</sup></label>
                                                            <div class="input-group position-relative">
                                                                <input name="retainerNumber" type="text" class="form-control mb-3" formControlName="retainerNumber" class="form-control" 
                                                                autocomplete="off" placeholder="Client Name" [ngClass]="{ 'is-invalid': submitted && form.controls.retainerNumber.errors }" /> 
                                                                <div *ngIf="submitted && form.controls.retainerNumber.errors" class="invalid-feedback">
                                                                    <div *ngIf="form.controls.retainerNumber.errors.required" style="font-size: larger;">
                                                                        Retainer number is required
                                                                    </div>
                                                                </div>
                                                                <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="createdOn">Created on <sup class="clsStric">*</sup></label>
                                                            <!-- <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Sales Person"></i> -->
                                                            <div class="input-group position-relative">
                                                                <input type="date"  name="createdOn" class="form-control mb-2" formControlName="createdOn" placeholder="Created On" autocomplete="off">
                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                
                                                </div>
                                                <hr>

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="billTo">Client</label>
                                                            <div class="input-group position-relative">
                                                               
                                                                <select formControlName="billTo">
                                                                    <option value="" selected>Select a Client</option>
                                                                    <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                           

                                                </div>

                                                <hr>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label for="case">Case
                                                                <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Payment receipts and late payment reminders will be sent to this email."></i>
                                                            </label>
                                                           <div class="block">
                                                            Any case for this client (change)
                                                               </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="ReferenceNumber1">Reference Number</label>
                                                            <input name="ReferenceNumber1" type="text" class="form-control mb-3" formControlName="ReferenceNumber1" class="form-control" placeholder="Reference Number" autocomplete="off"  />
                                                        </div>
                                                    </div>
                                               
                                                </div>
                                  
                                                <div class="row">
                                                    <div class="col-md-6 mb-4">
                                                        <label>Notes</label>
                                                        <textarea class="form-control p-3" name="notes_terms" id="notes_terms" rows="5" placeholder="Add terms & conditions here..." formControlName="notes_terms"></textarea>
                                                    </div>
                                                </div>
                                            


                                  
                                              </div>
                                             
                                            </div>
                                          </div>
                                    </div>
                              

                     
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  [routerLink]="['/']"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="sub">Save Retainer <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main> 