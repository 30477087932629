import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { ClientAddModel } from 'src/app/models/client/client.model';
import { ClientResponseModel } from 'src/app/models/client/clientResponse.model';
import { LocationModel } from 'src/app/models/Location/location.model';
import { CountryModel } from 'src/app/models/shared/country.model';
import { StateModel } from 'src/app/models/shared/state.model';
import { ClientService } from 'src/app/services/client.service';
import { CountryService } from 'src/app/services/country.service';
import { ToasterService } from 'src/app/services/toater.service';


interface Country {
  name: string;
  area: number;
  population: number;
}

const COUNTRIES: Country[] = [
  {
    name: 'Travel time',
    area: 1.0,
    population: 108.75
  },
  {
    name: 'SV - Investigation Time',
    area: 3.75,
    population: 29.00
  }
];

@Component({
  selector: 'app-new-retainer',
  templateUrl: './new-retainer.component.html',
  styleUrls: ['./new-retainer.component.css']
})
export class NewRetainerComponent implements OnInit {


  public invoiceForm: FormGroup;

  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private clientService: ClientService,
    private tosterService: ToasterService,
    private countryService: CountryService) { }
  form: FormGroup;
  submitted = false;
  returnUrl: string;
  countrySelectListItem: CountryModel[] = [];
  stateSelectListItem: StateModel[] = [];
  @BlockUI('container-blockui-location') blockUI: NgBlockUI;

  isDisabled = true;
  countries = COUNTRIES;

  selectedCars = '';
  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];


  tags$: Observable<any>;
  tagsInput$ = new Subject<string>();
  user: any = [];

  users = [{

    id: 1,
    name: 'Paul'
  }];

  controls = this.users.map(c => new FormControl(false));

  ngOnInit(): void {
    this.loadUser();
    this.loadCountries();
    this.form = this.formBuilder.group({

      customSwitch1: new FormControl(),
      orders: new FormArray(this.controls),
      retainerNumber: ['',
        Validators.required
      ],
      createdOn: [''],
      dueOn: [''],

      contact: [''],

      ReferenceNumber1: [''],


      dynamicRows: this.formBuilder.array([this.initRows()]),


      notes_terms: [''],

      locationName: ['',
        Validators.required
      ],


      case: ['']
    });
    this.loadTags();
  }

  get formArr() {
    return this.form.get('dynamicRows') as FormArray;
  }

  initRows() {
    return this.formBuilder.group({
      name: [''],
      code: [''],
      items: [''],
      staffs: [''],
      description: [''],
      qty: [''],
      rate: [''],
      discount: [''],
      tax: [''],
    });
  }

  addNewRows(): void {
    this.formArr.push(this.initRows());
  }

  deleteRow(index: number): void {
    this.formArr.removeAt(index);
  }

  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid.
    if (this.form.invalid) {
      this.tosterService.error('Please fill all required fields');
      return;
    }
    const clientModel = new ClientAddModel();
    clientModel.name = this.form.value.name;
    clientModel.accountCode = this.form.value.accountCode;
    clientModel.salesPersonId = this.form.value.salesPersonId;
    clientModel.caseManagerId = this.form.value.caseManagerId;
    clientModel.webSite = this.form.value.webSite;
    clientModel.billingEmail = this.form.value.billingEmail;
    clientModel.generalNotes = this.form.value.generalNotes;
    clientModel.casePolicies = this.form.value.casePolicies;
    clientModel.updatePolicies = this.form.value.updatePolicies;
    clientModel.invoicePolicies = this.form.value.invoicePolicies;
    clientModel.sendOverdueInvoices = this.form.value.sendOverdueInvoices == 'true' ? true : false;
    clientModel.show_Bill_To_Location = this.form.value.show_Bill_To_Location == 'true' ? true : false;
    clientModel.tags = this.form.value.tags;
    clientModel.caseTypeRestriction = this.form.value.caseTypeRestriction;
    clientModel.Location = new LocationModel();
    clientModel.Location.locationName = this.form.value.locationName;
    clientModel.Location.isPrimary = this.form.value.isPrimary;
    clientModel.Location.country = this.form.value.country;
    clientModel.Location.address1 = this.form.value.address1;
    clientModel.Location.address2 = this.form.value.address2;
    clientModel.Location.address3 = this.form.value.address3;
    clientModel.Location.city = this.form.value.city;
    clientModel.Location.state = this.form.value.state;
    clientModel.Location.zip = this.form.value.zip;
    //clientModel.Location.primaryPhone = this.form.value.primaryPhone;
    //clientModel.Location.secondaryPhone = this.form.value.secondaryPhone;
    clientModel.Location.fax = this.form.value.fax;
    this.clientService.addClient(clientModel).subscribe((data: ClientResponseModel) => {
      if (data.success) {
        this.router.navigate(['/client']);
        this.tosterService.success(data.message);
      } else {
        this.tosterService.success(data.message);
      }
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
    });
  }
  toggleDisabled(): void {
    const car: any = this.cars[1];
    car.disabled = !car.disabled;
  }
  private loadUser(): void {
    this.clientService.getUser().subscribe((data: any) => {
      this.user = data;
    }, error => {
    }, () => {
    });
  }

  private loadCountries(): void {
    this.blockUI.start();
    this.countryService.getCountries().subscribe((data: any) => {
      this.countrySelectListItem = data;
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  loadStates(name: any): void {
    const id: any = this.countrySelectListItem.find(x => x.name === name)?.id ?? undefined;
    if (id === null || id === undefined || id.length === 0) {
      return;
    }

    this.blockUI.start();
    this.countryService.getStates(id).subscribe((data: any) => {
      this.stateSelectListItem = data;
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  private loadTags(): void {
    this.tags$ = concat(
      of([]), // default items
      this.tagsInput$.pipe(
        debounceTime(200),
        filter(res => {
          return res !== null && res.length >= 0;
        }),
        distinctUntilChanged(),
        switchMap(term => {
          return this.clientService.getClientTags(term).pipe(
            catchError(() => of([])) // empty list on error
          );
        })
      )
    );
  }

  addCustomTag = (term) => term;


}
