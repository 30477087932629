import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';

import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  AbstractControl,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { CustomFieldCaseCategories } from 'src/app/helpers/enums/settings/customField/CustomFieldCaseCategories';
import { CaseTypeAddModel } from 'src/app/models/casetypes/caseTypeAddModel';
import { CustomFieldModel } from 'src/app/models/custom-fields/CustomFieldModel';
import { SubjectTypeViewModel } from 'src/app/models/subjectTypes/subjectTypeViewModel';
import { CaseReviewService } from 'src/app/services/case.review.service';
import { CaseFlagService } from 'src/app/services/caseflag.service';
import { CaseServicesService } from 'src/app/services/caseservice.service';
import { CasetypeService } from 'src/app/services/casetype.service';
import { CustomfieldService } from 'src/app/services/customfield.service';
import { DocumentTemplateService } from 'src/app/services/document.template.service';
import { FolderTemplateService } from 'src/app/services/foldertemplate.service';
import { ModalService } from 'src/app/services/modal.service';
import { SubjectTypeService } from 'src/app/services/subjecttype.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-new-case-type',
  templateUrl: './new-case-type.component.html',
  styleUrls: ['./new-case-type.component.css'],
})
export class NewCaseTypeComponent implements OnInit {
  @BlockUI('container-blockui-new-case-type') blockUI: NgBlockUI;
  form: FormGroup;
  submitted = false;
  customCases: CustomFieldModel[] = [];
  subjectTypes: SubjectTypeViewModel[] = [];

  dummy = [];
  referenceData = [
    {
      value: '',
      isCopyToInvoice: true,
      isReferenceType: true,
    },
    {
      value: '',
      isCopyToInvoice: true,
      isReferenceType: true,
    },
    {
      value: '',
      isCopyToInvoice: true,
      isReferenceType: true,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private folderTemplateService: FolderTemplateService,
    private caseTypeService: CasetypeService,
    private caseFlagService: CaseFlagService,
    private customFieldService: CustomfieldService,
    private subjectTypeService: SubjectTypeService,
    private router: Router,
    private caseServicesService: CaseServicesService,
    private modalService: ModalService,
    private caseReviewService: CaseReviewService,
    private documentTemplateService: DocumentTemplateService
  ) { }

  get caseReviewsToFormArray(): FormArray {
    return this.form.controls.case_reviews as FormArray;
  }
  get caseSubjectTypeToFormArray(): FormArray {
    return this.form.controls.case_subject_type as FormArray;
  }
  get folderTemplateToFormArray(): FormArray {
    return this.form.controls.folderTemplate as FormArray;
  }
  get documentTemplateToFormArray(): FormArray {
    return this.form.controls.documentTemplate as FormArray;
  }
  get caseFlagsToFormArray(): FormArray {
    return this.form.controls.caseFlags as FormArray;
  }
  get referencesToFormArray(): FormArray {
    return this.form.controls.references as FormArray;
  }
  get caseServicesToFormArray(): FormArray {
    return this.form.controls.case_services as FormArray;
  }
  tagKeyPress(event: KeyboardEvent): void {
    if (this.form.controls.tag.value.length >= 2) {
      event.preventDefault();
    }
  }
  loadCaseCustomFields(): void {
    this.customFieldService.getByUserForId(CustomFieldCaseCategories.Case).subscribe((data) => {
      if (data && data.length !== this.customCases.length){
        data.forEach((element, index) => {
          if (this.customCases.filter(x => x.id === element.id).length === 0){
            this.customCases.splice(index, 0 , element);
          }
        });
        }
    }, (error: any) => {
      this.toasterService.ProcessErrorResponse(error);
    });
  }
 loadDocumentTemplate(): void{
   this.blockUI.start();
   this.documentTemplateService.getAll().subscribe((data) => {
     if (!data) {
     return;
     }
 // load documenttemplate start
     const dt: FormArray = this.documentTemplateToFormArray;
     dt.clear();
     data.forEach((d) => {
  dt.push(
      this.formBuilder.group({
        id: [d.id],
        name: [d.name],
        isSelected: [false],
      })
    );
  });
  // load documenttemplate end
     this.blockUI.stop();

   }, (error: any) => {
  this.blockUI.stop();
  this.toasterService.ProcessErrorResponse(error);
}, () => {
  this.blockUI.stop();
});
 }
  loadRefrences(): void {
    const rd: FormArray = this.referencesToFormArray;
    rd.clear();
    this.referenceData.forEach((d) => {
      rd.push(
        this.formBuilder.group({
          value: [d.value],
          isCopyToInvoice: [d.isCopyToInvoice],
          isReferenceType: [d.isReferenceType],
          id: 0,
        })
      );
    });
  }

  loadDefaultFormData(): void {
    this.blockUI.start();
    forkJoin([
      this.folderTemplateService.getAll(),
      this.caseFlagService.getAll(),
      this.caseServicesService.getAll(),
      this.subjectTypeService.getAll(),
      this.customFieldService.getByUserForId(CustomFieldCaseCategories.Case),
      this.caseReviewService.getAll()
    ]).subscribe(
      ([folderTemplateAll, caseFlagAll, caseServicesAll, subjectTypeAll, customFieldCasesAll, caseReviewAll]) => {
       this.customCases = customFieldCasesAll;
        // load foldertemplate start
       const ft: FormArray = this.folderTemplateToFormArray;
       ft.clear();
       folderTemplateAll.forEach((d) => {
          ft.push(
            this.formBuilder.group({
              id: [d.id],
              name: [d.name],
              isSelected: [false],
            })
          );
        });
        // load foldertemplate end

        // load case flags start
       const cf: FormArray = this.caseFlagsToFormArray;
       cf.clear();
       caseFlagAll.forEach((d) => {
          cf.push(
            this.formBuilder.group({
              id: [d.id],
              name: [d.name],
              isSelected: [false],
            })
          );
        });
        // load case flags end

        // load case service start
       const cs: FormArray = this.caseServicesToFormArray;
       cs.clear();
       caseServicesAll.forEach((d) => {
          cs.push(
            this.formBuilder.group({
              id: [d.id],
              name: [d.name],
              isSelected: [false],
            })
          );
        });
        // load case service end

        // load case subject type start
       this.subjectTypes = subjectTypeAll;
       const cst: FormArray = this.caseSubjectTypeToFormArray;
       cst.clear();
       subjectTypeAll.forEach((d) => {
          cst.push(
            this.formBuilder.group({
              id: [d.id],
              name: [d.name],
              isSelected: [false],
            })
          );
        });
        // load case subject type end

        // load case review type start
       const st: FormArray = this.caseReviewsToFormArray;
       st.clear();
       caseReviewAll.forEach((d) => {
          st.push(
            this.formBuilder.group({
              id: [d.id],
              name: [d.name],
              isSelected: [false],
            })
          );
        });
        // load case review type end
       this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(
          error,
          'Something went wrong!'
        );
        this.blockUI.stop();
      }
    );
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      tag: new FormControl('', [Validators.required, Validators.maxLength(2)]),
      option_budget_tracking: new FormControl(true),
      option_public_request: new FormControl(true),
      option_admin_notes: new FormControl(true),
      option_case_services: new FormControl(true),
      option_notes_instructions: new FormControl(true),
      references: new FormArray([]),
      folderTemplate: new FormArray([]),
      documentTemplate: new FormArray([]),
      caseFlags: new FormArray([]),
      case_services: new FormArray([]),
      case_subject_type: new FormArray([]),
      case_reviews: new FormArray([]),
      default_subject_type: new FormControl(''),
      // generalNotes: new FormControl(),
      // attachment: new FormControl(),
      // orders: new FormArray(control),
      // phones: this.formBuilder.array([this.formBuilder.control(null)]),

      customCaseFields: this.formBuilder.array([]),
    });
    this.loadDefaultFormData();
    this.loadRefrences();
    this.loadDocumentTemplate();
  }

  // on change of select remove and add array
  onChange(indexx: number, idx: number, namex: string, isChecked: boolean): void {
    if (isChecked) {
      this.dummy.push({ index: indexx, id: idx, name: namex });
    } else {
      this.RemoveElementFromObjectArray(indexx);
      if (this.dummy.length === 0) {
        this.form.controls.default_subject_type.setValue('');
      }
    }
  }
  // remove particular array by index
  RemoveElementFromObjectArray(key: number): void {
    this.dummy.forEach((value, index) => {
      if (value.index === key) { this.dummy.splice(index, 1); }
    });
  }

  // for custom fields
  // new contact row
  get formArr() {
    return this.form.get('customCaseFields') as FormArray;
  }
  addNewRows(): void {
    this.formArr.push(this.initRows());
  }

  deleteRow(index: number): void {
    this.formArr.removeAt(index);
  }
  initRows() {
    return this.formBuilder.group({
      id: 0,
      isOpenable: [false],
      customCaseId: [this.customCases.length > 0 ? this.customCases[0].id : '', [RxwebValidators.unique(), Validators.required]],
    });
  }

  addPhone(): void {
    (this.form.get('phones') as FormArray).push(this.formBuilder.control(null));
  }
  getPhonesFormControls(): AbstractControl[] {
    return (this.form.get('phones') as FormArray).controls;
  }

  removePhone(index): void {
    (this.form.get('phones') as FormArray).removeAt(index);
  }
  OnCancel(): void {
    this.router.navigate(['/setting/cases/case-type-list']);
  }
  OnSubmit(): void {
    const caseTypeModel = new CaseTypeAddModel();
    const controls = this.form.controls;
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    caseTypeModel.id = 0;
    caseTypeModel.name = controls.name.value;
    caseTypeModel.tag = controls.tag.value;
    caseTypeModel.description = controls.description.value;
    caseTypeModel.caseTypeReferences = [];
    caseTypeModel.caseTypeCustomFields = [];
    caseTypeModel.isAdminNotes = controls.option_admin_notes.value;
    caseTypeModel.isBugetTracking = controls.option_budget_tracking.value;
    caseTypeModel.isCaseServices = controls.option_case_services.value;
    caseTypeModel.isNotesInstructions =
      controls.option_notes_instructions.value;
    caseTypeModel.isPublicCaseRequest = controls.option_public_request.value;
    caseTypeModel.caseFlagIds = controls.caseFlags.value
      .filter((x) => x.isSelected === true)
      .map((x) => x.id);

    caseTypeModel.folderTemplateIds = controls.folderTemplate.value
      .filter((x) => x.isSelected === true)
      .map((x) => x.id);
    caseTypeModel.documentTemplateIds = controls.documentTemplate.value
      .filter((x) => x.isSelected === true)
      .map((x) => x.id);
    caseTypeModel.caseReviewIds  = controls.case_reviews.value
      .filter((x) => x.isSelected === true)
      .map((x) => x.id);

    caseTypeModel.caseServiceIds = controls.case_services.value
      .filter((x) => x.isSelected === true)
      .map((x) => x.id);
    caseTypeModel.subjectTypeIds = controls.case_subject_type.value
      .filter((x) => x.isSelected === true)
      .map((x) => x.id);
    if (caseTypeModel.subjectTypeIds.length > 0 && !controls.default_subject_type.value) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    caseTypeModel.defaultSubjectTypeId = controls.default_subject_type.value === '' ? null : Number(controls.default_subject_type.value);

    this.form.value.references.map((x) => {
      caseTypeModel.caseTypeReferences.push({
        id: 0,
        name: x.value,
        isCopyToInvoice: x.isCopyToInvoice,
        isRefNumberRequired: x.isReferenceType,
      });
    });
    controls.customCaseFields.value.map((x, index) => {
      caseTypeModel.caseTypeCustomFields.push({
        id: 0,
        caseTypeId: 0,
        customFieldId: Number(x.customCaseId),
        isReopenable: x.isOpenable,
        sequence: index + 1
      });
    });
    this.blockUI.start();
    this.caseTypeService.post(caseTypeModel).subscribe(
      (data) => {
        setTimeout(() => {
          this.OnCancel();
        }, 10);
        setTimeout(() => {
          this.toasterService.success('Case-type created successfully');
        }, 300);
        this.blockUI.stop();
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }
  openCustomFieldPopup(): void {
    this.modalService.openNewCustomFieldPopup(CustomFieldCaseCategories.Case).subscribe((data: any) => {
      if (data){
        this.loadCaseCustomFields();
      }
    }, (error: any) => {
      this.toasterService.ProcessErrorResponse(error);
    });
  }
  onDrop(event: CdkDragDrop<string[]>): void {
    const prevValue  = this.formArr.at(event.previousIndex);
    this.deleteRow(event.previousIndex);
    this.formArr.insert(event.currentIndex, prevValue);
 }
 addRowAtIndex(index: number): void {
   this.formArr.insert(index + 1, this.initRows());
 }
}
