import { formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { DateFormatPipe } from 'src/app/helpers/pipes/date-format.pipe';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { StaffLicenseRenewModel } from 'src/app/models/staff-license/staff.license.renew.model';
import { StaffLicenseService } from 'src/app/services/staff.license.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-renew-staff-license',
  templateUrl: './renew-staff-license.component.html',
  styleUrls: ['./renew-staff-license.component.css']
})
export class RenewStaffLicenseComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ngbActiveModal: NgbActiveModal,
    private staffService: StaffLicenseService,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder
  ) {
  }

  form: FormGroup;
  isSubmitted = false;

  @BlockUI() blockUI: NgBlockUI;

  @Input() public header: string;

  @Input() public expiresOn: string;

  @Output() staffLicenseRenewModel = new EventEmitter<StaffLicenseRenewModel>();


  get activeModal(): any {
    return this.ngbActiveModal;
  }
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      renewedOn: [formatDate(new Date(), 'yyyy-MM-dd', 'en'), [Validators.required]],
      expiresOn: [formatDate(this.expiresOn, 'yyyy-MM-dd', 'en'), [Validators.required]],
    });

    // this.form.controls.renewedOn.setValue();
  }


  onSubmit(): void {

    this.isSubmitted = true;
    if (this.form.invalid) {
      return;
    }
    const model = new StaffLicenseRenewModel();
    model.expiresOn = this.form.value.expiresOn;
    model.renewedOn = this.form.value.renewedOn;
    this.staffLicenseRenewModel.emit(model);
  }

}
