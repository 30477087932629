<main class="content py-0">
  <div class="container-fluid p-0">
      <div class="row">
          <div class="col-md-12 pt-7">
              <div class="w-100 pt-4">
                  <div class="row">
                      <div class="col-md-12 px-0 pb-5">
                          <div class="col-auto mt-n1">
                              <app-breadcrumb></app-breadcrumb>
                          </div>
                      </div>
                  </div>
                  <div class="row">
                      <div class="col-auto d-none d-sm-block mb-3">
                          <h3 class="page-title"><strong>New Client</strong> </h3>
                          <p>Create new client detail here.</p>
                      </div>
                  </div>
                  <div class="row mx-0">
                      <div class="col-12 px-0 mb-5">
                          <form class="new-case-form" name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                              <div class="row">
                                  <div class="col-md-12">
                                      <div class="card" >

                                          <div class="card-body">
                                               <div class="user-header mb-4">
                                                  <h4 class="user-title font-weight-bold mb-0">Basic Information</h4>
                                              </div>
                                            <div class="card-text">

                                              <div class="row">
                                                  <div class="col-md-6">
                                                      <div class="form-group has-search">

                                                      <div class="input-group position-relative">

                                                          <label id="Label-24">Client Name<span class="clsStric">*</span></label>
                                                          <div class="input-group position-relative">
                                                              <input name="name" type="text" class="form-control mb-3" formControlName="name" class="form-control"
                                                              autocomplete="off" placeholder="Client Name" [ngClass]="{ 'is-invalid': submitted && form.controls.name.errors }" />
                                                              <div *ngIf="submitted && form.controls.name.errors" class="invalid-feedback">
                                                                  <div *ngIf="form.controls.name.errors.required" style="font-size: larger;">
                                                                      Name is required
                                                                  </div>
                                                              </div>
                                                              <button class="border-0 bg-transparent" type="button">
                                                              <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                              </button>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                                  <div class="col-md-6">
                                                      <div class="form-group has-search">
                                                          <label id="Label-24">Sales Person</label>
                                                          <!-- <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Sales Person"></i> -->
                                                          <div class="input-group position-relative">
                                                              <select formControlName="salesPersonId" >
                                                                  <option value="" selected>Select Person</option>

                                                                  <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>

                                                              </select>
                                                              <div class="input-group-append position-absolute append-btn">
                                                                  <button class="border-0 bg-transparent" type="button">
                                                                  <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-6">

                                                      <div class="form-group has-search">
                                                          <label id="Label-25">Case Manager</label>
                                                          <!-- <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Case Manager"></i> -->
                                                          <div class="input-group position-relative">

                                                              <select formControlName="caseManagerId">
                                                                  <option value="" selected>Select Manager</option>
                                                                  <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>
                                                              </select>
                                                              <div class="input-group-append position-absolute append-btn">
                                                                  <button class="border-0 bg-transparent" type="button">
                                                                  <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-6">
                                                      <div class="form-group has-search">
                                                          <label for="role">Account Code</label>
                                                          <input name="accountCode" type="text" class="form-control mb-3" formControlName="accountCode" placeholder="Account Code" autocomplete="off"/>

                                                      </div>
                                                  </div>
                                                  <div class="col-md-6">
                                                      <div class="form-group has-search">
                                                          <label for="website">Website</label>
                                                          <input name="webSite" type="text" class="form-control mb-3" formControlName="webSite"   autocomplete="off" placeholder="Website"/>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-6">
                                                      <div class="form-group has-search">
                                                          <label for="billemail">Billing Email</label>
                                                          <input type="billingEmail" class="form-control" id="billingEmail" name="billingEmail" formControlName="billingEmail" placeholder="Billing Email">
                                                      </div>
                                                  </div>
                                              </div>


                                            </div>

                                          </div>
                                        </div>
                                  </div>



                                  <div class="col-md-12">
                                      <div class="card flex-fill w-100 mb-4">
                                          <div class="card-header rounded p-4 mob-px-4">
                                              <div class="user-header mb-4">
                                                  <h4 class="user-title font-weight-bold mb-0">Primary Location</h4>
                                              </div>

                                              <div class="row">
                                                  <div class="col-md-6 mb-4">
                                                      <div class="form-group has-search">
                                                          <label id="Label-26">Location Name<span class="clsStric">*</span></label>
                                                          <input name="locationName" type="text" class="form-control mb-3" formControlName="locationName"
                                                          class="form-control" placeholder="Location Name" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && form.controls.locationName.errors }" />
                                                          <div *ngIf="submitted && form.controls.locationName.errors"
                                                          class="invalid-feedback">
                                                          <div *ngIf="form.controls.locationName.errors.required" style="font-size: larger;">
                                                              Location name is required
                                                          </div>
                                                      </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-6 mb-4">
                                                      <div class="form-group has-search">
                                                          <label for="country">Country</label>
                                                          <div class="input-group position-relative">
                                                              <select formControlName="country" (change)="loadStates($event.target.value)" name="country" id="client_country">
                                                                  <option *ngFor="let country of countrySelectListItem" [value]="country.name">
                                                                      {{country.name}}
                                                                    </option>
                                                              </select>
                                                              <div class="input-group-append position-absolute append-btn">
                                                                  <button class="border-0 bg-transparent" type="button">
                                                                  <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                  </button>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-6 mb-4">
                                                      <div class="form-group has-search">
                                                          <label id="Label-18">Address Line 1</label>
                                                          <input name="address1" type="text" class="form-control mb-3" formControlName="address1" class="form-control" placeholder="Address" autocomplete="off"  />
                                                      </div>
                                                  </div>
                                                  <div class="col-md-6 mb-4">
                                                      <div class="form-group has-search">
                                                          <label id="Label-19">Address Line 2</label>
                                                          <input name="address2" type="text" class="form-control mb-3" formControlName="address2" class="form-control" placeholder="Address" autocomplete="off"  />
                                                      </div>
                                                  </div>
                                                  <div class="col-md-6 mb-4">
                                                      <div class="form-group has-search">
                                                          <label id="Label-20">Address Line 3</label>
                                                          <input name="address3" type="text" class="form-control mb-3" formControlName="address3" class="form-control" placeholder="Address" autocomplete="off"  />
                                                      </div>
                                                  </div>
                                                  <div class="col-md-6 mb-4">
                                                      <div class="form-group has-search">
                                                          <label id="Label-21">City</label>
                                                          <input name="city" type="text" class="form-control mb-3" formControlName="city" placeholder="City" class="form-control" autocomplete="off"  />
                                                      </div>
                                                  </div>
                                                  <div class="col-md-6 mb-4">
                                                      <div class="form-group has-search">

                                                          <label id="Label-22">State/Province</label>

                                                          <select name="stateId" type="text" class="form-control mb-3" (change)="loadCounty($event.target.value)" formControlName="state" placeholder="State" class="form-control" >
                                                              <option value="" disabled selected="selected">Select State</option>
                                                              <option *ngFor="let state of stateSelectListItem" [value]="state.name">{{state.name}}</option>
                                                              </select>

                                                      </div>
                                                  </div>
                                                  <div class="col-md-6 mb-4">
                                                    <div class="form-group has-search">

                                                        <label id="Label-22">County</label>

                                                        <select name="CountyId" type="text" class="form-control mb-3" formControlName="county" placeholder="County" class="form-control" >
                                                            <option value="" disabled selected="selected">Select County</option>
                                                            <option *ngFor="let state of countySelectListItem" [value]="state.name">{{state.name}}</option>
                                                            </select>

                                                    </div>
                                                </div>
                                                  <div class="col-md-6 mb-4">
                                                      <div class="form-group has-search">
                                                          <label id="Label-23">Zip/Postal Code</label>
                                                          <input name="zip" type="text" (keypress)="appUtil._keyUp($event)" class="form-control mb-3" formControlName="zip" placeholder="Zip" class="form-control" autocomplete="off"  />
                                                      </div>
                                                  </div>
                                              </div>
                                              <div class="row">
                                                  <div class="col-md-6 mb-4">
                                                      <div class="form-group has-search">
                                                          <!-- <label id="Label-16">Phone </label>
                                                          <input name="primaryPhone" type="text" class="form-control mb-3" formControlName="primaryPhone" class="form-control" autocomplete="off"   placeholder="Primary Phone"/>
                                                          <br>
                                                          <input name="secondaryPhone" type="text" class="form-control mb-3" formControlName="secondaryPhone" class="form-control" autocomplete="off"   placeholder="Secondary Phone"/>
                                                          <br> -->
                                                          <label id="Label-16">Fax </label>
                                                          <input name="fax" type="text" class="form-control mb-3" formControlName="fax" class="form-control" autocomplete="off"   placeholder="Fax"/>
                                                      </div>

                                                  </div>

                                              </div>

                                            <!-- new contact number ui -->
                                            <app-contact-number-widget [form]="form" [isSubmitted]="submitted"></app-contact-number-widget>
                                            <!-- end -->

                                          </div>
                                      </div>
                                      <div class="card flex-fill w-100 mb-4">
                                          <div class="card-header rounded p-4 mob-px-4">
                                              <div class="user-header mb-4">
                                                  <h4 class="user-title font-weight-bold mb-0">Client Notes & Policies</h4>
                                              </div>
                                              <div class="row">
                                                  <div class="col-md-6 mb-4">
                                                      <label>General Notes</label>
                                                      <textarea class="form-control p-3" name="generalNotes" id="generalNotes" rows="5" placeholder="Add terms & conditions here..." formControlName="generalNotes"></textarea>
                                                  </div>
                                                  <div class="col-md-6 mb-4">
                                                      <label>Case Policies</label>
                                                      <textarea class="form-control p-3" name="casePolicies" id="casePolicies" rows="5" placeholder="Add terms & conditions here..." formControlName="casePolicies"></textarea>
                                                  </div>
                                                  <div class="col-md-6 mb-4">
                                                      <label>Update Policies</label>
                                                      <textarea class="form-control p-3" name="updatePolicies" id="updatePolicies" rows="5" formControlName="updatePolicies" placeholder="Thank you for giving us the opportunity to provide our services as we truly appreciate your business."></textarea>
                                                  </div>
                                                  <div class="col-md-6 mb-4">
                                                      <label>Invoice Policies</label>
                                                      <textarea class="form-control p-3" name="invoicePolicies" id="invoicePolicies" rows="5" formControlName="invoicePolicies" placeholder="Thank you for giving us the opportunity to provide our services as we truly appreciate your business."></textarea>
                                                  </div>
                                              </div>
                                          </div>
                                      </div>
                                      <div class="card flex-fill w-100 mb-4">
                                          <div class="card-header rounded p-4 mob-px-4">
                                              <div class="user-header mb-4">
                                                  <h4 class="user-title font-weight-bold mb-0">Client Settings</h4>
                                              </div>
                                              <div class="row mb-4">
                                                  <div class="col-md-6">
                                                      <div class="form-group has-search">
                                                          <label for="language">Case Type Restrictions</label>
                                                          <div class="input-group position-relative">
                                                              <ng-select [multiple]="true" formControlName="caseTypeRestriction" name="cars" class="custom-select">
                                                                  <ng-option *ngFor="let car of cars" [value]="car.name" >{{car.name}}</ng-option>
                                                              </ng-select>
                                                          </div>
                                                          <br/>
                                                      </div>
                                                  </div>

                                                  <div class="col-md-6">
                                                      <div class="form-group has-search">
                                                          <label id="track">Send Overdue Invoices</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="If disabled, overdue invoice reminders will not be sent to this client, or its contacts."></i>
                                                          <div>
                                                              <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                  <input type="radio" id="customRadio" class="custom-control-input" formControlName="sendOverdueInvoices"  name="sendOverdueInvoices" value="true">
                                                                  <label class="custom-control-label" for="customRadio">Yes</label>
                                                              </div>
                                                              <div class="custom-control custom-radio custom-control-inline">
                                                                  <input type="radio" id="customRadio2" class="custom-control-input" formControlName="sendOverdueInvoices"  name="sendOverdueInvoices" value="false">
                                                                  <label class="custom-control-label" for="customRadio2">No</label>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-6">

                                                      <div class="form-group has-search">
                                                          <label >Tags</label>
                                                          <ng-select [items]="tags$ | async" name="car2"
                                                          [hideSelected]="true" multiple="true" [typeahead]="tagsInput$"
                                                          bindLabel="name" [addTag]="addCustomTag" class="custom-select" formControlName="tags">
                                                          </ng-select>
                                                          <br/>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-6">
                                                      <div class="form-group has-search">
                                                          <label id="Label-18">Show "Bill-To" Location</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="If enabled, invoices for this client will include the location name in the 'Bill-To' details."></i>
                                                          <div>
                                                              <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                  <input type="radio" id="customRadio3" class="custom-control-input" formControlName="show_Bill_To_Location" name="show_Bill_To_Location" value="true">
                                                                  <label class="custom-control-label" for="customRadio3">Yes</label>
                                                              </div>
                                                              <div class="custom-control custom-radio custom-control-inline">
                                                                  <input type="radio" id="customRadio4" class="custom-control-input" formControlName="show_Bill_To_Location" name="show_Bill_To_Location" value="false">
                                                                  <label class="custom-control-label" for="customRadio4">No</label>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  </div>
                                                  <div class="col-md-6"></div>
                                              </div>
                                              <div class="row">
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                  <div class="form-group w-100">
                                      <div class="col-lg-12 text-right">
                                          <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  (click)="onCancel()"> Cancel</button>
                                          <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="sub">Save Client Info <span class="fa fa-arrow-right"></span></button>
                                      </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</main>
