import { Component, OnInit } from '@angular/core';
import { AppUtils } from 'src/app/helpers';



@Component({
  selector: 'app-case-detail-files',
  templateUrl: './case-detail-files.component.html',
  styleUrls: ['./case-detail-files.component.css']
})
export class CaseDetailFilesComponent implements OnInit {

  constructor(public appUtils: AppUtils) { }

  ngOnInit(): void {
  }

}
