import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray, FormControl, FormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'app-new-tax-rate',
  templateUrl: './new-tax-rate.component.html',
  styleUrls: ['./new-tax-rate.component.css']
})
export class NewTaxRateComponent implements OnInit {

  constructor(private formBuilder: FormBuilder) { }

  form: FormGroup;
  submitted: boolean = false;
  isSelected: boolean = false;
  tax_array: FormArray;

  toggleCompound: boolean;

  totalTax = [];
  taxSum: number;


  orders = [{
    id: 1,
    name: 'Paul'
  }];

  controls = this.orders.map(c => new FormControl(false));
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: new FormControl(),
      country: new FormControl(),
      isSelected: new FormControl(),
      reference_id: new FormControl(),


      tax_array: this.formBuilder.array([
        this.createItem()
      ])
    });
  }

  createItem(): FormGroup {
    return this.formBuilder.group({
      tax_compo_name: '',
      tax_compo_percentage: '',
      customSwitch1: ''
    });
  }
  addPhone(): void {
    this.tax_array = this.form.get('tax_array') as FormArray;
    this.tax_array.push(this.createItem());

  }

  removePhone(index): void {
    (this.form.get('tax_array') as FormArray).removeAt(index);
    this.totalTax.splice(index, 1);
    this.taxSum = this.totalTax.reduce((acc, cur) => acc + cur, 0);
  }


  saveTotal(event: any): void {
    this.totalTax.push(Number(event.target.value));
    this.taxSum = this.totalTax.reduce((acc, cur) => acc + cur, 0);
  }

  onSubmit(): void {

  }

  enableCompound(): void {
    this.toggleCompound = true;
  }

}
