import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CustomFieldCaseCategories } from '../helpers/enums/settings/customField/CustomFieldCaseCategories';
import { CustomFieldTypes } from '../helpers/enums/settings/customField/CustomFieldTypes';
import { CustomFieldAddModel } from '../models/custom-fields/CustomFieldAddModel';
import { CustomFieldEditModel } from '../models/custom-fields/CustomFieldEditModel';
import { CustomFieldModel } from '../models/custom-fields/CustomFieldModel';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';
import { SelectListItem } from '../models/shared/select.list.item';

@Injectable({
  providedIn: 'root'
})
export class CustomfieldService {

  constructor(private http: HttpClient) { }

  public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
    return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/customfield/query`, dataTablesParameters, {});
  }
  public post(model: CustomFieldAddModel): Observable<CustomFieldAddModel> {
    return this.http.post<CustomFieldAddModel>(`${environment.apiBaseUrl}/customfield/post`, model, {});
  }
  public deleteCustomFields(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/customfield/delete?ids=${ids.join('&ids=')}`);
  }

  public undeleteCustomFields(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/customfield/undeleted?ids=${ids.join('&ids=')}`);
  }
  public permanentDeleteCustomFields(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/customfield/permanentdelete?ids=${ids.join('&ids=')}`);
  }
  public getRedactValue(customFieldtypeId: string, form: FormGroup): { redact?: boolean, method?: number } {

    const returnObject: { redact?: boolean, method?: number } = { redact: null, method: null };
    if (!customFieldtypeId) {
      return returnObject;
    }
    const typeId = Number(customFieldtypeId);
    if (typeId === CustomFieldTypes.Date || typeId === CustomFieldTypes.DateBirthday ||
      typeId === CustomFieldTypes.InputLongAnswer || typeId === CustomFieldTypes.InputShortAnswer ||
      typeId === CustomFieldTypes.MultipleChoiceDropdown || typeId === CustomFieldTypes.MultipleChoiceRadio) {
      returnObject.redact = form.controls.redact_Yesno.value === 'true' ? true : false;
      if (returnObject.redact === true) {
        returnObject.method = Number(form.controls.redactMethod.value);
      }
      return returnObject;
    }
    return returnObject;
  }

  public getDefaultValue(customFieldtypeId: string, form: FormGroup): { isValid: boolean, value: string } {
    if (!customFieldtypeId) {
      return;
    }
    const typeId = Number(customFieldtypeId);
    const types = CustomFieldTypes;
    const controls = form.controls;
    const returnObject: { isValid: boolean, value: string } = { isValid: true, value: null };

    switch (typeId) {
      case types.Address || types.Date || types.DateBirthday || types.EmailAddress ||
        types.NameFirstMiddleLast || types.Section || types.WebsiteUrl || types.RatingNumber: {
          return returnObject;
        }
      case types.CountryDropdown: {
        const value = controls.dform_Country.value;
        if (value == null) {
          returnObject.isValid = false;
          return returnObject;
        }
        returnObject.value = value;
        return returnObject;
      }
      case types.InputLongAnswer: {
        returnObject.value = controls.dform_Longinput.value;
        return returnObject;

      }
      case types.InputShortAnswer: {
        returnObject.value = controls.dform_Shortinput.value;
        return returnObject;
      }
      case types.Money: {
        returnObject.value = controls.dform_Money.value;
        return returnObject;
      }
      case types.MultipleChoiceDropdown: {
        const value = controls.multipleChoiceDropdown.value;
        if (value.length > 0 && value.some(x => !x.dform_multiplechoices)) {
          returnObject.isValid = false;
          return returnObject;
        }
        returnObject.value = JSON.stringify(value);
        return returnObject;
      }
      case types.MultipleChoiceRadio: {
        const value = controls.multipleChoiceRadio.value;
        if (value.length === 0 || (value.length > 0 && value.some(x => !x.dform_multipleChoiceRadio))) {
          returnObject.isValid = false;
          return returnObject;
        }
        returnObject.value = JSON.stringify(value);
        return returnObject;
      }
      case types.Number: {
        returnObject.value = controls.dform_Number.value;
        return returnObject;
      }
      case types.YesOrNo: {
        returnObject.value = controls.dform_YesnoPart2.value;
        return returnObject;
      }
      case types.YesNoOrNA: {
        returnObject.value = controls.dform_Yesnona.value;
        return returnObject;
      }
      case types.RatingMultipleChoiceDropdown: {
        const value = controls.ratingMultipleChoiceDrop.value;
        if (value.length > 0 && value.some(x => !x.dform_ratingMultiDrop || !x.dform_ratingMultiDrop_scale)) {
          returnObject.isValid = false;
          return returnObject;
        }
        returnObject.value = JSON.stringify(value);
        return returnObject;
      }
      case types.RatingYesNoOrNA: {
        returnObject.value = controls.dform_RatingYesno.value;
        return returnObject;
      }
      default: {
        return returnObject;
      }
    }
  }

  public put(model: CustomFieldEditModel): Observable<string> {
    return this.http.put<string>(`${environment.apiBaseUrl}/customfield/put`, model, {});
  }

  public get(id: number): Observable<CustomFieldAddModel> {
    return this.http.get<CustomFieldAddModel>(`${environment.apiBaseUrl}/customfield/${id}`);
  }
  public getByUserForId(id: number): Observable<CustomFieldModel[]> {
    return this.http.get<CustomFieldModel[]>(`${environment.apiBaseUrl}/customfield/getByUseForId/${id}`);
  }
  public getByCaseTypeId(caseTypeId: number): Observable<CustomFieldModel[]> {
    return this.http.get<CustomFieldModel[]>(`${environment.apiBaseUrl}/customfield/query-customField-by-casetypeId/${caseTypeId}`);
  }
  public getBySubjectTypeId(subjectTypeId: number): Observable<CustomFieldModel[]> {
    return this.http.get<CustomFieldModel[]>(`${environment.apiBaseUrl}/customfield/query-customField-by-subjecttypeId/${subjectTypeId}`);
  }
  public getOtherVariablesByUserForId(): {key: string, value: string}[] {
    var keyValuePair = [  
      {value: '{{Author.email_address}}', key: '[Author] Email Address'},
      {value: 'Author.email', key: '[Author] Email Address'},
      {value: 'Author.fax', key: '[Author] Fax'},
      {value: 'Author.first_name', key: '[Author] First Name'},
      {value: 'Author.name', key: '[Author] Full Name'},
      {value: 'Author.home_phone', key: '[Author] Home Phone'},
      {value: 'Author.last_name', key: '[Author] Last Name'},
      {value: 'Author.mobile_phone', key: '[Author] Mobile Phone'},
      {value: 'Author.office_phone', key: '[Author] Office Phone'},
      {value: 'Author.signature', key: '[Author] Signature'},
      {value: 'Author.title', key: '[Author] Title'},
      {value: 'CaseField.total_surveillance_days', key: '[Case Field] # Total Surveillance Days'},
      {value: 'CaseField.of_claims', key: '[Case Field] # of Claims:'},
      {value: 'CaseField.successive_test_custom_field', key: '[Case Field] #Successive test Custom Field'},
      {value: 'CaseField.arrest', key: '[Case Field] Arrest'},
      {value: 'CaseField.arrest_long', key: '[Case Field] Arrest (Long)'},
      {value: 'CaseField.arrest_short', key: '[Case Field] Arrest (Short)'},
      {value: 'CaseField.cdi_case_number', key: '[Case Field] CDI Case Number'},
      {value: 'CaseField.carrier_name', key: '[Case Field] Carrier Name'},
      {value: 'CaseField.case_charged', key: '[Case Field] Case Charged'},
      {value: 'CaseField.case_charged_long', key: '[Case Field] Case Charged (Long)'},
      {value: 'CaseField.case_charged_short', key: '[Case Field] Case Charged (Short)'},
      {value: 'CaseField.case_synopsis', key: '[Case Field] Case Synopsis'},
      {value: 'CaseField.case_status', key: '[Case Field] Case status:'},
      {value: 'CaseField.claim_office', key: '[Case Field] Claim  Office'},
      {value: 'CaseField.client_cms_flag_request', key: '[Case Field] Client CMS Flag Request'},
      {value: 'CaseField.client_cms_flag_request_long', key: '[Case Field] Client CMS Flag Request (Long)'},
      {value: 'CaseField.client_cms_flag_request_short', key: '[Case Field] Client CMS Flag Request (Short)'},
      {value: 'CaseField.client_cms_flagged', key: '[Case Field] Client CMS Flagged'},
      {value: 'CaseField.client_cms_flagged_long', key: '[Case Field] Client CMS Flagged (Long)'},
      {value: 'CaseField.client_cms_flagged_short', key: '[Case Field] Client CMS Flagged (Short)'},
      {value: 'CaseField.closing_summary_date', key: '[Case Field] Closing Summary Date'},
      {value: 'CaseField.closing_summary_date_long', key: '[Case Field] Closing Summary Date (Long)'},
      {value: 'CaseField.closing_summary_date_short', key: '[Case Field] Closing Summary Date (Short)'},
      {value: 'CaseField.convicted', key: '[Case Field] Convicted'},
      {value: 'CaseField.convicted_long', key: '[Case Field] Convicted (Long)'},
      {value: 'CaseField.convicted_short', key: '[Case Field] Convicted (Short)'},
      {value: 'CaseField.d_r_in_progress', key: '[Case Field] D/R In Progress'},
      {value: 'CaseField.d_r_in_progress_long', key: '[Case Field] D/R In Progress (Long)'},
      {value: 'CaseField.d_r_in_progress_short', key: '[Case Field] D/R In Progress (Short)'},
      {value: 'CaseField.d_r_to_lea', key: '[Case Field] D/R to LEA'},
      {value: 'CaseField.d_r_to_lea_long', key: '[Case Field] D/R to LEA (Long)'},
      {value: 'CaseField.d_r_to_lea_short', key: '[Case Field] D/R to LEA (Short)'},
      {value: 'CaseField.da_acknowledgement', key: '[Case Field] DA Acknowledgement'},
      {value: 'CaseField.da_declination_date', key: '[Case Field] DA Declination Date'},
      {value: 'CaseField.da_declination_date_long', key: '[Case Field] DA Declination Date (Long)'},
      {value: 'CaseField.da_declination_date_short', key: '[Case Field] DA Declination Date (Short)'},
      {value: 'CaseField.da_request_date', key: '[Case Field] DA Request Date'},
      {value: 'CaseField.da_request_date_long', key: '[Case Field] DA Request Date (Long)'},
      {value: 'CaseField.da_request_date_short', key: '[Case Field] DA Request Date (Short)'},
      {value: 'CaseField.da_request_sent_back', key: '[Case Field] DA request sent back'},
      {value: 'CaseField.da_request_sent_back_long', key: '[Case Field] DA request sent back (Long)'},
      {value: 'CaseField.da_request_sent_back_short', key: '[Case Field] DA request sent back (Short)'},
      {value: 'CaseField.doi_acknowledgement', key: '[Case Field] DOI Acknowledgement'},
      {value: 'CaseField.doi_declination_date', key: '[Case Field] DOI Declination Date'},
      {value: 'CaseField.doi_declination_date_long', key: '[Case Field] DOI Declination Date (Long)'},
      {value: 'CaseField.doi_declination_date_short', key: '[Case Field] DOI Declination Date (Short)'},
      {value: 'CaseField.doi_request_date', key: '[Case Field] DOI Request Date'},
      {value: 'CaseField.doi_request_date_long', key: '[Case Field] DOI Request Date (Long)'},
      {value: 'CaseField.doi_request_date_short', key: '[Case Field] DOI Request Date (Short)'},
      {value: 'CaseField.doi_request_sent_back', key: '[Case Field] DOI request sent back'},
      {value: 'CaseField.doi_request_sent_back_long', key: '[Case Field] DOI request sent back (Long)'},
      {value: 'CaseField.doi_request_sent_back_short', key: '[Case Field] DOI request sent back (Short)'},
      {value: 'CaseField.days_video_obtained', key: '[Case Field] Days Video Obtained'},
      {value: 'CaseField.dec_date', key: '[Case Field] Dec. Date'},
      {value: 'CaseField.dec_date_long', key: '[Case Field] Dec. Date (Long)'},
      {value: 'CaseField.dec_date_short', key: '[Case Field] Dec. Date (Short)'},
      {value: 'CaseField.defense_attorney', key: '[Case Field] Defense Attorney'},
      {value: 'CaseField.defense_atty', key: '[Case Field] Defense Atty'},
      {value: 'CaseField.depo_date', key: '[Case Field] Depo Date'},
      {value: 'CaseField.depo_date_long', key: '[Case Field] Depo Date (Long)'},
      {value: 'CaseField.depo_date_short', key: '[Case Field] Depo Date (Short)'},
      {value: 'CaseField.depo_questions_to_da', key: '[Case Field] Depo Questions to DA'},
      {value: 'CaseField.depo_questions_to_da_long', key: '[Case Field] Depo Questions to DA (Long)'},
      {value: 'CaseField.depo_questions_to_da_short', key: '[Case Field] Depo Questions to DA (Short)'},
      {value: 'CaseField.estimated_claims_savings', key: '[Case Field] Estimated Claims Savings'},
      {value: 'CaseField.firstname', key: '[Case Field] Firstname'},
      {value: 'CaseField.firstname_long', key: '[Case Field] Firstname (Long)'},
      {value: 'CaseField.firstname_short', key: '[Case Field] Firstname (Short)'},
      {value: 'CaseField.hd', key: '[Case Field] HD'},
      {value: 'CaseField.hd_long', key: '[Case Field] HD (Long)'},
      {value: 'CaseField.hd_short', key: '[Case Field] HD (Short)'},
      {value: 'CaseField.le_arrest', key: '[Case Field] LE Arrest'},
      {value: 'CaseField.le_arrest_long', key: '[Case Field] LE Arrest (Long)'},
      {value: 'CaseField.le_arrest_short', key: '[Case Field] LE Arrest (Short)'},
      {value: 'CaseField.le_charged_case', key: '[Case Field] LE Charged Case'},
      {value: 'CaseField.le_charged_case_long', key: '[Case Field] LE Charged Case (Long)'},
      {value: 'CaseField.le_charged_case_short', key: '[Case Field] LE Charged Case (Short)'},
      {value: 'CaseField.lea_county_da_doi', key: '[Case Field] LEA County (DA / DOI)'},
      {value: 'CaseField.last_invoiced', key: '[Case Field] Last Invoiced'},
      {value: 'CaseField.last_invoiced_long', key: '[Case Field] Last Invoiced (Long)'},
      {value: 'CaseField.last_invoiced_short', key: '[Case Field] Last Invoiced (Short)'},
      {value: 'CaseField.last_update_to_client', key: '[Case Field] Last Update to Client'},
      {value: 'CaseField.last_update_to_client_long', key: '[Case Field] Last Update to Client (Long)'},
      {value: 'CaseField.last_update_to_client_short', key: '[Case Field] Last Update to Client (Short)'},
      {value: 'CaseField.origin', key: '[Case Field] Origin'},
      {value: 'CaseField.policy_number', key: '[Case Field] Policy Number'},
      {value: 'CaseField.policy_period', key: '[Case Field] Policy Period'},
      {value: 'CaseField.reference_label_4', key: '[Case Field] Reference label 4:'},
      {value: 'CaseField.referral_source', key: '[Case Field] Referral Source'},
      {value: 'CaseField.restitution_awarded', key: '[Case Field] Restitution Awarded'},
      {value: 'CaseField.restitution_awarded_long', key: '[Case Field] Restitution Awarded (Long)'},
      {value: 'CaseField.restitution_awarded_short', key: '[Case Field] Restitution Awarded (Short)'},
      {value: 'CaseField.restitution_awarded_amount', key: '[Case Field] Restitution Awarded Amount'},
      {value: 'CaseField.sfc_fd_1', key: '[Case Field] SFC / FD-1'},
      {value: 'CaseField.sfc_fd_1_long', key: '[Case Field] SFC / FD-1 (Long)'},
      {value: 'CaseField.sfc_fd_1_short', key: '[Case Field] SFC / FD-1 (Short)'},
      {value: 'CaseField.siu_file_review', key: '[Case Field] SIU File Review'},
      {value: 'CaseField.siu_file_review_long', key: '[Case Field] SIU File Review (Long)'},
      {value: 'CaseField.siu_file_review_short', key: '[Case Field] SIU File Review (Short)'},
      {value: 'CaseField.success_story', key: '[Case Field] Success Story'},
      {value: 'CaseField.success_story_long', key: '[Case Field] Success Story (Long)'},
      {value: 'CaseField.success_story_short', key: '[Case Field] Success Story (Short)'},
      {value: 'CaseField.success_story_summary', key: '[Case Field] Success Story Summary'},
      {value: 'CaseField.tin', key: '[Case Field] TIN'},
      {value: 'CaseField.test_label', key: '[Case Field] Test Label'},
      {value: 'CaseField.total_billed_amount', key: '[Case Field] Total Billed Amount:'},
      {value: 'CaseField.total_paid', key: '[Case Field] Total Paid:'},
      {value: 'Client.address', key: '[Client] Address'},
      {value: 'Client.name', key: '[Client] Name'},
      {value: 'Client.website', key: '[Client] Website'},
      {value: 'General.date', key: "[General] Today's Date"},
      {value: 'General.datetime', key: "[General] Today's Date & Time"},
      {value: 'General.date_long', key: "[General] Today's Date (Long)"},
      {value: 'General.date_short', key: "[General] Today's Date (Short)"},
      {value: 'Location.address', key: '[Location] Address'},
      {value: 'Location.name', key: '[Location] Name'},
      {value: 'Location.website', key: '[Location] Website'},
      {value: 'PrimaryContact.email', key: '[Primary Contact] Email Address'},
      {value: 'PrimaryContact.fax', key: '[Primary Contact] Fax'},
      {value: 'PrimaryContact.first_name', key: '[Primary Contact] First Name'},
      {value: 'PrimaryContact.name', key: '[Primary Contact] Full Name'},
      {value: 'PrimaryContact.home_phone', key: '[Primary Contact] Home Phone'},
      {value: 'PrimaryContact.last_name', key: '[Primary Contact] Last Name'},
      {value: 'PrimaryContact.mobile_phone', key: '[Primary Contact] Mobile Phone'},
      {value: 'PrimaryContact.office_phone', key: '[Primary Contact] Office Phone'},
      {value: 'PrimaryContact.signature', key: '[Primary Contact] Signature'},
      {value: 'PrimaryContact.title', key: '[Primary Contact] Title'},
      {value: 'PrimaryInvestigator.email', key: '[Primary Investigator] Email Address'},
      {value: 'PrimaryInvestigator.fax', key: '[Primary Investigator] Fax'},
      {value: 'PrimaryInvestigator.first_name', key: '[Primary Investigator] First Name'},
      {value: 'PrimaryInvestigator.name', key: '[Primary Investigator] Full Name'},
      {value: 'PrimaryInvestigator.home_phone', key: '[Primary Investigator] Home Phone'},
      {value: 'PrimaryInvestigator.last_name', key: '[Primary Investigator] Last Name'},
      {value: 'PrimaryInvestigator.mobile_phone', key: '[Primary Investigator] Mobile Phone'},
      {value: 'PrimaryInvestigator.office_phone', key: '[Primary Investigator] Office Phone'},
      {value: 'PrimaryInvestigator.signature', key: '[Primary Investigator] Signature'},
      {value: 'PrimaryInvestigator.title', key: '[Primary Investigator] Title'},
      {value: 'PrimaryManager.email', key: '[Primary Manager] Email Address'},
      {value: 'PrimaryManager.fax', key: '[Primary Manager] Fax'},
      {value: 'PrimaryManager.first_name', key: '[Primary Manager] First Name'},
      {value: 'PrimaryManager.name', key: '[Primary Manager] Full Name'},
      {value: 'PrimaryManager.home_phone', key: '[Primary Manager] Home Phone'},
      {value: 'PrimaryManager.last_name', key: '[Primary Manager] Last Name'},
      {value: 'PrimaryManager.mobile_phone', key: '[Primary Manager] Mobile Phone'},
      {value: 'PrimaryManager.office_phone', key: '[Primary Manager] Office Phone'},
      {value: 'PrimaryManager.signature', key: '[Primary Manager] Signature'},
      {value: 'PrimaryManager.title', key: '[Primary Manager] Title'},
      {value: 'PrimarySubject.name', key: '[Primary Subject] Name'},
      {value: 'PrimarySubject.proper_name', key: '[Primary Subject] Proper Name'},
      {value: 'Customer.address_line_1', key: '[Your Company] Address Line 1'},
      {value: 'Customer.address_line_2', key: '[Your Company] Address Line 2'},
      {value: 'Customer.address_line_3', key: '[Your Company] Address Line 3'},
      {value: 'Customer.city', key: '[Your Company] City'},
      {value: 'Customer.country', key: '[Your Company] Country'},
      {value: 'Customer.fax', key: '[Your Company] Fax Number'},
      {value: 'Customer.address', key: '[Your Company] Full Address'},
      {value: 'Customer.login_link', key: '[Your Company] Login Link'},
      {value: 'Customer.name', key: '[Your Company] Name'},
      {value: 'Customer.primary_phone', key: '[Your Company] Primary Phone'},
      {value: 'Customer.secondary_phone', key: '[Your Company] Secondary Phone'},
      {value: 'Customer.email_signature', key: '[Your Company] Signature'},
      {value: 'Customer.state', key: '[Your Company] State'},
      {value: 'Customer.website', key: '[Your Company] Website'},
      {value: 'Customer.zip', key: '[Your Company] Zip/Postal Code'},
    ]
    return keyValuePair;
  }

  public getVariables(model: CustomFieldModel[]): {key: string, value: string}[]{
    let keyValuePair: {key: string, value: string}[] = [];

    model.map(x => {
      const key = `[${x.category}] ${x.label}`;
      const value = `{{${x.category.replace(' ', '')}.${x.label.replace(/[^\w\s]/gi, '').trim().split(' ').join('_').toLowerCase()}}}`
      keyValuePair.push({key, value});
    });
    const otherFieldVariables = this.getOtherVariablesByUserForId();
    keyValuePair = [...keyValuePair, ...otherFieldVariables];
    return keyValuePair;
  }
}
