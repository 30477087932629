<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Assign Salesperson</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form [formGroup] ="form" (ngSubmit)="onSubmit()">
      <div class="form-group row">
          <label class="col-sm-4 col-form-label" for="salesPerson">Salesperson
              <sup class="clsStric">*</sup></label>
          <div class="col-sm-8">
              <select class="dropdown-toggle btn btn-lg"
                  formControlName="salesperson" [ngClass] ="{ 'is-invalid': isSubmitted && form.controls.salesperson.errors }">
                  <option value=""> Select Salesperson </option>
                  <option value="{{item.id}}" *ngFor="let item of salesPersonSelectListItem;">
                      {{item.value}}
                  </option>
              </select>
              <div *ngIf="isSubmitted && form.controls.salesperson.errors" class="invalid-feedback">
                  <div *ngIf="form.controls.salesperson.errors.required" style="font-size: larger;">
                    Salesperson is required
                  </div>
              </div>
          </div>
      </div>
      <div class="modal-footer border-0">
          <button type="button" class="btn btn-outline-dark"
              (click)="activeModal.dismiss('Cancel click')">Cancel</button>
          <button type="submit" class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2">Assign Salesperson</button>
      </div>
  </form>
</div>
