export class DocumentTemplateModel {
    public id: number;
    public name: string;
    public description: string;
    public accessGroupId?: number;
    public isUseMergeFields: boolean;
    public caseTypeIds: number[];
    public fileUrl: string;
    public fileName: string;
}
