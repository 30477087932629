import { CustomFieldTypesLabel } from 'src/app/helpers/constants/CustomFields/CustomFieldTypesLabel';
import { CustomFieldSeachOptionsLabel } from 'src/app/helpers/constants/CustomFields/CustomFieldSeachOptionsLabel';
import { CustomFieldCaseCategoriesLabel } from 'src/app/helpers/constants/CustomFields/CustomFieldCaseCategoriesLabel';
import { CustomFieldsFilterSearch } from 'src/app/helpers/constants/filter-and-search/customFieldsFilter&Search';
import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

import { FilterInputModel, SearchByModel } from 'src/app/models/shared/filters.model';
import { CustomFieldFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/CustomFieldFilterOptions';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-custom-field-filter',
  templateUrl: './custom-field-filter.component.html',
  styleUrls: ['./custom-field-filter.component.css']
})
export class CustomFieldFilterComponent implements OnInit {

  filterBy: FilterInputModel[] = [];
  filterIn: FilterInputModel[] = [];
  searchBy: SearchByModel[] = [];
  isFilterInVisible = true;
  @Output()
  onApplyFilter = new EventEmitter();

  @Output()
  resetFilters = new EventEmitter();


  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();


  constructor() {
  }

  ngOnInit(): void {
    this.loadFilterOptions();
  }

  private loadFilterOptions(): void {
    this.filterBy = [];
    this.searchBy = [];
    this.filterIn = [];

    //filterby
    CustomFieldsFilterSearch.forEach((key, value) =>
    this.filterBy.push({ name: key, id: `${value}`, group: undefined, isSelected: value === CustomFieldFilterOptions.None })
    );

    CustomFieldTypesLabel.forEach((x, y) => {
      this.filterBy.push({
        name: x,
        id: `${CustomFieldFilterOptions.CaseType}_` + y,
        group: 'Field Type', isSelected: false
      });
    });

    //filterIn
    this.filterIn.unshift({
      name: 'All Categories',
      id: -1,
      isSelected: true
    });

    CustomFieldCaseCategoriesLabel.forEach((x, y) => {
      this.filterIn.push({
        name: x,
        id: y
      })
    });

    //search by
    CustomFieldSeachOptionsLabel.forEach((x, y) => {
      this.searchBy.push({
        id: y,
        name: x
      });
    });
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onResetFilters(): void {
    this.loadFilterOptions();
    this.resetFilters.emit();
  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }
}
