import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { LocationAddModel } from 'src/app/models/Location/location.add.model';
import { CountryModel } from 'src/app/models/shared/country.model';
import { StateModel } from 'src/app/models/shared/state.model';
import { ClientService } from 'src/app/services/client.service';
import { CountryService } from 'src/app/services/country.service';
import { LocationService } from 'src/app/services/location.service';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppUtils } from 'src/app/helpers';

@Component({
  selector: 'app-new-location',
  templateUrl: './new-location.component.html',
  styleUrls: ['./new-location.component.scss'],
})
export class NewLocationComponent implements OnInit {
  @BlockUI('container-blockui-location') blockUI: NgBlockUI;
  @Input() public isForPopup: boolean;
  @Input() breadCrumbInputs: { key: string, value: string[] }[];
  @Input() clientId: number;
  @Output() public saveClick = new EventEmitter();

  countrySelectListItem: CountryModel[] = [];
  stateSelectListItem: StateModel[] = [];
  loadBreadCrumb = false;

  constructor(
    private formBuilder: FormBuilder,
    private countryService: CountryService,
    private toasterService: ToasterService,
    private locationService: LocationService,
    private router: Router,
    private staffService: StaffService,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private ngbActiveModal: NgbActiveModal,
    public appUtil: AppUtils
  ) {
  }

  form: FormGroup;
  submitted = false;
  users: any;
  selectedCountryId: number;

  ngOnInit(): void {
    if (!this.isForPopup && this.breadCrumbInputs && this.breadCrumbInputs.length > 0) {
      this.loadBreadCrumb = true;
    }
    this.loadCountries();
    this.loadSalespersons();
    this.loadManagers();
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      country: ['', Validators.required],
      salesPersonId: [''],
      caseManagerId: [''],
      isPrimary: false,
      address1: [''],
      address2: [''],
      address3: [''],
      city: [''],
      state: [''],
      zip: [''],
      fax: [''],
      webSite: [''],
      billingEmail: [''],
      generalNotes: [''],
      casePolicies: [''],
      updatePolicies: [''],
      invoicePolicies: [''],
    });
  }

  // convenience getter for easy access to form fields
  get f() {
    return this.form.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all required fields');
      return;
    }

    const model = new LocationAddModel();
    model.clientId = this.clientId;
    model.name = this.form.value.name;
    model.isPrimary = this.form.value.isPrimary as boolean;
    model.country = this.form.value.country;
    model.address1 = this.form.value.address1;
    model.address2 = this.form.value.address2;
    model.address3 = this.form.value.address3;
    model.city = this.form.value.city;
    model.state = this.form.value.state;
    model.zipCode = this.form.value.zip;
    model.fax = this.form.value.fax;
    model.salesPersonId = this.form.value.salesPersonId;
    model.caseManagerId = this.form.value.caseManagerId;
    model.billingEmail = this.form.value.billingEmail;
    model.generalNotes = this.form.value.generalNotes;
    model.casePolicies = this.form.value.casePolicies;
    model.updatePolicies = this.form.value.updatePolicies;
    model.invoicePolicies = this.form.value.invoicePolicies;
    this.form.value.dynamicRows.map((x: { phoneType: any; phoneNum: any; extn: any; }) =>
    model.contactNos.push({
        contactTypeId: Number(x.phoneType),
        contactNumber: x.phoneNum,
        extensionNote: x.extn ?? '',
        id: 0,
        entityId: 0
      })
    );
    this.locationService.add(model).subscribe(
      (data: string) => {
        if (this.isForPopup) {
          this.saveClick.emit();
          this.ngbActiveModal.close('save click');
        } else {
          this.router.navigate(['/client', this.clientId, 'location']);
        }
        this.toasterService.success(data);
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
      }
    );
  }

  private loadCountries() {
    this.blockUI.start();
    this.countryService.getCountries().subscribe(
      (data: any) => {
        this.countrySelectListItem = data;
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => {
        this.blockUI.stop();
      }
    );
  }

  loadStates(name: any) {
    const id: any =
      this.countrySelectListItem.find((x) => x.name === name)?.id ?? undefined;
    if (id === null || id === undefined || id.length === 0) {
      return;
    }

    this.blockUI.start();
    this.countryService.getStates(id).subscribe(
      (data: any) => {
        this.stateSelectListItem = data;
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => {
        this.blockUI.stop();
      }
    );
  }

  private loadManagers() {
    this.staffService.getManagers().subscribe(
      (data: any) => {
        this.users = data;
      },
      (error) => { },
      () => { }
    );
  }

  private loadSalespersons() {
    this.staffService.getSalespersons().subscribe(
      (data: any) => {
        this.users = data;
      },
      (error) => { },
      () => { }
    );
  }
  get activeModal(): any {
    return this.ngbActiveModal;
  }
  cancelNewLocation(): void {
    if (this.isForPopup) {
      this.ngbActiveModal.dismiss('Cancel click');
    }
    else {
      this.router.navigate(['/client', this.clientId, 'location']);
    }
  }
}
