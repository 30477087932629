<ol class="breadcrumb">
  <li *ngFor="let breadcrumb of breadcrumbs; let i=index;" class="breadcrumb-item">
    <span *ngIf="breadcrumb.url !== null" [routerLink]="'/'+ breadcrumb.url" routerLinkActive="router-link-active">
      {{ breadcrumb.label }}
    </span>
    <span *ngIf="breadcrumb.url === null">
      {{ breadcrumb.label }}
    </span>
    <span *ngIf="i < breadcrumbs.length-1">
      
    </span>
  </li>
</ol>
<!-- <breadcrumb #parent>  
  <ol class="breadcrumb">
    <ng-template ngFor let-route [ngForOf]="parent.breadcrumbs">
      <li *ngIf="!route.terminal" class="breadcrumb-item">
        <a href="" [routerLink]="[route.url]">{{ route.displayName }}</a>
      </li>
      <li *ngIf="route.terminal" class="breadcrumb-item active" aria-current="page">{{ route.displayName }}</li>
    </ng-template>
  </ol>
</breadcrumb>   -->