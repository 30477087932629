import { Component } from '@angular/core';
import { NoteCategory } from 'src/app/helpers/enums/notes/noteCategory';

declare const exportNav: any;

@Component({
    selector: 'app-client-notes',
    templateUrl: './client-notes.component.html',
    styleUrls: ['./client-notes.component.css']
})

export class ClientNotesComponent {

  category: NoteCategory;
  
  constructor() {
    this.category = NoteCategory.Client;
  }

  ngOnInit(): void {}
}

