import { Component, OnInit } from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AddReferralSourceModel } from 'src/app/models/referral-source/addReferralSourceModel';
import { ReferralsourceService } from 'src/app/services/referralsource.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-new-referral-source',
  templateUrl: './new-referral-source.component.html',
  styleUrls: ['./new-referral-source.component.css'],
})
export class NewReferralSourceComponent implements OnInit {
  @BlockUI('container-blockui-new-folder-template') blockUI: NgBlockUI;
  form: FormGroup;
  submitted = false;
  model: AddReferralSourceModel;

  constructor(
    private toasterService: ToasterService,
    private router: Router,
    private referralSourceService: ReferralsourceService
  ) {
    this.model = new AddReferralSourceModel();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(),
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.id = 0;
    this.model.name = this.form.controls.name.value;
    this.model.description = this.form.controls.description.value;

    this.referralSourceService.post(this.model).subscribe(
      (resposne) => {
        setTimeout(() => {
          this.onCancel();
        }, 10);
        setTimeout(() => {
          this.toasterService.success('Referral source created successfully');
          this.onCancel();
        }, 300);
        this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }
  onCancel(): void {
    this.router.navigate(['/setting/cases/referral-sources-list']);
  }
}
