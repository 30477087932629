import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.css']
})
export class ImageCropperComponent implements OnInit {


  @Input()
  imageChangedEvent: any;

  @Input()
  imageFile: any;

  @Output()
  croppedImage = new EventEmitter<ImageCroppedEvent>();

  @Output()
  saveCroppedImage = new EventEmitter<boolean>();

  get activeModal(): any {
    return this.ngbActiveModal;
  }

  constructor(private ngbActiveModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage.emit(event);
  }

  onCrop(): void {
    this.saveCroppedImage.emit(true);
  }

  onCancel(): void {
    this.saveCroppedImage.emit(false);
  }

}
