import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { ClientContactDetailModel } from 'src/app/models/client-contact/client.contact.detail.model';
import { ClientContactService } from 'src/app/services/client.contact.service';
import { ToasterService } from 'src/app/services/toater.service';


@Component({
  selector: 'app-contact-overview',
  templateUrl: './contact-overview.component.html',
  styleUrls: ['./contact-overview.component.css']
})
export class ContactOverviewComponent implements OnInit, OnDestroy {
  
  //quicklinks dynamic sample data structure
  quicklink = [
    {
      mainmenu: "All cases", mainlink: "#", submenu: [
        { name: "Active cases", link: "#" }
      ]
    },
    {
      mainmenu: "All Invoices", mainlink: "#", submenu: [
        { name: "Unpaid invoices", link: "#" },
        { name: "Payment history", link: "#" },
        { name: "Retainers", link: "#" }
      ]
    },
  ]
  accesslevel = true;
  public model = new ClientContactDetailModel();

  //profile photo/avatar variable
  public userPhoto: string = "";

  //this.userPhoto="https://picsum.photos/200/300";
  //   this.staffName=data.firstName+" "+data.lastName;

  public contactName: string;
  profileImgUrl: string;

  public shimmerornot: string;

  @BlockUI() blockUI: NgBlockUI;
  navigationSubscription: Subscription;
  contactServiceSubscription: Subscription;
  // @Output()
  // setContactName = new EventEmitter<string>();


  constructor(
    private clientContactService: ClientContactService,
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute) {
    this.route.parent.params.subscribe(params => this.model.id = params.contactId);
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.loadOverview();
      }
    });
  }

  ngOnInit(): void {
  }

  private loadOverview(): void {
    this.blockUI.start();
    this.contactServiceSubscription = this.clientContactService.getOverview(this.model.id)
      .subscribe((data: ClientContactDetailModel) => {
        this.clientContactService.contactDetailSubject$.next({
          id: data.id,
          name: data.name,
          clientId: data.clientId,
          clientName: data.clientName
        });
        this.model = data;
        this.shimmerornot = this.model.profileImgUrl ? 'shimmer' : '';
        this.profileImgUrl = this.model.profileImgUrl ?? '/assets/img/avatars/user.svg';
      }, error => {
        this.toasterService.ProcessErrorResponse(error);
        this.router.navigate(['/client-contact']);
        this.blockUI.stop();
      }, () => {
        this.blockUI.stop();
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
    this.contactServiceSubscription.unsubscribe();
  }


}
