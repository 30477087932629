<div id="mySidenav" class="sidenavbar"  [ngClass]="  filterOpen == true ? 'openFilter' : 'closeFilter'  ">
    
    <div class="closebtn" (click)="closeFilterNav(false)" >&times;</div>
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div id="sidenavForm" class="sidenav-form sidenav-bottom-btn d-flex flex-column">
            <h3 class="text-dark-blue br-bottom-border">Filter Results</h3>
            <div class="form-group has-search pt-3 position-relative">
                <label for="filterBy">Filter By</label>
                <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                <div class="input-group position-relative">
                    <ng-select ngClas="w-100" [items]="filterBy" bindLabel="name" bindValue="id" groupBy="group"
                        formControlName="filter" name="select-filter1">
                        <ng-template ng-optgroup-tmp let-item="item">
                            {{item.group || json}}
                        </ng-template>
                    </ng-select>
                    <br>
                </div>
            </div>
            <div class="form-group has-search" *ngIf="isFilterInAvailable">
                <label for="In">In</label>
                <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                <div class="input-group position-relative">
                    <ng-select ngClas="w-100" [items]="filterIn" bindLabel="name" bindValue="id" groupBy="group"
                        formControlName="filterIn"></ng-select>
                    <div class="input-group-append position-absolute append-btn">
                        <button class="border-0 bg-transparent" type="button">
                            <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                        </button>
                    </div>
                </div>
            </div>

            <div *ngIf="isSearchingAvailable">
                <div class="form-group has-search">
                    <label for="searchBy">Search by</label>
                    <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                    <div class="input-group position-relative">
                        <ng-select (change)='onOptionsSelected($event)' ngClas="w-100" [items]="searchBy" bindLabel="name" bindValue="id" groupBy="group"
                            formControlName="searchBy"></ng-select>
                            <ng-template ng-optgroup-tmp let-item="item">
                                {{item.group || json}}
                            </ng-template>
                        <!-- <div class="input-group-append position-absolute append-btn">
                            <button class="border-0 bg-transparent" type="button">
                                <i class="text-white rounded-circle bg-orange align-middle"
                                    data-feather="chevron-down"></i>
                            </button>
                        </div> -->
                    </div>
                </div>
                <div *ngIf="isforvisible==true" class="form-group has-search">
                    <label for="searchFor">For</label>
                    <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                    <div class="input-group position-relative">
                        <i class="fas fa-search"></i>
                        <input name="for" type="text" class="form-control" placeholder="For"
                            formControlName="searchFor" />
                    </div>
                </div>
                <div *ngIf="isvisible==true">
                <div  class="form-group has-search">
                    <label for="searchFor">Start Date DOL (Date of Loss) </label>
                    <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                    <div class="input-group position-relative">
                        <i class="fas fa-search"></i>
                        <div 
                        class="form-group has-search">
                        <div class="input-group date"
                        data-date-format="mm-dd-yyyy">
                        <input
                        class="form-control"
                        placeholder="mm-dd-yyyy"
                        formControlName="searchFor"
                        ngbDatepicker
                        #d="ngbDatepicker"
                        [minDate]="{
                          year: 1920,
                          month: 1,
                          day: 1
                        }"
                      />
                      <div class="input-group-append">
                        <button
                          class="
                            btn btn-outline-secondary
                            calendar
                          "
                          (click)="d.toggle()"
                          type="button"
                        ></button>
                      </div>
                    </div>
                       
                    </div>
                    </div>
                </div>
                
                    <div class="form-group has-search">
                        <label for="searchFor">End Date DOL (Date of Loss)</label>
                         <span class="form-control-feedback"><i class="align-middle" data-feather="search"></i></span>
                         <i class="fas fa-search"></i>
                        <div class="input-group date"
                            data-date-format="mm-dd-yyyy">
                            <input
                            class="form-control"
                            placeholder="mm-dd-yyyy"
                            formControlName="searchFrom"
                            ngbDatepicker
                            #dt="ngbDatepicker"
                            [minDate]="{
                              year: 1920,
                              month: 1,
                              day: 1
                            }"
                          />
                          <div class="input-group-append">
                            <button
                              class="
                                btn btn-outline-secondary
                                calendar
                              "
                              (click)="dt.toggle()"
                              type="button"
                            ></button>
                          </div>
                        </div>
                    </div>

                </div>
            
            </div>

            <div class="text-right filter-btn filter-btn-bottom d-flex justify-content-end align-items-end">
                <ol class="list-unstyled d-flex align-items-center justify-content-end">
                    <li>
                        <button type="reset" (click)="onResetFilters()" class="resbtn">
                            Reset Filters
                        </button>
                    </li>
                    <li>
                        <button type="submit" class="btn btn-lg bg-blue text-white ml-2">
                            <i class="align-middle fas fa-check-circle"></i> Apply Filters
                        </button>
                    </li>
                </ol>
            </div>
        </div>
    </form>
</div>