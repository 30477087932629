import { Component, OnInit, ViewChild, Renderer2, ɵSWITCH_CHANGE_DETECTOR_REF_FACTORY__POST_R3__, ElementRef } from '@angular/core';
import { Router } from '@angular/router';

// for datatable
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { RoleService } from 'src/app/services/role.service';
import { UserRoleFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/user.roles.filter.options';

declare const exportNav: any;

@Component({
  selector: 'app-user-roles',
  templateUrl: './user-roles.component.html',
  styleUrls: ['./user-roles.component.css']
})
export class UserRolesComponent implements OnInit {

  @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;
  isCheckedAll = false;
  selectedRowIds: Array<string> = [];
  isDeleteButtonDisabled = true;
  filterBy: any;

  constructor(
    private roleService: RoleService,
    private tosterService: ToasterService,
    private renderer: Renderer2,
    private router: Router,
    private elem: ElementRef) { }

  get userRoleFilterOptions(): any {
    return UserRoleFilterOptions;
  }

  // capture filter open and close events
  filterNavStatus = false;


  ngOnInit(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      processing: true,
      responsive: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },

      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,
      order: [[1, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        self.blockUI.start();
        dataTablesParameters.filterOption = this.filterBy ?? UserRoleFilterOptions.None;
        self.roleService.query(dataTablesParameters)
          .subscribe(resp => {
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            self.blockUI.stop();
          }, () => { this.toggleControls(); });
      },

      columns: [
        {
          data: null,
          title: `<input type="checkbox" action-type="select-unSelect-all" ${this.isCheckedAll ? 'checked' : ''}/>`,
          orderable: false,
          render: (data, type, row) => {
            const disabled = row.isAssociatedWithUsers ? 'disabled' : '';
            return `<input type='checkbox' value = '${row.id}' action-type= "select-row" ${disabled} />`;
          }
        },
        {
          data: 'name',
          title: 'Name',
          render: (data, type, row) => {
            return `
                <a detail-person-id="${row.id}" class='link m-r-10' action-type='link-detail'>${row.name}</a>
                `;
          }
        },
        {
          data: 'description',
          title: 'Description'
        },
        {
          data: 'availableFor',
          title: 'Available For',
          orderable: true
        },
        {
          data: 'name',
          title: 'Action ',
          orderable: false,
          render: (data, type, row) => {
            const disabled = row.isAssociatedWithUsers ? 'disabled' : '';
            const deleteButton = `<button  class='btn del-btn btn-lg btn-table' action-type="delete" ${disabled}>
            <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
            </button>`;
            const undeleteButton = `<button  class='btn del-btn btn-lg btn-table' action-type="un-delete">
            <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-restore-alt  fa-1x p-2 text-danger'></i>
            </button>`;
            const editButton = this.filterBy !== UserRoleFilterOptions.DeletedRoles
              ? `<button  class='btn del-btn btn-lg btn-table' alt="undelete">
            <i action-type='edit' class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
            </button>` : '';

            return `<div class='fa-edit'>${editButton} ${this.filterBy !== UserRoleFilterOptions.DeletedRoles
              ? deleteButton : undeleteButton}
              </div>`;
          }
        },
      ],
      rowCallback: (row, data: any) => {
        const _row = $(row);

        _row.find('[action-type="edit"]')
          .off('click')
          .on('click', () => {
            self.router.navigate(['/setting/role/edit/', data.id]);
          });
        _row.find('[action-type="delete"]')
          .off('click')
          .on('click', () => {
            self.deleteRole([data.id]);
          });
        _row.find('[action-type="un-delete"]')
          .off('click')
          .on('click', () => {
            self.unDeleteRole([data.id]);
          });
        _row.find('[action-type = "select-row"]')
          .off('click').on('click', (e: any) => {
            this.onSelectedOrUnselectRow(e);
          });
      }
    };

  }
  closeFilterEvent(event: any): void {
    this.filterNavStatus = event;
  }
  reload(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }

  deleteRole(roleIds: string[]): void {
    if (!confirm('Are you sure you want to delete selected role(s)?')) {
      return;
    }
    this.blockUI.start();
    this.roleService.delete(roleIds).subscribe(() => {
      this.reload();
      this.tosterService.success('Role(s) deleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  unDeleteRole(roleIds: string[]): void {
    if (!confirm('Are you sure you want to undelete selected role(s)?')) {
      return;
    }
    this.blockUI.start();
    this.roleService.undelete(roleIds).subscribe(() => {
      this.reload();
      this.tosterService.success('Role(s) undeleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtInstance = dtInstance;
      const header = dtInstance.columns().header();
      $(header).find('[action-type="select-unSelect-all"]')
        .off('change').on('change', (e: any) => {
          this.onSelectAllRow(e.target.checked);
        });
    });
  }

  onSelectAllRow(isChecked: boolean): void {
    const rows = [...this.elem.nativeElement.querySelectorAll('[action-type= "select-row"]')]
      .filter(row => !row.hasAttribute('disabled'));
    if (isChecked) {
      rows.forEach(row => {
        this.renderer.addClass(row, 'checked');
        row.checked = true;
      });
    } else {
      rows.forEach(row => {
        this.renderer.removeClass(row, 'checked');
        row.checked = false;
      });
    }
    this.enableOrDisableDeleteButton();
  }

  onSelectedOrUnselectRow(event: any): void {
    if (event.target.checked) {
      this.renderer.addClass(event.target, 'checked');
    } else {
      this.renderer.removeClass(event.target, 'checked');
    }
    this.toggleControls();
  }

  onBulkDelete(): void {
    this.deleteRole(this.getSelectedRoleIs());
  }

  onBulkUnDelete(): void {
    this.unDeleteRole(this.getSelectedRoleIs());
  }

  applyFilter(filterData: any) {
    this.filterBy = filterData.filter;
    this.dtInstance.ajax.reload();
  }

  onResetFilters(): void {
    this.filterBy = UserRoleFilterOptions.None;
    this.dtInstance.ajax.reload();
  }
  private getSelectedRoleIs(): any[] {
    const roleIds = [];
    const rows = this.dtInstance.rows().nodes();
    [...rows.$('.checked')].forEach(item => roleIds.push($(item).val()));
    return roleIds;
  }

  private toggleControls(): void {
    this.setSelectAllCheckbox();
    this.enableOrDisableDeleteButton();
  }

  private setSelectAllCheckbox(): void {
    const rows = this.dtInstance.rows().nodes();
    if (rows !== null) {
      const header = this.dtInstance.columns().header();
      const selectAllElement = $(header).find('[action-type="select-unSelect-all"]')[0] as any;
      const selelectedRows = [...rows.$('[action-type= "select-row"]')]
        .filter(row => !row.hasAttribute('disabled'));
      selectAllElement.checked = selectAllElement != null && selelectedRows.length > 0
        && selelectedRows.every(row => row.classList.contains('checked'));
    }
  }

  private enableOrDisableDeleteButton(): void {
    const rows = this.dtInstance.rows().nodes();
    this.isDeleteButtonDisabled = [...rows.$('.checked')].length === 0;
  }

}
