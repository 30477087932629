<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>New Update </strong> | Lorem Ipsum</h3>
                        </div>

                        <!-- <div class="col-md-auto fa-client-title">
                            1213
                        </div> -->
                    </div>


                    <div class="row mx-0" *blockUI="'container-blockui-new-case-flag'">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form"  [formGroup]="form" (ngSubmit)="onSubmit()" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
                                          
                                            <div class="card-body">
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-12">
                                                                <label for="clientPolicies">
                                                                    Client Policies</label>

                                                                    <textarea class="form-control mb-3" rows="7 " readonly>
                                                                        SURV: For Super Center cases, DO NOT contact the employer.

FOR AOE: Investigators must email Gabriela Miranda at gmiranda@superiorgrocers.com, and Evelin Valencia at EValencia@superiorgrocers.com to see who will be assisting with the handling of this claim. The responder will advise the investigator who to contact. Interviews must be completed during work hours unless otherwise specified by the insured contacts.

We do not handle MC Cases for this client. They do those in-house

Combine ext requests with an update.

When to use Red Flag/Safety Alert preset in JD.

“When acknowledging a surveillance referral, the firm shall complete a pre-surveillance verification to include, but not be limited to, the claimant address verification, telephone number, social security number, possible vehicles—and if appropriate a preliminary social media check. The pre-surveillance should be initiated within 48hrs. of receipt of the assignment to determine the best strategy and timeframe to conduct the surveillance. Any new information gleaned needs to be communicated to the claims examiner in the J.D. Wesson CMS and the Plan of Action (POA) should be updated. If there are no provided planned events or there was no direction provided in the referral (e.g., conduct surveillance on the weekend, specific date(s), etc.), the firm should initiate surveillance within 7 days of the assignment or provide a projected date(s) of surveillance. The three-week due date is a general guideline to provide flexibility for the firm to complete the assignment and submit a report. Timely surveillance updates keep the claims examiner informed. This allows the claims examiner to provide additional information or direction to increase the success of the investigation.”

Surveillance: If you obtain video that clearly shows the claimant exceeding the provided limitations/restrictions, then send a Surveillance Update to the claims examiner (CE) and Red Flag/Safety Alert to the SIU (two entries in CMS). The SIU is interested in video that would indicate the claimant may be committing insurance fraud or in some cases, high exposure claims. The purpose of Red Flag/Safety Alerts is provide the SIU with an early notice that needs their immediate attention before the final report is submitted, so they can work with the claims staff. *** If video was obtained please add to your update "the final video is in the process of being rendered and will be submitted upon completion"

Surveillance Updates that should be sent to the claims examiner:
Acknowledgement of the assignment.
Whenever possible, use the Surveillance Update preset in CMS, not the Client Update. It provides all the areas needed for the claims examiner. You can edit or delete the fields in the update as needed, depending on the assignment or results of the surveillance for that day.
If warranted, when surveillance is scheduled. In some cases, a Case Note may be appropriate. It depends on the assignment or the instructions provided by the claims examiner. (see Surveillance Section for more details.)
After conducting a day of surveillance.
Questions, problems, or issues that have developed during the investigation.
Due Date Extensions

Field Investigations: If, during the investigation, information is developed regarding inconsistencies, suspicious or questionable activity, possible fraud, credibility issues, and safety concerns, that needs the immediate attention of SIU, then send a Client Update to the CE and Red Flag/Safety Alert to the SIU (two entries in CMS). Keep in mind, most AOE/COE investigations have conflicting issues, such as statements, inconsistent dates, an unwitnessed injury allegation, poor job performance issues, inconsistent mechanism of the injury, etc., which can suggest delaying/denying the claim, requesting surveillance, or additional follow-up by the claims staff. It is common that AOE/COE investigations have multiple red flags, hence, the reason for the field investigation. However, the emphasis for early notification to the SIU should be focused on workplace safety, suspected fraud (i.e., new red flags discovered or a material misrepresentation) either by the claimant or policyholder, and significant findings regarding high exposure claims—this activity should generate a Red Flag/Safety Alert. The report should contain information that would corroborate the reason for the early notification to the SIU.

Client Updates that should be sent to the claims examiner:

Acknowledgement of the assignment.
When interviews have been scheduled.
Quick summary or acknowledgment that the interview(s) were conducted.
If there are problems getting a hold of the employer and you need the claims examiner to intervene.
Questions, problems, or issues that have developed during the investigation.
Notifying the claims examiner that you mailed a contact letter to the claimant, employer, etc.
Any updates to the claimant’s contact information (e.g., name spelling, address, etc.)
Any documents the claims examiner may need prior to receiving the final report and supporting documentation.
Due Date Extensions
Use the Case Notes preset for documenting investigative steps such as leaving voice messages, emails, etc., to reduce email traffic to the claims examiner and provide documentation to the file. Investigators do not have to document every step of the investigation in CMS, but you need to determine what is important should there be a problem, complaint, or due diligence issue.

Note: You do not need to indicate how many times you tried to contact an individual in the investigative report, if you were able to conduct an interview. If needed, that can be documented in a Case Note. However, if you were unable to interview the claimant, employer, or a key witness, then you should indicate your due diligence in the investigative report to show non-cooperation.
                                                                    </textarea>
                                                            </div>
                                                        </div>

                                                        <hr>

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="name">Access Group<sup class="clsStric">*</sup></label>
                                                                    
                                                                    <div class="input-group position-relative">
                                                                        <select name="accessGroup" formControlName="accessGroup" #asd>
                                                                            <option value="Select Role" selected>Select Role</option>
                                                                            <option value="{{list.id}}" *ngFor="let list of users">{{list.name}}</option>
                                                                        </select>

                                                                        <div class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent" type="button">
                                                                            <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="subject">Subject<sup class="clsStric">*</sup></label>
                                                                    
                                                                    <div class="input-group position-relative">
                                                                        
                                                                        <ng-select [items]="accounts" bindLabel="name" bindValue="name"  groupBy="country" formControlName="subject">
                                                                                    <ng-template ng-optgroup-tmp let-item="item">
                                                                                        {{item.country || 'Unnamed group'}}
                                                                                    </ng-template>
                                                                        </ng-select>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-md-6">

                                                                <div class="form-group has-search">
                                                                    <label for="activity">Activity<sup class="clsStric">*</sup></label>
                                                                    
                                                                    <div class="input-group position-relative">
                                                                        
                                                                        <ng-select [items]="accounts" bindLabel="name" bindValue="name"  groupBy="country" formControlName="activity">
                                                                                    <ng-template ng-optgroup-tmp let-item="item">
                                                                                        {{item.country || 'Unnamed group'}}
                                                                                    </ng-template>
                                                                        </ng-select>

                                                                    </div>
                                                                </div>

                                                            </div>


                                                            <div class="col-md-6">

                                                                <div class="form-group has-search">
                                                                    <label for="date">Date</label>
                                                                    
                                                                    <input type="date" formControlName="date" class="form-control mb-3" id="date" name="date" autocomplete="off">

                                                                    <input type="time" formControlName="time" class="form-control mb-3" id="time" name="time" autocomplete="off">
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <hr>

                                                        <div class="row">
                                                            <div class="col-md-6">

                                                                <div class="form-group has-search">
                                                                    <label for="preset">Preset</label>
                                                                    
                                                                    <div class="input-group position-relative">
                                                                        <p *ngIf="asd.value == 'Select Role'">	↑ Select an access group first. ↑</p>
                                                                        <select *ngIf="asd.value != 'Select Role'" name="preset" formControlName="preset">
                                                                            <option value="Select Role" selected>Select Role</option>
                                                                            <option value="{{list.id}}" *ngFor="let list of users">{{list.name}}</option>
                                                                        </select>

                                                                        <div class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent" type="button">
                                                                            <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        
                                                        <hr>

                                                        <div class="row">
                                                            <div class="col-md-6">

                                                                <div class="form-group has-search">
                                                                    <label for="title">Title</label>
                                                                    
                                                                    <input type="text" formControlName="title" class="form-control mb-3" id="title" name="title" autocomplete="off">
                                                                </div>

                                                            </div>

                                                            <div class="col-md-12">

                                                                <div class="form-group has-search">
                                                                    <label for="body">Body</label>
                                                                    
                                                                    <textarea formControlName="body" class="form-control mb-3"  rows="8" >This will be replaced with ckeditor or any Rich editor | Dependency </textarea>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                
                                                                <div class="form-group has-search">
                                                                    <label >Tags</label>
                                                                    <ng-select [items]="companies" [addTag]="addTagFn"            [hideSelected]="true"           multiple="true"            bindLabel="name"  ></ng-select>
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label >Files</label>
                                                                    <input type="file" class="form-control mb-3" placeholder="This will be replaced with any upload widget | Dependency">

                                                                    <p>
                                                                        66.79% full
                                                                        <small>Using 1.96TB of your 2.93GB</small>
                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        


                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>


                                
                                  
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  routerLink="/setting/cases/flag-list"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="sub">Save Update <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
