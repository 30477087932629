import { Component, OnInit } from '@angular/core';

import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AddOrEditAccessGroupModel } from 'src/app/models/access-groups/add.access-group.model';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { RoleService } from 'src/app/services/role.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';
@Component({
  selector: 'app-new-access-group',
  templateUrl: './new-access-group.component.html',
  styleUrls: ['./new-access-group.component.css']
})
export class NewAccessGroupComponent implements OnInit {


  @BlockUI('container-blockui-new-access-group') blockUI: NgBlockUI;
  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private accessGroupService: AccessGroupService,
    private roleService: RoleService) {
  }

  form: FormGroup;
  submitted = false;
  roles: { id: string, value: string }[] = [];
  addAccessGroupModel: AddOrEditAccessGroupModel = new AddOrEditAccessGroupModel();

  get limitAccessToFormArray(): FormArray {
    return this.form.controls.limitAccessTo as FormArray;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      isRequireValidation: true,
      isRefereshLastUpdate: true,
      isIncludeInDocuments: true,
      limitAccessTo: new FormArray([], minSelectedCheckboxes())
    });
    this.loadRoles();
  }

  private loadRoles(): void {
    this.blockUI.start();
    this.roleService.getRoles().subscribe((data: any) => {
      this.roles = data;
      const fa: FormArray = this.limitAccessToFormArray;
      fa.clear();

      data.forEach(d => {
        fa.push(this.formBuilder.group({
          id: [d.id],
          name: [d.value],
          isSelected: [false]
        }));
      });
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.addAccessGroupModel.name = this.form.controls.name.value;
    this.addAccessGroupModel.description = this.form.controls.description.value;
    this.addAccessGroupModel.isRequiredValidation = this.form.controls.isRequireValidation.value;
    this.addAccessGroupModel.isRefreshLastUpdate = this.form.controls.isRefereshLastUpdate.value;
    this.addAccessGroupModel.isIncludeInDocuments = this.form.controls.isIncludeInDocuments.value;
    this.addAccessGroupModel.roleIds = this.form.value.limitAccessTo.filter(x => x.isSelected).map(x => x.id);
    this.blockUI.start();
    this.accessGroupService.post(this.addAccessGroupModel).subscribe(resposne => {
      setTimeout(() => {
        this.router.navigate(['/setting/accessgroup/list']);
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Access-Group created successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });

  }

}
