<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>General Finance Settings</strong> </h3>
                        </div>

                        <!-- <div class="col-md-auto fa-client-title">
                            1213
                        </div> -->
                    </div>


                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form"  [formGroup]="form" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
                                          
                                            <div class="card-body">
                                                <div class="user-header mb-4">
                                                    <h4 class="card-title mb-0">
                                                        Expense
                                                    </h4>
                                                </div>
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search" >
                                                                    <label for="expense_entry" >Expense Entry Number Format<sup class="clsStric">*</sup> <i class="align-middle fas fa-info-circle ml-2 text-light-black"  placement="top" ngbTooltip="Your expense entry number can consist of variables, along with other letters and special characters, such as (-) dashes or (_) underscores. The available variables are as follows:&#123; &#123; Expense.entry_number &#125; &#125; - Entry Number"></i></label>
                                                                    
                                                                    <input type="text" formControlName="expense_entry" class="form-control mb-3" id="expense_entry" name="expense_entry" autocomplete="off">
                                                                </div>
                                                            </div>

                                                          
                                                        </div>

                                                   


                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>


                                        <div class="card" >
                                          
                                            <div class="card-body">
                                                <div class="user-header mb-4">
                                                    <h4 class="card-title mb-0">
                                                        Expense Slip
                                                    </h4>
                                                </div>
                                             
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">


                                                            <div class="col-md-6 mb-2">

                                                                <div class="form-group has-search">
                                                                    <label id="overtimeCalculation" for="overtime_calculation"> Overtime Calculation Default</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="If yes, the system will pre-select the option to allow overtime on new payment slips by default."></i>
                                                                    <div>
                                                                        <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                            <input type="radio" id="customRadio_over1" class="custom-control-input" formControlName="customRadio_over1"  name="customRadio_over1" value="true">
                                                                            <label class="custom-control-label" for="customRadio_over1">Yes</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" id="customRadio_over2" class="custom-control-input" formControlName="customRadio_over1"  name="customRadio_over1" value="false">
                                                                            <label class="custom-control-label" for="customRadio_over2">No</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>

                                                            <div class="col-md-6 mb-2">
                                                                <div class="form-group has-search">
                                                                    <label for="overtimeRate">Overtime Rate (Percent)<sup class="clsStric">*</sup> <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="The percentage of base wages to calculate for overtime. For example, 150% would represent 'time and a half'"></i></label>
                                                                    
                                                                    <input type="text" formControlName="overtimeRate" class="form-control mb-3" id="overtimeRate" name="overtimeRate" autocomplete="off">
                                                                </div>
                                                            </div>

                                                     
                                                         
                                                        </div>


                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search" >
                                                                    <label for="overtimeThreshold" >Overtime Threshold (Hours)<sup class="clsStric">*</sup> <i class="align-middle fas fa-info-circle ml-2 text-light-black"  placement="top" ngbTooltip="The number of expensed hours in a week before overtime is accrued."></i></label>
                                                                    
                                                                    <input type="text" formControlName="overtimeThreshold" class="form-control mb-3" id="overtimeThreshold" name="overtimeThreshold" autocomplete="off">
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="paymentSlip">Payment Slip Number Format<sup class="clsStric">*</sup> <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="Your payment slip number can consist of variables, along with other letters and special characters, such as (-) dashes or (_) underscores."></i></label>
                                                                    
                                                                    <input type="text" formControlName="paymentSlip" class="form-control mb-3" id="paymentSlip" name="paymentSlip" autocomplete="off">
                                                                </div>
                                                            </div>
                                                        </div>

                                                 
                                                 

                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>


                                        <div class="card" >
                                          
                                            <div class="card-body">
                                                <div class="user-header mb-4">
                                                    <h4 class="card-title mb-0">
                                                        Invoice
                                                    </h4>
                                                </div>
                                               
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">

                                                            <div class="col-md-6 mb-2">
                                                                <div class="form-group has-search">
                                                                    <label for="billingAddress">Billing Address/Info<sup class="clsStric">*</sup> <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="Your billing address, and other billing info that you want to appear on your invoices."></i></label>
                                                                    
                                                                    <textarea type="text" formControlName="billingAddress" class="form-control p-3 mb-3" rows="5" id="billingAddress" name="billingAddress" autocomplete="off"></textarea>
                                                                </div>
                                                            </div>


                                                            <div class="col-md-6 mb-2">
                                                                <div class="form-group has-search">
                                                                    <label >Default currency<sup class="clsStric">*</sup>  <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="The default currency that Trackops uses to calculate and format currency."></i></label>
                                                                    
                                                                    <select class="form-control" formControlName="defaultCurrency">
                                                                        <option *ngFor="let items of users; let i=index" value="items">
                                                                            {{i}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                     
                                                         
                                                        </div>


                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search" >
                                                                    <label for="defaultInvoice">Default Invoice Due Days <i class="align-middle fas fa-info-circle ml-2 text-light-black"  placement="top" ngbTooltip="If populated, the system will automatically populate the due date on new invoices to 30 days in the future."></i></label>
                                                                    
                                                                    <input type="text" formControlName="defaultInvoice" class="form-control mb-3" id="defaultInvoice" name="defaultInvoice" autocomplete="off">
                                                                </div>
                                                            </div>

                                                            <!-- 
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="paymentSlipNumber">Payment Slip Number Format<sup class="clsStric">*</sup> <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="Your payment slip number can consist of variables, along with other letters and special characters, such as (-) dashes or (_) underscores."></i></label>
                                                                    
                                                                    <input type="text" formControlName="paymentSlipNumber" class="form-control mb-3" id="paymentSlipNumber" name="paymentSlipNumber" autocomplete="off">
                                                                </div>
                                                            </div>
                                                            -->


                                                            <div class="col-md-6">
                                                                <label>Default public invoice notes</label>
                                                                <textarea class="form-control p-3" name="defaultNotes" id="defaultNotes" rows="5" placeholder="" formControlName="defaultNotes"></textarea>
                                                            </div>

                                                            <div class="col-md-6 mt-2">
                                                                <label>Default invoice terms</label>
                                                                <textarea class="form-control p-3" name="defaultInvoiceNotes" id="defaultInvoiceNotes" rows="5" placeholder="" formControlName="defaultInvoiceNotes"></textarea>
                                                            </div>



                                                            
                                                            <div class="col-md-6 mt-4 mb-2">

                                                                <div class="form-group has-search">
                                                                    <label id="includeInvoiceSummary" for="internal"> Include Invoice Summary <sup class="clsStric">*</sup></label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="When enabled, the system will append an invoice summary page to the bottom of each printed PDF."></i>
                                                                    <div>
                                                                        <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                            <input type="radio" id="includeInternalSwitch1" class="custom-control-input" formControlName="includeInternalSwitch1"  name="includeInternalSwitch1" value="true">
                                                                            <label class="custom-control-label" for="includeInternalSwitch1">Yes</label>
                                                                        </div>

                                                                        
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" id="includeInternalSwitch2" class="custom-control-input" formControlName="includeInternalSwitch1"  name="includeInternalSwitch1" value="false">
                                                                            <label class="custom-control-label" for="includeInternalSwitch2">No</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                               
                                                            </div>

                                                            
                                                        

                                                        </div>

                                                        <div class="row">
                                                            <div class="col-md-6 ">
                                                                <div class="form-group has-search">
                                                                    <label for="invoiceNumberFormat">Invoice Number Format<sup class="clsStric">*</sup> <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="Your invoice number can consist of variables, along with other letters and special characters, such as (-) dashes or (_) underscores."></i></label>
                                                                    
                                                                    <input type="text" formControlName="invoiceNumberFormat" class="form-control mb-3" id="invoiceNumberFormat" name="invoiceNumberFormat" autocomplete="off">
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6 mb-2">
                                                                <div class="form-group has-search">
                                                                    <label for="lineDateFormat">Line Item Date Format <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="Leave blank to use the default format, otherwise use placeholders to construct your own date format (e.g. Y-m-d or m/d/Y):"></i></label>
                                                                    
                                                                    <input type="text" formControlName="lineDateFormat" class="form-control mb-3" id="lineDateFormat" name="lineDateFormat" autocomplete="off">
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="row">
                                                            <div class="col-md-6 mb-2">
                                                                <div class="form-group has-search">
                                                                    <label for="lineItemStaff">Line Item Staff Display  <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="When enabled, invoices will display the staff member associated with each line item on an invoice."></i></label>
                                                                    
                                                                    <select class="form-control" formControlName="lineItemStaff">
                                                                        <option *ngFor="let items of users; let i=index">
                                                                            {{i}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6 mt-4 mb-2">

                                                                <div class="form-group has-search">
                                                                    <label id="showItemCodes" for="showItemCodes">Show Item Codes <sup class="clsStric">*</sup></label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="When enabled, invoice line items will include item codes (if applicable)."></i>
                                                                    <div>
                                                                        <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                            <input type="radio" id="showItemCodeSwitch1" class="custom-control-input" formControlName="showItemCodeSwitch1"  name="showItemCodeSwitch1" value="true">
                                                                            <label class="custom-control-label" for="showItemCodeSwitch1">Yes</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" id="showItemCodeSwitch2" class="custom-control-input" formControlName="showItemCodeSwitch1"  name="showItemCodeSwitch1" value="false">
                                                                            <label class="custom-control-label" for="showItemCodeSwitch2">No</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                               
                                                            </div>

                                                        </div>


                                                        <div class="row">
                                                           

                                                            <div class="col-md-6 mb-2">

                                                                <div class="form-group has-search">
                                                                    <label id="shoWLineItem" for="shoWLineItem">Show Line Item Dates <sup class="clsStric">*</sup></label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="When enabled, the system will display the primary subject from the connected case on each invoice (if available)."></i>
                                                                    <div>
                                                                        <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                            <input type="radio" id="shoWLineItemSwitch1" class="custom-control-input" formControlName="shoWLineItemSwitch1"  name="shoWLineItemSwitch1" value="true">
                                                                            <label class="custom-control-label" for="shoWLineItemSwitch1">Yes</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" id="shoWLineItemSwitch2" class="custom-control-input" formControlName="shoWLineItemSwitch1"  name="shoWLineItemSwitch1" value="false">
                                                                            <label class="custom-control-label" for="shoWLineItemSwitch2">No</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                               
                                                            </div>


                                                            <div class="col-md-6 mb-2">

                                                                <div class="form-group has-search">
                                                                    <label id="showPrimarySubject" for="showPrimarySubject">Show Primary Subject <sup class="clsStric">*</sup></label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="When enabled, the system will display the primary subject from the connected case on each invoice (if available)."></i>
                                                                    <div>
                                                                        <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                            <input type="radio" id="showPrimarySubjectSwitch1" class="custom-control-input" formControlName="showPrimarySubjectSwitch1"  name="showPrimarySubjectSwitch1" value="true">
                                                                            <label class="custom-control-label" for="showPrimarySubjectSwitch1">Yes</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" id="showPrimarySubjectSwitch2" class="custom-control-input" formControlName="showPrimarySubjectSwitch1"  name="showPrimarySubjectSwitch1" value="false">
                                                                            <label class="custom-control-label" for="showPrimarySubjectSwitch2">No</label>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                               
                                                            </div>

                                                        </div>
                                                 
                                                 

                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>



                                        <div class="card" >
                                          
                                            <div class="card-body">
                                                <div class="user-header mb-4">
                                                    <h4 class="card-title mb-0">Retainer</h4>
                                                </div>
                                               
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search" >
                                                                    <label for="retainerNumberFormat" >Retainer Number Format<sup class="clsStric">*</sup> <i class="align-middle fas fa-info-circle ml-2 text-light-black"  placement="top" ngbTooltip="Your retainer number can consist of variables, along with other letters and special characters, such as (-) dashes or (_) underscores. The available variables are as follows:&#123; &#123;Retainer.retainer_number &#125; &#125; - Retainer Number"></i></label>
                                                                    
                                                                    <input type="text" formControlName="retainerNumberFormat" class="form-control mb-3" id="retainerNumberFormat" name="retainerNumberFormat" autocomplete="off">
                                                                </div>
                                                            </div>

                                                          
                                                        </div>

                                                       



                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>


                                        <div class="card" >
                                          
                                            <div class="card-body">
                                                <div class="user-header mb-4">
                                                    <h4 class="card-title mb-0">Salesperson</h4>
                                                </div>
                                           
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            

                                                            <div class="col-md-6 mb-2">

                                                                <div class="form-group has-search">
                                                                    <label id="alwaysSalesperson" for="require_or_not"> Always use case salesperson <sup class="clsStric">*</sup></label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="When enabled, the system will assign the primary salesperson from the connected case (if exists) to a new invoice. When disabled, or if a primary salesperson is not assigned, the system will use the default salesperson configuration from the client, location, and contact."></i>
                                                                    <div>
                                                                        <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                            <input type="radio" id="alwaysSalespersonSwitch1" class="custom-control-input" formControlName="alwaysSalespersonSwitch1"  name="alwaysSalespersonSwitch1" value="true">
                                                                            <label class="custom-control-label" for="alwaysSalespersonSwitch1">Yes</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" id="alwaysSalespersonSwitch2" class="custom-control-input" formControlName="alwaysSalespersonSwitch1"  name="alwaysSalespersonSwitch1" value="false">
                                                                            <label class="custom-control-label" for="alwaysSalespersonSwitch2">No</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                
                                                            </div>
                                                        </div>

                                                       



                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>

                                    </div>


                                
                                  
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  [routerLink]="['..']"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="sub">Save Setting <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
