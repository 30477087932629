import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { StaffService } from 'src/app/services/staff.serrvice';

@Component({
  selector: 'app-edit-staff-note',
  templateUrl: './edit-staff-note.component.html',
  styleUrls: ['./edit-staff-note.component.css']
})
export class EditStaffNoteComponent implements OnInit, OnDestroy {

  staffSubscription: Subscription;
  breadCrumbInputs: { key: string, value: string[] }[] = [];

  constructor(private staffService: StaffService) { }

  ngOnInit(): void {
    this.staffSubscription = this.staffService.staffDetai$.subscribe((data) => {
      if (data == null || data === undefined) {
        return;
      }
      this.breadCrumbInputs.push({ key: 'id', value: [data.id, data.name] });
    });
  }

  ngOnDestroy(): void {
    this.staffSubscription.unsubscribe();
  }

}
