<div class="wrapper" [ngClass]="{'fullw': !isValidUser, 'wrapperfull': sidenavStatus}">
  <!-- <div *ngIf="isLoggedin == false">
    <app-login></app-login>
  </div> -->
  <div [ngStyle]="{'display': isValidUser ? 'block' : 'none' }">
    <app-side-bar fxFlex (sideToggleEvent)="toggleSidenav()"></app-side-bar>
    <app-header flexLayout="column"></app-header>
  </div>
  <div class="main" [ngClass]="{'forloginonly': !isValidUser}">
    <block-ui>
      <router-outlet></router-outlet>
    </block-ui>
    <footer class="footer">
      <app-footer></app-footer>
    </footer>
  </div>
</div>