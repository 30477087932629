import { ContatcNoInEntity } from "../shared/contactNoInEntity";

export class LocationModel
{
public  locationName: string;
public  isPrimary: boolean;
public  country: string;
public  address1: string;
public  address2: string;
public  address3: string;
public  city: string;
public  state: string;
public county:string;
public  zip: string;
public  fax: string;
public contactNos: ContatcNoInEntity<number>[] = [];
}
