import { Component, OnDestroy, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-custom-field',
  templateUrl: './new-custom-field.component.html',
  styleUrls: ['./new-custom-field.component.css']
})
export class NewCustomFieldComponent implements OnInit, OnDestroy {
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  constructor() {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
