import { Component, OnInit, ChangeDetectorRef, ViewChild, Renderer2 } from '@angular/core';
import { NoteCategory } from 'src/app/helpers/enums/notes/noteCategory';

@Component({
    selector: 'app-client-notes',
    templateUrl: './contact-notes.component.html',
    styleUrls: ['./contact-notes.component.css']
})

export class ContactNotesListComponent {

    category: NoteCategory;

    constructor() {
      this.category = NoteCategory.Contact;
    }

}

