import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css'],
})
export class SettingComponent implements OnInit {
  // sample data created for setting
  daSetting = [
    {
      id: 1,
      title: 'Global',
      submenu: [
        {
          name: 'General Global Settings',
          routerlnk: '/setting/global/general',
          subtitle: 'Configure global settings for the Trackops system.',
        },
        {
          name: 'Company Locations',
          routerlnk: '/setting/global/company-location-list',
          subtitle: 'Manage your physical branch offices.',
        },
        {
          name: 'Email Settings & Templates',
          routerlnk: '/clientList',
          subtitle:
            'Edit email settings and templates for things such as case updates, invoices, events, and more.',
        },
        {
          name: 'Document Templates',
          routerlnk: '/setting/global/document-template-list',
          subtitle: 'Upload your report templates.',
        },
        {
          name: 'Custom Fields',
          routerlnk: '/setting/global/custom-field-list',
          subtitle:
            'Create your own custom fields for various parts of the application.',
        },
        {
          name: 'Upload Logos',
          routerlnk: '/setting/global/upload-logo-edit',
          subtitle:
            'Upload your company logos for the interface, invoices, and email.',
        },
        {
          name: 'Addons & Integrations',
          routerlnk: '/setting/global/addons-list',
          subtitle:
            'Configure addons that integrate with 3rd party applications.',
        },
      ],
    },
    {
      id: 2,
      title: 'Users and Roles',
      submenu: [
        {
          name: 'Users access groups',
          routerlnk: '/setting/accessgroup/list',
          subtitle: 'Configure global settings for the Trackops system.',
        },
        {
          name: 'User Roles',
          routerlnk: '/setting/role',
          subtitle: 'Manage your physical branch offices.',
        },

        {
          name: 'User Security Settings',
          routerlnk: '/setting/role/user-security-setting',
          subtitle: 'Configure authentication security settings for all users.',
        },
        {
          name: 'Company Policies',
          routerlnk: '/setting/role/company-policies',
          subtitle:
            'Maintain company compliance by adding policies and procedures.',
        },
      ],
    },
    {
      id: 3,
      title: 'Cases',
      submenu: [
        {
          name: 'General Case Settings',
          routerlnk: '/setting/cases/general',
          subtitle: 'Configure settings for cases.',
        },
        {
          name: 'Case Assignment Types',
          routerlnk: '/setting/cases/assigntype',
          subtitle: 'Configure the groups that users can be assigned into.',
        },

        {
          name: 'Case Flags',
          routerlnk: '/setting/cases/flag-list',
          subtitle:
            'Setup indicators that flag important information on a case.',
        },
        {
          name: 'Case Regions',
          routerlnk: '/setting/cases/regions-list',
          subtitle:
            'Create and edit your geographical case regions or sales territories.',
        },

        {
          name: 'Case Services',
          routerlnk: '/setting/cases/services-list',
          subtitle: 'Update the services that you provide.',
        },
        {
          name: 'Case Type',
          routerlnk: '/setting/cases/case-type-list',
          subtitle: 'Configure the types/genres of cases that you work.',
        },
        {
          name: 'Case Update Presets',
          routerlnk: '/setting/cases/update-preset-list',
          subtitle:
            'Create pre-defined blocks of text for case update standardization',
        },
        {
          name: 'Case Statuses',
          routerlnk: '/setting/cases/case-status-list',
          subtitle: 'Configure and re-order your case statuses.',
        },
        {
          name: 'Case Status Triggers',
          routerlnk: '/setting/cases/edit-case-status-trigger',
          subtitle: 'Automate case status changes for your cases.',
        },

        {
          name: 'Case Reviews',
          routerlnk: '/setting/cases/case-review-list',
          subtitle: 'Setup evaluations to gather data about your cases.',
        },

        {
          name: 'Folder Templates',
          routerlnk: '/setting/cases/folder-templates',
          subtitle:
            'Automatically generate default folders when new cases are created.',
        },
        {
          name: 'Subject Types',
          routerlnk: '/setting/cases/subject-type-list',
          subtitle:
            'Configure the types/genres of subjects that you deal with.',
        },

        {
          name: 'Referral Sources',
          routerlnk: '/setting/cases/referral-sources-list',
          subtitle:
            'Define the places or avenues from which your case work originates.',
        },
      ],
    },
    {
      id: 4,
      title: 'Dashboard',
      submenu: [
        {
          name: 'Dashboard Tab Presets',
          routerlnk: '/setting/dashboard-tab-presets',
          subtitle: 'Configure default dashboard tabs for each user role.',
        },
      ],
    },
    {
      id: 5,
      title: 'Finance ',
      submenu: [
        {
          name: 'General Finance Settings',
          routerlnk: '/setting/finance/general-finance-setting',
          subtitle: 'Configure settings for invoices and expenses',
        },
        {
          name: 'Invoice & Expense Items',
          routerlnk: '/setting/finance/invoice-and-expense-list',
          subtitle: 'Manage invoice and expense items.',
        },
        {
          name: 'Item Codes',
          routerlnk: '/setting/finance/item-codes',
          subtitle:
            'Categorize your expenses and invoices through a set of item codes.',
        },
        {
          name: 'Payment Methods',
          routerlnk: '/setting/finance/payment-methods',
          subtitle:
            'Setup the way you receive payments for your invoices and retainers.',
        },
        {
          name: 'Tax Rates',
          routerlnk: '/setting/finance/tax-rates',
          subtitle:
            'Configure a tax rate table for use in invoicing and and related functions.',
        },
        {
          name: 'Tracking Categories',
          routerlnk: '/setting/finance/tracking-categories',
          subtitle:
            'Categorize expenses and invoices for more detailed reporting.',
        },
      ],
    },
    {
      id: 6,
      title: 'Calendar',
      submenu: [
        {
          name: 'Calendars',
          routerlnk: '/setting',
          subtitle:
            'Create calendars to categorize and color code your tasks & events.',
        },
        {
          name: 'Task Templates',
          routerlnk: '/setting',
          subtitle:
            'Automatically generate new tasks on cases based on pre-defined templates.',
        },
      ],
    },
  ];

  // expandAll()
  constructor() {}

  ngOnInit(): void {}
}
