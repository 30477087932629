<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12 px-0 pb-5">
                            <div class="col-auto mt-n1">
                                <app-breadcrumb></app-breadcrumb>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto d-none d-sm-block mb-3">
                            <h3 class="page-title"><strong>New Event</strong> </h3>
                            <!-- <p>Create new client detail here.</p> -->
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card">
                                            <div class="card-body">
                                                <div class="card-text">
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <div class="input-group position-relative">
                                                                    <label id="Label-24">Title<sup class="clsStric">*</sup></label>
                                                                    <div class="input-group position-relative">
                                                                        <input type="text" class="form-control mb-3" class="form-control" autocomplete="off" />

                                                                        <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <label id="dueOn">Case <sup class="clsStric">*</sup></label>

                                                                <div class="col-md-3">
                                                                    <button type="button" class="btn btn-primary">Edit</button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <label id="Calendar"> Calendar</label>
                                                                <div class="input-group position-relative">

                                                                    <select>
                                                                        <option value="" selected>Event</option>
                                                                        <option value="">Event1</option>
                                                                    </select>
                                                                    <div class="input-group-append position-absolute append-btn">
                                                                        <button class="border-0 bg-transparent" type="button">
                                                                        <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search eventInput">
                                                                <input type="checkbox" class="Event" name="Event" value="Event">
                                                                <label for="Event"> All Day Event</label>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <label id="Status"> Status</label>
                                                                <div class="input-group position-relative">
                                                                    <select>
                                                                        <option value="" selected>Not Started</option>
                                                                        <option value=""></option>
                                                                    </select>
                                                                    <div class="input-group-append position-absolute append-btn">
                                                                        <button class="border-0 bg-transparent" type="button">
                                                                        <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <label id="Timezone"> Timezone</label>
                                                                <div class="input-group position-relative">
                                                                    <select>
                                                                        <option value="" selected>System default</option>
                                                                        <option value=""></option>
                                                                    </select>
                                                                    <div class="input-group-append position-absolute append-btn">
                                                                        <button class="border-0 bg-transparent" type="button">
                                                                        <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-5">
                                                            <label id=" startDate "> Start Date :</label>
                                                            <table class="table table-striped border">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <input value="2021-06-01" type="date" class="form-control">
                                                                        </td>
                                                                        <td>
                                                                            <select class="form-control">
                                                                        <option>01
                                                                        </option>
                                                                    </select>
                                                                        </td>
                                                                        <td>
                                                                            <select class="form-control">
                                                                        <option>11
                                                                        </option>
                                                                    </select>
                                                                        </td>
                                                                        <td>
                                                                            <select class="form-control">
                                                                        <option>AM
                                                                        </option>
                                                                        <option>PM
                                                                        </option>
                                                                    </select>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-5">
                                                            <label id="endDate"> End Date :</label>

                                                            <table class="table table-striped border">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <input value="2022-06-01" type="date" class="form-control">
                                                                        </td>
                                                                        <td>
                                                                            <select class="form-control">
                                                                        <option>01
                                                                        </option>
                                                                    </select>
                                                                        </td>
                                                                        <td>
                                                                            <select class="form-control">
                                                                        <option>00
                                                                        </option>
                                                                    </select>
                                                                        </td>
                                                                        <td>
                                                                            <select class="form-control">
                                                                        <option>AM
                                                                        </option>
                                                                        <option>PM
                                                                        </option>
                                                                    </select>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <hr>
                                                    <div class="card flex-fill w-100 mb-4">
                                                        <div class="card-header rounded p-4 mob-px-4">
                                                            <div class="user-header mb-4">
                                                                <h4 class="user-title font-weight-bold mb-0">Address</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <label id="Country"> Country</label>
                                                                <div class="input-group position-relative">

                                                                    <select>
                                                                        <option value="" selected>United States</option>
                                                                    </select>
                                                                    <div class="input-group-append position-absolute append-btn">
                                                                        <button class="border-0 bg-transparent" type="button">
                                                                        <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <div class="input-group position-relative">

                                                                    <label id="Label-24">Address <sup class="clsStric">*</sup></label>
                                                                    <div class="input-group position-relative">
                                                                        <input type="text" class="form-control mb-3" class="form-control" autocomplete="off" placeholder="Address " />
                                                                        <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">

                                                                <div class="input-group position-relative">
                                                                    <label id="salesperson"> City </label>

                                                                    <div class="input-group position-relative">
                                                                        <input type="text" class="form-control mb-3" class="form-control" autocomplete="off" placeholder="City" />

                                                                        <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <label id="State"> State/Province </label>
                                                                <div class="input-group position-relative">

                                                                    <select>
                                                                    <option value="" selected> State</option>
                                                                    <option value=""></option>
                                                                </select>
                                                                    <div class="input-group-append position-absolute append-btn">
                                                                        <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">

                                                                <div class="input-group position-relative">

                                                                    <label id="Label-24">Zip/Postelcode<sup class="clsStric">*</sup></label>
                                                                    <div class="input-group position-relative">
                                                                        <input type="text" class="form-control mb-3" class="form-control" autocomplete="off" placeholder="Zip/Postelcode" />

                                                                        <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <hr>
                                                    <div class="row">
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <label id="Assignedto"> Assigned To </label>
                                                                <div class="input-group position-relative">
                                                                    <select>
                                                                    <option value="" selected>Assignedto</option>
                                                                    <option value=""></option>
                                                                </select>
                                                                    <div class="input-group-append position-absolute append-btn">
                                                                        <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-12">
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="user-header mb-4">
                                                    <h4 class="user-title font-weight-bold mb-0">Notes</h4>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 mb-4">
                                                        <textarea class="form-control p-3" id="notes_terms" rows="5" placeholder="Write here..."></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"> Cancel</button>
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="sub">Save Event <span class="fa fa-arrow-right"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>