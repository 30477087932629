import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.css']
})
export class AddLocationComponent implements OnInit, OnDestroy {
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  clientId: number;
  clientSubscription: Subscription;
  constructor(private route: ActivatedRoute,
              private clientService: ClientService) {
    this.route.params.subscribe(param => this.clientId = Number(param.id));
    this.clientSubscription = this.clientService.clientListDetail$.subscribe(data => {
      if (data) {
        this.breadCrumbInputs.push({
          key: 'id', value: [this.clientId.toString(), data.name]
        });
      }
    });
  }
  ngOnInit(): void {
  }
  ngOnDestroy(): void {
    this.clientSubscription.unsubscribe();
  }
}
