<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Select client ..</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">

            <div class="row">
                <div class="col-sm-4 d-flex col-form-label my-auto">
                    <label for="selectaf">Client <sup class="clsStric">*</sup></label>
                </div>
                <div class="col-sm-8">
                    <ng-select [items]="clientList"  bindLabel="value" [selectableGroup]="true" [searchable]="false" [clearable]="false"
                     bindValue="id" name="selectaf" formControlName="client" class="da-ngselect-max" [ngClass]="{ 'is-invalid': isSubmitted && form.controls.client.errors }">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                     {{item.value}}
                    </ng-template>
                </ng-select>
                </div>
            </div>
          
             
                <div *ngIf="isSubmitted && form.controls.client.errors" class="invalid-feedback">
                    <div *ngIf="form.controls.client.errors.required" style="font-size: larger;">
                        Client is required
                    </div>
                </div>
        </div>
        <div class="modal-footer border-0">
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cancel click')">Cancel</button>
            <button type="submit" class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2">Continue </button>
        </div>
    </form>
</div>