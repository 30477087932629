import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { AddOrEditAccessGroupModel } from 'src/app/models/access-groups/add.access-group.model';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { RoleService } from 'src/app/services/role.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';

@Component({
  selector: 'app-edit-access-group',
  templateUrl: './edit-access-group.component.html',
  styleUrls: ['./edit-access-group.component.css']
})
export class EditAccessGroupComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  model: AddOrEditAccessGroupModel;
  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private accessGroupService: AccessGroupService,
    private roleService: RoleService) {
    this.model = new AddOrEditAccessGroupModel();
    this.activeRoute.params.subscribe(params => this.model.id = params.id);
  }

  form: FormGroup;
  submitted = false;
  roles: { id: string, value: string }[] = [];

  get limitAccessToFormArray(): FormArray {
    return this.form.controls.limitAccessTo as FormArray;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      isRequireValidation: true,
      isRefereshLastUpdate: true,
      isIncludeInDocuments: true,
      limitAccessTo: new FormArray([], minSelectedCheckboxes())
    });
    this.loadData();
  }

  private loadData(): void {
    this.blockUI.start();
    forkJoin([
      this.roleService.getRoles(),
      this.accessGroupService.get(this.model.id)
    ]).subscribe(([
      roles,
      accessGroupEditModel
    ]) => {
      this.model = accessGroupEditModel;

      this.form.controls.name.setValue(this.model.name);
      this.form.controls.description.setValue(this.model.description);
      this.form.controls.isRequireValidation.setValue(this.model.isRequiredValidation);
      this.form.controls.isRefereshLastUpdate.setValue(this.model.isRefreshLastUpdate);
      this.form.controls.isIncludeInDocuments.setValue(this.model.isIncludeInDocuments);

      const fa: FormArray = this.limitAccessToFormArray;
      fa.clear();

      roles.forEach(d => {
        fa.push(this.formBuilder.group({
          id: [d.id],
          name: [d.value],
          isSelected: [this.model.roleIds.includes(d.id)]
        }));
      });
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate(['/setting/accessgroup/list']);
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.id = this.model.id;
    this.model.name = this.form.value.name;
    this.model.description = this.form.value.description;
    this.model.isRequiredValidation = this.form.value.isRequireValidation;
    this.model.isRefreshLastUpdate = this.form.value.isRefereshLastUpdate;
    this.model.isIncludeInDocuments =  this.form.value.isIncludeInDocuments;
    this.model.roleIds = this.form.value.limitAccessTo.filter(x => x.isSelected).map(x => x.id);
    this.blockUI.start();
    this.accessGroupService.put(this.model).subscribe(resposne => {
      setTimeout(() => {
        this.router.navigate(['/setting/accessgroup/list']);
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Acess group updated successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );

  }
  private getSelectedRoles(): string[] {
    return this.form.value.limitAccessTo
      .map((v, i) => v ? this.roles[i].id : null)
      .filter(v => v !== null);
  }

}
