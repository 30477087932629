<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-auto d-none d-sm-block">
                            <div class="d-flex mb-2">
                                <h3 class="page-title"><strong>{{categoryName}} Notes &nbsp;</strong> </h3>
                            </div>
                            <p>Get all the {{categoryName}} Notes info at one place</p>
                        </div>

                        <div class="col-auto ml-auto text-right mt-n1">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                    <li class="filter-item">
                                        <button class="btn btn-lg bg-blue text-white">
                                            <i class="align-middle fas fa-external-link-alt"></i> Export Data
                                        </button>
                                    </li>
                                    <li class="filter-item px-0 filter-icon" (click)="filterNavStatus = true">
                                        <button class="btn btn-lg bg-blue text-white">
                                            <i class="align-middle fas fa-filter"></i>
                                        </button>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <!-- Filter right sidenav -->
                        <app-notes-filter-and-search (onApplyFilter)="applyFilter($event)" (resetFilters)="onResetFilters()"
                            (closeFilterNav)="isShowFilterAndSearch = !isShowFilterAndSearch" [filterStatusParent]="filterNavStatus"  (closeBtnFilterStatus)="closeFilterEvent($event)">
                        </app-notes-filter-and-search>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="case-table table-responsive">
                            <div>
                                <table class="neu-table table table-striped neu-table w-100" datatable [dtOptions]="dtOptions" data-page="2">
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>