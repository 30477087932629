import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AddCompanyPolicyModel } from '../models/company-policy/addCompanyPolicyModel';
import { CompanyPolicyViewModel } from '../models/company-policy/companyPolicyViewModel';
import { EditCompanyPolicyModel } from '../models/company-policy/editCompanyPolicyModel';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyPolicyService {
  constructor(private http: HttpClient) { }

  public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
    return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/companypolicy/query`, dataTablesParameters, {});
  }

  public post(model: AddCompanyPolicyModel): Observable<string> {
    const formData = new FormData();
    formData.append('id', JSON.stringify(model.id));
    formData.append('name', model.name);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < model.roleIds.length; i++) {
      formData.append('roleIds[]', model.roleIds[i]);
    }
    formData.append('description', model.description);
    return this.http.post<string>(`${environment.apiBaseUrl}/companypolicy/post`, formData, {});
  }

  public get(id: number): Observable<CompanyPolicyViewModel> {
    return this.http.get<CompanyPolicyViewModel>(`${environment.apiBaseUrl}/companypolicy/${id}`);
  }

  public getAll(): Observable<CompanyPolicyViewModel[]> {
    return this.http.get<CompanyPolicyViewModel[]>(`${environment.apiBaseUrl}/companypolicy/get`);
  }

  public put(model: EditCompanyPolicyModel): Observable<CompanyPolicyViewModel> {
    const formData = new FormData();
    formData.append('id', JSON.stringify(model.id));
    formData.append('name', model.name);
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < model.roleIds.length; i++) {
      formData.append('roleIds[]', model.roleIds[i]);
    }
    formData.append('description', model.description);
    return this.http.put<CompanyPolicyViewModel>(`${environment.apiBaseUrl}/companypolicy/put`, formData, {});
  }
  public delete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/companypolicy/delete?ids=${ids.join('&ids=')}`);
  }
  public undelete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/companypolicy/undeleted?ids=${ids.join('&ids=')}`);
  }
}
