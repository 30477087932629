import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AddOrEditFolderTemplateModel } from "../models/folder-template/add.folder-template.model";
import { FolderTemplateModel } from "../models/folder-template/folder-template.model";
import { DataTableResponseModel } from "../models/shared/datatable.response.model";

@Injectable({
    providedIn: 'root'
})
export class FolderTemplateService {
    constructor(private http: HttpClient) {
    }
    public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/folder-template/query`, dataTablesParameters, {});
    }
    public getAll(): Observable<AddOrEditFolderTemplateModel[]>{
        return this.http.get<AddOrEditFolderTemplateModel[]>(`${environment.apiBaseUrl}/folder-template/get`);

    }
    public post(model: AddOrEditFolderTemplateModel): Observable<FolderTemplateModel> {
        return this.http.post<FolderTemplateModel>(`${environment.apiBaseUrl}/folder-template`, model, {});
    }

    public get(id: number): Observable<AddOrEditFolderTemplateModel> {
        return this.http.get<AddOrEditFolderTemplateModel>(`${environment.apiBaseUrl}/folder-template/${id}`);
    }

    public put(model: AddOrEditFolderTemplateModel): Observable<FolderTemplateModel> {
        return this.http.put<FolderTemplateModel>(`${environment.apiBaseUrl}/folder-template`, model, {});
    }
    public deleteFolderTemplate(ids: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/folder-template?ids=${ids.join('&ids=')}`);
    }
    public undeleteFolderTemplate(ids: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/folder-template/undelete?ids=${ids.join('&ids=')}`);
    }
}
