<main class="content py-0">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-md-12 pt-7">
          <div class="w-100 pt-4">
            <div class="row">
              <div class="col-md-12 px-0 pb-5">
                <div class="col-auto mt-n1">
                  <app-breadcrumb></app-breadcrumb>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto d-none d-sm-block mb-3">
                <h3 class="page-title">
                  <strong>Addons</strong>
                </h3>
              </div>
            </div>

          
  
           
              <div class="row">
                <div class="col-md-12">
                  <table class="neu-table table table-striped neu-table w-100" datatable [dtOptions]="dtOptions" data-page="2" ></table>
                </div>
              </div>

              
  
             
              </div>
           
          </div>
        </div>
      </div>
  </main>
  