import {Component, OnInit} from '@angular/core';
@Component({
    selector: 'app-investigator-snapshot',
    templateUrl: './investigator-snapshot.component.html',
    styleUrls: ['./investigator-snapshot.component.css']
})

export class InvestigatorSnapshotComponent{
    constructor(){

    }
}

