import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { LocationDetailModel } from 'src/app/models/Location/location.detail.model';
import { LocationService } from 'src/app/services/location.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-client-location-overview',
  templateUrl: './client-location-overview.component.html',
  styleUrls: ['./client-location-overview.component.css']
})
export class ClientLocationOverviewComponent implements OnInit, OnDestroy {

  @BlockUI() blockUI: NgBlockUI;
  navigationSubscription: Subscription;
  model: LocationDetailModel;
  //quick links sample data
  quicklink = [
    { mainmenu: 'All cases', mainlink: '#', submenu: [
      { name: 'Active cases', link: '#' }
    ]},
    { mainmenu: 'All Invoices', mainlink: '#', submenu: [
      { name: 'Unpaid invoices', link: '#' },
      { name: 'Payment history', link: '#' },
      { name: 'Retainers', link: '#' }
    ]},
  ];

  constructor(private route: ActivatedRoute,
              private locationService: LocationService,
              private toasterService: ToasterService,
              private router: Router) {
    this.model = new LocationDetailModel();
    this.route.parent.params.subscribe(params => this.model.id = params.locationId);
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.loadLocation();
      }
    });
  }

  ngOnInit(): void {
  }

  private loadLocation(): void {
    this.blockUI.start();
    this.locationService.get(this.model.id).subscribe((data: any) => {
      this.model = data;
      this.locationService.locationDetailSubject$.next({
        locationId: this.model.id, locationName: this.model.name, clientName: this.model.clientName, clientId: this.model.clientId
      });
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate([''], { relativeTo: this.route.parent });
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }
}
