export const ClientBreadCrumb = {
    CLIENT: 'Clients',
    NEW_CLIENT: 'New Client',
    EDIT_CLIENT: 'Edit Client',
    CLIENT_DETAILS: 'Client Details',
    CLIENT_OVERVIEW: 'Overview',
    CLIENT_LOCATIONS: 'Offices',
    CLIENT_CONTACTS: 'Contacts',
    CLIENT_PRICE_LIST: 'Price List',
    CLIENT_AFFILIATES: 'Affiliates',
    CLIENT_PREFERRED_INVESTIGATOR: 'Preferred Investigators',
    CLIENT_INVOICE_RULES: 'Invoice Rules',
    CLIENT_NOTES: 'Client Notes',
    CLIENT_HISTORY: 'History',

    // Client Location
    NEW_LOCATION: 'New Office',
    EDIT_LOCATION: 'Edit Office',
    LOCATION_DETAILS: 'Client Office Details',
    LOCATION_OVERVIEW: 'Client Office Overview',
    LOCATION_NOTES: 'Client Office Notes',
    LOCATION_HISTORY: 'Client Office History',
    LOCATION_CONTACTS: 'Client Office Contacts',

    // Client Contacts
    CONTACT_DETAILS: 'Contact Details',
    CONTACT_OVERVIEW: 'Overview',
    CONTACT_PERMISSION: 'Permission',
    CONTACT_NOTIFICATION: 'Notification',
    CONTACT_NOTES: 'Contact Notes',
    CONTACT_HISTORY: 'History',

    // Client Notes
    NEW_NOTE: 'New Note',
    EDIT_NOTE: 'Edit Note',

    // Client Contact
    NEW_CONTACT: 'New Contact',
    EDIT_CONTACT: 'Edit Contact',
} as const;