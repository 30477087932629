import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormArray, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { CustomFieldCaseCategories } from 'src/app/helpers/enums/settings/customField/CustomFieldCaseCategories';
import { CustomFieldModel } from 'src/app/models/custom-fields/CustomFieldModel';
import { AddSubjectTypeModel } from 'src/app/models/subjectTypes/addSubjectTypeModel';
import { EditSubjectTypeModel } from 'src/app/models/subjectTypes/editSubjectTypeModel';
import { SubjectTypeViewModel } from 'src/app/models/subjectTypes/subjectTypeViewModel';
import { CasetypeService } from 'src/app/services/casetype.service';
import { CustomfieldService } from 'src/app/services/customfield.service';
import { ModalService } from 'src/app/services/modal.service';
import { SubjectTypeService } from 'src/app/services/subjecttype.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';

@Component({
  selector: 'app-edit-case-subject',
  templateUrl: './edit-case-subject.component.html',
  styleUrls: ['./edit-case-subject.component.css']
})
export class EditCaseSubjectComponent implements OnInit {

  @BlockUI('edit-case-subject') blockUI: NgBlockUI;
  form: FormGroup;
  submitted = false;
  viewModel: SubjectTypeViewModel;
  customSubject: CustomFieldModel[] = [];

  model: EditSubjectTypeModel;
  get caseTypesToFormArray(): FormArray {
    return this.form.controls.case_types as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
    private caseTypeService: CasetypeService,
    private toasterService: ToasterService,
    private subjectTypeService: SubjectTypeService,
    private router: Router,
    private route: ActivatedRoute,
    private customFieldService: CustomfieldService,
    private modalService: ModalService
  ) {
    this.viewModel = new SubjectTypeViewModel();
    this.model = new AddSubjectTypeModel();
    this.route.params.subscribe(params => this.model.id = Number(params.id));
  }
  loadSubjectCustomFields(): void{
    this.customFieldService.getByUserForId(CustomFieldCaseCategories.Subject).subscribe((data) => {
      if (data && data.length !== this.customSubject.length){
      data.forEach((element, index) => {
        if (this.customSubject.filter(x => x.id === element.id).length === 0){
          this.customSubject.splice(index, 0 , element);
        }
      });
      }
    }, (error: any) => {
       this.toasterService.ProcessErrorResponse(error);
    });
  }
 // dynamic rows
  // new field row
  get subjectTypeSubjectCustomFieldformArray(): FormArray {
    return this.form.get('customSubjectFields') as FormArray;
  }
  addNewRows(): void {
    this.subjectTypeSubjectCustomFieldformArray.push(this.initRows());
  }

  deleteRow(index: number): void {
    this.subjectTypeSubjectCustomFieldformArray.removeAt(index);
  }
  initRows() {
    const fg =  this.formBuilder.group({
      id: 0,
      customSubjectId: [this.customSubject.length > 0 ? this.customSubject[0].id : '', [RxwebValidators.unique(), Validators.required]],
      highlightCheck: [false, Validators.required],
      crossCheck: [false, Validators.required],
      searchCheck: [false, Validators.required],
      reopenCheck: [true, Validators.required],
      isInternal: this.customSubject.length > 0 ? this.customSubject[0].internal : false
    });
    return fg;

  }
  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl(),
      case_types: new FormArray([], minSelectedCheckboxes()),
      customSubjectFields: this.formBuilder.array([]),
    });
    this.loadDefaultData();
  }
  private async loadDefaultData(): Promise<void> {
    this.blockUI.start();
    forkJoin([this.caseTypeService.getSelectListItem(), this.subjectTypeService.get(this.model.id),
      this.customFieldService.getByUserForId(CustomFieldCaseCategories.Subject)
    ]).subscribe(
      ([caseTypesdata, subjectTypeData, customFieldSubjectAll ]) => {
        this.customSubject = customFieldSubjectAll;
        // loading case types
        const ct: FormArray = this.caseTypesToFormArray;
        ct.clear();
        caseTypesdata.forEach((d) => {
          ct.push(
            this.formBuilder.group({
              id: [d.id],
              name: [d.value],
              isSelected: [subjectTypeData.caseTypeIds.includes(d.id)],
            })
          );
        });
        // load case type end
        // subjectcustomcasefield start
        const ctcc: FormArray = this.subjectTypeSubjectCustomFieldformArray;
        ctcc.clear();
        subjectTypeData.subjectTypeCustomFields.forEach((d) => {
           ctcc.push(
             this.formBuilder.group({
              id: d.id,
              customSubjectId: [Number(d.customFieldId), [RxwebValidators.unique(), Validators.required]],
              highlightCheck: d.isHighlighted,
              crossCheck: d.isCrossReference,
              searchCheck: d.isSearchable,
              reopenCheck: d.isReopenable,
              isInternal: this.customSubject.filter(x => x.id == d.customFieldId)[0].internal
             })
           );
         });
       // subjectcustomcasefield end
        this.form.controls.name.setValue(subjectTypeData.name);
        this.form.controls.description.setValue(subjectTypeData.description);
        this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(
          error,
          'Something went wrong!'
        );
        this.blockUI.stop();
      }
    );
  }
  OnCustomFieldChange(event): void{
    const customsubjectValue = this.customSubject.filter(x => x.id == event.target.value)[0];
    const control = this.form.controls.customSubjectFields.value.map(x => x.customSubjectId).indexOf(event.target.value);
    this.subjectTypeSubjectCustomFieldformArray.at(control).get('isInternal').setValue(customsubjectValue.internal);
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.subjectTypeCustomFields = [];
    const controls = this.form.controls;
    this.model.name = controls.name.value;
    this.model.description = controls.description.value;
    this.model.caseTypeIds = this.form.value.case_types
      .filter((x) => x.isSelected)
      .map((x) => x.id);
    controls.customSubjectFields.value.map((x, index) => {
        this.model.subjectTypeCustomFields.push({
          id: x.id,
          subjectTypeId: Number(this.model.id),
          customFieldId: Number(x.customSubjectId),
          isReopenable: x.reopenCheck,
          isHighlighted: x.highlightCheck,
          isCrossReference: x.crossCheck,
          isSearchable: x.searchCheck,
          sequence: index + 1
        });
      });
    this.blockUI.start();
    this.subjectTypeService.put(this.model).subscribe(
      (resposne) => {
        setTimeout(() => {
          this.onCancel();
        }, 10);
        setTimeout(() => {
          this.toasterService.success('Subject type updated successfully');
          this.onCancel();
        }, 300);
        this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }

  onCancel(): void {
    this.router.navigate(['/setting/cases/subject-type-list']);
  }
  openCustomFieldPopup(): void{
    this.modalService.openNewCustomFieldPopup(CustomFieldCaseCategories.Subject).subscribe((data: any) => {
    if (data){
         this.loadSubjectCustomFields();
      }
    }, (error: any) => {
      this.toasterService.ProcessErrorResponse(error);
    });
   }

   onDrop(event: CdkDragDrop<string[]>): void {
    const prevValue  = this.subjectTypeSubjectCustomFieldformArray.at(event.previousIndex);
    this.deleteRow(event.previousIndex);
    this.subjectTypeSubjectCustomFieldformArray.insert(event.currentIndex, prevValue);
  }
  addRowAtIndex(index: number): void {
    this.subjectTypeSubjectCustomFieldformArray.insert(index + 1, this.initRows());
  }

}
