import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormBuilder,
  FormControl,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { EditReferralSourceModel } from 'src/app/models/referral-source/editReferralSourceModel';
import { ReferralSourceViewModel } from 'src/app/models/referral-source/referralSourceViewModel';
import { ReferralsourceService } from 'src/app/services/referralsource.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-edit-referral-source',
  templateUrl: './edit-referral-source.component.html',
  styleUrls: ['./edit-referral-source.component.scss'],
})
export class EditReferralSourceComponent implements OnInit {
  @BlockUI('container-blockui-new-folder-template') blockUI: NgBlockUI;
  form: FormGroup;
  submitted = false;
  model: EditReferralSourceModel;

  constructor(
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private referralSourceService: ReferralsourceService
  ) {
    this.model = new EditReferralSourceModel();
    this.route.params.subscribe(
      (params) => (this.model.id = Number(params.id))
    );
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(),
    });
    this.loadDefaultData();
  }
  loadDefaultData(): void {
    this.referralSourceService.get(this.model.id).subscribe(
      (data: ReferralSourceViewModel) => {
        this.form.controls.name.setValue(data.name);
        this.form.controls.description.setValue(data.description);
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
      }
    );
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.name = this.form.controls.name.value;
    this.model.description = this.form.controls.description.value;

    this.referralSourceService.put(this.model).subscribe(
      (resposne) => {
        setTimeout(() => {
          this.onCancel();
        }, 10);
        setTimeout(() => {
          this.toasterService.success('Referral source updated successfully');
          this.onCancel();
        }, 300);
        this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }
  onCancel(): void {
    this.router.navigate(['/setting/cases/referral-sources-list']);
  }
}
