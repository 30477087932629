import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppUtils } from 'src/app/helpers';
import { CustomFieldCaseCategoriesLabel } from 'src/app/helpers/constants/CustomFields/CustomFieldCaseCategoriesLabel';
import { CustomFieldRedactMethodLabel } from 'src/app/helpers/constants/CustomFields/CustomFieldRedactMethodLabel';
import { CustomFieldTypesLabel } from 'src/app/helpers/constants/CustomFields/CustomFieldTypesLabel';
import { CustomFieldCaseCategories } from 'src/app/helpers/enums/settings/customField/CustomFieldCaseCategories';
import { CustomFieldTypes } from 'src/app/helpers/enums/settings/customField/CustomFieldTypes';
import { CustomFieldAddModel } from 'src/app/models/custom-fields/CustomFieldAddModel';
import { CountryModel } from 'src/app/models/shared/country.model';
import { CountryService } from 'src/app/services/country.service';
import { CustomfieldService } from 'src/app/services/customfield.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-new-custom-field-shared',
  templateUrl: './new-custom-field-shared.component.html',
  styleUrls: ['./new-custom-field-shared.component.css']
})
export class NewCustomFieldSharedComponent implements OnInit {

  @BlockUI('container-blockui-new-shared-custom-field') blockUI: NgBlockUI;
  @Input() public isForPopup: boolean;
  @Input() breadCrumbInputs: { key: string, value: string[] }[];
  @Input() isForEnabled: boolean;
  @Input() isForValue: number;
  @Output() public saveClick = new EventEmitter();
  loadBreadCrumb = false;
  customFieldCaseCategoriesLabel: { id: number, name: string }[] = [];
  customFieldCaseTypesLabel: { id: number, name: string }[] = [];
  customFieldRedactMethodLabel: { id: number, name: string }[] = [];
  countries: CountryModel[] = [];
  form: FormGroup;
  submitted = false;
  isSelected = false;
  selectedValue: any;
  customFieldAddModel: CustomFieldAddModel;

  constructor(private formBuilder: FormBuilder, public appUtil: AppUtils,
              private ngbActiveModal: NgbActiveModal,
              private toasterService: ToasterService, private countryService: CountryService,
              private customFieldService: CustomfieldService, private router: Router) {

  }

  loadDefaultData(): void {
    for (const [key, value] of CustomFieldCaseCategoriesLabel) {
      this.customFieldCaseCategoriesLabel.push({ id: key, name: value });
    }
    for (const [key, value] of CustomFieldRedactMethodLabel) {
      this.customFieldRedactMethodLabel.push({ id: key, name: value });
    }
    for (const [key, value] of CustomFieldTypesLabel) {
      this.customFieldCaseTypesLabel.push({ id: key, name: value });
    }
    this.countryService.getCountries()
      .subscribe((data: CountryModel[]) => {
        if (!data || data.length === 0) {
          return;
        }
        this.countries = data;
      });
  }
  // tslint:disable-next-line: typedef
  get GetCustomFieldCaseType() {
    return CustomFieldTypes;
  }

  // tslint:disable-next-line: typedef
  get GetCustomFieldCaseCategories() {
    return CustomFieldCaseCategories;
  }

  ngOnInit(): void {
    this.loadDefaultData();
    this.form = new FormGroup({
      label: new FormControl('', Validators.required),
      help_text: new FormControl(),
      use_for: new FormControl('', Validators.required),
      field_type: new FormControl('', Validators.required),
      require_or_not: new FormControl('', Validators.required),
      internal: new FormControl('', Validators.required),
      customSwitch0: new FormControl(),
      customSwitch1: new FormControl(),
      customSwitch2: new FormControl(),

      dform_Country: new FormControl(),
      text_field: new FormControl(),
      redact_Yesno: new FormControl(),
      dform_Longinput: new FormControl(),
      dform_Shortinput: new FormControl(),
      dform_Money: new FormControl(),
      dform_Number: new FormControl(),
      dform_YesnoPart2: new FormControl(),
      dform_Yesnona: new FormControl(),
      dform_RatingYesno: new FormControl(),

      redactMethod: new FormControl(),

      multipleChoiceDropdown: this.formBuilder.array([]),
      multipleChoiceRadio: this.formBuilder.array([]),
      ratingMultipleChoiceDrop: this.formBuilder.array([])
      
    });


    // this.form.controls.dform_YesnoPart2.setValue('false', { onlySelf: true });

    this.form.controls.redact_Yesno.setValue('false', { onlySelf: true });

    this.form.controls.redact_Yesno.setValue('false', { onlySelf: true });
    this.form.controls.require_or_not.setValue('false', { onlySelf: true });
    this.form.controls.internal.setValue('false', { onlySelf: true });

    this.form.controls.redactMethod.setValue('1', { onlySelf: true });
    this.form.controls.dform_Country.setValue('1', { onlySelf: true });


    this.form.controls.dform_Yesnona.setValue('na', { onlySelf: true });
    this.form.controls.dform_RatingYesno.setValue('na', { onlySelf: true });
    if (this.isForEnabled && this.isForValue){
         this.form.controls.use_for.setValue(this.isForValue);
    }
  }

  // for dynamic text field

  multipleChoiceDropdown(): FormArray {
    return this.form.get('multipleChoiceDropdown') as FormArray;
  }


  newEmployee(): FormGroup {
    return this.formBuilder.group({
      default_value_forMultiple: '',
      dform_multiplechoices: '',
    });
  }

  addEmployee(): void {
    const arr = this.multipleChoiceDropdown();
    const length = arr.value.length;
    if (length > 0 && arr.value.some(x => !x.dform_multiplechoices)) {
      this.toasterService.error('value cannot be empty!');
      return;
    }
    arr.push(this.newEmployee());
  }

  removeAll(index: number): void {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.form.controls.multipleChoiceDropdown.value.length; i++) {
      this.form.controls.multipleChoiceDropdown.value[i].default_value_forMultiple = 'false';
    }
    this.form.controls.multipleChoiceDropdown.value[index].default_value_forMultiple = 'true';
  }

  removeEmployee(empIndex: number): void {
    this.multipleChoiceDropdown().removeAt(empIndex);
  }

  // dynamic radio buttons
  multipleChoiceRadio(): FormArray {
    return this.form.get('multipleChoiceRadio') as FormArray;
  }
  addMultipleChoiceRadio(): void {
    const arr = this.multipleChoiceRadio();
    const length = arr.value.length;
    if (length > 0 && arr.value.some(x => !x.dform_multipleChoiceRadio)) {
      this.toasterService.error('value cannot be empty!');
      return;
    }
    arr.push(this.newEmployee2());
  }

  newEmployee2(): FormGroup {
    return this.formBuilder.group({
      default_value_forMultipleRadio: '',
      dform_multipleChoiceRadio: '',
    });
  }

  removeEmployee2(empIndex: number): void {
    this.multipleChoiceRadio().removeAt(empIndex);
  }
  removeAll2(index: number): void {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.form.controls.multipleChoiceRadio.value.length; i++) {
      this.form.controls.multipleChoiceRadio.value[i].default_value_forMultiple = 'false';
    }
    this.form.controls.multipleChoiceRadio.value[index].default_value_forMultiple = 'true';
  }

  // dynamic rating dropdown
  ratingMultipleChoiceDrop(): FormArray {
    return this.form.get('ratingMultipleChoiceDrop') as FormArray;
  }
  addRatingDrop(): void {

    const arr = this.ratingMultipleChoiceDrop();
    const length = arr.value.length;
    if (length > 0 && arr.value.some(x => !x.dform_ratingMultiDrop || !x.dform_ratingMultiDrop_scale)) {
      this.toasterService.error('value cannot be empty!');
      return;
    }
    arr.push(this.ratingDropForm());
  }

  ratingDropForm(): FormGroup {
    return this.formBuilder.group({
      default_value_forRatingDrop: 'false',
      dform_ratingMultiDrop: '1',
      dform_ratingMultiDrop_scale: '100'
    });
  }

  removeRatingDrop(empIndex: number): void {
    this.ratingMultipleChoiceDrop().removeAt(empIndex);
  }
  removeallRatingDrop(index: number): void {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.form.controls.ratingMultipleChoiceDrop.value.length; i++) {
      this.form.controls.ratingMultipleChoiceDrop.value[i].default_value_forRatingDrop = 'false';
    }
    this.form.controls.ratingMultipleChoiceDrop.value[index].default_value_forRatingDrop = 'true';
  }

  onCancel(): void {
      if (this.isForPopup) {
        this.saveClick.emit(this.customFieldAddModel);
        this.ngbActiveModal.dismiss('save click');
      }
      else {
        this.router.navigate(['/setting/global/custom-field-list']);
      }
  }


  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid.
    if (this.form.invalid) {
      this.toasterService.error('Please fill all required fields');
      return;
    }
    this.blockUI.start();
    const controls = this.form.controls;
    this.customFieldAddModel = new CustomFieldAddModel();

    const jsonValue = this.customFieldService.getDefaultValue(controls.field_type.value, this.form);
    if (!jsonValue.isValid) {
      this.toasterService.error('Value cannot be empty!');
      return;
    }
    const internal = Number(controls.use_for.value) === CustomFieldCaseCategories.Reviews ||
      Number(controls.use_for.value) === CustomFieldCaseCategories.Staff ? null :
      (controls.internal.value == 'true' ? true : false);
    const redactResponse = this.customFieldService.getRedactValue(controls.field_type.value, this.form);
    this.customFieldAddModel.id = 0;
    this.customFieldAddModel.label = controls.label.value;
    this.customFieldAddModel.helpText = controls.help_text.value;
    this.customFieldAddModel.useForId = Number(controls.use_for.value);
    this.customFieldAddModel.fieldTypeId = Number(controls.field_type.value);
    this.customFieldAddModel.required = controls.require_or_not.value == 'true' ? true : false;

    this.customFieldAddModel.internal = internal;
    this.customFieldAddModel.defaultJsonValue = jsonValue.value;
    this.customFieldAddModel.redact = redactResponse.redact;
    this.customFieldAddModel.redactMethod = redactResponse.method;
    this.customFieldService.post(this.customFieldAddModel).subscribe(response => {
      setTimeout(() => {
        this.toasterService.success('Custom field created successfully');
        this.customFieldAddModel = response;
        this.onCancel();
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }

}
