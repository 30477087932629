export class CaseTypeModel {
   public id: number;
   public name: string;
   public description: string;
   public tag: string;
   public isBugetTracking?: boolean;
   public isPublicCaseRequest: boolean;
   public isNotesInstructions: boolean;
   public isAdminNotes: boolean;
   public isCaseServices: boolean;
}
