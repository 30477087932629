export enum Permission {
    AssignedToCasesAsManager = 1,
    AssignedToCasesAsInvestigator = 2,
    AssignedToCasesClientsInvoicesAsSalesPerson = 3,
    AssignedToEvents = 4,
    AssignedToStaffMembersAsManager = 5,
    CreateTasksAndEvents = 6,
    DeleteTasksAndEvents = 7,
    EditTasksAndEvents = 8,
    ModifyTasksOrEventsStatus = 9,
    ViewTasksAndEvents = 10,
    AssignOrUnassignUsersToCases = 11,
    ViewCaseAccessAndAssignments = 12,
    CreateOrReopenCases = 13,
    DeleteCases = 14,
    EditCases = 15,
    ModifyBudgetOnCases = 16,
    ModifyCaseCreationDate = 17,
    ModifyDueDateOnCases = 18,
    ModifyFlagsOnCases = 19,
    ModifyRegionAndLocationOnCases = 20,
    ModifyStatusOfCases = 21,
    ViewNonActiveCases = 22,
    ViewAdministrativeNotesInCase = 23,
    ViewBudgetOnCases = 24,
    ViewCases = 25,
    ViewClientOrcontactInfoOnCases = 26,
    ViewInvestigatorInfoOnCases = 27,
    ViewManagerInfoOnCases = 28,
    ViewRelatedCases = 29,
    ApproveOrDeclineCaseRequests = 30,
    CreateCaseRequests = 31,
    DeleteCaseRequests = 32,
    EditCaseRequests = 33,
    ViewCaseRequests = 34,
    CreateCaseUpdates = 35,
    DeleteCaseUpdates = 36,
    DownloadOrWatchUpdateAttachments = 37,
    EditCaseUpdates = 38,
    ModifyCaseUpdateAccessGroup = 39,
    SendCaseUpdates = 40,
    ValidateOrInvalidateCaseUpdates = 41,
    ViewCaseUpdates = 42,
    ViewInvalidCaseUpdates = 43,
    AssignOrUnassignClientAffiliates = 44,
    AssignOrUnassignSalespeople = 45,
    CreateClients = 46,
    DeleteClients = 47,
    EditClients = 48,
    ViewClientAffiliates = 49,
    ViewClients = 50,
    EditClientPriceLists = 51,
    ViewClientPriceList = 52,
    CreateClientContacts = 53,
    DeleteClientContacts = 54,
    EditClientContacts = 55,
    ViewClientContacts = 56,
    CustomizeDashboard = 57,
    ViewDashboard = 58,
    DeleteDataSearches = 59,
    RunDataSearches = 60,
    ViewDataSearches = 61,
    GenerateDocumentsFromCases = 62,
    ApproveOrDeclineExpenseEntries = 63,
    CreateAndSubmitExpenses = 64,
    CreateAndSubmitExpensesForOtherStaff = 65,
    CreateEditAndDeleteExpenseSlipPayments = 66,
    CreatePayVoidAndDeletePaymentSlips = 67,
    DeleteTimeAndExpenses = 68,
    EditTimeAndExpenses = 69,

    ViewExpensePaymentSlips = 70,

    ViewExpenseRates = 71,

    ViewTimeAndExpenses = 72,

    DeleteFilesAndFolders = 73,

    EditFilesAndFolders = 74,

    UploadFilesAndCreateFolders = 75,

    ViewFilesAndFolders = 76,

    EditMyProfile = 77,

    ExportData = 78,

    ViewHistory = 79,

    ViewMyProfile = 80,

    ViewRedactedFieldData = 81,

    CreateInvoicesAndRetainers = 82,

    DeleteInvoicesAndRetainers = 83,

    EditInvoicesAndRetainers = 84,

    ManagePaymentsForInvoicesAndRetainers = 85,

    SendInvoicesAndRelatedInvoiceNotices = 86,

    ViewInvoicesAndRetainers = 87,

    CreateStaffLicenses = 88,

    DeleteStaffLicenses = 89,

    EditStaffLicenses = 90,

    ViewStaffLicenses = 91,

    ViewNotifications = 92,

    ViewReports = 93,

    CreateAandAnswerCaseReviews = 94,

    DeleteCaseReviews = 95,

    ModifyCaseReviewStatus = 96,

    ViewCaseReviews = 97,

    ViewSchedule = 98,

    ViewAdvancedSearch = 99,

    EditSystemSettings = 100,

    ViewSettings = 101,

    CreateStaffMembers = 102,

    DeleteStaffMembers = 103,

    EditStaffMembers = 104,

    ViewStaffMembers = 105,

    CreateCaseSubjects = 106,

    DeleteCaseSubjects = 107,

    EditCaseSubjects = 108,

    ViewCaseSubjects = 109,

    EditUserLoginAccess = 110,

    LoginAsLowerRankedUsers = 111,

    ViewAdministrativeNotes = 112,

    ViewPermissions = 113,

    EditStaffPriceLists = 114,

    ViewStaffPriceList = 115
}