import {Component, OnInit} from '@angular/core';
@Component({
    selector: 'app-new-vendor',
    templateUrl: './new-vendor.component.html',
    styleUrls: ['./new-vendor.component.css']
})

export class NewVendorComponent{
    constructor(){

    }
}

