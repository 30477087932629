<main class="content py-0">

    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12 px-0 pb-5">
                            <div class="col-auto mt-n1">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb bg-transparent custom-breadcrumb p-0 mt-1 mb-0">
                                        <li class="breadcrumb-item"><a routerLink="/case-list">Cases</a></li>
                                        <!-- <li class="breadcrumb-item"><a href="case_list_detail.html">Case List</a></li> -->
                                        <li class="breadcrumb-item active" aria-current="page">New Case</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="row new-case-form">
                        <div class="col-auto d-none d-sm-block mb-3">
                            <h3 class="page-title"><strong>New Case</strong> </h3>
                            <p>Create new case detail here.</p>
                        </div>

                        <legend>
                            <h3 class="text-orange title-legend">Case Summary</h3>
                        </legend>
                        
                    </div>
                    
                    <div class="row savebtn mt-3">
                        <div class="col-md-10"></div>
                        <div class="col-md-2  ">
                            <div class="row saveclassbutton float-right">
                                <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2 open4"
                                    (click)="showmessage()"> Save Case and Finish!&nbsp; </button>
                            </div>
                        </div>
                    </div>
                   


                    <div class="row">
                        <div class="col-md-9">

                            <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-2">
                                <ngb-panel id="custom-panel-2">
                                  <ng-template ngbPanelHeader let-opened="opened">
                                    <div class="d-flex align-items-center justify-content-between">
                                      <h5 class="m-0 font-weight-bold">Case Details <button type="button" class="btn btn-transparent shadow-none border-0" (click)="btnCaseRedirectionClick()"><i class="fa fas fa-pencil-alt"></i></button></h5>
                                      <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                                          class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                    </div>
                                  </ng-template>
                                  <ng-template ngbPanelContent>
                                    <div class="row">
                                        <div class="col-md-6">
                                            <div  class="row mb-4">
                                                <div class="col-md-4 da-font-bold font-weight-bold" >Due Date</div>
                                                <div class="col-md-8"> {{caseSummaryData.dueDate ? (caseSummaryData.dueDate | date) : ''}} 
                                                </div>
                                            </div>
                                            <div  class="row mb-4">
                                                <div class="col-md-4 da-font-bold font-weight-bold">Case Flags</div>
                                                <div class="col-md-8 case-width" *ngIf="caseSummaryData.flags && caseSummaryData.flags.length > 0"> 
                                                    <span  [style.backgroundColor]='cf.colourId' class='link m-r-10 case-flag-label ml-2 mt-1' [data-pos]='this.appUtil.lightOrDark(cf.colourId)' *ngFor="let cf of caseSummaryData.flags" >
                                                    {{cf.value}}
                                                    </span>
                                                </div>
                                            </div>
                                            <div  class="row mb-4">
                                                <div class="col-md-4 da-font-bold font-weight-bold">Case Type</div>
                                                <div class="col-md-8"> {{caseSummaryData.caseType}} 
                                                </div>
                                            </div>

                                            <div  class="row mb-4">
                                                <div class="col-md-4 da-font-bold font-weight-bold">Case Services</div>
                                                <div class="col-md-8" *ngIf="caseSummaryData.caseServicesInCases && caseSummaryData.caseServicesInCases.length > 0"> 
                                                    <!-- <span  *ngFor="let cf of caseSummaryData.caseServicesInCases" >
                                                        {{cf.value }}
                                                    </span> -->
                                                    <span>{{getFormattedName(caseSummaryData.caseServicesInCases)}}</span>
                                                </div>
                                            </div>
                                            <div  class="row mb-4">
                                                <div class="col-md-4 da-font-bold font-weight-bold">Case Region</div>
                                                <div class="col-md-8"> {{caseSummaryData.caseRegion}} 
                                                </div>
                                            </div>

                                            <div  class="row mb-4">
                                                <div class="col-md-4 da-font-bold font-weight-bold">Case Number</div>
                                                <div class="col-md-8">{{caseSummaryData.caseNumber}}
                                                </div>
                                            </div>
                                            <div  class="row mb-4" *ngFor="let rl of caseSummaryData.referenceLabels">
                                                <div class="col-md-4 da-font-bold font-weight-bold">{{rl.id}}</div>
                                                <div class="col-md-8">{{displayreferencevalue(rl.value)}}
                                                </div>
                                            </div>
                                            <div  class="row mb-4">
                                                <div class="col-md-4 da-font-bold font-weight-bold">Budget</div>
                                                <div class="col-md-8">${{converttodecimal(caseSummaryData.budget)}} / {{converttodecimal(caseSummaryData.hours)}} Hrs
                                                </div>
                                            </div>
                                            <hr>
                                            <div  class="row mb-4" *ngFor="let customField of caseSummaryData.customFields">
                                                <div class="col-md-4 da-font-bold font-weight-bold">{{customField.label}}</div>
                                                <div class="col-md-8"> {{displayCustomField(customField)}}
                                                </div>
                                            </div>
                            
                                                
                                        </div>

                                        <div class="col-md-6">
                                            <div  class="row mb-4">
                                                <div class="col-md-4 da-font-bold font-weight-bold">Admin Notes</div>
                                                <div class="col-md-8"> {{caseSummaryData.adminNotes}}
                                                </div>
                                            </div>
                                            <div  class="row mb-4">
                                                <div class="col-md-4 da-font-bold font-weight-bold">Scheduling Notes</div>
                                                <div class="col-md-8">{{caseSummaryData.schedulingNotes}}
                                                </div>
                                            </div>
                                            <div  class="row mb-4">
                                                <div class="col-md-4 da-font-bold font-weight-bold">Notes & Instructions</div>
                                                <div class="col-md-8">{{caseSummaryData.instructionNotes}}
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                  </ng-template>
                                </ngb-panel>
                              </ngb-accordion>

                            
                        </div>
                        <div class="col-md-3">

                            <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-3">
                                <ngb-panel id="custom-panel-3">
                                  <ng-template ngbPanelHeader let-opened="opened">
                                    <div class="d-flex align-items-center justify-content-between">
                                      <h5 class="m-0 font-weight-bold">Requested By </h5>
                                      <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                                          class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                    </div>
                                  </ng-template>
                                  <ng-template ngbPanelContent>
                                    <div class="row">
                                        <div class="col-md-12">

                                            <div  class="row">
                                                <div class="col da-font-bold font-weight-bold">{{caseSummaryData.clientContact}}</div>
                                            </div>
                                            <div  class="row">
                                                <div class="col da-font-bold font-weight-bold">{{caseSummaryData.clientEmail}}</div>
                                            </div>
                                            
                                            <div  class="row">
                                                <div class="col da-font-bold font-weight-bold" *ngIf="caseSummaryData.clientPhone && caseSummaryData.clientPhone.length > 0" >
                                                    <span>{{Contact(caseSummaryData.clientPhone)}}({{'Office'}})</span>
                                                </div>
                                            </div>
                                            <div  class="row mt-4 ">
                                                <div class="col da-font-bold font-weight-bold">{{caseSummaryData.clientName}} ({{caseSummaryData.clientLocation}})</div>
                                            </div>

                                            
                                        </div>
                                    </div>
                                  </ng-template>
                                </ngb-panel>
                              </ngb-accordion>

                        </div>
                        
                    </div>

                    <div class="row">

                        <div class="col-md-9">

                            <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-4">
                                <ngb-panel id="custom-panel-4">
                                  <ng-template ngbPanelHeader let-opened="opened">
                                    <div class="d-flex align-items-center justify-content-between">
                                      <h5 class="m-0 font-weight-bold">Subjects <button type="button" class="btn btn-transparent shadow-none border-0" (click)="btnClick()"><i class="fa fas fa-pencil-alt"></i></button></h5>
                                      <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                                          class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                    </div>
                                  </ng-template>
                                  <ng-template ngbPanelContent>
                                    <div class="row">
                                        <div class="col-md-12">
                                            <hr>
                                            <div  *ngIf="caseSummaryData.subjects" class="row mb-4">
                                                <div  *ngFor="let subject of caseSummaryData.subjects" class="col-md-12">
                                                    <b> {{subject.value}} </b>
                                                    ({{subject.id}})
                                                    <hr>
                                                </div>

                                            </div>
                                            <div  *ngIf="caseSummaryData.subjects && caseSummaryData.subjects.length == 0" class="row mb-4">
                                                <div  class="col-md-12">
                                                    <b> None </b>
                                                </div>

                                            </div>
                                          
                                        </div>
                                    </div>
                                  </ng-template>
                                </ngb-panel>
                              </ngb-accordion>

                        </div>
                        <div class="col-md-9">

                            <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-5">
                                <ngb-panel id="custom-panel-5">
                                  <ng-template ngbPanelHeader let-opened="opened">
                                    <div class="d-flex align-items-center justify-content-between">
                                      <h5 class="m-0 font-weight-bold">Supporting Files <button type="button" class="btn btn-transparent shadow-none border-0" (click)="Navigatetsupportingfile()"><i class="fa fas fa-plus"></i></button></h5>
                                      <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                                          class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                    </div>
                                  </ng-template>
                                  <ng-template ngbPanelContent>
                                    <div class="row">
                                        <div class="col-md-12" *ngIf="caseSummaryData.supportingFiles && caseSummaryData.supportingFiles.length != 0">

                                            <div  class="row mb-4" *ngFor="let supportingFiles of caseSummaryData.supportingFiles" >
                                                  <span [innerHTML]="appUtil.genrateIconExtension(supportingFiles.fileName)"></span>  <span>{{supportingFiles.fileName ? supportingFiles.fileName : supportingFiles.fileUrl}}</span>
                                            </div>
                                          
                                        </div>
                                        <div  *ngIf="caseSummaryData.supportingFiles && caseSummaryData.supportingFiles.length == 0" class="row mb-4">
                                            <div  class="col-md-12 ml-3">
                                                <b> None </b>
                                            </div>

                                        </div>
                                    </div>
                                  </ng-template>
                                </ngb-panel>
                              </ngb-accordion>

                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</main>


