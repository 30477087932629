import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-new-payment-method',
  templateUrl: './new-payment-method.component.html',
  styleUrls: ['./new-payment-method.component.css']
})
export class NewPaymentMethodComponent implements OnInit {


  constructor(private formBuilder: FormBuilder) { }

  form: FormGroup;
  submitted: boolean = false;
  isSelected: boolean = false;
  users = [{

    "id": 1,
    "name": "Paul"
  }];

  controls = this.users.map(c => new FormControl(false));
  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(),
      description: new FormControl(),
      isSelected: new FormControl(),
      orders: new FormArray(this.controls),
      reference_id: new FormControl(),
      customSwitch0: new FormControl(),
      customSwitch1: new FormControl(),
      customSwitch2: new FormControl(),
    });
  }

  onSubmit() {

  }

}
