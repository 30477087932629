import { HttpClient } from '@angular/common/http';
import { Injectable, Query } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { RoleAvailableFor } from '../helpers/enums/roles/role.available.for';
import { AddOrEditRoleRequestModel } from '../models/roles/add.role.request.model';
import { RoleModel } from '../models/roles/role.model';
import { SelectListItem } from '../models/shared/select.list.item';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  constructor(private http: HttpClient) {
  }

  public getRoles(): Observable<Array<SelectListItem<string>>> {
    return this.http.get<Array<SelectListItem<string>>>(`${environment.apiBaseUrl}/role/get-roles`);
  }

  public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
    return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/role/query`, dataTablesParameters, {});
  }
  public get(id: string): Observable<AddOrEditRoleRequestModel> {
    return this.http.get<AddOrEditRoleRequestModel>(`${environment.apiBaseUrl}/role/${id}`);
  }
  public put(model: AddOrEditRoleRequestModel): Observable<RoleModel> {
    return this.http.put<RoleModel>(`${environment.apiBaseUrl}/role`, model, {});
  }
  public post(model: AddOrEditRoleRequestModel): Observable<RoleModel> {
    return this.http.post<RoleModel>(`${environment.apiBaseUrl}/role/add-role`, model, {});
  }
  public delete(roleIds: string[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/role?roleIds=${roleIds.join('&roleIds=')}`);
  }
  public undelete(roleIds: string[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/role/undelete-roles?roleIds=${roleIds.join('&roleIds=')}`);
  }
  public getPermissions(roleAvailableFor: RoleAvailableFor): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/role/permissions?roleAvailableFor=${roleAvailableFor}`);
  }
  public getRoleCategories(): Observable<Array<SelectListItem<number>>> {
    return this.http.get<Array<SelectListItem<number>>>(`${environment.apiBaseUrl}/role/categories`);
  }

  public getRolesByAvailableFor(roleAvailableFor: RoleAvailableFor): Observable<Array<SelectListItem<string>>> {
    return this.http.get<Array<SelectListItem<string>>>(`${environment.apiBaseUrl}/role/get-roles-by-available-for/${roleAvailableFor}`);
  }
}
