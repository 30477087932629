
<button class="btn btn-lg bg-blue text-white filter-btn-nav  float-right"  >
    <i class="align-middle fas fa-filter"></i>
</button>

<table class="neu-table table table-striped neu-table w-100 table-responsive" datatable [dtOptions]="dtOptions" data-page="2" ></table>


<!-- Filter right sidenav -->
<app-case-detail-history-filter (onApplyFilter)="applyFilter($event)"     (resetFilters)="onResetFilters()" [filterStatusParent]="filterNavStatus"  (closeBtnFilterStatus)="closeFilterEvent($event)"  ></app-case-detail-history-filter>



