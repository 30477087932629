import { ModalService } from './../../../../services/modal.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { concat, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError, map, filter } from 'rxjs/operators';
import { RoleAvailableFor } from 'src/app/helpers/enums/roles/role.available.for';
import { NgbDateFRParserFormatter } from 'src/app/helpers/ngb.date.parser';
import { ClientContactEditModel } from 'src/app/models/client-contact/client.contact.edit.model';
import { CountryModel } from 'src/app/models/shared/country.model';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { ClientContactService } from 'src/app/services/client.contact.service';
import { ClientService } from 'src/app/services/client.service';
import { CountryService } from 'src/app/services/country.service';
import { LocationService } from 'src/app/services/location.service';
import { RoleService } from 'src/app/services/role.service';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';
import { LocationSelectedListItem } from 'src/app/models/Location/location.selected.list.item';

@Component({
  selector: 'app-edit-contact',
  templateUrl: './edit-contact.component.html',
  styleUrls: ['./edit-contact.component.css']
})
export class EditContactComponent implements OnInit, OnDestroy {

  clientContactsSubscription: Subscription;
  form: FormGroup;
  submitted = false;
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  loadBreadCrumb = false;
  managersSelectListItem: SelectListItem<string>[] = [];
  locationSelectListItem: LocationSelectedListItem[] = [];
  rolesSelectListItem: SelectListItem<string>[] = [];
  countrySelectListItem: CountryModel[] = [];
  salespersonSelectListItem: SelectListItem<string>[] = [];
  @BlockUI() blockUI: NgBlockUI;
  model: ClientContactEditModel;

  tags$: Observable<any>;
  tagsInput$ = new Subject<string>();

  public profileimg: string;
  public currentInput: string;
  fileBlob: string;
  fileName: string;
  imgSrc: string;
  isImageRender = false;
  isContactLoaded = false;

  dateStruct: NgbDateStruct;
  returnUrl: string;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private locationService: LocationService,
    private staffService: StaffService,
    private clientContactService: ClientContactService,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private countryService: CountryService,
    private ngbDateParserFormatter: NgbDateFRParserFormatter,
    private modalService: ModalService
  ) {
    this.model = new ClientContactEditModel();
    this.model.clientId = 0;
    this.route.params.subscribe(param => this.model.id = param.id);
    this.returnUrl = this.clientContactService.getReturnUrl();
    if (!this.returnUrl) {
      this.router.navigate(['client/contact-list']);
    }
    this.clientContactsSubscription = this.clientService.clientListDetail$.subscribe(data => {
      if (data) {
        this.model.clientId = data.id;
        this.breadCrumbInputs.push({
          key: 'id', value: [data.id.toString(), data.name]
        });
      }
    });
  }

  onImageCropped(file: File): void {
    this.form.controls.profileImg.setValue(file);
  }
  openAddLocationContactPopup(): void {
    this.modalService.openAddLocationContactPopup(this.model.clientId).subscribe(data => {
      this.loadData();
    });
  }
  ngOnInit(): void {

    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      middleName: '',
      lastName: ['', Validators.required],
      manager: [''],
      role: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      title: ['', [Validators.required]],
      country: [null, [Validators.required]],
      fax: [''],
      dob: null,
      accountCode: [''],
      tags: null,
      generalNotes: [''],
      adminNotes: [''],
      profileImg: null,
      location: ['', [Validators.required]],
      caseManager: [''],
      salesPerson: ['']
    });

    this.loadData();
    this.loadTags();
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }

    this.model.firstName = this.form.controls.firstName.value ?? '';
    this.model.middleName = this.form.controls.middleName.value ?? '';
    this.model.lastName = this.form.controls.lastName.value ?? '';
    this.model.accountCode = this.form.controls.accountCode.value ?? '';
    this.model.adminNotes = this.form.controls.adminNotes.value ?? '';
    this.model.country = this.form.controls.country.value ?? '';
    this.model.dob = this.ngbDateParserFormatter.format(this.form.controls.dob.value) ?? '';
    this.model.email = this.form.controls.email.value ?? '';
    this.model.fax = this.form.controls.fax.value ?? '';
    this.model.generalNotes = this.form.controls.generalNotes.value ?? '';
    this.model.profileImgFile = this.form.controls.profileImg.value;
    this.model.role = this.form.controls.role.value ?? '';
    this.model.tags = this.form.controls.tags.value;
    this.model.title = this.form.controls.title.value ?? '';
    this.model.locationId = Number(this.form.controls.location.value);
    this.model.caseManager = this.form.controls.caseManager.value ?? '';
    this.model.salesPerson = this.form.controls.salesPerson.value ?? '';
    this.model.contactNos = [];
    this.form.value.dynamicRows.map(
      (x: { phoneType: any; phoneNum: any; extn: any, id: any; }) =>
        this.model.contactNos.push({
          contactTypeId: Number(x.phoneType),
          contactNumber: x.phoneNum,
          extensionNote: x.extn ?? '',
          id: Number(x.id), entityId: ''
        })
    );
    this.blockUI.start();
    this.clientContactService.put(this.model).subscribe(
      data => {
        this.router.navigate([this.returnUrl]);
        this.toasterService.success(data);
        this.blockUI.stop();
      },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }

  addCustomTag = (term) => term;

  private loadData(): void {
    this.blockUI.start();
    forkJoin([
      this.locationService.getLocationListByClientId(this.model.clientId),
      this.staffService.getCaseManagers(),
      this.staffService.getSalespersons(),
      this.roleService.getRolesByAvailableFor(RoleAvailableFor.clientContact),
      this.countryService.getCountries(),
      this.clientContactService.get(this.model.id)
    ]).subscribe(([
      locations,
      caseManagers,
      salespersons,
      roles,
      countries,
      contact,
    ]) => {
      this.locationSelectListItem = locations;
      this.managersSelectListItem = caseManagers;
      this.salespersonSelectListItem = salespersons;
      this.countrySelectListItem = countries;
      this.rolesSelectListItem = roles;
      if (contact) {
        this.model = contact;
        this.loadContact();
      }
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error, 'Something went wrong!');
      this.router.navigate([this.returnUrl]);
      this.blockUI.stop();
    });
  }

  private loadContact(): void {
    this.breadCrumbInputs.push({
      key: 'id', value: [this.model.id,
      `${this.model.firstName} ${this.model.middleName ?? ''} ${this.model.lastName ?? ''}`.replace('  ', ' ')]
    });
    this.loadBreadCrumb = true;
    this.imgSrc = this.model.profileImgUrl;
    this.isImageRender = true;

    this.form.controls.firstName.setValue(this.model.firstName);
    this.form.controls.middleName.setValue(this.model.middleName);
    this.form.controls.lastName.setValue(this.model.lastName);
    this.form.controls.accountCode.setValue(this.model.accountCode);
    this.form.controls.adminNotes.setValue(this.model.adminNotes);
    this.form.controls.country.setValue(this.model.country);
    this.form.controls.dob.setValue(this.ngbDateParserFormatter.parse(this.model.dob));
    this.form.controls.email.setValue(this.model.email);
    this.form.controls.fax.setValue(this.model.fax);
    this.form.controls.generalNotes.setValue(this.model.generalNotes);
    this.form.controls.role.setValue(this.model.role);
    this.form.controls.tags.setValue(this.model.tags);
    this.form.controls.title.setValue(this.model.title);
    this.form.controls.caseManager.setValue(this.model.caseManager ?? '');
    this.form.controls.salesPerson.setValue(this.model.salesPerson ?? '');
    this.form.controls.location.setValue(this.model.locationId);
    this.isContactLoaded = true;
  }

  private loadTags(): void {
    this.tags$ = concat(
      of([]), // default items
      this.tagsInput$.pipe(
        debounceTime(200),
        filter(res => {
          return res !== null && res.length >= 0;
        }),
        distinctUntilChanged(),
        switchMap(term => {
          return this.staffService.getStaffTags(term).pipe(
            catchError(() => of([])) // empty list on error
          );
        })
      )
    );
  }

  private toDateString(date: NgbDateStruct): string // from internal model -> your mode
  {
    return date ? ('0' + date.month).slice(-2)
      + '/' + ('0' + date.day).slice(-2) + '/' + date.year : null;
  }

  ngOnDestroy(): void {
    this.clientContactsSubscription.unsubscribe();
  }

}
