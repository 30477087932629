import { Component, OnInit, ViewChild, Renderer2, Input, AfterViewInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { NoteService } from 'src/app/services/note.service';
import * as moment from 'moment';

import { AttachmentTypeOptions, NoteFilterByOptions, NoteSearchOptions } from 'src/app/helpers/enums/filters-and-searchs/note.search&filter.options';
import { AppUtils } from 'src/app/helpers/app.utils';



declare const exportNav: any;


@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit, AfterViewInit {


  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  @Input() moduleId: number;
  @Input() entityId = '';
  @Input() isCurrentUser = false;
  @Input() urlPrefix: '';
  private searchBy?: NoteSearchOptions;
  private searchFor: string;
  filterBy?: NoteFilterByOptions = null;
  filterByValue?: AttachmentTypeOptions = null;
  filterInAccessGroup?: string = null;
  isInitFilterAndSearch = false;
  isShowFilterAndSearch = false;
  isBulkOperationButtonDisabled = true;

  // capture filter open and close events
  filterNavStatus = false;
  closeFilterEvent(event: any): void {
    this.filterNavStatus = event;
  }

  constructor(private noteService: NoteService,
              private toasterService: ToasterService,
              private renderer: Renderer2,
              private router: Router,
              private route: ActivatedRoute,
              private appUtils: AppUtils) { }


  ngOnInit(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },

      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,
      order: [[2, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.module = this.moduleId;
        dataTablesParameters.entityId = this.entityId;
        dataTablesParameters.isCurrentUser = this.isCurrentUser;
        dataTablesParameters.filterBy = self.filterBy;
        dataTablesParameters.filterByValue = self.filterByValue;
        dataTablesParameters.filterInGroup = self.filterInAccessGroup;
        dataTablesParameters.searchBy = self.searchBy;
        dataTablesParameters.searchFor = self.searchFor;
        self.blockUI.start();
        self.noteService.query(dataTablesParameters)
          .subscribe(resp => {
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.toasterService.ProcessErrorResponse(error);
            self.blockUI.stop();
          });
      },
      columns: [
        {
          data: null,
          title: `<input type="checkbox" action-type="select-unSelect-all" />`,
          name: 'select',
          orderable: false,
          render: (data, type, row) => {
            return `<input type='checkbox' value = '${row.id}' action-type= "select-row"/>`;
          }
        },
        {
          data: 'isPinnedNote',
          title: '',
          orderable: false,
          render: (data, type, row) => {
            const checkstar = row.isPinnedNote ? '<i class="fas fa-star scale-up-center" title="Pinned Notes" style="color: #fcce2a"></i>' : '';
            return `${checkstar} `;
          }

        },
        {
          data: 'date',
          title: 'Date',
          render: (data, type, row) => {
            return `<div>${moment(row.date).format('MM/DD/YY')}</div>
                      <div>${row.author ?? ''}</div>
                      <div>${row.accessGroupName}</div>`;
          }
        },
        {
          data: 'noteText',
          title: 'Note',
          render: (data, type, row) => {
            if (!row.noteText) {
              return '';
            } else {
              return `<div class='note-text-wrap' title='${row.noteText}'>${row.noteText}</div>`;
            }
          }
        },
        {
          data: 'fileName',
          title: 'Attachment',
          render: (data, type, row) => {
            if (!row.fileName) {
              return '';
            }

            const showIcon = this.appUtils.genrateIconExtension(row.fileName);
            return row.fileUrl ?
              `<a class='link m-r-10 link-line-wrap '  action-type='download-attachment'  container='body' title='${row.fileName}'> ${showIcon} ${row.fileName}</a> ` :
              row.fileName;
          }
        },
        {
          data: 'name',
          title: 'Action ',
          orderable: false,
          render: (data, type, row) => {
            const deleteUndeleteButton = this.filterBy === NoteFilterByOptions.DeletedNotes ?
              `<button  class='btn del-btn btn-lg btn-table' action-type="un-delete">
              <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-restore-alt  fa-1x p-2 text-danger'></i>
              </button>` :
              `<button  class='btn del-btn btn-lg btn-table' action-type="delete">
              <i class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
              </button>`;
            return `<div class='fa-edit'>
                <button  class='btn del-btn btn-lg btn-table'  >
                <i action-type="edit-note" class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
                </button>
                ${deleteUndeleteButton}
                </div>
                `;
          }
        },
      ],
      rowCallback: (row, data: any) => {
        const _row = $(row);
        _row.find('[action-type="download-attachment"]')
          .off('click')
          .on('click', () => {
            self.downloadAttachment(data.fileName, data.id);
          });
        _row.find('[action-type="edit-note"]')
          .off('click')
          .on('click', () => {
            self.editNote(data.id);
          });
        _row.find('[action-type="delete"]')
          .off('click')
          .on('click', () => {
            // self.deleteRole([data.id]);
            self.deleteNote([data.id]);
          });
        _row.find('[action-type="un-delete"]')
          .off('click')
          .on('click', () => {
            // self.unDeleteRole([data.id]);
            self.unDeleteNote([data.id]);
          });
        _row.find('[action-type = "select-row"]')
          .off('click').on('click', (e: any) => {
            self.onSelectedOrUnselectRow(e);
          });
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
    };
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtInstance = dtInstance;
      const header = dtInstance.columns().header();
      $(header).find('[action-type="select-unSelect-all"]')
        .off('change').on('change', (e: any) => {
          this.onSelectAllRow(e.target.checked);
        });
    });
  }

  downloadAttachment(fileName, noteId): void {
    this.blockUI.start();
    this.noteService.downloadAttachment(noteId).subscribe((blob: File) => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = fileName;
      link.style.display = 'none';
      link.href = blobUrl;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobUrl);
        link.remove();
      }, 100);
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  editNote(id): void {
    this.noteService.setReturnUrl(this.router.url);
    this.router.navigate([`${this.urlPrefix}/notes/edit/${id}`]);
  }

  applyFilter(filterData: any): void {
    if (filterData.filter) {
      const filterIdArr = filterData.filter.split('_');
      if (filterIdArr && filterIdArr.length > 0) {
        this.filterBy = Number(filterIdArr[0]);
        if (filterIdArr.length > 1) {
          this.filterByValue = Number(filterIdArr[1]);
        }
      }
    }
    else {
      this.filterBy = null;
      this.filterByValue = null;
    }
    this.filterInAccessGroup = filterData.filterIn === '-1' ? null : filterData.filterIn;
    this.searchBy = filterData.searchBy;
    this.searchFor = filterData.searchFor;
    this.reload();
  }

  onResetFilters(): void {
    this.filterBy = null;
    this.filterInAccessGroup = null;
    this.searchBy = null;
    this.searchFor = '';
    this.reload();
  }

  onFilterNavClose(): void {
    this.isShowFilterAndSearch = false;
  }

  reload(): void {
    this.dtInstance.ajax.reload();
    this.resetSelectAllCheckBox();
  }

  onSelectAllRow(isChecked: boolean): void {
    const rows = this.dtInstance.rows().nodes();
    const checkBoxes = [...rows.$('[action-type= "select-row"]')]
      .filter(row => !row.hasAttribute('disabled'));
    if (isChecked) {
      checkBoxes.forEach(row => {
        this.renderer.addClass(row, 'checked');
        (row as HTMLInputElement).checked = true;
      });
    } else {
      checkBoxes.forEach(row => {
        this.renderer.removeClass(row, 'checked');
        (row as HTMLInputElement).checked = false;
      });
    }
    this.enableOrDisableDeleteButton();
  }

  onSelectedOrUnselectRow(event: any): void {
    if (event.target.checked) {
      this.renderer.addClass(event.target, 'checked');
    } else {
      this.renderer.removeClass(event.target, 'checked');
    }
    this.toggleControls();
  }

  onBulkDelete(): void {
    this.deleteNote(this.getSelectedNoteIds())
  }

  onBulkUnDelete(): void {
    this.unDeleteNote(this.getSelectedNoteIds());
  }

  deleteNote(noteIds: number[]): void {
    if (!confirm('Are you sure you want to delete selected note(s)?')) {
      return;
    }
    this.blockUI.start();
    this.noteService.delete(noteIds).subscribe(() => {
      this.reload();
      this.toasterService.success('Note(s) deleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  unDeleteNote(noteIds: number[]): void {
    if (!confirm('Are you sure you want to undelete selected note(s)?')) {
      return;
    }
    this.blockUI.start();
    this.noteService.undelete(noteIds).subscribe(() => {
      this.reload();
      this.toasterService.success('Note(s) undeleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  private getSelectedNoteIds(): any[] {
    const noteIds = [];
    const rows = this.dtInstance.rows().nodes();
    [...rows.$('.checked')].forEach(item => noteIds.push($(item).val()));
    return noteIds;
  }

  private toggleControls(): void {
    this.setSelectAllCheckbox();
    this.enableOrDisableDeleteButton();
  }

  private setSelectAllCheckbox(): void {
    const rows = this.dtInstance.rows().nodes();
    if (rows !== null) {
      const header = this.dtInstance.columns().header();
      const selectAllElement = $(header).find('[action-type="select-unSelect-all"]')[0] as any;
      const selelectedRows = [...rows.$('[action-type= "select-row"]')]
        .filter(row => !row.hasAttribute('disabled'));
      selectAllElement.checked = selectAllElement != null && selelectedRows.length > 0
        && selelectedRows.every(row => row.classList.contains('checked'));
    }
  }

  private enableOrDisableDeleteButton(): void {
    const rows = this.dtInstance.rows().nodes();
    this.isBulkOperationButtonDisabled = [...rows.$('.checked')].length === 0;
  }

  get noteFilterOptions(): any {
    return NoteFilterByOptions;
  }

  private resetSelectAllCheckBox(): void {
    const header = this.dtInstance.columns().header();
    const selectAllElement = $(header).find('[action-type="select-unSelect-all"]')[0] as any;
    if (selectAllElement && selectAllElement.checked) {
      selectAllElement.checked = false;
    }
  }
}
