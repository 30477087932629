<main class="content py-0 pr-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-8 col-lg-7 col-xl-8 col-xxl-8 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-auto d-none d-sm-block">
                            <div class="d-flex mb-2">
                                <h3><strong>Event Map</strong></h3>
                                <span class="dropdown subject-dropdown show ml-2">
                    <a
                      class="btn text-orange dropdown-toggle"
                      href="#"
                      role="button"
                      id="dropdownMenuLink"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      All Event
                    </a>
  
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                      <a class="dropdown-item" href="#">Action</a>
                      <a class="dropdown-item" href="#">Another action</a>
                      <a class="dropdown-item" href="#">Something else here</a>
                    </div>
                  </span>
                            </div>
                            <p>Get all the events list info on map</p>
                        </div>

                        <div class="col-auto ml-auto text-right mt-n1">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                    <li class="filter-item">
                                        <form class="form-inline d-none d-sm-inline-block col-md-12">
                                            <div class="input-group input-group-navbar border">
                                                <div class="input-group-append border-left">
                                                    <button class="btn bg-white rounded-left search-btn" type="button">
                              <i class="align-middle" data-feather="search"></i>
                            </button>
                                                </div>
                                                <input type="text" class="bg-white form-control pl-0" placeholder="Enter a City, State or Location..." aria-label="Search" />
                                            </div>
                                        </form>
                                    </li>
                                    <li class="filter-item px-0 filter-icon">
                                        <button class="btn btn-lg bg-blue text-white" (click)="filterNavStatus = !filterNavStatus">
                        <i class="align-middle fas fa-filter"></i>
                      </button>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <!-- Filter right sidenav -->
                        <app-event-map-filters (onApplyFilter)="applyFilter($event)" (resetFilters)="onResetFilters()" [filterStatusParent]="filterNavStatus" (closeBtnFilterStatus)="closeFilterEvent($event)"></app-event-map-filters>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card">
                                <div class="card-body">
                                    <div class="map-container">
                                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3353.5501887106443!2d-117.04305484890975!3d32.804184189812865!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x80d95649b799fdaf%3A0xbd1d0019b14f4ea2!2sArgus%20West%20Investigations!5e0!3m2!1sen!2sin!4v1596524581929!5m2!1sen!2sin"
                                            width="100%" height="800" frameborder="0" style="border: 0" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4 col-lg-5 col-xl-4 sidebar-right pt-7">
                <div class="col-12 h-100 pt-4 pr-0">
                    <div class="bg-white h-100 right-sidebar map-border">
                        <div class="card-body mob-px-4 map-sidebar">
                            <div class="row mb-6">
                                <div class="col-md-12">
                                    <button class="btn border-0 bg-warning text-white font-weight-500 evnt-btn-half map-btn-half pl-3 py-2">
                      In Progress
                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <h2 class="user-title font-weight-bold mb-2">
                                        Case Assignment - MED
                                        <small class="text-muted font-weight-500">(Surveillance)</small
                      >
                    </h2>
                    <p class="detail-client-title black-light-text">
                      Monday, August 20 ⋅ 8:30AM – 09:00 AM
                    </p>
                    <button class="btn text-orange ">
                      <i class="align-middle fas fa-pen text-orange mr-2"></i>Edit
                      Info
                    </button>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="col-md-6 mb-4">
                    <p class="text-light-black mb-1">Case No</p>
                    <h6
                      class="detail-client-title text-dark-blue font-weight-500 mb-0"
                    >
                      SV18242-01
                    </h6>
                  </div>
                  <div class="col-md-6 mb-4">
                    <p class="text-light-black mb-1">Subject</p>
                    <h6
                      class="detail-client-title text-dark-blue font-weight-500 mb-0"
                    >
                      Turner, Tracy
                    </h6>
                  </div>
                  <div class="col-md-12 mb-4">
                    <p class="text-light-black mb-0">Location</p>
                    <div class="d-flex align-items-baseline">
                      <h6
                        class="detail-client-title black-light-text font-weight-500"
                      >
                        904 Clay Court, Antioch, CA 94509
                      </h6>
                      <span class="subject-dropdown">
                        <a
                          href="#"
                          class="ml-2 btn text-orange dropdown-toggle border-0 dropdown-no-caret"
                          ><i class="align-middle fas fa-map"></i> Get
                          Direction</a
                        >
                      </span>
                    </div>
                  </div>
                  <div class="col-md-12 mb-4">
                    <p class="text-light-black mb-1">Assigned to</p>
                    <h6
                      class="detail-client-title text-dark-blue font-weight-500 mb-0"
                    >
                      MIKE STUBENVOLL, PAUL KEENAN
                    </h6>
                  </div>
                  <div class="col-md-12">
                    <div class="d-flex">
                      <img
                        class="mt-1 mr-2"
                        src="img/icons/address.png"
                        alt=""
                        height="14"
                      />
                      <p class="detail-client-title black-light-text mb-1">
                        Med Appt: 07/09/2020 at 2pm, Concentra,1655 Gateway Blvd
                        Concord, CA 94520 925-685-7744
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12 text-right">
                <div class="btn-group map-btn-group">
                
                  <button
                    type="button"
                    class="py-3 btn btn-lg bg-blue text-white rounded-0 edit_btn"
                  >
                    <i class="align-middle fas fa-external-link-alt mr-2"></i
                    >Edit
                  </button>
                  <button
                    type="button"
                    class="py-3 btn btn-lg bg-white text-dark-blue border-btn mr-3 rounded-0"
                  >
                    <i class="align-middle fas fa-copy mr-2"></i>Duplicate
                  </button>
                  <button
                    type="button"
                    class="py-3 btn btn-lg bg-blue text-white rounded-0"
                  >
                    <i class="align-middle fas fa-external-link-alt mr-2"></i
                    >Create Case Update
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main>