import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-new-tracking-category',
  templateUrl: './new-tracking-category.component.html',
  styleUrls: ['./new-tracking-category.component.css']
})
export class NewTrackingCategoryComponent implements OnInit {


  constructor(private formBuilder: FormBuilder) { }

  form: FormGroup;
  submitted: boolean = false;
  isSelected: boolean = false;
  users = [{

    "id": 1,
    "name": "Paul"
  }];

  controls = this.users.map(c => new FormControl(false));
  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(),
      description: new FormControl(),
    });
  }

  onSubmit() {

  }

}
