import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Console } from 'console';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { AppUtils } from 'src/app/helpers';
import { ComponentDestoryHelper } from 'src/app/helpers/component-destory.helper';
import { CustomFieldTypes } from 'src/app/helpers/enums/settings/customField/CustomFieldTypes';
import { NgbDateCustomParserFormatter } from 'src/app/helpers/NgbDateCustomParserFormatter';
import { AddCaseModel } from 'src/app/models/case/add-case-model';
import { AddOrEditCaseRegionModelModel } from 'src/app/models/caseregion/add.case-region.model';
import { CaseTypeViewModel } from 'src/app/models/casetypes/caseTypeViewModel';
import { CompanyLocationViewModel } from 'src/app/models/company-location/companyLocationViewModel';
import { CustomFieldModel } from 'src/app/models/custom-fields/CustomFieldModel';
import { LocationSelectedListItem } from 'src/app/models/Location/location.selected.list.item';
import { ReferralSourceViewModel } from 'src/app/models/referral-source/referralSourceViewModel';
import { CountryModel } from 'src/app/models/shared/country.model';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { StateModel } from 'src/app/models/shared/state.model';
import { CaseService } from 'src/app/services/case.service';
import { CaseFlagService } from 'src/app/services/caseflag.service';
import { CaseRegionService } from 'src/app/services/caseregion.service';
import { CaseServicesService } from 'src/app/services/caseservice.service';
import { CasetypeService } from 'src/app/services/casetype.service';
import { ClientContactService } from 'src/app/services/client.contact.service';
import { ClientService } from 'src/app/services/client.service';
import { CompanyLocationService } from 'src/app/services/company-location.service';
import { CountryService } from 'src/app/services/country.service';
import { CustomfieldService } from 'src/app/services/customfield.service';
import { LocationService } from 'src/app/services/location.service';
import { ModalService } from 'src/app/services/modal.service';
import { ReferralsourceService } from 'src/app/services/referralsource.service';
import { ToasterService } from 'src/app/services/toater.service';
import { StaffService } from 'src/app/services/staff.serrvice';
import { json } from '@rxweb/reactive-form-validators';
import { RoleService } from 'src/app/services/role.service';
import { RoleAvailableFor } from 'src/app/helpers/enums/roles/role.available.for';
import { CaseStatusViewModel } from 'src/app/models/case-status/caseStatusViewModel';
import { CasestatusService } from 'src/app/services/casestatus.service';

@Component({
  selector: 'app-add-case',
  templateUrl: './addCase.component.html',
  styleUrls: ['./addCase.component.css'],
})
export class AddCaseComponent extends ComponentDestoryHelper implements OnInit {
  @BlockUI('add-case-ui') blockUI: NgBlockUI;
  submitted: boolean;
  clientDetail: SelectListItem<number>;
  clientListItem;
  clientLocationListItem: LocationSelectedListItem[] = [];
  companyLocationList: CompanyLocationViewModel[] = [];
  casestatusList: CaseStatusViewModel[] = [];
  clientContactListItem: SelectListItem<string>[] = [];
  caseRegionList: AddOrEditCaseRegionModelModel[] = [];
  rolesSelectListItem: SelectListItem<string>[] = [];
  referralSourceList: ReferralSourceViewModel[] = [];
  caseTypeList: CaseTypeViewModel[];
  caseFlagList: SelectListItem<number>[] = [];
  caseServiceList: SelectListItem<number>[] = [];
  customFieldList: CustomFieldModel[] = [];
  managersSelectListItem: SelectListItem<string>[] = [];
  salespersonsSelectListItem: SelectListItem<string>[] = [];
  investigatorSelectListItem: SelectListItem<string>[] = [];
  panelStep = 1;
  selectedType = '';
  
  defaultCustomFieldValues = [];
  countryList: CountryModel[] = [];
  casePolicies = '';
  addCaseModel: AddCaseModel;
  stateSelectListItem: StateModel[] = [];
  constructor(
    private formBuilder: FormBuilder,
    private locationService: LocationService,
    private router: Router,
    private roleService: RoleService,
    private clientService: ClientService,
    private toasterService: ToasterService,
    private clientContactService: ClientContactService,
    private modalService: ModalService,
    private caseTypeService: CasetypeService,
    private caseStatusService:CasestatusService,
    public appUtils: AppUtils,
    private staffService: StaffService,
    private companyLocationService: CompanyLocationService,
    public caseRegionService: CaseRegionService,
    private referralSourceService: ReferralsourceService,
    private caseFlagService: CaseFlagService,
    private caseServicesService: CaseServicesService,
    private customFieldService: CustomfieldService,
    private countryService: CountryService,
    private caseService: CaseService,
    private ngbDateCustomParserFormatter: NgbDateCustomParserFormatter
  ) {
    super();
    this.caseTypeList = [];
    this.addCaseModel = new AddCaseModel();
  }

  // tslint:disable-next-line: typedef
  get GetCustomFieldCaseType() {
    return CustomFieldTypes;
  }
   filterOptions = [];
  form: FormGroup;

  get referencesToFormArray(): FormArray {
    return this.form.controls.references as FormArray;
  }
  get customFieldToFormArray(): FormArray {
    return this.form.controls.customFields as FormArray;
  }

  setRequired(): void {
    if (!this.appUtils.isRequiredField('createdOn', this.form)) {
      this.form.controls.createdOn.setValidators([Validators.required]);
      this.form.controls.createdOn.updateValueAndValidity();
    }
    if (!this.appUtils.isRequiredField('caseRegionId', this.form)) {
      this.form.controls.caseRegionId.setValidators([Validators.required]);
      this.form.controls.caseRegionId.updateValueAndValidity();
    }
  }

  unsetRequired(): void {
    if (this.appUtils.isRequiredField('createdOn', this.form)) {
      this.form.controls.createdOn.setValidators(null);
      this.form.controls.createdOn.updateValueAndValidity();
      this.form.controls.caseRegionId.setValidators(null);
      this.form.controls.caseRegionId.updateValueAndValidity();
    }
    if (this.appUtils.isRequiredField('caseRegionId', this.form)) {
      this.form.controls.caseRegionId.setValidators(null);
      this.form.controls.caseRegionId.updateValueAndValidity();
    }
  }

  loadRefernces(): void {
    const rf: FormArray = this.referencesToFormArray;
    rf.clear();
    const references = this.caseTypeList.filter(
      (x) => x.id === +this.form.controls.caseTypeId.value
    )[0].caseTypeReferences;
    references
      .filter((x) => x.name)
      .forEach((d) => {
        rf.push(
          this.formBuilder.group({
            value: d.isRefNumberRequired ? ['', Validators.required] : '',
            ...d,
          })
        );
      });
  }

  ngOnInit(): void {
    this.loadDefaults();
    this.loadRoles();
    this.loadcasestatus();
    this.form = this.formBuilder.group({
      // start : panel 1 controls
      clientNameId: ['', Validators.required],
      clientName: [''],
      clientContactId: ['', Validators.required],
      clientContact: [''],
      clientLocationId: ['', Validators.required],
      clientLocation: [''],
      caseTypeId: ['', Validators.required],
      caseType: ['', Validators.required],
      assignMyselfAs: [''],
      casemanager: [''],
      salesperson:[''],
      investigators:[''],
      // end : panel 1 controls
      companyLocationId: [''],
      caseRegionId: [''],
      caseStatus:[''],
      caseFlags: [],
      caseServices: [],
      referralSource: [''],
      references: new FormArray([]),
      createdOn: [''],
      dueDate: [null],
      caseLocation: [''],
      customFields: new FormArray([]),
      notes: '',
      adminnotes: '',
      schedulingnotes: '',
      budgetprice: '',
      budgethours: '',
    });
    this.form.controls.assignMyselfAs.setValue('none');
    const date = new Date();
    const ngbDateStruct = {  month: date.getUTCMonth() + 1, day: date.getUTCDate(), year: date.getUTCFullYear()};
    this.form.controls.createdOn.setValue(ngbDateStruct);
  }
  private loadcasestatus(): void {
    this.blockUI.start();
    this.caseStatusService.getAll().subscribe(
      (data: any) => {
        this.casestatusList = data;
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => {
        this.blockUI.stop();
      }
    );
  }
  onSubmit(): void {
    debugger;
    this.submitted = true;
    if (this.panelStep === 2 && this.form.invalid) {
      this.toasterService.error('Please fill all required fields');
      return;
    }
    this.blockUI.start();
    const controls = this.form.controls;
    this.addCaseModel.id = 0;
    this.addCaseModel.clientId = controls.clientNameId.value;
    this.addCaseModel.contactId = controls.clientContactId.value;
    this.addCaseModel.locationInClientId = controls.clientLocationId.value;
    this.addCaseModel.caseTypeId = +controls.caseTypeId.value;
    this.addCaseModel.caseStatusId=controls.caseStatus.value ? +controls.caseStatus.value : null;
    this.addCaseModel.assignedMyselfAs = controls.assignMyselfAs.value;
    this.addCaseModel.caseManager=controls.casemanager.value;
    this.addCaseModel.salesPersons=controls.salesperson.value;
    this.addCaseModel.investigator=controls.investigators.value;
    this.addCaseModel.referralSourceId = controls.referralSource.value ? +controls.referralSource.value : null;
    this.addCaseModel.createdOn = new Date(
      `${controls.createdOn.value.month}/${controls.createdOn.value.day}/${controls.createdOn.value.year}`
    );

    if (controls.dueDate.value) {
      this.addCaseModel.dueDate = new Date(
        `${controls.dueDate.value.month}/${controls.dueDate.value.day}/${controls.dueDate.value.year}`
      );
    }
    this.addCaseModel.caseFlagInCase = [];
    controls.caseFlags.value?.map((item) => {
      this.addCaseModel.caseFlagInCase.push({
        id: 0,
        caseId: 0,
        caseFlagId: item,
      });
    });
    this.addCaseModel.caseServicesInCase = [];
    controls.caseServices.value?.map((item) => {
      this.addCaseModel.caseServicesInCase.push({
        id: 0,
        caseId: 0,
        caseServiceId: item,
      });
    });
    this.addCaseModel.caseTypeReferenceLabelInCase = [];
    controls.references.value?.map((item) => {
      this.addCaseModel.caseTypeReferenceLabelInCase.push({
        id: 0,
        caseId: 0,
        caseTypeReferenceLabelId: item.id,
        value:
        typeof item.value=='object'
        ?JSON.stringify(item.value)
        :item.value?.toString(), 
        
      });
    });
    this.addCaseModel.customFieldInCase = [];
    controls.customFields.value?.map((item) => {
      this.addCaseModel.customFieldInCase.push({
        id: 0,
        caseId: 0,
        customFieldId: item.id,
        value:
          typeof item.data === 'object'
            ? JSON.stringify(item.data)
            : item.data?.toString(),
      });
    });
    this.addCaseModel.companyLocationId = controls.companyLocationId.value ? +controls.companyLocationId.value : null;
    this.addCaseModel.caseRegionId = +controls.caseRegionId.value;
    this.addCaseModel.caseLocation = controls.caseLocation.value;
    this.addCaseModel.instructionNote = controls.notes.value;
    this.addCaseModel.schedulingNote = controls.schedulingnotes.value;
    this.addCaseModel.adminNote = controls.adminnotes.value;
    this.addCaseModel.budget = controls.budgetprice.value ? controls.budgetprice.value.toString() : controls.budgetprice.value;
    this.addCaseModel.hours = controls.budgethours.value ? controls.budgethours.value.toString() : controls.budgethours.value;

    // this.router.navigate('/add-case-last-steps');
    // this.router.navigateByUrl('/add-case-last-steps');
    // this.router.navigate(['/', 'add-case-last-steps']);

    this.caseService.post(this.addCaseModel).subscribe(
      (data) => {
        setTimeout(() => {
          // this.OnCancel();
          // logic to goto subject type if exist
          // if not goto summary part
          const casetype = this.caseTypeList.filter(x => x.id == data.caseTypeId);
          if (casetype && casetype.length > 0 && casetype[0].caseTypeSubjectTypes && casetype[0].caseTypeSubjectTypes.length > 0){
            this.router.navigate(['/add-case-last-steps'], {
              queryParams: {
                caseTypeId: data.caseTypeId,
                caseId: data.id,
                isSubjectType: true
              }
            });
          }
          else{
            this.router.navigate(['/add-case-supporting-files'], {
              queryParams: {
                caseTypeId: data.caseTypeId,
                caseId: data.id
              }
            });
          }

        }, 301);
        setTimeout(() => {
          this.toasterService.success('Case details save successfully');
        }, 300);
        this.blockUI.stop();
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }
  OnCancel(): void {
    this.router.navigate(['/setting/cases/case-type-list']);
  }
  nextStep(e: number): void {
    this.submitted = true;

    // runs only if required field is missing
    if (e === 1) {
      this.unsetRequired();
      this.referencesToFormArray.clear();
      this.customFieldToFormArray.clear();
      this.referencesToFormArray.clear();
    }
    // if e === 2 means we goes forward
    if (e === 2 && this.form.invalid) {
      this.toasterService.error('Please fill all required fields');
      return;
    }

    // always run when we are going to panel 2
    if (e === 2) {
      const caseTypeId = this.form.controls.caseTypeId.value;
      this.loadPanel2Fields(caseTypeId);
      this.loadRefernces();
      this.form.controls.caseFlags.setValue(null);
      this.form.controls.caseServices.setValue(null);
      this.setRequired();
    }
    this.submitted = false;
    this.panelStep = e;
  }
  // start panel 1 events
  onChangeClient(event: SelectListItem<number>): void {
    this.clientContactListItem = [];
    this.clientLocationListItem = [];
    this.clientDetail = null;
    this.form.controls.clientLocationId.setValue('');
    this.form.controls.clientLocation.setValue('');
    this.form.controls.clientContactId.setValue('');
    this.form.controls.clientContact.setValue('');
    this.form.controls.clientName.setValue('');
    if (event) {
      this.loadClientContact(event.id);
      this.loadClientLocation(event.id);
      this.clientDetail = event;
      this.form.controls.clientName.setValue(event.value);
    }
  }
  onChangeClientLocation(event: SelectListItem<number>): void {
    this.form.controls.clientLocationId.setValue('');
    this.form.controls.clientLocation.setValue('');
    if (event) {
      this.form.controls.clientLocationId.setValue(event.id);
      this.form.controls.clientLocation.setValue(event.value);
    }
  }
  private loadRoles(): void {
    this.blockUI.start();
    this.roleService.getRolesByAvailableFor(RoleAvailableFor.employee).subscribe((data: any) => {
      this.rolesSelectListItem = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  onChangeClientContact(event: SelectListItem<string>): void {
    this.form.controls.clientContactId.setValue('');
    this.form.controls.clientContact.setValue('');
    if (event) {
      this.form.controls.clientContactId.setValue(event.id);
      this.form.controls.clientContact.setValue(event.value);
    }
  }
  onChangeCaseType(event: any): void {
    if (event && event.currentTarget) {
      this.form.controls.caseTypeId.setValue(event.currentTarget.value);
      this.form.controls.caseType.setValue(
        event.currentTarget.options[event.currentTarget.selectedIndex].text
      );
      return;
    }
    this.form.controls.caseTypeId.setValue('');
    this.form.controls.caseType.setValue('');
  }
  // end panel 1 events

  loadDefaults(): void {
    this.blockUI.start();
    forkJoin([
      this.clientService.getClientList(),
      this.countryService.getCountries(),
      this.referralSourceService.getAll(),
      this.caseRegionService.getAll(),
      this.companyLocationService.getAll(),
      this.caseTypeService.getCaseTypes(),
      
    ]).subscribe(
      ([
        clientList,
        countryList,
        referralSourceList,
        caseRegionList,
        companyLocationList,
        caseTypeList,
        
      ]) => {
        this.form.controls.budgetprice.setValue('0');
        this.form.controls.budgethours.setValue('0');
        this.clientListItem = clientList;
        this.countryList = countryList;
        this.referralSourceList = referralSourceList;
        this.caseRegionList = caseRegionList;
        this.companyLocationList = companyLocationList;
        this.caseTypeList = caseTypeList;
        
        
        if (this.caseRegionList.length > 0) {
          const defaultregionList = this.caseRegionList.filter(
            (x) => x.isSetAsDefaultRegion
          );
          if (defaultregionList.length === 1) {
            this.form.controls.caseRegionId.setValue(defaultregionList[0].id);
          }
        }
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => this.blockUI.stop()
    );

  }
  // popup for add new client
  openNewClientPopup(): void {
    const subscription = this.modalService
      .openNewClientPopup()
      .subscribe((data) => {
        this.loadClients();
      });
    this.subscriptions.push(subscription);
  }

  // popup for add new contact
  openClientAddContactPopup(): void {
    this.clientService.clientDetailSubject$.next({
      id: this.clientDetail.id,
      name: this.clientDetail.value,
    });
    const subscription = this.modalService
      .openClientContactPopup()
      .subscribe((data) => {
        this.loadClientContact(this.clientDetail.id);
      });
    this.subscriptions.push(subscription);
  }

  // popup for add new location
  openClientAddLocationPopup(): void {
    this.modalService
      .openAddLocationContactPopup(this.clientDetail.id)
      .subscribe((data) => {
        this.loadClientLocation(this.clientDetail.id);
      });
  }
  // load clients
  loadClients(): void {
    this.blockUI.start();
    const subscription = this.clientService.getClientList().subscribe(
      (clientList) => {
        this.clientListItem = clientList;
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => this.blockUI.stop()
    );
    this.subscriptions.push(subscription);
  }

  loadClientLocation(clientId: number): void {
    this.blockUI.start();
    const subscription = this.locationService
      .getLocationListByClientId(clientId)
      .subscribe(
        (clientLocationList) => {
          if (clientLocationList.length > 0) {
            this.clientLocationListItem = clientLocationList;
            const primaryLoc = clientLocationList.filter((x) => x.isPrimary);
            if (primaryLoc.length > 0) {
              this.form.controls.clientLocationId.setValue(primaryLoc[0].id);
              this.form.controls.clientLocation.setValue(primaryLoc[0].value);
            } else {
              this.form.controls.clientLocationId.setValue(
                clientLocationList[0].id
              );
              this.form.controls.clientLocation.setValue(
                clientLocationList[0].value
              );
            }
          }
        },
        (error: any) => {
          this.toasterService.ProcessErrorResponse(error);
        },
        () => this.blockUI.stop()
      );
    this.subscriptions.push(subscription);
  }
  private loadStates(id: any): void {
    this.blockUI.start();
    this.countryService.getStates(id).subscribe(
      (data: any) => {
        this.stateSelectListItem = data;
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => {
        this.blockUI.stop();
      }
    );
  }
  loadClientContact(clientId: number): void {
    this.blockUI.start();
    const subscription = this.clientContactService
      .getContactListByClient(clientId)
      .subscribe(
        (clientContactList) => {
          if (clientContactList.length > 0) {
            this.clientContactListItem = clientContactList;
            this.form.controls.clientContactId.setValue(
              clientContactList[0].id
            );
            this.form.controls.clientContact.setValue(
              clientContactList[0].value
            );
          }
        },
        (error: any) => {
          this.toasterService.ProcessErrorResponse(error);
        },
        () => this.blockUI.stop()
      );
    this.subscriptions.push(subscription);
  }
  loadPanel2Fields(caseTypeId: number): void {
    this.blockUI.start();
    forkJoin([
      this.caseFlagService.getByCaseTypeId(caseTypeId),
      this.caseServicesService.getByCaseTypeId(caseTypeId),
      this.customFieldService.getByCaseTypeId(caseTypeId),
      this.clientService.getClient(this.clientDetail.id),
    ]).subscribe(
      ([caseFlagList, caseServiceList, customFieldList, clientDetailModel]) => {
        this.caseFlagList = caseFlagList;
        this.caseServiceList = caseServiceList;

        this.customFieldList = customFieldList;
        this.casePolicies = '';
        if (clientDetailModel && clientDetailModel.casePolicies) {
          this.casePolicies = clientDetailModel.casePolicies;
        }
        // add customfields
        this.defaultCustomFieldValues = [];
        const cf: FormArray = this.customFieldToFormArray;
        cf.clear();
        // manipulate data
        this.customFieldList.forEach((d, index: number) => {
          this.defaultCustomFieldValues.push(
            this.appUtils.isJSON(d.defaultJsonValue)
              ? JSON.parse(d.defaultJsonValue)
              : d.defaultJsonValue
          );
          cf.push(
            this.formBuilder.group({
              data: this.CreateControlSetDefaultValueForCustomField(
                d.fieldTypeId,
                d
              ),
              ...d,
            })
          );
        });
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => this.blockUI.stop()
    );
  }

  // tslint:disable-next-line: typedef
  CreateControlSetDefaultValueForCustomField(
    typeId: number,
    cfm: CustomFieldModel
  ) {
    switch (typeId) {
      case this.GetCustomFieldCaseType.Address:
        return this.formBuilder.group({
          country: cfm.required ? ['', Validators.required] : '',
          address1: [''],
          address2: [''],
          address3: [''],
          city: [''],
          state: [''],
          zip_code: [''],
        });
        
      case this.GetCustomFieldCaseType.CountryDropdown:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.Date:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.DateBirthday:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.EmailAddress:
        return cfm.required
          ? [cfm.defaultJsonValue, [Validators.required,Validators.email]]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.InputLongAnswer:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.InputShortAnswer:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.Money:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.MultipleChoiceRadio:
        const mcr = JSON.parse(cfm.defaultJsonValue);
        const selectedArrays = mcr.filter(
          (x) => x.default_value_forMultiple == 'true'
        );
        if (selectedArrays.length > 0) {
          return cfm.required
            ? [selectedArrays[0].dform_multipleChoiceRadio, Validators.required]
            : [selectedArrays[0].dform_multipleChoiceRadio];
        }
        return cfm.required ? ['', Validators.required] : '';

      case this.GetCustomFieldCaseType.MultipleChoiceDropdown:
        const defaultJsonValue = JSON.parse(cfm.defaultJsonValue);
        const selectedArray = defaultJsonValue.filter(
          (x) => x.default_value_forMultiple == 'true'
        );
        if (selectedArray.length > 0) {
          return cfm.required
            ? [selectedArray[0].dform_multiplechoices, Validators.required]
            : [selectedArray[0].dform_multiplechoices];
        }
        return cfm.required ? ['', Validators.required] : '';

      case this.GetCustomFieldCaseType.NameFirstMiddleLast:
        return this.formBuilder.group({
          first: cfm.required ? ['', Validators.required] : '',
          middle: [''],
          last: cfm.required ? ['', Validators.required] : '',
        });

      case this.GetCustomFieldCaseType.Number:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.Section:
        return cfm.defaultJsonValue ? cfm.defaultJsonValue : '';
      case this.GetCustomFieldCaseType.WebsiteUrl:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.YesOrNo:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.YesNoOrNA:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.RatingMultipleChoiceDropdown:
        const rmcrs = JSON.parse(cfm.defaultJsonValue);
        const rmcr = rmcrs.filter(
          (x) => x.default_value_forRatingDrop == 'true'
        );
        if (rmcr.length > 0) {
          return cfm.required
            ? [rmcr[0].dform_ratingMultiDrop_scale, Validators.required]
            : [rmcr[0].dform_ratingMultiDrop_scale];
        }
        return cfm.required ? ['', Validators.required] : [''];
      case this.GetCustomFieldCaseType.RatingNumber:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.RatingYesNoOrNA:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      default:
        return '';
    }
  }

  goBacktoCases(): void{
    this.router.navigate(['/case-list']);
  }
  onChange(event) {
    debugger;
    
    this.selectedType = event.target.value;
    if(this.filterOptions.indexOf(this.selectedType) === -1){
      this.filterOptions.push(this.selectedType)
   
      console.log(this.filterOptions);
      if(this.selectedType=='Case Manager for Staff')
      {
        forkJoin([
          this.staffService.getCaseManagers() 
        ]).subscribe(([
          caseManagers  
        ]) => {
          this.managersSelectListItem = caseManagers;
        });
      }
      if(this.selectedType=='Salesperson')
      {
        forkJoin([
          this.staffService.getSalespersons() 
        ]).subscribe(([
          salesperson  
        ]) => {
          this.salespersonsSelectListItem = salesperson;
        });
      }
      if(this.selectedType=='Investigator')
      {
        forkJoin([
          this.staffService.getinvestigators() 
        ]).subscribe(([
          investigators  
        ]) => {
          this.investigatorSelectListItem = investigators;
        });
      }
    }
    

  }

  isExistsInFilterOptions=(item)=>{
    if(this.filterOptions.indexOf(item) >= 0){
      return true;
    }
    else return false;
  }
}
