import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

// for datatable
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { StaffLicenseService } from 'src/app/services/staff.license.service';
import { DateFormatPipe } from 'src/app/helpers/pipes/date-format.pipe';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ModalService } from 'src/app/services/modal.service';
import { StaffLicenseRenewModel } from 'src/app/models/staff-license/staff.license.renew.model';
import { StaffLicenseDtColumnName } from 'src/app/helpers/constants/staff/staff.licenses.column.name';
import { DatePipe } from '@angular/common';
import { HasPermission } from 'src/app/helpers/has-permission.helper';
import { Permission } from 'src/app/helpers/enums/roles/permission';
@Component({
  selector: 'app-staff-license',
  templateUrl: './staff-license.component.html',
  styleUrls: ['./staff-license.component.css']
})
export class StaffLicenseComponent implements OnInit {

  staffId: string;
  constructor(
    private licenseService: StaffLicenseService,
    private tosterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private datePipe: DatePipe) {
    this.route.parent.params.subscribe(params => this.staffId = params.id);
  }


  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: ADTSettings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;
  pipeInstance: any;
  permissionType = Permission;

  ngOnInit(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },

      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,
      order: [[0, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        self.blockUI.start();
        self.licenseService.query(dataTablesParameters, this.staffId)
          .subscribe(resp => {
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            self.blockUI.stop();
          });
      },

      columns: [
        {
          data: StaffLicenseDtColumnName.licenseName,
          title: 'Name',
          render: (data, type, row) => {
            const hrml = HasPermission.validate([Permission.ViewStaffLicenses]) ?
              `<a  detail-person-id="${row[StaffLicenseDtColumnName.id]}" class='link m-r-10' action-type='staff-license-detail'>${row[StaffLicenseDtColumnName.licenseName]}</a>`
              : `${row.name}`;
            return hrml;
          }
        },
        {
          data: StaffLicenseDtColumnName.state,
          title: 'State/Province'
        },
        {
          data: StaffLicenseDtColumnName.license,
          title: 'License'
        },
        {
          data: StaffLicenseDtColumnName.expiration,
          title: 'Expiration',
          render: (data, type, row) => {
            return `<span>${self.datePipe.transform(data, environment.dateTimeFormat.date)}</span>`;
          }
        },
        {
          data: null,
          title: 'Action ',
          orderable: false,
          render: (data, type, row) => {

            const editHtml = HasPermission.validate([Permission.EditStaffLicenses])
              ?
              `<button  class='btn del-btn btn-lg btn-table'>
                                        <i action-type="edit" class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
                                        </button>` : '';

            const renewHtml = HasPermission.validate([Permission.EditStaffLicenses])
              ?
              ` <button  class='btn del-btn btn-lg btn-table'>
                                    <i action-type="renew"  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-redo-alt fa-1x p-2 text-danger'></i>
                                    </button>` : ``;
            const deleteHtml = HasPermission.validate([Permission.DeleteStaffLicenses]) ? `<button  class='btn del-btn btn-lg btn-table'>
                          <i action-type="delete"
                          class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt
                           fa-1x p-2 text-danger'></i>
                          </button>` : '';

            return `<div class='fa-edit'>${editHtml} ${renewHtml} ${deleteHtml}</div>`;
          }
        },
      ],
      rowCallback: (row, data: any) => {
        const _row = $(row);
        _row.find('[action-type="staff-license-detail"]')
          .off('click')
          .on('click', () => {
            self.router.navigate(['/staff/license/show', data.id]);
          });

        _row.find('[action-type="edit"]')
          .off('click')
          .on('click', () => {
            self.licenseService.setReturnUrl(self.router.url);
            self.router.navigate(['edit/', data[StaffLicenseDtColumnName.id]], { relativeTo: self.route });
          });
        _row.find('[action-type="delete"]')
          .off('click')
          .on('click', () => {
            self.deleteLicense([data[StaffLicenseDtColumnName.id]]);
          });
        _row.find('[action-type="renew"]')
          .off('click')
          .on('click', () => {
            self.renewLicense(data.id, `${data.name} (${data.staffName})`, data[StaffLicenseDtColumnName.expiration]);
          });
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
    };
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtInstance = dtInstance;
      // const header = dtInstance.columns().header();
      // $(header).find('[action-type="select-unSelect-all"]')
      //     .off('change').on('change', (e: any) => {
      //         this.onSelectAllRow(e.target.checked);
      //     });
    });
  }

  private deleteLicense(ids: number[]): void {
    if (!confirm('Are you sure you want to delete selected license(s)?')) {
      return;
    }
    this.blockUI.start();
    this.licenseService.delete(ids).subscribe(() => {
      this.reload();
      this.tosterService.success('License(s) deleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }
  private renewLicense(id: number, modalHeader: string, expiresOn: string): void {
    this.modalService.openModalForRenewStaffLicense(modalHeader, expiresOn)
      .subscribe((model: StaffLicenseRenewModel) => {
        model.id = id;
        this.blockUI.start();
        this.licenseService.patch(model).subscribe((response) => {
          this.modalService.closeAllModals();
          this.dtInstance.ajax.reload(null, false);
          this.tosterService.success(response);
          this.blockUI.stop();
        });
      });
  }
  private reload(): void {
    this.dtInstance.ajax.reload();
  }

}
