export enum ColumnDetail {
    Name = 1,
    Title = 2,
    Email = 3,
    officePhone = 4,
    Tags = 5,
    AccountCode = 6,
    BirthDay = 7,
    StateOrProvince = 8
}

export enum ClientListColumnDetail {
    Name = 1,
    Address = 2,
    CaseManager = 3,
    Salesperson = 4,
    AccountCode = 5,
    Tag = 6,
}