import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ComponentDestoryHelper } from 'src/app/helpers/component-destory.helper';
import { CompanyPolicyFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/company-poicy.filter.options';
import { HistoryFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/history.search&filter.options';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HistoryService } from 'src/app/services/history.service';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent extends ComponentDestoryHelper implements OnInit, OnDestroy {

  @BlockUI('history-component-overview') blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  @Input() EntityId: string;
  @Input() ModuleId: number;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;
  dtRendered = true;
  isDtVisible = false;
  isDtInitialized = false;
  permissionType = Permission;
  isBulkActionDisabled = true;
  // for filters
  public filterOptionId: number = CompanyPolicyFilterOptions.None;
  private filterByItemId = '';
  private searchBy = -1;
  private searchFor = '';
  filterBy: any;
  formodel: string;
  frm: FormGroup;
  filterOptions: any;
  // capture filter open and close events
  filterNavStatus = false;
  constructor(private historyService: HistoryService,
              private tosterService: ToasterService, private datePipe: DatePipe,
              private router: Router,
              public cdr: ChangeDetectorRef) {
                super();
              }

  ngOnDestroy(): void {
    this.ngAfterOnDestroy();
  }

  closeFilterEvent(event: any): void {
    this.filterNavStatus = event;
  }

  ngOnInit(): void {
    this.initDataTable();
  }
  // tslint:disable-next-line: use-lifecycle-interface
  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtInstance = dtInstance;
    });
  }
  // private methods
  private initDataTable(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      destroy: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },
      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,
      order: [[0, 'desc']],
      autoWidth: false,
      ajax: (dataTablesParameters: any, callback) => {
        self.blockUI.start();
        dataTablesParameters.filterOptionId = this.filterOptionId;
        dataTablesParameters.filterItemId = this.filterByItemId;
        dataTablesParameters.moduleId = this.ModuleId;
        dataTablesParameters.entityId = this.EntityId;
        dataTablesParameters.searchBy = this.searchBy;
        dataTablesParameters.searchFor = this.searchFor;
        const subscription = self.historyService.query(dataTablesParameters)
          .subscribe(resp => {
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            self.blockUI.stop();
          });
        self.subscriptions.push(subscription);
      },
      columns: [
        {
          data: 'date',
          name: 'date',
          title: 'Date',
          render: (data, type, row) => {
            const html = `<span>${self.datePipe.transform(data, environment.dateTimeFormat.dateTime)}<span>`;
            return html;
           }
        },
        {
          data: 'actionId',
          name: 'actionId',
          title: 'Action',
          render: (data, type, row) => {
            return row.action;
           }
        },
        {
          data: 'categoryId',
          name: 'categoryId',
          title: 'Category',
          render: (data, type, row) => {
            return row.category;
           }
        },
        {
          data: 'details',
          name: 'details',
          title: 'Details',
          render: (data, type, row) => {
            if (row.linkDetails){
            return `<a  detail-person-id="${row.linkDetails}" class='link m-r-10' action-type='show-detail'>${row.details}</a>`;
            }
            else{
              return row.details;
            }
           }
        },
        {
          data: 'user.name',
          name: 'user.name',
          title: 'Action By',
          render: (data, type, row) => {
            return `<a  detail-person-id="${row.userId}" class='link m-r-10' action-type='show-staff'>${row.userName}</a>`;
           }
        }
      ],
      rowCallback: (row, data: any) => {
        const _row = $(row);
        _row.find('[action-type = "show-staff"]')
          .off('click').on('click', (e: any) => {
           // method name
           self.showStaff(data.userId);
          });
        _row.find('[action-type = "show-detail"]')
          .off('click').on('click', (e: any) => {
           // method name
           self.showDetails(data.linkDetails);
          });
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
    };
  }
  // for filters
  applyFilter(filterData: any): void {
    this.searchBy = Number(filterData.searchBy);
    this.searchFor = filterData.searchFor;
    const filterIdArr = filterData.filter?.split('_');
    if (filterIdArr && filterIdArr.length > 0) {
      this.filterOptionId = Number(filterIdArr[0]);
      if (filterIdArr.length > 1) {
        this.filterByItemId = filterIdArr[1];
      }
    }
    this.dtInstance.ajax.reload();
  }

  onResetFilters(): void {
    this.filterOptionId = HistoryFilterOptions.None;
    this.filterByItemId = '';
    this.searchBy = -1;
    this.searchFor = '';
    this.dtInstance.ajax.reload();
  }

  showStaff(userId: string): void {
    this.router.navigate([`/staff/${userId}/overview`]);
  }
  showDetails(linkDetails: string): void {
    this.router.navigate([linkDetails]);
  }
}
