<main class="content py-0" >
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-md-12 pt-7">
        <div class="w-100 pt-4">
          <div class="row">
            <div class="col-md-12">
              <app-breadcrumb></app-breadcrumb>
            </div>
          </div>
          <div class="row mt-4 mb-4">
            <div class="col-auto d-none d-sm-block">
              <h3 class="page-title">
                <strong>Edit Case Update Preset</strong>
              </h3>
            </div>
          </div>
          <div class="row mx-0"  *blockUI="'container-blockui-edit-case-update'">
            <div class="col-12 px-0 mb-5" >
              <form
                class="new-case-form"
                name="form"
                [formGroup]="form"
                (ngSubmit)="onSubmit()"
              >
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="card-text">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <div class="row form-error">
                                      <div class="col-md-12">
                                        <label for="name"
                                          >Name<sup class="clsStric">*</sup
                                          ><i
                                            class="
                                              align-middle
                                              fas
                                              fa-info-circle
                                              ml-2
                                              text-light-black
                                            "
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            placement="top"
                                            ngbTooltip="The name will appear in the preset selection menu when creating a new case update."
                                          ></i
                                        ></label>
                                        <input
                                          name="name"
                                          type="text"
                                          class="form-control mb-3"
                                          formControlName="name"
                                          placeholder=""
                                          autocomplete="off"
                                        />
                                        <div
                                          *ngIf="
                                            submitted &&
                                            form.controls.name.errors
                                          "
                                        >
                                          <div
                                            *ngIf="
                                              submitted &&
                                              form.controls.name.errors.required
                                            "
                                            class="text-danger"
                                          >
                                            Name is required
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <label for="access"
                                          >Access Group<i
                                            class="
                                              align-middle
                                              fas
                                              fa-info-circle
                                              ml-2
                                              text-light-black
                                            "
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            placement="top"
                                            ngbTooltip="Preset will only be available when the selected access group is used on an update. Leave blank to make this preset available for all access groups."
                                          ></i
                                        ></label>
                                        <select
                                          name="access"
                                          formControlName="access"
                                          class="form-control"
                                        >
                                          <option value="" selected></option>
                                          <option
                                            *ngFor="
                                              let item of accessGroupSelectListItem
                                            "
                                            [value]="item.id"
                                          >
                                            {{ item.value }}
                                          </option>
                                        </select>

                                        <div
                                          class="
                                            input-group-append
                                            position-absolute
                                            append-btn
                                          "
                                        >
                                          <button
                                            class="border-0 bg-transparent"
                                            type="button"
                                          >
                                            <i
                                              class="
                                                text-white
                                                rounded-circle
                                                bg-orange
                                                align-middle
                                              "
                                              data-feather="chevron-down"
                                            ></i>
                                          </button>
                                        </div>
                                      </div>
                                      <div class="col-md-12">
                                        <!-- place your validation html here and use is-invalid for input color to red -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <hr />
                              <br />
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <div class="row form-error">
                                      <div class="col-md-12">
                                        <label for="title"
                                          >Title<sup class="clsStric">*</sup
                                          ><i
                                            class="
                                              align-middle
                                              fas
                                              fa-info-circle
                                              ml-2
                                              text-light-black
                                            "
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            placement="top"
                                            ngbTooltip="Populates the 'Title' of the case update. You can use variables in this field."
                                          ></i
                                        ></label>
                                        <input
                                          name="title"
                                          type="text"
                                          class="form-control mb-3"
                                          formControlName="title"
                                          placeholder=""
                                          autocomplete="off"
                                        />

                                        <input
                                          id="custom_title"
                                          name="pin_note"
                                          formControlName="custom_title"
                                          class="mt-3"
                                          type="checkbox"
                                          (click)="OnonCustomTitleClick($event)"
                                        />
                                        <label for="custom_title">
                                          &nbsp;Custom Title</label
                                        >
                                      </div>
                                      <div
                                        *ngIf="
                                          submitted && form.controls.title.errors &&
                                          form.controls.title.errors.required
                                        "
                                        class="text-danger ml-3"
                                      >
                                        Title is required
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-12 mb-4">
                                  <label
                                    >Body<sup class="clsStric">*</sup
                                    ><i
                                      class="
                                        align-middle
                                        fas
                                        fa-info-circle
                                        ml-2
                                        text-light-black
                                      "
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      placement="top"
                                      ngbTooltip="Populates the 'Body' of the case update. You can use variables in this field."
                                    ></i
                                  ></label>
                                  <textarea
                                    class="form-control p-3"
                                    name="body"
                                    id="body"
                                    rows="7"
                                    placeholder="Note"
                                    formControlName="body"
                                  ></textarea>
                                  <div
                                    *ngIf="
                                      submitted && form.controls.body.errors &&
                                      form.controls.body.errors.required"
                                    class="mt-3 ml-1 text-danger"
                                  >
                                    Body is required
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <label for="variables"
                                          >Variables<i
                                            class="
                                              align-middle
                                              fas
                                              fa-info-circle
                                              ml-2
                                              text-light-black
                                            "
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            placement="top"
                                            ngbTooltip="Select a variable from the variable list to insert it into the title or body of this preset."
                                          ></i
                                        ></label>
                                        <select
                                          name="variables"
                                          formControlName="variables"
                                          class="form-control"
                                          (change)="onVariableChange($event)"
                                        >
                                        <option [value]="item.value" *ngFor="let item of variableData">{{item.key}}</option>
                                         
                                        </select>

                                        <div
                                          class="
                                            input-group-append
                                            position-absolute
                                            append-btn
                                          "
                                        >
                                          <button
                                            class="border-0 bg-transparent"
                                            type="button"
                                          >
                                            <i
                                              class="
                                                text-white
                                                rounded-circle
                                                bg-orange
                                                align-middle
                                              "
                                              data-feather="chevron-down"
                                            ></i>
                                          </button>
                                        </div>
                                      </div>
                                      <div class="col-md-12">
                                        <!-- place your validation html here and use is-invalid for input color to red -->
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <hr />

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="attachment"
                                      >Restrict use of this preset to user roles
                                      in the selected access group
                                    </label>
                                    <select
                                      name="restrict_use"
                                      formControlName="restrict_use"
                                      class="form-control"
                                    >
                                      <option value="" selected></option>
                                      <option
                                        *ngFor="
                                          let item of accessGroupSelectListItem
                                        "
                                        [value]="item.id"
                                      >
                                        {{ item.value }}
                                      </option>
                                    </select>
                                  </div>
                                  <small
                                    >Leave blank to allow access to users with a
                                    role in any access group.</small
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group w-100">
                    <div class="col-lg-12 text-right">
                      <button
                        class="
                          btn
                          font-weight-500
                          px-4
                          py-2
                          bg-white
                          text-dark-blue
                          border-btn
                        "
                        type="button"
                        (click)="onCancel()"
                      >
                        Cancel
                      </button>
                      <button
                        class="
                          btn
                          bg-blue
                          text-white
                          px-4
                          py-2
                          font-weight-500
                          ml-2
                        "
                        type="submit"
                      >
                        Save Preset <span class="fa fa-arrow-right"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
