import { Component, OnInit } from '@angular/core';

import {FormBuilder, FormGroup,FormControl,Validators, FormArray} from '@angular/forms';
import { Z_ERRNO } from 'zlib';

@Component({
  selector: 'app-new-invoice-rule',
  templateUrl: './new-invoice-rule.component.html',
  styleUrls: ['./new-invoice-rule.component.css']
})
export class NewInvoiceRuleComponent implements OnInit {

  constructor( private formBuilder:FormBuilder) { }

  form:FormGroup;
  submitted = false;
  users= [{
  
    "id":1,
    "name": "Paul"
  },
  { 
    "id":2,
    "name":"rahul"
  }];

  compare= [{
  
    "id":1,
    "name": "less than"
  },
  { 
    "id":2,
    "name":"greater than"
  },
  { 
    "id":3,
    "name":"equal to"
  }];

  

  perdaydata=[{
  
    "id":1,
    "name": "Paul"
  },
  { 
    "id":2,
    "name":"rahul"
  }];
  ngOnInit(): void {

    this.form=new FormGroup({
      name: new FormControl(),
      zero:new FormControl({value:'0',disabled:false}),
      is:new FormControl(),
      perday:new FormControl(),
      condition: new FormControl(),
      generalNotes: new FormControl(),
      attachment: new FormControl(),
      validatethis: new FormArray([new FormControl(),new FormControl()])
    })

    
  }


}
