import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { AddCompanyPolicyModel } from 'src/app/models/company-policy/addCompanyPolicyModel';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { CompanyPolicyService } from 'src/app/services/company-policy.service';
import { RoleService } from 'src/app/services/role.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';

@Component({
  selector: 'app-new-company-policy',
  templateUrl: './new-company-policy.component.html',
  styleUrls: ['./new-company-policy.component.css']
})
export class NewCompanyPolicyComponent implements OnInit {

  @BlockUI('container-blockui-new-folder-template')blockUI: NgBlockUI;
  model: AddCompanyPolicyModel;
  constructor(
      private formBuilder: FormBuilder,
      private toasterService: ToasterService,
      private router: Router,
      private roleService: RoleService,
      private companyPolicyService: CompanyPolicyService
      ) {
        this.model = new AddCompanyPolicyModel();
      }

  form: FormGroup;
  submitted = false;
  isSelected = false;

  get companyRolesToFormArray(): FormArray{
    return this.form.controls.company_roles as FormArray;
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      company_roles: new FormArray([], minSelectedCheckboxes()),
      description: new FormControl()
    });
    this.loadData();
  }

  private loadData(): void {
    this.blockUI.start();
    forkJoin([
      this.roleService.getRoles(),
    ]).subscribe(([
      roles
    ]) => {
     const ct: FormArray = this.companyRolesToFormArray;
     ct.clear();
     roles.forEach((d) => {
       ct.push(
         this.formBuilder.group({
           id: [d.id],
           name: [d.value],
           isSelected: [false],
         })
       );
     });
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.onCancel();
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
  }


  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.id = 0;
    this.model.name = this.form.controls.name.value;
    this.model.description = this.form.controls.description.value;
    this.model.roleIds = this.form.value.company_roles.filter(x => x.isSelected).map(x => x.id);
    this.blockUI.start();
    this.companyPolicyService.post(this.model).subscribe(resposne => {
      setTimeout(() => {
        this.onCancel();
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Company policy created successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }

  onCancel(): void {
    this.router.navigate(['/setting/role/company-policies']);
  }
}
