<div class="row">
    <div class="col-12" *blockUI="'version-history-policy-overview'">
     
        <div class="row" >
            <div class="col-md-9">
                <div class="card">
                    <div class="card-body">
                      
                        <div class="row  mb-4">
                            <div class="col-md-3 da-font-bold font-weight-bold">Name</div>
                            <div class="col-md-9">{{companyPolicyDetailData.name}}</div>
                        </div>
                        <div class="row  mb-4">
                            <div class="col-md-3 da-font-bold font-weight-bold">Current Version</div>
                            <div class="col-md-9">{{currentVersion}}</div>
                        </div>
                        <div class="row  mb-4">
                            <div class="col-md-3 da-font-bold font-weight-bold">Description</div>
                            <div class="col-md-9">{{companyPolicyDetailData.description}}</div>
                        </div>
                        <hr/>
                        <div class="row  mb-4">
                            <div class="col-md-3 da-font-bold font-weight-bold">Included Roles</div>
                            <div class="col-md-9">{{companyPolicyDetailData.roles}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="row ">
                    <div class="card">
                         <div class="card-body">
                            <div class="card-title">
                               <h5 class="m-0 font-weight-bold "> Current Version Compliance</h5>
                               
                            </div>
                            <div class="policy-chart">
                                <div>
                                    <div>
                                      <div class="chart">
                                        <canvas baseChart
                                          [data]="pieChartData"
                                          [labels]="pieChartLabels"
                                          [chartType]="pieChartType"
                                          [options]="pieChartOptions"
                                          [plugins]="pieChartPlugins"
                                          [colors]="pieChartColors"
                                          [legend]="pieChartLegend">
                                        </canvas>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="my-auto d-flex justify-content-center">
                                    <!-- <button class="btn btn-primary mt-2" (click)="pieChartLegend=!pieChartLegend">Toggle Legend</button> -->
                                  
                                   
                                  </div>
                            </div>
                            <p>0 of 7 users</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr/>

        <div class="row" >
            <div class="col-12">
                <h5 class="font-weight-bold">Version History</h5>
            </div>
            
          </div>
                    
                        <div class="row">
                         
                            
            
                          <div class="col-auto ml-auto text-right mt-n1">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                             
                                    <li class="filter-item">
                                        <div class="btn-group dropleft dropleft-btn">
                                            <div ngbDropdown class="d-inline-block">
                                              <button *ngIf="filterOptionId !== companyPolicyVersionHistoryFilterOptions.Deleted" class="btn btn-lg btn btn-danger" (click) = "onBulkDelete()"  [disabled]="isBulkActionDisabled" id="dropdownBasic1">Delete</button>  
                                              <button *ngIf="filterOptionId === companyPolicyVersionHistoryFilterOptions.Deleted" class="btn btn-lg btn btn-danger" (click) = "onBulkUnDelete()"  [disabled]="isBulkActionDisabled" id="dropdownBasic1">Undelete</button>  
                                              
                                              </div>
                                        </div>
                                     </li>
                                    <li class="filter-item px-0 filter-icon"  (click)="filterNavStatus = true">
                                        <button class="btn btn-lg bg-blue text-white">
                                            <i class="align-middle fas fa-filter"></i>
                                        </button>
                                    </li>
                                </ol>
                            </nav>
                        </div>
            
                        

                        <!-- Filter right sidenav -->
                        <app-policy-overiew-filter (onApplyFilter)="applyFilter($event)" 
                            (resetFilters)="onResetFilters()" [filterStatusParent]="filterNavStatus"  (closeBtnFilterStatus)="closeFilterEvent($event)"  ></app-policy-overiew-filter>
                        </div>
            
                      
              
                        
                          <div class="row">
                            <div class="col-md-12">
                              <table class="neu-table table table-striped neu-table w-100 table-responsive" datatable [dtOptions]="dtOptions" data-page="2" ></table>
                            </div>
                          </div>
            
                          
              
         
              
   
    </div>
</div>