import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NoteFor } from 'src/app/helpers/enums/notes/noteFor';

@Component({
  selector: 'app-client-location-notes',
  templateUrl: './client-location-notes.component.html',
  styleUrls: ['./client-location-notes.component.css']
})
export class ClientLocationNotesComponent implements OnInit {
  
  moduleId = NoteFor.ClientLocation;
  entityId: string;

  constructor(private route: ActivatedRoute) {
    this.route.parent.params.subscribe(params => this.entityId = params.locationId);
  }

  ngOnInit(): void {

  }

}
