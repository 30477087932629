export const environment = {
  production: true,
  apiBaseUrl: 'https://test-cmpapi.aspcore.net/api',
  googleClientId: '762723070255-vqbqdeo7sh4ocd3g7vghb5p4fl4avm1a.apps.googleusercontent.com',
  paging: {
    defaultPageSize: 10,
    lengthMenu: [10, 15, 25, 50, 100]
  },
  dateTimeFormat: {
    date: 'yyyy-MM-dd',
    dateTime: 'yyyy-MM-dd h:mm:ss a',
    dateTime_minutes: 'yyyy-MM-dd h:mm a'
  }
};
