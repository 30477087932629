import { ContatcNoInEntity } from '../shared/contactNoInEntity';

export class EmployeeAddModel {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    title: string;
    managerId: string;
    role: string;
    address1: string;
    address2: string;
    address3: string;
    country: string;
    city: string;
    state: string;
    zip: string;
    fax: string;
    dob: string;
    accountCode: string;
    tags: string[];
    generalNotes: string;
    adminNotes: string;
    emailSignature: string;
    preferedServices: string;
    preferedRegions: string;
    isTrackLastLocation: boolean;
    profileImg: File;
    profileImgUrl: string;
    contactNos: ContatcNoInEntity<number>[] = [];
}
