<main class="content py-0">
 
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-12   pt-7">

                <div class="row">
                    <div class="col-md-12">
                        <app-breadcrumb></app-breadcrumb>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto d-none d-sm-block mb-3">
                        <h3 class="page-title"><strong>Edit Access Group
                        </strong> </h3>
                        
                    </div>
                </div>
                <div class="row mx-0" *blockUI="'container-blockui-new-access-group'">
                    <div class="col-12 px-0 mb-5">
                        <form class="new-case-form" name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card flex-fill w-100 mb-2">
                                        <div class="card-header rounded p-4 mob-px-4">
                                            <div class="row">
                                                <div class="col-md-6 mb-2">
                                                    <div class="form-group has-search">
                                                        <label id="Label-7x1"> Name<sup class="clsStric">*</sup></label>
                                                        <input type="text" class="form-control" id="Label-7x1" formControlName="name">
                                                        <div *ngIf="submitted && form.controls.name.errors">
                                                            <div *ngIf="submitted && form.controls.name.errors.required" class="text-danger">
                                                                    Name is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mb-2">
                                                    <div class="form-group has-search">
                                                        <label id="Label-8x1">Description</label>
                                                        <textarea class="form-control p-3" name="updatePolicies" id="updatePolicies" rows="5" formControlName="description" placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group has-search">
                                                        <label id="track">Required Validation?</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"   placement="top" ngbTooltip="If enabled, case updates created with this access group will require manual validation. If disabled, they will be automatically validated."></i>
                                                        <div>
                                                            <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                <input type="radio" id="isRequireValidationYes" class="custom-control-input" formControlName="isRequireValidation"  name="isRequireValidation" [value] ="true">
                                                                <label class="custom-control-label" for="isRequireValidationYes">Yes</label>
                                                            </div>
                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="isRequireValidationNo" class="custom-control-input" formControlName="isRequireValidation"  name="isRequireValidation" [value] ="false">
                                                                <label class="custom-control-label" for="isRequireValidationNo">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                               
                                                
                                                <div class="col-md-6">
                                                    <div class="form-group has-search">
                                                        <label id="track">Refresh "Last Update"?</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="When enabled, the system will refresh the 'Last Update' date, indicating that a substantive case update has been recently posted."></i>
                                                        <div>
                                                            <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                <input type="radio" id="isRefereshLastUpdateYes" class="custom-control-input" formControlName="isRefereshLastUpdate"  name="isRefereshLastUpdate" [value] = "true">
                                                                <label class="custom-control-label" for="isRefereshLastUpdateYes">Yes</label>
                                                            </div>
                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="isRefereshLastUpdateNo" class="custom-control-input" formControlName="isRefereshLastUpdate"  name="isRefereshLastUpdate" [value] = "false">
                                                                <label class="custom-control-label" for="isRefereshLastUpdateNo">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div class="col-md-6">
                                                    <div class="form-group has-search">
                                                        <label id="track">Include in Documents?</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"   placement="top" ngbTooltip="If enabled, case updates with this access group can be included in generated documents via the {Updates.list} variable."></i>
                                                        <div>
                                                            <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                <input type="radio" id="isIncludeInDocumentsYes" class="custom-control-input" formControlName="isIncludeInDocuments"  name="isIncludeInDocuments" [value] = "true">
                                                                <label class="custom-control-label" for="isIncludeInDocumentsYes">Yes</label>
                                                            </div>
                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="isIncludeInDocumentsNo" class="custom-control-input" formControlName="isIncludeInDocuments"  name="isIncludeInDocuments" [value] = "false">
                                                                <label class="custom-control-label" for="isIncludeInDocumentsNo">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                         
                                            <hr/>

                                            <div class="row">
                                                <div class="col-md-6 mb-2">
                                                    <label>Limit Access To:<sup class="clsStric">*</sup></label>
                                                    <div class="form-check mt-2"
                                                         formArrayName="limitAccessTo" >
                                                         <div class="form-check mt-2" *ngFor="let limitAccessTo of limitAccessToFormArray.controls; let i = index"
                                                                        [formGroupName]="i">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            formControlName="isSelected"
                                                                            id="chkPrevillage{{i}}">
                                                                        <label class="form-check-label"
                                                                            for="chkPrevillage{{i}}">
                                                                            {{limitAccessTo.value.name}}
                                                                        </label>
                                                                    </div>
                                                      </div>
                                                      <div *ngIf="submitted && form.controls.limitAccessTo.errors">
                                                          <div *ngIf="submitted && form.controls.limitAccessTo.errors.required" class="text-danger">
                                                            Please select atleast one role
                                                          </div>
                                                      </div>
                                                </div>
                                              
                                            </div>

                                            


                                        </div>
                                    </div>
                                </div>

                                <div class="form-group w-100">
                                    <div class="col-lg-12 text-right">
                                        <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button" routerLink="/setting/accessgroup/list"> Cancel</button> 
                                        <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="submit">Save<span class="fa fa-arrow-right"></span></button> 
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
                


                </div>
         </div>
    </div>
</main>
