import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';
import { AddSubjectTypeModel } from '../models/subjectTypes/addSubjectTypeModel';
import { EditSubjectTypeModel } from '../models/subjectTypes/editSubjectTypeModel';
import { SubjectTypeViewModel } from '../models/subjectTypes/subjectTypeViewModel';

@Injectable({
  providedIn: 'root'
})
export class SubjectTypeService {

constructor(private http: HttpClient) { }
public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
  return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/subjecttype/query`, dataTablesParameters, {});
}

public post(model: AddSubjectTypeModel): Observable<string> {
  return this.http.post<string>(`${environment.apiBaseUrl}/subjecttype/post`, model, {});
}

public get(id: number): Observable<SubjectTypeViewModel> {
  return this.http.get<SubjectTypeViewModel>(`${environment.apiBaseUrl}/subjecttype/${id}`);
}
public getAll(): Observable<SubjectTypeViewModel[]> {
  return this.http.get<SubjectTypeViewModel[]>(`${environment.apiBaseUrl}/subjecttype/get`);
}

public getByIds(ids: number[]): Observable<SubjectTypeViewModel[]> {
  return this.http.get<SubjectTypeViewModel[]>(`${environment.apiBaseUrl}/subjecttype/getByIds?ids=${ids.join('&ids=')}`);
}
public put(model: EditSubjectTypeModel): Observable<SubjectTypeViewModel> {
  return this.http.put<SubjectTypeViewModel>(`${environment.apiBaseUrl}/subjecttype/put`, model, {});
}
public delete(ids: number[]): Observable<any> {
  return this.http.delete(`${environment.apiBaseUrl}/subjecttype/delete?ids=${ids.join('&ids=')}`);
}
public undelete(ids: number[]): Observable<any> {
  return this.http.delete(`${environment.apiBaseUrl}/subjecttype/undeleted?ids=${ids.join('&ids=')}`);
}
}
