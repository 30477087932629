import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { AppUtils } from 'src/app/helpers';
import { LocationAddModel } from 'src/app/models/Location/location.add.model';
import { LocationDetailModel } from 'src/app/models/Location/location.detail.model';
import { CountryModel } from 'src/app/models/shared/country.model';
import { StateModel } from 'src/app/models/shared/state.model';
import { CountryService } from 'src/app/services/country.service';
import { LocationService } from 'src/app/services/location.service';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-edit-location',
  templateUrl: './edit-location.component.html',
  styleUrls: ['./edit-location.component.css']
})
export class EditLocationComponent implements OnInit {
  @BlockUI('container-blockui-location') blockUI: NgBlockUI;
  countrySelectListItem: CountryModel[] = [];
  stateSelectListItem: StateModel[] = [];
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  loadBreadCrumb = false;
  returnUrl: string;

  constructor(private formBuilder: FormBuilder,
    private countryService: CountryService,
    private toasterService: ToasterService,
    private locationService: LocationService,
    private router: Router,
    private staffService: StaffService,
    private route: ActivatedRoute,
    public appUtil: AppUtils) {
    this.model = new LocationDetailModel();
    this.route.params.subscribe(param => this.model.id = Number(param.locationId));
    this.returnUrl = this.locationService.getReturnUrl();
  }

  form: FormGroup;
  submitted = false;
  caseManagers: any;
  salesPersons: any;
  model: LocationDetailModel;
  isShowPrimaryDiv: boolean;
  isContactLoaded = false;

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['',
        Validators.required
      ],
      country: ['', Validators.required
      ],
      salesPersonId: [''],
      caseManagerId: [''],
      isPrimary: false,
      address1: [''],
      address2: [''],
      address3: [''],
      city: [''],
      state: [''],
      zip: [''],
      fax: [''],
      webSite: [''],
      billingEmail: [''],
      generalNotes: [''],
      casePolicies: [''],
      updatePolicies: [''],
      invoicePolicies: [''],
    });
    this.loadData();
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all required fields');
      return;
    }

    const editModel = new LocationAddModel();
    editModel.id = this.model.id;
    editModel.clientId = this.model.clientId;
    editModel.name = this.form.value.name;
    editModel.isPrimary = this.form.value.isPrimary as boolean;
    editModel.country = this.form.value.country;
    editModel.address1 = this.form.value.address1;
    editModel.address2 = this.form.value.address2;
    editModel.address3 = this.form.value.address3;
    editModel.city = this.form.value.city;
    editModel.state = this.form.value.state;
    editModel.zipCode = this.form.value.zip;
    editModel.primaryPhone = this.form.value.primaryPhone;
    editModel.secondaryPhone = this.form.value.secondaryPhone;
    editModel.fax = this.form.value.fax;
    editModel.salesPersonId = this.form.value.salesPersonId;
    editModel.caseManagerId = this.form.value.caseManagerId;
    editModel.billingEmail = this.form.value.billingEmail;
    editModel.generalNotes = this.form.value.generalNotes;
    editModel.casePolicies = this.form.value.casePolicies;
    editModel.updatePolicies = this.form.value.updatePolicies;
    editModel.invoicePolicies = this.form.value.invoicePolicies;
    this.form.value.dynamicRows.map((x: { phoneType: any; phoneNum: any; extn: any, id: any; }) =>
      editModel.contactNos.push({
        contactTypeId: Number(x.phoneType),
        contactNumber: x.phoneNum,
        extensionNote: x.extn ?? '',
        id: Number(x.id),
        entityId: 0
      })
    );
    this.locationService.edit(editModel).subscribe((data: string) => {
      this.router.navigate([this.returnUrl]);
      this.toasterService.success(data);
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    });
  }

  loadData(): void {
    forkJoin([
      this.staffService.getSalespersons(),
      this.staffService.getCaseManagers(),
      this.countryService.getCountries(),
      this.locationService.get(this.model.id),

    ]).subscribe(([
      salespersons,
      caseManagers,
      countries,
      location
    ]) => {
      this.salesPersons = salespersons;
      this.caseManagers = caseManagers;
      this.countrySelectListItem = countries;
      this.model = location;
      this.isShowPrimaryDiv = location.isPrimary;
      this.initForm();
      this.breadCrumbInputs.push(...[
        { key: 'id', value: [this.model.clientId.toString(), this.model.clientName] },
        { key: 'locationId', value: [this.model.id.toString(), this.model.name] }
      ]);
      this.loadBreadCrumb = true;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate([this.returnUrl]);
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
  }

  private loadStates(name: any): void {
    const id: any = this.countrySelectListItem.find(x => x.name === name)?.id ?? undefined;
    if (id === null || id === undefined || id.length === 0) {
      return;
    }

    this.blockUI.start();
    this.countryService.getStates(id).subscribe((data: any) => {
      this.stateSelectListItem = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  private initForm(): void {
    this.form.controls.name.setValue(this.model.name);
    this.form.controls.isPrimary.setValue(this.model.isPrimary);
    this.form.controls.address1.setValue(this.model.address1);
    this.form.controls.address2.setValue(this.model.address2);
    this.form.controls.address3.setValue(this.model.address3);
    this.form.controls.country.setValue(this.model.country);
    this.loadStates(this.model.country);
    this.form.controls.state.setValue(this.model.state);
    this.form.controls.city.setValue(this.model.city);
    this.form.controls.zip.setValue(this.model.zipCode);
    this.form.controls.fax.setValue(this.model.fax);
    this.form.controls.salesPersonId.setValue(this.model.salesPersonId ?? '');
    this.form.controls.caseManagerId.setValue(this.model.caseManagerId ?? '');
    this.form.controls.billingEmail.setValue(this.model.billingEmail);
    this.form.controls.generalNotes.setValue(this.model.generalNotes);
    this.form.controls.updatePolicies.setValue(this.model.updatePolicies);
    this.form.controls.casePolicies.setValue(this.model.casePolicies);
    this.form.controls.invoicePolicies.setValue(this.model.invoicePolicies);
    this.isContactLoaded = true;
  }
}

