export enum CustomFieldTypes {
    Address = 1,
    CountryDropdown = 2,
    Date = 3,
    DateBirthday = 4,
    EmailAddress = 5,
    InputLongAnswer = 6,
    InputShortAnswer = 7,
    Money = 8,
    MultipleChoiceDropdown = 9,
    MultipleChoiceRadio = 10,
    NameFirstMiddleLast = 11,
    Number = 12,
    Section = 13,
    WebsiteUrl = 14,
    YesOrNo = 15,
    YesNoOrNA = 16,
    RatingMultipleChoiceDropdown = 17,
    RatingNumber = 18,
    RatingYesNoOrNA = 19
}
