import { TemplateBindingParseResult } from '@angular/compiler';
import { stringify } from '@angular/compiler/src/util';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-profile-pic-uploader',
  templateUrl: './profile-pic-uploader.component.html',
  styleUrls: ['./profile-pic-uploader.component.css']
})
export class ProfilePicUploaderComponent implements OnInit {

  imageChangeEvt: any;
  fileName: string;
  base64String: string;
  file: File;
  clonedImgSrc: any;

  @Input() imgSrc?: any;
  constructor(private modalService: ModalService) { }

  @Output() profileImgCropped = new EventEmitter<File>();

  @ViewChild('inputFile', { static: false })
  inputFileElem: ElementRef;

  ngOnInit(): void {
    this.clonedImgSrc = this.imgSrc?.slice();

    this.imgSrc = this.imgSrc ?? '/assets/img/avatars/user.svg';
  }

  onFileSelected(event: any): void {
    const fileList = event.target.files as FileList;
    if (!fileList || fileList.length === 0) {
      return;
    }
    this.imageChangeEvt = event;
    this.fileName = fileList.item(0).name;

    const instance = this.modalService.openModalForImageCropper(fileList.item(0));
    instance.croppedImage.subscribe((e: ImageCroppedEvent) => {
      this.imgSrc = e.base64;
      const file = base64ToFile(e.base64) as any;
      file.name = this.fileName;
      this.file = file as File;
    });
    instance.saveCroppedImage.subscribe((saveCroppedImage: boolean) => {
      if (saveCroppedImage) {
        this.profileImgCropped.emit(this.file);
      } else {
        this.imgSrc = this.clonedImgSrc;
      }
      this.modalService.closeAllModals();
      this.inputFileElem.nativeElement.value = '';
    });
  }

  onImageCropped(event: ImageCroppedEvent): void {
    this.imgSrc = event.base64;
    const file = base64ToFile(event.base64) as any;
    file.name = this.fileName;
    this.file = file;
  }

}
