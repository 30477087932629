<div class="modal-header">
  <h5 class="modal-title">{{ modalConfig.modalTitle }}</h5>

  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <ng-content></ng-content>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-secondary" (click)="dismiss()"
    *ngIf="modalConfig.hideDismissButton === undefined || !modalConfig.hideDismissButton()"
    [disabled]="modalConfig.disableDismissButton !== undefined && modalConfig.disableDismissButton()">
    {{ modalConfig.dismissButtonLabel }}
  </button>
  <button type="button" class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" (click)="close()"
    *ngIf="modalConfig.hideCloseButton === undefined || !modalConfig.hideCloseButton()"
    [disabled]="modalConfig.disableCloseButton !== undefined && modalConfig.disableCloseButton()">
    {{ modalConfig.closeButtonLabel }}
  </button>
</div>