import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { AddOrEditFolderTemplateModel } from 'src/app/models/folder-template/add.folder-template.model';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { CasetypeService } from 'src/app/services/casetype.service';
import { FolderTemplateService } from 'src/app/services/foldertemplate.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';
@Component({
  selector: 'app-new-folder-template',
  templateUrl: './new-folder-template.component.html',
  styleUrls: ['./new-folder-template.component.css']
})
export class NewFolderTemplateComponent implements OnInit {
  accessGroupSelectListItem: SelectListItem<number>[] = [];
  @BlockUI('container-blockui-new-folder-template')blockUI: NgBlockUI;
  model: AddOrEditFolderTemplateModel;

  constructor(private formBuilder: FormBuilder,
              private accessGroupService: AccessGroupService,
              private toasterService: ToasterService,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private folderTemplateService: FolderTemplateService,
              private caseTypeService: CasetypeService){

              this.model = new AddOrEditFolderTemplateModel();
              this.activeRoute.params.subscribe(params => this.model.id = params.id);
               }

  form: FormGroup;
  submitted = false;

  // users = [{ }];
  addFolderTemplateModel: AddOrEditFolderTemplateModel = new AddOrEditFolderTemplateModel();

get caseTypesToFormArray(): FormArray{
  return this.form.controls.case_types as FormArray;
}

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      access_grp: ['', [Validators.required]],
      default_notes: [''],
      case_types: new FormArray([], minSelectedCheckboxes())
    });
    this.loadDefaultData();
  }

  private async loadDefaultData(): Promise<void> {
    this.blockUI.start();
    forkJoin([this.caseTypeService.get(),
      this.accessGroupService.getAccessGroups()]).subscribe(
      ([caseTypesdata, accessGroupsdata]) => {
       // loading case types
       const ct: FormArray = this.caseTypesToFormArray;
       ct.clear();
       caseTypesdata.forEach((d) => {
         ct.push(
           this.formBuilder.group({
             id: [d.id],
             name: [d.name],
             isSelected: [false],
           })
         );
       });
        // loading access groups
       this.accessGroupSelectListItem = accessGroupsdata;
       const defaultAccessGroup = this.accessGroupSelectListItem.find(x => x.value);
       this.form.controls.access_grp.setValue(defaultAccessGroup.id);
       this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(
          error,
          'Something went wrong!'
        );
        this.blockUI.stop();
      }
    );
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.addFolderTemplateModel.name = this.form.controls.name.value;
    this.addFolderTemplateModel.accessGroupId = Number(this.form.controls.access_grp.value);
    this.addFolderTemplateModel.defaultNote = this.form.controls.default_notes.value;
    this.addFolderTemplateModel.caseTypeIds = this.form.value.case_types.filter(x => x.isSelected).map(x => x.id);
    this.blockUI.start();
    this.folderTemplateService.post(this.addFolderTemplateModel).subscribe(resposne => {
      setTimeout(() => {
        this.router.navigate(['setting/cases/folder-templates']);
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Folder template created successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }



}
