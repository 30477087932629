<div class="row">
  <div class="col-md-9">
    <div class="row">
      <div class="col-md-12">
        <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-3">
          <ngb-panel id="custom-panel-3">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0 font-weight-bold">General Information</h5>
                <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                    class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold font-weight-bold">Primary Office</div>
                <div class="col-md-9">{{primaryLocation.locationName}}</div>
              </div>
              <hr />
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold font-weight-bold">Salesperson</div>
                <div class="col-md-9">{{model.salesPersonName}} </div>
              </div>
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold font-weight-bold">Case Manager</div>
                <div class="col-md-9">{{model.caseManagerName}}</div>
              </div>
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold font-weight-bold">Account Code</div>
                <div class="col-md-9">{{model.accountCode}} </div>
              </div>
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold font-weight-bold">Website</div>
                <div class="col-md-9"><a href="{{model.webSite}}">{{model.webSite}}</a></div>
              </div>
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold  font-weight-bold">Billing Email</div>
                <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-5">
          <ngb-panel id="custom-panel-5">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0 font-weight-bold"><i
                    class="align-middle far fa-file-alt mr-2 fx-2 text-orange"></i>Client Notes & Policies</h5>
                <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                    class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row mb-4" *ngIf="model.pinnedNotes && model.pinnedNotes.length > 0">
                <div class="col-md-12">
                  <app-pinned-notes-list-view [pinnedNotes]="model.pinnedNotes"></app-pinned-notes-list-view>
                </div>
              </div>
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold font-weight-bold">General Notes</div>
                <div class="col-md-9">{{model.generalNotes}}</div>
              </div>
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold font-weight-bold">Case Policies</div>
                <div class="col-md-9">{{model.casePolicies}} </div>
              </div>
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold font-weight-bold">Update Policies</div>
                <div class="col-md-9">{{model.updatePolicies}}</div>
              </div>
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold font-weight-bold">Invoice Policies</div>
                <div class="col-md-9">
                  {{model.invoicePolicies}}
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
        <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-4">
          <ngb-panel id="custom-panel-4">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0 font-weight-bold"><i class="align-middle fas fa-tools mr-2 fx-2 text-orange"></i>Client
                  Settings</h5>
                <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                    class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold font-weight-bold">Send Overdue Invoices</div>
                <div class="col-md-9">{{model.sendOverdueInvoices ? "Yes" : "No"}}</div>
              </div>
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold font-weight-bold">Show "Bill-To" Office</div>
                <div class="col-md-9">{{model.show_Bill_To_Location ? "Yes" : "No"}} </div>
              </div>
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold font-weight-bold">Case Type Restrictions</div>
                <div class="col-md-9">18S22706</div>
              </div>
              <div class="row  mb-4">
                <div class="col-md-3 da-font-bold font-weight-bold">Tags</div>
                <div class="col-md-9">
                  <ng-select [multiple]="true" [(ngModel)]="model.tags" [ngClass]="'ngselectview'" [clearable]="false"
                    [disabled]="true" [searchable]="false">
                  </ng-select>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
  <div class="col-md-3">
    <div class="row " id="stickyoney">
      <div class="col-md-12">
        <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
          <ngb-panel id="custom-panel-1">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0  font-weight-bold">Client Balance</h5>
                <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                    class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row">
                <div class="col-md-12 mb-3">
                  <div class="row">
                    <div class="col-md-6 font-weight-bold">Outstanding</div>
                    <div class="col-md-6">$0.00</div>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="row">
                    <div class="col-md-6 font-weight-bold">Past Due</div>
                    <div class="col-md-6">$0.00</div>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <div class="row">
                    <div class="col-md-6 font-weight-bold">Retainer Balance</div>
                    <div class="col-md-6">$0.00</div>
                  </div>
                </div>
                <div class="col-md-12 mb-3">
                  <small>This Client usually pays in about 23 days.</small>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
      <div class="col-md-12 mt-3">
        <ngb-accordion #a="ngbAccordion">
          <ngb-panel id="custom-panel-2">
            <ng-template ngbPanelHeader let-opened="opened">
              <div class="d-flex align-items-center justify-content-between">
                <h5 class="m-0  font-weight-bold">Quick Links</h5>
                <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                    class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
              </div>
            </ng-template>
            <ng-template ngbPanelContent>
              <div class="row quicklinks">
                <div class="col-md-12 mb-3">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="list-group mb-3" *ngFor="let quick of quicklink; index as i; ">
                        <div class="list-group quickhead">
                          {{quick.mainmenu}}
                        </div>
                        <ul class="list-group">
                          <li class="list-group-item border-0" *ngFor="let submen of quicklink[i].submenu; index as x">
                            <a class="link" href="{{submen.link}}">{{submen.name}}</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </div>
    </div>
  </div>
</div>