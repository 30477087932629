<main class="content py-0">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-md-12 pt-7">
        <div class="w-100 pt-4">
          <div class="row">
            <div class="col-md-12">
              <app-breadcrumb></app-breadcrumb>
            </div>
          </div>

          <div class="row mt-4 mb-4">
            <div class="col-auto d-none d-sm-block">
              <h3 class="page-title"><strong>New Review</strong></h3>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-12 px-0 mb-5">
              <form class="new-case-form" name="form" [formGroup]="form">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="card-text">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="name"
                                      >Name<sup class="clsStric">*</sup></label
                                    >

                                    <input
                                      type="text"
                                      formControlName="name"
                                      class="form-control mb-3"
                                      id="name"
                                      name="name"
                                      autocomplete="off"
                                    />
                                    <div
                                    *ngIf="submitted && form.controls.name.errors">
                                    <div *ngIf="submitted && form.controls.name.errors.required"
                                        class="text-danger">
                                        Name is required
                                    </div>
                                </div>
                                  </div>
                                </div>

                                <div class="col-md-6"></div>
                              </div>

                              <hr />

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="access_grp"
                                      >Access Group<sup class="clsStric">*</sup
                                      ><i
                                        class="
                                          align-middle
                                          fas
                                          fa-info-circle
                                          ml-2
                                          text-light-black
                                        "
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        placement="top"
                                        ngbTooltip="A user must be a part of the selected access group in order to answer and/or complete the review."
                                      ></i
                                    ></label>
                                    <div class="input-group position-relative">
                                      <select
                                        class="form-control"
                                        formControlName="access_grp"
                                      >
                                        <option selected disabled>
                                          Select
                                        </option>
                                        <option *ngFor="let item of accessGroupSelectListItem"
                                          [value]="item.id"
                                        >
                                          {{ item.value }}
                                        </option>
                                      </select>
                                      <div
                                        *ngIf="
                                          submitted &&
                                          form.controls.access_grp.errors
                                        "
                                        class="text-danger"
                                      >
                                        <div
                                          *ngIf="form.controls.access_grp.errors.required"
                                        >
                                          Access Group is Required
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="form-group has-search">
                                      <label for="case_status"
                                        >Case Status<sup class="clsStric">*</sup
                                        ><i
                                          class="
                                            align-middle
                                            fas
                                            fa-info-circle
                                            ml-2
                                            text-light-black"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          placement="top"
                                          ngbTooltip="The review will become available when a case reaches this status."
                                        ></i
                                      ></label>
                                      <div class="input-group position-relative">
                                        <select
                                          class="form-control"
                                          formControlName="case_status"
                                        >
                                          <option selected disabled>
                                            Select
                                          </option>
                                          <option
                                            *ngFor="let item of caseStatusSelectListItem"
                                            [value]="item.id"
                                          >
                                            {{ item.name }}
                                          </option>
                                        </select>
                                       
                                      </div>
                                      <div
                                      *ngIf="
                                        submitted &&
                                        form.controls.case_status.errors"
                                      class="text-danger"
                                    >
                                      <div
                                        *ngIf="form.controls.case_status.errors.required"
                                      >
                                      Case Status is Required
                                      </div>
                                    </div>
                                    </div>
                                  </div>
                                </div>


                              
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="review_type"
                                      >Review Type<sup class="clsStric">*</sup
                                      ><i
                                        class="
                                          align-middle
                                          fas
                                          fa-info-circle
                                          ml-2
                                          text-light-black
                                        "
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        placement="top"
                                        ngbTooltip="Auto generated reviews are created for you on each case (1 per case, user, or task/event), where manual reviews are only created as needed."
                                      ></i
                                    ></label>

                                    <select
                                      class="form-control"
                                      formControlName="review_type"
                                    >
                                    <option selected disabled>
                                        Select
                                      </option>
                                      <option
                                      [value]="item.id"
                                        *ngFor="let item of reviewsTypes"
                                      >
                                        {{ item.name }}
                                      </option>
                                    </select>
                                  </div>
                                  <div
                                  *ngIf="
                                    submitted &&
                                    form.controls.review_type.errors"
                                  class="text-danger"
                                >
                                  <div
                                    *ngIf="form.controls.review_type.errors.required"
                                  >
                                  Review type is Required
                                  </div>
                                </div>
                                </div>
                              </div>
                              <hr />
                             
                                <!--------------------------->
                                <div class="row">
                                    <div class="col-md-6" *ngIf="form.controls.review_type.value == reviewTypes.AutoGenerateOnePerCaseAssignment || 
                                    form.controls.review_type.value == reviewTypes.AutoGenerateOnePerTaskOrAssigment ">
                                      <div class="form-group has-search">
                                        <label for="calendarId"
                                          >Calendar<i
                                            class="
                                              align-middle
                                              fas
                                              fa-info-circle
                                              ml-2
                                              text-light-black
                                            "
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            placement="top"
                                            ngbTooltip="Reviews will only be generated for tasks/events that are associated with the selected calendar."
                                          ></i
                                        ></label>
                                        <div class="input-group position-relative">
                                          <select
                                            class="form-control"
                                            formControlName="calendarId"
                                          >
                                            <option selected disabled>
                                              Select
                                            </option>
                                            <option *ngFor="let item of calendarListItem"
                                              [value]="item.id"
                                            >
                                              {{ item.name }}
                                            </option>
                                          </select>
                                        
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-md-6" *ngIf="
                                    form.controls.review_type.value == reviewTypes.AutoGenerateOnePerTaskOrAssigment ">
                                        <div class="form-group has-search">
                                          <label for="activityStatusId"
                                            >Activity Status<i
                                              class="
                                                align-middle
                                                fas
                                                fa-info-circle
                                                ml-2
                                                text-light-black"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              placement="top"
                                              ngbTooltip="Reviews will only be generated once a task/event reaches the specified status."
                                            ></i
                                          ></label>
                                          <div class="input-group position-relative">
                                            <select
                                              class="form-control"
                                              formControlName="activityStatusId"
                                            >
                                              <option selected disabled>
                                                Select
                                              </option>
                                              <option
                                                *ngFor="let item of activityStatusListItem"
                                                [value]="item.id"
                                              >
                                                {{ item.name }}
                                              </option>
                                            </select>
                                           
                                          </div>
                                       
                                        </div>
                                      </div>
                                    </div>
                                <!------------------------------->
                                <hr>

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="description">Description</label>
                                    <textarea
                                      formControlName="description"
                                      class="form-control p-3"
                                      name="description"
                                      id="description"
                                      rows="5"
                                      placeholder="Description"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                            <hr>

                              <div class="row">
                                <table class="table table-striped border">
                                  <thead>
                                    <tr>
                                      <th scope="col">Question</th>
                                      <th scope="col">
                                        Enable Date (?)
                                        <i
                                          class="
                                            align-middle
                                            fas
                                            fa-info-circle
                                            ml-2
                                            text-light-black
                                          "
                                        ></i>
                                      </th>
                                      <th></th>
                                    </tr>
                                  </thead>
                                  <tbody formArrayName="customReviewFields">
                                    <tr
                                      *ngFor="
                                        let itemrow of form.controls
                                          .customReviewFields['controls'];
                                        index as i;
                                        let l = last
                                      "
                                      [formGroupName]="i"
                                    >
                                      <td>
                                        <select
                                          class="form-control"
                                          formControlName="customReviewId"
                                        >
                                          <option
                                            *ngFor="
                                              let cc of customReview;
                                              index as i
                                            "
                                            [selected]="i == 0"
                                            [value]="cc.id"
                                          >
                                            {{ cc.label }} ::
                                            {{ cc.type }}
                                          </option>
                                        </select>
                                      </td>

                                      <td>
                                        <input
                                          type="checkbox"
                                          formControlName="isDateEnabled"
                                        />
                                      </td>

                                      <td>
                                        <button
                                          (click)="deleteRow(i)"
                                          class="border-0 btn btn-danger"
                                        >
                                          <i class="fas fa-trash-alt"></i>
                                        </button>
                                      </td>
                                      <td>
                                        <div
                                          *ngIf="
                                            submitted &&
                                            form.controls.customReviewFields
                                              .controls[i].controls
                                              .customReviewId.errors
                                          "
                                          class="text-danger w-100 d-block"
                                        >
                                          <div
                                            *ngIf="
                                              this.form.controls
                                                .customReviewFields.controls[i]
                                                .controls.customReviewId.errors
                                                .unique
                                            "
                                          >
                                            Duplicate custom review type are not
                                            allowed
                                          </div>
                                          <div
                                            *ngIf="
                                              this.form.controls
                                                .customReviewFields.controls[i]
                                                .controls.customReviewId.errors
                                                .required
                                            "
                                          >
                                            Custom review type is required
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                              <div class="row">
                                <div class="col-md-12">
                                    <button type="button"
                                        class="btn btn-primary"
                                        (click)="addNewRows()">Add
                                        Line </button>
                                    <button type="button"
                                        class="btn btn-primary ml-3"
                                        (click)="openCustomFieldPopup()">Add
                                        Custom Field</button>

                                </div>
                            </div>
                            <hr>
                              <div class="row">
                                <div class="col-md-6 mb-2">
                                  <label
                                    ><b
                                      ><sup class="clsStric">*</sup> Make this
                                      review available on the following case
                                      types:</b
                                    ></label
                                  >
                                  <div
                                    class="form-check mt-2"
                                    formArrayName="case_types"
                                  >
                                    <div
                                      class="form-check mt-2"
                                      *ngFor="
                                        let ct of caseTypesToFormArray.controls;
                                        let i = index
                                      "
                                      [formGroupName]="i"
                                    >
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        formControlName="isSelected"
                                        id="casetypes_{{ i }}"
                                      />
                                      <label
                                        class="form-check-label"
                                        for="lbl_casetypes_{{ i }}"
                                      >
                                        {{ ct.value.name }}
                                      </label>
                                    </div>
                                  </div>
                                  <div
                                    *ngIf="
                                      submitted &&
                                      form.controls.case_types.errors
                                    "
                                  >
                                    <div
                                      *ngIf="
                                        submitted &&
                                        form.controls.case_types.errors.required
                                      "
                                      class="text-danger"
                                    >
                                      Please select atleast one case type
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group w-100">
                    <div class="col-lg-12 text-right">
                      <button
                        class="
                          btn
                          font-weight-500
                          px-4
                          py-2
                          bg-white
                          text-dark-blue
                          border-btn
                        "
                        type="button"
                        (click)="onCancel()"
                      >
                        Cancel
                      </button>
                      <button
                        class="
                          btn
                          bg-blue
                          text-white
                          px-4
                          py-2
                          font-weight-500
                          ml-2
                        "
                        type="submit"
                        (click)="onSubmit()"
                      >
                        Save Review <span class="fa fa-arrow-right"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
