import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';

@Injectable()
export class AuthenticatedUserGuard implements CanActivate {

    constructor(private router: Router, private authenticationService: AuthenticationService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // If use is authenticated and token not expired
        // then proceed to page
        if (!this.authenticationService.isLoggedIn() || this.authenticationService.isTokenExpired()) {
            this.authenticationService.logout().subscribe(() => {
                this.router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
            });
            return false;
        }

        return true;
    }
}
