import { ModalService } from './../../../../services/modal.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { concat, forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap, catchError, map, filter } from 'rxjs/operators';
import { RoleAvailableFor } from 'src/app/helpers/enums/roles/role.available.for';
import { NgbDateFRParserFormatter } from 'src/app/helpers/ngb.date.parser';
import { ClientContactAddModel } from 'src/app/models/client-contact/client.contact.add.model';
import { CountryModel } from 'src/app/models/shared/country.model';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { ClientContactService } from 'src/app/services/client.contact.service';
import { ClientService } from 'src/app/services/client.service';
import { CountryService } from 'src/app/services/country.service';
import { LocationService } from 'src/app/services/location.service';
import { RoleService } from 'src/app/services/role.service';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';
import { PhoneCategory } from 'src/app/helpers/enums/phoneCategory/phoneCategoryFor';
import { ContatcNoInEntity } from 'src/app/models/shared/contactNoInEntity';
import { LocationSelectedListItem } from 'src/app/models/Location/location.selected.list.item';

@Component({
  selector: 'app-new-contact',
  templateUrl: './new-contact.component.html',
  styleUrls: ['./new-contact.component.css']
})
export class NewContactComponent implements OnInit, OnDestroy {

  clientContactsSubscription: Subscription;
  form: FormGroup;
  submitted = false;
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  loadBreadCrumb = false;
  managersSelectListItem: SelectListItem<string>[] = [];
  locationSelectListItem: LocationSelectedListItem[] = [];
  rolesSelectListItem: SelectListItem<string>[] = [];
  countrySelectListItem: CountryModel[] = [];
  salespersonSelectListItem: SelectListItem<string>[] = [];
  @BlockUI() blockUI: NgBlockUI;
  @Input() isForPopup;
  @Output() public saveClick = new EventEmitter();
  model: ClientContactAddModel;

  tags$: Observable<any>;
  tagsInput$ = new Subject<string>();

  public profileimg: string;
  public currentInput: string;
  fileBlob: string;
  fileName: string;
  locationId: number;
  dateStruct: NgbDateStruct;
  clientId: number;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private clientService: ClientService,
    private locationService: LocationService,
    private staffService: StaffService,
    private clientContactService: ClientContactService,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private countryService: CountryService,
    private ngbDateParserFormatter: NgbDateFRParserFormatter,
    private modalService: ModalService,
    private ngbActiveModal: NgbActiveModal
  ) {
    this.model = new ClientContactAddModel();
    if (!this.isForPopup){
    this.route.params.subscribe(param => this.model.clientId = Number(param.id));
    }
    this.clientContactsSubscription = this.clientService.clientListDetail$.subscribe(data => {
      if (data) {
        this.clientId = data.id;
        this.model.clientId = data.id;
        this.breadCrumbInputs.push({
          key: 'id', value: [data.id.toString(), data.name]
        });
        this.loadBreadCrumb = true;
      }
    });

    this.route.queryParams.subscribe(params => {
      this.locationId = params.locationId;
    });
  }

  onImageCropped(file: File): void {
    this.form.controls.profileImg.setValue(file);
  }

  openAddLocationContactPopup(): void {
    this.modalService.openAddLocationContactPopup(this.model.clientId).subscribe(data => {
      this.loadData();
    });
  }

  ngOnInit(): void {
    if (!this.isForPopup && this.breadCrumbInputs && this.breadCrumbInputs.length > 0) {
      this.loadBreadCrumb = true;
    }
    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      middleName: [''],
      lastName: ['', Validators.required],
      manager: [''],
      role: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      title: ['', [Validators.required]],
      country: [null, [Validators.required]],
      fax: [''],
      dob: null,
      accountCode: [''],
      tags: null,
      generalNotes: [''],
      adminNotes: [''],
      profileImg: null,
      location: ['', [Validators.required]],
      caseManager: [''],
      salesPerson: [''],
    });

    this.loadData();
    this.loadTags();
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }

    this.model.firstName = this.form.controls.firstName.value;
    this.model.middleName = this.form.controls.middleName.value;
    this.model.lastName = this.form.controls.lastName.value;
    this.model.accountCode = this.form.controls.accountCode.value;
    this.model.adminNotes = this.form.controls.adminNotes.value;
    this.model.country = this.form.controls.country.value;
    this.model.dob = this.ngbDateParserFormatter.format(this.form.controls.dob.value) ?? '';
    this.model.email = this.form.controls.email.value;
    this.model.fax = this.form.controls.fax.value;
    this.model.generalNotes = this.form.controls.generalNotes.value;
    this.model.profileImgFile = this.form.controls.profileImg.value;
    this.model.role = this.form.controls.role.value;
    this.model.tags = this.form.controls.tags.value;
    this.model.title = this.form.controls.title.value;
    this.model.locationId = Number(this.form.controls.location.value);
    this.model.caseManager = this.form.controls.caseManager.value;
    this.model.salesPerson = this.form.controls.salesPerson.value;
    this.form.value.dynamicRows.map((x: { phoneType: any; phoneNum: any; extn: any; }) =>
      this.model.contactNos.push({
        contactTypeId: Number(x.phoneType),
        contactNumber: x.phoneNum,
        extensionNote: x.extn ?? '',
        id: 0,
        entityId: ''
      })
    );
    this.blockUI.start();
    this.clientContactService.post(this.model).subscribe(
      data => {
        this.onCancel();
        this.toasterService.success('Client Contact created successfully');
        this.blockUI.stop();
      },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }
  onCancel(): void {
    if (this.isForPopup) {
      this.saveClick.emit();
      this.ngbActiveModal.dismiss('save click');
    }
    else {
      this.router.navigate(['/client/contact-list']);
    }
}
  addCustomTag = (term) => term;

  private loadData(): void {
    this.blockUI.start();
    forkJoin([
      this.staffService.getCaseManagers(),
      this.staffService.getSalespersons(),
      this.roleService.getRolesByAvailableFor(RoleAvailableFor.clientContact),
      this.countryService.getCountries(),
      this.locationService.getLocationListByClientId(this.model.clientId),
    ]).subscribe(([
      caseManagers,
      salespersons,
      roles,
      countries,
      locations
    ]) => {
      this.managersSelectListItem = caseManagers;
      this.salespersonSelectListItem = salespersons;
      this.locationSelectListItem = locations;
      this.countrySelectListItem = countries;
      this.rolesSelectListItem = roles;
      if (locations && locations.length > 0) {
        this.form.controls.location.setValue(locations[0].id);
      }

      if (this.locationId && this.locationSelectListItem.some(x => x.id === this.locationId)) {
        this.form.controls.location.setValue(this.locationId);
      }
      else if (locations && locations.length > 0) {
        this.form.controls.location.setValue(locations[0].id);
      }

      if (roles && roles.length > 0) {
        this.form.controls.role.setValue(roles[0].id);
      }
      const defaultCountry = this.countrySelectListItem.find(x => x.isDefault);
      this.form.controls.country.setValue(defaultCountry.name);
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  private loadTags(): void {
    this.tags$ = concat(
      of([]), // default items
      this.tagsInput$.pipe(
        debounceTime(200),
        filter(res => {
          return res !== null && res.length >= 0;
        }),
        distinctUntilChanged(),
        switchMap(term => {
          return this.staffService.getStaffTags(term).pipe(
            catchError(() => of([])) // empty list on error
          );
        })
      )
    );
  }

  private toDateString(date: NgbDateStruct): string // from internal model -> your mode
  {
    return date ? ('0' + date.month).slice(-2)
      + '/' + ('0' + date.day).slice(-2) + '/' + date.year : null;
  }

  ngOnDestroy(): void {
    this.clientContactsSubscription.unsubscribe();
  }

}
