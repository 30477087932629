import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { ClientAddModel } from 'src/app/models/client/client.model';
import { LocationModel } from 'src/app/models/Location/location.model';
import { NoteListModel } from 'src/app/models/note/note.list.model';
import { ClientService } from 'src/app/services/client.service';
import { ToasterService } from 'src/app/services/toater.service';


import { NoteFor } from 'src/app/helpers/enums/notes/noteFor';

@Component({
  selector: 'app-case-subject-detail-overview',
  templateUrl: './case-subject-detail-overview.component.html',
  styleUrls: ['./case-subject-detail-overview.component.css']
})
export class CaseSubjectDetailOverviewComponent implements OnInit {


  moduleId: number;
  clientId: number;
  profileImgUrl: string;
  public shimmerornot: string;

  //quicklinks dynamic sample data structure

  quicklink = [
    {
      mainmenu: "All cases", mainlink: "#", submenu: [
        { name: "Active cases", link: "#" }
      ]
    },
    {
      mainmenu: "All Invoices", mainlink: "#", submenu: [
        { name: "Unpaid invoices", link: "#" },
        { name: "Payment history", link: "#" },
        { name: "Retainers", link: "#" }
      ]
    },
  ];

  constructor(private clientService: ClientService,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService) {
    this.model = new ClientAddModel();
    this.route.parent.params.subscribe(params => this.model.id = params.id);
    this.primaryLocation = new LocationModel();
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.loadClient();
      }
    });
  }

  model: ClientAddModel;
  primaryLocation: LocationModel;
  @BlockUI() blockUI: NgBlockUI;
  navigationSubscription: Subscription;

  ngOnInit(): void {
    // client policy notes
    this.moduleId = NoteFor.Client;
    this.route.parent.params.subscribe(param => this.clientId = Number(param.id));
  }

  private loadClient(): void {
    this.blockUI.start();
    this.clientService.getClient(this.model.id).subscribe((data: any) => {
      this.clientService.clientDetailSubject$.next({ id: this.clientId, name: data.name });
      this.model = data;
      if (data) {
        this.primaryLocation = data.location;
      }
      // this.shimmerornot = this.model.profileImgUrl ? 'shimmer' : '';
      // this.profileImgUrl = this.model.profileImgUrl ?? '/assets/img/avatars/user.svg';

      // unhide above for real and comment for dummy preview
      this.shimmerornot = '';
      this.profileImgUrl = '/assets/img/avatars/user.svg';

    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate(['/client']);
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

}
