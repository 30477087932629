import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { CaseFlagEditModel } from 'src/app/models/caseflag/caseFlagEditModel';
import { CaseFlagService } from 'src/app/services/caseflag.service';
import { CasetypeService } from 'src/app/services/casetype.service';
import { RoleService } from 'src/app/services/role.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';


@Component({
  selector: 'app-edit-case-flag',
  templateUrl: './edit-case-flag.component.html',
  styleUrls: ['./edit-case-flag.component.css']
})
export class EditCaseFlagComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  model: CaseFlagEditModel;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private caseflagservice: CaseFlagService,
    private caseTypeService: CasetypeService,
    private roleService: RoleService) {
    this.model = new CaseFlagEditModel();
    this.activeRoute.params.subscribe(params => this.model.id = params.id);
  }

  form: FormGroup;
  submitted = false;
  roles: { id: string, value: string }[] = [];

  coolpro;

  get limitAccessToFormArray(): FormArray {
    return this.form.controls.limitAccessTo as FormArray;
  }
  get caseTypesToFormArray(): FormArray{
    return this.form.controls.case_types as FormArray;
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      colors: ['', [Validators.required]],
      description: [''],
      case_types: new FormArray([], minSelectedCheckboxes())
    });
    this.loadData();
  }

  private loadData(): void {
    this.blockUI.start();
    forkJoin([
      this.roleService.getRoles(),
      this.caseflagservice.get(this.model.id),
      this.caseTypeService.get()
    ]).subscribe(([
      roles,
      CaseFlagEditModelData,
      caseTypesdata
    ]) => {
      this.model = CaseFlagEditModelData;
      this.coolpro = this.model;

      this.form.controls.name.setValue(this.model.name);
      this.form.controls.colors.setValue(this.model.color);
      this.form.controls.description.setValue(this.model.description);
      const ct: FormArray = this.caseTypesToFormArray;
      ct.clear();
      caseTypesdata.forEach((d) => {
        ct.push(
          this.formBuilder.group({
            id: [d.id],
            name: [d.name],
            isSelected: [CaseFlagEditModelData.caseTypeIds.includes(d.id)],
          })
        );
      });
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate(['/setting/cases/regions-list']);
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.id = this.model.id;
    this.model.name = this.form.value.name;
    this.model.color = this.form.value.colors;
    this.model.description = this.form.value.description;
    this.model.caseTypeIds = this.form.value.case_types.filter(x => x.isSelected).map(x => x.id);
    this.blockUI.start();
    this.caseflagservice.put(this.model).subscribe(resposne => {
      setTimeout(() => {
        this.router.navigate(['/setting/cases/flag-list']);
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Case flag updated successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );

  }


}
