export class CompanyLocationModel {
        public id: number;
        public name: string;
        public isPrimary: boolean;
        public country: string;
        public state: string;
        public address1: string;
        public address2: string;
        public address3: string;
        public city: string;
        public zipCode: string;
        public primaryPhone: string;
        public secondaryPhone: string;
        public faxPhone: string;
        public billingAddress: string;
}
