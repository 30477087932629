export class ClientContactDtColumnName {
    public static readonly clientContactName = 'name';
    public static readonly title = 'title';
    public static readonly accountCode = 'accountCode';
    public static readonly client = 'client';
    public static readonly location = 'location';
    public static readonly clientName = 'clientName';
    public static readonly contactCount = 'contactCount';
    public static readonly creationDate = 'creationDate';
    public static readonly locationCount = 'locationCount';
    public static readonly salesPersonName = 'salesPersonName';
    public static readonly role = 'role';
    public static readonly manager = 'manager';
    public static readonly email = 'email';
    public static readonly homephone = 'homePhone';
    public static readonly contactNumber = 'contactNumber';
    public static readonly fax = 'fax';
    public static readonly fulladdress = 'fullAddress';
    public static readonly address1 = 'address1';
    public static readonly address2 = 'address2';
    public static readonly address3 = 'address3';
    public static readonly state = 'state';
    public static readonly city = 'city';
    public static readonly zip = 'zip';
    public static readonly country = 'country';
    public static readonly dateOfBirth = 'dob';
    public static readonly created = 'createdDate';
    public static readonly isActiveLogin = 'isActiveLogin';
    public static readonly lastlogin = 'lastLoggedOn';
    public static readonly lastPasswordChange = 'lastPasswordChange';
    public static readonly salesPerson = 'salesPerson';
    public static readonly caseManger = 'caseManager';
    public static readonly lastCase = 'lastCase';
}