<div class="row mt-4">
    <div class="col-md-3">
        <div class="card p-1">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <div class="tab-icon-expense bg-pink rounded-circle text-center">
                            <i class="fas fa-hourglass-half text-white align-middle "></i>
                        </div>
                        
                    </div>
                    <div class="col-md-7 pl-2 mt-2">
                        <h2 class="font-weight-bold">0.20</h2>
                        <p>Hours Counted</p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
    <div class="col-md-3">
        <div class="card p-1">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <div class="tab-icon-expense bg-primary rounded-circle text-center">
                            <i class="fas fa-stopwatch text-white align-middle "></i>
                        </div>
                        
                    </div>
                    <div class="col-md-7 pl-2 mt-2">
                        <h2 class="font-weight-bold">$5.70</h2>
                        <p>Wages (Regular)</p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>

    <div class="col-md-3">
        <div class="card p-1">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <div class="tab-icon-expense bg-warning rounded-circle text-center">
                          
                            <i class="fas fa-file-invoice-dollar text-white align-middle"></i>
                        </div>
                        
                    </div>
                    <div class="col-md-7 pl-2 mt-2">
                        <h2 class="font-weight-bold">$4,575</h2>
                        <p>Expenses Counted</p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>


    <div class="col-md-3">
        <div class="card p-1">
            <div class="card-body">
                <div class="row">
                    <div class="col-md-5">
                        <div class="tab-icon-expense bg-success rounded-circle text-center">
                          
                            <i class="fas fa-hourglass-half text-white align-middle"></i>
                        </div>
                        
                    </div>
                    <div class="col-md-7 pl-2 mt-2">
                        <h2 class="font-weight-bold">$6,575</h2>
                        <p>Total  Counted</p>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</div>

 <!-- Filter right sidenav -->
 <app-case-detail-expenses-filter (onApplyFilter)="applyFilter($event)" 
 (resetFilters)="onResetFilters()"  [filterStatusParent]="filterNavStatus"  (closeBtnFilterStatus)="closeFilterEvent($event)"></app-case-detail-expenses-filter>

<div class="row">

    <div class="col-md-12">
        
        <button class="btn btn-lg bg-blue text-white float-right ml-3"  (click)="filterNavStatus = true">
            <i class="align-middle fas fa-filter"></i>
        </button>
        <button class="btn btn-lg bg-white float-right" >
            <i class="align-middle fas fa-plus-circle"></i> New Expense Entry
        </button>
        <table class="neu-table table table-striped table-responsive neu-table w-100" datatable [dtOptions]="dtOptions" data-page="2" ></table>

        <div class="w-100  mt-3 pt-3 border-top ">
            <h4 class="float-right">Time Total: <span class="color-secondary">$8.75</span></h4>
        </div>
    </div>
</div>