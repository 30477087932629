import { Component, OnInit } from '@angular/core';

interface Country {
  name: string;
  area: number;
  population: number;
}


const COUNTRIES: Country[] = [
  {
    name: 'Travel time',
    area: 1.0,
    population: 108.75
  },
  {
    name: 'SV - Investigation Time',
    area: 3.75,
    population: 29.00
  }
];

@Component({
  selector: 'app-payment-slip-entries',
  templateUrl: './payment-slip-entries.component.html',
  styleUrls: ['./payment-slip-entries.component.css']
})
export class PaymentSlipEntriesComponent implements OnInit {

  opened: boolean;
  countries = COUNTRIES;

  dataEntries = [
    {
      id: 1, title: '1/10/16  –  EXP-39269 (Awaiting Payment)  –  CLARK, CHERLY  –  ST06180-01', submenu: [
        { name: '	ST - Investigation Time', hours: '32', total: '182' }
      ]
    },
    {
      id: 2, title: '1/10/16  –  EXP-39270 (Awaiting Payment)  –  CLARK, CHERLY  –  ST06191-01', submenu: [
        { name: '	ST - Investigation Time', hours: '191', total: '710' }
      ]
    },
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
