
<button class="btn btn-lg bg-white float-right" (click)="open(content)" ><i class="align-middle fas fa-plus-circle"></i> New Review </button>
<table class="neu-table table table-striped neu-table w-100 table-responsive" datatable [dtOptions]="dtOptions" data-page="2" ></table>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Select Review</h4>
        <button type="button" class="btn btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fas fa-times"></i></button>
    </div>
    <div class="modal-body">
        <form>
            <div class="mb-3">
                <div class="row align-items-center">
                    <div class="col-4">
                        <label class="mb-0" for="dateOfBirth">Select Review Type <sup class="clsStric">*</sup></label>
                    </div>
                    <div class="col-8">
                        <div class="input-group">
                            <select class="form-select bg-white" aria-label="Default select example" [(ngModel)]="groupSelectDropdown" name="groupSelectDropdown" >
                                <option selected disabled>Select an option</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                        </div>
                    </div>
                </div>

              

                
               
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
        <button type="button" class=" btn btn-lg bg-blue text-white " [disabled]="!groupSelectDropdown" (click)="gotoAnswer()">Continue</button>
 
    </div>
</ng-template>