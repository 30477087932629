import { NoteListModel } from '../note/note.list.model';
import { ClientContactEditModel } from './client.contact.edit.model';

export class ClientContactDetailModel extends ClientContactEditModel {
    public name: string;
    public locationName: string;
    public clientName: string;
    public caseManagerName: string;
    public salesPersonName: string;
    public pinnedNotes: NoteListModel[];
    public address1: string;
    public address2: string;
    public address3: string;
    public city: string;
    public state: string;
    public zipCode: string;
}