import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';

import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { ClientAddModel } from 'src/app/models/client/client.model';
import { LocationModel } from 'src/app/models/Location/location.model';
import { StaffDetailModel } from 'src/app/models/staff/staff.detail.model';
import { ClientService } from 'src/app/services/client.service';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';




@Component({
  selector: 'app-staff-overview',
  templateUrl: './staff-overview.component.html',
  styleUrls: ['./staff-overview.component.css']
})
export class StaffOverviewComponent implements OnInit, OnDestroy {




  //quicklinks dynamic sample data structure

  quicklink = [
    {
      mainmenu: "All cases", mainlink: "#", submenu: [
        { name: "Active cases", link: "#" }
      ]
    },
    {
      mainmenu: "All Invoices", mainlink: "#", submenu: [
        { name: "Unpaid invoices", link: "#" },
        { name: "Payment history", link: "#" },
        { name: "Retainers", link: "#" }
      ]
    },
  ]
  accesslevel = true;
  public model = new StaffDetailModel();

  //profile photo/avatar variable
  public userPhoto: string = "";

  //this.userPhoto="https://picsum.photos/200/300";
  //   this.staffName=data.firstName+" "+data.lastName;

  public staffName: string;
  profileImgUrl: string;

  public shimmerornot: string;

  @BlockUI() blockUI: NgBlockUI;
  navigationSubscription: Subscription;
  staffServiceSubscription: Subscription;
  @Output()
  setStaffName = new EventEmitter<string>();


  constructor(
    private staffService: StaffService,
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute) {
    this.route.parent.params.subscribe(params => this.model.id = params.id);
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.loadOverview();
      }
    });


  }

  ngOnInit(): void {

  }

  private loadOverview(): void {
    this.blockUI.start();
    this.staffServiceSubscription = this.staffService.getOverview(this.model.id)
      .subscribe((data: StaffDetailModel) => {
        this.staffService.staffDetailSubject$.next({
          id: data.id,
          name: `${data.firstName} ${data.middleName ?? ''} ${data.lastName ?? ''}`.replace('  ', ' ')
        });
        this.model = data;
        this.shimmerornot = this.model.profileImgUrl ? 'shimmer' : '';
        this.profileImgUrl = this.model.profileImgUrl ?? '/assets/img/avatars/user.svg';
      }, error => {
        this.toasterService.ProcessErrorResponse(error);
        this.router.navigate(['/staff']);
        this.blockUI.stop();
      }, () => {
        this.blockUI.stop();
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

}
