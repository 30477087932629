import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { mapTo, tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthenticateResponseModel } from '../models/authenticate/authenticate.response.model';
import { AuthenticateRequestModel } from '../models/authenticate/authenticate.request.model';
import * as jwt_decode from 'jwt-decode';
import { SocialUser } from 'angularx-social-login';
import { CreatePasswordRequestModel } from '../models/authenticate/create.password.request.model';
import { ResetPasswordRequestModel } from '../models/authenticate/reset.password.request.model';
import { Permission } from '../helpers/enums/roles/permission';
import { HasPermission } from '../helpers/has-permission.helper';
import { StaffDetailModel } from '../models/staff/staff.detail.model';
import { ChangePasswordRequestModel } from '../models/authenticate/change.password.request.model';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private readonly JWT_TOKEN = 'JWT_TOKEN';
    private readonly REFRESH_TOKEN = 'REFRESH_TOKEN';

    private userSubject$ = new BehaviorSubject<AuthenticateResponseModel>(null);
    public user$ = this.userSubject$.asObservable();

    public userNameSubject$ = new BehaviorSubject<string>(null);
    public userNameDetai$ = this.userNameSubject$.asObservable();

    constructor(private http: HttpClient) {
    }

    public validatePermission(permissions: Permission[]): Observable<boolean> {
        return this.user$.pipe(map((user: AuthenticateResponseModel) => {
            if (user == null || user.permissions == null || user.permissions.length === 0) {
                return false;
            }
            return user.permissions.some(permission => permissions.includes(permission));
        }));
    }

    public login(authenticateRequestModel: AuthenticateRequestModel): Observable<boolean> {
        const formData: FormData = new FormData();
        formData.append('userName', authenticateRequestModel.userName);
        formData.append('password', authenticateRequestModel.password);

        return this.http.post<any>(`${environment.apiBaseUrl}/auth/login`, formData)
            .pipe(
                tap((user: AuthenticateResponseModel) => {
                    this.doLoginUser(user);
                }),
                mapTo(true),
                catchError(error => {
                    throw error;
                }));
    }

    public forgotPassword(email: string) {
        const formData: FormData = new FormData();
        formData.append('email', email);
        return this.http.post(`${environment.apiBaseUrl}/auth/forgot-password`, formData);
    }

    public overView(): Observable<StaffDetailModel> {
        return this.http.get<StaffDetailModel>(`${environment.apiBaseUrl}/auth/overview`);
    }
    public permissions(): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/auth/permissions`);
    }

    public activate(model: CreatePasswordRequestModel) {
        const formData: FormData = new FormData();
        formData.append('key', model.key);
        formData.append('secret', model.secret);
        formData.append('password', model.password);
        formData.append('confirmPassword', model.confirmPassword);
        return this.http.post(`${environment.apiBaseUrl}/auth/create-password`, formData);
    }

    public reset(model: ResetPasswordRequestModel) {
        const formData: FormData = new FormData();
        formData.append('key', model.key);
        formData.append('secret', model.secret);
        formData.append('newPassword', model.newPassword);
        formData.append('confirmPassword', model.confirmPassword);
        return this.http.post(`${environment.apiBaseUrl}/auth/reset`, formData);
    }

    public changePassword(model: ChangePasswordRequestModel) {
        const formData: FormData = new FormData();
        formData.append('currentPassword', model.currentPassword);
        formData.append('newPassword', model.newPassword);
        formData.append('confirmPassword', model.confirmPassword);
        return this.http.post(`${environment.apiBaseUrl}/auth/change-password`, formData);
    }

    public externalLogin(model: SocialUser) {
        return this.http.post<any>(`${environment.apiBaseUrl}/auth/externalLogin`, model)
            .pipe(
                tap((user: AuthenticateResponseModel) => {
                    this.doLoginUser(user);
                }),
                mapTo(true),
                catchError(error => {
                    throw error;
                }));
    }

    public logout() {
        const formData: FormData = new FormData();
        formData.append('refreshToken', this.getRefreshToken());
        formData.append('token', this.getJwtToken());
        return this.http.post<any>(`${environment.apiBaseUrl}/auth/logout`, formData)
            .pipe(
                tap(() => {
                    this.doLogoutUser();
                }),
                mapTo(true),
                catchError(error => {
                    this.doLogoutUser();
                    return of(false);
                }));
    }


    public isLoggedIn() {
        return !!this.getJwtToken();
    }

    public refreshToken() {
        const formData: FormData = new FormData();
        formData.append('refreshToken', this.getRefreshToken());
        formData.append('token', this.getJwtToken());
        return this.http.post<any>(`${environment.apiBaseUrl}/auth/refresh`, formData)
            .pipe(
                tap((user: AuthenticateResponseModel) => {
                    this.doLoginUser(user);
                }),
                mapTo(true),
                catchError(error => {
                    this.doLogoutUser();
                    return of(false);
                }));
    }

    public isTokenExpired(): boolean {
        const token = this.getJwtToken();
        if (!token) { return true; }

        const date = this.getTokenExpirationDate(token);
        if (date === undefined) { return false; }
        return !(date.valueOf() > new Date().valueOf());
    }


    // Used in interceptor
    public getJwtToken() {
        return localStorage.getItem(this.JWT_TOKEN);
    }

    private doLoginUser(authenticateResponseModel: AuthenticateResponseModel) {
        this.storeTokens(authenticateResponseModel);
        HasPermission.storePermissions(authenticateResponseModel.permissions);
        this.userSubject$.next(authenticateResponseModel);
    }

    private doLogoutUser() {
        // Clear all data
        localStorage.clear();
        this.removeTokens();
        HasPermission.deletePermissions();
        this.userSubject$.next(null);
    }

    private getRefreshToken() {
        return localStorage.getItem(this.REFRESH_TOKEN);
    }

    public storeTokens(authenticateResponseModel: AuthenticateResponseModel) {
        localStorage.setItem(this.JWT_TOKEN, authenticateResponseModel.token);
        localStorage.setItem(this.REFRESH_TOKEN, authenticateResponseModel.refreshToken);
    }


    private removeTokens() {
        localStorage.removeItem(this.JWT_TOKEN);
        localStorage.removeItem(this.REFRESH_TOKEN);
    }

    private getTokenExpirationDate(token: string): Date {
        const decoded = jwt_decode(token);
        if (decoded.exp === undefined) {
            return null;
        }
        const date = new Date(0);
        date.setUTCSeconds(decoded.exp);
        return date;
    }
}
