import { ThrowStmt } from '@angular/compiler';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { AppUtils } from 'src/app/helpers';
import { LogoTypes } from 'src/app/helpers/enums/settings/logo/logo-enum';
import { AddOrEditLogoModel } from 'src/app/models/logo/addOrEditLogoModel';
import { LogoViewModel } from 'src/app/models/logo/logoViewModel';
import { LogoService } from 'src/app/services/logo.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-upload-logo-edit',
  templateUrl: './upload-logo-edit.component.html',
  styleUrls: ['./upload-logo-edit.component.css']
})
export class UploadLogoEditComponent implements OnInit, OnDestroy {

  submitted = false;
  model: AddOrEditLogoModel;
  viewModel: LogoViewModel[];
  subscriptions: Subscription[];
  printId = 0;
  systemId = 0;
  emailId = 0;
  printUrl = null;
  emailUrl = null;
  systemUrl = null;

  @BlockUI('blockui-upload-logo-div')blockUI: NgBlockUI;
  constructor(private toasterService: ToasterService, private logoService: LogoService,
              private router: Router, public appUtils: AppUtils) {
                this.model = new AddOrEditLogoModel();
                this.viewModel = [];
                this.subscriptions = [];
               }
  form: FormGroup;

  ngOnInit(): void {
    this.form = new FormGroup({
      system_logo: new FormControl(),
      print_logo: new FormControl(),
      email_logo: new FormControl()
    });
    this.loadDefaultData();
  }
  loadDefaultData(): void{
  this.blockUI.start();
  const subscription =  this.logoService.getAll().subscribe((data) => {
    this.viewModel = data;
    data.forEach((element) => {
      if (element.typeId == LogoTypes.Print) {
        this.printId = element.id;
        this.printUrl =element.url;
      }
      if (element.typeId == LogoTypes.Email) {
        this.emailId = element.id;
        this.emailUrl =element.url;

      }
      if (element.typeId == LogoTypes.System) {
        this.systemId = element.id;
        this.systemUrl =element.url;

      }
    });
    this.blockUI.stop();
  }, (errors: any) => {
  this.blockUI.stop();
  this.toasterService.ProcessErrorResponse(errors);
  }, () => {
  this.blockUI.stop();
  });
  this.subscriptions.push(subscription);
  }
  deleteLogo(id: number): void{
    if (!confirm('Are you sure you want to delete Logo?')) {
      return;
    }
    const ids = [id];
    const subscription = this.logoService.delete(ids).subscribe((data: any) => {
    this.toasterService.success('Logo deleted successfully');
    setTimeout(() => {
      this.onReload();
    }, 10);
    }, (errors: any) => {
      this.toasterService.ProcessErrorResponse(errors);
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
    this.subscriptions.push(subscription);
  }
  onPrintFileSelected(event: any): void {
    const fileList = event.target.files as FileList;
    if (!fileList || fileList.length === 0) {
      this.form.controls.print_logo.setValue(null);
      return;
    }
    if (fileList.item(0).name.split('.').length > 2) {
      this.toasterService.error('Invalid file!');
      return;
    }
    this.form.controls.print_logo.setValue(fileList.item(0));
  }
  onSystemFileSelected(event: any): void {
    const fileList = event.target.files as FileList;
    if (!fileList || fileList.length === 0) {
      this.form.controls.system_logo.setValue(null);
      return;
    }
    if (fileList.item(0).name.split('.').length > 2) {
      this.toasterService.error('Invalid file!');
      return;
    }
    this.form.controls.system_logo.setValue(fileList.item(0));
  }
  onEmailFileSelected(event: any): void {
    const fileList = event.target.files as FileList;
    if (!fileList || fileList.length === 0) {
      this.form.controls.email_logo.setValue(null);
      return;
    }
    if (fileList.item(0).name.split('.').length > 2) {
      this.toasterService.error('Invalid file!');
      return;
    }
    this.form.controls.email_logo.setValue(fileList.item(0));
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    const controls  = this.form.controls;
    if (controls.print_logo.value){
    this.model.printFile =  controls.print_logo.value;
    }
    if (controls.system_logo.value){
    this.model.systemFile = controls.system_logo.value;
    }
    if (controls.email_logo.value){
    this.model.emailFile = controls.email_logo.value;
    }
    this.model.printId = this.printId;
    this.model.systemId = this.systemId;
    this.model.emailId = this.emailId;
    this.blockUI.start();
    const subscription = this.logoService.post(this.model).subscribe(resposne => {
      setTimeout(() => {
       this.onReload();
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Logo uploaded successfully');
      }, 300);
      this.blockUI.stop();
    },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
    this.subscriptions.push(subscription);
  }
  onCancel(): void {
    this.router.navigate(['/setting']);
  }
 onReload(): void {
  this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
    this.router.navigate(['/setting/global/upload-logo-edit']);
  });
 }

  ngOnDestroy(): void {
   this.subscriptions.every((subscription: Subscription) => subscription.unsubscribe());
  }
}
