import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-new-invoice-and-expense-item',
  templateUrl: './new-invoice-and-expense-item.component.html',
  styleUrls: ['./new-invoice-and-expense-item.component.css']
})
export class NewInvoiceAndExpenseItemComponent implements OnInit {




  constructor(private formBuilder: FormBuilder) { }

  form: FormGroup;
  submitted = false;
  isSelected = false;

  users = [{
    id: 1,
    name: 'Paul'
  }];

  controls = this.users.map(c => new FormControl(false));
  ngOnInit(): void {
    this.form = new FormGroup({
      itemName: new FormControl(),
      expenseAlias: new FormControl(),
      rateType: new FormControl(),
      defaultExpenseNote: new FormControl(),

      invoiceAlias: new FormControl(),
      invoiceUnit: new FormControl(),
      invoiceUnitDiscount: new FormControl(),
      defaultCurrency: new FormControl(),
      defultInvoiceDescrip: new FormControl(),

      classCode: new FormControl(),
      referenceId: new FormControl(),


      orders: new FormArray(this.controls),

    });
  }

  onSubmit() {

  }

}
