import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { StaffDetailModel } from 'src/app/models/staff/staff.detail.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {

  quicklink = [
    {
      mainmenu: "All cases", mainlink: "#", submenu: [
        { name: "Active cases", link: "#" }
      ]
    },
    {
      mainmenu: "All Invoices", mainlink: "#", submenu: [
        { name: "Unpaid invoices", link: "#" },
        { name: "Payment history", link: "#" },
        { name: "Retainers", link: "#" }
      ]
    },
  ];
  accesslevel = true;
  model = new StaffDetailModel();
  profileImgUrl: string;
  @BlockUI() blockUI: NgBlockUI;
  navigationSubscription: Subscription;
  staffServiceSubscription: Subscription;
  @Output()
  setStaffName = new EventEmitter<string>();


  constructor(
    private authService: AuthenticationService,
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute) {
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.loadOverview();
      }
    });
  }

  ngOnInit(): void {
  }

  private loadOverview(): void {
    this.blockUI.start();
    this.staffServiceSubscription = this.authService.overView()
      .subscribe((data: StaffDetailModel) => {
        this.authService.userNameSubject$.next(`${data.firstName ?? ''} ${data.middleName ?? ''} ${data.lastName ?? ''}`
          .replace('  ', ' '));
        this.model = data;
        this.profileImgUrl = this.model.profileImgUrl ?? '/assets/img/avatars/user.svg';
      }, error => {
        this.toasterService.ProcessErrorResponse(error);
        this.router.navigate(['/']);
        this.blockUI.stop();
      }, () => {
        this.blockUI.stop();
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

}
