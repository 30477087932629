import { Component, EventEmitter, OnInit, Output, Input, OnChanges } from '@angular/core';
import { AppUtils } from 'src/app/helpers';
import { ClientFilterByOptionsMapping, ClientSearchOptionsMapping } from 'src/app/helpers/constants/filter-and-search/client.search&filter.option.mapping';
import { CaseFilterByOptionsMapping,CaseSearchOptionsMapping } from 'src/app/helpers/constants/filter-and-search/case.search&filter.option.mapping';
import { ClientFilterByOptions } from 'src/app/helpers/enums/filters-and-searchs/client.search&filter.option';
import {CaseFilterByOptions, CaseSearchOptions} from 'src/app/helpers/enums/filters-and-searchs/case.search&filter.option';

import { CaseSearchByModel, FilterBy, FilterInputModel, FilterModel, SearchByModel } from 'src/app/models/shared/filters.model';
import { SearchModel } from 'src/app/models/shared/search.model';
import { ClientService } from 'src/app/services/client.service';
import { StaffService } from 'src/app/services/staff.serrvice';
import { CaseService } from 'src/app/services/case.service';

@Component({
  selector: 'app-case-list-filter',
  templateUrl: './case-list-filter.component.html',
  styleUrls: ['./case-list-filter.component.css']
})
export class CaseListFilterComponent implements OnInit {



  filterBy: FilterInputModel[] = [];
  filterIn: FilterInputModel[] = [];
  isFilterInVisible = false;

  @Output() onApplyFilter = new EventEmitter();
  @Output() resetFilters = new EventEmitter();

  searchBy: CaseSearchByModel[] = [];

  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();

  constructor(
    private appUtils: AppUtils,
    private staffService: StaffService,
    private clientService: ClientService,
    private caseService:CaseService) {
      const CasefilterOptions = [];
     
         CaseSearchOptionsMapping.forEach((key, value) => 

         CasefilterOptions.push({
          name: key,
          id: value["val"] ,
          group:  value["group"], 
          isSelected: false
        })
       ); 
        
         this.searchBy = CasefilterOptions;
         if (this.searchBy.length >= 1) {
           this.searchBy[0].isSelected = true;
         }
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    this.loadFilterOptions();
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onResetFilters(): void {
    this.resetFilters.emit();
  }

  private loadFilterOptions(): void {
    debugger;
    this.staffService.getCaseManagers().subscribe((data: any) => {
      const filterOptions = [];
      CaseFilterByOptionsMapping.forEach((key, value) =>
        filterOptions.push({ name: key, id: `${value}`, group: undefined, isSelected: false })
      );
     
      data.forEach(user =>
        filterOptions.push({
          name: user.value,
          id: `${CaseFilterByOptions.Casemanager}_` + user.value,
          group: user.isActive?'Case Manager (Active)':'Case Manager (De-Active)', isSelected: false
        })
      );
     

      this.filterBy = filterOptions;
      if (this.filterBy.length >= 1) {
        this.filterBy[0].isSelected = true;
      }
    });
  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }


}
