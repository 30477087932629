import { Component, OnInit, ViewChild, Renderer2  } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

// for datatable
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { ClientService } from 'src/app/services/client.service';
import { Route } from '@angular/compiler/src/core';
import { HistoryFor } from 'src/app/helpers/enums/history/historyFor';


@Component({
  selector: 'app-chistory',
  templateUrl: './chistory.component.html',
  styleUrls: ['./chistory.component.css']
})
export class ChistoryComponent implements OnInit {
 entityId: string;
 moduleId: number;
  constructor(private route: ActivatedRoute, private router: Router) { }

              ngOnInit(): void {
                this.moduleId = HistoryFor.Client;
                this.route.parent.params.subscribe(param => this.entityId = param.id);
              }


}
