<div class="row">
    <div class="col-md-9">
      <div class="row">
        <div class="col-md-12">
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-3">
            <ngb-panel id="custom-panel-3">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold">Claimant Information</h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Full Name</div>
                  <div class="col-md-9">{{primaryLocation.locationName}}</div>
                </div>
                
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Alias</div>
                  <div class="col-md-9">{{model.salesPersonName}} </div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Street Address</div>
                  <div class="col-md-9">{{model.caseManagerName}}</div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Date of Birth</div>
                  <div class="col-md-9">{{model.accountCode}} </div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">SSN</div>
                  <div class="col-md-9"><a href="{{model.webSite}}">{{model.webSite}}</a></div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Home Phone</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Cell Phone</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Sex</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Race</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Height</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Weight</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Hair</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Build</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Eyes</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Glasses</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Spouse</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>


                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Children</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Other Characteristics</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Drivers License Number / State</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Date of Injury</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Notes</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Injury / Limitations</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-5">
            <ngb-panel id="custom-panel-5">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold"><i
                      class="align-middle far fa-file-alt mr-2 fx-2 text-orange"></i>Address Information</h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row mb-4" *ngIf="model.pinnedNotes && model.pinnedNotes.length > 0">
                  <div class="col-md-12">
                    <app-pinned-notes-list-view [pinnedNotes]="model.pinnedNotes"></app-pinned-notes-list-view>
                  </div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Name</div>
                  <div class="col-md-9">{{model.generalNotes}}</div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Street Address</div>
                  <div class="col-md-9">{{model.casePolicies}} 
                    Lorem Ipsum
                    <span class="subject-dropdown">
                      <a href="#" class="ml-2 btn text-orange dropdown-toggle border-0 dropdown-no-caret"><i class="align-middle fas fa-map"></i> View on Map</a>
                  </span>
                  </div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Notes</div>
                  <div class="col-md-9">{{model.updatePolicies}}</div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
          <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-4">
            <ngb-panel id="custom-panel-4">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0 font-weight-bold"><i class="align-middle fas fa-tools mr-2 fx-2 text-orange"></i>Employer Information</h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Name</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">DBA</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>
                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Primary Contact</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>




                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Is the Insured?</div>
                  <div class="col-md-9">{{model.sendOverdueInvoices ? "Yes" : "No"}}</div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Address of Insured</div>
                  <div class="col-md-9">Lorem Ipsum
                  
                    <span class="subject-dropdown">
                      <a href="#" class="ml-2 btn text-orange dropdown-toggle border-0 dropdown-no-caret"><i class="align-middle fas fa-map"></i> View on Map</a>
                  </span>
                </div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Office phone</div>
                  <div class="col-md-9">{{model.billingEmail}}</div>
                </div>

                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Cell Phone</div>
                  <div class="col-md-9">{{model.billingEmail}}</div>
                </div>


                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Email</div>
                  <div class="col-md-9"><a href="mailto:{{model.billingEmail}}">{{model.billingEmail}}</a></div>
                </div>


                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold  font-weight-bold">Notes</div>
                  <div class="col-md-9">{{model.billingEmail}}</div>
                </div>


                <div class="row  mb-4">
                  <div class="col-md-3 da-font-bold font-weight-bold">Ok to Contact?</div>
                  <div class="col-md-9">{{model.sendOverdueInvoices ? "Yes" : "No"}}</div>
                </div>


              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <div class="row " id="stickyoney">
        <div class="col-md-12">
            <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-0">
              <ngb-panel id="custom-panel-0">
                <ng-template ngbPanelHeader let-opened="opened">
                  <div class="d-flex align-items-center justify-content-between">
                    <h5 class="m-0  font-weight-bold">Photo</h5>
    
    
                    <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                        class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
    
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="row">
                    <div class="col-md-12 mb-3">
    
                      <!-- for initials avatar -->
                      <!-- <app-avatar-photo  [photoUrl]="userPhoto" [name]="staffName"></app-avatar-photo> -->
    
                      <img [src]="profileImgUrl" class="w-100 {{shimmerornot}}">
    
                    </div>
                  </div>
                </ng-template>
              </ngb-panel>
    
    
            </ngb-accordion>
          </div>
          
        
        <div class="col-md-12 mt-3">
          <ngb-accordion #a="ngbAccordion">
            <ngb-panel id="custom-panel-2">
              <ng-template ngbPanelHeader let-opened="opened">
                <div class="d-flex align-items-center justify-content-between">
                  <h5 class="m-0  font-weight-bold">Quick Links</h5>
                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                      class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                </div>
              </ng-template>
              <ng-template ngbPanelContent>
                <div class="row quicklinks">
                  <div class="col-md-12 mb-3">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="list-group mb-3" *ngFor="let quick of quicklink; index as i; ">
                          <div class="list-group quickhead">
                            {{quick.mainmenu}}
                          </div>
                          <ul class="list-group">
                            <li class="list-group-item border-0" *ngFor="let submen of quicklink[i].submenu; index as x">
                              <a class="link" href="{{submen.link}}">{{submen.name}}</a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-template>
            </ngb-panel>
          </ngb-accordion>
        </div>
      </div>
    </div>
  </div>