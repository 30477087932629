import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { AddDocumentTemplateModel } from 'src/app/models/document-templates/addDocumentTemplateModel';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { CasetypeService } from 'src/app/services/casetype.service';
import { DocumentTemplateService } from 'src/app/services/document.template.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';

@Component({
  selector: 'app-new-document-template',
  templateUrl: './new-document-template.component.html',
  styleUrls: ['./new-document-template.component.css']
})
export class NewDocumentTemplateComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  accessGroupSelectListItem: SelectListItem<number>[] = [];
  @BlockUI('container-blockui-new-folder-template')blockUI: NgBlockUI;
  model: AddDocumentTemplateModel;

  constructor(private formBuilder: FormBuilder,
              private accessGroupService: AccessGroupService,
              private toasterService: ToasterService,
              private router: Router,
              private documentTemplateService: DocumentTemplateService,
              private caseTypeService: CasetypeService,
              private modalService: ModalService){

              this.model = new AddDocumentTemplateModel();
               }

get caseTypesToFormArray(): FormArray{
  return this.form.controls.case_types as FormArray;
}

onFileSelected(event: any): void{
  const fileList = event.target.files as FileList;
  if (!fileList || fileList.length === 0) {
    this.form.controls.template_file.setValue(null);
    return;
  }
  if (fileList.item(0).name.split('.').length > 2) {
    this.toasterService.error('Invalid file!');
    return;
  }
  this.form.controls.template_file.setValue(fileList.item(0));
}
  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      customSwitch1: new FormControl(true),
      template_file: new FormControl(null, Validators.required),
      restrict_use: new FormControl(''),
      case_types: new FormArray([], minSelectedCheckboxes())
    });
    this.loadDefaultData();
  }

  private async loadDefaultData(): Promise<void> {
    this.blockUI.start();
    forkJoin([this.caseTypeService.getSelectListItem(),
      this.accessGroupService.getAccessGroups()]).subscribe(
      ([caseTypesdata, accessGroupsdata]) => {
       // loading case types
       const ct: FormArray = this.caseTypesToFormArray;
       ct.clear();
       caseTypesdata.forEach((d) => {
         ct.push(
           this.formBuilder.group({
             id: [d.id],
             name: [d.value],
             isSelected: [false],
           })
         );
       });
        // loading access groups
       this.accessGroupSelectListItem = accessGroupsdata;
       this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(
          error,
          'Something went wrong!'
        );
        this.blockUI.stop();
      }
    );
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.id = 0;
    this.model.name = this.form.controls.name.value;
    this.model.accessGroupId = this.form.controls.restrict_use.value ? Number(this.form.controls.restrict_use.value) : null ;
    this.model.description = this.form.controls.description.value;
    this.model.templateFile = this.form.controls.template_file.value;
    this.model.isUseMergeFields = this.form.controls.customSwitch1.value;
    this.model.caseTypeIds = this.form.value.case_types.filter(x => x.isSelected).map(x => x.id);
    this.blockUI.start();
    this.documentTemplateService.post(this.model).subscribe(resposne => {
      setTimeout(() => {
        this.onCancel();
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Document template created successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }
  onCancel(): void {
    this.router.navigate(['/setting/global/document-template-list']);
  }

  viewTemplate(): void {
    this.modalService.openDocumentTemplateVariablePopup();
  }

}
