import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';
import { CaseFlagAddModel } from '../models/caseflag/caseFlagAddModel';
import { CaseFlagViewModel } from '../models/caseflag/caseFlagViewModel';
import { CaseFlagEditModel } from '../models/caseflag/caseFlagEditModel';
import { SelectListItem } from '../models/shared/select.list.item';

@Injectable({
    providedIn: 'root'
})
export class CaseFlagService {
    constructor(private http: HttpClient) {
    }
    public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/caseflag/query`, dataTablesParameters, {});
    }

    public post(model: CaseFlagAddModel): Observable<CaseFlagViewModel> {
        return this.http.post<CaseFlagViewModel>(`${environment.apiBaseUrl}/caseflag`, model, {});
    }

    public get(id: number): Observable<CaseFlagViewModel> {
        return this.http.get<CaseFlagViewModel>(`${environment.apiBaseUrl}/caseflag/${id}`);
    }

    public getByCaseTypeId(casetypeId: number): Observable<SelectListItem<number>[]> {
        return this.http.get<SelectListItem<number>[]>(`${environment.apiBaseUrl}/caseflag/query-caseflag-by-casetypeId/${casetypeId}`);
    }

    public getAll(): Observable<CaseFlagViewModel[]>{
        return this.http.get<CaseFlagViewModel[]>(`${environment.apiBaseUrl}/caseflag/get`);

    }
    public put(model: CaseFlagEditModel): Observable<CaseFlagViewModel> {
        return this.http.put<CaseFlagViewModel>(`${environment.apiBaseUrl}/caseflag`, model, {});
    }
    public delete(ids: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/caseflag?ids=${ids.join('&ids=')}`);
    }
    public undelete(ids: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/caseflag/undelete?ids=${ids.join('&ids=')}`);
    }
}
