import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import * as Chart from 'chart.js';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin, Subscription } from 'rxjs';
import { AppUtils } from 'src/app/helpers';
import { ComponentDestoryHelper } from 'src/app/helpers/component-destory.helper';
import { CustomFieldTypes } from 'src/app/helpers/enums/settings/customField/CustomFieldTypes';
import { CaseStatusViewModel } from 'src/app/models/case-status/caseStatusViewModel';
import { AddCaseModel } from 'src/app/models/case/add-case-model';
import { CaseViewModel } from 'src/app/models/case/caseViewModel';
import { AddOrEditCaseRegionModelModel } from 'src/app/models/caseregion/add.case-region.model';
import { CaseTypeViewModel } from 'src/app/models/casetypes/caseTypeViewModel';
import { CompanyLocationViewModel } from 'src/app/models/company-location/companyLocationViewModel';
import { CustomFieldModel } from 'src/app/models/custom-fields/CustomFieldModel';
import { ReferralSourceViewModel } from 'src/app/models/referral-source/referralSourceViewModel';
import { CountryModel } from 'src/app/models/shared/country.model';
import { SubjectTypeInCaseViewModel } from 'src/app/models/subject-type-in-case/subjectTypeInCaseViewModel';
import { SubjectTypeViewModel } from 'src/app/models/subjectTypes/subjectTypeViewModel';
import { CaseService } from 'src/app/services/case.service';
import { CaseRegionService } from 'src/app/services/caseregion.service';
import { CasestatusService } from 'src/app/services/casestatus.service';
import { CasetypeService } from 'src/app/services/casetype.service';
import { ClientService } from 'src/app/services/client.service';
import { CompanyLocationService } from 'src/app/services/company-location.service';
import { CountryService } from 'src/app/services/country.service';
import { CustomfieldService } from 'src/app/services/customfield.service';
import { ReferralsourceService } from 'src/app/services/referralsource.service';
import { SubjectTypeInCaseService } from 'src/app/services/subject.type.in.case.service';
import { SubjectTypeService } from 'src/app/services/subjecttype.service';
import { ToasterService } from 'src/app/services/toater.service';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { StaffService } from 'src/app/services/staff.serrvice';
import { StaffDetailModel } from 'src/app/models/staff/staff.detail.model';
import { CaseBudgetModel } from 'src/app/models/case/caseBudgetModel';
import { registerLocaleData } from '@angular/common';


@Component({
  selector: 'app-case-overview',
  templateUrl: './case-overview.component.html',
  styleUrls: ['./case-overview.component.css']
})
export class CaseOverviewComponent extends ComponentDestoryHelper  implements OnInit,OnDestroy {
  chart:any;
  @ViewChild('closebutton',{static: false}) closebutton: ElementRef;
  closeResult = '';
  open(content: any) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    debugger;
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  
  public model = new CaseViewModel();
  public caseBudget=new CaseBudgetModel();

  public staffName: string;
  profileImgUrl: string;
  caseTypeData: CaseTypeViewModel;
  
  public shimmerornot: string;
  primarySubjectTypeId = 0;
  isHiddenDiv = true;
  titleName = '';
  isShown: boolean = false ; 
  @BlockUI() blockUI: NgBlockUI;
  navigationSubscription: Subscription;
  caseServiceSubscription: Subscription;
  @Output()
  setStaffName = new EventEmitter<string>();
  clientListItem;
  countryList: CountryModel[] = [];
  defaultCustomFieldValues = [];
  subjectTypeList: SubjectTypeViewModel[];
  customFieldList: CustomFieldModel[] = [];
  referralSourceList: ReferralSourceViewModel[] = [];
  companyLocationList: CompanyLocationViewModel[] = [];
  caseRegionList: AddOrEditCaseRegionModelModel[] = [];
  casestatusList: CaseStatusViewModel[] = [];
  stafflist: StaffDetailModel[] = [];
  caseTypeList: CaseTypeViewModel[];
  submitted: boolean;
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  alreadyAddedsubjectTypeIncaseViewModel: SubjectTypeInCaseViewModel[];
  caseSummaryData: any = {
   // dueDate: [null]
  };
  addCaseModel: AddCaseModel;
  get GetCustomFieldCaseType() {
    return CustomFieldTypes;
  }
  form: FormGroup;
  constructor(
    private formBuilder: FormBuilder,
    private caseService: CaseService,
    private toasterService: ToasterService,
    private clientService: ClientService,
    private router: Router,
    private countryService: CountryService,
    private caseStatusService:CasestatusService,
    public caseRegionService: CaseRegionService,
    private caseTypeService: CasetypeService,
    private staffService:StaffService,
    private appUtils: AppUtils,
    private companyLocationService: CompanyLocationService,
    private referralSourceService: ReferralsourceService,
    private subjectTypeInCaseService: SubjectTypeInCaseService,
    private subjectTypeService: SubjectTypeService,
    private customFieldService: CustomfieldService,
    private route: ActivatedRoute,
    private modalService: NgbModal) {
    super();
    this.addCaseModel = new AddCaseModel();
    this.route.parent.params.subscribe(params => this.model.id = params.id);
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
    
        this.loadOverview();
      }
    });


  }
  loadSubjectTypes(ids: number[]): void {
   
    this.blockUI.start();
    const subscription = this.subjectTypeService.getByIds(ids).subscribe(
      (subjectTypeList) => {
        this.subjectTypeList = subjectTypeList;
        if (
          this.subjectTypeList &&
          this.subjectTypeList.length > 0 &&
          this.primarySubjectTypeId > 0
        ) {
          this.loadCustomFields(this.primarySubjectTypeId);
          this.titleName = this.getSubjectNameById(this.primarySubjectTypeId);
          // set hidden field isPinned and isPrimary to true
          this.form.controls.is_pinned.setValue('true');
          this.form.controls.is_primary.setValue('true');
          this.form.controls.subjectTypeId.setValue(this.primarySubjectTypeId);
          this.isHiddenDiv = true;
        }
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => this.blockUI.stop()
    );
    this.subscriptions.push(subscription);
  }
  get customFieldToFormArray(): FormArray {
    return this.form.controls.customFields as FormArray;
  }
  public getSubjectNameById(subjectTypeid: number): string {
    
    if(this.subjectTypeList ==undefined)
    {
      return '';
    }
    const currentSubjectTypes = this.subjectTypeList.filter(
      (x) => x.id === subjectTypeid
    );
    
    if (currentSubjectTypes.length === 0) {
      return '';
    }
    return currentSubjectTypes[0].name;
  }
  loadCustomFields(subjectTypeId: number): void {
    
    this.blockUI.start();
    const subscription = this.customFieldService
      .getBySubjectTypeId(subjectTypeId)
      .subscribe(
        (customFieldList) => {
          // add customfields
          this.customFieldList = customFieldList;
          this.defaultCustomFieldValues = [];
          const cf: FormArray = this.customFieldToFormArray;
          cf.clear();
          // manipulate data
          this.customFieldList.forEach((d, index: number) => {
            // used to require first custom field, if bydefault not required
            if (index === 0 && !d.required) {
              d.required = true;
            }

            this.defaultCustomFieldValues.push(
              this.appUtils.isJSON(d.defaultJsonValue)
                ? JSON.parse(d.defaultJsonValue)
                : d.defaultJsonValue
            );
            cf.push(
              this.formBuilder.group({
                data: this.CreateControlSetDefaultValueForCustomField(
                  d.fieldTypeId,
                  d
                ),
                ...d,
              })
            );
          });
        },
        (error: any) => {
          this.toasterService.ProcessErrorResponse(error);
        },
        () => this.blockUI.stop()
      );
    this.subscriptions.push(subscription);
  }
  CreateControlSetDefaultValueForCustomField(
    typeId: number,
    cfm: CustomFieldModel
  ) {
    switch (typeId) {
      case this.GetCustomFieldCaseType.Address:
        return this.formBuilder.group({
          country: cfm.required ? ['', Validators.required] : '',
          address1: [''],
          address2: [''],
          address3: [''],
          city: [''],
          state: [''],
          zip_code: [''],
        });

      case this.GetCustomFieldCaseType.CountryDropdown:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.Date:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.DateBirthday:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required,Validators.pattern(this.emailPattern)]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.EmailAddress:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.InputLongAnswer:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.InputShortAnswer:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.Money:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.MultipleChoiceRadio:
        const mcr = JSON.parse(cfm.defaultJsonValue);
        const selectedArrays = mcr.filter(
          (x) => x.default_value_forMultiple == 'true'
        );
        if (selectedArrays.length > 0) {
          return cfm.required
            ? [selectedArrays[0].dform_multipleChoiceRadio, Validators.required]
            : [selectedArrays[0].dform_multipleChoiceRadio];
        }
        return cfm.required ? ['', Validators.required] : '';

      case this.GetCustomFieldCaseType.MultipleChoiceDropdown:
        const defaultJsonValue = JSON.parse(cfm.defaultJsonValue);
        const selectedArray = defaultJsonValue.filter(
          (x) => x.default_value_forMultiple == 'true'
        );
        if (selectedArray.length > 0) {
          return cfm.required
            ? [selectedArray[0].dform_multiplechoices, Validators.required]
            : [selectedArray[0].dform_multiplechoices];
        }
        return cfm.required ? ['', Validators.required] : '';

      case this.GetCustomFieldCaseType.NameFirstMiddleLast:
        return this.formBuilder.group({
          first: cfm.required ? ['', Validators.required] : '',
          middle: [''],
          last: cfm.required ? ['', Validators.required] : '',
        });

      case this.GetCustomFieldCaseType.Number:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.Section:
        return cfm.defaultJsonValue ? cfm.defaultJsonValue : '';
      case this.GetCustomFieldCaseType.WebsiteUrl:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.YesOrNo:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.YesNoOrNA:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.RatingMultipleChoiceDropdown:
        const rmcrs = JSON.parse(cfm.defaultJsonValue);
        const rmcr = rmcrs.filter(
          (x) => x.default_value_forRatingDrop == 'true'
        );
        if (rmcr.length > 0) {
          return cfm.required
            ? [rmcr[0].dform_ratingMultiDrop_scale, Validators.required]
            : [rmcr[0].dform_ratingMultiDrop_scale];
        }
        return cfm.required ? ['', Validators.required] : [''];
      case this.GetCustomFieldCaseType.RatingNumber:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.RatingYesNoOrNA:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      default:
        return '';
    }
  }
  loadDefaults(): void {
    debugger;
    this.blockUI.start();
    forkJoin([
      this.countryService.getCountries(),
     // this.caseTypeService.getById(this.caseSummaryData.caseTypeId),
      this.subjectTypeInCaseService.getByCaseId(this.model.id),
      this.staffService.getstafflist()
    ]).subscribe(
      ([countryList, subjecttypeInCaseData,stafflist]) => {
        this.countryList = countryList;
        // load case type data
        //this.caseTypeData = caseTypeData;
        
        if (this.caseTypeData && this.caseTypeData.caseTypeSubjectTypes) {
          this.loadSubjectTypes(
            this.caseTypeData.caseTypeSubjectTypes.map((x) => x.subjectTypeId)
          );
          const customSubjectType = this.caseTypeData.caseTypeSubjectTypes
            .filter((x) => x.isDefault)
            .map((p) => p.subjectTypeId);
          if (customSubjectType.length > 0) {
            this.primarySubjectTypeId = customSubjectType[0];
          }
        }

        // load subject type in case data
        this.alreadyAddedsubjectTypeIncaseViewModel = subjecttypeInCaseData;
        this.stafflist=stafflist;
        console.log(stafflist);
        this.loadSubjectTypeInCaseConnections(subjecttypeInCaseData);
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => this.blockUI.stop()
    );
  }
  loadSubjectTypeInCaseConnections(model: SubjectTypeInCaseViewModel[]): void {
    // loading case types
    const ct: FormArray = this.connectionsToFormArray;
    ct.clear();
    model.forEach((d) => {
      ct.push(
        this.formBuilder.group({
          id: [d.id],
          name: [d.displayValue],
          isSelected: [false],
        })
      );
    });
  }
  converttodecimal(data:any) {
   
   if(data==undefined){
     return parseFloat('00').toFixed(2);
   }
    return parseFloat(data).toFixed(2);
     
  }
  private loadOverview(): void {
    debugger;
    this.blockUI.start();
    this.caseServiceSubscription = this.caseService.getCaseSummary(this.model.id)
      .subscribe((data: CaseViewModel) => {
        this.caseService.caseDetailSubject$.next({
          id: data.id.toString(),
          name: `${data.caseNumber} (${(data.subjects && data.subjects.length>0 ?data.subjects[0].value:'')})`,
          casetypeid:data.caseTypeId.toString()
        });
        this.caseSummaryData = data;
        console.log(data);
        this.form.controls.caseStatus.setValue(this.caseSummaryData.caseStatusId.toString());
        this.loadDefaults();
       
       
        
      }, error => {
        this.toasterService.ProcessErrorResponse(error);
        this.router.navigate(['/case']);
        this.blockUI.stop();
      }, () => {
        this.blockUI.stop();
      });
  }
  
  get connectionsToFormArray(): FormArray {
    return this.form.controls.connections as FormArray;
  }
  ngOnInit(): void {
   // this.loadDefaults();
   
    this.loadcasestatus(); 
    this.form = this.formBuilder.group({
      is_pinned: new FormControl('false'),
      is_primary: new FormControl('false'),
      subjectTypeId: new FormControl(0),
      customFields: new FormArray([]),
      connections: new FormArray([]),
      photo: [null],
      companyLocationId: [''],
      
      caseStatus:[''],
      staff:[''],
      stafflist:[''],
       caseRegionId: [''],
       caseFlags: [],
       caseServices: [],
       referralSource: [''],
       references: new FormArray([]),
       createdOn: [''],
       dueDate: [null],
       budgetdate:[null],
       caseLocation: [''],
       notes: '',
       adminnotes: '',
       schedulingnotes: '',
       budgetprice: '',
       budgethours: '',
       notesJustification:'',
       caseId:'',
       staffId:''


     });
     this.loadCharts();
  }


  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  loadCharts(): void{
    // donut start
    const centerText = {
      beforeDraw: function (chart, args, options) {
        var width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx;

        ctx.restore();
        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";
        var text = "6.75%",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }
    this.chart = new Chart('canvas', {
      type: 'doughnut',
      data: {
        labels: ['Data1', 'Data2'],
        datasets: [
          {
            data: [10, 90],
            backgroundColor: ['rgb(114,183,32)', 'rgb(182,227,169)'],
            fill: false
          },
        ]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        }
      },
      plugins: [centerText]
    });
    // donut end
    // donut start
    const centerText1 = {
      beforeDraw: function (chart, args, options) {
        var width = chart.chart.width,
          height = chart.chart.height,
          ctx = chart.chart.ctx;

        ctx.restore();
        var fontSize = (height / 114).toFixed(2);
        ctx.font = fontSize + "em sans-serif";
        ctx.textBaseline = "middle";
        var text = "70.5%",
          textX = Math.round((width - ctx.measureText(text).width) / 2),
          textY = height / 2;
        ctx.fillText(text, textX, textY);
        ctx.save();
      }
    }
    this.chart = new Chart('canvas1', {
      type: 'doughnut',
      data: {
        labels: ['Data1', 'Data2'],
        datasets: [
          {
            data: [75, 25],
            backgroundColor: ['rgb(114,183,32)', 'rgb(182,227,169)'],
            fill: false
          },
        ]
      },
      options: {
        legend: {
          display: false
        },
        tooltips: {
          enabled: false
        }
      },
      plugins: [centerText1]
    });
  }


  private loadcasestatus(): void {
    this.blockUI.start();
    this.caseStatusService.getAll().subscribe(
      (data: any) => {
        this.casestatusList = data;
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => {
        this.blockUI.stop();
      }
    );
  }
  
  getFormattedName(data: any[]):string{
   
    let name = "";
    if(data.length>0) {
      data.forEach((val,index)=>{
        name = name + val.value;
        if(index < data.length-1){
          name = name + ",";
        }
      })
    }
   
    return name;
  }
  getFormatted(data: any[]):string{
  
    let name = "";
    if(data.length>0) {
      data.forEach((val,index)=>{
        name = name + val.value;
        if(index < data.length-1){
          name = name + "/";
        }
      })
    }
   
    return name;
  }
  displayCustomField(data: any): string{
    
    if (data.fieldTypeId === this.GetCustomFieldCaseType.Address){
      const parsedValue = JSON.parse(data.value);
      let value = '';
      if (parsedValue.address1) {
        value += `${parsedValue.address1} `;
      }
      if (parsedValue.address2) {
        value += `${parsedValue.address2} `;
      }
      if (parsedValue.address3) {
        value += `${parsedValue.address3} `;
      }
      if (parsedValue.city) {
        value += `${parsedValue.city}, `;
      }
      if (parsedValue.state) {
        value += `${parsedValue.state} `;
      }
      if (parsedValue.zip_code) {
        value += `${parsedValue.zip_code} `;
      }
      return value;
    }
    if (
      data.fieldTypeId ===
      this.GetCustomFieldCaseType.NameFirstMiddleLast
    ) {
      const parsedValue = JSON.parse(data.value);
      const value = `${parsedValue.last},${parsedValue.first}`;
      if (parsedValue.middle) {
        return `${value} ${parsedValue.middle}`;
      }
      return value;
    }
    if (
      data.fieldTypeId ===
        this.GetCustomFieldCaseType.DateBirthday ||
      data.fieldTypeId === this.GetCustomFieldCaseType.Date
    ) {
      const parsedValue = JSON.parse(data.value);

      const d = new Date(
        parsedValue.year,
        parsedValue.month,
        parsedValue.day
      );
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      return `${mo} ${da},${ye}`;
    }
    if (data.value === 'null'){
    return '';
    }
    if (data && data.value){
    return data.value;
    }
    return '';
  }
  DOLFormat(data: any[]):any{
  
    let name = "";
    if(data.length>0) {
      data.forEach((val,index)=>{
        name = name + val.value;
      
        const parsedValue = JSON.parse(name);
              const d = new Date(
                parsedValue.year,
                parsedValue.month,
                parsedValue.day
              );
              
         name=`${parsedValue.month}/${parsedValue.day}/${parsedValue.year}`
        
      })
    }
   
     return name;
  }
  NavigatetoEditSubjectTypeForm(Subjecttypeid:number,id:number):void{
    
    
    this.router.navigate(['/edit-case-second-steps'], {
      queryParams: {
        caseTypeId: this.caseSummaryData.caseTypeId,
        caseId: this.model.id,
        isSubjectType:true,
        id:id,
        Subjecttypeid:Subjecttypeid,
        RedirectFrom:'addcase'
      }
    });
  }
  NextValue():void{
    debugger;
    this.isShown=true ; 
    const id = (this.caseSummaryData.caseStatusId+1).toString();
    this.form.controls.caseStatus.setValue(id.toString());

  }
  PreValue():void{
    debugger;
    this.isShown=true ; 
    const id = (this.caseSummaryData.caseStatusId-1).toString();
    this.form.controls.caseStatus.setValue(id.toString());

  }
  onClose():void{
    this.isShown=false; 
  }

  onEditSubmit(): void {
    debugger;
    this.submitted = true;
  
   
    const controls = this.form.controls;
    this.model.id = this.caseSummaryData.id;
    this.model.caseTypeId=this.caseSummaryData.caseTypeId;
    this.model.caseStatusId = parseInt(controls.caseStatus.value) ? parseInt(controls.caseStatus.value) : null;
console.log('put',this.model)
    this.caseService.updateCaseStatus(this.model).subscribe(
      (data) => {
        setTimeout(() => {
         
          this.toasterService.success('Case Status Updated successfully');
          this.loadOverview(); 
          this.loadcasestatus(); 
        }, 300);
       
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
       
      }
    );
  }
  SaveBudget():void{
    debugger;
    const controls = this.form.controls;
    this.caseBudget.caseId=this.caseSummaryData.id;
    this.caseBudget.staffId= controls.staff.value ? controls.staff.value : null;
    this.caseBudget.budgetDate=new Date(
      `${controls.budgetdate.value.month}/${controls.budgetdate.value.day}/${controls.budgetdate.value.year}`
    );
    console.log(this.caseBudget.budgetDate);
    this.caseBudget.budget=controls.budgetprice.value ? controls.budgetprice.value.toString() : controls.budgetprice.value;
    this.caseBudget.hours=controls.budgethours.value ? controls.budgethours.value.toString() : controls.budgethours.value;
    this.caseBudget.notesJustification= controls.notesJustification.value;
    this.caseService.postCaseBudget(this.caseBudget).subscribe(
      (data) => {
        setTimeout(() => {
          this.clear();
          this.toasterService.success('Budget Created successfully');
          this.loadOverview(); 
          this.loadcasestatus(); 
         // this.loadBudget(this.caseSummaryData.id);
          
          
        }, 300);
       
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
       
      }

    );
    
    
  }
  
  clear():void{
    this.form = this.formBuilder.group({
      is_pinned: new FormControl('false'),
      is_primary: new FormControl('false'),
      subjectTypeId: new FormControl(0),
      customFields: new FormArray([]),
      connections: new FormArray([]),
      photo: [null],
      companyLocationId: [''],
      
      caseStatus:[''],
      staff:[''],
      stafflist:[''],
       caseRegionId: [''],
       caseFlags: [],
       caseServices: [],
       referralSource: [''],
       references: new FormArray([]),
       createdOn: [''],
       dueDate: [null],
       budgetdate:[null],
       caseLocation: [''],
       notes: '',
       adminnotes: '',
       schedulingnotes: '',
       budgetprice: '',
       budgethours: '',
       notesJustification:'',
       caseId:'',
       staffId:''




    });

  }

  gotoClient(id: number): void {
    this.router.navigate(['/client/edit-client/', id]);
  }
  linkDetail(id: string): void {
    debugger;
    this.router.navigate(['/client/contact-show', id, 'overview']);
  }
  openForEditProfile(id:string): void{
    this.router.navigate([`/staff/edit-employee/${id}`]);
    //this.router.navigate(['staff/edit-emplpyee/', this.staffId]);
  }
}
