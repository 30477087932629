
import { CaseTypeCaseFlagModel } from '../CaseTypeCaseFlags/CaseTypeCaseFlagModel';
import { CaseTypeCaseReviewModel } from '../caseTypeCaseReview/caseTypeCaseReviewModel';
import { CaseTypeCaseServicesModel } from '../caseTypeCaseServices/caseTypeCaseServicesModel';
import { CaseTypeCustomFieldModel } from '../caseTypeCustomField/caseTypeCustomFieldModel';
import { CaseTypeDocumentTemplateModel } from '../caseTypeDocumentTemplate/caseTypeDocumentTemplateModel';
import { CaseTypeFolderTemplateModel } from '../caseTypeFolderTemplate/caseTypeFolderTemplateModel';
import { CaseTypeReferenceLabelModel } from '../caseTypeReferenceLabel/caseTypeReferenceLabelModel';
import { CaseTypeSubjectTypeModel } from '../caseTypeSubjectType/caseTypeSubjectTypeModel';
import { CaseTypeModel } from './caseTypeModel';

export class CaseTypeViewModel extends CaseTypeModel{
public caseTypeReferences: CaseTypeReferenceLabelModel[];
public caseTypeFolderTemplates: CaseTypeFolderTemplateModel[];
public caseTypeCaseFlags: CaseTypeCaseFlagModel[];
public caseTypeCustomFields: CaseTypeCustomFieldModel[];
public caseTypeCaseServices: CaseTypeCaseServicesModel[];
public caseTypeSubjectTypes: CaseTypeSubjectTypeModel[];
public caseTypeCaseReviews: CaseTypeCaseReviewModel[];
public caseTypeDocumentTemplates: CaseTypeDocumentTemplateModel[];
}
