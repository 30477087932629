import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoteFor } from 'src/app/helpers/enums/notes/noteFor';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-add-client-location-notes',
  templateUrl: './add-client-location-notes.component.html',
  styleUrls: ['./add-client-location-notes.component.css']
})
export class AddClientLocationNotesComponent implements OnInit, OnDestroy {

  entityId: string;
  moduleId: number;
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  locationSubscription: Subscription;

  constructor(private route: ActivatedRoute, 
              private locationService: LocationService) {

    this.route.params.subscribe(param => this.entityId = param.locationId);
    this.moduleId = NoteFor.ClientLocation;
    this.locationSubscription = this.locationService.locationListDetail$.subscribe((data) => {
      if (data == null) {
        return;
      }
      this.breadCrumbInputs.push(...[
        { key: 'id', value: [data.clientId.toString(), data.clientName] },
        { key: 'locationId', value: [data.locationId.toString(), data.locationName] }
      ]);
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.locationSubscription.unsubscribe();
  }

}
