import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClientContactService } from 'src/app/services/client.contact.service';

@Component({
  selector: 'app-edit-client-contact-note',
  templateUrl: './edit-client-contact-note.component.html',
  styleUrls: ['./edit-client-contact-note.component.css']
})
export class EditClientContactNoteComponent implements OnInit, OnDestroy {

  contactSubscription: Subscription;
  breadCrumbInputs: { key: string, value: string[] }[] = [];

  constructor(private clientContactService: ClientContactService) { }

  ngOnInit(): void {
    this.contactSubscription = this.clientContactService.contactDetail$.subscribe((data) => {
      if (data == null || data === undefined) {
        return;
      }

      this.breadCrumbInputs = [
        { key: 'contactId', value: [data.id, data.name] },
        { key: 'id', value: [data.clientId.toString(), data.clientName] }
      ];
    });
  }

  ngOnDestroy(): void {
    this.contactSubscription.unsubscribe();
  }

}
