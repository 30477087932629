export class AddOrEditGeneralGlobalSettingModel {
        public id: number;
        public  customerName: string;
        public  customerWebsite: string;
        public  accountSubdomain: string;
        public  country: string;
        public  language: string;
        public  pageLimit: number;
        public  distanceMeasurementUnit: string;
        public  loginRequestExpirationHours: number;
        public  mapUrl: string;
        public  systemTimeZone: string;
        public  weekStartDay: string;
}
