export class CaseStatusModel {
    public id: number;
    public name: string;
    public description: string;
    public color: string;
    public webhookUrl: string;
    public rank: number;
    public isMonitorDueDate: boolean;
    public isActive: boolean;
    public isReopenable: boolean;
}
