export class NoteEditModel {
    public id: number;
    public accessGroupId: number;
    public noteText: string;
    public isPinnedNote: boolean;
    public fileUrl: string;
    public fileName: string;
    public date: string;
    public module: number;
    public entityId: string;
    public attachment: File;
    public isAttachmentDeleted: boolean;
}