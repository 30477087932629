import { RoleAvailableFor } from 'src/app/helpers/enums/roles/role.available.for';

export class AddOrEditRoleRequestModel {
    id: string;
    name: string;
    description: string;
    availableFor: RoleAvailableFor;
    rankBelow: string;
    rolePermissions: number[];
    accessGroupIds: number[];
    defaultAccessGroup: number;
    roleCategory: number;
}