import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';

import { Router } from '@angular/router';

// for datatable
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { ClientService } from 'src/app/services/client.service';
import { HttpClient } from '@angular/common/http';
import { CaseAssignmentTypeService } from 'src/app/services/case.assignment.types.service';
import { isCommaListExpression } from 'typescript';



@Component({
  selector: 'app-case-assignment-types',
  templateUrl: './case-assignment-types.component.html',
  styleUrls: ['./case-assignment-types.component.css']
})
export class CaseAssignmentTypesComponent implements OnInit {



  // for angular datatable
  @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;

  constructor(
    private caseAssignmentTypeService: CaseAssignmentTypeService,
    private tosterService: ToasterService,
    private renderer: Renderer2,
    private router: Router) { }

  posts: object;

  ngOnInit(): void {

    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },

      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,
      order: [[0, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        self.blockUI.start();
        self.caseAssignmentTypeService.query(dataTablesParameters)
          .subscribe(resp => {
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            self.blockUI.stop();
          });
      },

      columns: [

        {
          data: 'name',
          title: 'Name'
        },
        {
          data: 'defaultName',
          title: 'Default Name'
        },
        {
          data: 'isUpdateNoticesEnabled',
          title: 'Update/File Notices',
          render: (data, type, row) => {
            const icon = `<i class='align-middle rounded-circle
             ${data ? 'fas fa-check' : 'fas fa-times text-danger'}
              fa-1x p-2'></i>`;
            return `<div class='fa-edit'>${icon}</div>`;
          }
        },
        {
          data: 'isAssignmentNoticesEnabled',
          title: 'Assignment Notices',
          render: (data, type, row) => {
            const icon = `<i class='align-middle rounded-circle
             ${data ? 'fas fa-check' : 'fas fa-times text-danger'}
             fa-1x p-2'></i>`;
            return `<div class='fa-edit'>${icon}</div>`;
          }
        },
        {
          data: 'isConfirmAssignmentNoticesEnabled',
          title: 'Confirm Assignments',
          render: (data, type, row) => {
            const icon = `<i class='align-middle rounded-circle
             ${data ? 'fas fa-check' : 'fas fa-times text-danger'}
            fa-1x p-2'></i>`;
            return `<div class='fa-edit'>${icon}</div>`;
          }
        },
        {
          data: 'isAssignToReopenEnabled',
          title: 'Assign to Reopens',
          render: (data, type, row) => {
            const icon = `<i class='align-middle rounded-circle
             ${data ? 'fas fa-check' : 'fas fa-times text-danger'}
             fa-1x p-2'></i>`;
            return `<div class='fa-edit'>${icon}</div>`;
          }
        },
        {
          data: null,
          title: 'Action ',
          orderable: false,
          render(data, type, row) {
            return `<div class='fa-edit'>
                    <button  class='btn del-btn btn-lg btn-table'>
                    <i  action-type="edit" class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
                    </button>`;
          }
        },
      ],
      rowCallback: (row, data: any) => {
        const _row = $(row);

        _row.find('[action-type="edit"]')
          .off('click')
          .on('click', () => {
            self.router.navigate(['setting/cases/edit-assignment-type', data.id]);
          });
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
    };



  }

}
