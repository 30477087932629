import { LogoModel } from './logoModel';

export class AddOrEditLogoModel {
    // public logos: LogoModel[];
    public printFile: File;
    public emailFile: File;
    public systemFile: File;
    public printId: number;
    public emailId: number;
    public systemId: number;
}
