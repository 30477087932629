import {Component, OnInit} from '@angular/core';
@Component({
    selector: 'app-calendars',
    templateUrl: './calendars.component.html',
    styleUrls: ['./calendars.component.css']
})

export class CalendarsComponent{
    constructor(){

    }



  // for filters
  applyFilter(filterData: any): void {
  }

  onResetFilters(): void {
  }

  // capture filter open and close events
  filterNavStatus = false;
  closeFilterEvent(event: any): void {
    this.filterNavStatus = event;
  }

}
