import { Component, OnInit, ViewChild, OnDestroy, Renderer2, Compiler } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';

import { Router } from '@angular/router';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';
import { StaffDtColumnName } from 'src/app/helpers/constants/staff/staff.column.name';
import { ModalService } from 'src/app/services/modal.service';
import { Page } from 'src/app/helpers/enums/customize-column/page';
import { ManagerAssignModel } from 'src/app/models/staff/assign.managers.model';
import { StaffFilterOptions, StaffSearchOptions } from 'src/app/helpers/enums/filters-and-searchs/staff-filter.options';
declare const exportNav: any;



@Component({
    selector: 'app-staff-list',
    templateUrl: './staff-list.component.html',
    styleUrls: ['./staff-list.component.css']
})


export class StaffListComponent implements OnInit, OnDestroy {
    constructor(
        private staffService: StaffService,
        private tosterService: ToasterService,
        private router: Router,
        private renderer: Renderer2,
        private modalService: ModalService) {

    }
    @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
    @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;

    dtOptions: any = {};

    dtInstance: DataTables.Api;
    rowIndex = 0;
    private searchBy?: StaffSearchOptions;
    private searchFor: string;
    filterBy?: StaffFilterOptions = null;
    filterByValue?: string = null;
    filterInRole?: string = null;
    permissionType = Permission;
    visibleColumns = [];
    isDtVisible = false;
    isDtInitialized = false;
    isBulkOperationButtonDisabled = true;
    isInitFilterAndSearch = false;
    isShowFilterAndSearch = false;
    listen: () => void;
    filterNavStatus = false;



    get staffFilterOptions(): any {
        return StaffFilterOptions;
    }

    async ngOnInit(): Promise<void> {
        this.initDataTable();
    }

    private initDataTable(): void {
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            destroy: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },
            displayStart: 0,
            paging: true,
            pagingType: 'full_numbers',
            PageLength: 10,
            lengthMenu: environment.paging.lengthMenu,
            order: [[1, 'asc']],
            autoWidth: false,
            ajax: (dataTablesParameters: any, callback) => {
                self.blockUI.start();
                dataTablesParameters.filterBy = self.filterBy;
                dataTablesParameters.filterByValue = self.filterByValue;
                dataTablesParameters.filterInRole = self.filterInRole;
                dataTablesParameters.searchBy = self.searchBy;
                dataTablesParameters.searchFor = self.searchFor;
                dataTablesParameters.isDtInitialized = self.isDtInitialized;
                self.staffService.query(dataTablesParameters)
                    .subscribe(resp => {
                        this.visibleColumns = resp.visibleColumns;
                        self.dtInstance.page.len(resp.pageLength);
                        self.isDtInitialized = true;
                        self.showColumns();
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                        self.blockUI.stop();
                    }, error => {
                        self.tosterService.ProcessErrorResponse(error);
                        self.blockUI.stop();
                    });
            },
            columns: [
                {
                    data: null,
                    title: `<input type="checkbox" action-type="select-unSelect-all"/>`,
                    name: 'select',
                    orderable: false,
                    render: (data, type, row) => {
                        // const disabled = '';
                        return `<input type='checkbox' value = '${row.id}' action-type= "select-row" />`;
                    }
                },
                {
                    data: StaffDtColumnName.StaffName,
                    name: StaffDtColumnName.StaffName.toLowerCase(),
                    title: 'Name',
                    render: (data, type, row) => {
                        const src = row.profileImgUrl ?? '/assets/img/avatars/user.svg';
                        const html = HasPermission.validate([Permission.ViewStaffMembers])
                            && this.filterBy !== StaffFilterOptions.DeletedStaffs ?
                            ` <a class='link m-r-10' action-type='staff-detail'><img src="${src}"  onerror="" class='${row.profileImgUrl ? 'shimmer' : ''} avatar img-fluid rounded-circle mr-1'> ${row.name}</a>` : `<span>${row.name}</span>`;
                        return html;
                    }
                },
                {
                    data: StaffDtColumnName.title,
                    name: StaffDtColumnName.title.toLowerCase(),

                    title: 'Title'
                },
                {
                    data: StaffDtColumnName.email,
                    name: StaffDtColumnName.email.toLowerCase(),

                    title: 'Email'
                },
                {
                    data: StaffDtColumnName.role,
                    name: StaffDtColumnName.role.toLowerCase(),
                    orderable: false,

                    title: 'Role'
                },
                {
                    data: StaffDtColumnName.isActive,
                    name: StaffDtColumnName.isActive.toLowerCase(),
                    orderable: false,
                    title: 'Status',
                 render: (data, type, row) => {
                 let html = "";
            
           
           
             if(data==false)
              {
                html = 'De-Active'
               
              }
              else
              {
                  html='Active'
              }
             
             return html;
           }
                },
                {
                    data: StaffDtColumnName.manager,
                    name: StaffDtColumnName.manager.toLowerCase(),
                    orderable: false,

                    title: 'Manager'
                },
                {
                    data: StaffDtColumnName.accountCode,
                    name: StaffDtColumnName.accountCode.toLowerCase(),

                    title: 'Account Code'
                },
                {
                    data: StaffDtColumnName.fullAddress,
                    name: StaffDtColumnName.fullAddress.toLowerCase(),
                    orderable: false,

                    title: 'Full Address'
                },
                // {
                //     data: StaffDtColumnName.homePhone,
                //     name: StaffDtColumnName.homePhone.toLowerCase(),

                //     title: 'Home Phone'
                // },
                // {
                //     data: StaffDtColumnName.contactNumber,
                //     name: StaffDtColumnName.contactNumber.toLowerCase(),

                //     title: 'Contact Number'
                // },
                {
                    data: StaffDtColumnName.fax,
                    name: StaffDtColumnName.fax.toLowerCase(),

                    title: 'Fax'
                },
                {
                    data: StaffDtColumnName.address1,
                    name: StaffDtColumnName.address1.toLowerCase(),

                    title: 'Address Line 1'
                },
                {
                    data: StaffDtColumnName.address2,
                    name: StaffDtColumnName.address2.toLowerCase(),

                    title: 'Address Line 2'
                },
                {
                    data: StaffDtColumnName.address3,
                    name: StaffDtColumnName.address3.toLowerCase(),

                    title: 'Address Line 3'
                },
                {
                    data: StaffDtColumnName.city,
                    name: StaffDtColumnName.city.toLowerCase(),

                    title: 'City'
                },
                {
                    data: StaffDtColumnName.state,
                    name: StaffDtColumnName.state.toLowerCase(),

                    title: 'State'
                },
                {
                    data: StaffDtColumnName.zip,
                    name: StaffDtColumnName.zip.toLowerCase(),
                    title: 'Zip'
                },
                {
                    data: StaffDtColumnName.country,
                    name: StaffDtColumnName.country.toLowerCase(),

                    title: 'Country'
                },
                {
                    data: StaffDtColumnName.dob,
                    name: StaffDtColumnName.dob.toLowerCase(),
                    title: 'Date Of Birth'
                },
                {
                    data: StaffDtColumnName.lastLoggedOn,
                    name: StaffDtColumnName.lastLoggedOn.toLowerCase(),
                    title: 'Last Login'
                },
                {
                    data: StaffDtColumnName.created,
                    name: StaffDtColumnName.created.toLowerCase(),
                    title: 'Created Date'
                },
                {
                    data: StaffDtColumnName.lastPasswordChange,
                    name: StaffDtColumnName.lastPasswordChange.toLowerCase(),
                    orderable: false,
                    title: 'Last Password Change'
                },
                {
                    data: StaffDtColumnName.isActiveLogin,
                    name: StaffDtColumnName.isActiveLogin.toLowerCase(),
                    orderable: false,
                    title: 'Is Login Active'
                },
                {
                    data: StaffDtColumnName.avgRating,
                    name: StaffDtColumnName.avgRating.toLowerCase(),
                    orderable: false,
                    title: 'Avg. Rating'
                },
                {
                    data: null,
                    title: 'Action ',
                    name: 'action',
                    orderable: false,
                    render: (data, type, row) => {
                        const editHtml = HasPermission.validate([Permission.EditStaffMembers])
                            && this.filterBy !== StaffFilterOptions.DeletedStaffs ?
                            `<div class='fa-edit'>
                        <button  class='btn del-btn btn-lg btn-table'>
                        <i  action-type="edit" class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
                        </button>` : '';

                        const deleteHtml = HasPermission.validate([Permission.DeleteStaffMembers]) ?
                            `<button  class='btn del-btn btn-lg btn-table'>
                        <i action-type="${this.filterBy !== StaffFilterOptions.DeletedStaffs ? 'delete' : 'un-delete'}"
                        class='align-middle rounded-circle bg-delete bg-delete-btn fas ${this.filterBy !== StaffFilterOptions.DeletedStaffs ? 'fa-trash-alt' : 'fa-trash-restore-alt'}
                         fa-1x p-2 text-danger'></i>
                        </button>
                        </div>` : '';

                        return `${editHtml} ${deleteHtml}`;
                    }
                },
            ],
            rowCallback: (row, data: any) => {
                const _row = $(row);
                _row.find('[action-type="staff-detail"]')
                    .off('click')
                    .on('click', () => {
                        this.linkDetail(data.id);
                    });

                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', () => {
                        self.router.navigate(['/staff/edit-employee/', data.id]);
                    });
                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', () => {
                        self.deleteStaff([data.id]);
                    });
                _row.find('[action-type="un-delete"]')
                    .off('click')
                    .on('click', () => {
                        self.unDeleteStaff([data.id]);
                    });
                _row.find('[action-type = "select-row"]')
                    .off('click').on('click', (e: any) => {
                        this.onSelectedOrUnselectRow(e);
                    });
            },
            // Use this attribute to enable the responsive extension
            responsive: true,
            // Configure the buttons
            // Configure the buttons
            buttons: [
                'columnsToggle',
                'colvis',
                'copy',
                'print',
                'excel',
                {
                    text: 'Some button',
                    key: '1',
                    action(e, dt, node, config) {
                        alert('Button activated');
                    }
                }
            ]
        };
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            this.dtInstance = dtInstance;
            const header = dtInstance.columns().header();
            $(header).find('[action-type="select-unSelect-all"]')
                .off('change').on('change', (e: any) => {
                    this.onSelectAllRow(e.target.checked);
                });
        });
    }

    onSelectAllRow(isChecked: boolean): void {
        const rows = this.dtInstance.rows().nodes();
        const checkBoxes = [...rows.$('[action-type= "select-row"]')]
            .filter(row => !row.hasAttribute('disabled'));
        if (isChecked) {
            checkBoxes.forEach(row => {
                this.renderer.addClass(row, 'checked');
                (row as HTMLInputElement).checked = true;
            });
        } else {
            checkBoxes.forEach(row => {
                this.renderer.removeClass(row, 'checked');
                (row as HTMLInputElement).checked = false;
            });
        }
        this.enableOrDisableDeleteButton();
    }

    linkDetail(id: string): void {
        this.router.navigate(['/staff', id]);
    }

    onSelectedOrUnselectRow(event: any): void {
        if (event.target.checked) {
            this.renderer.addClass(event.target, 'checked');
        } else {
            this.renderer.removeClass(event.target, 'checked');
        }
        this.toggleControls();
    }

    ngOnDestroy(): void {
        if (this.listen) {
            this.listen();
        }
    }
    // capture filter open and close events
    closeFilterEvent(event: any): void {
        this.filterNavStatus = event;
    }

    applyFilter(filterData: any): void {
        const filterIdArr = filterData.filter?.split('_');
        if (filterIdArr && filterIdArr.length > 0) {
            this.filterBy = Number(filterIdArr[0]);
            if (filterIdArr.length > 1) {
                this.filterByValue = filterIdArr[1];
            }
        }
        this.filterInRole = filterData.filterIn === '-1' ? null : filterData.filterIn;
        this.searchBy = filterData.searchBy;
        this.searchFor = filterData.searchFor;
        this.reload();
    }

    reload(): void {
        this.dtInstance.ajax.reload();
    }

    onResetFilters(): void {
        this.filterBy = null;
        this.filterByValue = null;
        this.filterInRole = null;
        this.searchBy = null;
        this.searchFor = '';
        this.reload();
    }

    openAssignManagerModal(): void {
        const selectedStaffCount = this.getSelectedStaffIds().length;
        this.modalService.openModalForAssignManagers(selectedStaffCount).subscribe((managerId) => {
            const model = new ManagerAssignModel();
            model.managerId = managerId;
            model.staffIds = this.getSelectedStaffIds();
            this.blockUI.start();
            this.staffService.assignManagers(model).subscribe(() => {
                this.modalService.closeAllModals();
                this.dtInstance.ajax.reload(null, false);
                this.tosterService.success('Manager has been assigned successfully to selected staff(s)');
                this.blockUI.stop();
            }, error => {
                this.tosterService.ProcessErrorResponse(error);
                this.blockUI.stop();
            });
        });
    }

    onBulkDelete(): void {
        this.deleteStaff(this.getSelectedStaffIds());
    }

    onBulkUnDelete(): void {
        this.unDeleteStaff(this.getSelectedStaffIds());
    }


    deleteStaff(userIds: string[]): void {
        if (!confirm('Are you sure you want to delete selected staff(s)?')) {
            return;
        }
        this.blockUI.start();
        this.staffService.delete(userIds).subscribe(() => {
            this.reload();
            this.tosterService.success('Staff(s) deleted successfully.');
            this.blockUI.stop();
        }, error => {
            this.tosterService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }

    unDeleteStaff(userIds: string[]): void {
        if (!confirm('Are you sure you want to undelete selected staff(s)?')) {
            return;
        }
        this.blockUI.start();
        this.staffService.undelete(userIds).subscribe(() => {
            this.reload();
            this.tosterService.success('Staff(s) undeleted successfully.');
            this.blockUI.stop();
        }, error => {
            this.tosterService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }

    openCustomizedListModal(): void {
        this.modalService.openModalForConfiguringCustomizedList(Page.StaffList).subscribe(() => {
            this.isDtVisible = false;
            this.isDtInitialized = false;
            this.dtInstance.ajax.reload(null, false);
            this.tosterService.success('Customized list has been updated successfully');
        });
    }
    private showColumns(): void {
        const visibleColumnsSelector = this.visibleColumns.map(item => `${item.toLowerCase()}:name`)
            .concat(['action:name', 'select:name']);
        const visibleIndexes = this.dtInstance.columns(visibleColumnsSelector).indexes().toArray();
        this.dtInstance.columns().every(function () {
            const colIndex = this.index();
            this.visible(visibleIndexes.includes(colIndex));
        });
        this.isDtVisible = true;
    }
    private getSelectedStaffIds(): any[] {
        const staffIds = [];
        const rows = this.dtInstance.rows().nodes();
        [...rows.$('.checked')].forEach(item => staffIds.push($(item).val()));
        return staffIds;
    }

    private toggleControls(): void {
        this.setSelectAllCheckbox();
        this.enableOrDisableDeleteButton();
    }

    private setSelectAllCheckbox(): void {
        const rows = this.dtInstance.rows().nodes();
        if (rows !== null) {
            const header = this.dtInstance.columns().header();
            const selectAllElement = $(header).find('[action-type="select-unSelect-all"]')[0] as any;
            const selelectedRows = [...rows.$('[action-type= "select-row"]')]
                .filter(row => !row.hasAttribute('disabled'));
            selectAllElement.checked = selectAllElement != null && selelectedRows.length > 0
                && selelectedRows.every(row => row.classList.contains('checked'));
        }
    }

    private enableOrDisableDeleteButton(): void {
        const rows = this.dtInstance.rows().nodes();
        this.isBulkOperationButtonDisabled = [...rows.$('.checked')].length === 0;
    }

}

