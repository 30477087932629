<main class="content py-0">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-md-12 pt-7">
        <div class="w-100 pt-4">
          <div class="row">
            <div class="col-md-12">
            <app-breadcrumb></app-breadcrumb>
            </div>
          </div>

          <div class="row mt-4 mb-4">
            <div class="col-auto d-none d-sm-block">
              <h3 class="page-title"><strong>New Custom Field</strong></h3>
            </div>

          </div>

          <div class="row mx-0" *blockUI="'container-blockui-new-shared-custom-field'">
            <div class="col-12 px-0 mb-5">
              <form class="new-case-form" name="form" [formGroup]="form">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="card-text">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="label">Label<sup class="clsStric">*</sup></label>

                                    <input type="text" formControlName="label" class="form-control mb-3" id="label"
                                      name="label" autocomplete="off" />
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="help_text">Help Text
                                      <i class="
                                          align-middle
                                          fas
                                          fa-info-circle
                                          ml-2
                                          text-light-black
                                        " placement="top"
                                        ngbTooltip="Displayed under the field to provide the user with additional information."></i></label>

                                    <input type="text" formControlName="help_text" class="form-control mb-3"
                                      id="help_text" name="help_text" autocomplete="off" />
                                  </div>
                                </div>
                              </div>

                              <hr />
                              <div class="row">
                                <div class="col-md-6" *ngIf="!isForEnabled">
                                  <div class="form-group has-search">
                                    <label for="use_for">Use for<sup class="clsStric">*</sup></label>

                                    <select class="form-control" formControlName="use_for">
                                      <option value="" selected="selected">---Select---</option>
                                      <option [value]="item.id" *ngFor="
                                          let item of customFieldCaseCategoriesLabel;
                                          let i = index
                                        ">
                                        {{ item.name }}
                                      </option>
                                    </select>
                                  </div>
                                </div>

                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="field_type">Field type<sup class="clsStric">*</sup>
                                    </label>
                                    <select class="form-control" formControlName="field_type" id="field_type">
                                      <option value="" selected="selected">---Select---</option>
                                      <option [value]="item.id" *ngFor="
                                          let item of customFieldCaseTypesLabel;
                                          let i = index
                                        ">
                                        {{ item.name }}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div class="row dynamicForm">
                                <div class="col-md-12">
                                  <!-- on country (dropdown) based -->
                                  <div class="form-group has-search" *ngIf="
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.CountryDropdown
                                    ">
                                    <label for="text_field">Default Value<sup class="clsStric">*</sup>
                                    </label>

                                    <select class="form-control" formControlName="dform_Country">
                                      <option [value]="items.id" [selected]="items.isDefault" *ngFor="
                                          let items of countries;
                                          let i = index
                                        ">
                                        {{ items.name }}
                                      </option>
                                    </select>
                                  </div>

                                  <!-- input long answer -->
                                  <div class="form-group has-search" *ngIf="
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.InputLongAnswer
                                    ">
                                    <label for="dform_Longinput">Default Value
                                    </label>

                                    <textarea type="text" rows="5" formControlName="dform_Longinput"
                                      class="form-control p-3"></textarea>
                                  </div>

                                  <!-- short input answer -->
                                  <div class="form-group has-search" *ngIf="
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.InputShortAnswer
                                    ">
                                    <label for="dform_Shortinput">Default Value
                                    </label>

                                    <input type="text" formControlName="dform_Shortinput" class="form-control" />
                                  </div>

                                  <!-- money -->
                                  <div class="form-group has-search" *ngIf="
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.Money
                                    ">
                                    <label for="dform_Money">Default Value
                                    </label>

                                    <input type="text" formControlName="dform_Money" class="form-control"
                                      (keypress)="appUtil._keyUp($event)" />
                                  </div>

                                  <!-- dynamically add multiple dropdown  -->

                                  <div formArrayName="multipleChoiceDropdown" class="form-group has-search" *ngIf="
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.MultipleChoiceDropdown
                                    ">
                                    <label for="text_field" class="mr-3">
                                      Add Choices
                                    </label>
                                    <div *ngFor="
                                        let getDynamic of multipleChoiceDropdown()
                                          .controls;
                                        let i = index;
                                        let l = count;
                                      ">
                                      <div class="row" [formGroupName]="i">
                                        <div class="col-md-1">
                                          <label class="
                                              mx-auto
                                              my-auto
                                              d-flex
                                              text-center
                                              align-items-center align-bottom
                                              justify-content-center
                                              pt-3
                                            ">
                                            <input type="radio" value="Male" name="default_value_forMultiple"
                                              formControlName="default_value_forMultiple" [value]="true"
                                              (change)="removeAll(i)" />
                                          </label>
                                        </div>

                                        <div class="col-md-3">
                                          <input formControlName="dform_multiplechoices" class="form-control mb-2"
                                            type="text" ngClass="i" [readonly]="i+1 <= l-1" />
                                        </div>
                                        <div class="col-md-1">
                                          <button class="btn del-btn btn-lg btn-table" (click)="removeEmployee(i)">
                                            <i action-type="delete" class="
                                                align-middle
                                                rounded-circle
                                                bg-delete bg-delete-btn
                                                fas
                                                fa-trash-alt fa-1x
                                                p-2
                                                text-danger
                                              "></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <button class="
                                        btn
                                        bg-blue
                                        text-white
                                        font-weight-500
                                      " (click)="addEmployee()">
                                      <i class="fas fa-plus"></i> Add
                                    </button>
                                  </div>

                                  <!-- dynamic end -->

                                  <!-- dynamically add multiple dropdown  -->

                                  <div formArrayName="multipleChoiceRadio" class="form-group has-search" *ngIf="
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.MultipleChoiceRadio
                                    ">
                                    <label for="text_field" class="mr-3">Add Choices<sup class="clsStric">*</sup>
                                    </label>
                                    <div *ngFor="
                                        let getDynamic of multipleChoiceRadio()
                                          .controls;
                                        let i = index;
                                        let l = count;
                                      ">
                                      <div class="row" [formGroupName]="i">
                                        <div class="col-md-1">
                                          <label class="
                                              mx-auto
                                              my-auto
                                              d-flex
                                              text-center
                                              align-items-center align-bottom
                                              justify-content-center
                                              pt-3
                                            ">
                                            <input type="radio" value="Male" name="default_value_forMultipleRadio"
                                              formControlName="default_value_forMultipleRadio" [value]="true"
                                              (change)="removeAll2(i)" />
                                          </label>
                                        </div>

                                        <div class="col-md-3">
                                          <input formControlName="dform_multipleChoiceRadio" class="form-control mb-2"
                                            type="text" [readonly]="i+1 <= l-1" />
                                        </div>
                                        <div class="col-md-1">
                                          <button class="btn del-btn btn-lg btn-table" (click)="removeEmployee2(i)">
                                            <i action-type="delete" class="
                                                align-middle
                                                rounded-circle
                                                bg-delete bg-delete-btn
                                                fas
                                                fa-trash-alt fa-1x
                                                p-2
                                                text-danger
                                              "></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <button class="
                                        btn
                                        bg-blue
                                        text-white
                                        font-weight-500
                                      " (click)="addMultipleChoiceRadio()">
                                      <i class="fas fa-plus"></i> Add
                                    </button>
                                  </div>

                                  <!-- dynamic end -->

                                  <!-- Number -->
                                  <div class="form-group has-search" *ngIf="
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.Number
                                    ">
                                    <label for="dform_Number">Default Value
                                    </label>

                                    <input type="text" formControlName="dform_Number" class="form-control"
                                      (keypress)="appUtil._keyUp($event)" />
                                  </div>

                                  <!-- yes or no 12 -->
                                  <div class="form-group has-search" *ngIf="
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.YesOrNo
                                    ">
                                    <label> Default Value</label>
                                    <div>
                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                          mr-5
                                        ">
                                        <input type="radio" id="dform_YesnoPart2_1" class="custom-control-input"
                                          formControlName="dform_YesnoPart2" name="dform_YesnoPart2" value="true" />
                                        <label class="custom-control-label" for="dform_YesnoPart2_1">Yes</label>
                                      </div>
                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                        ">
                                        <input type="radio" id="dform_YesnoPart2_2" class="custom-control-input"
                                          formControlName="dform_YesnoPart2" name="dform_YesnoPart2" value="false"
                                          checked />
                                        <label class="custom-control-label" for="dform_YesnoPart2_2">No</label>
                                      </div>
                                    </div>
                                  </div>

                                  <!-- yes, no or n/a 13-->
                                  <div class="form-group has-search" *ngIf="
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.YesNoOrNA
                                    ">
                                    <label> Default Value</label>
                                    <div>
                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                          mr-5
                                        ">
                                        <input type="radio" id="dform_Yesnona_1" class="custom-control-input"
                                          formControlName="dform_Yesnona" name="dform_Yesnona" value="yes" />
                                        <label class="custom-control-label" for="dform_Yesnona_1">Yes</label>
                                      </div>
                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                        ">
                                        <input type="radio" id="dform_Yesnona_2" class="custom-control-input"
                                          formControlName="dform_Yesnona" name="dform_Yesnona" value="no" />
                                        <label class="custom-control-label" for="dform_Yesnona_2">No</label>
                                      </div>

                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                        ">
                                        <input type="radio" id="dform_Yesnona_3" class="custom-control-input"
                                          formControlName="dform_Yesnona" name="dform_Yesnona" value="na" checked />
                                        <label class="custom-control-label" for="dform_Yesnona_3">N/A</label>
                                      </div>
                                    </div>
                                  </div>

                                  <!-- dynamically rating multiple choice dropdown   -->

                                  <div formArrayName="ratingMultipleChoiceDrop" class="form-group has-search" *ngIf="
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.RatingMultipleChoiceDropdown
                                    ">
                                    <label for="text_field" class="mr-3">Add Choices
                                    </label>
                                    <div *ngFor="
                                        let getDynamic of ratingMultipleChoiceDrop()
                                          .controls;
                                        let i = index;
                                        let l = count;
                                      ">
                                      <div class="row" [formGroupName]="i">
                                        <div class="col-md-1">
                                          <label class="
                                              mx-auto
                                              my-auto
                                              d-flex
                                              text-center
                                              align-items-center align-bottom
                                              justify-content-center
                                              pt-3
                                            ">
                                            <input type="radio" name="default_value_forRatingDrop"
                                              formControlName="default_value_forRatingDrop" [value]=""
                                              (change)="removeallRatingDrop(i)" />
                                          </label>
                                        </div>

                                        <div class="col-md-3">
                                          <input formControlName="dform_ratingMultiDrop" class="form-control mb-2"
                                            type="text" [readonly]="i+1 <= l-1" />

                                          <input formControlName="dform_ratingMultiDrop_scale" class="form-control mb-2"
                                            type="number" [max]="100" [min]="1" (keypress)="apputil._keyUp($event)"
                                            [readonly]="i+1 <= l-1" />
                                        </div>
                                        <div class="col-md-1">
                                          <button class="btn del-btn btn-lg btn-table" (click)="removeRatingDrop(i)">
                                            <i action-type="delete" class="
                                                align-middle
                                                rounded-circle
                                                bg-delete bg-delete-btn
                                                fas
                                                fa-trash-alt fa-1x
                                                p-2
                                                text-danger
                                              "></i>
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <button class="
                                        btn
                                        bg-blue
                                        text-white
                                        font-weight-500
                                      " (click)="addRatingDrop()">
                                      <i class="fas fa-plus"></i> Add
                                    </button>
                                  </div>

                                  <!-- dynamic end -->

                                  <!-- rating yes, no or n/a 23-->
                                  <div class="form-group has-search" *ngIf="
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.RatingYesNoOrNA
                                    ">
                                    <label> Default Value</label>
                                    <div>
                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                          mr-5
                                        ">
                                        <input type="radio" id="dform_RatingYesno_1" class="custom-control-input"
                                          formControlName="dform_RatingYesno" name="dform_RatingYesno" value="yes" />
                                        <label class="custom-control-label" for="dform_RatingYesno_1">Yes</label>
                                      </div>
                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                        ">
                                        <input type="radio" id="dform_RatingYesno_2" class="custom-control-input"
                                          formControlName="dform_RatingYesno" name="dform_RatingYesno" value="no" />
                                        <label class="custom-control-label" for="dform_RatingYesno_2">No</label>
                                      </div>

                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                        ">
                                        <input type="radio" id="dform_RatingYesno_3" class="custom-control-input"
                                          formControlName="dform_RatingYesno" name="dform_RatingYesno" value="na"
                                          checked />
                                        <label class="custom-control-label" for="dform_RatingYesno_3">N/A</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-12" [ngClass]="{
                                    'no-border':
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.Date ||
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.DateBirthday ||
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.InputLongAnswer ||
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.InputShortAnswer ||
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.MultipleChoiceDropdown ||
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.MultipleChoiceRadio
                                  }">
                                  <!-- Redact -->
                                  <div class="form-group has-search" *ngIf="
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.Date ||
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.DateBirthday ||
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.InputLongAnswer ||
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.InputShortAnswer ||
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.MultipleChoiceDropdown ||
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.MultipleChoiceRadio
                                    ">
                                    <label> Redact</label><i class="
                                        align-middle
                                        fas
                                        fa-info-circle
                                        ml-2
                                        text-light-black
                                      " data-toggle="tooltip" data-placement="top" placement="top"
                                      ngbTooltip="If yes, the information provided by the user may be redacted, requiring users to have permission to 'View Sensitive Data' in order to view the entire value."></i>
                                    <div>
                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                          mr-5
                                        ">
                                        <input type="radio" id="date_Yesno_1" class="custom-control-input"
                                          formControlName="redact_Yesno" name="redact_Yesno" value="true" checked />
                                        <label class="custom-control-label" for="date_Yesno_1">Yes</label>
                                      </div>
                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                        ">
                                        <input type="radio" id="date_Yesno_2" class="custom-control-input"
                                          formControlName="redact_Yesno" name="redact_Yesno" value="false" />
                                        <label class="custom-control-label" for="date_Yesno_2">No</label>
                                      </div>
                                    </div>
                                  </div>

                                  <!-- Select redact on  -->
                                  <div class="form-group has-search" *ngIf="
                                      this.form.controls.redact_Yesno.value ==
                                      'true' && (
                                         this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.Date ||
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.DateBirthday ||
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.InputLongAnswer ||
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.InputShortAnswer ||
                                      this.form.controls.field_type.value ==
                                        GetCustomFieldCaseType.MultipleChoiceDropdown ||
                                      this.form.controls.field_type.value == GetCustomFieldCaseType.MultipleChoiceRadio
                                      )
                                    ">
                                    <label for="text_field">Redact Method
                                      <i class="
                                          align-middle
                                          fas
                                          fa-info-circle
                                          ml-2
                                          text-light-black
                                        " data-toggle="tooltip" data-placement="top" placement="top"
                                        ngbTooltip="Mask all, some, or none of the output, when displaying redacted data on the screen."></i></label>

                                    <select class="form-control" formControlName="redactMethod">
                                      <option [value]="item.id" *ngFor="let item of customFieldRedactMethodLabel">
                                        {{item.name}}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div class="row">
                                <div class="col-md-6 mb-2">
                                  <div class="form-group has-search">
                                    <label id="track" for="require_or_not">
                                      Required</label><i class="
                                        align-middle
                                        fas
                                        fa-info-circle
                                        ml-2
                                        text-light-black
                                      " data-toggle="tooltip" data-placement="top" placement="top"
                                      ngbTooltip="If yes, the user will be required to fill-in this field."></i>
                                    <div>
                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                          mr-5
                                        ">
                                        <input type="radio" id="customRadio3" class="custom-control-input"
                                          formControlName="require_or_not" name="require_or_not" value="true" />
                                        <label class="custom-control-label" for="customRadio3">Yes</label>
                                      </div>
                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                        ">
                                        <input type="radio" id="customRadio4" class="custom-control-input"
                                          formControlName="require_or_not" name="require_or_not" value="false" />
                                        <label class="custom-control-label" for="customRadio4">No</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-6 mb-2">
                                  <div class="form-group has-search" *ngIf="this.form.controls.use_for.value == GetCustomFieldCaseCategories.Case
                              || this.form.controls.use_for.value == GetCustomFieldCaseCategories.Contact || 
                              this.form.controls.use_for.value == GetCustomFieldCaseCategories.Subject">
                                    <label id="track" for="internal">
                                      Internal</label><i class="
                                        align-middle
                                        fas
                                        fa-info-circle
                                        ml-2
                                        text-light-black
                                      " data-toggle="tooltip" data-placement="top" placement="top"
                                      ngbTooltip="If yes, client contacts will not be able to view or fill-in this field."></i>
                                    <div>
                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                          mr-5
                                        ">
                                        <input type="radio" id="customRadio5" class="custom-control-input"
                                          formControlName="internal" name="internal" value="true" />
                                        <label class="custom-control-label" for="customRadio5">Yes</label>
                                      </div>
                                      <div class="
                                          custom-control
                                          custom-radio
                                          custom-control-inline
                                        ">
                                        <input type="radio" id="customRadio6" class="custom-control-input"
                                          formControlName="internal" name="internal" value="false" />
                                        <label class="custom-control-label" for="customRadio6">No</label>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- {{ form.value | json }} -->

                  <div class="form-group w-100">
                    <div class="col-lg-12 text-right">
                      <button class="
                          btn
                          font-weight-500
                          px-4
                          py-2
                          bg-white
                          text-dark-blue
                          border-btn
                        " type="button" (click)="onCancel()">
                        Cancel
                      </button>
                      <button (click)="onSubmit()" class="
                          btn
                          bg-blue
                          text-white
                          px-4
                          py-2
                          font-weight-500
                          ml-2
                        " type="submit">
                        Save Custom Field
                        <span class="fa fa-arrow-right"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>