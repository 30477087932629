import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

// for datatable
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { ClientService } from 'src/app/services/client.service';

//model
import { NgbModal, ModalDismissReasons, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ModalService } from 'src/app/services/modal.service';
import { Subject, Subscription } from 'rxjs';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';

@Component({
  selector: 'app-caffiliates',
  templateUrl: './caffiliates.component.html',
  styleUrls: ['./caffiliates.component.css']
})
export class CaffiliatesComponent implements OnInit {

  //for angular datatable
  @BlockUI('container-blockui-affiliates') blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;
  clientId: number;
  subsciptions: Subscription[] = [];
  dtTrigger: Subject<any> = new Subject();
  permissionType = Permission;

  constructor(private clientService: ClientService,
              private tosterService: ToasterService,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: ModalService) {
    this.route.parent.params.subscribe(param => this.clientId = Number(param.id));
  }

  //model
  closeResult = '';
  form: FormGroup;

  ngOnInit(): void {
    this.initDataTable();
    const subscription = this.clientService.listenToLoadAffiliates().subscribe((data) => {
      this.reloadDataTable();
    });
    this.subsciptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subsciptions.forEach(x => x.unsubscribe());
  }

  openAddModal() {
    this.modalService.openModalForAddAffiliates(this.clientId);
  }

  reloadDataTable(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.ajax.reload();
    });
  }

  private initDataTable() {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"><"clear">',
      serverSide: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
      },

      displayStart: 0,
      paging: false,
      pagingType: 'full_numbers',
      ordering: false,
      ajax: (dataTablesParameters: any, callback) => {
        self.blockUI.start();
        self.clientService.getSelectedAffiliates(this.clientId)
          .subscribe(resp => {
            callback({
              data: resp
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            this.router.navigate(['/client']);
            self.blockUI.stop();
          });
      },

      columns: [
        {
          data: 'affiliateName',
          title: 'Name',
          render: function (data, type, row) {
            const html = HasPermission.validate([Permission.ViewClientAffiliates]) && !row.isDisabled ?
                `<a class='link m-r-10' action-type = "link-affiliate">${row.affiliateName}</a>`
                : `<span>${row.affiliateName}</span>`;
            return html;
          }
        },
        {
          data: 'id',
          title: HasPermission.validate([Permission.AssignOrUnassignClientAffiliates]) ? 'Action ' : '',
          orderable: false,
          render: function (data, type, row) {
            return HasPermission.validate([Permission.AssignOrUnassignClientAffiliates]) ?
                `<div class='fa-edit'>
                <button  class='btn del-btn btn-lg btn-table'>
                <i action-type="delete" class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
                </button>
                </div>` : '';
          }
        },
      ],
      // Use this attribute to enable the responsive extension
      responsive: true,
      rowCallback: (row, data: any) => {
        const _row = $(row);
        _row.find('[action-type="delete"]')
          .off('click')
          .on('click', () => {
            self.deleteAffiliate(data.id, data.affiliateName);
          });

        _row.find('[action-type = "link-affiliate"]')
          .off('click')
          .on('click', (e: any) => {
            this.linkAffiliate(data.affiliateId);
          });
      }
    };
  }

  private linkAffiliate(affiliateId: number): void {
    this.router.navigate(['../', affiliateId], {relativeTo: this.route.parent});
  }

  deleteAffiliate(id: number, affiliateName: string): void {
    if (!confirm("Are you sure you want to delete '" + affiliateName + "' from Affiliates?")) {
      return;
    }
    this.blockUI.start();
    this.clientService.deleteAffiliate(id).subscribe((data: any) => {
      this.reloadDataTable();
      this.tosterService.success(data);
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }
}
