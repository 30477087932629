import { LocationModel } from '../Location/location.model';
import { NoteListModel } from '../note/note.list.model';

export class ClientAddModel
{
    public id: number;
    public  name: string;
    public  accountCode: string;
    public  salesPersonId: string;
    public  caseManagerId: string;
    public  webSite: string;
    public  billingEmail: string;
    public  generalNotes: string;
    public  casePolicies: string;
    public  updatePolicies: string;
    public  invoicePolicies: string;
    public sendOverdueInvoices: boolean;
    public show_Bill_To_Location: boolean;
    public tags: string[];
    public caseTypeRestriction: string;
    public Location: LocationModel;
    public caseManagerName: string;
    public salesPersonName: string;
    public pinnedNotes: NoteListModel[];
}

