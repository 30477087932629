import { Component, OnDestroy, OnInit } from '@angular/core';

import { Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ComponentDestoryHelper } from 'src/app/helpers/component-destory.helper';
import { LanguagesDetails } from 'src/app/helpers/constants/settings/general-global-setting/language';
import { MeasurementUnitDetails } from 'src/app/helpers/constants/settings/general-global-setting/measurement-unit';
import { SystemTimezoneDetails } from 'src/app/helpers/constants/settings/general-global-setting/system-timezone';
import { WeekStartDayDetails } from 'src/app/helpers/constants/settings/general-global-setting/week-start-day';
import { AddOrEditGeneralGlobalSettingModel } from 'src/app/models/general-global-setting/addOrEditGeneralGlobalSettingModel';
import { CountryModel } from 'src/app/models/shared/country.model';
import { CountryService } from 'src/app/services/country.service';
import { GeneralGlobalSettingService } from 'src/app/services/general-global-setting.service';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment.prod';
@Component({
  selector: 'app-general-global-setting',
  templateUrl: './general-global-setting.component.html',
  styleUrls: ['./general-global-setting.component.css']
})
export class GeneralGlobalSettingComponent extends ComponentDestoryHelper implements OnInit, OnDestroy {

  constructor(private toasterService: ToasterService,
              private countryService: CountryService, private generalGlobalSettingService: GeneralGlobalSettingService,
              private router: Router) {
    super();
    this.model = new AddOrEditGeneralGlobalSettingModel();
    this.model.id = 0;
   }
   @BlockUI('blockui-general-global-setting') blockUI: NgBlockUI;
   countrySelectListItem: CountryModel[] = [];
   languageListItem: {key: string, value: string}[] = [];
   measurementListItem: {key: string, value: string}[] = [];
   systemTimezoneListItem: {key: string, value: string}[] = [];
   weekStartDayListItem: {key: string, value: string}[] = [];
   model: AddOrEditGeneralGlobalSettingModel;
  form: FormGroup;
  submitted = false;
  pageLimit = environment.paging.lengthMenu;

  ngOnDestroy(): void{
    this.ngAfterOnDestroy();
  }

  ngOnInit(): void {
    LanguagesDetails.forEach( (key, value) => {
      this.languageListItem.push({key, value});
    });
    MeasurementUnitDetails.forEach( (key, value) => {
      this.measurementListItem.push({key, value});
    });
    SystemTimezoneDetails.forEach( (key, value) => {
      this.systemTimezoneListItem.push({key, value});
    });
    WeekStartDayDetails.forEach( (key, value) => {
      this.weekStartDayListItem.push({key, value});
    });
    this.loadCountries();
    this.loadGeneralGlobalSettings();
    this.form = new FormGroup({
      customer_name: new FormControl('', Validators.required),
      customer_website: new FormControl(''),
      account_subdomain: new FormControl('', Validators.required),
      country: new FormControl('', Validators.required),
      language: new FormControl('', Validators.required),
      page_size_limit: new FormControl('', Validators.required),
      measurement_unit: new FormControl('', Validators.required),
      login_exp_hour: new FormControl('', Validators.required),
      system_timezone: new FormControl('', Validators.required),
      map_url: new FormControl('', Validators.required),
      day: new FormControl('', Validators.required)
    });
  }
  private loadGeneralGlobalSettings(): void{
    this.blockUI.start();
    const subscription = this.generalGlobalSettingService.get().subscribe((data: AddOrEditGeneralGlobalSettingModel) => {
      if (data == null) {
        return;
      }
      this.model.id = data.id;
      const controls = this.form.controls;
      controls.customer_name.setValue(data.customerName);
      controls.customer_website.setValue(data.customerWebsite);
      controls.account_subdomain.setValue(data.accountSubdomain);
      controls.country.setValue(data.country);
      controls.language.setValue(data.language);
      controls.page_size_limit.setValue(data.pageLimit);
      controls.measurement_unit.setValue(data.distanceMeasurementUnit);
      controls.login_exp_hour.setValue(data.loginRequestExpirationHours);
      controls.system_timezone.setValue(data.systemTimeZone);
      controls.map_url.setValue(data.mapUrl);
      controls.day.setValue(data.weekStartDay);
    }, (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
    }, () => this.blockUI.stop());
    this.subscriptions.push(subscription);
  }
  private loadCountries(): void {
    this.blockUI.start();
    const subscription = this.countryService.getCountries().subscribe((data: any) => {
      this.countrySelectListItem = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
    this.subscriptions.push(subscription);
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    const controls  = this.form.controls;
    this.model.customerName =  controls.customer_name.value;
    this.model.customerWebsite =  controls.customer_website.value;
    this.model.accountSubdomain =  controls.account_subdomain.value;
    this.model.country =  controls.country.value;
    this.model.language =  controls.language.value;
    this.model.pageLimit =  Number(controls.page_size_limit.value);
    this.model.distanceMeasurementUnit = controls.measurement_unit.value;
    this.model.loginRequestExpirationHours =  controls.login_exp_hour.value.toString();
    this.model.systemTimeZone =  controls.system_timezone.value;
    this.model.mapUrl =  controls.map_url.value;
    this.model.weekStartDay =  controls.day.value;

    this.blockUI.start();
    const subscription = this.generalGlobalSettingService.post(this.model).subscribe(resposne => {
      setTimeout(() => {
       this.onCancel();
      }, 10);
      setTimeout(() => {
        this.toasterService.success('General global settings updated successfully');
      }, 300);
      this.blockUI.stop();
    },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
    this.subscriptions.push(subscription);
  }
  onCancel(): void {
    this.router.navigate(['/setting']);
  }
}
