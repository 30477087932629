
export class CustomFieldAddModel  {
    public id: number;
    public label: string;
    public helpText: string;
    public defaultJsonValue: string;
    public useForId: number;
    public fieldTypeId: number;
    public redactMethod?: number;
    public redact?: boolean;
    public required: boolean;
    public internal: boolean;
}
