<main class="content py-0">
   
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-auto d-none d-sm-block">
                            <div class="d-flex mb-2">
                                <h3 class="page-title"><strong>Calendar</strong> </h3>
                                <span class="dropdown subject-dropdown show ml-2">
                                    <a class="btn text-orange dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        All Invoices
                                    </a>
                                
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </span>
                            </div>
                            <p>Get all the Expenses list at one place</p>
                        </div>

                        <div class="col-auto ml-auto text-right mt-n1">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                    <li class="filter-item px-0 filter-icon" (click)="filterNavStatus = true">
                                        <button class="btn btn-lg bg-blue text-white">
                                            <i class="align-middle fas fa-filter"></i>
                                        </button>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div class="row pt-4">
                    <div class="col-6 col-md-3">
                        <div class="card d-block">
                            <div id="datetimepicker-calendar"></div>
                        </div>
                        <div class="card d-block">
                            <div class="card-body">
                                <h4 class="mb-4 calendar-title">Calendars</h4>
                                <div class="calendarList">
                                    <div class="calendar-event w-100 d-flex justify-content-between py-2">
                                        <div class="event-icon">
                                            <i class="align-middle fas fa-calendar-day mr-2"></i> Event
                                        </div>
                                        <div class="event-checkbox">
                                            <form>
                                                <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="customCheck" name="event" checked>
                                                <label class="custom-control-label" for="customCheck"></label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div class="calendar-event w-100 d-flex justify-content-between py-2">
                                        <div class="event-icon">
                                            <i class="align-middle fas fa-clipboard-list mr-2"></i> Reminders
                                        </div>
                                        <div class="event-checkbox">
                                            <form>
                                                <div class="custom-control custom-checkbox">
                                                <input type="checkbox" class="custom-control-input" id="customCheck1" name="reminder">
                                                <label class="custom-control-label" for="customCheck1"></label>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>    
                    </div>
                    <div class="col-12 col-md-9">
                        <div class="card">
                            <div class="card-body">
                                
                                <app-calendar-view></app-calendar-view>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>


<!--Calendar Event Date Modal -->

<!-- <div id="modal-view-event-add" class="modal modal-top fade calendar-modal">
    <div class="modal-dialog modal-dialog-centered calendar-modal-dialog">
        <div class="modal-content">
            <form id="add-event">
                <div class="modal-body calendar-body">
                    <h4 class="mb-0"><a class="text-dark-blue font-weight-bold" href="case_list_detail.html">Case assignment</a></h4>
                    <small>(Surveillance)</small> 
                    <hr>       
                    <div class="form-group">
                        <label class="text-dark d-block font-weight-bold">6/29/20</label>
                        <i class="fas fa-circle text-success mr-2"></i>In progress
                    </div>
                    <hr>
                    <div class="form-group">
                        <label class="text-dark mb-0">6:30 a.m. start time</label>
                    </div> 
                    <hr>
                    <div class="row">     
                        <div class="form-group col-md-6">
                            <h5 class="font-weight-bold">Case No:</h5>
                            <hr>
                            <a href="#" class="text-dark-blue">SV18149-02</a>
                        </div>
                        <div class="form-group col-md-6">
                            <h5 class="font-weight-bold">Subject:</h5>
                            <hr>
                            <a href="#" class="text-dark-blue">Ramos, Lanette</a>
                        </div>
                    </div> 
                    <div class="form-group">
                        <h5 class="font-weight-bold">Address:</h5>
                        <hr>
                        <label class="text-dark mb-0 d-block">14719 Terry Knoll Drive</label>
                        <label class="text-dark mb-0 d-block">Whittier, CA 90604</label>
                        <a class="text-dark-blue" href="">(view map)</a>
                    </div>
                    <div class="form-group">
                        <h5 class="font-weight-bold">Assigned to:</h5>
                        <hr>
                        <label class="text-dark mb-0 d-block">CHRIS PETITTI</label>
                        <label class="text-dark mb-0 d-block">DYANA SPELLMAN</label>
                    </div>     
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn bg-blue text-white" >Edit</button>
                    <button type="button" class="btn bg-blue text-white" >Duplicate</button>
                    <button type="button" class="btn bg-blue text-white" >Create Case Update</button>      
                </div>
            </form>
        </div>
    </div>
</div> -->



   <!-- Filter right sidenav -->
   <app-calendar-filters (onApplyFilter)="applyFilter($event)" 
   (resetFilters)="onResetFilters()" [filterStatusParent]="filterNavStatus"  (closeBtnFilterStatus)="closeFilterEvent($event)"  ></app-calendar-filters>