<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-12 pt-7">
                <div class="w-100 pt-3 position-relative">
                    <div class="row mb-2 mb-xl-3">
                        <div class="col-auto d-none d-sm-block p-0">
                            <button type="button" [routerLink]="['/client']" class="btn btn-bck-title"><span class="align-middle fas fa-chevron-left icon-font mr-2"></span>Back to List</button>
                        </div>
                        <div class="col float-right pr-0">
                            <div class="col-12 fa-client-title">{{clientName}}</div>
                        </div>
                        <div class="col float-right pr-0">
                            <nav>
                                <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0 float-right">
                                    <li class="filter-item">
                                        <div class="btn-group dropleft dropleft-btn">
                                            <div ngbDropdown class="d-inline-block">
                                                <button class="btn btn-outline-primary btn-lg bulkaction-dropdown" id="dropdownBasic1" ngbDropdownToggle > New</button>
                                                <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                  <button ngbDropdownItem (click)="onNewContactClick()">Address</button>
                                                  <button ngbDropdownItem (click)="onNewLocation()">Attorney - Defense</button>
                                                  <button ngbDropdownItem>Business Associate</button>
                                                  <button ngbDropdownItem>New Claimant</button>
                                                  <button ngbDropdownItem (click)="onNewNote()">Client</button>

                                                  <button ngbDropdownItem>Defendant</button>
                                                  <button ngbDropdownItem>Doctor</button>
                                                  <button ngbDropdownItem >Employee</button>

                                                  <button ngbDropdownItem>Employer</button>
                                                  <button ngbDropdownItem>Insured</button>
                                                  <button ngbDropdownItem >Subject</button>

                                                  <button ngbDropdownItem>Supervisor</button>
                                                  <button ngbDropdownItem>Suspect</button>
                                                  <button ngbDropdownItem >Vehicle</button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="filter-item">
                                        <div class="btn-group dropleft dropleft-btn">
                                            <div ngbDropdown class="d-inline-block">
                                                <button class="btn btn-outline-primary btn-lg bulkaction-dropdown" id="dropdownBasic1" > <i class="fas fa-pen"></i> Edit</button>
                                                
                                              </div>
                                        </div>
                                    </li>
                                    <li class="filter-item">
                                        <div class="btn-group dropleft dropleft-btn">
                                            <div ngbDropdown class="d-inline-block">
                                                <button class="btn btn-lg   btn btn-danger" id="dropdownBasic1">Delete</button>
                                              </div>
                                        </div>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                    <app-breadcrumb *ngIf="loadBreadCrumb" [breadCrumbInputs]="breadCrumbInputs"></app-breadcrumb>
                     <!-- START TABS DIV -->
                    <div class="row">
                        <div class="col-12">
                            <div class="tabbable-responsive">
                                <div class="tabbable detail-tab col-12 pl-0">
                                    <!-- your navs bound to current route fragments -->
                                    <ul ngbNav [activeId]="currentPath" (navChange)="onNavChange($event)"
                                        class="nav-tabs mb-4">
                                        <li [ngbNavItem]="tab.path" *ngFor="let tab of tabDetails">
                                            <a ngbNavLink [routerLink]="[tab.path]">{{ tab.title }}</a>
                                        </li>
                                    </ul>
                                    <!-- and an actual outlet elsewhere -->
                                    <router-outlet (activate)="componentAdded($event)"></router-outlet>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</main>
