import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-new-case-update',
  templateUrl: './new-case-update.component.html',
  styleUrls: ['./new-case-update.component.css']
})
export class NewCaseUpdateComponent implements OnInit {

  constructor(private fb: FormBuilder) { }
  form: FormGroup;

  users = [{

    id: 1,
    name: 'Paul'
  },
  {
    id: 2,
    name: 'rahul'
  }];

  selectedAccount = 'Adam';
  accounts = [
    { name: 'Adam', email: 'adam@email.com', age: 12, country: 'United States', child: { state: 'Active' } },
    { name: 'Homer', email: 'homer@email.com', age: 47, country: '', child: { state: 'Active' } },
    { name: 'Samantha', email: 'samantha@email.com', age: 30, country: 'United States', child: { state: 'Active' } },
    { name: 'Amalie', email: 'amalie@email.com', age: 12, country: 'Argentina', child: { state: 'Active' } },
    { name: 'Estefanía', email: 'estefania@email.com', age: 21, country: 'Argentina', child: { state: 'Active' } },
    { name: 'Adrian', email: 'adrian@email.com', age: 21, country: 'Ecuador', child: { state: 'Active' } },
    { name: 'Wladimir', email: 'wladimir@email.com', age: 30, country: 'Ecuador', child: { state: 'Inactive' } },
    { name: 'Natasha', email: 'natasha@email.com', age: 54, country: 'Ecuador', child: { state: 'Inactive' } },
    { name: 'Nicole', email: 'nicole@email.com', age: 43, country: 'Colombia', child: { state: 'Inactive' } },
    { name: 'Michael', email: 'michael@email.com', age: 15, country: 'Colombia', child: { state: 'Inactive' } },
    { name: 'Nicolás', email: 'nicole@email.com', age: 43, country: 'Colombia', child: { state: 'Inactive' } }
  ];


  // for tags
  selectedCompanies;
  companies: any[] = [];
  companiesNames = ['Uber', 'Microsoft', 'Flexigen'];

  ngOnInit(): void {

    this.form = new FormGroup({
      accessGroup: new FormControl(),
      subject: new FormControl(),
      activity: new FormControl(),
      date: new FormControl(),
      time: new FormControl(),
      preset: new FormControl(),
      title: new FormControl(),
      body: new FormControl()
    });

    // for tags
    this.companiesNames.forEach((c, i) => {
      this.companies.push({ id: i, name: c });
    });

  }

  addTagFn(name: any): any {
    return { name, tag: true };
  }

}
