import { Component, OnInit, ChangeDetectorRef, ViewChild, Renderer2 } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';


import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { ClientService } from 'src/app/services/client.service';
import { ClientDtColumnName } from 'src/app/helpers/constants/client/client.column.name';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';

declare const exportNav: any;
type SearchOption = { id: number, value: string, selected: boolean };

@Component({
  selector: 'app-payment-methods-list',
  templateUrl: './payment-methods-list.component.html',
  styleUrls: ['./payment-methods-list.component.css']
})
export class PaymentMethodsListComponent implements OnInit {



  constructor(private clientService: ClientService,
    private tosterService: ToasterService,
    private renderer: Renderer2,
    private router: Router,
    public cdr: ChangeDetectorRef) { }

  // for filters
  formodel: string;
  frm: FormGroup;
  filterOptions: any;

  @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;
  dtRendered = true;
  visibleColumns = [];
  isDtVisible = false;
  isDtInitialized = false;
  permissionType = Permission;
  hasPermissionType = HasPermission;

  // for filters
  private searchBy?: number;
  private searchFor: string;
  private filterByOptionId = 0;
  private filterByItemId: string;

  get filterByOption(): number {
    return this.filterByOptionId;
  }

  users: any = [];
  isBulkActionDisabled = true;


  // capture filter open and close events
  filterNavStatus = false;
  closeFilterEvent(event: any): void {
    this.filterNavStatus = event;
  }

  ngOnInit(): void {
    this.initDataTable();
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtInstance = dtInstance;
      const header = dtInstance.columns().header();
      $(header).find('[action-type="select-unSelect-all"]')
        .off('change').on('change', (e: any) => {
          this.onSelectAllRow(e.target.checked);
        });
    });
  }

  private initDataTable(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      destroy: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },
      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,
      order: [[1, 'asc']],
      autoWidth: false,
      ajax: (dataTablesParameters: any, callback) => {
        self.blockUI.start();
        dataTablesParameters.searchBy = self.searchBy;
        dataTablesParameters.searchFor = self.searchFor;
        dataTablesParameters.filterOptionId = self.filterByOptionId;
        dataTablesParameters.filterItemId = self.filterByItemId;
        dataTablesParameters.isDtInitialized = self.isDtInitialized;
        self.clientService.getClients(dataTablesParameters)
          .subscribe(resp => {
            this.visibleColumns = resp.visibleColumns;
            self.dtInstance.page.len(resp.pageLength);
            self.isDtInitialized = true;
            self.showColumns();
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            self.blockUI.stop();
          });
      },
      columns: [
        {
          data: null,
          title: `<input type="checkbox" action-type="select-unSelect-all" />`,
          name: 'select',
          orderable: false,
          render: (data, type, row) => {
            return `<input type='checkbox' value = '${row.id}' action-type= "select-row"/>`;
          }
        },
        {
          data: ClientDtColumnName.clientName,
          name: ClientDtColumnName.clientName.toLowerCase(),
          title: 'Name',
          render: (data, type, row) => {
            const html = HasPermission.validate([Permission.ViewClients]) && !row.isDeleted ?
              `<a class='link m-r-10' action-type='link-detail'>${row.name}</a>` : `<span>${row.name}</span>`;
            return html;
          }
        },
        {
          data: ClientDtColumnName.accountCode,
          name: ClientDtColumnName.accountCode.toLowerCase(),
          title: 'Account Code',
        },
        {
          data: ClientDtColumnName.salesPersonName,
          name: ClientDtColumnName.salesPersonName.toLowerCase(),
          title: 'Sales Person',
          orderable: false,
        },
        {
          data: ClientDtColumnName.caseManagerName,
          name: ClientDtColumnName.caseManagerName.toLowerCase(),
          title: 'Case Manager',
          orderable: false,
        },
        {
          data: ClientDtColumnName.locationCount,
          name: ClientDtColumnName.locationCount.toLowerCase(),
          title: 'Offices',
          orderable: false,
        },
        {
          data: ClientDtColumnName.contactCount,
          name: ClientDtColumnName.contactCount.toLowerCase(),
          title: 'Contacts',
          orderable: false,
        },
        {
          data: ClientDtColumnName.affiliateCount,
          name: ClientDtColumnName.affiliateCount.toLowerCase(),
          title: 'Affiliates',
          orderable: false,
        },
        {
          data: ClientDtColumnName.creationDate,
          name: ClientDtColumnName.creationDate.toLowerCase(),
          title: 'Created',
          orderable: false,
        },
        {
          data: 'name',
          title: (HasPermission.validate([Permission.DeleteClients]) || HasPermission.validate([Permission.EditClients])) ? 'Action' : '',
          name: 'action',
          orderable: false,
          render: (data, type, row) => {
            const disabled = '';
            const permissionDelete = HasPermission.validate([Permission.DeleteClients]);
            const permissionEdit = HasPermission.validate([Permission.EditClients]);
            const deleteButton = permissionDelete ? `<button  class='btn del-btn btn-lg btn-table' action-type="delete">
            <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
              </button>`
              : '';
            const undeleteButton = permissionDelete ? `<button  class='btn del-btn btn-lg btn-table' action-type="un-delete">
              <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-restore-alt  fa-1x p-2 text-danger'></i>
              </button>`
              : '';
            const editButton = !row.isDeleted && permissionEdit
              ? `<button  class='btn del-btn btn-lg btn-table' action-type="edit" alt="undelete">
              <i class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
                </button>`
              : '';

            return `<div class='fa-edit'>${editButton}
                    ${row.isDeleted ? undeleteButton : deleteButton}
                    </div>`;
          }
        },
      ],
      rowCallback: (row, data: any) => {
        const _row = $(row);

        _row.find('[action-type="link-detail"]')
          .off('click')
          .on('click', () => {
            self.linkDetail(data.id);
          });
        _row.find('[action-type="edit"]')
          .off('click')
          .on('click', () => {
            self.edit(data.id);
          });
        _row.find('[action-type="delete"]')
          .off('click')
          .on('click', () => {
            self.delete([data.id]);
          });
        _row.find('[action-type="un-delete"]')
          .off('click')
          .on('click', () => {
            self.unDelete([data.id]);
          });
        _row.find('[action-type = "select-row"]')
          .off('click').on('click', (e: any) => {
            this.onSelectedOrUnselectRow(e);
          });
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
    };
  }

  onSelectedOrUnselectRow(event: any): void {
    if (event.target.checked) {
      this.renderer.addClass(event.target, 'checked');
    } else {
      this.renderer.removeClass(event.target, 'checked');
    }
    this.toggleControls();
  }

  private toggleControls(): void {
    this.setSelectAllCheckbox();
    this.enableOrDisableDeleteButton();
  }
  private setSelectAllCheckbox(): void {
    const rows = this.dtInstance.rows().nodes();
    if (rows !== null) {
      const header = this.dtInstance.columns().header();
      const selectAllElement = $(header).find('[action-type="select-unSelect-all"]')[0] as any;
      const selelectedRows = [...rows.$('[action-type= "select-row"]')];
      selectAllElement.checked = selectAllElement != null && selelectedRows.length > 0
        && selelectedRows.every(row => row.classList.contains('checked'));
    }
  }

  private showColumns(): void {
    const visibleColumnsSelector = this.visibleColumns.map(item => `${item.toLowerCase()}:name`)
      .concat(['action:name', 'select:name']);
    const visibleIndexes = this.dtInstance.columns(visibleColumnsSelector).indexes().toArray();
    this.dtInstance.columns().every(function () {
      const colIndex = this.index();
      this.visible(visibleIndexes.includes(colIndex));
    });
    this.isDtVisible = true;
  }

  private reloadDataTable(): void {
    this.dtInstance.ajax.reload();
  }

  // for filters
  applyFilter(filterData: any): void {
    this.searchBy = Number(filterData.searchBy);
    this.searchFor = filterData.searchFor;

    const filterIdArr = filterData.filter?.split('_');
    if (filterIdArr && filterIdArr.length > 0) {
      this.filterByOptionId = Number(filterIdArr[0]);
      if (filterIdArr.length > 1) {
        this.filterByItemId = filterIdArr[1];
      }
    }
    this.reloadDataTable();
  }

  onResetFilters(): void {
    this.searchBy = null;
    this.searchFor = '';
    this.filterByOptionId = 0;
    this.filterByItemId = '';
    this.reloadDataTable();
  }

  delete(ids: number[]): void {
    if (!confirm('Are you sure you want to delete selected client(s)?')) {
      return;
    }
    this.blockUI.start();
    this.clientService.deleteClient(ids).subscribe(() => {
      this.reloadDataTable();
      this.tosterService.success('Client(s) has been deleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  unDelete(ids: number[]): void {
    if (!confirm('Are you sure you want to undelete selected client(s)?')) {
      return;
    }
    this.blockUI.start();
    this.clientService.undeleteClient(ids).subscribe(() => {
      this.reloadDataTable();
      this.tosterService.success('Client(s) has been undeleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  onSelectAllRow(isChecked: boolean): void {
    const rows = this.dtInstance.rows().nodes();
    const checkBoxes = [...rows.$('[action-type= "select-row"]')];
    if (isChecked) {
      checkBoxes.forEach(row => {
        this.renderer.addClass(row, 'checked');
        (row as HTMLInputElement).checked = true;
      });
    } else {
      checkBoxes.forEach(row => {
        this.renderer.removeClass(row, 'checked');
        (row as HTMLInputElement).checked = false;
      });
    }
    this.enableOrDisableDeleteButton();
  }
  private enableOrDisableDeleteButton(): void {

    const rows = this.dtInstance.rows().nodes();
    this.isBulkActionDisabled = [...rows.$('.checked')].length === 0;
  }



  linkDetail(id: number): void {
    this.router.navigate(['/client/', id]);
  }

  edit(id: number): void {
    this.router.navigate(['/client/edit-client/', id]);
  }


}
