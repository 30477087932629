import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Page } from '../helpers/enums/customize-column/page';

@Injectable({
    providedIn: 'root'
})
export class CustomizedColumnService {
    constructor(private http: HttpClient) {
    }

    public getPageSize(moduleId: Page): Observable<number> {
        return this.http.get<number>(`${environment.apiBaseUrl}/CustomizedList/get-page-size/${moduleId}`);
    }

    public getById(moduleId: Page): Observable<{columns: any, pageSize: number}> {
        return this.http.get<{columns: any, pageSize: number}>(`${environment.apiBaseUrl}/CustomizedList/${moduleId}`);
    }
    public patch(moduleId: Page, columns: number[], pageSize: number): Observable<any> {
        return this.http.patch<any>(`${environment.apiBaseUrl}/CustomizedList?id=${moduleId}&columns=${columns.join('&columns=')}&pageSize=${pageSize}`, null);
    }
}
