<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{header}} :</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group has-search row">
            <label for="renewedOn" class="col-md-4 my-auto mx-auto">Renewed on<sup class="clsStric">*</sup></label>
            <div class="col-sm-8">
                <input type="date" formControlName="renewedOn" class="form-control mb-3 ">
                <div *ngIf="isSubmitted && form.controls.renewedOn.errors" class="text-danger">
                    <div *ngIf="form.controls.renewedOn.errors.required">
                        Renewed on is required
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group has-search row">
            <label for="expiresOn"  class="col-md-4  my-auto mx-auto">Expires on<sup class="clsStric">*</sup></label>
            <div class="col-sm-8">
                <input type="date" formControlName="expiresOn" class="form-control mb-3">
                <div *ngIf="isSubmitted && form.controls.expiresOn.errors" class="text-danger">
                    <div *ngIf="form.controls.expiresOn.errors.required">
                        Expires on is required
                    </div>
                </div>


                

            </div>
        </div>
        <div class="modal-footer border-0">
            <button type="button" class="btn btn-outline-dark"
                (click)="activeModal.dismiss('Cancel click')">Cancel</button>
            <button type="submit" class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2">Renew License </button>
        </div>
    </form>
</div>