<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>New Subject Type</strong> </h3>
                        </div>
                    </div>


                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form" [formGroup]="form" *blockUI="'new-case-subject'">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card">

                                            <div class="card-body">

                                                <div class="card-text">

                                                    <div class="row">

                                                        <div class="col-md-12">

                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group has-search">
                                                                        <label for="name">Name<sup
                                                                                class="clsStric">*</sup></label>

                                                                        <input type="text" formControlName="name"
                                                                            class="form-control mb-3" id="name"
                                                                            name="name" autocomplete="off">
                                                                        <div *ngIf="submitted && form.controls.name.errors && form.controls.name.errors.required"
                                                                            class="text-danger">
                                                                            Name is required!
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6">

                                                                </div>
                                                            </div>

                                                            <div class="row">
                                                                <div class="col-md-6">
                                                                    <div class="form-group has-search">
                                                                        <label for="default_notes">Description</label>


                                                                        <input type="text" formControlName="description"
                                                                            class="form-control mb-3" id="description"
                                                                            name="description" autocomplete="off">

                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <hr>


                                                            <!-- new dynamic ui -->

                                                            <div class="row">
                                                                <div class="col-12">
                                                                    <p class="font-weight-bold">Build a form for this
                                                                        subject type by adding custom fields below:</p>
                                                                </div>
                                                            </div>

                                                            <table class="table table-striped border">
                                                                <thead>
                                                                    <tr>

                                                                        <th scope="col">
                                                                            <!-- Field -->
                                                                        </th>
                                                                        <th scope="col">Highlight <i
                                                                                class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                                data-toggle="tooltip"
                                                                                data-placement="top" placement="top"
                                                                                ngbTooltip="Highlighted fields will be displayed on the subject list within a case. The first field cannot be highlighted because it will already be visible on the screen."></i>
                                                                        </th>

                                                                        <th scope="col">Cross Reference <i
                                                                                class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                                data-toggle="tooltip"
                                                                                data-placement="top" placement="top"
                                                                                ngbTooltip="Cross-Referenced fields are used to find similar subjects with matching criteria. Not all field types can be cross-referenced."></i>
                                                                        </th>
                                                                        <th scope="col">Searchable <i
                                                                                class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                                data-toggle="tooltip"
                                                                                data-placement="top" placement="top"
                                                                                ngbTooltip="Making a field searchable allows you to look for its values across all subjects."></i>
                                                                        </th>
                                                                        <th scope="col">Reopenable <i
                                                                                class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                                data-toggle="tooltip"
                                                                                data-placement="top" placement="top"
                                                                                ngbTooltip="Reopenable fields copy their field values to new instances of the subject when the corresponding case is reopened."></i>
                                                                        </th>


                                                                        <th></th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody formArrayName="customSubjectFields" cdkDropList
                                                                    (cdkDropListDropped)="onDrop($event)">
                                                                    <tr *ngFor="let itemrow of form.controls.customSubjectFields['controls']; index as i; let l = last"
                                                                        [formGroupName]="i" cdkDrag cdkDragLockAxis="y">
                                                                        <th class="col-xs">
                                                                            <div class="drag-handle">
                                                                                <svg class="bi bi-list handle"
                                                                                    width="1em" height="1em"
                                                                                    viewBox="0 0 16 16"
                                                                                    fill="currentColor"
                                                                                    xmlns="http://www.w3.org/2000/svg">
                                                                                    <path fill-rule="evenodd"
                                                                                        d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
                                                                                        clip-rule="evenodd" />
                                                                                </svg>
                                                                                <!-- {{ i }} -->
                                                                            </div>
                                                                        </th>

                                                                        <td>
                                                                            <select class="form-control"
                                                                                (change)="OnCustomFieldChange($event)"
                                                                                formControlName="customSubjectId">
                                                                                <option
                                                                                    *ngFor="let cc of customSubject;index as i"
                                                                                    [selected]="i == 0" [value]="cc.id">
                                                                                    {{cc.label}} :: {{cc.type}}
                                                                                </option>
                                                                            </select>
                                                                        </td>

                                                                        <td>
                                                                            <input type="checkbox"
                                                                                formControlName="highlightCheck"
                                                                                class="form-control ">
                                                                        </td>
                                                                        <td *ngIf="itemrow.value.isInternal">
                                                                            <span>N/A</span>
                                                                        </td>
                                                                        <td *ngIf="!itemrow.value.isInternal">
                                                                            <input type="checkbox"
                                                                                formControlName="crossCheck"
                                                                                class="form-control ">
                                                                        </td>
                                                                        <td>
                                                                            <input type="checkbox"
                                                                                formControlName="searchCheck"
                                                                                class="form-control ">
                                                                        </td>
                                                                        <td>
                                                                            <input type="checkbox"
                                                                                formControlName="reopenCheck"
                                                                                class="form-control ">
                                                                        </td>

                                                                        <td>
                                                                            <button
                                                                            (click)="addRowAtIndex(i)"
                                                                            class=" border-0 btn btn-success"><i
                                                                                class="fas fa-plus"></i></button>
                                                                            <button (click)="deleteRow(i)"
                                                                                class="ml-2 border-0 btn btn-danger"><i
                                                                                    class="fas fa-trash-alt"></i></button>
                                                                        </td>
                                                                        <td>
                                                                            <div *ngIf="submitted && form.controls.customSubjectFields.controls[i].controls.customSubjectId.errors"
                                                                                class="text-danger w-100 d-block">
                                                                                <div
                                                                                    *ngIf="this.form.controls.customSubjectFields.controls[i].controls.customSubjectId.errors.unique">
                                                                                    Duplicate custom case type are not
                                                                                    allowed
                                                                                </div>
                                                                                <div
                                                                                    *ngIf="this.form.controls.customSubjectFields.controls[i].controls.customSubjectId.errors.required">
                                                                                    Custom case type is required
                                                                                </div>
                                                                            </div>

                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>




                                                            <div class="row">
                                                                <div class="col-md-12">
                                                                    <button type="button" class="btn btn-primary"
                                                                        (click)="addNewRows()">Add Line </button>
                                                                    <button type="button" class="btn btn-primary ml-3"
                                                                        (click)="openCustomFieldPopup()">Add Custom
                                                                        Field</button>
                                                                </div>
                                                            </div>


                                                            <!-- end -->

                                                            <hr>


                                                            <div class="row">
                                                                <div class="col-md-6 mb-2">
                                                                    <label><b> Make this
                                                                            subject type available on the following case
                                                                            types:</b><sup class="clsStric">*</sup>
                                                                    </label>
                                                                    <div class="form-check mt-2"
                                                                        formArrayName="case_types">
                                                                        <div class="form-check mt-2"
                                                                            *ngFor="let ct of caseTypesToFormArray.controls; let i = index"
                                                                            [formGroupName]="i">
                                                                            <input class="form-check-input"
                                                                                type="checkbox"
                                                                                formControlName="isSelected"
                                                                                id="casetypes_{{i}}">
                                                                            <label class="form-check-label"
                                                                                for="lbl_casetypes_{{i}}">
                                                                                {{ct.value.name}}
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        *ngIf="submitted && form.controls.case_types.errors">
                                                                        <div *ngIf="submitted && form.controls.case_types.errors.required"
                                                                            class="text-danger">
                                                                            Please select atleast one case type
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button
                                                class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn"
                                                type="button" (click)="onCancel()"> Cancel</button>
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2"
                                                type="submit" (click)="onSubmit()">Save Subject Type <span
                                                    class="fa fa-arrow-right"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>