export enum ClientContactFilterOptions {
    None = 1,
    WithoutMFA = 2,
    WithStaleAccount = 3,
    WithStalePassword = 4,
    WithVulnerablePasswords = 5,
    DeletedContacts = 6,
    AccessLevel = 7,
    SalesPerson = 8
}

export enum ClientContactSearchOptions {
    Name = 1,
    Title = 2,
    Email = 3,
    officePhone = 4,
    Tags = 5,
    AccountCode = 6,
    BirthDay = 7,
    StateOrProvince = 8,
    Location = 9,
    ClientName = 10,
    ClientCity = 11,
    ClientStateOrProvince = 12,
    ClientTag = 13,
    CaseManager = 14
}