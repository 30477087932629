export enum  PhoneCategory{
  Mobile = 1,
  Home = 2,
  Office = 3,
}
// export const PhoneCategoryType: new Map<PhoneCategory, string> = {
//   [PhoneCategory.Mobile]: "Mobile Number",
//   [PhoneCategory.Home]: "Home Number",
//   [PhoneCategory.Office]: "Office Number",
// };
export const PhoneCategoryMapping = new Map<number, string>([
  [PhoneCategory.Mobile, 'Mobile Phone'],
  [PhoneCategory.Home, 'Home Phone'],
  [PhoneCategory.Office, 'Office Phone']
]);
