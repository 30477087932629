import {Component, OnInit} from '@angular/core';
@Component({
    selector: 'app-event-map',
    templateUrl: './event-map.component.html',
    styleUrls: ['./event-map.component.css']
})

export class EventMapComponent{
    constructor(){

    }

    // for filters
  applyFilter(filterData: any): void {
}

onResetFilters(): void {
}

// capture filter open and close events
filterNavStatus = false;
closeFilterEvent(event: any): void {
  this.filterNavStatus = event;
}
}
