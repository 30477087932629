import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AffiliateAddModel } from 'src/app/models/client/affiliate.add.model';
import { ClientService } from 'src/app/services/client.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-add-affiliate',
  templateUrl: './add-affiliate.component.html',
  styleUrls: ['./add-affiliate.component.css']
})
export class AddAffiliateComponent implements OnInit {

  constructor(
    private clientService: ClientService,
    private ngbActiveModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService) { }

  @Input() public clientId: number;
  affiliateList: number[];
  form: FormGroup;
  isSubmitted: boolean = false;

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      affiliate: ['', Validators.required]
    });
    this.loadAffiliates();
  }

  private loadAffiliates() {
    this.clientService.getAffiliatesToAdd(this.clientId).subscribe((data: any) => {
      this.affiliateList = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
    });
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.form.valid) {
      this.saveAffiliates();
    }
  }

  get activeModal(): any {
    return this.ngbActiveModal;
  }

  private saveAffiliates() {
    let model = new AffiliateAddModel();
    model.clientId = this.clientId;
    model.Affiliates = this.form.value.affiliate;
    this.clientService.addAffiliates(model).subscribe((data: any) => {
      this.activeModal.close("save click");
      this.clientService.notifyToLoadAffiliates();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
    });
  }
}
