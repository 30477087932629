import { Component, OnInit, ViewChild, Renderer2, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { NoteService } from 'src/app/services/note.service';
import * as moment from 'moment';
import { AttachmentTypeOptions, NoteFilterByOptions, NoteSearchOptions } from 'src/app/helpers/enums/filters-and-searchs/note.search&filter.options';
import { NoteCategory } from 'src/app/helpers/enums/notes/noteCategory';
import { NoteFor } from 'src/app/helpers/enums/notes/noteFor';
import { HasPermission } from 'src/app/helpers/has-permission.helper';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { ClientService } from 'src/app/services/client.service';
import { LocationService } from 'src/app/services/location.service';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ClientContactService } from 'src/app/services/client.contact.service';

declare const exportNav: any;

@Component({
  selector: 'app-notes-by-category',
  templateUrl: './notes-by-category.component.html',
  styleUrls: ['./notes-by-category.component.css']
})
export class NotesByCategoryComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  @Input() notesCategory: NoteCategory;
  @Input() entityId: string;

  private searchBy?: NoteSearchOptions;
  private searchFor: string;
  filterBy?: NoteFilterByOptions = null;
  filterByValue?: AttachmentTypeOptions = null;
  filterInAccessGroup?: string = null;
  isInitFilterAndSearch = false;
  isShowFilterAndSearch = false;
  isBulkOperationButtonDisabled = true;

  constructor(private noteService: NoteService,
              private tosterService: ToasterService,
              private router: Router,
              private clientService: ClientService,
              private locationService: LocationService,
              private staffService: StaffService,
              private clientContactService: ClientContactService) { }

  ngOnInit(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },

      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,
      order: [[0, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {
        dataTablesParameters.moduleCategory = this.notesCategory;
        dataTablesParameters.filterBy = self.filterBy;
        dataTablesParameters.filterByValue = self.filterByValue;
        dataTablesParameters.filterInGroup = self.filterInAccessGroup;
        dataTablesParameters.searchBy = self.searchBy;
        dataTablesParameters.searchFor = self.searchFor;
        self.blockUI.start();
        self.noteService.query(dataTablesParameters)
          .subscribe(resp => {
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            self.blockUI.stop();
          });
      },
      columns: [
        {
          data: 'date',
          title: 'Date',
          render: (data, type, row) => {
            return `${moment(row.date).format('MM/DD/YY')}`;
          }
        },
        {
          data: 'entityName',
          title: 'For',
          render: (data, type, row) => {
            return `<a class='link m-r-10' action-type='link-detail'>${row.entityName}</a>`;
          }
        },
        {
          data: 'author',
          title: 'Author',
          render: (data, type, row) => {
            const html = HasPermission.validate([Permission.ViewStaffMembers]) ?
              ` <a class='link m-r-10' action-type='author-detail'>${row.author}</a>` : `<span>${row.author}</span>`;
            return html;
          }
        },
        {
          data: 'accessGroupName',
          title: 'Access Group'
        },
        {
          data: 'noteText',
          title: 'Note'
        }
      ],
      rowCallback: (row, data: any) => {
        const _row = $(row);

        _row.find('[action-type="link-detail"]')
          .off('click')
          .on('click', () => {
            self.linkDetail(data.entityId, data.module, data.entityName, data.parentEntityId, data.parentEntityName);
          });
        _row.find('[action-type="author-detail"]')
          .off('click')
          .on('click', () => {
            self.linkAuthorDetail(data.authorId);
          });
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
    };
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtInstance = dtInstance;
    });
  }

  // capture filter open and close events
  filterNavStatus = false;
  closeFilterEvent(event: any): void {
    this.filterNavStatus = event;
  }

  applyFilter(filterData: any): void {
    if (filterData.filter) {
      const filterIdArr = filterData.filter.split('_');
      if (filterIdArr && filterIdArr.length > 0) {
        this.filterBy = Number(filterIdArr[0]);
        if (filterIdArr.length > 1) {
          this.filterByValue = Number(filterIdArr[1]);
        }
      }
    }
    else {
      this.filterBy = null;
      this.filterByValue = null;
    }
    this.filterInAccessGroup = filterData.filterIn === '-1' ? null : filterData.filterIn;
    this.searchBy = filterData.searchBy;
    this.searchFor = filterData.searchFor;
    this.reload();
  }

  onResetFilters(): void {
    this.filterBy = null;
    this.filterInAccessGroup = null;
    this.searchBy = null;
    this.searchFor = '';
    this.reload();
  }

  onFilterNavClose(): void {
    this.isShowFilterAndSearch = false;
  }

  reload(): void {
    this.dtInstance.ajax.reload();
  }

  linkDetail(entityId: string, module: NoteFor, entityName: string, parentEntityId = '', parentEntityName = ''): void {
    if (!module) {
      return;
    }

    switch (module) {
      case NoteFor.Client:
        this.clientService.clientDetailSubject$.next({ id: Number(entityId), name: entityName });
        this.router.navigate(['/client', Number(entityId), 'notes']);
        break;

      case NoteFor.ClientLocation:
        this.locationService.locationDetailSubject$.next({
          locationId: Number(entityId), locationName: entityName, clientId: Number(parentEntityId), clientName: parentEntityName 
        });
        this.router.navigate(['/client/location', Number(entityId), 'notes']);
        break;

      case NoteFor.Staff:
        this.staffService.staffDetailSubject$.next({ id: entityId, name: entityName });
        this.router.navigate(['/staff', entityId, 'notes']);
        break;

      case NoteFor.ClientContact:
        this.clientContactService.contactDetailSubject$.next({
          id: entityId,
          name: entityName,
          clientId: Number(parentEntityId),
          clientName: parentEntityName
        });
        this.router.navigate(['/client/contact-show', entityId, 'notes']);
        break;

      default: alert('No such enity exist');
    }
  }

  linkAuthorDetail(authorId: string): void {
    this.router.navigate(['/staff', authorId]);
  }

  get categoryName(): string {
    switch (this.notesCategory) {
      case NoteCategory.Client: return 'Client';
      case NoteCategory.Staff: return 'Staff';
      default: return '';
    }
  }
}