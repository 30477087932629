import { formatDate } from '@angular/common';
import { Component, OnInit } from '@angular/core';

import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CountryModel } from 'src/app/models/shared/country.model';
import { StateModel } from 'src/app/models/shared/state.model';
import { CountryService } from 'src/app/services/country.service';
import { StaffLicenseService } from 'src/app/services/staff.license.service';
import { ToasterService } from 'src/app/services/toater.service';
@Component({
  selector: 'app-new-staff-license',
  templateUrl: './new-staff-license.component.html',
  styleUrls: ['./new-staff-license.component.css']
})
export class NewStaffLicenseComponent implements OnInit {

  breadCrumbInputs: { key: string, value: string[] }[] = [];
  loadBreadCrumb = false;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private licenseService: StaffLicenseService,
    private countryService: CountryService,
    private route: ActivatedRoute) {
    this.route.params.subscribe(param => this.staffId = param.id);
    this.breadCrumbInputs.push({
      key: 'id', value: [ this.staffId,
      this.staffId]
    });
    this.loadBreadCrumb = true;
  }

  form: FormGroup;
  staffId: string;
  submitted = false;
  countrySelectListItem: CountryModel[] = [];
  stateSelectListItem: StateModel[] = [];

  @BlockUI() blockUI: NgBlockUI;


  ngOnInit(): void {

    this.form = this.formBuilder.group({
      name: [null, [Validators.required]],
      expiresOn: [null, [Validators.required]],
      license: [''],
      notes: [''],
      attachment: [null],
      state: [''],
      country: ['']

    });

    this.loadCountries();
  }

  private loadCountries(): void {
    this.blockUI.start();
    this.countryService.getCountries().subscribe((data: any) => {
      this.countrySelectListItem = data;
      const defaultCountry = this.countrySelectListItem.find(x => x.isDefault);
      this.form.controls.country.setValue(defaultCountry.id);
      this.loadStates(defaultCountry.id);

    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }
  public loadStates(id: any): void {

    if (id === null || id === undefined || id.length === 0) {
      return;
    }
    this.blockUI.start();
    this.countryService.getStates(id)
      .subscribe((data: any) => {
        this.stateSelectListItem = data;
      }, error => {
        this.toasterService.ProcessErrorResponse(error);
      }, () => {
        this.blockUI.stop();
      });

  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    const formData = new FormData();
    formData.append('name', this.form.controls.name.value);
    formData.append('licenseNumber', this.form.controls.license.value);
    const country = this.countrySelectListItem.find(x => x.id === this.form.controls.country.value);
    if (country) {
      formData.append('country', country.name);
    }
    formData.append('state', this.form.controls.state.value);
    formData.append('expiresOn', this.form.controls.expiresOn.value);
    formData.append('notes', this.form.controls.notes.value);
    formData.append('staffId', this.staffId);
    const attachment = this.form.controls.attachment.value;

    if (attachment != null && attachment !== undefined) {
      formData.append('attachment', attachment, attachment?.name);
    }

    this.blockUI.start();
    this.licenseService.post(formData).subscribe(
      (response) => {
        this.toasterService.success(response);
        this.router.navigate(['../'], { relativeTo: this.route });
        this.blockUI.stop();
      },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });


  }

  onFileSelected(event: any): void {
    const fileList = event.target.files as FileList;
    if (!fileList || fileList.length === 0) {
      this.form.controls.attachment.setValue(null);
      return;
    }
    this.form.controls.attachment.setValue(fileList.item(0));
  }

}
