<button class="btn btn-lg bg-blue text-white filter-btn-nav  float-right"  (click)="filterNavStatus = true">
    <i class="align-middle fas fa-filter"></i>
</button>
<button class="btn btn-lg bg-white float-right mr-3"  (click)="open(content)"><i class="align-middle  fas fa-plus-circle"></i>
   Assign New</button>

   <button class="btn btn-lg bg-white float-right mr-3" (click)="massOpen(massAssignContent)"><i class="align-middle  fas fa-plus-circle"></i>
    Mass Assign</button>
<div ngbDropdown class="d-inline-block float-right mr-3">
    <button class="btn btn-outline-primary btn-lg bulkaction-dropdown" id="dropdownBasic1" ngbDropdownToggle
        [disabled]="isBulkOperationButtonDisabled">Bulk Action</button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        
        <button ngbDropdownItem (click)="onBulkUnDelete()" *ngIf="filterBy == noteFilterOptions.DeletedNotes">Make Primary</button>
        <button ngbDropdownItem (click)="onBulkUnDelete()" *ngIf="filterBy == noteFilterOptions.DeletedNotes">Confirm Assignment</button>
        <button ngbDropdownItem (click)="onBulkUnDelete()" *ngIf="filterBy == noteFilterOptions.DeletedNotes">Notify</button>
        <button ngbDropdownItem (click)="onBulkDelete()" *ngIf="filterBy != noteFilterOptions.DeletedNotes">Remove</button>
    </div>
</div>
<div class="case-table table-responsive">
    <div>
        <table class="neu-table table table-striped neu-table w-100" datatable [dtOptions]="dtOptions" data-page="2">
        </table>
    </div>
</div>


<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Assign New</h4>
        <button type="button" class="btn btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fas fa-times"></i></button>
    </div>
    <div class="modal-body">
        <form>
            <div class="mb-3">
                <div class="row align-items-center">
                    <div class="col-4">
                        <label class="mb-0" for="dateOfBirth">Group <sup class="clsStric">*</sup></label>
                    </div>
                    <div class="col-8">
                        <div class="input-group">
                            <select class="form-select bg-white" aria-label="Default select example" [(ngModel)]="groupSelectDropdown" name="groupSelectDropdown" >
                                <option selected disabled>Select an option</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                        </div>
                    </div>
                </div>

                <div class="row align-items-center mt-3">
                    <div class="col-4">
                        <label class="mb-0" for="dateOfBirth">Person <sup class="clsStric">*</sup></label>
                    </div>
                    <div class="col-8">
                        <div class="input-group">
                            <select class="form-select bg-white" aria-label="Default select example" [disabled]="!groupSelectDropdown">
                                <option selected disabled>Select an option</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                              </select>
                        </div>
                    </div>
                </div>

                <hr>
                
                <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input" id="setasPrimary">
                    <label class="form-check-label" for="setasPrimary"><strong>Set as Primary</strong>
                        <div class="checkboxTexts">If checked, the selected person will be listed as the "primary" party for the chosen
                            group.</div>
                    </label>
                </div>

                <div class="form-group form-check">
                    <input type="checkbox" class="form-check-input" id="sendAssign">
                    <label class="form-check-label" for="sendAssign"><strong> Send Assignment Notification</strong>
                        <div class="checkboxTexts">If checked, a notification will be sent to the selected person, informing them of this assignment.</div>
                    </label>
                </div>

                
               
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
        <button type="button" class=" btn btn-lg bg-blue text-white " (click)="modal.close('Save click')">Assign to Case</button>
 
    </div>
</ng-template>


<ng-template #massAssignContent let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Mass Assign</h4>
        <button type="button" class="btn btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"><i class="fa fas fa-times"></i></button>
    </div>
    <div class="modal-body">
        <form>
           

            <div class="row">
                <div class="col-md-4">
                    
                    <ngb-accordion #acc="ngbAccordion"   class="da-setting-card" [closeOthers]="true"  activeIds="toggle-1">
                        <ngb-panel id="toggle-1" title="Case Manager">

                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center justify-content-between">
                                  <div class="m-0 font-weight-bold">Case Manager </div>
                                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                </div>
                           </ng-template>
                          <ng-template ngbPanelContent>
                            <div *ngFor="let timeslot of RandomData" class="checkbox">
                                <label>
                                    <input type="checkbox" value="{{timeslot.id}}" name="{{timeslot.id}}" (change)="addItemsforMass($event)"  /> {{timeslot.first_name}} {{timeslot.last_name}}
                                </label>
                            </div>
                          </ng-template>
                        </ngb-panel>
                        <ngb-panel id="toggle-2" title="Salesperson">


                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center justify-content-between">
                                  <div class="m-0 font-weight-bold">Salesperson </div>
                                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                </div>
                           </ng-template>
                          <ng-template ngbPanelContent>
                            <div *ngFor="let timeslot of RandomData" class="checkbox">
                                <label>
                                    <input type="checkbox" value="{{timeslot.id}}" name="{{timeslot.id}}" (change)="addItemsforMass($event)"  /> {{timeslot.first_name}} {{timeslot.last_name}}
                                </label>
                            </div>
                          </ng-template>
                        </ngb-panel>

                        <ngb-panel id="toggle-3" title="Additional Employee">


                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center justify-content-between">
                                  <div class="m-0 font-weight-bold">Additional Employee </div>
                                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                </div>
                           </ng-template>
                          <ng-template ngbPanelContent>
                            <div *ngFor="let timeslot of RandomData" class="checkbox">
                                <label>
                                    <input type="checkbox" value="{{timeslot.id}}" name="{{timeslot.id}}" (change)="addItemsforMass($event)"  /> {{timeslot.first_name}} {{timeslot.last_name}}
                                </label>
                            </div>
                          </ng-template>
                        </ngb-panel>

                        <ngb-panel id="toggle-4" title="Investigator">


                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center justify-content-between">
                                  <div class="m-0 font-weight-bold">Investigator </div>
                                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                </div>
                           </ng-template>
                          <ng-template ngbPanelContent>
                            <div *ngFor="let timeslot of RandomData" class="checkbox">
                                <label>
                                    <input type="checkbox" value="{{timeslot.id}}" name="{{timeslot.id}}" (change)="addItemsforMass($event)"  /> {{timeslot.first_name}} {{timeslot.last_name}}
                                </label>
                            </div>
                          </ng-template>
                        </ngb-panel>

                        <ngb-panel id="toggle-5" title="Client Contact">


                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center justify-content-between">
                                  <div class="m-0 font-weight-bold">Client Contact </div>
                                  <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                </div>
                           </ng-template>
                          <ng-template ngbPanelContent>
                            <div *ngFor="let timeslot of RandomData" class="checkbox">
                                <label>
                                    <input type="checkbox" value="{{timeslot.id}}" name="{{timeslot.id}}" (change)="addItemsforMass($event)"  /> {{timeslot.first_name}} {{timeslot.last_name}}
                                </label>
                            </div>
                          </ng-template>
                        </ngb-panel>
                      </ngb-accordion>

                </div>
                <div class="col-md-8">
                    
                    <div class="mb-3">
                        <div class="row">
                            <div class="col-12">
                                <ng-select [items]="RandomData"  bindLabel="first_name" name="searchMass" [(ngModel)]="selectedPersonId" (change)="onMassSearch($event)">
                                </ng-select>
                            </div>

                            <div class="col-12 mt-4" *ngIf="filteredRandomData.length > 0">
                                <table class="table table-striped" >
                                    <thead>
                                    <tr>
                                      <th scope="col">Case Manager</th>
                                      <th scope="col">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor="let selectedMassData of filteredRandomData; index as i">
                                      <td scope="row">
                                        {{ selectedMassData.first_name }}, {{ selectedMassData.last_name }}
                                      </td>
                                      <td>
                                     
                                            <button class='btn del-btn btn-lg btn-table' action-type="delete" (click)="deleteMass(selectedMassData.id)">
                                                <i class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
                                            </button>
                                        
                                      </td>
                                    </tr>
                                    </tbody>
                                  </table>
                            </div>
                        </div>
                  
                        
                        <div class="form-group form-check mt-3">
                            <input type="checkbox" class="form-check-input" id="setasPrimary">
                            <label class="form-check-label" for="setasPrimary"><strong>Send Assignment Notifications</strong>
                                <div class="checkboxTexts">If checked, notifications will only be sent to people for whom assignment notices are enabled.</div>
                            </label>
                        </div>
        
                        
                       
                    </div>

                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss('cancel click')">Cancel</button>
        <button type="button" class=" btn btn-lg bg-blue text-white " (click)="modal.close('Save click')">Assign to Case</button>
 
    </div>
</ng-template>

<app-notes-filter-and-search (onApplyFilter)="applyFilter($event)" (resetFilters)="onResetFilters()"
    (closeFilterNav)="isShowFilterAndSearch = !isShowFilterAndSearch"  [filterStatusParent]="filterNavStatus" 
    (closeBtnFilterStatus)="closeFilterEvent($event)">
</app-notes-filter-and-search>