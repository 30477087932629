import { ContatcNoInEntity } from '../shared/contactNoInEntity';

export class ClientContactEditModel {
    id: string;
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    title: string;
    caseManager: string;
    salesPerson: string;
    role: string;
    country: string;
    fax: string;
    dob: string;
    accountCode: string;
    tags: string[];
    generalNotes: string;
    adminNotes: string;
    profileImgFile: File;
    profileImgUrl: string;
    clientId: number;
    locationId: number;
    contactNos: ContatcNoInEntity<string>[] = [];
}
