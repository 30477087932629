<div *blockUI="'container-blockui-cutomize-list'">
    <div>
        <app-modal [modalConfig]="modalConfig">
            <!--  body of the modal  -->
            <form [formGroup]="form">
                <div class="form-group row">
                    <label class="col-sm-4 col-form-label" for="exampleInputPassword1">Salesperson <sup
                            class="clsStric">*</sup></label>
                    <div class="col-sm-8">
                        <select class="dropdown-toggle btn btn-lg" name="user_view[limit]" id="user_view_limit"
                            formControlName="salesperson" [ngClass]="{ 'is-invalid': isSubmitted && form.controls.salesperson.errors }">
                            <option value="{{items.id}}" *ngFor="let items of salesperson;">{{items.value}}</option>
                        </select>
                        <div *ngIf="isSubmitted && form.controls.salesperson.errors" class="invalid-feedback">
                            <div *ngIf="form.controls.salesperson.errors.required" style="font-size: larger;">
                                Salesperson is required
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </app-modal>
    </div>
</div>