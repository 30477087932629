<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>New Document Template</strong> </h3>
                        </div>
                    </div>


                    <div class="row mx-0" *blockUI="'container-blockui-new-folder-template'">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form"  [formGroup]="form" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
                                          
                                            <div class="card-body">
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="name">Name<sup class="clsStric">*</sup></label>
                                                                    <input type="text" formControlName="name" class="form-control mb-3" id="name" name="name" autocomplete="off">
                                                                    <div
                                                                    *ngIf="submitted && form.controls.name.errors">
                                                                    <div
                                                                      *ngIf="submitted && form.controls.name.errors.required"
                                                                      class="text-danger"
                                                                    >
                                                                      Name is required
                                                                    </div>
                                                                  </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="description">Description</label>
                                                                    
                                                                    <input type="text" formControlName="description" class="form-control mb-3" id="description" name="name" autocomplete="off">
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <hr>

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    
                                                                    <div class="form-group has-search">
                                                                        <label for="template_file">Template File<sup class="clsStric">*</sup></label>
                                                                        <input class="form-control  pt-2" type="file" accept=".docx,.odt"
                                                                            id="template_file" name="attachment"
                                                                            (change)="onFileSelected($event)">
                                                                            <div
                                                                            *ngIf="submitted && form.controls.template_file.errors">
                                                                            <div
                                                                              *ngIf="submitted && form.controls.template_file.errors.required"
                                                                              class="text-danger"
                                                                            >
                                                                              Template file is required
                                                                            </div>
                                                                          </div>
                                                                    </div>
                                                                    <small>Document templates must be either Open Document (.odt) or Microsoft Word (.docx) files.</small>

                                                                    <a class="d-block mt-2" (click)="viewTemplate()">View Template Variable</a>

                                                                    
                                                                    <div class="custom-control custom-switch mt-3">
                                                                        <input type="checkbox" formControlName="customSwitch1" class="custom-control-input" id="customSwitch1">
                                                                        <label class="custom-control-label"  for="customSwitch1"><b>Template uses Microsoft Word merge fields</b></label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="restrict_use"> Restrict use of this template to user roles in the selected access group<i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top"  ngbTooltip="Leave blank to allow access to users with a role in any access group."></i></label>
                                                                    
                                                                    <select class="form-control" formControlName="restrict_use">
                                                                        <option value="" selected></option>
                                                                        <option
                                                                          *ngFor="
                                                                            let item of accessGroupSelectListItem
                                                                          "
                                                                          [value]="item.id"
                                                                        >
                                                                          {{ item.value }}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>

                                                   
                                                        <hr>


                                                       <div class="row">
                                                        <div class="col-md-6 mb-2">
                                                            <label><b><sup class="clsStric">*</sup> Make this template available for download on the following case types:</b></label>
                                                            <div class="form-check mt-2" formArrayName="case_types">
                                                                <div class="form-check mt-2"
                                                                    *ngFor="let ct of caseTypesToFormArray.controls; let i = index"
                                                                    [formGroupName]="i">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        formControlName="isSelected" id="casetypes_{{i}}">
                                                                    <label class="form-check-label" for="lbl_casetypes_{{i}}">
                                                                        {{ct.value.name}}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="submitted && form.controls.case_types.errors">
                                                                <div *ngIf="submitted && form.controls.case_types.errors.required"
                                                                    class="text-danger">
                                                                    Please select atleast one case type
                                                                </div>
                                                            </div> 
                                                        </div>
                                                       </div>

                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button" (click)="onCancel()"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="submit" (click)="onSubmit()">Save Document Template <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
