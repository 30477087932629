<div [formGroup]="form" method="post" action="" novalidate="novalidate">
    <div class="row">

        <div class="col-md-12">
            <div class="row case-detail-tpcards mt-4">
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">

                            <div class="row">
                                <div class="col-md-6">
                                    <h5 class="card-title"><strong>Current Status</strong>
                                    </h5>
                                </div>
                                <div class="col-md-9 case-width">
                                    <span [style.backgroundColor]='caseSummaryData.caseStatusColour' class='link m-r-10 case-status-label'>
                                {{caseSummaryData.caseStatus}}
                                </span>
                                </div>
                            </div>
                            <select *ngIf="isShown" id="search6" formControlName="caseStatus">
                      <option value="" selected></option>
                      <option
                          *ngFor="let cll of casestatusList"
                          [value]="cll.id">{{cll.name}}</option>
                      </select>
                            <!-- <a href="#" class="card-link primary-link">View History <i class=" fas fa-chevron-right align-self-center"></i></a> -->
                            <div class="prevnxtBtn">
                                <a (click)="PreValue()" class="prevnxtBtns">&laquo; Prev</a>
                                <a (click)="NextValue()" class="prevnxtBtns">Next &raquo;</a>
                            </div>
                            <div class="prevnxtBtn">
                                <a  class="prevnxtBtns statusbtn" (click)="onEditSubmit()"> Change Status</a>
                                <a  class="prevnxtBtns cancelbtn " (click)="onClose()">Cancel </a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">

                            <div class="row">
                                <div class="col-md-6">
                                    <h5 class="card-title"><strong>Pinned Subjects</strong>
                                    </h5>
                                </div>
                                <div class="col-md-6">
                                    <h6 class=" font-weight-bold text-muted text-right"></h6>
                                </div>
                            </div>
                            <!-- <h4 class="card-text mb-3 font-weight-bold" *ngIf="caseSummaryData.subjects && caseSummaryData.subjects.length > 0">
                      <span>{{getFormattedName(caseSummaryData.subjects)}}</span>
                    </h4> -->
                            <div class="d-flex align-items-center mt-2" *ngFor="let alreadyaddedSubjectType of alreadyAddedsubjectTypeIncaseViewModel">
                                <img class="rounded-circle border border-orange-color img-thumbnail" [src]="alreadyaddedSubjectType.photoUrl == null ? '/assets/img/avatars/user.svg' : alreadyaddedSubjectType.photoUrl" alt="" width="95">
                                <div class="d-flex align-items-end ml-3 ">
                                    <div>
                                        <p class="text-light-black mb-1"> {{alreadyaddedSubjectType.displayValue}}
                                        </p>
                                        <h6 class="detail-client-title black-light-text">
                                            {{getSubjectNameById(alreadyaddedSubjectType.subjectTypeId)}}
                                        </h6>
                                    </div>
                                    <div class="subject-dropdown">
                                        <span id="span1" (click)="NavigatetoEditSubjectTypeForm(alreadyaddedSubjectType.subjectTypeId,alreadyaddedSubjectType.id)" class="btn text-orange dropdown-toggle border-0 dropdown-no-caret"><i
                                      class="align-middle fas fa-pen"></i>
                              </span>
                                    </div>
                                </div>
                            </div>
                            <a href="#" class="card-link primary-link">View History <i class=" fas fa-chevron-right align-self-center"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">

                            <div class="row">
                                <div class="col-md-6">
                                    <h5 class="card-title">
                                        <strong>Budget</strong>
                                    </h5>
                                </div>
                                <div class="col-md-6">
                                    <ng-template #content let-modal>
                                        <!-- <div class="modal-header modal_header">
                                            <h4 class="modal-title" id="modal-basic-title">Add Budget</h4>
                                            <button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')">x</button>
                                        </div> -->
                                        <div class="modal-header">
                                            <h4 class="modal-title" id="modal-basic-title">Add Budget</h4>
                                            <button type="button"   class="close" aria-label="Close" (click)="modal.close('cross click')">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                        </div>
                                        <div class="modal-body">
                                                <div class="mb-5">
                                                    <div class="input-group">
                                                        <!-- <div class="col-md-6"> -->
                                                            <!-- <div class="form-group has-search"> -->
                                                                <div class="col-sm-4 d-flex col-form-label my-auto">
                                                                <label for="Added By"> <sup class="clsStric">*</sup>Due Date:</label>
                                                            </div>               
                                                            <div class="input-group date"
                                                                    data-date-format="mm-dd-yyyy">
                                                                   <input
                                                                    class="form-control"
                                                                    placeholder="mm-dd-yyyy"
                                                                    formControlName="budgetdate"
                                                                    ngbDatepicker
                                                                    #dt="ngbDatepicker"
                                                                    [minDate]="{
                                                                      year: 1920,
                                                                      month: 1,
                                                                      day: 1
                                                                    }"
                                                                  /> 
                                                                   <div class="input-group-append">
                                                                    <button
                                                                      class="
                                                                        btn btn-outline-secondary
                                                                        calendar
                                                                      "
                                                                      (click)="dt.toggle()"
                                                                      type="button"
                                                                    ></button>
                                                                  </div>
                                                                </div>
                                                            <!-- </div> -->

                                                        <!-- </div> -->
                                                    </div>
                                                    <div class="input-group">
                                                            <div class="col-sm-4 d-flex col-form-label my-auto">
                                                                <label for="Added By"> <sup class="clsStric">*</sup>Added By:</label>
                                                            </div>
                                                            <select formControlName="staff" id="">
                                                                <option value="" selected></option>
                                                                <option
                                                                *ngFor="let cll of stafflist"
                                                                [value]="cll.id">{{cll.name}}</option>
                                                            </select>
                                                    </div>
                                                    
                                                    <div class="input-group">
                                                           <div class="col-sm-4 d-flex col-form-label my-auto">
                                                                <label for="Budget">Additional Budget:</label>
                                                            </div>
                                                           <div>
                                                            <span >$</span>
                                                            <input formControlName="budgetprice" class="additionalBudget dateTime" type="number" value="0">
                                                            <span>Hours</span>
                                                            <input formControlName="budgethours" class="additionalBudget dateTime"  type="number" value="0">
                                                           </div>
                                                           <p>Ther ammount entered will be added to or subtracted from the existing budget</p>

                                                    </div>
                                                    <div class="input-group">
                                                        <div class="col-sm-4 d-flex col-form-label my-auto">
                                                            <label for="Justification">Notes Justification:</label>
                                                        </div>
                                                        <textarea  formControlName="notesJustification" class="textarea dateTime" name="" id="" cols="30" rows="5"></textarea>
                                                    </div> 
                                                </div>
                                            
                                        </div>
                                        <div class="modal-footer border-0">
                                            <button (click)="SaveBudget();modal.close('Cancel click')" data-target="model" class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2">Save Budget</button>
                                            <button type="button" class="btn btn-outline-dark cancelbtn" (click)="modal.close('Cancel click')">Cancel</button>
                                        </div>
                                    </ng-template>

                                    <a (click)="open(content)">
                                        <h6 class=" font-weight-bold text-muted text-right"><strong>Add Budget</strong></h6>
                                    </a>

                                </div>
                            </div>
                            <h4 class="card-text mt-3 mb-3 mb-3text font-weight-bold ">${{converttodecimal(caseSummaryData.caseBudget)}} / {{converttodecimal(caseSummaryData.caseHours)}} Hrs.</h4>
                            <div [hidden]="!chart">
                                <canvas class="donut" id="canvas">
                      </canvas>
                            </div>
                            <a href="#" class="card-link primary-link ">View History <i class=" fas fa-chevron-right align-self-center"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card">
                        <div class="card-body">

                            <div class="row">
                                <div class="col-md-6">
                                    <h5 class="card-title">
                                        <strong>Snapshot</strong>
                                    </h5>
                                </div>
                                <div class="col-md-6">
                                    <h6 class=" font-weight-bold text-muted text-right"></h6>
                                </div>
                            </div>
                            <h4 class="card-text mb-3 mb-3txt font-weight-bold">Expensed: ${{converttodecimal(caseSummaryData.budget)}} / {{converttodecimal(caseSummaryData.hours)}} Hrs.</h4>
                            <h4 class="card-text mb-3txt font-weight-bold ">Invoiced: $0.00 / Est. $0.00.</h4>
                            <div [hidden]="!chart">

                                <canvas id="canvas1">
                            </canvas>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-12">
            <div class="card-columns">

                <div class="cardx">
                    <div class="card-bodyx">
                        <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-3">
                            <ngb-panel id="custom-panel-3">
                                <ng-template ngbPanelHeader let-opened="opened">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h5 class="m-0 font-weight-bold">General Information</h5>
                                        <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                            class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Created On</div>
                                        <div class="col-md-9">{{caseSummaryData.createdOn ? (caseSummaryData.createdOn | date) : ''}}</div>
                                    </div>

                                    <div class="row  mb-4 ">
                                        <ng-template #content let-modal>
                                            <div class="modal-header ">
                                                <h4 class="modal-title" id="modal-basic-title">Modify Due Date (ST22035-01)</h4>
                                                <button type="button" class="close" aria-label="Close" (click)="modal.close('cross click')">
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <form>
                                                    <div class="mb-5 ">
                                                        <div class="input-group">
                                                            <div class="col-sm-4 d-flex col-form-label my-auto">
                                                                <label for="dueDate">Due Date :</label>
                                                            </div>
                                                            <input  id="dueDate" class="dateTime" type="date" value="01/01/21">
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                            <div class="modal-footer border-0">
                                                <button type="submit" class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2">Save Due Date </button>
                                                <button type="button" class="btn btn-outline-dark cancelbtn" (click)="modal.close('Cancel click')">Cancel</button>
                                            </div>
                                        </ng-template>


                                        <div (click)="open(content)" class="col-md-5 da-font-bold font-weight-bold dueDate">DueDate</div>


                                        <div class="col-md-9">{{caseSummaryData.dueDate ? (caseSummaryData.dueDate | date) : ''}} </div>
                                    </div>
                                    <div class="row mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Case Flags</div>
                                        <div class="col-md-9 case-width" *ngIf="caseSummaryData.flags && caseSummaryData.flags.length > 0">
                                            <span [style.backgroundColor]='cf.colourId' class='link m-r-10 case-flag-label ml-1 mt-1' *ngFor="let cf of caseSummaryData.flags">
                            {{cf.value}}
                            </span>
                                        </div>
                                    </div>
                                    <hr />
                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Client</div>
                                        <!-- <div class="col-md-9">{{caseSummaryData.clientName}}</div>  -->
                                        <a class='link col-md-9 myLink1' (click)="gotoClient(caseSummaryData.clientId)" action-type='link-detail'>{{caseSummaryData.clientName}}</a>
                                    </div>
                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Client Contact</div>
                                        <!-- <div class="col-md-9">{{caseSummaryData.clientContact}}</div> -->
                                        <a class='link col-md-9 myLink1' (click)="linkDetail(caseSummaryData.contactId)" action-type='link-detail'>{{caseSummaryData.clientContact}}</a>
                                    </div>

                                    <hr/>
                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Case Type</div>
                                        <div class="col-md-9">{{caseSummaryData.caseType}}</div>
                                    </div>
                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Case Services</div>
                                        <div class="col-md-9" *ngIf="caseSummaryData.caseServicesInCases && caseSummaryData.caseServicesInCases.length > 0">

                                            <span>{{getFormatted(caseSummaryData.caseServicesInCases)}}</span>
                                        </div>
                                    </div>

                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Company Location</div>
                                        <div class="col-md-9">{{caseSummaryData.companyLocationName}}</div>
                                    </div>
                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold  font-weight-bold">Case Region</div>
                                        <div class="col-md-9">{{caseSummaryData.caseRegion}}</div>
                                    </div>

                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Case Location</div>
                                        <div class="col-md-9">{{caseSummaryData.caseLocation}}</div>
                                    </div>

                                    <hr />


                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Case Number</div>
                                        <div class="col-md-9">{{caseSummaryData.caseNumber}}</div>
                                    </div>

                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Claim Number</div>
                                        <div class="col-md-9" *ngIf="caseSummaryData.claimNumber && caseSummaryData.claimNumber.length > 0">

                                            <span>{{getFormattedName(caseSummaryData.claimNumber)}}</span>
                                        </div>
                                    </div>
                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Insured</div>
                                        <div class="col-md-9" *ngIf="caseSummaryData.pinnedSubject && caseSummaryData.pinnedSubject.length > 0">

                                            <span>{{getFormattedName(caseSummaryData.pinnedSubject)}}</span>
                                        </div>
                                    </div>
                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">DOL</div>
                                        <div class="col-md-9" *ngIf="caseSummaryData.dol && caseSummaryData.dol.length> 0">

                                            <span>{{DOLFormat(caseSummaryData.dol)}}</span>
                                        </div>
                                    </div>
                                    <hr />

                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Case Manager</div>
                                        <div class="col-md-9">{{caseSummaryData.caseManager}}</div>
                                        <!-- <a class='link col-md-9 myLink1'  action-type='link-detail'>{{caseSummaryData.caseManager}}</a> -->
                                    </div>

                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Investigator</div>
                                        <div class="col-md-9">{{caseSummaryData.primaryInvestigator}}</div>
                                    </div>
                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Salesperson</div>
                                        <div class="col-md-9">{{caseSummaryData.salesPerson}}</div>
                                    </div>
                                    <hr />


                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Referral Source</div>
                                        <div class="col-md-9">{{caseSummaryData.referralSource}}</div>
                                    </div>

                                    <div class="row mb-4" *ngFor="let customField of caseSummaryData.customFields">
                                        <div class="col-md-3 da-font-bold font-weight-bold">{{customField.label}}</div>
                                        <div class="col-md-9"> {{displayCustomField(customField)}}
                                        </div>
                                    </div>







                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                </div>

                <div class="cardx">
                    <div class="card-bodyx">
                        <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-3">
                            <ngb-panel id="custom-panel-3">
                                <ng-template ngbPanelHeader let-opened="opened">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h5 class="m-0 font-weight-bold">Notes</h5>
                                        <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                            class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Admin Notes</div>
                                        <div class="col-md-9">{{caseSummaryData.adminNotes}}</div>
                                    </div>
                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Scheduling Notes</div>
                                        <div class="col-md-9">{{caseSummaryData.schedulingNotes}}</div>
                                    </div>
                                    <div class="row  mb-4">
                                        <div class="col-md-3 da-font-bold font-weight-bold">Notes & Instructions</div>
                                        <div class="col-md-9">{{caseSummaryData.instructionNotes}}</div>
                                    </div>

                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                </div>

                <div class="cardx">
                    <div class="card-bodyx">
                        <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-3">
                            <ngb-panel id="custom-panel-3">
                                <ng-template ngbPanelHeader let-opened="opened">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <h5 class="m-0 font-weight-bold">Client Case Policies</h5>
                                        <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i
                            class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                    </div>
                                </ng-template>
                                <ng-template ngbPanelContent>
                                    <div class="row  mb-4">
                                        <div class="col-md-12">
                                            {{caseSummaryData.casePolicies}}
                                        </div>
                                    </div>

                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                </div>
            </div>
        </div>


    </div>
</div>