import { Injectable, NgModuleRef } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { AssignSalespersonComponent } from '../components/Clients/assign-salesperson/assign-salesperson.component';
import { AddAffiliateComponent } from '../components/Clients/client-list-detail/caffiliates/add-affiliate/add-affiliate.component';
import { CustomizedListComponent } from '../components/shared/customized-list/customized-list.component';
import { AssignManagerComponent } from '../components/staff/assign-manager/assign-manager.component';
import { Page } from '../helpers/enums/customize-column';
import { ImageCropperComponent } from '../components/shared/Modals/image-cropper/image-cropper.component';
import { StaffLicenseRenewModel } from '../models/staff-license/staff.license.renew.model';
import { RenewStaffLicenseComponent } from '../components/staff/shared/license/renew-staff-license/renew-staff-license.component';

// for new contact popup
import { NewContactPopupComponent } from '../components/shared/new-contact-popup/new-contact-popup.component';
import { PaymentSlipEnterPaymentPopupComponent } from '../components/shared/Modals/payment-slip-enter-payment-popup/payment-slip-enter-payment-popup.component';
import { NewLocationComponent } from '../components/Clients/shared/location/new-location.component';
import { AssignContactSalespersonComponent } from '../components/Clients/client-contacts/assign-contact-salesperson/assign-contact-salesperson.component';
import { NewCustomFieldSharedComponent } from '../components/shared/setting/custom-field/new-custom-field-shared/new-custom-field-shared.component';
import { CustomFieldCaseCategories } from '../helpers/enums/settings/customField/CustomFieldCaseCategories';
import { TemplateVariableComponent } from '../components/shared/setting/document-template-popup/template-variable/template-variable.component';
import { NewContactComponent } from '../components/Clients/shared/new-contact/new-contact.component';
import { NewClientComponent } from '../components/Clients/shared/new-client/new-client.component';

@Injectable()
export class ModalService {
  constructor(private ngbModal: NgbModal) { }
  closeAllModals(): void {
    if (this.ngbModal.hasOpenModals()) {
      this.ngbModal.dismissAll();
    }
  }

  openAddLocationContactPopup(clientId: number): Observable<any> {
    const modalRef = this.ngbModal.open(NewLocationComponent,
      { windowClass: 'groupModal', size: 'lg', centered: true });
    modalRef.componentInstance.isForPopup = true;
    modalRef.componentInstance.clientId = clientId;
    return modalRef.componentInstance.saveClick;
  }

  openDocumentTemplateVariablePopup(): void {
    const modalRef = this.ngbModal.open(TemplateVariableComponent,
      { windowClass: 'groupModal', size: 'lg', centered: true });
    modalRef.componentInstance.isForPopup = true;
  }

  openNewClientPopup(): Observable<any> {
    const modalRef = this.ngbModal.open(NewClientComponent,
      { windowClass: 'groupModal', size: 'lg', centered: false });
    modalRef.componentInstance.isForPopup = true;
    return modalRef.componentInstance.saveClick;
  }
  openNewCustomFieldPopup(useForId: number): Observable<any> {
    const modalRef = this.ngbModal.open(NewCustomFieldSharedComponent,
      { windowClass: 'groupModal', size: 'lg', centered: false });
    modalRef.componentInstance.isForPopup = true;
    modalRef.componentInstance.isForEnabled = true;
    modalRef.componentInstance.isForValue = useForId;

    return modalRef.componentInstance.saveClick;
  }

  openModalForAddAffiliates(clientId: number): void {
    const modalRef = this.ngbModal.open(AddAffiliateComponent,
      { windowClass: 'groupModal', size: 'md', centered: true });
    modalRef.componentInstance.clientId = clientId;
  }

  openNewContactPopup(): void {
    const modalRef = this.ngbModal.open(NewContactPopupComponent,
      { windowClass: 'groupModal', size: 'md', centered: true });
  }

  openClientContactPopup(): Observable<any>{
    const modalRef = this.ngbModal.open(NewContactComponent,
      { windowClass: 'groupModal', size: 'lg', centered: true });
    modalRef.componentInstance.isForPopup = true;
    return modalRef.componentInstance.saveClick;
  }

  openPaymentSlipEnterPayment(): void {
    const modalRef = this.ngbModal.open(PaymentSlipEnterPaymentPopupComponent,
      { windowClass: 'groupModal', size: 'md', centered: true });
  }

  openModalForConfiguringCustomizedList(moduleId: Page): Observable<any> {
    const modalRef = this.ngbModal.open(CustomizedListComponent,
      { windowClass: 'groupModal', size: 'lg', centered: true });
    modalRef.componentInstance.moduleId = moduleId;
    return modalRef.componentInstance.saveClick;
  }

  openClientAssignSalespersonModel(clientIds: number[]): Observable<any> {
    const modalRef = this.ngbModal.open(AssignSalespersonComponent,
      { windowClass: 'groupModal', size: 'md', centered: true });
    modalRef.componentInstance.clientIds = clientIds;
    return modalRef.componentInstance.assignClick;
  }

  openModalForAssignManagers(selectedStaffCount: number): Observable<any> {
    const modalRef = this.ngbModal.open(AssignManagerComponent,
      { windowClass: 'groupModal', size: 'md', centered: true });
    modalRef.componentInstance.selectedStaffCount = selectedStaffCount;
    return modalRef.componentInstance.managerId;
  }

  openSalesPersonAssignModel(selectedContactsCount: number): Observable<any> {
    const modalRef = this.ngbModal.open(AssignContactSalespersonComponent,
      { windowClass: 'groupModal', size: 'md', centered: true });
    modalRef.componentInstance.selectedContactsCount = selectedContactsCount;
    return modalRef.componentInstance.salesPersonId;
  }

  openModalForImageCropper(imageEvt: any): any {
    const modalRef = this.ngbModal.open(ImageCropperComponent,
      { windowClass: 'groupModal', size: 'md', centered: true });
    modalRef.componentInstance.imageFile = imageEvt;
    return modalRef.componentInstance;
  }

  openModalForRenewStaffLicense(header: string, expiresOn: string): Observable<any> {
    const modalRef = this.ngbModal.open(RenewStaffLicenseComponent,
      { windowClass: 'groupModal', size: 'md', centered: true });
    modalRef.componentInstance.header = header;
    modalRef.componentInstance.expiresOn = expiresOn;
    return modalRef.componentInstance.staffLicenseRenewModel;
  }
}
