import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { CaseSubjectTypeFilterOptionsDetail } from 'src/app/helpers/constants/filter-and-search/caseSubjectTypeFilterOptionsDetail';

import {FilterInputModel } from 'src/app/models/shared/filters.model';
@Component({
  selector: 'app-case-subject-type-filter',
  templateUrl: './case-subject-type-filter.component.html',
  styleUrls: ['./case-subject-type-filter.component.css']
})
export class CaseSubjectTypeFilterComponent implements OnInit {

  filterBy: FilterInputModel[] = [];
  // tslint:disable-next-line: no-output-on-prefix
  @Output()
  onApplyFilter = new EventEmitter();

  @Output()
  resetFilters = new EventEmitter();


  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();


  constructor(){

  }

ngOnInit(): void {
  CaseSubjectTypeFilterOptionsDetail.forEach((x, y) => {
      this.filterBy.push({ name: x, id: y });
    });
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onResetFilters(): void {
    this.resetFilters.emit();
  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }

}
