

<button class="btn btn-lg bg-blue text-white filter-btn-nav  float-right"  (click)="filterNavStatus = true">
    <i class="align-middle fas fa-filter"></i>
</button>


<div class="table-responsive">
    <table class="neu-table table table-striped neu-table w-100" datatable [dtOptions]="dtOptions" data-page="2" ></table>
</div>

<app-payment-slip-history-filter  (onApplyFilter)="applyFilter($event)" 
(resetFilters)="onResetFilters()" [filterStatusParent]="filterNavStatus"  (closeBtnFilterStatus)="closeFilterEvent($event)" >
</app-payment-slip-history-filter>
              
  
