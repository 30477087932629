<main class="content py-0">

    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-12   pt-7">



                <div class="row">
                    <div class="col-md-12">
                        <app-breadcrumb *ngIf="loadBreadCrumb" [breadCrumbInputs]="breadCrumbInputs"></app-breadcrumb>
                    </div>
                </div>

                <div class="row">
                    <div class="col-auto d-none d-sm-block mb-3">
                        <h3 class="page-title"><strong>Edit Office</strong> </h3>

                    </div>
                </div>


                <div class="row mx-0" *blockUI="'container-blockui-location'">
                    <div class="col-12 px-0 mb-5">
                        <form class="new-case-form" name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="row">


                                <div class="col-md-12">
                                    <div class="card flex-fill w-100 mb-2">
                                        <div class="card-header rounded p-4 mob-px-4">
                                            <div class="row">

                                                <div class="col-md-6 mb-2">
                                                    <div class="form-group has-search">
                                                        <label id="Label-7x1">Name<sup class="text-danger">*</sup></label>
                                                        <input type="text" class="form-control" id="Label-7x1" formControlName="name">

                                                    </div>
                                                </div>
                                                <div class="col-md-6 mb-2">

                                                    <div class="form-group has-search" *ngIf="!isShowPrimaryDiv">
                                                        <label id="track">Make Primary</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Make Primary"></i>
                                                        <div>
                                                            <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                <input type="radio" id="customRadio" class="custom-control-input" formControlName="isPrimary"  name="isPrimary" [value]='true'>
                                                                <label class="custom-control-label" for="customRadio">Yes</label>
                                                            </div>
                                                            <div class="custom-control custom-radio custom-control-inline">
                                                                <input type="radio" id="customRadio2" class="custom-control-input" formControlName="isPrimary"  name="isPrimary" [value]='false'>
                                                                <label class="custom-control-label" for="customRadio2">No</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6 mb-2">
                                                    <div class="form-group has-search">
                                                        <label id="Label-7x3">Country<sup class="text-danger">*</sup></label>
                                                        <select class="form-control" formControlName="country" (change)="loadStates($event.target.value)" name="country" id="client_country">
                                                            <option *ngFor="let country of countrySelectListItem" [value]="country.name">
                                                                {{country.name}}
                                                              </option>
                                                            </select>
                                                            <!-- <div *ngIf="submitted && form.controls.country.errors" class="text-danger">
                                                                <div *ngIf="form.controls.country.errors.required">
                                                                    Country is required
                                                                </div>
                                                            </div> -->
                                                    </div>
                                                </div>


                                                <div class="col-md-12">

                                                </div>
                                                <div class="col-md-6 mb-2">
                                                    <div class="form-group has-search">
                                                        <label id="Label-7x11">Address 1</label>
                                                        <input type="text" class="form-control" id="Label-7x11" formControlName="address1">

                                                    </div>
                                                </div>
                                                <div class="col-md-6 mb-2">

                                                    <div class="form-group has-search">
                                                        <label id="Label-7x12">Address 2</label>
                                                        <input type="text" class="form-control" id="Label-7x12" formControlName="address2">

                                                    </div>
                                                </div>

                                                <div class="col-md-6 mb-2">

                                                    <div class="form-group has-search">
                                                        <label id="Label-7x13">Address 3</label>
                                                        <input type="text" class="form-control" id="Label-7x13" formControlName="address3">

                                                    </div>
                                                </div>

                                                <div class="col-md-6 mb-2">

                                                    <div class="form-group has-search">
                                                        <label id="Label-7x14">City</label>
                                                        <input type="text" class="form-control" id="Label-7x14" formControlName="city">

                                                    </div>
                                                </div>

                                                <div class="col-md-6 mb-2">

                                                    <div class="form-group has-search">
                                                        <label id="Label-7x16">State/Province</label>

                                                        <select class="form-control" id="Label-7x16" formControlName="state" name="state">
                                                            <option value="" >Select State</option>
                                                            <option *ngFor="let state of stateSelectListItem" [value]="state.name">{{state.name}}</option>
                                                          </select>

                                                    </div>
                                                </div>


                                                <div class="col-md-6 mb-2">

                                                    <div class="form-group has-search">
                                                        <label id="Label-7x16">Zip/Postal Code</label>
                                                        <input type="text" class="form-control" id="Label-7x16" formControlName="zip" (keypress)="appUtil._keyUp($event)">


                                                    </div>
                                                </div>




                                            </div>
                                            <hr/>

                                            <div class="row">
                                                <div class="col-md-6 mb-2">
                                                    <div class="form-group has-search">
                                                        <label id="Label-7x16">Fax </label>
                                                        <input name="fax" type="text" class="form-control mb-3" formControlName="fax" class="form-control" autocomplete="off"   placeholder="Fax"/>
                                                    </div>
                                                </div>

                                            </div>
                                            <!-- new contact number ui -->
                                            <app-contact-number-widget *ngIf='isContactLoaded' [form]="form" [contactNumbers]="model.contactNos" [isSubmitted]="submitted">
                                            </app-contact-number-widget>
                                            <!-- end -->
                                            <hr/>

                                            <div class="row">
                                                <div class="col-md-6 mb-2">


                                                    <div class="form-group has-search">
                                                        <label id="Label-24">Sales Person</label>
                                                        <div class="input-group position-relative">
                                                            <select formControlName="salesPersonId" >
                                                                <option value="" selected>Select Person</option>
                                                                <option value="{{list.id}}" *ngFor="let list of salesPersons">{{list.value}}</option>
                                                            </select>
                                                            <div class="input-group-append position-absolute append-btn">
                                                                <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col-md-6 mb-2">

                                                    <div class="form-group has-search">
                                                        <label id="Label-25">Case Manager</label>

                                                        <div class="input-group position-relative">

                                                            <select formControlName="caseManagerId">
                                                                <option value="" selected>Select Manager</option>
                                                                <option value="{{list.id}}" *ngFor="let list of caseManagers">{{list.value}}</option>
                                                            </select>
                                                            <div class="input-group-append position-absolute append-btn">
                                                                <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="col-md-6 mb-2">

                                                    <div class="form-group has-search">
                                                        <label id="Label-email">Billing Email</label>
                                                        <input type="email" class="form-control" id="Label-email" formControlName="billingEmail">

                                                    </div>
                                                </div>
                                            </div>

                                            <hr/>

                                            <div class="row">
                                                <div class="col-md-6 mb-2">
                                                    <label>General Notes:</label>
                                                    <textarea class="form-control p-3" name="generalNotes" id="generalNotes" rows="5" placeholder="Add terms & conditions here..." formControlName="generalNotes"></textarea>
                                                </div>
                                                <div class="col-md-6 mb-2">
                                                    <label>Case Policies</label>
                                                    <textarea class="form-control p-3" name="casePolicies" id="casePolicies" rows="5" placeholder="Add terms & conditions here..." formControlName="casePolicies"></textarea>
                                                </div>
                                                <div class="col-md-6 mb-2">
                                                    <label>Update Policies</label>
                                                    <textarea class="form-control p-3" name="updatePolicies" id="updatePolicies" rows="5" formControlName="updatePolicies" placeholder="Thank you for giving us the opportunity to provide our services as we truly appreciate your business."></textarea>
                                                </div>
                                                <div class="col-md-6 mb-2">
                                                    <label>Invoice Policies:</label>
                                                    <textarea class="form-control p-3" name="invoicePolicies" id="invoicePolicies" rows="5" formControlName="invoicePolicies" placeholder="Thank you for giving us the opportunity to provide our services as we truly appreciate your business."></textarea>
                                                </div>
                                            </div>




                                        </div>
                                    </div>
                                </div>

                                <div class="form-group w-100">
                                    <div class="col-lg-12 text-right">
                                        <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button" [routerLink]="[this.returnUrl]"> Cancel</button>
                                        <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="submit">Save Office <span class="fa fa-arrow-right"></span></button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>


            </div>
        </div>
    </div>
</main>
