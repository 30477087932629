import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { ItemsList } from '@ng-select/ng-select/lib/items-list';
import { forkJoin } from 'rxjs';
import { StaffFilterOptionsMapping, StaffSearchOptionMapping } from 'src/app/helpers/constants/filter-and-search/staff.filters.options';
import { StaffFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/staff-filter.options';
import { RoleAvailableFor } from 'src/app/helpers/enums/roles/role.available.for';
import { FilterInputModel, FilterModel, SearchByModel } from 'src/app/models/shared/filters.model';
import { SearchModel } from 'src/app/models/shared/search.model';
import { RoleService } from 'src/app/services/role.service';
import { StaffService } from 'src/app/services/staff.serrvice';

@Component({
  selector: 'app-filter-and-search',
  templateUrl: './filter-and-search.component.html',
  styleUrls: ['./filter-and-search.component.css']
})
export class FilterAndSearchComponent implements OnInit {

  filterBy: FilterInputModel[] = [];
  filterIn: FilterInputModel[] = [];
  isFilterInVisible = true;

  @Output()
  onApplyFilter = new EventEmitter();

  @Output()
  resetFilters = new EventEmitter();

  @Output()
  closeFilterNav = new EventEmitter();

  searchBy: SearchByModel[] = [];

  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();

  constructor(
    private staffService: StaffService,
    private roleService: RoleService) {
    StaffSearchOptionMapping.forEach((key, value) => this.searchBy.push({ id: value, name: key }));
  }

  ngOnInit(): void {
    this.loadFilterOptions();
  }

  private loadFilterOptions(): void {
    forkJoin([this.staffService.getManagers(),
    this.roleService.getRolesByAvailableFor(RoleAvailableFor.employee)])
      .subscribe(([managers, roles]) => {
        const filterByOptions = [];
        StaffFilterOptionsMapping.forEach((key, value) =>
          filterByOptions.push({ name: key, id: `${value}`, group: undefined, isSelected: value === StaffFilterOptions.None })
        );
        managers.forEach((item) => filterByOptions.push({ name: item.value, id: `${StaffFilterOptions.StaffManagers}_${item.id}`, group: 'Staff Managers' }));
        this.filterBy = filterByOptions;
        this.filterIn = roles.map((item => ({ id: item.id, name: item.value })));
        this.filterIn.unshift({ id: '-1', name: 'All Roles', isSelected: true });
      });
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onFilterNavClose(): void {
    // this.showFiltersAndSearch = false;
    this.closeFilterNav.emit();
  }

  onResetFilters(): void {
    this.loadFilterOptions();
    this.resetFilters.emit();
  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }

}
