import { HistoryFilterOptions, HistorySearchOptions } from '../../enums/filters-and-searchs/history.search&filter.options';

export const HistoryFilterOptionDetails = new Map<number, string>([
    [HistoryFilterOptions.None, 'None'],
    [HistoryFilterOptions.Edited, 'Show Edits']
    ]);

export const HistorySearchOptionDetails = new Map<number, string>([
    [HistorySearchOptions.Log, 'Log (Details)'],
    [HistorySearchOptions.LastName, 'Last Name'],
    [HistorySearchOptions.IpAddress, 'IP Address']
    ]);
