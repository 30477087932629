import { ClientFilterByOptions, ClientSearchOptions } from "../../enums/filters-and-searchs/client.search&filter.option";

export const ClientSearchOptionsMapping = new Map<number, string>([
    [ClientSearchOptions.Name, 'Name'],
    [ClientSearchOptions.Address, 'Address'],
    [ClientSearchOptions.CaseManager, 'Case Manager'],
    [ClientSearchOptions.Salesperson, 'Salesperson'],
    [ClientSearchOptions.AccountCode, 'Account Code'],
    [ClientSearchOptions.Tag, 'Tag']
]);

export const ClientFilterByOptionsMapping = new Map<number, string>([
    [ClientFilterByOptions.None, 'None'],
    [ClientFilterByOptions.DeletedClients, 'Deleted Clients'],
]);