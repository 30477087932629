import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LocationService } from 'src/app/services/location.service';

@Component({
  selector: 'app-edit-client-location-notes',
  templateUrl: './edit-client-location-notes.component.html',
  styleUrls: ['./edit-client-location-notes.component.css']
})
export class EditClientLocationNotesComponent implements OnInit, OnDestroy {

  breadCrumbInputs: { key: string, value: string[] }[] = [];
  locationSubscription: Subscription;

  constructor(private locationService: LocationService) { 
    this.locationSubscription = this.locationService.locationListDetail$.subscribe((data) => {
      if (data == null) {
        return;
      }
      this.breadCrumbInputs.push(...[
        { key: 'id', value: [data.clientId.toString(), data.clientName] },
        { key: 'locationId', value: [data.locationId.toString(), data.locationName] }
      ]);
    });
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.locationSubscription.unsubscribe();
  }

}
