import { Component, OnInit } from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { AppUtils } from 'src/app/helpers';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ComponentDestoryHelper } from 'src/app/helpers/component-destory.helper';
import { CustomFieldTypes } from 'src/app/helpers/enums/settings/customField/CustomFieldTypes';
import { CaseTypeViewModel } from 'src/app/models/casetypes/caseTypeViewModel';
import { CustomFieldModel } from 'src/app/models/custom-fields/CustomFieldModel';
import { CountryModel } from 'src/app/models/shared/country.model';
import { StateModel } from 'src/app/models/shared/state.model';
import { AddOrEditSubjectTypeInCaseModel } from 'src/app/models/subject-type-in-case/addOrEditSubjectTypeInCaseModel';
import { SubjectTypeInCaseViewModel } from 'src/app/models/subject-type-in-case/subjectTypeInCaseViewModel';
import { SubjectTypeViewModel } from 'src/app/models/subjectTypes/subjectTypeViewModel';
import { CasetypeService } from 'src/app/services/casetype.service';
import { CountryService } from 'src/app/services/country.service';
import { CustomfieldService } from 'src/app/services/customfield.service';
import { SubjectTypeInCaseService } from 'src/app/services/subject.type.in.case.service';
import { SubjectTypeService } from 'src/app/services/subjecttype.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-add-case-second',
  templateUrl: './add-case-second.component.html',
  styleUrls: ['./add-case-second.component.css'],
})
export class AddCaseSecondComponent
  extends ComponentDestoryHelper
  implements OnInit
{
  closeResult = '';
  // caseTypeId=1&caseId=6&isSubjectType=false
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: NgbModal,
    private toasterService: ToasterService,
    private route: ActivatedRoute,
    private caseTypeService: CasetypeService,
    private customFieldService: CustomfieldService,
    private countryService: CountryService,
    private appUtils: AppUtils,
    private subjectTypeService: SubjectTypeService,
    private subjectTypeInCaseService: SubjectTypeInCaseService
  ) {
    super();
    this.addOrEditSubjectTypeInCaseModel = new AddOrEditSubjectTypeInCaseModel();

    this.route.queryParams.subscribe((parmas) => {
      console.log(parmas);
    
      this.caseTypeId = parmas.caseTypeId;
      this.isSubjectType = parmas.isSubjectType;
      this.caseId = parmas.caseId;
      this.Subjecttypeid=parseInt(parmas.Subjecttypeid);
      if(parmas.panelStep) this.panelStep= parseInt(parmas.panelStep);
     // this.createSubjectTypeForm(this.Subjecttypeid);
    });
  }
  @BlockUI('add-case-second-ui') blockUI: NgBlockUI;
  
  submitted: boolean;
  caseTypeId = 0;
  panelStep = 3;
  isSubjectType = false;
  Subjecttypeid=0;
  caseId = 0;
  caseTypeData: CaseTypeViewModel;
  subjectTypeList: SubjectTypeViewModel[];
  form: FormGroup;
  customFieldList: CustomFieldModel[] = [];
  defaultCustomFieldValues = [];
  primarySubjectTypeId = 0;
  titleName = '';
  countryList: CountryModel[] = [];
  stateSelectListItem: StateModel[] = [];
  isHiddenDiv = true;
  addOrEditSubjectTypeInCaseModel: AddOrEditSubjectTypeInCaseModel;
  alreadyAddedsubjectTypeIncaseViewModel: SubjectTypeInCaseViewModel[];
  emailPattern = "^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  // tslint:disable-next-line: typedef
  get GetCustomFieldCaseType() {
    return CustomFieldTypes;
  }
  get customFieldToFormArray(): FormArray {
    return this.form.controls.customFields as FormArray;
  }
  loadCaseTypeById(caseTypeId: number): void {
    this.blockUI.start();
    const subscription = this.caseTypeService.getById(caseTypeId).subscribe(
      (caseTypeData) => {
        this.caseTypeData = caseTypeData;
        if (this.caseTypeData && this.caseTypeData.caseTypeSubjectTypes) {
          this.loadSubjectTypes(
            this.caseTypeData.caseTypeSubjectTypes.map((x) => x.subjectTypeId)
          );
          const customSubjectType = this.caseTypeData.caseTypeSubjectTypes
            .filter((x) => x.isDefault)
            .map((p) => p.subjectTypeId);
          if (customSubjectType.length > 0) {
            this.primarySubjectTypeId = customSubjectType[0];
          }
        }
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => this.blockUI.stop()
    );
    this.subscriptions.push(subscription);
  }

  private loadStates(id: any): void {
    this.blockUI.start();
    this.countryService.getStates(id).subscribe(
      (data: any) => {
        this.stateSelectListItem = data;
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => {
        this.blockUI.stop();
      }
    );
  }
  public getSubjectNameById(subjectTypeid: number): string {
    
    if(this.subjectTypeList ==undefined)
    {
      return '';
    }
    const currentSubjectTypes = this.subjectTypeList.filter(
      (x) => x.id === subjectTypeid
    );
    
    if (currentSubjectTypes.length === 0) {
      return '';
    }
    return currentSubjectTypes[0].name;
  }
  loadCustomFields(subjectTypeId: number): void {
    
    this.blockUI.start();
    const subscription = this.customFieldService
      .getBySubjectTypeId(subjectTypeId)
      .subscribe(
        (customFieldList) => {
          // add customfields
          this.customFieldList = customFieldList;
          this.defaultCustomFieldValues = [];
          const cf: FormArray = this.customFieldToFormArray;
          cf.clear();
          // manipulate data
          this.customFieldList.forEach((d, index: number) => {
            // used to require first custom field, if bydefault not required
            if (index === 0 && !d.required) {
              d.required = true;
            }

            this.defaultCustomFieldValues.push(
              this.appUtils.isJSON(d.defaultJsonValue)
                ? JSON.parse(d.defaultJsonValue)
                : d.defaultJsonValue
            );
            cf.push(
              this.formBuilder.group({
                data: this.CreateControlSetDefaultValueForCustomField(
                  d.fieldTypeId,
                  d
                ),
                ...d,
              })
            );
          });
        },
        (error: any) => {
          this.toasterService.ProcessErrorResponse(error);
        },
        () => this.blockUI.stop()
      );
    this.subscriptions.push(subscription);
  }


  
  createSubjectTypeForm(subjectTypeId: number): void {
    
    this.form.reset();
    this.nextStep(3);
    this.titleName = this.subjectTypeList.filter(
      (x) => x.id === subjectTypeId
    )[0].name;
    this.isHiddenDiv = false;
    this.submitted = false;
    this.form.controls.is_pinned.setValue('true');
    this.form.controls.is_primary.setValue('false');
    this.form.controls.subjectTypeId.setValue(subjectTypeId);
    this.loadCustomFields(subjectTypeId);
    this.loadSubjectTypeInCaseByCaseId(this.caseId);
  }
  loadSubjectTypes(ids: number[]): void {
   
    this.blockUI.start();
    const subscription = this.subjectTypeService.getByIds(ids).subscribe(
      (subjectTypeList) => {
        console.log(subjectTypeList)
        this.subjectTypeList = subjectTypeList;
        if (
          this.subjectTypeList &&
          this.subjectTypeList.length > 0 &&
          this.primarySubjectTypeId > 0
        ) {
          this.loadCustomFields(this.primarySubjectTypeId);
          this.titleName = this.getSubjectNameById(this.primarySubjectTypeId);
          // set hidden field isPinned and isPrimary to true
          this.form.controls.is_pinned.setValue('true');
          this.form.controls.is_primary.setValue('true');
          this.form.controls.subjectTypeId.setValue(this.primarySubjectTypeId);
          this.isHiddenDiv = true;
        }
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => this.blockUI.stop()
    );
    this.subscriptions.push(subscription);
  }
  loadDefaults(): void {
    
    this.blockUI.start();
    forkJoin([
      this.countryService.getCountries(),
      this.caseTypeService.getById(this.caseTypeId),
      this.subjectTypeInCaseService.getByCaseId(this.caseId),
    ]).subscribe(
      ([countryList, caseTypeData, subjecttypeInCaseData]) => {
        this.countryList = countryList;
        // load case type data
        this.caseTypeData = caseTypeData;
        
        if (this.caseTypeData && this.caseTypeData.caseTypeSubjectTypes) {
          this.loadSubjectTypes(
            this.caseTypeData.caseTypeSubjectTypes.map((x) => x.subjectTypeId)
          );
          const customSubjectType = this.caseTypeData.caseTypeSubjectTypes
            .filter((x) => x.isDefault)
            .map((p) => p.subjectTypeId);
          if (customSubjectType.length > 0) {
            this.primarySubjectTypeId = customSubjectType[0];
          }
        }

        // load subject type in case data
        this.alreadyAddedsubjectTypeIncaseViewModel = subjecttypeInCaseData;
        this.loadSubjectTypeInCaseConnections(subjecttypeInCaseData);
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => this.blockUI.stop()
    );
  }

  get connectionsToFormArray(): FormArray {
    return this.form.controls.connections as FormArray;
  }

  ngOnInit(): void {
    
    this.loadDefaults();
    // this.loadCaseTypeById(this.caseTypeId);
    // this.loadSubjectTypeInCaseByCaseId(this.caseId);
    this.form = this.formBuilder.group({
      is_pinned: new FormControl('false'),
      is_primary: new FormControl('false'),
      subjectTypeId: new FormControl(0),
      customFields: new FormArray([]),
      connections: new FormArray([]),
      photo: [null],
    });
  }

  onFileSelected(event: any): void {
    const fileList = event.target.files as FileList;
    if (!fileList || fileList.length === 0) {
      this.form.controls.photo.setValue(null);
      return;
    }
    if (fileList.item(0).name.split('.').length > 2) {
      this.toasterService.error('Invalid file!');
      return;
    }
    this.form.controls.photo.setValue(fileList.item(0));
  }

  getDisplayValueForSubjectType(): string {
    if (
      this.form.controls.customFields.value &&
      this.form.controls.customFields.value.length > 0
    ) {
      const selCustomField = this.form.controls.customFields.value[0];

      if (selCustomField && typeof selCustomField.data === 'object') {
        if (
          selCustomField.fieldTypeId === this.GetCustomFieldCaseType.Address
        ) {
          let value = '';
          if (selCustomField.data.address1) {
            value += `${selCustomField.data.address1} `;
          }
          if (selCustomField.data.address2) {
            value += `${selCustomField.data.address2} `;
          }
          if (selCustomField.data.address3) {
            value += `${selCustomField.data.address3} `;
          }
          if (selCustomField.data.city) {
            value += `${selCustomField.data.city}, `;
          }
          if (selCustomField.data.state) {
            value += `${selCustomField.data.state} `;
          }
          if (selCustomField.data.zip_code) {
            value += `${selCustomField.data.zip_code} `;
          }
          return value;
        }
      }
      if (
        selCustomField.fieldTypeId ===
        this.GetCustomFieldCaseType.NameFirstMiddleLast
      ) {
        const value = `${selCustomField.data.last},${selCustomField.data.first}`;
        if (selCustomField.data.middle) {
          return `${value} ${selCustomField.data.middle}`;
        }
        return value;
      }
      if (
        selCustomField.fieldTypeId ===
          this.GetCustomFieldCaseType.DateBirthday ||
        selCustomField.fieldTypeId === this.GetCustomFieldCaseType.Date
      ) {
        const d = new Date(
          selCustomField.data.year,
          selCustomField.data.month,
          selCustomField.data.day
        );
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
        const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
        return `${mo} ${da},${ye}`;
      }
      if (selCustomField) {
        return selCustomField.data;
      }
    }
    return '';
  }
  onNewSubjectSaveAndProceed(): void {
    
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all required fields');
      return;
    }
    this.blockUI.start();
    const controls = this.form.controls;
    this.addOrEditSubjectTypeInCaseModel.id = 0;
    this.addOrEditSubjectTypeInCaseModel.displayValue =
      this.getDisplayValueForSubjectType();
    this.addOrEditSubjectTypeInCaseModel.isPinned =
      controls.is_pinned.value === 'true' ? true : false;
    this.addOrEditSubjectTypeInCaseModel.isPrimary =
      controls.is_primary.value === 'true' ? true : false;
    this.addOrEditSubjectTypeInCaseModel.customFieldSubjectTypeInCases = [];
    this.addOrEditSubjectTypeInCaseModel.caseId = +this.caseId;
    this.addOrEditSubjectTypeInCaseModel.photoUrl = controls.photo.value;
    this.addOrEditSubjectTypeInCaseModel.subjectTypeId =
      +controls.subjectTypeId.value;

    controls.customFields.value?.map((item) => {
      this.addOrEditSubjectTypeInCaseModel.customFieldSubjectTypeInCases.push({
        id: 0,
        subjectTypeInCaseId: 0,
        customFieldId: item.id,
        value:
          typeof item.data === 'object'
            ? JSON.stringify(item.data)
            : item.data?.toString(),
      });
    });

    this.addOrEditSubjectTypeInCaseModel.subjectTypeInCaseConnections = [];

    controls.connections.value
      ?.filter((x) => x.isSelected)
      .map((item) => {
        this.addOrEditSubjectTypeInCaseModel.subjectTypeInCaseConnections.push({
          id: 0,
          subjectTypeInCaseFirstId: 0,
          subjectTypeInCaseSecondId: item.id,
        });
      });

    this.subjectTypeInCaseService
      .post(this.addOrEditSubjectTypeInCaseModel)
      .subscribe(
        (data) => {
          setTimeout(() => {
            this.loadSubjectTypeInCaseByCaseId(this.caseId);
          }, 10);
          setTimeout(() => {
            this.toasterService.success('Subject type created successfully');
            this.panelStep = 4;
          }, 300);
        },
        (error) => {
          this.toasterService.ProcessErrorResponse(error);
        },
        () => this.blockUI.stop()
      );
  }

  nextStep(e: number): void {
    if (e === 4) {
      this.onNewSubjectSaveAndProceed();
    }
    if (e !== 4) {
      this.panelStep = e;
    }
  }
  backSubjectType(e: number): void {
    debugger;
    this.panelStep = e;
  }
  loadSubjectTypeInCaseByCaseId(caseId: number): void {
    this.blockUI.start();
    const subscription = this.subjectTypeInCaseService
      .getByCaseId(caseId)
      .subscribe(
        (data) => {
          this.alreadyAddedsubjectTypeIncaseViewModel = data;
          this.loadSubjectTypeInCaseConnections(data);
        },
        (error) => this.toasterService.ProcessErrorResponse(error),
        () => this.blockUI.stop()
      );
    this.subscriptions.push(subscription);
  }
  loadSubjectTypeInCaseConnections(model: SubjectTypeInCaseViewModel[]): void {
    // loading case types
    const ct: FormArray = this.connectionsToFormArray;
    ct.clear();
    model.forEach((d) => {
      ct.push(
        this.formBuilder.group({
          id: [d.id],
          name: [d.displayValue],
          isSelected: [false],
        })
      );
    });
  }
  // tslint:disable-next-line: typedef
  CreateControlSetDefaultValueForCustomField(
    typeId: number,
    cfm: CustomFieldModel
  ) {
    console.log(cfm);
    switch (typeId) {
      case this.GetCustomFieldCaseType.Address:
        return this.formBuilder.group({
          country: cfm.required ? ['', Validators.required] : '',
          address1: [''],
          address2: [''],
          address3: [''],
          city: [''],
          state: [''],
          zip_code: [''],
        });

      case this.GetCustomFieldCaseType.CountryDropdown:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.Date:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.DateBirthday:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required,Validators.pattern(this.emailPattern)]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.EmailAddress:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.InputLongAnswer:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.InputShortAnswer:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.Money:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.MultipleChoiceRadio:
        const mcr = JSON.parse(cfm.defaultJsonValue);
        const selectedArrays = mcr.filter(
          (x) => x.default_value_forMultiple == 'true'
        );
        if (selectedArrays.length > 0) {
          return cfm.required
            ? [selectedArrays[0].dform_multipleChoiceRadio, Validators.required]
            : [selectedArrays[0].dform_multipleChoiceRadio];
        }
        return cfm.required ? ['', Validators.required] : '';

      case this.GetCustomFieldCaseType.MultipleChoiceDropdown:
        const defaultJsonValue = JSON.parse(cfm.defaultJsonValue);
        const selectedArray = defaultJsonValue.filter(
          (x) => x.default_value_forMultiple == 'true'
        );
        if (selectedArray.length > 0) {
          return cfm.required
            ? [selectedArray[0].dform_multiplechoices, Validators.required]
            : [selectedArray[0].dform_multiplechoices];
        }
        return cfm.required ? ['', Validators.required] : '';

      case this.GetCustomFieldCaseType.NameFirstMiddleLast:
        return this.formBuilder.group({
          first: cfm.required ? ['', Validators.required] : '',
          middle: [''],
          last: cfm.required ? ['', Validators.required] : '',
        });

      case this.GetCustomFieldCaseType.Number:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.Section:
        return cfm.defaultJsonValue ? cfm.defaultJsonValue : '';
      case this.GetCustomFieldCaseType.WebsiteUrl:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.YesOrNo:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.YesNoOrNA:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.RatingMultipleChoiceDropdown:
        const rmcrs = JSON.parse(cfm.defaultJsonValue);
        const rmcr = rmcrs.filter(
          (x) => x.default_value_forRatingDrop == 'true'
        );
        if (rmcr.length > 0) {
          return cfm.required
            ? [rmcr[0].dform_ratingMultiDrop_scale, Validators.required]
            : [rmcr[0].dform_ratingMultiDrop_scale];
        }
        return cfm.required ? ['', Validators.required] : [''];
      case this.GetCustomFieldCaseType.RatingNumber:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      case this.GetCustomFieldCaseType.RatingYesNoOrNA:
        return cfm.required
          ? [cfm.defaultJsonValue, Validators.required]
          : [cfm.defaultJsonValue];
      default:
        return '';
    }
  }

  EditControlSetDefaultValueForCustomField(
    typeId: number,
    cfm:SubjectTypeInCaseViewModel
  ) {
    console.log(cfm);
    switch (typeId) {
      case this.GetCustomFieldCaseType.Address:
        return this.formBuilder.group({
          country: cfm[0].required ? ['', Validators.required] : '',
          address1: [''],
          address2: [''],
          address3: [''],
          city: [''],
          state: [''],
          zip_code: [''],
        });

      case this.GetCustomFieldCaseType.CountryDropdown:
        return cfm[0].required
          ? [cfm[0].defaultJsonValue, Validators.required]
          : [cfm[0].defaultJsonValue];
      case this.GetCustomFieldCaseType.Date:
        return cfm[0].required
          ? [cfm[0].defaultJsonValue, Validators.required]
          : [cfm[0].defaultJsonValue];
      case this.GetCustomFieldCaseType.DateBirthday:
        return cfm[0].required
          ? [cfm[0].defaultJsonValue, Validators.required]
          : [cfm[0].defaultJsonValue];
      case this.GetCustomFieldCaseType.EmailAddress:
        return cfm[0].required
          ? [cfm[0].defaultJsonValue, Validators.required, Validators.pattern(this.emailPattern)]
          : [cfm[0].defaultJsonValue];
      case this.GetCustomFieldCaseType.InputLongAnswer:
        return cfm[0].required
          ? [cfm[0].defaultJsonValue, Validators.required]
          : [cfm[0].defaultJsonValue];
      case this.GetCustomFieldCaseType.InputShortAnswer:
        return cfm[0].required
          ? [cfm[0].defaultJsonValue, Validators.required]
          : [cfm[0].defaultJsonValue];
      case this.GetCustomFieldCaseType.Money:
        return cfm[0].required
          ? [cfm[0].defaultJsonValue, Validators.required]
          : [cfm[0].defaultJsonValue];
      case this.GetCustomFieldCaseType.MultipleChoiceRadio:
        const mcr = JSON.parse(cfm[0].defaultJsonValue);
        const selectedArrays = mcr.filter(
          (x) => x.default_value_forMultiple == 'true'
        );
        if (selectedArrays.length > 0) {
          return cfm[0].required
            ? [selectedArrays[0].dform_multipleChoiceRadio, Validators.required]
            : [selectedArrays[0].dform_multipleChoiceRadio];
        }
        return cfm[0].required ? ['', Validators.required] : '';

      case this.GetCustomFieldCaseType.MultipleChoiceDropdown:
        const defaultJsonValue = JSON.parse(cfm[0].defaultJsonValue);
        const selectedArray = defaultJsonValue.filter(
          (x) => x.default_value_forMultiple == 'true'
        );
        if (selectedArray.length > 0) {
          return cfm[0].required
            ? [selectedArray[0].dform_multiplechoices, Validators.required]
            : [selectedArray[0].dform_multiplechoices];
        }
        return cfm[0].required ? ['', Validators.required] : '';

      case this.GetCustomFieldCaseType.NameFirstMiddleLast:
        return this.formBuilder.group({
          first: cfm[0].required ? ['', Validators.required] : '',
          middle: [''],
          last: cfm[0].required ? ['', Validators.required] : '',
        });

      case this.GetCustomFieldCaseType.Number:
        return cfm[0].required
          ? [cfm[0].defaultJsonValue, Validators.required]
          : [cfm[0].defaultJsonValue];
      case this.GetCustomFieldCaseType.Section:
        return cfm[0].defaultJsonValue ? cfm[0].defaultJsonValue : '';
      case this.GetCustomFieldCaseType.WebsiteUrl:
        return cfm[0].required
          ? [cfm[0].defaultJsonValue, Validators.required]
          : [cfm[0].defaultJsonValue];
      case this.GetCustomFieldCaseType.YesOrNo:
        return cfm[0].required
          ? [cfm[0].defaultJsonValue, Validators.required]
          : [cfm[0].defaultJsonValue];
      case this.GetCustomFieldCaseType.YesNoOrNA:
        return cfm[0].required
          ? [cfm[0].defaultJsonValue, Validators.required]
          : [cfm[0].defaultJsonValue];
      case this.GetCustomFieldCaseType.RatingMultipleChoiceDropdown:
        const rmcrs = JSON.parse(cfm[0].defaultJsonValue);
        const rmcr = rmcrs.filter(
          (x) => x.default_value_forRatingDrop == 'true'
        );
        if (rmcr.length > 0) {
          return cfm[0].required
            ? [rmcr[0].dform_ratingMultiDrop_scale, Validators.required]
            : [rmcr[0].dform_ratingMultiDrop_scale];
        }
        return cfm[0].required ? ['', Validators.required] : [''];
      case this.GetCustomFieldCaseType.RatingNumber:
        return cfm[0].required
          ? [cfm[0].defaultJsonValue, Validators.required]
          : [cfm[0].defaultJsonValue];
      case this.GetCustomFieldCaseType.RatingYesNoOrNA:
        return cfm[0].required
          ? [cfm[0].defaultJsonValue, Validators.required]
          : [cfm[0].defaultJsonValue];
      default:
        return '';
    }
  }

  NavigateToSupportingFiles(): void{
    this.router.navigate(['/add-case-supporting-files'], {
      queryParams: {
        caseTypeId: this.caseTypeId,
        caseId: this.caseId
      }
    });
  }
  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    debugger;
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {

      this.backSubjectType(4);
      return `with: ${reason}`;

    }
  }
  
  NavigatetoEditSubjectTypeForm(id:number):void{
    
    this.router.navigate(['/edit-case-second-steps'], {
      queryParams: {
        caseTypeId: this.caseTypeId,
        caseId: this.caseId,
        isSubjectType:true,
        id:id,
        Subjecttypeid:this.Subjecttypeid,
        RedirectFrom:'addcase'
      }
    });
    

  }
  
}

