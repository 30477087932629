import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {

constructor(private http: HttpClient) { }

public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
  return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/history/query`, dataTablesParameters, {});
}
}
