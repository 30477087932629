import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { AddOrEditCaseRegionModelModel } from 'src/app/models/caseregion/add.case-region.model';
import { CaseRegionService } from 'src/app/services/caseregion.service';
import { RoleService } from 'src/app/services/role.service';
import { ToasterService } from 'src/app/services/toater.service';
import { Validators, FormBuilder, FormArray, FormControl, FormGroup } from '@angular/forms';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';
@Component({
  selector: 'app-new-case-region',
  templateUrl: './new-case-region.component.html',
  styleUrls: ['./new-case-region.component.css']
})
export class NewCaseRegionComponent implements OnInit {
  @BlockUI('container-blockui-new-case-region') blockUI: NgBlockUI;
  model: AddOrEditCaseRegionModelModel;
  addCaseRigionModel: AddOrEditCaseRegionModelModel;
  form: FormGroup;
  submitted = false;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private activeRoute: ActivatedRoute,
              private toasterService: ToasterService,
              private caseRegionService: CaseRegionService){
                this.model = new AddOrEditCaseRegionModelModel();
                this.addCaseRigionModel = new AddOrEditCaseRegionModelModel();
                this.activeRoute.params.subscribe(params => this.model.id = params.id);
    }

 ngOnInit(): void {
  this.form = this.formBuilder.group({
    name: ['', [Validators.required]],
    description: [''],
    isSetAsDefaultRegion: false,
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.addCaseRigionModel.name = this.form.controls.name.value;
    this.addCaseRigionModel.description = this.form.controls.description.value;
    this.addCaseRigionModel.isSetAsDefaultRegion = this.form.controls.isSetAsDefaultRegion.value;

    this.blockUI.start();
    this.caseRegionService.post(this.addCaseRigionModel).subscribe(resposne => {
      setTimeout(() => {
       this.onCancel();
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Case region created successfully');
      }, 300);
      this.blockUI.stop();
    },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }
  onCancel(): void{
    this.router.navigate(['/setting/cases/regions-list']);
  }
}
