
  
    <main class="content py-0">
      
        <div class="container-fluid p-0">
            <div class="row">
                <div class="col-md-12 pt-7">
                    <div class="w-100 pt-4">
                        <div class="row">
                            <div class="col-auto d-none d-sm-block">
                                <div class="d-flex mb-2">
                                    <h3 class="page-title"><strong>User Access Groups &nbsp;</strong> </h3>
                                 
                                </div>
                            </div>
    
                            <div class="col-auto ml-auto text-right mt-n1">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                        <li class="filter-item">
                                            <div class="btn-group dropleft dropleft-btn">
                                                <div  class="d-inline-block">
                                                   <button  routerLink="../new" class="btn btn-outline-primary btn-lg bulkaction-dropdown" id="dropdownBasic1" ><i class="fas fa-plus"></i> New Access Groups</button>
                                                  </div>
                                            </div>
                                        </li>
                                        <li class="filter-item">
                                            <div class="btn-group dropleft dropleft-btn">
                                                <div ngbDropdown class="d-inline-block">
                                                    <!-- <button class="btn btn-lg   btn btn-danger" id="dropdownBasic1">Delete</button> -->
                                                    <button *ngIf="filterBy !== userRoleFilterOptions.DeletedRoles" class="btn btn-lg btn btn-danger" (click) = "onBulkDelete()"  [disabled]="isDeleteButtonDisabled" id="dropdownBasic1">Delete</button>
                                                    <button *ngIf="filterBy === userRoleFilterOptions.DeletedRoles" class="btn btn-lg btn btn-danger" (click) = "onBulkUnDelete()"  [disabled]="isDeleteButtonDisabled" id="dropdownBasic1">Undelete</button>
                                                  </div>
                                            </div>
                                        </li>
                                        <li class="filter-item px-0 filter-icon"  (click)="filterNavStatus = true">
                                            <button class="btn btn-lg bg-blue text-white">
                                                <i class="align-middle fas fa-filter"></i>
                                            </button>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                            <!-- Filter right sidenav -->

                           <app-user-access-group-search-and-filter (onApplyFilter)= "applyFilter($event)"
                           (resetFilters) = "onResetFilters()" [filterStatusParent]="filterNavStatus"  (closeBtnFilterStatus)="closeFilterEvent($event)"></app-user-access-group-search-and-filter>
                          
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12 px-0 pb-5">
                            <div class="col-auto mt-n1">
                                <app-breadcrumb></app-breadcrumb>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="case-table table-responsive">
                                    <div class="case-table table-responsive" *blockUI="'container-blockui-manage'" >
                                        <div>
                                            <table class="neu-table table table-striped neu-table" datatable [dtOptions]="dtOptions"  data-page="2"></table>
                                       </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>

 