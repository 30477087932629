import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AccessGroupListModel } from "../models/access-groups/access-group.list.model";
import { AccessGroupModel } from "../models/access-groups/access-group.model";
import { AddOrEditAccessGroupModel } from "../models/access-groups/add.access-group.model";
import { SelectListItem } from "../models/shared/select.list.item";
import { DataTableResponseModel } from "../models/shared/datatable.response.model";

@Injectable({
    providedIn: 'root'
})
export class AccessGroupService {
    constructor(private http: HttpClient) {
    }

    public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/access-group/query`, dataTablesParameters, {});
    }
    public getAccessGroups(): Observable<Array<SelectListItem<number>>> {
        return this.http.get<Array<SelectListItem<number>>>(`${environment.apiBaseUrl}/access-group/get-access-groups`);
    }
    public post(model: AddOrEditAccessGroupModel): Observable<AccessGroupModel> {
        return this.http.post<AccessGroupModel>(`${environment.apiBaseUrl}/access-group`, model, {});
    }
    public get(id: number): Observable<AddOrEditAccessGroupModel> {
        return this.http.get<AddOrEditAccessGroupModel>(`${environment.apiBaseUrl}/access-group/${id}`);
    }
    public put(model: AddOrEditAccessGroupModel): Observable<AccessGroupModel> {
        return this.http.put<AccessGroupModel>(`${environment.apiBaseUrl}/access-group`, model, {});
    }
    public delete(ids: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/access-group?ids=${ids.join('&ids=')}`);
    }
    public undelete(ids: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/access-group/undelete?ids=${ids.join('&ids=')}`);
    }
    public getCurrentUserAccessGroups(): Observable<Array<SelectListItem<number>>> {
        return this.http.get<Array<SelectListItem<number>>>(`${environment.apiBaseUrl}/access-group/get-current-user-access-groups`);
    }
}
