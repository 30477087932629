import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Directive({
  selector: '[hasPermissions]'
})
export class HasPermissionDirective {

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authenticationService: AuthenticationService
) { }

@Input() set hasPermissions(permissions: Permission[]) {
    this.authenticationService.validatePermission(permissions).subscribe(valid => {
        if (valid) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
            this.viewContainer.clear();
        }
    });
}

}
