<nav id="sidebar" class="sidebar">
  <div class="position-fixed menu-btn">
    <span class="curve"></span>
    <span
      (click)="sideToggle()"
      class="sidebar-toggle justify-content-center d-flex rounded-circle bg-orange position-absolute"
    >
      <i class="text-white fas fa-chevron-left align-self-center"></i>
    </span>
  </div>

  <div class="sidebar-content sidebar-scrollable">
    <div class="sidebar-brand"  routerLink="/">
      <span class="align-middle hide"
        ><img src="assets/img/icons/logo-transparent.png" alt="Logo"
      /></span>
      <span class="align-middle show"
        ><img src="assets/img/icons/logo-small.png" alt="Logo"
      /></span>
    </div>

    <ul class="sidebar-nav">
      <li class="sidebar-header mb-3">MENU</li>
      <li class="sidebar-item active">
        <div class="sidebar-link"   routerLink="/">
          <i class="align-middle fas fa-th-large"></i>
          <span class="align-middle">Dashboard</span>
        </div>
      </li>
      <li class="sidebar-item">
        <div class="sidebar-link collapsed sidebar-submenu">
          <i class="align-middle fas fa-file-alt"></i>
          <span class="align-middle">Cases</span
          ><span class="ml-3 badge badge-secondary bg-orange">New</span>
        </div>
        <ul
          id="ui"
          class="sidebar-dropdown list-unstyled collapse"
          data-parent="#sidebar"
        >
          <li class="sidebar-item">
            <a class="sidebar-link" routerLink="/add-case-first-steps"
              ><i class="align-middle fas fa-list-alt"></i>New Case</a
            >
            <a class="sidebar-link" routerLink="/case-list"
              ><i class="align-middle fas fa-list-alt"></i>Case List</a
            >
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link" routerLink="/case-requests"
              ><i class="align-middle fas fa-list"></i>Case Requests</a
            >
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link" routerLink="/case-subjects"
              ><i class="align-middle fas fa-clipboard-list"></i>Case
              Subjects</a
            >
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link" routerLink="/case-updates"
              ><i class="align-middle fas fa-user-edit"></i>Case Updates</a
            >
          </li>
          <li class="sidebar-item">
            <a class="sidebar-link" routerLink="/case-reviews"
              ><i class="align-middle fas fa-eye"></i>Case Reviews</a
            >
          </li>
        </ul>
      </li>
            <li class="sidebar-item">
                <div  class="sidebar-link collapsed sidebar-submenu">
                    <i class="align-middle fas fa-user-tie"></i> <span class="align-middle">Clients</span>
                </div>
                <ul id="ui" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">

                  <li class="sidebar-item"><a class="sidebar-link" routerLink="client/add-client"><i class="align-middle fas fa-clipboard-list"></i>New Client </a></li>

                  <li class="sidebar-item"><a class="sidebar-link" (click)="openNewContactPopup()"><i class="align-middle fas fa-clipboard-list"></i>New Contact</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="client"><i class="align-middle fas fa-clipboard-list"></i>Client List</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="client/contact-list"><i class="align-middle fas fa-address-book"></i>Client Contacts</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/clientNotes"><i class="align-middle fas fa-clipboard"></i>Client Notes</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/client/contact-notes"><i class="align-middle fas fa-clipboard"></i>Contact Notes</a></li>
                </ul>
            </li>

            <li class="sidebar-item">
                <div  class="sidebar-link collapsed sidebar-submenu">
                    <i class="align-middle fas fa-users"></i> <span class="align-middle">Staff</span>
                </div>
                <ul id="ui" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/staff"><i class="align-middle fas fa-clipboard-list"></i>Staff List</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/investigatorSnapshot"><i class="align-middle fas fa-camera-retro"></i>Investigator Snapshot</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/licenses"><i class="align-middle fas fa-id-badge"></i>Linceses</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/staffNotes"><i class="align-middle fas fa-clipboard"></i>Staff Notes</a></li>
                </ul>
            </li>

            <li class="sidebar-item">
                <div class="sidebar-link collapsed sidebar-submenu">
                    <i class="align-middle fas fa-plus-circle"></i> <span class="align-middle">Expenses</span>
                </div>
                <ul id="ui" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
                  
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/expenses/expense-entries"><i class="align-middle fas fa-list"></i>Expense Entries</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/expenses/new-expense-entry"><i class="align-middle fas fa-list"></i>New Expense Entry</a></li>

                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/expenses/expense-by-staff"><i class="align-middle fas fa-clipboard-list"></i>Entries by Staff</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/expenses/payment-slips"><i class="align-middle fas fa-file-invoice"></i>Payment Slips</a></li>
                </ul>
            </li>

            <li class="sidebar-item">
                <div  class="sidebar-link collapsed sidebar-submenu">
                    <i class="align-middle fas fa-file-invoice-dollar"></i> <span class="align-middle">Invoices</span>
                </div>
                <ul id="ui" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">

                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/invoices/new-invoice"><i class="align-middle fas fa-plus-square"></i>New Invoice</a>  </li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/invoices/invoice-list"><i class="align-middle fas fa-american-sign-language-interpreting"></i>Invoice List</a></li>


                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/invoices/payment-list"><i class="align-middle fas fa-receipt"></i>Payment History</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/invoices/retainers-list"><i class="align-middle fas fa-list"></i>Retainers</a></li>
                </ul>
            </li>

            <li class="sidebar-item">
                <div class="sidebar-link collapsed sidebar-submenu">
                    <i class="align-middle fas fa-calendar-alt"></i> <span class="align-middle">Calendar</span>
                </div>
                <ul id="ui" class="sidebar-dropdown list-unstyled collapse" data-parent="#sidebar">
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/calendars"><i class="align-middle fas fa-calendar-alt"></i>Calendar</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/schedule"><i class="align-middle fas fa-calendar-day"></i>Schedule</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/eventMap"><i class="align-middle fas fa-calendar-check"></i>Event Map</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/eventList"><i class="align-middle fas fa-calendar-alt"></i>Event List</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/taskList"><i class="align-middle fas fa-tasks"></i>Task List</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/newEvent"><i class="align-middle fas fa-calendar-day"></i>New Event</a></li>
                    <li class="sidebar-item"><a class="sidebar-link" routerLink="/activityFulfillment"><i class="align-middle fas fa-chart-line"></i>Activity Fulfillment</a></li>
                </ul>
            </li>


     

      <li class="sidebar-item">
        <div class="sidebar-link" >
          <i class="align-middle fas fa-clipboard-list"></i>
          <span class="align-middle">Reports</span>
        </div>
      </li>
    </ul>
  </div>
</nav>
