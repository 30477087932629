import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule, RouterLinkActive } from '@angular/router';
import { ActivityFulfillmentComponent } from './components/calendar/activity-fulfillment/activity-fulfillment.component';
import { CalendarsComponent } from './components/calendar/calendars/calendars.component';
import { EventListComponent } from './components/calendar/event-list/event-list.component';
import { EventMapComponent } from './components/calendar/event-map/event-map.component';
import { NewTaskComponent } from './components/calendar/new-task/new-task.component';
import { ScheduleComponent } from './components/calendar/schedule/schedule.component';
import { TaskListComponent } from './components/calendar/task-list/task-list.component';
import { AddCaseComponent } from './components/case/add-case/addCase.component';
import { EditCaseSecondComponent } from './components/case/edit-case-second/edit-case-second.component';
import { EditCaseComponent } from './components/case/edit-case/edit-case.component';
import { EditCaseThirdComponent } from './components/case/edit-case-third/edit-case-third.component'; 
import { CaseListComponent } from './components/case/case-list/case-list.component';
import { CaseRequestsComponent } from './components/case/case-requests/case-requests.component';
import { CaseReviewsComponent } from './components/case/case-reviews/case-reviews.component';
import { CaseSubjectsComponent } from './components/case/case-subjects/case-subjects.component';
import { CaseUpdatesComponent } from './components/case/case-updates/case-updates.component';
import { AddClientComponent } from './components/Clients/add-client/add-client.component';
import { ClientContactsComponent } from './components/Clients/client-contacts/client-contacts.component';
import { ClientListComponent } from './components/Clients/client-list/client-list.component';
import { ClientNotesComponent } from './components/Clients/client-notes/client-notes.component';
import { ContactNotesListComponent } from './components/Clients/contact-notes/contact-notes.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { ExpenseEntriesStaffComponent } from './components/Expenses/expense-entries-staff/expense-entries-staff.component';
import { ExpenseEntriesComponent } from './components/Expenses/expense-entries/expense-entries.component';
import { NewExpenseEntryComponent } from './components/Expenses/new_expense_entry/new-expense-entry.component';
import { PaymentSipsComponent } from './components/Expenses/payment-slips/payment-slips.component';
import { InvoiceListComponent } from './components/invoices/invoice-list/invoice-list.component';
import { PaymentHistoryComponent } from './components/invoices/payment-history/payment-history.component';
import { RetainersListComponent } from './components/invoices/retainers/retainers-list.component';
import { NewVendorComponent } from './components/staff/add-vendor/new-vendor.component';
import { InvestigatorSnapshotComponent } from './components/staff/Investigator-Snapshot/investigator-snapshot.component';
import { LicensesComponent } from './components/staff/Licenses/licenses.component';
import { StaffListComponent } from './components/staff/staff-list/staff-list.component';
import { StaffNotesComponent } from './components/staff/staff-notes/staff-notes.component';

import { AuthenticatedUserGuard, UnauthenticatedUserGuard } from './guards';
import { LoginComponent } from './components/authenticate/login/login.component';
import { LogoutComponent } from './components/authenticate/logout/logout.component';
import { AddEmployeeComponent } from './components/staff/add-employee/add-employee.component';
import { EditClientComponent } from './components/Clients/edit-client/edit-client.component';
import { CreatePasswordComponent } from './components/authenticate/create-password/create-password.component';

import { ChangePasswordComponent } from './components/change-password/change-password.component';

import { ClientListDetailComponent } from './components/Clients/client-list-detail/client-list-detail.component';
import { ResetPasswordRequestModel } from './models/authenticate/reset.password.request.model';
import { ResetPasswordComponent } from './components/authenticate/reset-password/reset-password.component';
import { NewEventComponent } from './components/calendar/new-event/new-event.component';
// for setting page

import { LocationDetailComponent } from './components/Clients/client-list-detail/clocation/location-detail/location-detail.component';

import { AddLocationComponent } from './components/Clients/client-list-detail/clocation/add-location/add-location.component';
import {
  NewAccessGroupComponent,
  NewUserRoleComponent,
  SettingComponent,
  UserAccessGroupsComponent,
  UserRolesComponent,
} from './components/setting';
import { EditLocationComponent } from './components/Clients/client-list-detail/clocation/edit-location/edit-location.component';

import { AddNewContactComponent } from './components/Clients/client-list-detail/contacts/add-new-contact/add-new-contact.component';

import { EditUserRoleComponent } from './components/setting/users-roles/user-roles/edit-user-role/edit-user-role.component';

import { NewInvoiceRuleComponent } from './components/Clients/client-list-detail/cinvoice/new-invoice-rule/new-invoice-rule.component';
import { EditAccessGroupComponent } from './components/setting/users-roles/user-access-groups/edit-access-group/edit-access-group.component';

import { GeneralGlobalSettingComponent } from './components/setting/global/general-global-setting/general-global-setting.component';
import { EditEmployeeComponent } from './components/staff/edit-employee/edit-employee.component';
import { PermissionGuard } from './guards/permission.guard';
import { StaffDetailComponent } from './components/staff/staff-detail/staff-detail.component';
import { StaffOverviewComponent } from './components/staff/staff-detail/staff-overview/staff-overview.component';
import { StaffPermissionComponent } from './components/staff/staff-detail/staff-permission/staff-permission.component';
import { CoverviewComponent } from './components/Clients/client-list-detail/coverview/coverview.component';

import { NewStaffLicenseComponent } from './components/staff/staff-detail/staff-license/new-staff-license/new-staff-license.component';

import { StaffLicenseComponent } from './components/staff/staff-detail/staff-license/staff-license.component';

import { StaffPriceListComponent } from './components/staff/staff-detail/staff-price-list/staff-price-list.component';

import { StaffAgendaComponent } from './components/staff/staff-detail/staff-agenda/staff-agenda.component';

import { StaffNotificationComponent } from './components/staff/staff-detail/staff-notification/staff-notification.component';

import { StaffPoliciesComponent } from './components/staff/staff-detail/staff-policies/staff-policies.component';

import { StaffHistoryComponent } from './components/staff/staff-detail/staff-history/staff-history.component';

import { StaffDetailNoteComponent } from './components/staff/staff-detail/staff-notes/staff-notes.component';
import { ClocationComponent } from './components/Clients/client-list-detail/clocation/clocation.component';
import { CaffiliatesComponent } from './components/Clients/client-list-detail/caffiliates/caffiliates.component';
import { ContactsComponent } from './components/Clients/client-list-detail/contacts/contacts.component';
import { PinvestigatorComponent } from './components/Clients/client-list-detail/pinvestigator/pinvestigator.component';
import { CpricelistComponent } from './components/Clients/client-list-detail/cpricelist/cpricelist.component';
import { CinvoiceComponent } from './components/Clients/client-list-detail/cinvoice/cinvoice.component';
import { CnotesComponent } from './components/Clients/client-list-detail/cnotes/cnotes.component';
import { ChistoryComponent } from './components/Clients/client-list-detail/chistory/chistory.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OverviewComponent } from './components/profile/overview/overview.component';
import { PermissionComponent } from './components/profile/permission/permission.component';

import { GeneralCaseSettingsComponent } from './components/setting/cases/general-case-settings/general-case-settings.component';

import { CaseAssignmentTypesComponent } from './components/setting/cases/case-assignment-types/case-assignment-types.component';

import { CaseFlagsComponent } from './components/setting/cases/case-flag/case-flag-list/case-flags.component';

import { NewCaseFlagComponent } from './components/setting/cases/case-flag/new-case-flag/new-case-flag.component';

import { CaseRegionsListComponent } from './components/setting/cases/case-regions/case-regions-list/case-regions-list.component';

import { EditCaseFlagComponent } from './components/setting/cases/case-flag/edit-case-flag/edit-case-flag.component';

import { NewCaseRegionComponent } from './components/setting/cases/case-regions/new-case-region/new-case-region.component';
import { EditCaseRegionsComponent } from './components/setting/cases/case-regions/edit-case-regions/edit-case-regions.component';

import { CaseServicesListComponent } from './components/setting/cases/case-services/case-services-list/case-services-list.component';
import { NewCaseServiceComponent } from './components/setting/cases/case-services/new-case-service/new-case-service.component';

import { ClientNewNoteComponent } from './components/Clients/client-list-detail/cnotes/client-new-note/client-new-note.component';

import { CaseTypeListComponent } from './components/setting/cases/case-types/case-type-list/case-type-list.component';
import { NewCaseTypeComponent } from './components/setting/cases/case-types/new-case-type/new-case-type.component';

import { CaseUpdatePresetListComponent } from './components/setting/cases/case-update-presets/case-update-preset-list/case-update-preset-list.component';

import { NewCaseUpdatePresetComponent } from './components/setting/cases/case-update-presets/new-case-update-preset/new-case-update-preset.component';

import { CaseStatusListComponent } from './components/setting/cases/case-status/case-status-list/case-status-list.component';
import { NewCaseStatusComponent } from './components/setting/cases/case-status/new-case-status/new-case-status.component';
import { EditCaseStatusTriggerComponent } from './components/setting/cases/case-status-triggers/edit-case-status-trigger/edit-case-status-trigger.component';
import { ClientEditNoteComponent } from './components/Clients/client-list-detail/cnotes/client-edit-note/client-edit-note.component';

import { CaseReviewListComponent } from './components/setting/cases/case-reviews/case-review-list/case-review-list.component';
import { NewCaseReviewComponent } from './components/setting/cases/case-reviews/new-case-review/new-case-review.component';
import { FolderTemplateListComponent } from './components/setting/cases/case-folder-template/folder-template-list/folder-template-list.component';
import { NewFolderTemplateComponent } from './components/setting/cases/case-folder-template/new-folder-template/new-folder-template.component';
import { EditStaffLicenseComponent } from './components/staff/shared/license/edit-staff-license/edit-staff-license.component';
import { CaseSubjectTypeListComponent } from './components/setting/cases/case-subjects/case-subject-type-list/case-subject-type-list.component';
import { NewCaseSubjectComponent } from './components/setting/cases/case-subjects/new-case-subject/new-case-subject.component';
import { ReferralSourcesListComponent } from './components/setting/cases/case-referral/referral-sources-list/referral-sources-list.component';
import { NewReferralSourceComponent } from './components/setting/cases/case-referral/new-referral-source/new-referral-source.component';

import { CustomFieldsListComponent } from './components/setting/global/custom-fields/custom-fields-list/custom-fields-list.component';
import { NewCustomFieldComponent } from './components/setting/global/custom-fields/new-custom-field/new-custom-field.component';
import { AddonsListComponent } from './components/setting/global/addons-integration/addons-list/addons-list.component';

import { UploadLogoEditComponent } from './components/setting/global/upload-logos/upload-logo-edit/upload-logo-edit.component';
import { DocumentTemplateListComponent } from './components/setting/global/document-template/document-template-list/document-template-list.component';
import { DocumentTemplateFilterComponent } from './components/setting/global/document-template/document-template-list/document-template-filter/document-template-filter.component';
import { ClientLocationOverviewComponent } from './components/Clients/client-list-detail/clocation/location-detail/client-location-overview/client-location-overview.component';
import { ClientLocationContactComponent } from './components/Clients/client-list-detail/clocation/location-detail/client-location-contact/client-location-contact.component';
import { ClientLocationNotesComponent } from './components/Clients/client-list-detail/clocation/location-detail/client-location-notes/client-location-notes.component';
import { AddClientLocationNotesComponent } from './components/Clients/client-list-detail/clocation/location-detail/client-location-notes/add-client-location-notes/add-client-location-notes.component';
import { EditClientLocationNotesComponent } from './components/Clients/client-list-detail/clocation/location-detail/client-location-notes/edit-client-location-notes/edit-client-location-notes.component';
import { NewDocumentTemplateComponent } from './components/setting/global/document-template/new-document-template/new-document-template.component';
import { CompanyLocationListComponent } from './components/setting/global/company-location/company-location-list/company-location-list.component';
import { NewCompanyLocationComponent } from './components/setting/global/company-location/new-company-location/new-company-location.component';
import { GeneralFinanceSettingComponent } from './components/setting/finance/general-finance-setting/general-finance-setting.component';
import { InvoiceAndExpenseListComponent } from './components/setting/finance/invoice-and-expense/invoice-and-expense-list/invoice-and-expense-list.component';
import { ItemCodesListComponent } from './components/setting/finance/item-codes/item-codes-list/item-codes-list.component';
import { NewItemCodeComponent } from './components/setting/finance/item-codes/new-item-code/new-item-code.component';
import { PaymentMethodsListComponent } from './components/setting/finance/payment-method/payment-methods-list/payment-methods-list.component';
import { NewPaymentMethodComponent } from './components/setting/finance/payment-method/new-payment-method/new-payment-method.component';
import { TaxRateListComponent } from './components/setting/finance/tax-rate/tax-rate-list/tax-rate-list.component';
import { NewTaxRateComponent } from './components/setting/finance/tax-rate/new-tax-rate/new-tax-rate.component';
import { TrackingCategoriesListComponent } from './components/setting/finance/tracking-categories/tracking-categories-list/tracking-categories-list.component';
import { NewTrackingCategoryComponent } from './components/setting/finance/tracking-categories/new-tracking-category/new-tracking-category.component';
import { NewStaffNoteComponent } from './components/staff/staff-detail/staff-notes/new-staff-note/new-staff-note.component';
import { LicenseOverviewComponent } from './components/staff/shared/license/staff-license-detail/license-overview/license-overview.component';
import { LicenseHistoryComponent } from './components/staff/shared/license/staff-license-detail/license-history/license-history.component';
import { StaffLicenseDetailComponent } from './components/staff/shared/license/staff-license-detail/staff-license-detail.component';
import { Permission } from './helpers/enums/roles/permission';
import { Error403Component, Error404Component } from './components/error';
import { EditStaffNoteComponent } from './components/staff/staff-detail/staff-notes/edit-staff-note/edit-staff-note.component';
import { UserSecuritySettingComponent } from './components/setting/users-roles/user-security-setting/user-security-setting.component';
import { CompanyPoliciesListComponent } from './components/setting/users-roles/company-policies/company-policies-list/company-policies-list.component';
import { NewCompanyPolicyComponent } from './components/setting/users-roles/company-policies/new-company-policy/new-company-policy.component';
import { DashboardTabPresetListComponent } from './components/setting/dashboard-tab-preset/dashboard-tab-preset-list/dashboard-tab-preset-list.component';
import { isNull } from '@angular/compiler/src/output/output_ast';
import { StaffBreadCrumb } from './helpers/constants/breadcrumbs/staff-breadcrumb';
import { SettingsBreadCrumb } from './helpers/constants/breadcrumbs/settings-breadcrumb';
import { ProfileNotesComponent } from './components/profile/notes/notes.component';
import { NewProfileNoteComponent } from './components/profile/notes/new-note/new-note.component';
import { EditProfileNoteComponent } from './components/profile/notes/edit-note/edit-note.component';
import { PaymentSlipDetailComponent } from './components/Expenses/payment-slips/payment-slip-detail/payment-slip-detail.component';
import { PaymentSlipOverviewComponent } from './components/Expenses/payment-slips/payment-slip-detail/payment-slip-overview/payment-slip-overview.component';
import { PaymentSlipEntriesComponent } from './components/Expenses/payment-slips/payment-slip-detail/payment-slip-entries/payment-slip-entries.component';
import { ClientBreadCrumb } from './helpers/constants/breadcrumbs/client-breadcrumb';
import { PaymentSlipHistoryComponent } from './components/Expenses/payment-slips/payment-slip-detail/payment-slip-history/payment-slip-history.component';
import { ContactDetailComponent } from './components/Clients/client-contacts/contact-detail/contact-detail.component';
import { ContactOverviewComponent } from './components/Clients/client-contacts/contact-detail/contact-overview/contact-overview.component';
import { ContactPermissionComponent } from './components/Clients/client-contacts/contact-detail/contact-permission/contact-permission.component';
import { ContactNotificationComponent } from './components/Clients/client-contacts/contact-detail/contact-notification/contact-notification.component';
import { ContactHistoryComponent } from './components/Clients/client-contacts/contact-detail/contact-history/contact-history.component';
import { NewClientContactNoteComponent } from './components/Clients/client-contacts/contact-detail/contact-notes/new-client-contact-note/new-client-contact-note.component';
import { ContactDetailNotesComponent } from './components/Clients/client-contacts/contact-detail/contact-notes/contact-notes.component';
import { NewInvoiceComponent } from './components/invoices/new-invoice/new-invoice.component';
import { EditClientContactComponent } from './components/Clients/client-list-detail/contacts/edit-client-contact/edit-client-contact.component';
import { NewInvoiceAndExpenseItemComponent } from './components/setting/finance/invoice-and-expense/new-invoice-and-expense-item/new-invoice-and-expense-item.component';

import { NewRetainerComponent } from './components/invoices/new-retainer/new-retainer.component';
import { EditCaseAssignmentTypesComponent } from './components/setting/cases/case-assignment-types/edit-case-assignment-types/edit-case-assignment-types.component';
import { CaseDetailComponent } from './components/case/case-detail/case-detail.component';
import { EditClientContactNoteComponent } from './components/Clients/client-contacts/contact-detail/contact-notes/edit-client-contact-note/edit-client-contact-note.component';
import { CaseOverviewComponent } from './components/case/case-detail/case-overview/case-overview.component';
import { CaseDetailAssignedComponent } from './components/case/case-detail/case-detail-assigned/case-detail-assigned.component';
import { CaseDetailFilesComponent } from './components/case/case-detail/case-detail-files/case-detail-files.component';
import { CaseDetailUpdatesComponent } from './components/case/case-detail/case-detail-updates/case-detail-updates.component';
import { CaseDetailHistoryComponent } from './components/case/case-detail/case-detail-history/case-detail-history.component';
import { CaseDetailReviewsComponent } from './components/case/case-detail/case-detail-reviews/case-detail-reviews.component';
import { CaseDetailBillingComponent } from './components/case/case-detail/case-detail-billing/case-detail-billing.component';
import { CaseDetailExpensesComponent } from './components/case/case-detail/case-detail-expenses/case-detail-expenses.component';
import { EditCustomFieldComponent } from './components/setting/global/custom-fields/edit-custom-field/edit-custom-field.component';
import { NewCaseUpdateComponent } from './components/case/case-updates/new-case-update/new-case-update.component';
import { CaseSubjectDetailComponent } from './components/case/case-subjects/case-subject-detail/case-subject-detail.component';
import { CaseSubjectDetailOverviewComponent } from './components/case/case-subjects/case-subject-detail/case-subject-detail-overview/case-subject-detail-overview.component';
import { CaseSubjectDetailConnectionComponent } from './components/case/case-subjects/case-subject-detail/case-subject-detail-connection/case-subject-detail-connection.component';
import { CaseSubjectDetailHistoryComponent } from './components/case/case-subjects/case-subject-detail/case-subject-detail-history/case-subject-detail-history.component';
import { EditCaseServicesComponent } from './components/setting/cases/case-services/edit-case-services/edit-case-services.component';
import { EditFolderTemplateComponent } from './components/setting/cases/case-folder-template/edit-folder-template/edit-folder-template.component';
import { CaseRequestDetailComponent } from './components/case/case-requests/case-request-detail/case-request-detail.component';
import { CaseRequestDetailOverviewComponent } from './components/case/case-requests/case-request-detail/case-request-detail-overview/case-request-detail-overview.component';
import { CaseRequestDetailSubjectsComponent } from './components/case/case-requests/case-request-detail/case-request-detail-subjects/case-request-detail-subjects.component';
import { EditCaseTypeComponent } from './components/setting/cases/case-types/edit-case-type/edit-case-type.component';
import { CaseRequestDetailHistoryComponent } from './components/case/case-requests/case-request-detail/case-request-detail-history/case-request-detail-history.component';
import { CaseRequestDetailFilesComponent } from './components/case/case-requests/case-request-detail/case-request-detail-files/case-request-detail-files.component';
import { EditCaseSubjectComponent } from './components/setting/cases/case-subjects/edit-case-subject/edit-case-subject.component';
import { EditReferralSourceComponent } from './components/setting/cases/case-referral/edit-referral-source/edit-referral-source.component';
import { EditCaseStatusComponent } from './components/setting/cases/case-status/edit-case-status/edit-case-status.component';
import { EditCaseReviewComponent } from './components/setting/cases/case-reviews/edit-case-review/edit-case-review.component';
import { EditCaseUpdatePresetComponent } from './components/setting/cases/case-update-presets/edit-case-update-preset/edit-case-update-preset.component';
import { CaseUpdatesDetailComponent } from './components/case/case-updates/case-updates-detail/case-updates-detail.component';
import { CaseUpdateDetailOverviewComponent } from './components/case/case-updates/case-updates-detail/case-update-detail-overview/case-update-detail-overview.component';
import { CaseUpdateDetailHistoryComponent } from './components/case/case-updates/case-updates-detail/case-update-detail-history/case-update-detail-history.component';
import { CaseMapComponent } from './components/case/case-map/case-map.component';
import { EditDocumentTemplateComponent } from './components/setting/global/document-template/edit-document-template/edit-document-template.component';
import { EventListOverviewComponent } from './components/calendar/event-list/event-list-overview/event-list-overview.component';
import { CompanyPolicyOverviewComponent } from './components/setting/users-roles/company-policies/company-policy-overview/company-policy-overview.component';
import { PolicyHistoryComponent } from './components/setting/users-roles/company-policies/company-policy-overview/policy-history/policy-history.component';
import { PolicyOverviewComponent } from './components/setting/users-roles/company-policies/company-policy-overview/policy-overview/policy-overview.component';
import { NewPolicyVersionComponent } from './components/setting/users-roles/company-policies/new-policy-version/new-policy-version.component';
import { EditCompanyPolicyComponent } from './components/setting/users-roles/company-policies/edit-company-policy/edit-company-policy.component';
import { EditPolicyVersionComponent } from './components/setting/users-roles/company-policies/edit-policy-version/edit-policy-version.component';
import { EditCompanyLocationComponent } from './components/setting/global/company-location/edit-company-location/edit-company-location.component';
import { ClientLocationHistoryComponent } from './components/Clients/client-list-detail/clocation/location-detail/client-location-history/client-location-history.component';
import { CaseRequestBreadCrumb } from './helpers/constants/breadcrumbs/case-request-breadcrumb';
import { CaseSubjectBreadCrumb } from './helpers/constants/breadcrumbs/case-subject-breadcrumb';
import { AddCaseSecondComponent } from './components/case/add-case-second/add-case-second.component';
import { AddCaseThirdComponent } from './components/case/add-case-third/add-case-third.component';
import { AddCaseCaseSummaryComponent } from './components/case/add-case-case-summary/add-case-case-summary.component';
import { AnswerReviewComponent } from './components/case/case-reviews/answer-review/answer-review.component';
import { CaseDetailSubjectsComponent } from './components/case/case-detail/case-detail-subjects/case-detail-subjects.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [AuthenticatedUserGuard],
  },

  {
    path: 'account/login',
    component: LoginComponent,
    canActivate: [UnauthenticatedUserGuard],
  },
  {
    path: 'account/logout',
    component: LogoutComponent,
  },
  {
    path: 'account/create-password/:key/:secret',
    component: CreatePasswordComponent,
    canActivate: [UnauthenticatedUserGuard],
  },
  {
    path: 'account/reset/:key/:secret',
    component: ResetPasswordComponent,
    canActivate: [UnauthenticatedUserGuard],
  },
  {
    path: 'myProfile',
    component: ProfileComponent,
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      { path: 'overview', component: OverviewComponent },
      { path: 'permission', component: PermissionComponent },
      { path: 'license', component: StaffLicenseComponent },
      { path: 'price-list', component: StaffPriceListComponent },
      { path: 'agenda', component: StaffAgendaComponent },
      { path: 'notifications', component: StaffNotificationComponent },
      { path: 'policies', component: StaffPoliciesComponent },
      { path: 'notes', component: ProfileNotesComponent },
      { path: 'history', component: StaffHistoryComponent },
    ],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'staff',
    component: StaffListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: { name: StaffBreadCrumb.STAFF, parents: [] },
    },
  },
  {
    path: 'staff/add-employee',
    component: AddEmployeeComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.CreateStaffMembers],
      breadcrumbs: {
        name: StaffBreadCrumb.Add_Staff,
        parents: [StaffBreadCrumb.STAFF],
      },
    },
  },
  {
    path: 'staff/edit-employee/:id',
    component: EditEmployeeComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.EditStaffMembers],
      breadcrumbs: {
        name: StaffBreadCrumb.EDIT_STAFF,
        parents: [StaffBreadCrumb.STAFF, StaffBreadCrumb.STAFF_DETAILS],
      },
    },
  },
  {
    path: 'staff/:id',
    component: StaffDetailComponent,
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      {
        path: 'overview',
        component: StaffOverviewComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_OVERVIEW } },
      },
      {
        path: 'permission',
        component: StaffPermissionComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_PERMISSION } },
      },
      {
        path: 'license',
        component: StaffLicenseComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_LICENSE } },
      },
      {
        path: 'price-list',
        component: StaffPriceListComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_PRICELIST } },
      },
      {
        path: 'agenda',
        component: StaffAgendaComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_AGENDA } },
      },
      {
        path: 'notifications',
        component: StaffNotificationComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_NOTIFICATION } },
      },
      {
        path: 'policies',
        component: StaffPoliciesComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_POLICIES } },
      },
      {
        path: 'notes',
        component: StaffDetailNoteComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_NOTES } },
      },
      {
        path: 'history',
        component: StaffHistoryComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_HISTORY } },
      },
    ],
    data: {
      permissions: [Permission.ViewStaffMembers],
      breadcrumbs: {
        name: StaffBreadCrumb.STAFF_DETAILS,
        parents: [StaffBreadCrumb.STAFF, StaffBreadCrumb.STAFF_DETAILS],
      },
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'client/contact-notes',
    component: ContactNotesListComponent,
  },
  {
    path: 'client/contact-show/:contactId',
    component: ContactDetailComponent,
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      {
        path: 'overview',
        component: ContactOverviewComponent,
        data: { breadcrumbs: { name: ClientBreadCrumb.CONTACT_OVERVIEW } },
      },
      {
        path: 'permission',
        component: ContactPermissionComponent,
        data: { breadcrumbs: { name: ClientBreadCrumb.CONTACT_PERMISSION } },
      },
      {
        path: 'notifications',
        component: ContactNotificationComponent,
        data: { breadcrumbs: { name: ClientBreadCrumb.CONTACT_NOTIFICATION } },
      },
      {
        path: 'notes',
        component: ContactDetailNotesComponent,
        data: { breadcrumbs: { name: ClientBreadCrumb.CONTACT_NOTES } },
      },
      {
        path: 'history',
        component: ContactHistoryComponent,
        data: { breadcrumbs: { name: ClientBreadCrumb.CONTACT_HISTORY } },
      },
    ],
    data: {
      permissions: [Permission.ViewClientContacts],
      breadcrumbs: {
        name: ClientBreadCrumb.CONTACT_DETAILS,
        parents: [
          ClientBreadCrumb.CLIENT,
          ClientBreadCrumb.CLIENT_DETAILS,
          ClientBreadCrumb.CLIENT_CONTACTS,
          ClientBreadCrumb.CONTACT_DETAILS,
        ],
      },
    },
    runGuardsAndResolvers: 'always',
  },

  {
    path: 'staff/:id/license/add',
    component: NewStaffLicenseComponent,
    canActivate: [AuthenticatedUserGuard, PermissionGuard],
    data: {
      permissions: [Permission.CreateStaffLicenses],
      breadcrumbs: {
        name: StaffBreadCrumb.NEW_LICENSE,
        parents: [StaffBreadCrumb.STAFF, StaffBreadCrumb.STAFF_LICENSE],
      },
    },
  },
  {
    path: 'staff/:id/license/edit/:licenseId',
    component: EditStaffLicenseComponent,
    canActivate: [AuthenticatedUserGuard, PermissionGuard],
    data: {
      permissions: [Permission.EditStaffLicenses],
      breadcrumbs: {
        name: StaffBreadCrumb.EDIT_LICENSE,
        parents: [StaffBreadCrumb.STAFF, StaffBreadCrumb.LICENSE_DETAILS],
      },
    },
  },
  {
    path: 'staff/license/show/:licenseId',
    component: StaffLicenseDetailComponent,
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      {
        path: 'overview',
        component: LicenseOverviewComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.LICENSE_OVERVIEW } },
      },
      {
        path: 'history',
        component: LicenseHistoryComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.LICENSE_HISTORY } },
      },
    ],
    data: {
      permissions: [Permission.ViewStaffLicenses],
      breadcrumbs: {
        name: StaffBreadCrumb.LICENSE_DETAILS,
        parents: [
          StaffBreadCrumb.STAFF,
          StaffBreadCrumb.STAFF_DETAILS,
          StaffBreadCrumb.STAFF_LICENSE,
          StaffBreadCrumb.LICENSE_DETAILS,
        ],
      },
    },
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'staff/:id/notes/add',
    component: NewStaffNoteComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.ViewStaffMembers],
      breadcrumbs: {
        name: StaffBreadCrumb.NEW_NOTE,
        parents: [StaffBreadCrumb.STAFF, StaffBreadCrumb.STAFF_DETAILS],
      },
    },
  },
  {
    path: 'staff/notes/edit/:noteId',
    component: EditStaffNoteComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.ViewStaffMembers],
      breadcrumbs: {
        name: StaffBreadCrumb.EDIT_NOTE,
        parents: [StaffBreadCrumb.STAFF, StaffBreadCrumb.STAFF_DETAILS],
      },
    },
  },
  {
    path: 'myProfile/notes/add',
    component: NewProfileNoteComponent,
    canActivate: [AuthenticatedUserGuard],
  },
  {
    path: 'myProfile/notes/edit/:noteId',
    component: EditProfileNoteComponent,
    canActivate: [AuthenticatedUserGuard],
  },
  {
    path: 'setting',
    component: SettingComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: { name: SettingsBreadCrumb.SETTINGS, parents: [] },
    },
  },

  {
    path: 'setting/role',
    component: UserRolesComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.USER_ROLES,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },

  {
    path: 'setting/accessgroup/list',
    component: UserAccessGroupsComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.USER_ACCESS_GROUPS,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },

  {
    path: 'setting/accessgroup/new',
    component: NewAccessGroupComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_USER_ACCESS_GROUPS,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.USER_ACCESS_GROUPS,
        ],
      },
    },
  },
  {
    path: 'setting/accessgroup/edit/:id',
    component: EditAccessGroupComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_USER_ACCESS_GROUP,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.USER_ACCESS_GROUPS,
        ],
      },
    },
  },

  {
    path: 'case-list',
    component: CaseListComponent,
    canActivate: [AuthenticatedUserGuard],
  },

  {
    path: 'setting/cases/regions-list',
    component: CaseRegionsListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.CASE_REGION_LIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },

  {
    path: 'setting/cases/new-region',
    component: NewCaseRegionComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_CASE_REGION,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_REGION_LIST,
        ],
      },
    },
  },

  {
    path: 'setting/cases/edit-region/:id',
    component: EditCaseRegionsComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_CASE_REGION,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_REGION_LIST,
        ],
      },
    },
  },

  {
    path: 'setting/cases/flag-list',
    component: CaseFlagsComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.CASE_FLAG_LIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },

  {
    path: 'setting/cases/services-list',
    component: CaseServicesListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.CASE_SERVICE_LIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },

  {
    path: 'setting/cases/new-service',
    component: NewCaseServiceComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_CASE_SERVICE,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_SERVICE_LIST,
        ],
      },
    },
  },

  {
    path: 'setting/cases/edit-service/:id',
    component: EditCaseServicesComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_CASE_SERVICE,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_SERVICE_LIST,
        ],
      },
    },
  },

  {
    path: 'setting/cases/folder-templates',
    component: FolderTemplateListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.FOLDER_TEMPLATE_lIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },

  {
    path: 'setting/cases/new-folder-template',
    component: NewFolderTemplateComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_FOLDER_TEMPLATE,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.FOLDER_TEMPLATE_lIST,
        ],
      },
    },
  },

  {
    path: 'setting/cases/edit-folder-template/:id',
    component: EditFolderTemplateComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_FOLDER_TEMPLATE,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.FOLDER_TEMPLATE_lIST,
        ],
      },
    },
  },

  {
    path: 'setting/cases/assigntype',
    component: CaseAssignmentTypesComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.CASE_ASSIGNMENT_TYPE,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },

  {
    path: 'setting/cases/edit-assignment-type/:id',
    component: EditCaseAssignmentTypesComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_CASE_ASSIGNMENT_TYPE,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_ASSIGNMENT_TYPE,
        ],
      },
    },
  },

  {
    path: 'setting/cases/new-flag',
    component: NewCaseFlagComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_CASE_FLAG,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_FLAG_LIST,
        ],
      },
    },
  },

  {
    path: 'setting/cases/edit-flag/:id',
    component: EditCaseFlagComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_CASE_FLAG,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_FLAG_LIST,
        ],
      },
    },
  },
  { path: 'add-case-first-steps', component: AddCaseComponent },
  { path: 'add-case-last-steps', component: AddCaseSecondComponent },
  { path: 'add-case-supporting-files', component: AddCaseThirdComponent },
  { path: 'add-case-summary', component: AddCaseCaseSummaryComponent},
  { path: 'case-requests', component: CaseRequestsComponent },
  { path: 'edit-case-second-steps', component: EditCaseSecondComponent },
  { path: 'edit-case-steps', component: EditCaseComponent },
  { path: 'edit-case-third-steps', component: EditCaseThirdComponent },
  { path: 'case-list', component: CaseListComponent },
  
  {
    path: 'case-request/:id',
    component: CaseRequestDetailComponent,
    canActivate: [AuthenticatedUserGuard],
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard],
        
      },
      {
        path: 'overview',
        component: CaseRequestDetailOverviewComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: CaseRequestBreadCrumb.CASE_REQUEST_OVERVIEW } },
      },
      {
        path: 'subjects',
        component: CaseRequestDetailSubjectsComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: CaseRequestBreadCrumb.CASE_REQUEST_SUBJECT } },
      },
      {
        path: 'files',
        component: CaseRequestDetailFilesComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: CaseRequestBreadCrumb.CASE_REQUEST_FILES } },
      },
      {
        path: 'history',
        component: CaseRequestDetailHistoryComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: CaseRequestBreadCrumb.CASE_REQUEST_HISTORY } },
      },
    ],
    runGuardsAndResolvers: 'always',
    data: {
      permissions: [Permission.ViewClients],
      breadcrumbs: {
        name: CaseRequestBreadCrumb.CASE_REQUEST_DETAILS,
        parents: [CaseRequestBreadCrumb.CASE_REQUEST, CaseRequestBreadCrumb.CASE_REQUEST_DETAILS],
      },
      // todo: change above breadcrumb details
    },
  },

  { path: 'case-subjects', component: CaseSubjectsComponent },

  {
    path: 'case-subjects/:id',
    component: CaseSubjectDetailComponent,
    canActivate: [AuthenticatedUserGuard],
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard],
      },
      {
        path: 'overview',
        component: CaseSubjectDetailOverviewComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: CaseSubjectBreadCrumb.CASE_SUBJECT_OVERVIEW } },
      },
      {
        path: 'connection',
        component: CaseSubjectDetailConnectionComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: CaseSubjectBreadCrumb.CASE_SUBJECT_CONNECTION } },
      },
      {
        path: 'history',
        component: CaseSubjectDetailHistoryComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: CaseSubjectBreadCrumb.CASE_SUBJECT_HISTORY } },
      },
    ],
    runGuardsAndResolvers: 'always',
    data: {
      permissions: [Permission.ViewClients],
      breadcrumbs: {
        name: CaseSubjectBreadCrumb.CASE_SUBJECT_DETAILS,
        parents: [CaseSubjectBreadCrumb.CASE_SUBJECT, CaseSubjectBreadCrumb.CASE_SUBJECT_DETAILS],
      },
    },
  },

  { path: 'case-map', component: CaseMapComponent },
  { path: 'case-updates', component: CaseUpdatesComponent },

  {
    path: 'case-updates-detail/:id',
    component: CaseUpdatesDetailComponent,
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      {
        path: 'overview',
        component: CaseUpdateDetailOverviewComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_OVERVIEW } },
      },
      {
        path: 'history',
        component: CaseUpdateDetailHistoryComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_NOTES } },
      },
    ],
  },

  { path: 'case-reviews', component: CaseReviewsComponent },
  {
    path: 'case-reviews/entry/new/:id',
    component: AnswerReviewComponent
  },

  {
    path: 'case-updates/:id/new-case-update',
    component: NewCaseUpdateComponent,
  },

  {
    path: 'case-detail/:id',
    component: CaseDetailComponent,
    children: [
      { path: '', redirectTo: 'overview', pathMatch: 'full' },
      {
        path: 'overview',
        component: CaseOverviewComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_OVERVIEW } },
      },
      {
        path: 'updates',
        component: CaseDetailUpdatesComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_PERMISSION } },
      },
      {
        path: 'subjects',
        component: CaseDetailSubjectsComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_LICENSE } },
      },
      {
        path: 'file',
        component: CaseDetailFilesComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_PRICELIST } },
      },
      {
        path: 'assigned',
        component: CaseDetailAssignedComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_AGENDA } },
      },
      {
        path: 'reviews',
        component: CaseDetailReviewsComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_NOTIFICATION } },
      },
      {
        path: 'agenda',
        component: StaffPoliciesComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_POLICIES } },
      },
      {
        path: 'expenses',
        component: CaseDetailExpensesComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_NOTES } },
      },
      {
        path: 'billing',
        component: CaseDetailBillingComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_HISTORY } },
      },
      {
        path: 'history',
        component: CaseDetailHistoryComponent,
        data: { breadcrumbs: { name: StaffBreadCrumb.STAFF_NOTES } },
      },
    ],
  },
  {
    path: 'client/add-client',
    component: AddClientComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.CreateClients],
      breadcrumbs: {
        name: ClientBreadCrumb.NEW_CLIENT,
        parents: [ClientBreadCrumb.CLIENT],
      },
    },
  },
  {
    path: 'client/edit-client/:id',
    component: EditClientComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.EditClients],
      breadcrumbs: {
        name: ClientBreadCrumb.EDIT_CLIENT,
        parents: [ClientBreadCrumb.CLIENT, ClientBreadCrumb.CLIENT_DETAILS],
      },
    },
  },
  {
    path: 'client',
    component: ClientListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: { name: ClientBreadCrumb.CLIENT, parents: [] },
    },
  },
  {
    path: 'client/contact-list',
    component: ClientContactsComponent,
    data: {
      permissions: [Permission.ViewClientContacts],
    },
  },
  { path: 'clientNotes', component: ClientNotesComponent },
  { path: 'investigatorSnapshot', component: InvestigatorSnapshotComponent },
  { path: 'licenses', component: LicensesComponent },
  { path: 'staffNotes', component: StaffNotesComponent },

  { path: 'expenseEntriesStaff', component: ExpenseEntriesStaffComponent },

  { path: 'invoices/invoice-list', component: InvoiceListComponent },
  { path: 'invoices/new-invoice', component: NewInvoiceComponent },
  { path: 'invoices/retainers-list', component: RetainersListComponent },
  { path: 'invoices/payment-list', component: PaymentHistoryComponent },
  { path: 'invoices/new-retainer', component: NewRetainerComponent },

  { path: 'activityFulfillment', component: ActivityFulfillmentComponent },
  { path: 'calendars', component: CalendarsComponent },
  { path: 'eventList', component: EventListComponent },
  { path: 'newEvent', component: NewEventComponent },

  {
    path: 'eventList/:id',
    component: EventListOverviewComponent,
    canActivate: [AuthenticatedUserGuard],
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard],
      },
      {
        path: 'overview',
        component: CoverviewComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: ClientBreadCrumb.CLIENT_OVERVIEW } },
      },
    ],
  },

  { path: 'eventMap', component: EventMapComponent },
  { path: 'schedule', component: ScheduleComponent },
  { path: 'taskList', component: TaskListComponent },
  { path: 'addNewVendor', component: NewVendorComponent },

  { path: 'newTask', component: NewTaskComponent },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthenticatedUserGuard],
  },

  {
    path: 'client/:id',
    component: ClientListDetailComponent,
    canActivate: [AuthenticatedUserGuard],
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard],
      },
      {
        path: 'overview',
        component: CoverviewComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: ClientBreadCrumb.CLIENT_OVERVIEW } },
      },
      {
        path: 'affiliate',
        component: CaffiliatesComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: ClientBreadCrumb.CLIENT_AFFILIATES } },
      },
      {
        path: 'location',
        component: ClocationComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: ClientBreadCrumb.CLIENT_LOCATIONS } },
      },
      {
        path: 'contact',
        component: ContactsComponent,
        data: {
          permissions: [Permission.ViewClientContacts],
          breadcrumbs: { name: ClientBreadCrumb.CLIENT_CONTACTS },
        },
      },
      {
        path: 'preferred-investigator',
        component: PinvestigatorComponent,
        data: {
          breadcrumbs: { name: ClientBreadCrumb.CLIENT_PREFERRED_INVESTIGATOR },
        },
      },
      {
        path: 'price-list',
        component: CpricelistComponent,
        data: { breadcrumbs: { name: ClientBreadCrumb.CLIENT_PRICE_LIST } },
      },
      {
        path: 'invoice-rules',
        component: CinvoiceComponent,
        data: { breadcrumbs: { name: ClientBreadCrumb.CLIENT_INVOICE_RULES } },
      },
      {
        path: 'notes',
        component: CnotesComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: ClientBreadCrumb.CLIENT_NOTES } },
      },
      {
        path: 'history',
        component: ChistoryComponent,
        data: { breadcrumbs: { name: ClientBreadCrumb.CLIENT_HISTORY } },
      },
    ],
    runGuardsAndResolvers: 'always',
    data: {
      permissions: [Permission.ViewClients],
      breadcrumbs: {
        name: ClientBreadCrumb.CLIENT_DETAILS,
        parents: [ClientBreadCrumb.CLIENT, ClientBreadCrumb.CLIENT_DETAILS],
      },
    },
  },

  {
    path: 'setting/role/new-role',
    component: NewUserRoleComponent,
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_USER_ROLE,
        parents: [SettingsBreadCrumb.SETTINGS, SettingsBreadCrumb.USER_ROLES],
      },
    },
  },
  {
    path: 'setting/role/user-security-setting',
    component: UserSecuritySettingComponent,
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.USER_SECURITY_SETTINGS,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },

  {
    path: 'setting/role/company-policies',
    component: CompanyPoliciesListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.COMPANY_POLICY_LIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },

  {
    path: 'company-policy-detail/:id',
    component: CompanyPolicyOverviewComponent,
    canActivate: [AuthenticatedUserGuard],
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard],
      },
      {
        path: 'overview',
        component: PolicyOverviewComponent,
        canActivate: [AuthenticatedUserGuard],
        data: {
          breadcrumbs: { name: SettingsBreadCrumb.COMPANY_POLICY_OVERVIEW },
        },
      },
      {
        path: 'history',
        component: PolicyHistoryComponent,
        canActivate: [AuthenticatedUserGuard],
        data: {
          breadcrumbs: { name: SettingsBreadCrumb.COMPANY_POLICY_HISTORY },
        },
      },
    ],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.COMPANY_POLICY_DETAIL,
        parents: [
          SettingsBreadCrumb.COMPANY_POLICY_LIST,
          SettingsBreadCrumb.COMPANY_POLICY_DETAIL,
        ],
      },
      runGuardsAndResolvers: 'always',
    },
  },
  {
    path: 'setting/role/policy/:id/new-policy-version',
    component: NewPolicyVersionComponent,
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_COMPANY_POLICY_VERSION,
        parents: [
          SettingsBreadCrumb.COMPANY_POLICY_LIST,
          SettingsBreadCrumb.COMPANY_POLICY_DETAIL,
        ],
      },
    },
  },
  {
    path: 'setting/role/policy/:id/edit-policy-version/:vid',
    component: EditPolicyVersionComponent,
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_COMPANY_POLICY_VERSION,
        parents: [
          SettingsBreadCrumb.COMPANY_POLICY_LIST,
          SettingsBreadCrumb.COMPANY_POLICY_DETAIL,
        ],
      },
    },
  },
  {
    path: 'setting/role/new-company-policy',
    component: NewCompanyPolicyComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_COMPANY_POLICY,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.COMPANY_POLICY_LIST,
        ],
      },
    },
  },

  {
    path: 'setting/role/edit-company-policy/:id',
    component: EditCompanyPolicyComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_COMPANY_POLICY,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.COMPANY_POLICY_LIST,
        ],
      },
    },
  },

  {
    path: 'setting/global/general',
    component: GeneralGlobalSettingComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.GENERAL_GLOBAL_SETTING,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },
  { path: 'setting/cases/general', component: GeneralCaseSettingsComponent },
  { path: 'setting/cases/assigntype', component: CaseAssignmentTypesComponent },
  {
    path: 'setting/cases/edit-assignment-type/:id',
    component: EditCaseAssignmentTypesComponent,
  },
  { path: 'setting/cases/flag-list', component: CaseFlagsComponent },
  { path: 'setting/cases/new-flag', component: NewCaseFlagComponent },
  { path: 'setting/cases/edit-flag', component: EditCaseFlagComponent },

  { path: 'setting/cases/regions-list', component: CaseRegionsListComponent },

  { path: 'setting/cases/new-region', component: NewCaseRegionComponent },
  { path: 'setting/cases/edit-region', component: EditCaseRegionsComponent },
  { path: 'setting/cases/services-list', component: CaseServicesListComponent },
  { path: 'setting/cases/new-service', component: NewCaseServiceComponent },
  { path: 'setting/cases/edit-service', component: EditCaseServicesComponent },
  {
    path: 'setting/cases/case-type-list',
    component: CaseTypeListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.CASE_TYPE_LIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },
  {
    path: 'setting/cases/new-case-type',
    component: NewCaseTypeComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_CASE_TYPE,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_TYPE_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/cases/edit-case-type/:id',
    component: EditCaseTypeComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_CASE_TYPE,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_TYPE_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/cases/update-preset-list',
    component: CaseUpdatePresetListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.CASE_UPDATE_PRESET_LIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },
  {
    path: 'setting/cases/new-preset',
    component: NewCaseUpdatePresetComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_CASE_UPDATE_PRESET,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_UPDATE_PRESET_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/cases/edit-preset/:id',
    component: EditCaseUpdatePresetComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_CASE_UPDATE_PRESET,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_STATUS_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/cases/case-status-list',
    component: CaseStatusListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.CASE_STATUS_LIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },
  {
    path: 'setting/cases/new-case-status',
    component: NewCaseStatusComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_CASE_STATUS,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_STATUS_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/cases/edit-case-status/:id',
    component: EditCaseStatusComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_CASE_STATUS,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_STATUS_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/cases/edit-case-status-trigger',
    component: EditCaseStatusTriggerComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_CASE_STATUS_TRIGGERS,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },
  {
    path: 'setting/cases/case-review-list',
    component: CaseReviewListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.CASE_REVIEWS_LIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },
  {
    path: 'setting/cases/new-case-review',
    component: NewCaseReviewComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_CASE_REVIEWS,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_REVIEWS_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/cases/edit-case-review/:id',
    component: EditCaseReviewComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_CASE_REVIEWS,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CASE_REVIEWS_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/cases/folder-templates',
    component: FolderTemplateListComponent,
    canActivate: [AuthenticatedUserGuard],
  },
  {
    path: 'setting/cases/new-folder-template',
    component: NewFolderTemplateComponent,
  },
  {
    path: 'setting/cases/edit-folder-template',
    component: EditFolderTemplateComponent,
  },
  {
    path: 'setting/cases/subject-type-list',
    component: CaseSubjectTypeListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.SUBJECT_TYPE_LIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },
  {
    path: 'setting/cases/new-subject-type',
    component: NewCaseSubjectComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_SUBJECT_TYPE,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.SUBJECT_TYPE_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/cases/edit-subject-type/:id',
    component: EditCaseSubjectComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_SUBJECT_TYPE,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.SUBJECT_TYPE_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/cases/referral-sources-list',
    component: ReferralSourcesListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.REFERRAL_SOURCE_LIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },
  {
    path: 'setting/cases/new-referral-source',
    component: NewReferralSourceComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_REFERRAL_SOURCE,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.REFERRAL_SOURCE_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/cases/edit-referral-source/:id',
    component: EditReferralSourceComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_REFERRAL_SOURCE,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.REFERRAL_SOURCE_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/global/custom-field-list',
    component: CustomFieldsListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.CUSTOM_FIELD_LIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },
  {
    path: 'setting/global/new-custom-field',
    component: NewCustomFieldComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      name: SettingsBreadCrumb.NEW_CUSTOM_FIELD,
      parents: [SettingsBreadCrumb.SETTINGS, SettingsBreadCrumb.CUSTOM_FIELD_LIST],
    },
  },

  {
    path: 'setting/global/edit-custom-field/:id',
    component: EditCustomFieldComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_CUSTOM_FIELD,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.CUSTOM_FIELD_LIST,
        ],
      },
    },
  },

  { path: 'setting/global/addons-list', component: AddonsListComponent },
  {
    path: 'setting/global/upload-logo-edit',
    component: UploadLogoEditComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.UPLOAD_LOGO_EDIT,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },
  {
    path: 'setting/global/document-template-list',
    component: DocumentTemplateListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.DOCUMENT_TEMPLATE_lIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },
  {
    path: 'setting/global/new-document-template',
    component: NewDocumentTemplateComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_DOCUMENT_TEMPLATE,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.DOCUMENT_TEMPLATE_lIST,
        ],
      },
    },
  },
  {
    path: 'setting/global/edit-document-template/:id',
    component: EditDocumentTemplateComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_DOCUMENT_TEMPLATE,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.DOCUMENT_TEMPLATE_lIST,
        ],
      },
    },
  },
  {
    path: 'setting/global/company-location-list',
    component: CompanyLocationListComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.COMPANY_LOCATION_LIST,
        parents: [SettingsBreadCrumb.SETTINGS],
      },
    },
  },
  {
    path: 'setting/global/new-company-location',
    component: NewCompanyLocationComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.NEW_COMPANY_LOCATION,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.COMPANY_LOCATION_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/global/edit-company-location/:id',
    component: EditCompanyLocationComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_COMPANY_LOCATION,
        parents: [
          SettingsBreadCrumb.SETTINGS,
          SettingsBreadCrumb.COMPANY_LOCATION_LIST,
        ],
      },
    },
  },
  {
    path: 'setting/finance/general-finance-setting',
    component: GeneralFinanceSettingComponent,
  },
  {
    path: 'setting/finance/invoice-and-expense-list',
    component: InvoiceAndExpenseListComponent,
  },
  {
    path: 'setting/finance/new-item',
    component: NewInvoiceAndExpenseItemComponent,
  },
  { path: 'setting/finance/item-codes', component: ItemCodesListComponent },
  { path: 'setting/finance/new-item-code', component: NewItemCodeComponent },
  {
    path: 'setting/finance/payment-methods',
    component: PaymentMethodsListComponent,
  },
  {
    path: 'setting/finance/new-payment-method',
    component: NewPaymentMethodComponent,
  },
  { path: 'setting/finance/tax-rates', component: TaxRateListComponent },
  { path: 'setting/finance/new-tax-rate', component: NewTaxRateComponent },
  {
    path: 'setting/finance/tracking-categories',
    component: TrackingCategoriesListComponent,
  },
  {
    path: 'setting/finance/new-tracking-category',
    component: NewTrackingCategoryComponent,
  },

  {
    path: 'setting/dashboard-tab-presets',
    component: DashboardTabPresetListComponent,
  },

  { path: 'expenses/new-expense-entry', component: NewExpenseEntryComponent },
  { path: 'expenses/expense-entries', component: ExpenseEntriesComponent },
  {
    path: 'expenses/expense-by-staff',
    component: ExpenseEntriesStaffComponent,
  },
  { path: 'expenses/payment-slips', component: PaymentSipsComponent },
  {
    path: 'expenses/payment-slips/show/:id',
    component: PaymentSlipDetailComponent,
    canActivate: [AuthenticatedUserGuard],
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard],
      },
      {
        path: 'overview',
        component: PaymentSlipOverviewComponent,
        canActivate: [AuthenticatedUserGuard],
      },
      {
        path: 'expenses-entries',
        component: PaymentSlipEntriesComponent,
        canActivate: [AuthenticatedUserGuard],
      },
      { path: 'history', component: PaymentSlipHistoryComponent },
    ],
    runGuardsAndResolvers: 'always',
  },
  {
    path: 'setting/role/edit/:id',
    component: EditUserRoleComponent,
    pathMatch: 'full',
    canActivate: [AuthenticatedUserGuard],
    data: {
      breadcrumbs: {
        name: SettingsBreadCrumb.EDIT_USER_ROLE,
        parents: [SettingsBreadCrumb.SETTINGS, SettingsBreadCrumb.USER_ROLES],
      },
    },
  },

  // client-location routes start
  {
    path: 'client/:id/location/add',
    component: AddLocationComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.ViewClients],
      breadcrumbs: {
        name: ClientBreadCrumb.NEW_LOCATION,
        parents: [
          ClientBreadCrumb.CLIENT,
          ClientBreadCrumb.CLIENT_DETAILS,
          ClientBreadCrumb.CLIENT_LOCATIONS,
        ],
      },
    },
  },
  {
    path: 'client/location/edit/:locationId',
    component: EditLocationComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.ViewClients],
      breadcrumbs: {
        name: ClientBreadCrumb.EDIT_LOCATION,
        parents: [
          ClientBreadCrumb.CLIENT,
          ClientBreadCrumb.CLIENT_DETAILS,
          ClientBreadCrumb.CLIENT_LOCATIONS,
          ClientBreadCrumb.LOCATION_DETAILS,
        ],
      },
    },
  },
  {
    path: 'client/location/:locationId',
    component: LocationDetailComponent,
    canActivate: [AuthenticatedUserGuard],
    children: [
      {
        path: '',
        redirectTo: 'overview',
        pathMatch: 'full',
        canActivate: [AuthenticatedUserGuard],
      },
      {
        path: 'overview',
        component: ClientLocationOverviewComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: ClientBreadCrumb.LOCATION_OVERVIEW } },
      },
      {
        path: 'contact',
        component: ClientLocationContactComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: ClientBreadCrumb.LOCATION_CONTACTS } },
      },
      {
        path: 'notes',
        component: ClientLocationNotesComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: ClientBreadCrumb.LOCATION_NOTES } },
      },
      {
        path: 'history',
        component: ClientLocationHistoryComponent,
        canActivate: [AuthenticatedUserGuard],
        data: { breadcrumbs: { name: ClientBreadCrumb.LOCATION_HISTORY } },
      },
    ],
    data: {
      permissions: [Permission.ViewClients],
      breadcrumbs: {
        name: ClientBreadCrumb.LOCATION_DETAILS,
        parents: [
          ClientBreadCrumb.CLIENT,
          ClientBreadCrumb.CLIENT_DETAILS,
          ClientBreadCrumb.CLIENT_LOCATIONS,
          ClientBreadCrumb.LOCATION_DETAILS,
        ],
      },
    },
  },
  {
    path: 'client/location/:locationId/notes/add',
    component: AddClientLocationNotesComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.ViewClients],
      breadcrumbs: {
        name: ClientBreadCrumb.NEW_NOTE,
        parents: [
          ClientBreadCrumb.CLIENT,
          ClientBreadCrumb.CLIENT_DETAILS,
          ClientBreadCrumb.CLIENT_LOCATIONS,
          ClientBreadCrumb.LOCATION_DETAILS,
          ClientBreadCrumb.LOCATION_NOTES,
        ],
      },
    },
  },
  {
    path: 'client/location/notes/edit/:noteId',
    component: EditClientLocationNotesComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.ViewClients],
      breadcrumbs: {
        name: ClientBreadCrumb.EDIT_NOTE,
        parents: [
          ClientBreadCrumb.CLIENT,
          ClientBreadCrumb.CLIENT_DETAILS,
          ClientBreadCrumb.CLIENT_LOCATIONS,
          ClientBreadCrumb.LOCATION_DETAILS,
          ClientBreadCrumb.LOCATION_NOTES,
        ],
      },
    },
  },
  // client location routes end

  // client notes routes start
  {
    path: 'client/:id/notes/add',
    component: ClientNewNoteComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.ViewClients],
      breadcrumbs: {
        name: ClientBreadCrumb.NEW_NOTE,
        parents: [
          ClientBreadCrumb.CLIENT,
          ClientBreadCrumb.CLIENT_DETAILS,
          ClientBreadCrumb.CLIENT_NOTES,
        ],
      },
    },
  },
  {
    path: 'client/notes/edit/:noteId',
    component: ClientEditNoteComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.ViewClients],
      breadcrumbs: {
        name: ClientBreadCrumb.EDIT_NOTE,
        parents: [
          ClientBreadCrumb.CLIENT,
          ClientBreadCrumb.CLIENT_DETAILS,
          ClientBreadCrumb.CLIENT_NOTES,
        ],
      },
    },
  },
  // client notes routes end

  // client contacts routes start
  {
    path: 'client/:id/contact/add',
    component: AddNewContactComponent,
    data: {
      permissions: [Permission.CreateClientContacts],
      breadcrumbs: {
        name: ClientBreadCrumb.NEW_CONTACT,
        parents: [
          ClientBreadCrumb.CLIENT,
          ClientBreadCrumb.CLIENT_DETAILS,
          ClientBreadCrumb.CLIENT_CONTACTS,
        ],
      },
    },
  },
  {
    path: 'client/contact/edit/:id',
    component: EditClientContactComponent,
    data: {
      permissions: [Permission.EditClientContacts],
      breadcrumbs: {
        name: ClientBreadCrumb.EDIT_CONTACT,
        parents: [
          ClientBreadCrumb.CLIENT,
          ClientBreadCrumb.CLIENT_DETAILS,
          ClientBreadCrumb.CLIENT_CONTACTS,
        ],
      },
    },
  },

  {
    path: 'client/contact-show/:contactId/notes/add',
    component: NewClientContactNoteComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.ViewClients],
      breadcrumbs: {
        name: ClientBreadCrumb.NEW_NOTE,
        parents: [
          ClientBreadCrumb.CLIENT,
          ClientBreadCrumb.CLIENT_DETAILS,
          ClientBreadCrumb.CLIENT_CONTACTS,
          ClientBreadCrumb.CONTACT_DETAILS,
          ClientBreadCrumb.CONTACT_NOTES,
        ],
      },
    },
  },
  {
    path: 'client/contact-show/notes/edit/:noteId',
    component: EditClientContactNoteComponent,
    canActivate: [AuthenticatedUserGuard],
    data: {
      permissions: [Permission.ViewClients],
      breadcrumbs: {
        name: ClientBreadCrumb.EDIT_NOTE,
        parents: [
          ClientBreadCrumb.CLIENT,
          ClientBreadCrumb.CLIENT_DETAILS,
          ClientBreadCrumb.CLIENT_CONTACTS,
          ClientBreadCrumb.CONTACT_DETAILS,
          ClientBreadCrumb.CONTACT_NOTES,
        ],
      },
    },
  },
  // client contacts routes end

  {
    path: 'error/403',
    component: Error403Component,
  },
  {
    path: 'error/404',
    component: Error404Component,
  },
  // {path:'setting/accessgroup/new-access-group',component:NewAccessGrpComponent},

  { path: 'client/:id/new-invoice-rule', component: NewInvoiceRuleComponent },
  { path: '**', redirectTo: '/error/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: false,
      onSameUrlNavigation: 'reload',
      enableTracing: false,
    }),
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
