import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HistoryFilterOptionDetails, HistorySearchOptionDetails } from 'src/app/helpers/constants/filter-and-search/history.filter&search.option.details';
import { HistoryFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/history.search&filter.options';
import { FilterInputModel, SearchByModel } from 'src/app/models/shared/filters.model';

@Component({
  selector: 'app-history-filter-and-search',
  templateUrl: './history-filter-and-search.component.html',
  styleUrls: ['./history-filter-and-search.component.scss']
})
export class HistoryFilterAndSearchComponent implements OnInit {


  filterBy: FilterInputModel[] = [];
  searchBy: SearchByModel[] = [];
  isFilterInVisible = true;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onApplyFilter = new EventEmitter();

  @Output()
  resetFilters = new EventEmitter();


  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();


  constructor() {
  }

  ngOnInit(): void {
    this.loadFilterOptions();
  }

  private loadFilterOptions(): void {
    this.filterBy = [];
    this.searchBy = [];

    // filterby
    HistoryFilterOptionDetails.forEach((key, value) =>
    this.filterBy.push({ name: key, id: `${value}`, group: undefined, isSelected: value === HistoryFilterOptions.None })
    );

    // search by
    HistorySearchOptionDetails.forEach((x, y) => {
      this.searchBy.push({
        id: y,
        name: x
      });
    });
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onResetFilters(): void {
    this.loadFilterOptions();
    this.resetFilters.emit();
  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }


}
