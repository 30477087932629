import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddCaseStatusModel } from '../models/case-status/addCaseStatusModel';
import { CaseStatusViewModel } from '../models/case-status/caseStatusViewModel';
import { EditCaseStatusModel } from '../models/case-status/editCaseStatusModel';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';

@Injectable({
  providedIn: 'root'
})
export class CasestatusService {


  constructor(private http: HttpClient) { }
  public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
    return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/casestatus/query`, dataTablesParameters, {});
  }

  public post(model: AddCaseStatusModel): Observable<string> {
    return this.http.post<string>(`${environment.apiBaseUrl}/casestatus/post`, model, {});
  }

  public get(id: number): Observable<CaseStatusViewModel> {
    return this.http.get<CaseStatusViewModel>(`${environment.apiBaseUrl}/casestatus/${id}`);
  }
  public getAll(): Observable<CaseStatusViewModel[]> {
    return this.http.get<CaseStatusViewModel[]>(`${environment.apiBaseUrl}/casestatus/get`);
  }
  public put(model: EditCaseStatusModel): Observable<CaseStatusViewModel> {
    return this.http.put<CaseStatusViewModel>(`${environment.apiBaseUrl}/casestatus/put`, model, {});
  }
  public delete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/casestatus/delete?ids=${ids.join('&ids=')}`);
  }
  public undelete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/casestatus/undeleted?ids=${ids.join('&ids=')}`);
  }


}
