import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { CreatePasswordRequestModel } from 'src/app/models/authenticate/create.password.request.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ToasterService } from 'src/app/services/toater.service';
import { Match } from 'src/app/validators';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.css']
})
export class CreatePasswordComponent implements OnInit {

  showDetails = true;
  password: string;
  password2: string;

  chklow = false;
  chkcaps = false;
  chknum = false;
  chklen = false;
  chkSpec = false;

  toggleEye: boolean;
  toggleEye2: boolean;

  model: CreatePasswordRequestModel = new CreatePasswordRequestModel();

  @BlockUI('blockui-frm-create-pwd') blockUI: NgBlockUI;
  form: FormGroup;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private toasterService: ToasterService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder
  ) {
    this.route.params.subscribe((params) => {
      this.model.key = params.key;
      this.model.secret = params.secret;
    });
  }


  ngOnInit(): void {
    this.form = this.formBuilder.group({
      password: ['', [Validators.required, Validators.minLength(8)]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: [
        Match('password', 'confirmPassword')
      ]
    });
  }



  // on form submit
  onSubmit(): void {
    // stop here if form is invalid.
    if (this.form.invalid || !this.isPasswordValid) {
      return;
    }

    this.model.password = this.form.controls.password.value;
    this.model.confirmPassword = this.form.controls.confirmPassword.value;

    this.blockUI.start();
    this.authenticationService.activate(this.model)
      .subscribe(
        () => {
          this.blockUI.stop();
          setTimeout(() => {
            this.router.navigate(['/account/login']);
          }, 100);
          setTimeout(() => {
            this.toasterService.success('Your account has been activated please log in to continue');
          }, 500);
        },
        error => {
          this.blockUI.stop();
          this.toasterService.ProcessErrorResponse(error);
        });
  }

  onKeyup(event): void {
    // Validate lowercase letters
    const lowerCaseLetters = /[a-z]/g;
    if (this.form.value.password.match(lowerCaseLetters)) {
      this.chklow = true;
    } else {
      this.chklow = false;
    }

    // trying shorthand didnt worked

    const upperCaseLetters = /[A-Z]/g;
    if (this.form.value.password.match(upperCaseLetters)) {
      this.chkcaps = true;
    } else {
      this.chkcaps = false;
    }

    const num = /[0-9]/g;
    if (this.form.value.password.match(num)) {
      this.chknum = true;
    } else {
      this.chknum = false;
    }

    // check len
    if (this.form.value.password.length >= 12){
      this.chklen = true;
    } else {
      this.chklen = false;
    }

    const specialChars = /[!@#\$%\^&\*\(\)]/g;
    if (this.form.value.password.match(specialChars)) {
      this.chkSpec = true;
    } else {
      this.chkSpec = false;
    }
  }

  get isPasswordValid(): boolean {
    return this.chklen && this.chknum && this.chklow && this.chkcaps && this.chkSpec;
  }

  changeEye(): void {
    this.toggleEye = !this.toggleEye;
  }

  changeEye2(): void {
    this.toggleEye2 = !this.toggleEye2;
  }

}
