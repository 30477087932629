import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CountryModel } from '../models/shared/country.model';
import { StateModel } from '../models/shared/state.model';

@Injectable({
    providedIn: 'root'
})
export class CountryService {
    constructor(private http: HttpClient) {
    }

    getCountries(): Observable<CountryModel[]> {
        return this.http.get<CountryModel[]>(`${environment.apiBaseUrl}/country`);
    }

    getStates(countryId: number): Observable<StateModel[]>{
        return this.http.get<StateModel[]>(`${environment.apiBaseUrl}/country/${countryId}/states`);
    }
    getCounty(stateId: number): Observable<StateModel[]>{
        return this.http.get<StateModel[]>(`${environment.apiBaseUrl}/country/${stateId}/county`);
    }
}
