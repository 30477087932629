<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Add Affiliates</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label for="selectaf"></label>
                <ng-select [items]="affiliateList" [multiple]="true" bindLabel="value" [selectableGroup]="true"
                    [closeOnSelect]="false" bindValue="id" name="selectaf" formControlName="affiliate" class="da-ngselect-max" [ngClass]="{ 'is-invalid': isSubmitted && form.controls.affiliate.errors }">
                    <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                        <input id="item-{{index}}" type="checkbox" [ngModel]="item$.selected"
                            [ngModelOptions]="{ standalone : true }" /> {{item.value}}
                    </ng-template>
                </ng-select>
                <div *ngIf="isSubmitted && form.controls.affiliate.errors" class="invalid-feedback">
                    <div *ngIf="form.controls.affiliate.errors.required" style="font-size: larger;">
                        Affiliate is required
                    </div>
                </div>
        </div>
        <div class="modal-footer border-0">
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cancel click')">Cancel</button>
            <button type="submit" class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2">Add Affiliates </button>
        </div>
    </form>
</div>