<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Assign {{selectedStaffCount}} selected user(s) to :</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group row">
            <label class="col-sm-4 col-form-label" for="manager">Manager
                <sup class="clsStric">*</sup></label>
            <div class="col-sm-8">
                <select class="dropdown-toggle btn btn-lg"
                    formControlName="manager" [ngClass]="{ 'is-invalid': isSubmitted && form.controls.manager.errors }">
                    <option value="">Select Manager</option>
                    <option value="{{item.id}}" *ngFor="let item of managersSelectListItem;">
                        {{item.value}}
                    </option>
                </select>
                <div *ngIf="isSubmitted && form.controls.manager.errors" class="invalid-feedback">
                    <div *ngIf="form.controls.manager.errors.required" style="font-size: larger;">
                        Manager is required
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-footer border-0">
            <button type="button" class="btn btn-outline-dark"
                (click)="activeModal.dismiss('Cancel click')">Cancel</button>
            <button type="submit" class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2">Assign Manager </button>
        </div>
    </form>
</div>