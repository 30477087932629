export enum CompanyLocationFilterOptions {
    None = 1,
    Deleted = 2
}


export enum CompanyLocationSearchOptions {
    Name = 1,
    Address = 2,
    CaseManager = 3,
    SalesPerson = 4,
    AccountCode = 5,
    Tag = 6
}
