import { AccessGroupFilterOptions } from '../../enums/filters-and-searchs/access.group.filter.options';
import { UserRoleFilterOptions } from '../../enums/filters-and-searchs/user.roles.filter.options';

export const RolesFilterOptionsDetail = new Map<number, string>([
    [UserRoleFilterOptions.None, 'None'],
    [UserRoleFilterOptions.DeletedRoles, 'Deleted Roles'],
]);
export const AccessGroupFilterOptionsDetail = new Map<number, string>([
    [AccessGroupFilterOptions.None, 'None'],
    [AccessGroupFilterOptions.DeletedAccessGroups, 'Deleted Access Groups'],
]);
