import { ValueTransformer } from '@angular/compiler/src/util';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-staff-permission',
  templateUrl: './staff-permission.component.html',
  styleUrls: ['./staff-permission.component.css']
})
export class StaffPermissionComponent implements OnInit {

  permissionsToDisplay = [];
  @BlockUI() blockUI: NgBlockUI;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private staffService: StaffService,
    private toasterService: ToasterService) {
    this.route.parent.params.subscribe(params => this.loadUserPermisisons(params.id));
  }

  ngOnInit(): void {
  }

  private loadUserPermisisons(id: string): void {
    this.blockUI.start();
    this.staffService.getStaffPermissions(id).subscribe((data) => {
      Object.entries(data).forEach(
        ([key, values], index) => {
          this.permissionsToDisplay.push({
            id: index,
            permissionCategory: key,
            permissions: (values as Array<any>).map(x => ({
              permission: x.permission,
              hasPermission: x.hasPermission,
              name: x.name
            }))
          });
        });
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate(['/staff']);
      this.blockUI.stop();
    }, () => {
    });

  }

  private gerPermmissionsArray(values: Array<any>): Array<any> {
    return values.map(x => ({
      permission: x.permission,
      hasPermisison: x.hasPermisison,
      name: x.name
    }));

  }

}
