import { Component, OnInit } from '@angular/core';

import { ActivatedRoute } from '@angular/router';
import { NoteFor } from 'src/app/helpers/enums/notes/noteFor';

@Component({
  selector: 'app-staff-notes',
  templateUrl: './staff-notes.component.html',
  styleUrls: ['./staff-notes.component.css']
})
export class StaffDetailNoteComponent implements OnInit {

  moduleId: number;
  staffId: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe(params => this.staffId = params.id);
    this.moduleId = NoteFor.Staff;
  }
}
