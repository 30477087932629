import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { CustomFieldCaseCategories } from 'src/app/helpers/enums/settings/customField/CustomFieldCaseCategories';
import { CustomFieldModel } from 'src/app/models/custom-fields/CustomFieldModel';
import { CustomfieldService } from 'src/app/services/customfield.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-template-variable',
  templateUrl: './template-variable.component.html',
  styleUrls: ['./template-variable.component.css']
})
export class TemplateVariableComponent implements OnInit {
  @BlockUI('container-blockui-location') blockUI: NgBlockUI;
  @Input() public isForPopup: boolean;
  @Input() breadCrumbInputs: { key: string, value: string[] }[];
  @Output() public saveClick = new EventEmitter();

  element: CustomFieldModel[];
  variableData: {key: string, value: string}[] = [];

  constructor(private customFieldService: CustomfieldService,
              private toasterService: ToasterService,
              private ngbActiveModal: NgbActiveModal, ) {
      this.element = [];
    }

  ngOnInit(): void {
    this.loadData();
  }

  loadData(): void {
    forkJoin([this.customFieldService.getByUserForId(CustomFieldCaseCategories.Case),
    this.customFieldService.getByUserForId(CustomFieldCaseCategories.Subject),
    ]).subscribe(
      ([customFieldCase, customFieldSubject]) => {

        this.element = [...customFieldCase, ...customFieldSubject];
        this.variableData = this.customFieldService.getVariables(this.element);
        this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(
          error,
          'Something went wrong!'
        );
        this.blockUI.stop();
      }
    );
  }

  get activeModal(): any {
    return this.ngbActiveModal;
  }

}
