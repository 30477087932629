import { Component, OnInit, ViewChild, Renderer2, OnDestroy, AfterViewInit } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

// for datatable
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { ClientService } from 'src/app/services/client.service';
import { ClientContactService } from 'src/app/services/client.contact.service';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';
import { ClientContactDtColumnName } from 'src/app/helpers/constants/client-contacts/client.contact.column.name';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-contacts',
    templateUrl: './contacts.component.html',
    styleUrls: ['./contacts.component.css']
})
export class ContactsComponent implements OnInit, AfterViewInit, OnDestroy {

    constructor(private clientContactService: ClientContactService,
                private clientService: ClientService,
                private tosterService: ToasterService,
                private router: Router,
                private route: ActivatedRoute) {

        this.route.parent.params.subscribe(param => this.clientId = Number(param.id));
    }

    // for angular datatable
    @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
    @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
    dtOptions: DataTables.Settings = {};
    dtInstance: DataTables.Api;
    rowIndex = 0;
    dtRendered = true;
    visibleColumns = [];
    isDtVisible = false;
    isDtInitialized = false;
    permissionType = Permission;
    hasPermissionType = HasPermission;
    clientSubscription: Subscription;
    clientId: number;
    clientName: string;

    users: any = [];
    isBulkActionDisabled = true;

    ngOnInit(): void {
        this.initDataTable();
        this.clientSubscription = this.clientService.clientListDetail$.subscribe((data) => {
          if (data == null || data === undefined) {
            return;
          }
          this.clientName = data.name;
        });
    }

    ngAfterViewInit(): void {
        this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
            this.dtInstance = dtInstance;
        });
    }

    private initDataTable(): void {
        const self = this;
        this.dtOptions = {
            dom: '<"top">rt<"bottom"lip><"clear">',
            serverSide: true,
            destroy: true,
            processing: true,
            language: {
                loadingRecords: '&nbsp;',
                processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
                paginate: {
                    first: '<i class="fa fa-angle-double-left">',
                    last: '<i class="fa fa-angle-double-right">',
                    previous: '<i class="fa fa-angle-left">',
                    next: '<i class="fa fa-angle-right">'
                }
            },

            displayStart: 0,
            paging: true,
            pagingType: 'full_numbers',
            pageLength: environment.paging.defaultPageSize,
            lengthMenu: environment.paging.lengthMenu,
            order: [[1, 'asc']],
            autoWidth: false,
            ajax: (dataTablesParameters: any, callback) => {
                dataTablesParameters.clientId = self.clientId;
                self.blockUI.start();
                self.clientContactService.queryForDetails(dataTablesParameters)
                    .subscribe(resp => {
                        callback({
                            recordsTotal: resp.recordsTotal,
                            recordsFiltered: resp.recordsFiltered,
                            data: resp.data
                        });
                        self.blockUI.stop();
                    }, error => {
                        self.tosterService.ProcessErrorResponse(error);
                        self.blockUI.stop();
                    });
            },
            columns: [
                {
                    data: ClientContactDtColumnName.clientContactName,
                    title: 'Name',
                    render: (data, type, row) => {
                        const src = row.profileImgUrl ?? '/assets/img/avatars/user.svg';
                        const html = HasPermission.validate([Permission.ViewClientContacts]) ?
                            ` <a class='link m-r-10' action-type='detail'><img src="${src}"  onerror="" class='${row.profileImgUrl ? 'shimmer' : ''} avatar img-fluid rounded-circle mr-1'> ${row.name}</a>` : `<span>${row.name}</span>`;
                        return html;
                    }
                },
                {
                    data: ClientContactDtColumnName.title,

                    title: 'Title'
                },
                {
                    data: ClientContactDtColumnName.location,

                    title: 'Location'
                },
                {
                    data: ClientContactDtColumnName.email,
                    
                    title: 'Email'
                },
                {
                  data: ClientContactDtColumnName.lastCase,

                  title: 'Last Case'
                },
                {
                    data: null,
                    title: 'Action ',
                    orderable: false,
                    render: (data, type, row) => {
                        const editHtml = HasPermission.validate([Permission.EditClientContacts]) ?
                            `<div class='fa-edit'>
                        <button  class='btn del-btn btn-lg btn-table'>
                        <i  action-type="edit" class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
                        </button>` : '';

                        const deleteHtml = HasPermission.validate([Permission.DeleteClientContacts]) ?
                            `<button  class='btn del-btn btn-lg btn-table'>
                        <i action-type="delete"
                            class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
                        </button>
                        </div>` : '';

                        return `${editHtml} ${deleteHtml}`;
                    }
                },
            ],
            rowCallback: (row, data: any) => {
                const _row = $(row);
                _row.find('[action-type="detail"]')
                    .off('click')
                    .on('click', () => {
                        this.linkDetail(data.id);
                    });
                _row.find('[action-type="edit"]')
                    .off('click')
                    .on('click', () => {
                        this.edit(data.id, data.clientId, data.clientName);
                    });
                _row.find('[action-type="delete"]')
                    .off('click')
                    .on('click', () => {
                        self.delete([data.id]);
                    });
            },
            // Use this attribute to enable the responsive extension
            responsive: true,
        };
    }

    reload(): void {
        this.dtInstance.ajax.reload();
    }

    delete(userIds: string[]): void {
        if (!confirm('Are you sure you want to delete selected contact?')) {
            return;
        }
        this.blockUI.start();
        this.clientContactService.delete(userIds).subscribe(() => {
            this.reload();
            this.tosterService.success('Contact deleted successfully.');
            this.blockUI.stop();
        }, error => {
            this.tosterService.ProcessErrorResponse(error);
            this.blockUI.stop();
        });
    }

    linkDetail(id: string): void {
        this.router.navigate(['/client/contact-show', id, 'overview']);
    }

    edit(id: string, clientId, clientName): void {
        this.clientContactService.setReturnUrl(this.router.url);
        this.clientService.clientDetailSubject$.next({ id: clientId, name: clientName });
        this.router.navigate(['/client/contact/edit/', id]);
    }

    onNewContactClick(): void {
      this.clientService.clientDetailSubject$.next({id: this.clientId, name: this.clientName });
      this.router.navigate([`client/${this.clientId}/contact/add`]);
    }

    ngOnDestroy(): void {
      this.clientSubscription.unsubscribe();
    }
}

