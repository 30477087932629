import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { debug } from 'console';
import { Subscription } from 'rxjs';
import { StaffLicenseService } from 'src/app/services/staff.license.service';

@Component({
  selector: 'app-staff-license-detail',
  templateUrl: './staff-license-detail.component.html',
  styleUrls: ['./staff-license-detail.component.css']
})
export class StaffLicenseDetailComponent implements OnInit, OnDestroy {

  activeTab = 1;
  tabDetails = [];
  licenseName: string;
  currentPath: string;
  staffLicenseSubscription: Subscription;
  loadBreadCrumbs = false;
  breadCrumbInputs: { key: string, value: string[] }[] = [];


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private staffLicenseService: StaffLicenseService) {
  }



  ngOnInit(): void {
    this.tabDetails = [
      { id: 1, title: 'Overview', path: 'overview' },
      { id: 2, title: 'History', path: 'history' },
    ];
    this.staffLicenseSubscription = this.staffLicenseService.staffDetai$.subscribe((data) => {
      if (data == null) {
        return;
      }
      this.licenseName = `${data.licenseName} (${data.staffName})`;
      // this.breadCrumbInputs.push(...[{ key: 'id', value: [data.staffId, data.staffName] },
      // { key: 'licenseId', value: [data.licenseId.toString(), data.licenseName] }]);
      // this.loadBreadCrumbs = true;
      this.breadCrumbInputs = [
        { key: 'id', value: [data.staffId, data.staffName] },
        { key: 'licenseId', value: [data.licenseId.toString(), data.licenseName] }
      ];
      this.loadBreadCrumbs = true;

    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.activeTab = changeEvent.nextId;
    // this.loadBreadCrumbs = false;
  }

  // sticky money
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (document.getElementById('stickyoney')) {
      if (document.body.scrollTop > 132 ||
        document.documentElement.scrollTop > 132) {
        document.getElementById('stickyoney').classList.add('stick_me');
      } else {
        document.getElementById('stickyoney').classList.remove('stick_me');
      }
    }
  }
  componentAdded($evt): void {
    this.currentPath = $evt.route.snapshot.routeConfig.path;
  }

  ngOnDestroy(): void {
    this.staffLicenseSubscription.unsubscribe();
  }

}
