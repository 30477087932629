import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AddDocumentTemplateModel } from '../models/document-templates/addDocumentTemplateModel';
import { DocumentTemplateViewModel } from '../models/document-templates/documentTemplateViewModel';
import { EditDocumentTemplateModel } from '../models/document-templates/editDocumentTemplateModel';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';

@Injectable({
  providedIn: 'root'
})
export class DocumentTemplateService {
  constructor(private http: HttpClient) { }

  public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
    return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/documenttemplate/query`, dataTablesParameters, {});
  }

  public post(model: AddDocumentTemplateModel): Observable<string> {
    const formData = new FormData();
    formData.append('id', JSON.stringify(model.id));
    formData.append('name', model.name);
    if (model.accessGroupId){
      formData.append('accessGroupId', JSON.stringify(model.accessGroupId));
      }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < model.caseTypeIds.length; i++) {
      formData.append('caseTypeIds[]', JSON.stringify(model.caseTypeIds[i]));
  }
    formData.append('isUseMergeFields', JSON.stringify(model.isUseMergeFields));
    formData.append('description', model.description);
    if (model.templateFile != null && model.templateFile !== undefined) {
        formData.append('templateFile', model.templateFile, model.templateFile.name);
    }
    return this.http.post<string>(`${environment.apiBaseUrl}/documenttemplate/post`, formData, {});
  }

  public get(id: number): Observable<DocumentTemplateViewModel> {
    return this.http.get<DocumentTemplateViewModel>(`${environment.apiBaseUrl}/documenttemplate/${id}`);
  }
  public getAll(): Observable<DocumentTemplateViewModel[]> {
    return this.http.get<DocumentTemplateViewModel[]>(`${environment.apiBaseUrl}/documenttemplate/get`);
  }
  public put(model: EditDocumentTemplateModel): Observable<DocumentTemplateViewModel> {
    const formData = new FormData();
    formData.append('id', JSON.stringify(model.id));
    formData.append('name', model.name);
    if (model.accessGroupId){
    formData.append('accessGroupId', JSON.stringify(model.accessGroupId));
    }
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < model.caseTypeIds.length; i++) {
      formData.append('caseTypeIds[]', JSON.stringify(model.caseTypeIds[i]));
  }
    formData.append('isUseMergeFields', JSON.stringify(model.isUseMergeFields));
    formData.append('isFileRemoved', JSON.stringify(model.isFileRemoved));

    formData.append('description', model.description);
    if (model.templateFile != null && model.templateFile !== undefined) {
        formData.append('templateFile', model.templateFile, model.templateFile.name);
    }
    return this.http.put<DocumentTemplateViewModel>(`${environment.apiBaseUrl}/documenttemplate/put`, formData, {});
  }
  public delete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/documenttemplate/delete?ids=${ids.join('&ids=')}`);
  }
  public undelete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/documenttemplate/undeleted?ids=${ids.join('&ids=')}`);
  }
  public downloadAttachment(id: number): Observable<any> {
    return this.http.get(`${environment.apiBaseUrl}/documenttemplate/downloadAttachment/${id}`, { responseType: 'blob' });
}
}
