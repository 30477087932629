export class StaffLicenseEditModel {
    public id: number;
    public name: string;
    public licenseNumber: string;
    public country: string;
    public state: string;
    public expiresOn: string;
    public notes: string;
    public staffId: string;
    public fileName: string;
    public isFileRemoved: boolean;
}
