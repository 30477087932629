import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import * as moment from 'moment';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AppUtils } from 'src/app/helpers';
import { NoteListModel } from 'src/app/models/note/note.list.model';
import { NoteService } from 'src/app/services/note.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-pinned-notes-list-view',
  templateUrl: './pinned-notes-list-view.component.html',
  styleUrls: ['./pinned-notes-list-view.component.css']
})
export class PinnedNotesListViewComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  @Input() pinnedNotes: NoteListModel[];

  constructor(private noteService: NoteService,
              private toasterService: ToasterService,
              private router: Router,
              private route: ActivatedRoute,
              private appUtils: AppUtils) { }


  ngOnInit(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: false,
      processing: false,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },
      autoWidth: true,
      displayStart: 0,
      paging: false,
      pagingType: 'full_numbers',
      data: this.pinnedNotes,
      columns: [
        {
          data: 'date',
          title: 'Date',
          render: (data, type, row) => {
            return `<div>${moment(row.date).format('MM/DD/YY')}</div>
                      <div>${row.author ?? ''}</div>
                      <div>${row.accessGroupName}</div>`;
          }
        },
        {
          data: 'noteText',
          title: 'Note'
        },
        {
          data: 'fileName',
          title: 'Attachment',
          render: (data, type, row) => {
            if (!row.fileName) {
              return '';
            }

            const showIcon = this.appUtils.genrateIconExtension(row.fileName);
            return row.fileUrl ?
              `<a class='link m-r-10 link-line-wrap '  action-type='download-attachment'  container='body' title='${row.fileName}'> ${showIcon} ${row.fileName}</a> ` :
              row.fileName;
          }
        },
        {
          data: 'name',
          title: 'Action ',
          render: (data, type, row) => {
            return `<div class='fa-edit'>
                <button  class='btn del-btn btn-lg btn-table'  >
                <i action-type="edit-note" class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
                </button>
                </div>
                `;
          }
        },
      ],
      rowCallback: (row, data: any) => {
        const _row = $(row);
        _row.find('[action-type="download-attachment"]')
          .off('click')
          .on('click', () => {
            self.downloadAttachment(data.fileName, data.id);
          });
        _row.find('[action-type="edit-note"]')
          .off('click')
          .on('click', () => {
            self.editNote(data.id);
          });
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
    };
  }

  editNote(id): void {
    this.noteService.setReturnUrl(this.router.url);
    this.router.navigate(['../notes/edit/', id], { relativeTo: this.route.parent });
  }

  downloadAttachment(fileName, noteId): void {
    this.blockUI.start();
    this.noteService.downloadAttachment(noteId).subscribe((blob: File) => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = fileName;
      link.style.display = 'none';
      link.href = blobUrl;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(blobUrl);
          link.remove();
      }, 100);
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }
}
