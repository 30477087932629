import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ComponentDestoryHelper } from 'src/app/helpers/component-destory.helper';
import { AddorEditUserSecuritySettingModel } from 'src/app/models/user-security-setting/addorEditUserSecuritySettingModel';
import { UserSecuritySettingViewModel } from 'src/app/models/user-security-setting/userSecuritySettingViewModel';
import { ToasterService } from 'src/app/services/toater.service';
import { UserSecuritySettingService } from 'src/app/services/user.security.setting.service';

@Component({
  selector: 'app-user-security-setting',
  templateUrl: './user-security-setting.component.html',
  styleUrls: ['./user-security-setting.component.css']
})
export class UserSecuritySettingComponent extends ComponentDestoryHelper implements OnInit, OnDestroy {
  @BlockUI('container-blockui-new-user-security-setting')blockUI: NgBlockUI;
  form: FormGroup;
  submitted = false;
  model: AddorEditUserSecuritySettingModel;
  constructor(private formBuilder: FormBuilder, private toasterService: ToasterService,
              private userSecuritySettingService: UserSecuritySettingService,
              private router: Router) {
                super();
                this.model = new AddorEditUserSecuritySettingModel();
                this.model.id = 0;
               }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      minimum_length: new FormControl(0, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]),
      user_password_expiry: new FormControl(0, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]),
      enable_multi_factor_auth: new FormControl(false, Validators.required)
    });
    this.loadDefault();
  }

loadDefault(): void{
  this.blockUI.start();
  const subscription = this.userSecuritySettingService.get().subscribe((data: UserSecuritySettingViewModel) => {
    if (data != null)
    {
      this.model.id = data.id;
      this.form.controls.minimum_length.setValue(data.minimumPasswordLength);
      this.form.controls.user_password_expiry.setValue(data.passwordExpirationDays);
      this.form.controls.enable_multi_factor_auth.setValue(data.isMultiFactorAuthentication);
    }
  }, (error: any) => {
    this.toasterService.ProcessErrorResponse(error);
  }, () => {
    this.blockUI.stop();
  });
  this.subscriptions.push(subscription);
}

onSubmit(): void {
  this.submitted = true;
  if (this.form.invalid) {
    this.toasterService.error('Please fill all the required fields!');
    return;
  }
  this.model.minimumPasswordLength = this.form.controls.minimum_length.value;
  this.model.isMultiFactorAuthentication = this.form.controls.enable_multi_factor_auth.value;
  this.model.passwordExpirationDays = this.form.controls.user_password_expiry.value;

  this.blockUI.start();
  const subscription = this.userSecuritySettingService.post(this.model).subscribe(resposne => {
    setTimeout(() => {
      this.onCancel();
    }, 10);
    setTimeout(() => {
      this.toasterService.success('User security updated successfully');
    }, 300);
    this.blockUI.stop();
  },
    error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  this.subscriptions.push(subscription);
}

onCancel(): void {
  this.router.navigate(['/setting']);
}
ngOnDestroy(): void {
  this.ngAfterOnDestroy();
}
}
