import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ClientAddModel } from 'src/app/models/client/client.model';
import { LocationModel } from 'src/app/models/Location/location.model';
import { ClientService } from 'src/app/services/client.service';
import { numberFormat } from 'highcharts';


import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';


import { ModalService } from 'src/app/services/modal.service';


@Component({
  selector: 'app-payment-slip-detail',
  templateUrl: './payment-slip-detail.component.html',
  styleUrls: ['./payment-slip-detail.component.css']
})
export class PaymentSlipDetailComponent implements OnInit {


  constructor(private router: Router, private clientService: ClientService,
    private route: ActivatedRoute, private modalService: ModalService) {
    this.model = new ClientAddModel();
    this.route.params.subscribe(params => this.model.id = params.id);
    this.primaryLocation = new LocationModel();
  }

  model: ClientAddModel;
  primaryLocation: LocationModel;
  selectedTags = [];
  active = 1;
  tabDetails = [];
  currentPath: string;
  activeTab = 1;
  clientSubscription: Subscription;
  clientName: string;

  name = 'Angular';

  ngOnInit(): void {
    this.tabDetails = [
      { id: 1, title: 'Overview', path: 'overview' },
      { id: 2, title: 'Expense Entries', path: 'expenses-entries' },
      { id: 3, title: 'History', path: 'history' }
    ];
    this.clientSubscription = this.clientService.clientListDetail$.subscribe((data) => {
      if (data) {
        this.clientName == data.name;
      }
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.activeTab = changeEvent.nextId;
  }

  componentAdded($evt): void {
    this.currentPath = $evt.route.snapshot.routeConfig.path;
  }

  // sticky money
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (document.getElementById('stickyoney')) {
      if (document.body.scrollTop > 132 ||
        document.documentElement.scrollTop > 132) {
        document.getElementById('stickyoney').classList.add('stick_me');
      } else {
        document.getElementById('stickyoney').classList.remove('stick_me');
      }
    }
  }

  ngOnDestroy(): void {
    this.clientSubscription.unsubscribe();
  }

  openPaymentSlipEnterPayment(): void {
    this.modalService.openPaymentSlipEnterPayment();
  }
}
