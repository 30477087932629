<main class="content py-0">
  <div class="container-fluid p-0">
      <div class="row">
          <div class="col-md-12 pt-7">
              <div class="w-100 pt-4">
                  <div class="row">
                      <div class="col-md-12">
                          <app-breadcrumb></app-breadcrumb>
                      </div>
                  </div>

                  <div class="row mt-4 mb-4">
                      <div class="col-auto d-none d-sm-block">
                          <h3 class="page-title"><strong>Edit Case Type</strong> </h3>
                      </div>

                      <!-- <div class="col-md-auto fa-client-title">
                          1213
                      </div> -->
                  </div>


                  <div class="row mx-0" *blockUI="'container-blockui-edit-case-type'">
                      <div class="col-12 px-0 mb-5">
                          <form class="new-case-form" name="form"  [formGroup]="form" >
                              <div class="row">
                                  <div class="col-md-12">
                                      <div class="card" >
                                        
                                          <div class="card-body">
                                            
                                            <div class="card-text">

                                              <div class="row">

                                                  <div class="col-md-12">

                                                      <div class="row">
                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="name">Name<sup class="clsStric">*</sup></label>
                                                                  
                                                                  <input type="text" formControlName="name" class="form-control mb-3" id="name" name="name" autocomplete="off">
                                                              </div>
                                                          </div>

                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="tag">Tag<sup class="clsStric">*</sup></label>
                                                                  
                                                                  <input type="text" formControlName="tag" (keypress)="tagKeyPress($event)" class="form-control mb-3" id="tag" name="tag" autocomplete="off">
                                                              </div>
                                                          </div>
                                                      </div>

                                                      <div class="row">
                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="name">Description</label>
                                                                  
                                                                  <input type="text" formControlName="description" class="form-control mb-3" id="description" name="description" autocomplete="off">
                                                              </div>
                                                          </div>

                                                      </div>
                                                      <div class="row" formArrayName="references">
                                                          <div class="col-md-6" [formGroupName]="i" *ngFor="let ref of referencesToFormArray.controls; ; let i = index">
                                                              <div class="form-group has-search">
                                                                  <label for="ref_label_{{i}}">Reference Label {{i + 1}}</label>
                                                                  
                                                                  <input type="text" formControlName="value" class="form-control mb-3" id="name_ref_{{i}}" name="name_ref_{{i}}" autocomplete="off">

                                                                  <div class="row">
                                                                      <div class="col-md-12">
                                                                          <input type="checkbox" formControlName="isCopyToInvoice" id="isCopyToInvoice_{{i}}">
                                                                          <label for="reflabel1"> &nbsp;Copy to Invoice</label>
                                                                      </div>
                                                                      <div class="col-md-12">
                                                                          <input type="checkbox" formControlName="isReferenceType" id="isReferenceType_{{i}}">
                                                                          <label for="reflabel2"> &nbsp; Make this reference number required</label>
                                                                      </div>
                                                                  </div>

                                                              </div>
                                                          </div>

                                                          
                                                      </div>



                                                      <div class="row">
                                                          
                                                          <div class="col-md-12">
                                                              
                                                              <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-3">
                                                                  <ngb-panel id="custom-panel-3">
                                                                    <ng-template ngbPanelHeader let-opened="opened">
                                                                      <div class="d-flex align-items-center justify-content-between">
                                                                        <h5 class="m-0 font-weight-bold">Case Components</h5>
                                                                        <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                              
                                                                      </div>
                                                                    </ng-template>
                                                                    <ng-template ngbPanelContent>
                                                                        <div class="row">
                                                                            <div class="col-md-12" >
                                                                                <div class="mb-1 pt-2 pb-2">
                                                                                    Case Flags
                                                                                </div>
                                                                                <div class="row formgroup pt-2" formArrayName="caseFlags">
                                                                                    <div class="col-md-3 mb-1 mt-1" [formGroupName]="i" *ngFor="let caseFlag of caseFlagsToFormArray.controls; ; let i = index">
                                                                                       
                                                                                        <label for="caseFlag_{{i}}">
                                                                                            &nbsp;
                                                                                            <input type="checkbox" id="caseFlag_{{i}}" formControlName="isSelected" />  {{caseFlag.value.name}}
                                                                                        </label>
                                                                                        
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </div>
                                                                        </div>
                                                                      <div class="row">
                                                                          <div class="col-md-12" >
                                                                              <div class="mb-1 pt-2 pb-2">
                                                                                  Folder Template
                                                                              </div>
                                                                              <div class="row formgroup pt-2" formArrayName="folderTemplate">
                                                                                  <div class="col-md-3 mb-1 mt-1" [formGroupName]="i" *ngFor="let folderTemp of folderTemplateToFormArray.controls; ; let i = index">
                                                                                     
                                                                                      <label for="folderTemplate_{{i}}">
                                                                                          &nbsp;
                                                                                          <input type="checkbox" id="folderTemplate_{{i}}" formControlName="isSelected" />  {{folderTemp.value.name}}
                                                                                      </label>
                                                                                      
                                                                                  </div>
                                                                              </div>
                                                                              
                                                                          </div>
                                                                      </div>
                                                                      <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="mb-1 pt-2 pb-2">
                                                                                Document Template
                                                                            </div>
                                                                            <div class="row formgroup pt-2"
                                                                                formArrayName="documentTemplate">
                                                                                <div class="col-md-3 mb-1 mt-1"
                                                                                    [formGroupName]="i"
                                                                                    *ngFor="let documentTemp of documentTemplateToFormArray.controls; ; let i = index">

                                                                                    <label
                                                                                        for="documentTemplate_{{i}}">
                                                                                        &nbsp;
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="documentTemplate_{{i}}"
                                                                                            formControlName="isSelected" />
                                                                                        {{documentTemp.value.name}}
                                                                                    </label>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                      <div class="row">
                                                                        <div class="col-md-12" >
                                                                            <div class="mb-1 pt-2 pb-2">
                                                                                Case Services
                                                                            </div>
                                                                            <div class="row formgroup pt-2" formArrayName="case_services">
                                                                                <div class="col-md-3 mb-1 mt-1" [formGroupName]="i" *ngFor="let caseService of caseServicesToFormArray.controls; ; let i = index">
                                                                                   
                                                                                    <label for="caseservice_{{i}}">
                                                                                        &nbsp;
                                                                                        <input type="checkbox" id="caseservice_{{i}}" formControlName="isSelected" />  {{caseService.value.name}}
                                                                                    </label>
                                                                                    
                                                                                </div>
                                                                            </div>
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="mb-1 pt-2 pb-2">
                                                                                Case Review
                                                                            </div>
                                                                            <div class="row formgroup pt-2"
                                                                                formArrayName="case_reviews">
                                                                                <div class="col-md-3 mb-1 mt-1"
                                                                                    [formGroupName]="i"
                                                                                    *ngFor="let caseReview of caseReviewsToFormArray.controls; ; let i = index">

                                                                                    <label
                                                                                        for="caseReview_{{i}}">
                                                                                        &nbsp;
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="caseReview_{{i}}"
                                                                                            formControlName="isSelected" />
                                                                                        {{caseReview.value.name}}
                                                                                    </label>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <hr>
                                                                    <div class="row">
                                                                        <div class="col-md-12">
                                                                            <div class="mb-1 pt-2 pb-2">
                                                                                Subject Types
                                                                            </div>
                                                                            <div class="row formgroup pt-2"
                                                                                formArrayName="case_subject_type">
                                                                                <div class="col-md-3 mb-1 mt-1"
                                                                                    [formGroupName]="i"
                                                                                    *ngFor="let caseSubjectType of caseSubjectTypeToFormArray.controls; ; let i = index">

                                                                                    <label
                                                                                        for="caseSubjectType_{{i}}">
                                                                                        &nbsp;
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            id="caseSubjectType_{{i}}"
                                                                                            formControlName="isSelected"
                                                                                            (change)="onChange(i,caseSubjectType.value.id,caseSubjectType.value.name, $event.target.checked)" />
                                                                                        {{caseSubjectType.value.name}}
                                                                                    </label>

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                        <div class="col-md-12">
                                                                            <div class="mb-1 pt-2 pb-2">
                                                                                Default Type <sup
                                                                                    class="clsStric">*</sup>
                                                                            </div>

                                                                            <div class="row">
                                                                                <select class="form-control"
                                                                                    formControlName="default_subject_type">
                                                                                    <option selected
                                                                                        [value]="">
                                                                                    </option>
                                                                                    <option
                                                                                        *ngFor="let cc of dummy; index as i"
                                                                                        [value]="cc.id">
                                                                                        {{cc.name}}
                                                                                    </option>
                                                                                </select>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                      <!-- <div class="row mt-3">
                                                                          <div class="col-md-12 " >
                                                                              <div class="mb-1 pt-2 pb-2">
                                                                                  Case Services
                                                                              </div>
                                                                              <div class="row formgroup pt-2">
                                                                                  
                                                                                  <div class="col-md-3 mb-1 mt-1" *ngFor="let order of ordersData; index as i">
                                                                                
                                                                                      <label for="caseserve_{{i}}" formArrayName="orders">
                                                                                          &nbsp;
                                                                                          <input type="checkbox" id="caseserve_{{i}}" [formControlName]="i" />  {{order.name}}
                                                                                      </label>
                                                                                      
                                                                                  </div>

                                                                                 

                                                                              </div>
                                                                              
                                                                          </div>
                                                                      </div> -->

                                                                    </ng-template>
                                                                  </ngb-panel>
                                                              </ngb-accordion>


                                                              
                                                                <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-6">
                                                                    <ngb-panel id="custom-panel-6">
                                                                      <ng-template ngbPanelHeader let-opened="opened">
                                                                        <div class="d-flex align-items-center justify-content-between">
                                                                          <h5 class="m-0 font-weight-bold">Custom Fields</h5>
                                                                          <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                                
                                                                        </div>
                                                                      </ng-template>
                                                                      <ng-template ngbPanelContent>
                                                                        
                                                                        
                                                                        <!-- new contact ui -->
                               
                            
                                    <table class="table table-striped border">
                                        <thead>
                                        <tr>
                                          <th></th>
                                          <th scope="col">Field</th>
                                          <th scope="col">Reopenable (?)  <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="Reopenable fields copy their field values to new instances of the case when it is reopened."></i></th>
                                         

                                          <th></th>
                                        </tr>
                                        </thead>
                                        <tbody cdkDropList
                                        (cdkDropListDropped)="onDrop($event)"  formArrayName="customCaseFields" >
                                        <tr *ngFor="let itemrow of form.controls.customCaseFields['controls']; index as i; let l = last" 
                                        [formGroupName]="i" cdkDrag cdkDragLockAxis="y">
                                            <td class="col-xs">
                                                <div class="drag-handle">
                                                    <svg class="bi bi-list handle"
                                                        width="1em" height="1em"
                                                        viewBox="0 0 16 16"
                                                        fill="currentColor"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd"
                                                            d="M2.5 11.5A.5.5 0 013 11h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 7h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5zm0-4A.5.5 0 013 3h10a.5.5 0 010 1H3a.5.5 0 01-.5-.5z"
                                                            clip-rule="evenodd" />
                                                    </svg>
                                                    <!-- {{ i }} -->
                                                </div>
                                            </td>
                                       
                                          <td>
                                           

                                            <select class="form-control" formControlName="customCaseId">
                                                <option *ngFor="let cc of customCases;index as i" [selected]="i == 0" [value]="cc.id">
                                                    {{cc.label}} :: {{cc.type}}
                                                  </option>
                                            </select>
                                           
                                          </td>
                                         
                                       
                                          <td>
                                            <input type="checkbox" formControlName="isOpenable">
                                          </td>
                                          

                                          <td>
                                              <button
                                              (click)="addRowAtIndex(i)"
                                              class=" border-0 btn btn-success"><i
                                                  class="fas fa-plus"></i></button>

                                            <button (click)="deleteRow(i)"
                                                class="ml-2 border-0 btn btn-danger"><i class="fas fa-trash-alt"></i></button>
                                        </td>
                                        <td>
                                            <div *ngIf="submitted && form.controls.customCaseFields.controls[i].controls.customCaseId.errors"
                                                class="text-danger w-100 d-block">
                                                <div *ngIf="this.form.controls.customCaseFields.controls[i].controls.customCaseId.errors.unique">
                                                    Duplicate custom case type are not allowed
                                                </div>
                                                <div *ngIf="this.form.controls.customCaseFields.controls[i].controls.customCaseId.errors.required">
                                                    Custom case type is required
                                                   </div>
                                            </div>
                                            
                                        </td>
                                          
                                        </tr>
                                        </tbody>
                                      </table>

                                      
                           

                            <div class="row">
                              <div class="col-md-12">
                                <button type="button" class="btn btn-primary" (click)="addNewRows()">Add Line </button>
                                <button type="button" class="btn btn-primary ml-3" (click)="openCustomFieldPopup()">Add Custom Field</button>

                              </div>
                            </div>

                            
                                
                              <!-- end -->


                                                                      </ng-template>
                                                                    </ngb-panel>
                                                                </ngb-accordion>


                                                              <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-5">
                                                                  <ngb-panel id="custom-panel-5">
                                                                    <ng-template ngbPanelHeader let-opened="opened">
                                                                      <div class="d-flex align-items-center justify-content-between">
                                                                        <h5 class="m-0 font-weight-bold">Options</h5>
                                                                        <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                              
                                                                      </div>
                                                                    </ng-template>
                                                                    <ng-template ngbPanelContent>
                                                                      
                                                                      <div class="row">
                                                                          <div class="col-md-12">
                                                                              <label for="optioncheck_">
                                                                                  &nbsp;
                                                                                  <input type="checkbox" id="optioncheck_option_budget_tracking" formControlName="option_budget_tracking" /> Enable budget tracking for this case type
                                                                              </label>
                                                                          </div>
                                                                          <div class="col-md-12">
                                                                              <label for="optioncheck_">
                                                                                  &nbsp;
                                                                                  <input type="checkbox" id="optioncheck_option_public_request" formControlName="option_public_request" /> Make this case type available for public case requests
                                                                              </label>
                                                                          </div>
                                                                          <div class="col-md-12">
                                                                              <label for="optioncheck_">
                                                                                  &nbsp;
                                                                                  <input type="checkbox" id="optioncheck_option_notes_instructions" formControlName="option_notes_instructions" /> Copy "Notes & Instructions" to reopened cases
                                                                              </label>
                                                                          </div>
                                                                          <div class="col-md-12">
                                                                              <label for="optioncheck_">
                                                                                  &nbsp;
                                                                                  <input type="checkbox" id="optioncheck_option_admin_notes" formControlName="option_admin_notes" /> Copy "Admin Notes" to reopened cases
                                                                              </label>
                                                                          </div>
                                                                          
                                                                          <div class="col-md-12">
                                                                              <label for="optioncheck_">
                                                                                  &nbsp;
                                                                                  <input type="checkbox" id="optioncheck_option_case_services" formControlName="option_case_services" /> Copy "Case Services" to reopened cases
                                                                              </label>
                                                                          </div>
                                                                      </div>
                                                                    </ng-template>
                                                                  </ngb-panel>
                                                              </ngb-accordion>

                                                          </div>
                                                      </div>

                                                      


                                                  

                                                  </div>
                                              </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="form-group w-100">
                                      <div class="col-lg-12 text-right">
                                          <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button" (click)="OnCancel()"> Cancel</button> 
                                          <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" (click)="OnSubmit()"  type="submit">Save Case Type <span class="fa fa-arrow-right"></span></button> 
                                      </div>
                                  </div>
                              </div>
                          </form>

                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</main>
