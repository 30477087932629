<div class="row">
    <div class="col-md-9">
        <div class="row">
            <div class="col-md-12">
                <div class="cardx">
                    <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
                        <ngb-panel id="custom-panel-1">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center justify-content-between">
                                    <h5 class="m-0 font-weight-bold">
                                        Office Overview</h5>
                                    <button ngbPanelToggle class="btn btn-link p-0 shadow-none">
                                        <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="row  mb-3">
                                    <div class="col-md-4 da-font-bold">
                                        Client</div>
                                    <div class="col-md-8">
                                        {{model.clientName}}</div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold ">
                                        Office Name</div>
                                    <div class="col-md-8">{{model.name}}
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold">
                                        Address</div>
                                    <div class="col-md-8">
                                        <div *ngIf="model.address1">
                                            {{model.address1}}</div>
                                        <div *ngIf="model.address2">
                                            {{model.address2}}</div>
                                        <div *ngIf="model.address3">
                                            {{model.address3}}</div>
                                        <div>
                                            <span *ngIf="model.city">{{model.city}}</span>
                                            <span *ngIf="model.city && model.state">,</span>
                                            <span *ngIf="model.state"> {{model.state}}</span>
                                            <span *ngIf="model.zipCode"> ({{model.zipCode}})</span>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold">
                                        Contact Number :</div>
                                    <div class="col-md-8">
                                        {{model.primaryPhone}}
                                    </div>
                                </div>

                                <div class="row mb-3">
                                  <div class="col-md-4 da-font-bold">
                                      Secondary Phone:</div>
                                  <div class="col-md-8">
                                      {{model.secondaryPhone}}
                                  </div>
                              </div>

                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold">
                                        Fax:</div>
                                    <div class="col-md-8">
                                        {{model.fax}}
                                    </div>
                                </div>
                                <hr />
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold">
                                        Office Salesperson</div>
                                    <div class="col-md-8">
                                        {{model.salesPersonName}}
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold">
                                        Office Case Manager </div>
                                    <div class="col-md-8">
                                        {{model.caseManagerName}}
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold">
                                        Office Billing Email</div>
                                    <div class="col-md-8">
                                        {{model.billingEmail}}
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
            <div class="col-md-12">
                <div class="cardx">
                    <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-2">
                        <ngb-panel id="custom-panel-2">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center justify-content-between">
                                    <h5 class="m-0 font-weight-bold">
                                        Office Notes & Policies</h5>
                                    <button ngbPanelToggle class="btn btn-link p-0 shadow-none">
                                        <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="row mb-3" *ngIf="model.pinnedNotes && model.pinnedNotes.length > 0">
                                    <div class="col-md-12">
                                        <app-pinned-notes-list-view [pinnedNotes]="model.pinnedNotes">
                                        </app-pinned-notes-list-view>
                                    </div>
                                </div>
                                <div class="row  mb-3">
                                    <div class="col-md-4 da-font-bold">
                                        Office General Notes</div>
                                    <div class="col-md-8">
                                        {{model.generalNotes}}
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold ">
                                        Office Case Policies</div>
                                    <div class="col-md-8">
                                        {{model.casePolicies}}
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold">
                                        Office Update Policies
                                    </div>
                                    <div class="col-md-8">
                                        {{model.updatePolicies}}
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold">
                                        Office Invoice Policies</div>
                                    <div class="col-md-8">
                                        {{model.invoicePolicies}}
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-3">
        <div class="row " id="stickyoney">
            <div class="col-md-12">
                <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-2">
                    <ngb-panel id="custom-panel-2">
                        <ng-template ngbPanelHeader let-opened="opened">
                            <div class="d-flex align-items-center justify-content-between">
                                <h5 class="m-0  font-weight-bold">Quick
                                    Links</h5>
                                <button ngbPanelToggle class="btn btn-link p-0 shadow-none">
                                    <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                            </div>
                        </ng-template>
                        <ng-template ngbPanelContent>
                            <div class="row quicklinks">
                                <div class="col-md-12 mb-3">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="list-group mb-3" *ngFor="let quick of quicklink; index as i; ">
                                                <div class="list-group quickhead">
                                                    {{quick.mainmenu}}
                                                </div>
                                                <ul class="list-group">
                                                    <li class="list-group-item border-0"
                                                        *ngFor="let submen of quicklink[i].submenu; index as x">
                                                        <a class="link" href="{{submen.link}}">{{submen.name}}</a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-template>
                    </ngb-panel>
                </ngb-accordion>
            </div>
        </div>

    </div>
</div>
