import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { AppUtils } from 'src/app/helpers';
import { ClientAddModel } from 'src/app/models/client/client.model';
import { ClientResponseModel } from 'src/app/models/client/clientResponse.model';
import { LocationModel } from 'src/app/models/Location/location.model';
import { CountryModel } from 'src/app/models/shared/country.model';
import { CountyModel } from 'src/app/models/shared/County.model';
import { StateModel } from 'src/app/models/shared/state.model';
import { ClientService } from 'src/app/services/client.service';
import { CountryService } from 'src/app/services/country.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-new-client',
  templateUrl: './new-client.component.html',
  styleUrls: ['./new-client.component.css']
})
export class NewClientComponent implements OnInit {

  @Input() public isForPopup: boolean;
  @Output() public saveClick = new EventEmitter();
  clientModel = new ClientAddModel();
  loadBreadCrumb = false;
  constructor(private router: Router,
              private formBuilder: FormBuilder,
              private clientService: ClientService,
              private tosterService: ToasterService,
              private countryService: CountryService,
              public appUtil: AppUtils,
              private ngbActiveModal: NgbActiveModal) { }
  form: FormGroup;
  submitted = false;
  returnUrl: string;
  countrySelectListItem: CountryModel[] = [];
  stateSelectListItem: StateModel[] = [];
  countySelectListItem: CountyModel[] = [];
  @BlockUI('container-blockui-location') blockUI: NgBlockUI;


  selectedCars = '';
  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  tags$: Observable<any>;
  tagsInput$ = new Subject<string>();
  user: any = [];

  ngOnInit(): void {
    this.loadUser();
    this.loadCountries();
    
    this.form = this.formBuilder.group({
      name: ['',
        Validators.required
      ],
      accountCode: [''],
      salesPersonId: [''],
      caseManagerId: [''],
      webSite: [''],
      billingEmail: [''],
      generalNotes: [''],
      casePolicies: [''],
      updatePolicies: [''],
      invoicePolicies: [''],
      sendOverdueInvoices: false,
      show_Bill_To_Location: false,
      tags: null,
      caseTypeRestriction: [],
      locationName: ['',
        Validators.required
      ],
      isPrimary: true,
      country: [''],
      address1: [''],
      address2: [''],
      address3: [''],
      city: [''],
      state: [''],
      county: [''],
      zip: [''],
      //primaryPhone: [''],
      //secondaryPhone: [''],
      fax: [''],
    });
    this.loadTags();
  }
  onCancel(): void {
    if (this.isForPopup) {
      this.saveClick.emit();
      this.ngbActiveModal.dismiss('save click');
    }
    else {
      this.router.navigate(['/client']);
    }
}
  onSubmit(): void {
    debugger;
    this.submitted = true;
    // stop here if form is invalid.
    if (this.form.invalid) {
      this.tosterService.error('Please fill all required fields');
      return;
    }
    this.clientModel.name = this.form.value.name;
    this.clientModel.accountCode = this.form.value.accountCode;
    this.clientModel.salesPersonId = this.form.value.salesPersonId;
    this.clientModel.caseManagerId = this.form.value.caseManagerId;
    this.clientModel.webSite = this.form.value.webSite;
    this.clientModel.billingEmail = this.form.value.billingEmail;
    this.clientModel.generalNotes = this.form.value.generalNotes;
    this.clientModel.casePolicies = this.form.value.casePolicies;
    this.clientModel.updatePolicies = this.form.value.updatePolicies;
    this.clientModel.invoicePolicies = this.form.value.invoicePolicies;
    this.clientModel.sendOverdueInvoices = this.form.value.sendOverdueInvoices === 'true' ? true : false;
    this.clientModel.show_Bill_To_Location = this.form.value.show_Bill_To_Location === 'true' ? true : false;
    this.clientModel.tags = this.form.value.tags;
    this.clientModel.caseTypeRestriction = this.form.value.caseTypeRestriction;
    this.clientModel.Location = new LocationModel();
    this.clientModel.Location.locationName = this.form.value.locationName;
    this.clientModel.Location.isPrimary = this.form.value.isPrimary;
    this.clientModel.Location.country = this.form.value.country;
    this.clientModel.Location.address1 = this.form.value.address1;
    this.clientModel.Location.address2 = this.form.value.address2;
    this.clientModel.Location.address3 = this.form.value.address3;
    this.clientModel.Location.city = this.form.value.city;
    this.clientModel.Location.state = this.form.value.state;
    this.clientModel.Location.county = this.form.value.county;
    this.clientModel.Location.zip = this.form.value.zip;
    this.clientModel.Location.fax = this.form.value.fax;
    this.form.value.dynamicRows.map((x: { phoneType: any; phoneNum: any; extn: any; }) =>
    this.clientModel.Location.contactNos.push({
        contactTypeId: Number(x.phoneType),
        contactNumber: x.phoneNum,
        extensionNote: x.extn ?? '',
        id: 0,
        entityId: 0
      })
    );
    this.clientService.addClient(this.clientModel).subscribe((data: ClientResponseModel) => {
      if (data.success) {
        this.onCancel();
        this.tosterService.success(data.message);
      } else {
        this.tosterService.success(data.message);
      }
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
    });
  }
  toggleDisabled(): void {
    const car: any = this.cars[1];
    car.disabled = !car.disabled;
  }
  private loadUser(): void {
    this.clientService.getUser().subscribe((data: any) => {
      this.user = data;
    }, error => {
    }, () => {
    });
  }

  private loadCountries(): void {
    debugger;
    this.blockUI.start();
    this.countryService.getCountries().subscribe((data: any) => {
      this.countrySelectListItem = data;
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  loadStates(name: any): void {
    debugger;
    const id: any = this.countrySelectListItem.find(x => x.name === name)?.id ?? undefined;
    if (id === null || id === undefined || id.length === 0) {
      return;
    }

    this.blockUI.start();
    this.countryService.getStates(id).subscribe((data: any) => {
      this.stateSelectListItem = data;
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }
  loadCounty(name: any): void {
    debugger;
    const id: any =  this.stateSelectListItem.find(x => x.name === name)?.id ?? undefined;
    if (id === null || id === undefined || id.length === 0) {
      return;
    }

    this.blockUI.start();
    this.countryService.getCounty(id).subscribe((data: any) => {
      this.countySelectListItem = data;
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  private loadTags(): void {
    this.tags$ = concat(
      of([]), // default items
      this.tagsInput$.pipe(
        debounceTime(200),
        filter(res => {
          return res !== null && res.length >= 0;
        }),
        distinctUntilChanged(),
        switchMap(term => {
          return this.clientService.getClientTags(term).pipe(
            catchError(() => of([])) // empty list on error
          );
        })
      )
    );
  }

  addCustomTag = (term) => term;

}
