<div class="row">
    <div class="col-md-12">
        <div class="row">
            <div class="col-md-12">
                <div class="cardx">
                    <ngb-accordion #a="ngbAccordion" activeIds="custom-panel-1">
                        <ngb-panel id="custom-panel-1">
                            <ng-template ngbPanelHeader let-opened="opened">
                                <div class="d-flex align-items-center justify-content-between">
                                    <h5 class="m-0 font-weight-bold">
                                        License Overview</h5>
                                    <button ngbPanelToggle class="btn btn-link p-0 shadow-none">
                                        <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                </div>
                            </ng-template>
                            <ng-template ngbPanelContent>
                                <div class="row  mb-3">
                                    <div class="col-md-4 da-font-bold">
                                        Name</div>
                                    <div class="col-md-8">
                                        {{model.name}}</div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold ">
                                        Country</div>
                                    <div class="col-md-8">{{model.country}}
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold ">
                                        State/Province</div>
                                    <div class="col-md-8">{{model.state}}
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold ">
                                        License</div>
                                    <div class="col-md-8">{{model.licenseNumber}}
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold ">
                                        Expiration</div>
                                    <div class="col-md-8">{{model.expiresOn}}
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold ">
                                        Last Renewed</div>
                                    <div class="col-md-8">{{model.lastRenewed | date : environment.dateTimeFormat.date
                                        }}
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold ">
                                        Notes</div>
                                    <div class="col-md-8">{{model.notes}}
                                    </div>
                                </div>
                                <div class="row mb-3">
                                    <div class="col-md-4 da-font-bold ">
                                        Photo/File</div>
                                    <div class="col-md-8" *ngIf="model.fileName != null && model.fileName.length > 0">
                                        <div class="row">
                                            <div class="col-md-1"
                                                [innerHTML]="appUtils.genrateIconExtension(model.fileName)"></div>
                                            <div class="col-md-9 align-middle my-auto">{{model.fileName}}</div>
                                            <div class="col-md-1">
                                                <a class='link' (click)="downloadAttachment()"><i
                                                        class="align-middle rounded-circle bg-primary bg-delete-btn text-center fa-1x p-2 text-white far fas fa-download"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </ngb-panel>
                    </ngb-accordion>
                </div>
            </div>
        </div>
    </div>
</div>