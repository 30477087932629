import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { LocationAddModel } from "../models/Location/location.add.model";
import { LocationDetailModel } from "../models/Location/location.detail.model";
import { LocationSelectedListItem } from "../models/Location/location.selected.list.item";
import { DataTableResponseModel } from "../models/shared/datatable.response.model";
import { SelectListItem } from "../models/shared/select.list.item";

@Injectable({
    providedIn: 'root'
})
export class LocationService {

    public locationDetailSubject$ = new BehaviorSubject<{
        locationId: number, locationName: string, clientName: string, clientId: number }>(null);
    public locationListDetail$ = this.locationDetailSubject$.asObservable();
    private returnUrl: string;

    public setReturnUrl = (url: string) => this.returnUrl = url;

    public getReturnUrl = () => this.returnUrl;

    constructor(private http: HttpClient) {
    }

    public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/location/query`, dataTablesParameters, {});
    }

    public add(model: LocationAddModel): Observable<string> {
        return this.http.post<string>(`${environment.apiBaseUrl}/location/add`, model, {});
    }

    public get(id: number): Observable<LocationDetailModel> {
        return this.http.get<LocationDetailModel>(`${environment.apiBaseUrl}/location/get/${id}`);
    }

    public delete(id: number) {
        return this.http.get(`${environment.apiBaseUrl}/location/delete/${id}`);
    }

    public edit(model: LocationAddModel): Observable<string> {
        return this.http.post<string>(`${environment.apiBaseUrl}/location/edit`, model, {});
    }

    public getLocationListByClientId(clientId: number): Observable<LocationSelectedListItem[]>{
        return this.http.get<LocationSelectedListItem[]>(`${environment.apiBaseUrl}/location/get-location-list-by-client/${clientId}`);
    }
}
