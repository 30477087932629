import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { NoteFor } from 'src/app/helpers/enums/notes/noteFor';
import { ClientContactService } from 'src/app/services/client.contact.service';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.css']
})
export class ContactDetailComponent implements OnInit, OnDestroy {

  activeTab = 1;
  tabDetails = [];
  contactSubscription: Subscription;
  contactName: string;
  currentPath: string;
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  loadBreadCrumb = false;
  entityId: string;
  id: string;
  clientId: number;
  clientName: string;
  contactId: string;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private clientService:ClientService,
    private clientContactService: ClientContactService){
  }

  ngOnInit(): void {
    this.tabDetails = [
      { id: 1, title: 'Overview', path: 'overview' },
      { id: 2, title: 'Permission', path: 'permission' },
      { id: 3, title: 'Notifications', path: 'notifications' },
      { id: 4, title: 'Notes', path: 'notes' },
      { id: 5, title: 'History', path: 'history' }
    ];

    this.contactSubscription = this.clientContactService.contactDetail$.subscribe((data) => {
      if (data == null || data === undefined) {
        return;
      }
      this.contactName = data.name;
      this.contactId = data.id;
      this.clientId = data.clientId;
      this.clientName = data.clientName;
      this.breadCrumbInputs = [
        { key: 'id', value: [data.clientId.toString(), data.clientName] },
        { key: 'contactId', value: [data.id, data.name] }
      ];
      this.loadBreadCrumb = true;
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.activeTab = changeEvent.nextId;
  }

  // sticky money
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (document.getElementById('stickyoney')) {
      if (document.body.scrollTop > 132 ||
        document.documentElement.scrollTop > 132) {
        document.getElementById('stickyoney').classList.add('stick_me');
      } else {
        document.getElementById('stickyoney').classList.remove('stick_me');
      }
    }
  }
  componentAdded($evt): void {
    this.currentPath = $evt.route.snapshot.routeConfig.path;
  }

  onClickNote():void{
    this.router.navigate([`/client/contact-show/${this.contactId}/notes/add`]);
  }

  ngOnDestroy(): void {
    this.contactSubscription.unsubscribe();
  }

  onClickProfile():void{
    this.edit(this.contactId,this.clientId,this.clientName);
  }

  edit(id: string, clientId, clientName): void {
    this.clientContactService.setReturnUrl(this.router.url);
    this.clientService.clientDetailSubject$.next({ id: clientId, name: clientName });
    this.router.navigate(['/client/contact/edit/', id]);
  }


}
