import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-assign-manager',
  templateUrl: './assign-manager.component.html',
  styleUrls: ['./assign-manager.component.css']
})
export class AssignManagerComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ngbActiveModal: NgbActiveModal,
    private staffService: StaffService,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder
  ) {
  }

  managersSelectListItem: SelectListItem<string>[] = [];
  form: FormGroup;
  isSubmitted = false;

  @BlockUI() blockUI: NgBlockUI;

  @Input() public selectedStaffCount: number;

  @Output() managerId = new EventEmitter<string>();


  get activeModal(): any {
    return this.ngbActiveModal;
  }
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      manager: ['', [Validators.required]]
    });
    this.loadManagers();
  }

  private loadManagers(): void {
    this.blockUI.start();
    this.staffService.getManagers().subscribe((data: any) => {
      this.managersSelectListItem = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  onSubmit(): void {

    this.isSubmitted = true;
    if (this.form.invalid) {
      return;
    }
    this.managerId.emit(this.form.value.manager);
  }



}
