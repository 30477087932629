<main class="content py-0">

    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12 px-0 pb-5">
                            <div class="col-auto mt-n1">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb bg-transparent custom-breadcrumb p-0 mt-1 mb-0">
                                        <li class="breadcrumb-item"><a routerLink="/case-list">Cases</a></li>
                                        <!-- <li class="breadcrumb-item"><a href="case_list_detail.html">Case List</a></li> -->
                                        <li class="breadcrumb-item active" aria-current="page">New Case</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto d-none d-sm-block mb-3">
                            <h3 class="page-title"><strong>New Case</strong> </h3>
                            <p>Create new case detail here.</p>
                        </div>
                    </div>
                    <div class="row mx-0" *blockUI="'add-case-second-ui'">
                        <div class="col-12 px-0 mb-5">
                            <form name="basicform" id="basicform" method="post" action="" novalidate="novalidate"
                                [formGroup]="form" class="new-case-form">


                                <div id="sf3" class="frm" *ngIf="panelStep === 3">
                                    <fieldset>
                                        <legend>Step 3/4
                                            <h3 class="text-orange title-legend">Subject Information</h3>
                                        </legend>

                                        <div class="row">
                                            <div class="col-md-12 col-xl-12 col-xxl-12">
                                                <div class="card flex-fill w-100 mb-4">


                                                    <div class="card-header rounded p-4 mob-px-4" *ngIf="!isHiddenDiv">
                                                        <div class="row mb-4 col-md-6 mb-2">
                                                        <div class="form-group has-search">
                                                            <label for="is_primary"> Make Primary</label>
                                                            <div>
                                                                
                                                                <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                    <input type="radio" id="is_primary_1" class="custom-control-input" value="true" name="is_primary" formControlName="is_primary" >
                                                                    <label class="custom-control-label" for="is_primary_1">Yes</label>
                                                                </div>
                                                                <div class="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" id="is_primary_2" class="custom-control-input" value="false" name="is_primary" formControlName="is_primary">
                                                                    <label class="custom-control-label" for="is_primary_2">No</label>
                                                                </div>
                                                        </div>
                                                        
                                                    </div>
                                                    </div>
                                                    <div class="row mb-4 col-md-6 mb-2">
                                                        <div class="form-group has-search">
                                                            <label for="is_pinned"> Pin to Front of Case</label>
                                                            <div>
                                                                <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                    <input type="radio" id="is_pinned_1" class="custom-control-input" value="true" name="is_pinned" formControlName="is_pinned" >
                                                                    <label class="custom-control-label" for="is_pinned_1">Yes</label>
                                                                </div>
                                                                <div class="custom-control custom-radio custom-control-inline">
                                                                    <input type="radio" id="is_pinned_2" class="custom-control-input" value="false"  name="is_pinned" formControlName="is_pinned">
                                                                    <label class="custom-control-label" for="is_pinned_2">No</label>
                                                                </div>
                                                        </div>
                                                        
                                                    </div>
                                                    </div>
                                                    </div>
                                                    <div class="card-header rounded p-4 mob-px-4">
                                                        <div class="user-header mb-4">
                                                            <h4 class="user-title font-weight-bold mb-0"><span
                                                                    class="mr-2"><img src="img/icons/money-bag.png"
                                                                        alt=""></span>{{titleName}} Information</h4>
                                                        </div>
                                                        <div class="row mb-4" formArrayName="customFields">
                                                            <div class="col-md-12"
                                                                *ngFor="let cf of customFieldToFormArray.controls;let i=index"
                                                                [formGroupName]="i">
                                                                <div class="form-group has-search">
                                                                    <label for="referralSource">{{cf.value.label}} <span
                                                                            *ngIf="cf.value.required" class="clsStric">*
                                                                        </span>
                                                                        <i *ngIf="cf.value.helpText"
                                                                            class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                            data-toggle="tooltip" data-placement="top"
                                                                            placement="top"
                                                                            [ngbTooltip]="cf.value.helpText"></i>
                                                                    </label>
                                                                    <!-- Number 2 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.CountryDropdown"
                                                                        class="input-group position-relative">
                                                                        <select id="multipleChoiceDropdown_{{i}}"
                                                                            formControlName="data">
                                                                            <option *ngFor="let country of countryList"
                                                                                [value]="country.id">{{country.name}}
                                                                            </option>
                                                                        </select>
                                                                        <div
                                                                            class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent"
                                                                                type="button">
                                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                                    data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 3 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.Date"
                                                                        class="form-group has-search">
                                                                        <div class="input-group date"
                                                                        data-date-format="mm-dd-yyyy">
                                                                        <input
                                                                        class="form-control"
                                                                        placeholder="mm-dd-yyyy"
                                                                        formControlName="data"
                                                                        ngbDatepicker
                                                                        #d="ngbDatepicker"
                                                                        [minDate]="{
                                                                          year: 1920,
                                                                          month: 1,
                                                                          day: 1
                                                                        }"
                                                                      />
                                                                      <div class="input-group-append">
                                                                        <button
                                                                          class="
                                                                            btn btn-outline-secondary
                                                                            calendar
                                                                          "
                                                                          (click)="d.toggle()"
                                                                          type="button"
                                                                        ></button>
                                                                      </div>
                                                                    </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 4 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.DateBirthday"
                                                                        class="form-group has-search">
                                                                        <div class="input-group date"
                                                                        data-date-format="mm-dd-yyyy">
                                                                        <input
                                                                        class="form-control"
                                                                        placeholder="mm-dd-yyyy"
                                                                        formControlName="data"
                                                                        ngbDatepicker
                                                                        #d="ngbDatepicker"
                                                                        [minDate]="{
                                                                          year: 1920,
                                                                          month: 1,
                                                                          day: 1
                                                                        }"
                                                                      />
                                                                      <div class="input-group-append">
                                                                        <button
                                                                          class="
                                                                            btn btn-outline-secondary
                                                                            calendar
                                                                          "
                                                                          (click)="d.toggle()"
                                                                          type="button"
                                                                        ></button>
                                                                      </div>
                                                                    </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 5 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.EmailAddress"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <input type="email" formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}">
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>

                                                                    <!-- Number 6 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.InputLongAnswer"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <textarea formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}" cols="40"
                                                                                rows="5"></textarea>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 7 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.InputShortAnswer"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <textarea formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}" cols="20"
                                                                                rows="5"></textarea>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>

                                                                    <!-- Number 8 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.Money"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <input type="number" formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}">
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 9 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.MultipleChoiceDropdown"
                                                                        class="input-group position-relative">
                                                                        <select id="multipleChoiceDropdown_{{i}}"
                                                                            formControlName="data">
                                                                            <option
                                                                                *ngFor="let mcd of defaultCustomFieldValues[i]"
                                                                                [value]="mcd.dform_multiplechoices">
                                                                                {{mcd.dform_multiplechoices}}</option>
                                                                        </select>
                                                                        <div
                                                                            class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent"
                                                                                type="button">
                                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                                    data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 10-->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.MultipleChoiceRadio"
                                                                        class="input-group position-relative">
                                                                        <div
                                                                            *ngFor="let mcr of defaultCustomFieldValues[i];let count=index" >
                                                                            <input id="radiobutton_{{count}}"
                                                                                [value]="mcr.dform_multipleChoiceRadio"
                                                                                type="radio" formControlName="data">
                                                                            <label class="pl-2 pr-2"
                                                                                for="{{mcr.dform_multipleChoiceRadio}}">{{mcr.dform_multipleChoiceRadio}}</label>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 11 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.NameFirstMiddleLast"
                                                                        class="form-group has-search">
                                                                        <div formGroupName="data">
                                                                            <input type="text" formControlName="first"
                                                                                class="form-control rounded mb-2" placeholder="First Name"
                                                                                id="text_{{counter}}">
                                                                                <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.controls.first.errors && form.controls.customFields.controls[i].controls.data.controls.first.errors.required"
                                                                                class="text-danger">
                                                                                First name is required!
                                                                                </div>
                                                                            <input type="text" formControlName="middle"
                                                                                class="form-control rounded  mb-2" placeholder="Middle Name"
                                                                                id="text_{{counter+1}}">
                                                                            <input type="text" formControlName="last" placeholder="Last Name"
                                                                                class="form-control rounded"
                                                                                id="text_{{counter+2}}">
                                                                                <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.controls.last.errors && form.controls.customFields.controls[i].controls.data.controls.last.errors.required"
                                                                                class="text-danger">
                                                                                Last name is required!
                                                                                </div>
                                                                        </div>
                                                                       
                                                                       
                                                                    </div>
                                                                    <!-- Number 12 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.Number"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <input type="number" formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}">
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 13 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.Section"
                                                                        class="form-group has-search">
                                                                        <hr>
                                                                    </div>
                                                                    <!-- Number 14-->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.WebsiteUrl"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <input type="text" formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}">
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 15-->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.YesOrNo"
                                                                        class="input-group position-relative">
                                                                        <div>
                                                                            <input id="radiobutton_yes" type="radio"
                                                                                value="true" formControlName="data">
                                                                            <label for="Yes" class="pl2- pr-2">Yes</label>
                                                                            <input id="radiobutton_no" type="radio"
                                                                                value="false" formControlName="data">
                                                                            <label for="no" class="pl-2 pr-2">No</label>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 16-->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.YesNoOrNA"
                                                                        class="input-group position-relative">
                                                                        <div>
                                                                            <input id="radiobutton_yes" type="radio"
                                                                                value="yes" formControlName="data">
                                                                            <label for="Yes" class="pl-2 pr-2">Yes</label>
                                                                            <input id="radiobutton_no" type="radio"
                                                                                value="no" formControlName="data">
                                                                            <label for="no" class="pl-2 pr-2">No</label>
                                                                            <input id="radiobutton_na" type="radio"
                                                                                value="na" formControlName="data">
                                                                            <label for="na" class="pl-2 pr-2">N/A</label>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 17 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.RatingMultipleChoiceDropdown"
                                                                        class="input-group position-relative">
                                                                        <select id="multipleChoiceDropdown_{{i}}"
                                                                            formControlName="data">
                                                                            <option
                                                                                *ngFor="let mcd of defaultCustomFieldValues[i]"
                                                                                [value]="mcd.dform_ratingMultiDrop_scale">
                                                                                {{mcd.dform_ratingMultiDrop}}</option>
                                                                        </select>
                                                                        <div
                                                                            class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent"
                                                                                type="button">
                                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                                    data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 18 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.RatingNumber"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <input type="text" formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}"> %
                                                                        </div>
                                                                    </div>
                                                                    <!-- Number 19-->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.RatingYesNoOrNA"
                                                                        class="input-group position-relative">
                                                                        <div>
                                                                            <input id="radiobutton_yes" type="radio"
                                                                                value="yes" formControlName="data">
                                                                            <label for="Yes" class="pl-2 pr-2">Yes</label>
                                                                            <input id="radiobutton_no" type="radio"
                                                                                value="no" formControlName="data">
                                                                            <label for="no" class="pl-2 pr-2">No</label>
                                                                            <input id="radiobutton_na" type="radio"
                                                                                value="na" formControlName="data">
                                                                            <label for="na" class="pl-2 pr-2">N/A</label>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>

                                                                     <!-- Number 1-->
                                                                     <div  *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.Address" 
                                                                     class="input-group position-relative">
                                                                     <div formGroupName="data">
                                                                        <div class="row" >
                                                                            <div class="col-md-12">
                                                                                <div class="form-group has-search">
                                                                                    <label for="country">Country<sup class="clsStric">*</sup></label>
                                                                                    
                                                                                    <select formControlName="country" class="form-control" (change)="loadStates($event.target.value)">
                                                                                        <option *ngFor="let country of countryList"
                                                                                        [value]="country.id">{{country.name}}
                                                                                    </option>
                                                                                    </select>
                                                                                </div>
                                                                                <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.controls.country.errors && form.controls.customFields.controls[i].controls.data.controls.country.errors.required"
                                                                                class="text-danger">
                                                                                Country is required!
                                                                                </div>
                                                                            </div>
                  
                                                                            <div class="col-md-12">
                                                                                <div class="form-group has-search">
                                                                                    <label for="address_1">Address 1
                                                                                        </label>
                  
                                                                                        <input type="text" formControlName="address1" class="form-control" placeholder="Address 1" name="address_1" id="address_1">
                                                                                </div>
                                                                            </div>
                  
                                                                          
                                                                        </div>
                  
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <div class="form-group has-search">
                                                                                    <label for="address_2">Address 2
                                                                                    </label>
                  
                                                                                    <input type="text" formControlName="address2" class="form-control" placeholder="Address 2" name="address_2" id="address_2">
                                                                                </div>
                                                                            </div>
                  
                                                                            <div class="col-md-12 mb-2">
                                                                                <div class="form-group has-search">
                                                                                    <label for="address_3">Address 3</label>
                                                                                    <input type="text" formControlName="address3" class="form-control" placeholder="Address 3" name="address_3" id="address_3">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                  
                  
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <div class="form-group has-search">
                                                                                    <label for="city">City
                                                                                    </label>
                  
                                                                                    <input type="text" formControlName="city" name="city" id="city" class="form-control" placeholder="">
                                                                                </div>
                                                                            </div>
                  
                                                                            <div class="col-md-12 mb-2">
                                                                                <div class="form-group has-search">
                                                                                    <label for="select_state">State/Province</label>
                                                                                    
                                                                                    <select name="select_state" id="select_state" type="text" class="form-control mb-3" formControlName="state" placeholder="State" class="form-control" >
                                                                                        <option value="" selected="selected">Select State</option>
                                                                                        <option *ngFor="let state of stateSelectListItem" [value]="state.name">{{state.name}}</option>
                                                                                        </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                  
                  
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <div class="form-group has-search">
                                                                                    <label for="zip_code">Zip/Postal Code
                                                                                    </label>
                  
                                                                                    <input type="text" formControlName="zip_code" class="form-control" placeholder="" id="zip_code" name="zip_code">
                                                                                </div>
                                                                            </div>
                  
                                                                            <div class="col-md-6 mb-2">
                                                                                
                                                                            </div>
                                                                        </div>
                                                                     </div>
                                                                     <!-- <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                     class="text-danger">
                                                                     {{cf.value.label}} is required!
                                                                 </div> -->
                                                                 </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card-header rounded p-4 mob-px-4" *ngIf="alreadyAddedsubjectTypeIncaseViewModel && alreadyAddedsubjectTypeIncaseViewModel.length > 0">
                                                        <div class="user-header mb-4">
                                                            <h4 class="user-title font-weight-bold mb-0"><span
                                                                    class="mr-2"><img src="img/icons/money-bag.png"
                                                                        alt=""></span>Connections</h4>
                                                        </div>
                                                        <div class="row mb-4">
                                                            <div class="form-check mt-2" formArrayName="connections">
                                                                <div class="form-check mt-2"
                                                                    *ngFor="let ct of connectionsToFormArray.controls; let i = index"
                                                                    [formGroupName]="i">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        formControlName="isSelected" id="connections_{{i}}">
                                                                    <label class="form-check-label" for="lbl_connections_{{i}}">
                                                                        {{ct.value.name}}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="card-header rounded p-4 mob-px-4">
                                                        <div class="user-header mb-4">
                                                            <h4 class="user-title font-weight-bold mb-0"><span
                                                                    class="mr-2"><img src="img/icons/money-bag.png"
                                                                        alt=""></span>Photo</h4>
                                                        </div>
                                                        <div class="row mb-4">
                                                            <div class="col-md-6 mb-2">
                                                                <label>Photo</label>
                                                                <span
                                                                    class="mr-2"><img src="{{subjectPhoto}}"
                                                                        alt=""></span>
                                                                <div class="form-group has-search" >
                                                                    <input class="form-control pt-2" type="file" accept=".pdf"
                                                                    id="photo"  name="photo" accept="image/png, image/jpg, image/jpeg"
                                                                    (change)="onFileSelected($event)">
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <div class="form-group">
                                                    <div class="col-lg-12">
                                                        <div class="form-group">
                                                            <div class="col-lg-12 text-right">
                                                                
                                                                <button
                                                                    class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2 open4"
                                                                    (click)="nextStep(4)" type="button">Save Subject & Continue <span
                                                                        class="fa fa-arrow-right"></span></button>
                                                                <button
                                                                class="btn ml-3 font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn back2"
                                                                (click)="backSubjectType(4)" type="button">Skip this step <span
                                                                    class="fa fa-arrow-right"></span></button>
        
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
           
                                        </div>
                                    </fieldset>

                                </div>

                                <div id="sf4" class="frm" *ngIf="panelStep === 4">
                                    <fieldset>
                                        <legend>Step 4/4
                                            <h3 class="text-orange title-legend">Subject Summary</h3>
                                        </legend>

                                        <div class="row">
                                            <div class="col-md-6 col-xl-6 col-xxl-6">
                                                <div class="card flex-fill w-100 mb-4">
                                                    <div class="card-header rounded p-4 pb-5 mob-px-4">
                                                        <div class="user-header mb-4">
                                                            <h4 class="user-title font-weight-bold mb-0">Subjects
                                                                Already Added</h4>
                                                        </div>
                                                        <div class="d-flex align-items-center mt-2" *ngFor="let alreadyaddedSubjectType of alreadyAddedsubjectTypeIncaseViewModel">
                                                            <img class="rounded-circle border border-orange-color img-thumbnail"
                                                                [src]="alreadyaddedSubjectType.photoUrl == null ? '/assets/img/avatars/user.svg' : alreadyaddedSubjectType.photoUrl" alt=""
                                                                width="95">
                                                            <div class="d-flex align-items-end ml-3 ">
                                                                <div>
                                                                    <p class="text-light-black mb-1"> {{alreadyaddedSubjectType.displayValue}}
                                                                    </p>
                                                                    <h6 class="detail-client-title black-light-text">
                                                                    
                                                                        {{getSubjectNameById(alreadyaddedSubjectType.subjectTypeId)}}
                                                                        
                                                                    </h6>
                                                                </div>
                                                                <div class="subject-dropdown">
                                                                    <span id="span1" (click)="EditSubjectTypeForm(alreadyaddedSubjectType.subjectTypeId,alreadyaddedSubjectType.id)" class="ml-5 btn text-orange dropdown-toggle border-0 dropdown-no-caret"><i
                                                                            class="align-middle fas fa-pen"></i> Edit
                                                                        Info</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-xl-6 col-xxl-6">
                                                <div class="card flex-fill w-100 mb-4">
                                                    <div class="card-header rounded p-4 mob-px-4">
                                                        <div class="user-header mb-4">
                                                            <h4 class="user-title font-weight-bold mb-0">Add Additional
                                                                Subjects <span
                                                                    class="lead font-weight-normal text-light-black">(if
                                                                    available)</span></h4>
                                                        </div>
                                                        <div class="row" >
                                                            <div class="col-md-4 py-2" *ngFor="let st of subjectTypeList">
                                                                <span id="span2" (click)="NavigatetoAddSubjectTypeForm(st.id)" class="text-dark-blue font-weight-500 subject-type">Add {{st.name}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="col-lg-12">
                                                <div class="form-group">
                                                    <div class="col-lg-12 text-right">
                                                        <!-- <button
                                                            class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn back4"
                                                            type="button" (click)="nextStep(3)"> Cancel</button> -->
                                                        <button
                                                            class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2 open4"
                                                            type="button" (click)="NavigateToSupportingFiles()">I'm done adding subject and Proceed <span
                                                                class="fa fa-arrow-right"></span></button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </form>
                            <!-- {{form.value | json}} -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
