import { CustomFieldTypes } from '../../enums/settings/customField/CustomFieldTypes';

export const CustomFieldTypesLabel = new Map<number, string>([
    [CustomFieldTypes.Address, 'Address (Address, City, State, Zip, Country)'],
    [CustomFieldTypes.CountryDropdown, 'Country Dropdown'],
    [CustomFieldTypes.Date, 'Date'],
    [CustomFieldTypes.DateBirthday, 'Date (Birthday)'],
    [CustomFieldTypes.EmailAddress, 'Email Address'],
    [CustomFieldTypes.InputLongAnswer, 'Input (Long Answer)'],
    [CustomFieldTypes.InputShortAnswer, 'Input (Short Answer)'],
    [CustomFieldTypes.Money, 'Money'],
    [CustomFieldTypes.MultipleChoiceDropdown, 'Multiple Choice (Dropdown)'],
    [CustomFieldTypes.MultipleChoiceRadio, 'Multiple Choice (Radio)'],
    [CustomFieldTypes.NameFirstMiddleLast, 'Name (First, Middle, Last)'],
    [CustomFieldTypes.Number, 'Number'],
    [CustomFieldTypes.Section, 'Section'],
    [CustomFieldTypes.WebsiteUrl, 'Website (Url)'],
    [CustomFieldTypes.YesOrNo, 'Yes or No'],
    [CustomFieldTypes.YesNoOrNA, 'Yes, No or N/A'],
    [CustomFieldTypes.RatingMultipleChoiceDropdown, 'Rating Multiple Choice (Dropdown)'],
    [CustomFieldTypes.RatingNumber, 'Rating Number (0 - 100)'],
    [CustomFieldTypes.RatingYesNoOrNA, 'Rating Yes, No or N/A']
]);
