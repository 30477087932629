import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';

import { Router } from '@angular/router';

// for datatable
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-staff-price-list',
  templateUrl: './staff-price-list.component.html',
  styleUrls: ['./staff-price-list.component.css']
})
export class StaffPriceListComponent implements OnInit {


  //for angular datatable
  @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;

  constructor(
    private clientService: ClientService,
    private tosterService: ToasterService,
    private renderer: Renderer2,
    private router: Router) { }

  ngOnInit(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },

      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,
      order: [[0, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {

        self.blockUI.start();
        self.clientService.getClients(dataTablesParameters)
          .subscribe(resp => {
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            self.blockUI.stop();
          });
      },

      columns: [

        {
          data: 'name',
          title: 'Name',
          render: function (data, type, row) {

            return `
                <a  detail-person-id="${row.id}" class='link m-r-10' action-type='link-detail'>${row.name}</a>
                `;
          }
        },
        {
          data: 'accountCode',
          title: 'Account Code'
        },
        {
          data: 'salesPersonName',
          title: 'Sales Person',
          orderable: false
        },
        {
          data: 'caseManagerName',
          title: 'Case Manager',
          orderable: false
        },
        {
          data: 'locationCount',
          title: 'Locations',
          orderable: false
        },
        {
          data: 'contactCount',
          title: 'Contacts',
          orderable: false,
          visible: false
        },
        {
          data: 'affiliateCount',
          title: 'Affiliates',
          orderable: false,
          visible: false
        },
        {
          data: 'creationDate',
          title: 'Created',
          orderable: false,
        },
        {
          data: 'name',
          title: 'Action ',
          orderable: false,
          render: function (data, type, row) {
            return `<div class='fa-edit'>
                <button  class='btn del-btn btn-lg btn-table'  >
                <i  view-person-id="${row.id}" class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
                </button>

                <button  class='btn del-btn btn-lg btn-table'   >
                <i del-person-id="${row.id}"  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
                </button>
                </div>
                `;
          }
        },
      ],
      // Use this attribute to enable the responsive extension
      responsive: true,
    };

  }

}
