<div class="row mt-4">
    <div class="col-md-12">

        <div class="card-columns">
            <!-- <pre>{{permissionsToDisplay | json}}</pre> -->
            <div class="cardx" *ngFor="let item of permissionsToDisplay; index as i">
              <div class="card-bodyx">
                

                

                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-{{i}}"   class="da-setting-card">
                    <ngb-panel title="{{item.permissionCategory}}" id="ngb-panel-{{i}}">

                       <ng-template ngbPanelHeader let-opened="opened">
                            <div class="d-flex align-items-center justify-content-between pt-3">
                             
                              
                                <div class="col-md-1">
                                    <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button> 
                                </div>
                                <div class="col-md-7"> 
                                    <h5 class="m-0 font-weight-bold">{{item.permissionCategory}} </h5>
                                </div>
                                <div class="col-md-4">
                                    <div class="row spermission-allow text-center">
                                        <div class="col-md-6 font-weight-bold">Allow</div>
                                      
                                        <div class="col-md-6 font-weight-bold">Deny</div>
                                    </div>
                                    
                                </div>

                              
                            

                             
                            </div>
                       </ng-template>

                      <ng-template ngbPanelContent>
                        
                        <div class="spermission-content">
                            <div class="list-group list-group-flush "  *ngFor="let permission of item.permissions; index as x">
                            
                                <div class="card-body pl-0 pr-0 pb-0">
                                    <div class="row mx-0">
                                        <div class="col-md-8">
                                            <p class=" mb-1">{{permission.name}}</p>
                                        </div>
                                        <div class="col-md-2 mb-3" >
                                            <p *ngIf="!permission.hasPermission" class="text-light-black text-center mb-1">__</p>
                                            <h6 *ngIf="permission.hasPermission" class="detail-client-title black-light-text font-weight-500 text-center mb-0">Yes <i class="align-middle permission_icon rounded-circle bg-success fas fa-check text-white"></i></h6>
                                        </div>
                                        <div class="col-md-2 mb-3">
                                            <p *ngIf="permission.hasPermission" class="text-light-black text-center mb-1">__</p>

                                            <h6  *ngIf="!permission.hasPermission" class="detail-client-title black-light-text font-weight-500 text-center mb-0">No <i class="align-middle permission_icon rounded-circle bg-danger fas fa-times text-white"></i></h6>
                                        </div>
                                    
                                    
                                    </div>
                                </div>
                            
                            </div>
                        </div>
                      </ng-template>
                    </ngb-panel>
                  
                  </ngb-accordion>

              </div>
            </div>
           
        </div>
        
    </div>
</div>