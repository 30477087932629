<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb *ngIf="loadBreadCrumb" [breadCrumbInputs]="breadCrumbInputs"></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>New License </strong> </h3>
                        </div>

                        <!-- <div class="col-md-auto fa-client-title">
                            1213
                        </div> -->
                    </div>


                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card">

                                            <div class="card-body">

                                                <div class="card-text">

                                                    <div class="row">

                                                        <div class="col-md-12">

                                                            <div class="row">

                                                                <div class="col-md-6">
                                                                    <div class="form-group has-search input-group position-relative">
                                                                        <label for="name">Name<sup
                                                                                class="clsStric">*</sup></label>
                                                                        <div class="input-group position-relative">
                                                                            <input type="text" formControlName="name"
                                                                                class="form-control "
                                                                                autocomplete="off">
                                                                            
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.name.errors"
                                                                                class="text-danger mt-3">
                                                                                <div
                                                                                    *ngIf="form.controls.name.errors.required">
                                                                                    Name is required
                                                                                </div>
                                                                            </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <hr />
                                                            <div class="row">

                                                                <div class="col-md-6">
                                                                    <div class="form-group has-search">
                                                                        <label for="license">License Number</label>

                                                                        <div class="input-group position-relative">
                                                                            <input type="text" formControlName="license"
                                                                                class="form-control " id="date"
                                                                                name="licnum" autocomplete="off">
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                                <div class="col-md-6">
                                                                    <div class="form-group has-search">
                                                                        <label for="country">Country</label>
                                                                        <div class="input-group position-relative">
                                                                            <select formControlName="country"
                                                                                (change)="loadStates($event.target.value)">
                                                                                <option
                                                                                    *ngFor="let country of countrySelectListItem"
                                                                                    [value]="country.id">
                                                                                    {{country.name}}
                                                                                </option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6">
                                                                    <div class="form-group has-search">
                                                                        <label for="state">State/Province</label>
                                                                        <div class="input-group position-relative">
                                                                            <ng-select name='stateSelect'
                                                                                formControlName="state">
                                                                                <ng-option
                                                                                    *ngFor="let state of stateSelectListItem"
                                                                                    [value]="state.name">{{state.name}}
                                                                                </ng-option>
                                                                            </ng-select>
                                                                            <div
                                                                                class="input-group-append position-absolute append-btn">
                                                                                <button class="border-0 bg-transparent"
                                                                                    type="button">
                                                                                    <i class="text-white rounded-circle bg-orange align-middle"
                                                                                        data-feather="chevron-down"></i>
                                                                                </button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div class="col-md-6">
                                                                    <div class="form-group has-search">
                                                                        <label for="expiresOn">Expires on<sup
                                                                                class="clsStric">*</sup></label>
                                                                        <input type="date" formControlName="expiresOn"
                                                                            class="form-control mb-3">
                                                                        <div *ngIf="submitted && form.controls.expiresOn.errors"
                                                                            class="text-danger">
                                                                            <div
                                                                                *ngIf="form.controls.expiresOn.errors.required">
                                                                                Expires on is required
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>


                                                            <div class="row">
                                                                <div class="col-md-6 mb-4">
                                                                    <label for="notes">Notes:</label>
                                                                    <textarea class="form-control p-3" name="notes"
                                                                        rows="5" placeholder="Note"
                                                                        formControlName="notes"></textarea>
                                                                </div>



                                                            </div>

                                                            <hr>

                                                            <div class="row">

                                                                <div class="col-md-6">

                                                                    <div class="form-group has-search">
                                                                        <label for="file">Attachment </label>
                                                                        <input class="form-control  pt-2"
                                                                            (change)="onFileSelected($event)"
                                                                            type="file" id="attachment"
                                                                            name="attachment"
                                                                            accept="application/pdf, image/*">
                                                                    </div>
                                                                    <small>File must be an image or PDF file.</small>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>




                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button
                                                class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn"
                                                type="button" [routerLink]="['..']"> Cancel</button>
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2"
                                                type="submit">Save License <span
                                                    class="fa fa-arrow-right"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>