<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12 px-0 pb-5">
                            <div class="col-auto mt-n1">
                                <app-breadcrumb></app-breadcrumb>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto d-none d-sm-block mb-3">
                            <h3 class="page-title"><strong>Answer Review (ST QC Reviews)</strong> </h3>
                            <!-- <p>Create new client detail here.</p> -->
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
  
                                            <div class="card-body">
                                                 <!-- <div class="user-header mb-4">
                                                    <h4 class="user-title font-weight-bold mb-0">Basic Information</h4>
                                                </div> -->
                                              <div class="card-text">
  
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="Label-24">Staff</label>
                                                            <!-- <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Sales Person"></i> -->
                                                            <div class="input-group position-relative">
                                                                <select formControlName="salesPersonId" >
                                                                    <option value="" selected>Select Person</option>
  
                                                                    <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>
  
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                    
                                                            <div class="input-group position-relative">
                                                    
                                                                <label id="Label-24">Review Date<span class="clsStric">*</span></label>
                                                                <div class="input-group position-relative">
                                                                    <input name="name" type="date" class="form-control mb-3" formControlName="date" class="form-control"
                                                                        autocomplete="off" placeholder="Client Name"
                                                                        [ngClass]="{ 'is-invalid': submitted && form.controls.date.errors }" />
                                                                    <div *ngIf="submitted && form.controls.date.errors" class="invalid-feedback">
                                                                        <div *ngIf="form.controls.date.errors.required" style="font-size: larger;">
                                                                            Date is required
                                                                        </div>
                                                                    </div>
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                        <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   

                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="Label-24">Status <span class="clsStric">*</span></label>
                                                            <!-- <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Sales Person"></i> -->
                                                            <div class="input-group position-relative">
                                                                <select formControlName="salesPersonId" >
                                                                    <option value="" selected>Select Person</option>
  
                                                                    <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>
  
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   

                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="Label-24">Activity</label>
                                                            <!-- <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Sales Person"></i> -->
                                                            <div class="input-group position-relative">
                                                                <select formControlName="salesPersonId" >
                                                                    <option value="" selected>Select Person</option>
  
                                                                    <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>
  
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                              
                                                </div>
                                                <hr class="mt-4 mb-4">

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="Label-24">Objectives Met</label>
                                                            <!-- <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Sales Person"></i> -->
                                                            <div>
                                                                <div class="custom-control custom-radio custom-control-inline mr-5"><input type="radio" id="customRadio"
                                                                        formcontrolname="sendOverdueInvoices" name="sendOverdueInvoices" value="true"
                                                                        class="custom-control-input "><label for="customRadio"
                                                                        class="custom-control-label">Yes</label></div>
                                                                <div class="custom-control custom-radio custom-control-inline"><input type="radio" id="customRadio2"
                                                                        formcontrolname="sendOverdueInvoices" name="sendOverdueInvoices" value="false"
                                                                        class="custom-control-input "><label for="customRadio2"
                                                                        class="custom-control-label">No</label></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                   

                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="Label-24">Grammar / Structure <span class="clsStric">*</span></label>
                                                            <i class="align-middle fas fa-info-circle ml-2 text-light-black" ngbTooltip="Readability" placement="top"></i> 
                                                            <div class="input-group position-relative">
                                                                <select formControlName="salesPersonId" >
                                                                    <option value="" selected>Select Person</option>
  
                                                                    <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>
  
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                   

                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="Label-24">Content</label>
                                                            <i class="align-middle fas fa-info-circle ml-2 text-light-black" ngbTooltip="Detail depth and accuracy" placement="top"></i> 
                                                            <div class="input-group position-relative">
                                                                <select formControlName="salesPersonId" >
                                                                    <option value="" selected>Select Person</option>
  
                                                                    <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>
  
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="Label-24">Responsiveness</label>
                                                            <i class="align-middle fas fa-info-circle ml-2 text-light-black" ngbTooltip="Time & Quality" placement="top"></i> 
                                                            <div class="input-group position-relative">
                                                                <select formControlName="salesPersonId" >
                                                                    <option value="" selected>Select Person</option>
  
                                                                    <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>
  
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="Label-24">Notes</label>
                                                          
                                                            <div class="input-group position-relative">
                                                                <textarea  name="notes" id="notes" rows="5" placeholder="Add notes" formcontrolname="notes" class="form-control p-3 " ></textarea>
                                                            </div>
                                                        </div>
                                                    </div>
                                              
                                                </div>
  
  
                                              </div>
  
                                            </div>
                                          </div>
                                    </div>
  
  
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button" > Cancel</button>
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="sub">Save Review <span class="fa fa-arrow-right"></span></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </main>
  