import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, OnDestroy {

  activeTab = 1;
  tabDetails = [];
  staffSubscription: Subscription;
  staffName: string;
  currentPath: string;


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthenticationService) {
  }



  ngOnInit(): void {
    this.tabDetails = [
      { id: 1, title: 'Overview', path: 'overview' },
      { id: 2, title: 'Permission', path: 'permission' },
      { id: 3, title: 'Licenses', path: 'license' },
      { id: 4, title: 'Price List', path: 'price-list' },
      { id: 5, title: 'Agenda', path: 'agenda' },

      { id: 6, title: 'Notifications', path: 'notifications' },
      { id: 7, title: 'Policies', path: 'policies' },
      { id: 8, title: 'Notes', path: 'notes' },
      { id: 9, title: 'History', path: 'history' }
    ];
    this.staffSubscription = this.authService.userNameDetai$.subscribe((data) => this.staffName = data);
  }

  onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.activeTab = changeEvent.nextId;
  }

  // sticky money
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (document.getElementById('stickyoney')) {
      if (document.body.scrollTop > 132 ||
        document.documentElement.scrollTop > 132) {
        document.getElementById('stickyoney').classList.add('stick_me');
      } else {
        document.getElementById('stickyoney').classList.remove('stick_me');
      }
    }
  }
  componentAdded($evt): void {
    this.currentPath = $evt.route.snapshot.routeConfig.path;
  }

  ngOnDestroy(): void {
    this.staffSubscription.unsubscribe();
  }

}
