<main class="content py-0">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-md-12 pt-7">
        <div class="w-100 pt-4">
          <div class="row">
            <div class="col-md-12">
              <app-breadcrumb
                *ngIf="loadBreadCrumb"
                [breadCrumbInputs]="breadCrumbInputs"
              >
              </app-breadcrumb>
            </div>
          </div>
          <div class="row mt-4 mb-4">
            <div class="col-auto d-none d-sm-block">
              <h3 class="page-title"><strong>New Note</strong></h3>
            </div>
          </div>
          <div class="row mx-0">
            <div class="col-12 px-0 mb-5">
              <form
                class="new-case-form"
                name="form"
                [formGroup]="form"
                (ngSubmit)="onSubmit()"
              >
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="card-text">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="access"
                                      >Access Group<sup class="clsStric"
                                        >*</sup
                                      ></label
                                    >
                                    <div
                                      class="
                                        input-group
                                        position-relative
                                        d-flex
                                        flex-column
                                      "
                                    >
                                      <select
                                        name="access"
                                        formControlName="accessGroup"
                                      >
                                        <option value="" selected>
                                          Select Access Group
                                        </option>
                                        <option
                                          value="{{ list.id }}"
                                          *ngFor="let list of accessGroups"
                                        >
                                          {{ list.value }}
                                        </option>
                                      </select>
                                      <div
                                        *ngIf="
                                          isSubmitted &&
                                          form.controls.accessGroup.errors
                                        "
                                        class="text-danger"
                                      >
                                        <div
                                          *ngIf="
                                            form.controls.accessGroup.errors
                                              .required
                                          "
                                        >
                                          Access Group is required
                                        </div>
                                      </div>
                                      <div
                                        class="
                                          input-group-append
                                          position-absolute
                                          append-btn
                                        "
                                      >
                                        <button
                                          class="border-0 bg-transparent"
                                          type="button"
                                        >
                                          <i
                                            class="
                                              text-white
                                              rounded-circle
                                              bg-orange
                                              align-middle
                                            "
                                            data-feather="chevron-down"
                                          ></i>
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <label for="date"
                                      >Date<sup class="clsStric">*</sup></label
                                    >

                                    <input
                                      type="date"
                                      formControlName="date"
                                      class="form-control"
                                      id="date"
                                      name="date"
                                      autocomplete="off"
                                    />
                                    <div
                                      *ngIf="
                                        isSubmitted && form.controls.date.errors
                                      "
                                      class="text-danger"
                                    >
                                      <div
                                        *ngIf="
                                          form.controls.date.errors.required
                                        "
                                      >
                                        Date is required
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-6 mb-4">
                                  <label
                                    >Note:<sup class="clsStric">*</sup></label
                                  >
                                  <textarea
                                    class="form-control p-3"
                                    name="generalNotes"
                                    id="generalNotes"
                                    rows="5"
                                    placeholder="Note"
                                    formControlName="generalNotes"
                                  ></textarea>
                                  <div
                                    *ngIf="
                                      isSubmitted &&
                                      form.controls.generalNotes.errors
                                    "
                                    class="text-danger"
                                  >
                                    <div
                                      *ngIf="
                                        form.controls.generalNotes.errors
                                          .required
                                      "
                                    >
                                      Note is required
                                    </div>
                                  </div>
                                  <input
                                    id="pin_note"
                                    name="pin_note"
                                    formControlName="isPinnedNote"
                                    class="mt-3"
                                    type="checkbox"
                                  />
                                  <label for="pin_note">
                                    &nbsp;Pin this note to the overview</label
                                  >
                                </div>
                              </div>
                              <hr />
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <div class="row">
                                      <div class="col-md-7">
                                        <label for="attachment"
                                          >Attachment
                                        </label>
                                        <input
                                          class="form-control pt-2"
                                          type="file"
                                          id="attachment"
                                          name="attachment"
                                          (change)="onFileSelected($event)"
                                          #fileInput
                                        />
                                      </div>
                                      <div class="col-md-2 my-auto pl-0">
                                        <button
                                          class="
                                            btn btn-light
                                            bg-transparent
                                            border-0
                                            text-danger
                                            shadow-none
                                            mt-4
                                          "
                                          type="button"
                                          (click)="fileInputReset()"
                                        >
                                          Clear
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                  <small
                                    >File must be an image, PDF, spreadsheet, or
                                    document.</small
                                  >
                                  <br />
                                  <small class="text-danger"
                                    >Executable files(msi,exe,etc...) will be
                                    convert into zip.</small
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group w-100">
                    <div class="col-lg-12 text-right">
                      <button
                        class="
                          btn
                          font-weight-500
                          px-4
                          py-2
                          bg-white
                          text-dark-blue
                          border-btn
                        "
                        type="button"
                        [routerLink]="['../']"
                      >
                        Cancel
                      </button>
                      <button
                        class="
                          btn
                          bg-blue
                          text-white
                          px-4
                          py-2
                          font-weight-500
                          ml-2
                        "
                        type="submit"
                      >
                        Save Note <span class="fa fa-arrow-right"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
