<main class="content py-0">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-md-12 pt-7">
        <div class="w-100 pt-4">
          <div class="row">
            <div class="col-auto d-none d-sm-block">
              <div class="d-flex mb-2">
                <h3 class="page-title"><strong>Staff List &nbsp;</strong></h3>
                <div ngbDropdown class="d-inline-block">
                  <button
                    class="btn btn-outline-primary dropdown-primary"
                    id="dropdownBasic1"
                    ngbDropdownToggle
                  >
                    All Staff
                  </button>
                  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                    <button ngbDropdownItem>Action - 1</button>
                    <button ngbDropdownItem>Another Action</button>
                    <button ngbDropdownItem>Something else is here</button>
                  </div>
                </div>
              </div>
              <p>Get all the Staff list info at one place</p>
            </div>

            <div class="col-auto ml-auto text-right mt-n1">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                  <li class="filter-item">
                    <div ngbDropdown class="d-inline-block">
                      <button
                        class="
                          btn btn-outline-primary btn-lg
                          bulkaction-dropdown
                        "
                        id="dropdownBasic1"
                        ngbDropdownToggle
                        [disabled]="isBulkOperationButtonDisabled"
                      >
                        Bulk Action
                      </button>
                      <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                        <button
                          *ngIf="filterBy !== staffFilterOptions.DeletedStaffs"
                          ngbDropdownItem
                          (click)="openAssignManagerModal()"
                        >
                          Assign Manager
                        </button>
                        <button
                          *ngIf="filterBy !== staffFilterOptions.DeletedStaffs"
                          ngbDropdownItem
                          (click)="onBulkDelete()"
                        >
                          Delete
                        </button>
                        <button
                          *ngIf="filterBy === staffFilterOptions.DeletedStaffs"
                          ngbDropdownItem
                          (click)="onBulkUnDelete()"
                        >
                          Undelete
                        </button>
                      </div>
                    </div>
                  </li>

                  <li
                    class="filter-item"
                    *hasPermissions="[permissionType.CreateStaffMembers]"
                  >
                    <button
                      class="btn btn-lg bg-blue text-white"
                      [routerLink]="['add-employee']"
                    >
                      <i class="align-middle fas fa-plus-circle"></i> New
                      User
                    </button>
                  </li>
                  <li
                    class="filter-item"
                    *hasPermissions="[permissionType.CreateStaffMembers]"
                  >
                    <a href="/newuser">
                      <button [disabled]="true" class="btn btn-lg bg-blue text-white">
                        <i class="align-middle fas fa-plus-circle"></i> New
                        Vendor
                      </button></a
                    >
                  </li>
                  <li class="filter-item">
                    <button class="btn btn-lg bg-blue text-white">
                      <i class="align-middle fas fa-external-link-alt"></i>
                      Export Data
                    </button>
                  </li>
                  <li class="filter-item px-0 filter-icon"  (click)="filterNavStatus = true">
                    <button class="btn btn-lg bg-blue text-white">
                      <i class="align-middle fas fa-filter"></i>
                    </button>
                  </li>
                </ol>
              </nav>
            </div>
            <!-- Filter right sidenav -->
            <app-filter-and-search
              (onApplyFilter)="applyFilter($event)"
              (resetFilters)="onResetFilters()"  [filterStatusParent]="filterNavStatus"  (closeBtnFilterStatus)="closeFilterEvent($event)"
            >
            </app-filter-and-search>
          </div>
        </div>
        <div class="row">
          <div class="col-md-10"></div>

          <div class="col-md-2">
            <div class="form-group form-inline float-right mb-0">
              <button
                class="dropdown-toggle btn btn-lg bulkaction-dropdown"
                (click)="openCustomizedListModal()"
              >
                Customize List&nbsp;
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div
              class="case-table table-responsive mt-0"
              *blockUI="'container-blockui-manage'"
            >
              <div>
                <table
                  class="
                    neu-table
                    table table-striped
                    neu-table
                    w-auto
                    table-responsive
                  "
                  [ngStyle]="{ visibility: isDtVisible ? 'visible' : 'hidden' }"
                  datatable
                  [dtOptions]="dtOptions"
                ></table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
