import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddOrEditGeneralGlobalSettingModel } from '../models/general-global-setting/addOrEditGeneralGlobalSettingModel';

@Injectable({
  providedIn: 'root'
})
export class GeneralGlobalSettingService {

constructor(private http: HttpClient) { }
public post(model: AddOrEditGeneralGlobalSettingModel): Observable<string> {
  return this.http.post<string>(`${environment.apiBaseUrl}/generalglobalsetting/post`, model, {});
}
public get(): Observable<AddOrEditGeneralGlobalSettingModel> {
  return this.http.get<AddOrEditGeneralGlobalSettingModel>(`${environment.apiBaseUrl}/generalglobalsetting/get`);
}
}
