import { Component, OnInit } from '@angular/core';
import { AppUtils } from 'src/app/helpers';


@Component({
  selector: 'app-case-detail-updates',
  templateUrl: './case-detail-updates.component.html',
  styleUrls: ['./case-detail-updates.component.css']
})
export class CaseDetailUpdatesComponent implements OnInit {

  profileImgUrl = '/assets/img/avatars/user.svg';
  filterNavStatus: boolean;
  constructor(public appUtil: AppUtils) { }

  ngOnInit(): void {
  }

}
