<main class="content py-0">
  <div class="container-fluid p-0">
      <div class="row">
          <div class="col-md-12 pt-7">
              <div class="w-100 pt-4">
                  <div class="row">
                      <div class="col-md-12">
                          <app-breadcrumb></app-breadcrumb>
                      </div>
                  </div>

                  <div class="row mt-4 mb-4">
                      <div class="col-auto d-none d-sm-block">
                          <h3 class="page-title"><strong>Edit Folder Template</strong> </h3>
                      </div>
                  </div>


                  <div class="row mx-0" *blockUI="'container-blockui-new-folder-template'">
                      <div class="col-12 px-0 mb-5">
                          <form class="new-case-form" name="form"  [formGroup]="form" (ngSubmit)="onSubmit()">
                              <div class="row">
                                  <div class="col-md-12">
                                      <div class="card" >

                                          <div class="card-body">

                                            <div class="card-text">

                                              <div class="row">

                                                  <div class="col-md-12">

                                                    <div class="row">
                                                            <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="name">Name<sup class="clsStric">*</sup></label>

                                                                  <input type="text" formControlName="name" class="form-control mb-3" id="name" name="name" autocomplete="off">
                                                                  <div *ngIf="submitted && form.controls.name.errors">
                                                                      <div *ngIf="submitted && form.controls.name.errors.required" class="text-danger">
                                                                              Name is required
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>

                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="access_grp">Access Group<sup class="clsStric">*</sup><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top"  ngbTooltip="A user must be a part of the selected access group in order to answer and/or complete the review."></i></label>
                                                                  <div class="input-group position-relative">
                                                                      <select class="form-control" formControlName="access_grp">
                                                                        <option selected disabled>
                                                                          Select
                                                                        </option>
                                                                        <option *ngFor="let item of accessGroupSelectListItem"
                                                                          [value]="item.id">
                                                                          {{item.value}}
                                                                        </option>
                                                                      </select>
                                                                      <div *ngIf="submitted && form.controls.access_grp.errors" class="text-danger">
                                                                        <div *ngIf="form.controls.access_grp.errors.required">
                                                                          Access Group is Required
                                                                        </div>
                                                                      </div>
                                                                  </div>
                                                              </div>
                                                          </div>
                                                      </div>






                                                      <div class="row">
                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="default_notes">Default Notes</label>


                                                                   <textarea formControlName="default_notes" class="form-control p-3" name="case_request_inst" id="default_notes" rows="5"  placeholder="" ></textarea>
                                                              </div>
                                                          </div>
                                                      </div>
                                                      <hr>
                                                      <div class="row">
                                                        <div class="col-md-6 mb-2">
                                                            <label><b><sup class="clsStric">*</sup> Create this folder on cases for the following case types:</b></label>
                                                            <div class="form-check mt-2" formArrayName="case_types">
                                                                <div class="form-check mt-2"
                                                                    *ngFor="let ct of caseTypesToFormArray.controls; let i = index"
                                                                    [formGroupName]="i">
                                                                    <input class="form-check-input" type="checkbox"
                                                                        formControlName="isSelected" id="casetypes_{{i}}">
                                                                    <label class="form-check-label" for="lbl_casetypes_{{i}}">
                                                                        {{ct.value.name}}
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="submitted && form.controls.case_types.errors">
                                                                <div *ngIf="submitted && form.controls.case_types.errors.required"
                                                                    class="text-danger">
                                                                    Please select atleast one case type
                                                                </div>
                                                            </div> 
                                                        </div>
                                                       </div>

                                                  </div>
                                              </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>




                                  <div class="form-group w-100">
                                      <div class="col-lg-12 text-right">
                                          <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  routerLink ="/setting/cases/folder-templates"> Cancel</button>
                                          <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="submit">Save Folder Template <span class="fa fa-arrow-right"></span></button>
                                      </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</main>
