import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';

// for autocomplete
import { concat, Observable, Subject } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap } from 'rxjs/operators';
import { AppUtils } from 'src/app/helpers';
import { RoleAvailableFor } from 'src/app/helpers/enums/roles/role.available.for';
import { NgbDateFRParserFormatter } from 'src/app/helpers/ngb.date.parser';
import { CountryModel } from 'src/app/models/shared/country.model';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { StateModel } from 'src/app/models/shared/state.model';
import { EmployeeAddModel } from 'src/app/models/staff/employee.add.model';
import { CountryService } from 'src/app/services/country.service';
import { RoleService } from 'src/app/services/role.service';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';

const states = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado',
  'Connecticut', 'Delaware', 'District Of Columbia', 'Federated States Of Micronesia', 'Florida', 'Georgia',
  'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
  'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana',
  'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
  'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island',
  'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Islands', 'Virginia',
  'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];

@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.css']
})
export class AddEmployeeComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private staffService: StaffService,
    private toasterService: ToasterService,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private countryService: CountryService,
    private ngbDateParserFormatter: NgbDateFRParserFormatter,
    public appUtil: AppUtils
  ) {
    this.model = new EmployeeAddModel();
  }

  @BlockUI('container-blockui-employee') blockUI: NgBlockUI;
  form: FormGroup;
  submitted = false;
  model: EmployeeAddModel;
  managersSelectListItem: SelectListItem<string>[] = [];
  rolesSelectListItem: SelectListItem<string>[] = [];
  countrySelectListItem: CountryModel[] = [];
  stateSelectListItem: StateModel[] = [];
  tags$: Observable<any>;
  tagsInput$ = new Subject<string>();
  // for dropdowns
  options = [
    { id: 1, name: 'Lorem Ipsum' },
    { id: 2, name: 'dolor ' },
    { id: 3, name: 'amet' },
  ];
  countries = [
    { id: 'US', name: 'US' },
    { id: 'India', name: 'India' },
  ];
  selectedValue = 1;
  Selectedcountries = 1;

  dateStruct: NgbDateStruct;

  public profileimg: string;
  public currentInput: string;
  fileBlob: string;
  fileName: string;


  // for tags
  selectedCars2 = [];

  cars2 = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];

  onImageCropped(file: File): void {
    this.form.controls.profileImg.setValue(file);
  }

  ngOnInit(): void {

    this.form = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      middleName: [''],
      lastName: [''],
      manager: [''],
      role: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      title: ['', [Validators.required]],
      address1: [''],
      address2: [''],
      address3: [''],

      country: new FormControl(null),
      state: [''],
      zip: [''],
      city: [''],

      contactNumber: [''],

      homePhone: [''],
      fax: [''],
      dob: null,
      accountCode: [''],
      tags: null,
      generalNotes: [''],
      adminNotes: [''],
      emailSignature: [''],
      preferedServices: [''],
      preferedRegions: [''],
      isTrackLastLocation: false,
      profileImg: null
    });

    this.loadManagers();
    this.loadRoles();
    this.loadCountries();
    this.loadTags();


  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : states.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }

    this.model.firstName = this.form.controls.firstName.value;
    this.model.middleName = this.form.controls.middleName.value;
    this.model.lastName = this.form.controls.lastName.value;
    this.model.accountCode = this.form.controls.accountCode.value;
    this.model.address1 = this.form.controls.address1.value;
    this.model.address2 = this.form.controls.address2.value;
    this.model.address3 = this.form.controls.address3.value;
    this.model.adminNotes = this.form.controls.adminNotes.value;
    this.model.city = this.form.controls.city.value;
    const country = this.form.controls.country.value;
    this.model.country = this.countrySelectListItem.find(x => x.id === country)?.name;
    this.model.dob = this.ngbDateParserFormatter.format(this.form.controls.dob.value) ?? '';
    this.model.email = this.form.controls.email.value;
    this.model.emailSignature = this.form.controls.emailSignature.value;
    this.model.fax = this.form.controls.fax.value;
    this.model.generalNotes = this.form.controls.generalNotes.value;
    this.model.isTrackLastLocation = this.form.controls.isTrackLastLocation.value as boolean;
    this.model.managerId = this.form.controls.manager.value;
    this.model.preferedRegions = this.form.controls.preferedRegions.value;
    this.model.preferedServices = this.form.controls.preferedServices.value;
    this.model.profileImg = this.form.controls.profileImg.value;
    this.model.role = this.form.controls.role.value;
    this.model.state = this.form.controls.state.value;
    this.model.tags = this.form.controls.tags.value;
    this.model.title = this.form.controls.title.value;
    this.model.zip = this.form.controls.zip.value;
    this.form.value.dynamicRows.map((x: { phoneType: any; phoneNum: any; extn: any; }) =>
    this.model.contactNos.push({
        contactTypeId: Number(x.phoneType),
        contactNumber: x.phoneNum,
        extensionNote: x.extn ?? '',
        id: 0,
        entityId: 0
      })
    );
    this.blockUI.start();
    this.staffService.post(this.model).subscribe(
      (data: { isSuccess: boolean, message: string }) => {
        setTimeout(() => {
          this.router.navigate(['/staff']);
        }, 10);
        setTimeout(() => {
          this.toasterService.success('Employee created successfully');
        }, 300);
        this.blockUI.stop();
      },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }


  addCustomTag = (term) => term;

  private loadManagers(): void {
    this.blockUI.start();
    this.staffService.getManagers().subscribe((data: any) => {
      this.managersSelectListItem = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  private loadRoles(): void {
    this.blockUI.start();
    this.roleService.getRolesByAvailableFor(RoleAvailableFor.employee).subscribe((data: any) => {
      this.rolesSelectListItem = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  private loadCountries(): void {
    this.blockUI.start();
    this.countryService.getCountries().subscribe((data: any) => {
      this.countrySelectListItem = data;
      const defaultCountry = this.countrySelectListItem.find(x => x.isDefault);
      this.form.controls.country.setValue(defaultCountry.id);
      this.loadStates(defaultCountry.id);

    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  private loadTags(): void {
    this.tags$ = concat(
      of([]), // default items
      this.tagsInput$.pipe(
        debounceTime(200),
        filter(res => {
          return res !== null && res.length >= 0;
        }),
        distinctUntilChanged(),
        switchMap(term => {
          return this.staffService.getStaffTags(term).pipe(
            catchError(() => of([])) // empty list on error
          );
        })
      )
    );
  }

  private loadStates(id: any): void {

    if (id === null || id === undefined || id.length === 0) {
      return;
    }
    this.blockUI.start();
    this.countryService.getStates(id).subscribe((data: any) => {
      this.stateSelectListItem = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  getFormValidationErrors(): void {
    Object.keys(this.form.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
        });
      }
    });
  }

  private toDateString(date: NgbDateStruct): string // from internal model -> your mode
  {
    return date ? ('0' + date.month).slice(-2)
      + '/' + ('0' + date.day).slice(-2) + '/' + date.year : null;
  }


}
