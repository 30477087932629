<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-titled d-block">Enter Payments </h4>
   
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">

    <form name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <div class="row">
                <div class="col-md-12">
                    <div class="modal-title">
                        <h4 class="p-3 pl-0 font-weight-bold d-block">SLIP-00738 - SCOBIE, SPENCER</h4>
                        
                    </div>
                </div>
            </div>
           

            <div class="row">
                <div class="col-sm-4 d-flex col-form-label my-auto">
                    <label for="selectaf">Amount <sup class="clsStric">*</sup></label>
                </div>
                <div class="col-sm-8">
                    <input type="text" formControlName="text" class="form-control">
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-4 d-flex col-form-label my-auto">
                    <label for="selectaf">Date <sup class="clsStric">*</sup></label>
                </div>
                <div class="col-sm-8">
                    <input type="date" formControlName="date" class="form-control">
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-4 d-flex col-form-label my-auto">
                    <label for="selectaf">Check/Reference #<sup class="clsStric">*</sup></label>
                </div>
                <div class="col-sm-8">
                    <input type="text" formControlName="checkRef" class="form-control">
                </div>
            </div>

            <div class="row mt-3">
                <div class="col-sm-4 d-flex col-form-label my-auto">
                    <label for="selectaf">Notes</label>
                </div>
                <div class="col-sm-8">
                  <textarea formControlName="notes" class="form-control"></textarea>
                </div>
            </div>
            <hr>

            <div class="row mt-3">
                <div class="col-sm-4 d-flex col-form-label my-auto">
                    <label for="selectaf">Amount Due</label>
                </div>
                <div class="col-sm-8">
                  <h3>$1,522.10</h3>
                </div>
            </div>
          
             
                <div *ngIf="isSubmitted && form.controls.client.errors" class="invalid-feedback">
                    <div *ngIf="form.controls.client.errors.required" style="font-size: larger;">
                        Client is required
                    </div>
                </div>
        </div>
        <div class="modal-footer border-0">
            <button type="button" class="btn btn-outline-dark" (click)="activeModal.dismiss('Cancel click')">Cancel</button>
            <button type="submit" class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2">Save Payments </button>
        </div>
    </form>
</div>