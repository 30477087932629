<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12 px-0 pb-5">
                            <div class="col-auto mt-n1">
                                <app-breadcrumb></app-breadcrumb>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto d-none d-sm-block mb-3">
                            <h3 class="page-title">
                                <strong>Case Map (MC20796-02)</strong>
                            </h3>
                        </div>


                        <div class="col-auto ml-auto text-right mt-n1">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                    <!-- <li class="filter-item" *hasPermissions='[permissionType.CreateClients]'>
                                        <button class="btn btn-lg bg-blue text-white"
                                            [routerLink]="['/setting/finance/new-tracking-category']">
                                            <i class="align-middle fas fa-plus-circle"></i> New Tracking Category
                                        </button>
                                    </li> -->
                                    <!-- <li class="filter-item">
                                        <div class="btn-group dropleft dropleft-btn">
                                            <div ngbDropdown class="d-inline-block">
                                                <button class="btn btn-lg   btn btn-danger"
                                                    id="dropdownBasic1">Delete</button>
                                            </div>
                                        </div>
                                    </li> -->



                                </ol>
                            </nav>
                        </div>


                    </div>




                    <iframe [src]='sanitizer.bypassSecurityTrustResourceUrl(mapsURL)' width="100%" height="515px"
                        frameborder="0" allowfullscreen webkitallowfullscreen mozallowfullscreen>
                    </iframe>





                </div>

            </div>
        </div>
    </div>
</main>