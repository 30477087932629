import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import {
  Validators,
  FormBuilder,
  FormArray,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { CustomFieldCaseCategories } from 'src/app/helpers/enums/settings/customField/CustomFieldCaseCategories';
import { CustomFieldModel } from 'src/app/models/custom-fields/CustomFieldModel';
import { AddSubjectTypeModel } from 'src/app/models/subjectTypes/addSubjectTypeModel';
import { CasetypeService } from 'src/app/services/casetype.service';
import { CustomfieldService } from 'src/app/services/customfield.service';
import { ModalService } from 'src/app/services/modal.service';
import { SubjectTypeService } from 'src/app/services/subjecttype.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';

@Component({
  selector: 'app-new-case-subject',
  templateUrl: './new-case-subject.component.html',
  styleUrls: ['./new-case-subject.component.css'],
})
export class NewCaseSubjectComponent implements OnInit {
  @BlockUI('new-case-subject') blockUI: NgBlockUI;
  form: FormGroup;
  submitted = false;
  customSubject: CustomFieldModel[] = [];
  model: AddSubjectTypeModel;
  totalRows = 1;
  get caseTypesToFormArray(): FormArray {
    return this.form.controls.case_types as FormArray;
  }

  constructor(
    private formBuilder: FormBuilder,
    private caseTypeService: CasetypeService,
    private toasterService: ToasterService,
    private subjectTypeService: SubjectTypeService,
    private router: Router,
    private modalService: ModalService,
    private customFieldService: CustomfieldService
  ) {
    this.model = new AddSubjectTypeModel();
  }
  loadSubjectCustomFields(): void{
    this.customFieldService.getByUserForId(CustomFieldCaseCategories.Subject).subscribe((data) => {
      if (data && data.length !== this.customSubject.length){
      data.forEach((element, index) => {
        if (this.customSubject.filter(x => x.id === element.id).length === 0){
          this.customSubject.splice(index, 0 , element);
        }
      });
      }
    }, (error: any) => {
       this.toasterService.ProcessErrorResponse(error);
    });
  }
  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', [Validators.required]),
      description: new FormControl(),
      case_types: new FormArray([], minSelectedCheckboxes()),

      customSubjectFields: this.formBuilder.array([]),

    });
    this.loadDefaultData();
  }

  // dynamic rows
  // new field row
  get formArr() {
    return this.form.get('customSubjectFields') as FormArray;
  }
  addNewRows(): void {
    this.formArr.push(this.initRows());
  }

  deleteRow(index: number): void {
    this.formArr.removeAt(index);

  }
  initRows() {
    return  this.formBuilder.group({
      id: 0,
      customSubjectId: [this.customSubject.length > 0 ? this.customSubject[0].id : '', [RxwebValidators.unique(), Validators.required]],
      highlightCheck: [false, Validators.required],
      crossCheck: [false, Validators.required],
      searchCheck: [false, Validators.required],
      reopenCheck: [true, Validators.required],
      isInternal: this.customSubject.length > 0 ? this.customSubject[0].internal : false
    });
  }
  OnCustomFieldChange(event): void{
    const customsubjectValue = this.customSubject.filter(x => x.id == event.target.value)[0];
    const control = this.form.controls.customSubjectFields.value.map(x => x.customSubjectId).indexOf(event.target.value);
    this.formArr.at(control).get('isInternal').setValue(customsubjectValue.internal);
  }

  private async loadDefaultData(): Promise<void> {
    this.blockUI.start();
    forkJoin([this.caseTypeService.getSelectListItem(),
       this.customFieldService.getByUserForId(CustomFieldCaseCategories.Subject)]).subscribe(
      ([caseTypesdata, customFieldSubjectAll]) => {
        this.customSubject = customFieldSubjectAll;
        // loading case types
        const ct: FormArray = this.caseTypesToFormArray;
        ct.clear();
        caseTypesdata.forEach((d) => {
          ct.push(
            this.formBuilder.group({
              id: [d.id],
              name: [d.value],
              isSelected: [false],
            })
          );
        });
        this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(
          error,
          'Something went wrong!'
        );
        this.blockUI.stop();
      }
    );
  }
  onSubmit(): void {
    ;
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    const controls = this.form.controls;
    this.model.id = 0;
    this.model.subjectTypeCustomFields = [];
    this.model.name = controls.name.value;
    this.model.description = controls.description.value;
    this.model.caseTypeIds = this.form.value.case_types
      .filter((x) => x.isSelected)
      .map((x) => x.id);
    controls.customSubjectFields.value.map((x, index) => {
        this.model.subjectTypeCustomFields.push({
          id: 0,
          subjectTypeId: 0,
          customFieldId: Number(x.customSubjectId),
          isReopenable: x.reopenCheck,
          isHighlighted: x.highlightCheck,
          isCrossReference: x.crossCheck,
          isSearchable: x.searchCheck,
          sequence: index + 1
        });
      });
    this.subjectTypeService.post(this.model).subscribe(
      (resposne) => {
        setTimeout(() => {
          this.onCancel();
        }, 10);
        setTimeout(() => {
          this.toasterService.success('Subject type created successfully');
          this.onCancel();
        }, 300);
        this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }

  onCancel(): void {
    this.router.navigate(['/setting/cases/subject-type-list']);
  }
  openCustomFieldPopup(): void{
    this.modalService.openNewCustomFieldPopup(CustomFieldCaseCategories.Subject).subscribe((data: any) => {
    if (data){
         this.loadSubjectCustomFields();
      }
    }, (error: any) => {
      this.toasterService.ProcessErrorResponse(error);
    });
   }

   onDrop(event: CdkDragDrop<string[]>): void{
     const prevValue  = this.formArr.at(event.previousIndex);
     this.deleteRow(event.previousIndex);
     this.formArr.insert(event.currentIndex, prevValue);
  }
  addRowAtIndex(index: number): void {
    this.formArr.insert(index + 1, this.initRows());
  }
}
