<div class="row">
  <div class="col-12">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0 float-right">
        <li
          class="filter-item px-0 filter-icon"
          (click)="filterNavStatus = true"
        >
          <button class="btn btn-lg bg-blue text-white">
            <i class="align-middle fas fa-filter"></i>
          </button>
        </li>
      </ol>
    </nav>

    <!-- Filter right sidenav -->
    <app-history-filter-and-search
      (onApplyFilter)="applyFilter($event)"
      (resetFilters)="onResetFilters()"
      [filterStatusParent]="filterNavStatus"
      (closeBtnFilterStatus)="closeFilterEvent($event)"
    ></app-history-filter-and-search>
  </div>

  <div class="col-md-12" *blockUI="'history-component-overview'">
    <table
      class="table table-striped neu-table w-100 table-responsive"
      datatable
      [dtOptions]="dtOptions"
      data-page="2"
    ></table>
  </div>
</div>
