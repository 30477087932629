<main class="content py-0">

    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12 px-0 pb-5">
                            <div class="col-auto mt-n1">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb bg-transparent custom-breadcrumb p-0 mt-1 mb-0">
                                        <li class="breadcrumb-item"><a routerLink="/case-list">Cases</a></li>
                                        <!-- <li class="breadcrumb-item"><a href="case_list_detail.html">Case List</a></li> -->
                                        <li class="breadcrumb-item active" aria-current="page">New Case</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto d-none d-sm-block mb-3">
                            <h3 class="page-title"><strong>New Case</strong> </h3>
                            <p>Create new case detail here.</p>
                        </div>
                    </div>

                    <div class="row mx-0" *blockUI="'add-case-supporting-files'" >
                        <div class="col-12 px-0 mb-5">
                            <form  class="new-case-form"  name="basicform" id="basicform" method="post" action="" novalidate="novalidate"
                            [formGroup]="form" (ngSubmit)="onSubmit()" class="new-case-form">

                                <div class="frm">
                                    <fieldset>
                                        <legend>
                                            <h3 class="text-orange title-legend"> Supporting Files</h3>
                                        </legend>
            
                                        <div class="row mx-0" >
                                            <div class="col-12 px-0 mb-5">
                                               
                                                    <div class="row ">
                                                        <div class="col-12">
                                                            <div class="card flex-fill w-100 mb-4">
                                                                <div class="card-header rounded p-4 mob-px-4">
                                                                   
                                                                </div>
                                                                <div class="card-body">
                                                                    
                                                                    <div class="row">
                                                                    <div class="col-md-12">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="form-group has-search">
                                                                                    <label for="caseAccessgroup">Access group<span
                                                                                            class="clsStric">*</span></label>
                                                                                    <div class="input-group position-relative">
                                                                                      
                            
                                                                                        <select class="form-control" id="accessGroupId" formControlName="accessGroupId" >
                                                                                            <option value="" selected></option>
                                                                                            <option *ngFor="let agsi of accessGroupSelectedListItem" [value]="agsi.id" >{{agsi.value}}</option>
                                                                                        </select>
                                                                                       
                                                                                    </div>
                                                                                    <div class="mt-3" *ngIf="submitted && form.controls.accessGroupId.errors && form.controls.accessGroupId.errors.required"
                                                                                    class="text-danger">
                                                                                    Access group is required!
                                                                                </div>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-12 mt-2">
                                                                                <div class="form-group has-search">
                                                                                    <label for="caseAccessgroup">Files<span
                                                                                            class="clsStric">*</span></label>
                                                                                    <div class="input-group position-relative">
                                                                                        <ng-container>
                                                                                            <input type="file" id="file" multiple 
                                                                                                (change)="getFileDetails($event)">
                                                                                    
                                                                                            <!-- <button class="btn " (click)="uploadFiles()">Upload</button> -->
                                                                                        </ng-container>
                    
                    
                                                                                    </div>
                                                                                    <div class="mt-3" *ngIf="submitted && myFiles.length == 0"
                                                                                    class="text-danger">
                                                                                    Atleast one file is required!
                                                                                </div>
                                                                                    <div class=" files-data mt-3">
                                                                                        <div *ngIf="myFiles?.length">
                                                                                            <ul class="list-group"  *ngFor="let file of myFiles; ;let i=index">
                                                                                                <li class="list-group-item d-flex justify-content-between align-items-center">
                                                                                                    
                                                                                                    <span  class="d-flex align-items-center">
                                                                                                        <span [innerHTML]="appUtils.genrateIconExtension(file.name)"></span>
                        
                                                                                                        <span>
                                                                                                            {{file.name}}  {{file.type}}
                                                                                                        </span>
                                                                                                        
                                                                                                    </span>
                                                                                                    
                        
                                                                                                    <span class="d-flex align-items-center">
                                                                                                        <span class="badge badge-primary badge-pill">
                                                                                                            {{file.size/1024 | number: '1.0-0'}} kb
                                                                                                        </span>
                                                                                                        <span class="badge pl-2 text-danger" (click)="removefile(i)">
                                                                                                            <i class="fa fas fa-minus-circle"></i>
                                                                                                        </span>
                                                                                                    </span>
                                                                                                </li>
                                                                                              </ul>
                    
                                                                                        </div>
                                                                                          
                                                                                           
                    
                                                                                        <div *ngIf="!myFiles?.length" class="d-flex align-items-center align-middle empty-data h-100">
                                                                                        
                                                                                            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
                                                                                                y="0px" width="462.035px" height="46.035px" viewBox="0 0 462.035 462.035"
                                                                                                style="enable-background:new 0 0 462.035 462.035;     fill: #84a8d4" xml:space="preserve">
                                                                                                <g>
                                                                                                    <path d="M457.83,158.441c-0.021-0.028-0.033-0.058-0.057-0.087l-50.184-62.48c-0.564-0.701-1.201-1.305-1.879-1.845
                                                                                                c-2.16-2.562-5.355-4.225-8.967-4.225H65.292c-3.615,0-6.804,1.661-8.965,4.225c-0.678,0.54-1.316,1.138-1.885,1.845l-50.178,62.48
                                                                                                c-0.023,0.029-0.034,0.059-0.057,0.087C1.655,160.602,0,163.787,0,167.39v193.07c0,6.5,5.27,11.771,11.77,11.771h438.496
                                                                                                c6.5,0,11.77-5.271,11.77-11.771V167.39C462.037,163.787,460.381,160.602,457.83,158.441z M408.516,134.615l16.873,21.005h-16.873
                                                                                                V134.615z M384.975,113.345v42.274H296.84c-2.514,0-4.955,0.805-6.979,2.293l-58.837,43.299l-58.849-43.305
                                                                                                c-2.023-1.482-4.466-2.287-6.978-2.287H77.061v-42.274H384.975z M53.523,155.62H36.65l16.873-21.005V155.62z M438.498,348.69H23.54
                                                                                                V179.16h137.796l62.711,46.148c4.15,3.046,9.805,3.052,13.954-0.005l62.698-46.144h137.799V348.69L438.498,348.69z" />
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                                <g>
                                                                                                </g>
                                                                                            </svg>
                                                                                        
                                                                                        </div>
                                                                                    </div>
                                                                                    <hr>
                    
                                                                                    <div class="col-12 floating-detail" *ngIf="false">
                                                                                      69.95% Full / Using 2.5TB of your 2.9TB
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                      
                    
                                                                      
                                                                    </div>
                    
                                                                </div>
                                                                </div>
                                                            </div>
                                                           
                                                        </div>
                                                    
                                                    </div>

                                                    <div class="form-group">
                                                        <div class="col-lg-12 text-right">
                                                            <button
                                                                class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn back2"
                                                                type="button" (click)="onSubmit()"> <span
                                                                    class="fa fa-arrow-left"></span> Upload Files</button>
                                                            <button
                                                                class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2 open2"
                                                                type="button" (click)="NavigateToCaseSummary()">Skip this step <span
                                                                    class="fa fa-arrow-right"></span></button>
                                                        </div>
                                                    </div>
                                                    
                                                
                                                <!-- {{form.value | json}} -->
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    </div>
              
                   

                    
                </div>
            </div>
        </div>
    </div>
</main>
