import { Component, OnInit } from '@angular/core';
import {
  Validators,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { AddCaseStatusModel } from 'src/app/models/case-status/addCaseStatusModel';
import { CaseStatusViewModel } from 'src/app/models/case-status/caseStatusViewModel';
import { CasestatusService } from 'src/app/services/casestatus.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-new-case-status',
  templateUrl: './new-case-status.component.html',
  styleUrls: ['./new-case-status.component.css'],
})
export class NewCaseStatusComponent implements OnInit {
  @BlockUI('container-blockui-new-folder-template') blockUI: NgBlockUI;

  form: FormGroup;
  submitted = false;
  model: AddCaseStatusModel;
  caseStatusesList: CaseStatusViewModel[] = [];
  rankList: {id: string, name: string}[] = [];
  constructor(
    private caseStatusService: CasestatusService,
    private toasterService: ToasterService,
    private router: Router,
  ) {
    this.model = new AddCaseStatusModel();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      colors: new FormControl(null, Validators.required),
      rank_below: new FormControl('0', Validators.required), // default casetypeId is null and rank is 0
      description: new FormControl(''),
      webhookURL: new FormControl(''),
      is_active: new FormControl(false),
      is_monitor_due_date: new FormControl(false),
      is_reopenable: new FormControl(false),
    });
    this.loadDefaults();
  }
  loadDefaults(): void {
    this.blockUI.start();
    this.caseStatusService.getAll().subscribe(
      (data: CaseStatusViewModel[]) => {
        this.caseStatusesList = []; // .reset dropdown
        this.caseStatusesList = data;
        this.rankList = this.caseStatusesList.map(x => ({id: x.rank.toString() , name: x.name }));
        this.blockUI.stop();
      },
      (error: any) => {
        this.blockUI.stop();
        this.toasterService.ProcessErrorResponse(error);
      }
    );
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }

    this.model.id = 0;
    const controls = this.form.controls;
    this.model.name = controls.name.value;
    this.model.description = controls.description.value;
    this.model.color = controls.colors.value;
    this.model.webhookUrl = controls.webhookURL.value;
    this.model.isActive = controls.is_active.value;
    this.model.isMonitorDueDate = controls.is_monitor_due_date.value;
    this.model.isReopenable = controls.is_reopenable.value;
    this.model.rank = Number(controls.rank_below.value) + 1;
    this.caseStatusService.post(this.model).subscribe(
      (resposne) => {
        setTimeout(() => {
          this.onCancel();
        }, 10);
        setTimeout(() => {
          this.toasterService.success('Case status created successfully');
          this.onCancel();
        }, 300);
        this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }
  onCancel(): void {
    this.router.navigate(['/setting/cases/case-status-list']);
  }
}
