import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';

import { Router } from '@angular/router';

// for datatable
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-case-detail-expenses',
  templateUrl: './case-detail-expenses.component.html',
  styleUrls: ['./case-detail-expenses.component.css']
})
export class CaseDetailExpensesComponent implements OnInit {




  // for angular datatable
  @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;
  dtRendered = true;
  visibleColumns = [];
  isDtVisible = false;
  isDtInitialized = false;

  // for filters
  private searchBy?: number;
  private searchFor: string;
  private filterByOptionId = 0;
  private filterByItemId: string;

  get filterByOption(): number {
    return this.filterByOptionId;
  }

  users: any = [];
  isBulkActionDisabled = true;

  // capture filter open and close events
  filterNavStatus = false;
  closeFilterEvent(event: any): void {
    this.filterNavStatus = event;
  }


  ngOnInit(): void {
    this.initDataTable();
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtInstance = dtInstance;
      const header = dtInstance.columns().header();
      $(header).find('[action-type="select-unSelect-all"]')
        .off('change').on('change', (e: any) => {
          this.onSelectAllRow(e.target.checked);
        });
    });
  }


  constructor(private clientService: ClientService,
    private tosterService: ToasterService, private renderer: Renderer2, private router: Router) { }

  private initDataTable(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },

      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,
      order: [[0, 'asc']],
      ajax: (dataTablesParameters: any, callback) => {

        self.blockUI.start();
        self.clientService.getClients(dataTablesParameters)
          .subscribe(resp => {
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            self.blockUI.stop();
          });
      },

      columns: [

        {
          data: 'name',
          title: 'Name',
          render: function (data, type, row) {

            return `
                <a  detail-person-id="${row.id}" class='link m-r-10' action-type='link-detail'>${row.name}</a>
                `;
          }
        },
        {
          data: 'accountCode',
          title: 'Account Code'
        },
        {
          data: 'salesPersonName',
          title: 'Sales Person',
          orderable: false
        },
        {
          data: 'caseManagerName',
          title: 'Case Manager',
          orderable: false
        },
        {
          data: 'locationCount',
          title: 'Locations',
          orderable: false
        },
        {
          data: 'contactCount',
          title: 'Contacts',
          orderable: false,
          visible: false
        },
        {
          data: 'affiliateCount',
          title: 'Affiliates',
          orderable: false,
          visible: false
        },
        {
          data: 'creationDate',
          title: 'Created',
          orderable: false,
        },
        {
          data: 'name',
          title: 'Action ',
          orderable: false,
          render: function (data, type, row) {
            return `<div class='fa-edit'>
                <button  class='btn del-btn btn-lg btn-table'  >
                <i  view-person-id="${row.id}" class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
                </button>

                <button  class='btn del-btn btn-lg btn-table'   >
                <i del-person-id="${row.id}"  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
                </button>
                </div>
                `;
          }
        },
      ],
      // Use this attribute to enable the responsive extension
      responsive: true,
    };

  }




  // for filters
  applyFilter(filterData: any): void {
    this.searchBy = Number(filterData.searchBy);
    this.searchFor = filterData.searchFor;

    const filterIdArr = filterData.filter?.split('_');
    if (filterIdArr && filterIdArr.length > 0) {
      this.filterByOptionId = Number(filterIdArr[0]);
      if (filterIdArr.length > 1) {
        this.filterByItemId = filterIdArr[1];
      }
    }
    this.reloadDataTable();
  }



  private reloadDataTable(): void {
    this.dtInstance.ajax.reload();
  }

  onResetFilters(): void {
    this.searchBy = null;
    this.searchFor = '';
    this.filterByOptionId = 0;
    this.filterByItemId = '';
    this.reloadDataTable();
  }

  delete(ids: number[]): void {
    if (!confirm('Are you sure you want to delete selected client(s)?')) {
      return;
    }
    this.blockUI.start();
    this.clientService.deleteClient(ids).subscribe(() => {
      this.reloadDataTable();
      this.tosterService.success('Client(s) has been deleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  unDelete(ids: number[]): void {
    if (!confirm('Are you sure you want to undelete selected client(s)?')) {
      return;
    }
    this.blockUI.start();
    this.clientService.undeleteClient(ids).subscribe(() => {
      this.reloadDataTable();
      this.tosterService.success('Client(s) has been undeleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  onSelectAllRow(isChecked: boolean): void {
    const rows = this.dtInstance.rows().nodes();
    const checkBoxes = [...rows.$('[action-type= "select-row"]')];
    if (isChecked) {
      checkBoxes.forEach(row => {
        this.renderer.addClass(row, 'checked');
        (row as HTMLInputElement).checked = true;
      });
    } else {
      checkBoxes.forEach(row => {
        this.renderer.removeClass(row, 'checked');
        (row as HTMLInputElement).checked = false;
      });
    }
    this.enableOrDisableDeleteButton();
  }
  private enableOrDisableDeleteButton(): void {

    const rows = this.dtInstance.rows().nodes();
    this.isBulkActionDisabled = [...rows.$('.checked')].length === 0;
  }




}
