<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>User Security Settings</strong> </h3>
                        </div>
                    </div>


                    <div class="row mx-0" *blockUI="'container-blockui-new-user-security-setting'">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form"  [formGroup]="form" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
                                          
                                            <div class="card-body">
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6">

                                                                
                                                              
                                                                    <div class="custom-control custom-switch">
                                                                        <input type="checkbox" formControlName="enable_multi_factor_auth" class="custom-control-input" id="enable_multi_factor_auth">
                                                                        <label class="custom-control-label"  for="enable_multi_factor_auth"><b>Enable Multi-Factor Authentication</b> <sup class="clsStric">*</sup>    <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="If enabled, users have the option to enhance their security by requiring a registered authentication device to login."></i></label>
                                                                    </div>
    
                                                               
                                                            </div>
                                                            <div class="col-md-6"></div>
                                                        </div>

                                                        <hr>

                                                        <div class="row">

                                                            
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="minimum_length">Minimum Password Length<sup class="clsStric">*</sup>  <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="The minimum number of characters required for a valid password."></i></label>
                                                                    
                                                                    <input type="number" min="0" formControlName="minimum_length" class="form-control mb-3" id="minimum_length" name="minimum_length" autocomplete="off">
                                                                    <div *ngIf="submitted && form.controls.minimum_length.errors">
                                                                        <div *ngIf="submitted && form.controls.minimum_length.errors.required" class="text-danger">
                                                                                Minimum password length is required
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="submitted && form.controls.minimum_length.errors">
                                                                        <div *ngIf="submitted && form.controls.minimum_length.errors.pattern" class="text-danger">
                                                                                Value must be greather than or equal to 0
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="user_password_expiry">User Password Expiration (Days)<sup class="clsStric">*</sup> <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="The number of days before the system will require a user to change their password. Set to 0 to disable this feature."></i></label>
                                                                    
                                                                    <input type="number" min="0" formControlName="user_password_expiry" class="form-control mb-3" id="user_password_expiry" name="user_password_expiry" autocomplete="off">
                                                                    <div *ngIf="submitted && form.controls.user_password_expiry.errors">
                                                                        <div *ngIf="submitted && form.controls.user_password_expiry.errors.required" class="text-danger">
                                                                            User password expiration (Days) is required
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.user_password_expiry.errors">
                                                                            <div *ngIf="submitted && form.controls.user_password_expiry.errors.pattern" class="text-danger">
                                                                                    Value must be greather than or equal to 0
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>


                                
                                  
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button" (click)="onCancel()"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="submit" (click)="onSubmit()">Save Settings <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
