import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ComponentDestoryHelper } from 'src/app/helpers/component-destory.helper';
import { EditCompanyLocationModel } from 'src/app/models/company-location/editCompanyLocationModel';
import { CountryModel } from 'src/app/models/shared/country.model';
import { StateModel } from 'src/app/models/shared/state.model';
import { CompanyLocationService } from 'src/app/services/company-location.service';
import { CountryService } from 'src/app/services/country.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-edit-company-location',
  templateUrl: './edit-company-location.component.html',
  styleUrls: ['./edit-company-location.component.css']
})
export class EditCompanyLocationComponent extends ComponentDestoryHelper implements OnInit, OnDestroy {

  @BlockUI('blockui-edit-company-location-ui') blockUI: NgBlockUI;
  model: EditCompanyLocationModel;
 form: FormGroup;
 submitted = false;
 countrySelectListItem: CountryModel[] = [];
 stateSelectListItem: StateModel[] = [];
isPrimaryVisible = false;
  constructor(private router: Router, private route: ActivatedRoute, private companyLocationService: CompanyLocationService,
              private toasterService: ToasterService, private countryService: CountryService) {
                super();
                this.model = new EditCompanyLocationModel();
                this.route.params.subscribe((params) => {
                  this.model.id = +params.id;
                 });
  }
  ngOnDestroy(): void {
    this.ngAfterOnDestroy();
  }

  ngOnInit(): void {
    this.loadCountries();
    this.loadCompanyLocation();
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      make_primary: new FormControl('false'),
      country: new FormControl(null, Validators.required),
      city: new FormControl(),
      address1: new FormControl(),
      address2: new FormControl(),
      address3: new FormControl(),
      state: new FormControl(),
      zip_code: new FormControl(),
      primaryPhone: new FormControl(),
      secondaryPhone: new FormControl(),
      fax: new FormControl(),
      billing_address: new FormControl()
    });
  }
  private loadCompanyLocation(): void{
    this.blockUI.start();
    const subscription = this.companyLocationService.get(this.model.id).subscribe((data) => {
      if (data == null)
      {
        this.router.navigate(['/error/404']);
      }
      this.form.controls.name.setValue(data.name);
      this.form.controls.make_primary.setValue(data.isPrimary.toString());
      this.form.controls.country.setValue(data.country);
      this.form.controls.city.setValue(data.city);
      this.form.controls.address1.setValue(data.address1);
      this.form.controls.address2.setValue(data.address2);
      this.form.controls.address3.setValue(data.address3);
      this.form.controls.state.setValue(data.state);
      this.form.controls.zip_code.setValue(data.zipCode);
      if (data.primaryPhone){
      this.form.controls.primaryPhone.setValue(data.primaryPhone);
      }
      if (data.secondaryPhone) {
      this.form.controls.secondaryPhone.setValue(data.secondaryPhone);
      }
      if (data.faxPhone){
      this.form.controls.fax.setValue(data.faxPhone);
      }
      this.form.controls.billing_address.setValue(data.billingAddress);
      if (data.isPrimary){
        this.isPrimaryVisible = true;
      }
      this.loadStates(data.country);
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
    this.subscriptions.push(subscription);
  }
  private loadCountries(): void {
    this.blockUI.start();
    const subscription = this.countryService.getCountries().subscribe((data: any) => {
      this.countrySelectListItem = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
    this.subscriptions.push(subscription);
  }

  loadStates(name: any): void {
    const id: any = this.countrySelectListItem.find(x => x.name === name)?.id ?? undefined;
    if (id === null || id === undefined || id.length === 0) {
      return;
    }

    this.blockUI.start();
    const subscription =  this.countryService.getStates(id).subscribe((data: any) => {
      this.stateSelectListItem = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
    this.subscriptions.push(subscription);
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    const controls  = this.form.controls;
    this.model.isPrimary = false;
    this.model.name = controls.name.value;
    this.model.address1 = controls.address1.value;
    this.model.address2 = controls.address2.value;
    this.model.address3 = controls.address3.value;
    this.model.country = controls.country.value;
    this.model.state = controls.state.value;
    this.model.city = controls.city.value;
    this.model.zipCode = controls.zip_code.value;
    this.model.primaryPhone = controls.primaryPhone.value;
    this.model.secondaryPhone = controls.secondaryPhone.value;
    this.model.faxPhone = controls.fax.value;
    this.model.billingAddress = controls.billing_address.value;
    if (controls.make_primary.value === 'true')
    {
      this.model.isPrimary = true;
    }
    this.blockUI.start();
    const subscription = this.companyLocationService.put(this.model).subscribe(resposne => {
      setTimeout(() => {
       this.onCancel();
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Company location updated successfully');
      }, 300);
      this.blockUI.stop();
    },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
    this.subscriptions.push(subscription);
  }
  onCancel(): void {
    this.router.navigate(['/setting/global/company-location-list']);
  }
}
