<main class="content py-0">
  <div class="container-fluid p-0">
      <div class="row">
          <div class="col-md-12 pt-7">
              <div class="w-100 pt-4">
                  <div class="row">
                      <div class="col-md-12">
                          <app-breadcrumb></app-breadcrumb>
                      </div>
                  </div>

                  <div class="row mt-4 mb-4">
                      <div class="col-auto d-none d-sm-block">
                          <h3 class="page-title"><strong>New Case Status</strong> </h3>
                      </div>
                  </div>


                  <div class="row mx-0">
                      <div class="col-12 px-0 mb-5">
                          <form class="new-case-form" name="form"  [formGroup]="form" >
                              <div class="row">
                                  <div class="col-md-12">
                                      <div class="card" >
                                        
                                          <div class="card-body">
                                            
                                            <div class="card-text">

                                              <div class="row">

                                                  <div class="col-md-12">

                                                      <div class="row">
                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="name">Name<sup class="clsStric">*</sup></label>
                                                                  
                                                                  <input type="text" formControlName="name" class="form-control mb-3" id="name" name="name" autocomplete="off">
                                                                  <div *ngIf="submitted && form.controls.name.errors && form.controls.name.errors.required"
                                                                  class="text-danger">
                                                                  Name is required!
                                                              </div>
                                                              </div>
                                                          </div>

                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="colors">Colors<sup class="clsStric">*</sup> <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="Choose a color that black text reads well on."></i></label>
                                                                  
                                                                  <input type="color" formControlName="colors" class="form-control mb-3  form-control-color" id="colors" name="colors" autocomplete="off">
                                                                  <div *ngIf="submitted && form.controls.colors.errors && form.controls.colors.errors.required"
                                                                  class="text-danger">
                                                                  Colors is required!
                                                              </div>
                                                              </div>
                                                          </div>
                                                      </div>


                                                      <div class="row">
                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="name">Rank Below<sup class="clsStric">*</sup></label>
                                                                  
                                                                  <select class="form-control" formControlName="rank_below">
                                                                      <option value="0"></option>
                                                                      <option [value]="item.id" *ngFor="let item of rankList; let i=index">
                                                                          {{item.name}}
                                                                      </option>
                                                                  </select>
                                                              </div>
                                                          </div>

                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="webhookURL">Webhook URL <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="A webhook allows you to send a JSON record of your case to the specified website when it reaches this status."></i></label>
                                                                  
                                                                  <input type="text" formControlName="webhookURL" class="form-control mb-3  form-control-color" id="webhookURL" name="webhookURL" autocomplete="off">
                                                              </div>
                                                          </div>
                                                      </div>

                                                      <div class="row">

                                                          <div class="col-md-6">
                                                              <div class="form-group has-search">
                                                                  <label for="description">Description</label>
                                                                  
                                                                  <input type="text" formControlName="description" class="form-control mb-3" id="description" name="description" autocomplete="off">
                                                              </div>
                                                          </div>

                                                          <div class="col-md-12">
                                                              <hr>
                                                          </div>
                                                          <div class="col-md-6 mb-2">

                                                              <div class="custom-control custom-switch">
                                                                  <input type="checkbox" formControlName="is_monitor_due_date" class="custom-control-input" id="is_monitor_due_date">
                                                                  <label  class="custom-control-label"  for="is_monitor_due_date"><b>Monitor Due Date</b><i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="If checked, the system will monitor the case due dates in this status."></i></label>
                                                              </div>
                                                              
                                                          </div>

                                                          <div class="col-md-6 mb-2">

                                                              <div class="custom-control custom-switch">
                                                                  <input type="checkbox" formControlName="is_active" class="custom-control-input" id="is_active">
                                                                  <label class="custom-control-label"  for="is_active"><b>Set as "Active"</b><i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="If checked, the system will monitor the case due dates in this status."></i></label>
                                                              </div>

                                                             
                                                          </div>

                                                          <div class="col-md-6 mb-2">
                                                              <div class="custom-control custom-switch">
                                                                  <input type="checkbox" formControlName="is_reopenable" class="custom-control-input" id="is_reopenable">
                                                                  <label  class="custom-control-label"  for="is_reopenable"><b>Set as "Reopenable"</b><i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="In order to reopen a case, all cases in the series (related cases) must be in a status that is 'Reopenable'"></i></label>
                                                              </div>
                                                              
                                                          </div>


                                                       
                                                      </div>


                                                  </div>
                                              </div>
                                            </div>
                                          </div>
                                      </div>
                                  </div>

                                  <div class="form-group w-100">
                                      <div class="col-lg-12 text-right">
                                          <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  (click)="onCancel()"> Cancel</button> 
                                          <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="submit" (click)="onSubmit()">Save Case Status<span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</main>
