import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CaseStatusTriggerModel } from '../models/case-status-trigger/caseStatusTriggerModel';

@Injectable({
  providedIn: 'root'
})
export class CaseStatusTriggerService {

  constructor(private http: HttpClient) { }
  public get(): Observable<CaseStatusTriggerModel>{
    return this.http.get<CaseStatusTriggerModel>(`${environment.apiBaseUrl}/casestatustrigger/get`);

  }
  public AddOrUpdate(model: CaseStatusTriggerModel): Observable<string> {
    return this.http.post<string>(`${environment.apiBaseUrl}/casestatustrigger/post`, model, {});
  }
}
