import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Page } from 'src/app/helpers/enums/customize-column/page';
import { CustomizedColumnService } from 'src/app/services/customizedColumn.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-customized-list',
  templateUrl: './customized-list.component.html',
  styleUrls: ['./customized-list.component.css']
})
export class CustomizedListComponent implements OnInit {

  constructor(
    private customizeColumnService: CustomizedColumnService,
    private ngbActiveModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService) { }
  @Input() public moduleId: Page;
  @Output() saveClick = new EventEmitter();
  selectedColumnIds: number[];
  pageSizes = environment.paging.lengthMenu;
  form: FormGroup;
  @BlockUI('container-blockui-cutomize-list') blockUI: NgBlockUI;

  get columns(): FormArray {
    return this.form.controls.columns as FormArray;
  }
  get activeModal(): any {
    return this.ngbActiveModal;
  }
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      columns: new FormArray([], minSelectedCheckboxes()),
      pageSize: ['', [Validators.required]],
    });
    this.loadData();
  }

  private loadData(): void {
    this.blockUI.start();
    this.customizeColumnService.getById(this.moduleId).subscribe(response => {
      const fa: FormArray = this.columns;
      fa.clear();
      response.columns.forEach(d => {
        fa.push(this.formBuilder.group({
          id: [d.column],
          name: [d.name],
          isSelected: [d.isSelected]
        }));
      });
      this.form.controls.pageSize.setValue(response.pageSize);
      this.blockUI.stop();

    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  submit(): void {
    if (this.form.invalid) {
      this.toasterService.error('Please selected atleast one column');
      return;
    }

    this.SaveCustomizedList();
  }

  private SaveCustomizedList(): void {
    this.selectedColumnIds = this.form.value.columns.filter(x => x.isSelected).map(x => x.id);
    this.blockUI.start();
    this.customizeColumnService.patch(this.moduleId, this.selectedColumnIds, this.form.value.pageSize).subscribe((data: any) => {
      this.saveClick.emit();
      this.activeModal.dismiss('save click');
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

}
