import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray, FormControl, FormGroup } from '@angular/forms';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { AddCaseServiceModel } from 'src/app/models/caseservice/add.case-service.model';
import { CaseServicesService } from 'src/app/services/caseservice.service';
import { RoleService } from 'src/app/services/role.service';
import { ToasterService } from 'src/app/services/toater.service';
import { CasetypeService } from 'src/app/services/casetype.service';
import { CaseServiceViewModel } from 'src/app/models/caseservice/case-service-view-model';


@Component({
  selector: 'app-new-case-service',
  templateUrl: './new-case-service.component.html',
  styleUrls: ['./new-case-service.component.css']
})
export class NewCaseServiceComponent implements OnInit {
  @BlockUI('container-blockui-new-case-service') blockUI: NgBlockUI;

  addCaseserviceModel: AddCaseServiceModel;

  constructor(private formBuilder: FormBuilder,
              private router: Router,
              private toasterService: ToasterService,
              private caseServiceService: CaseServicesService,
              private caseTypeService: CasetypeService) {
 this.addCaseserviceModel = new AddCaseServiceModel();
  }


  form: FormGroup;
  submitted = false;
  isSelected = false;

  get caseTypesToFormArray(): FormArray{
    return this.form.controls.case_types as FormArray;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      case_types: new FormArray([], minSelectedCheckboxes())
    });
    this.loadDefaultData();
  }
  private async loadDefaultData(): Promise<void> {
    this.blockUI.start();
    forkJoin([this.caseTypeService.get()]).subscribe(
      ([caseTypesdata]) => {
       // loading case types
       const ct: FormArray = this.caseTypesToFormArray;
       ct.clear();
       caseTypesdata.forEach((d) => {
         ct.push(
           this.formBuilder.group({
             id: [d.id],
             name: [d.name],
             isSelected: [false],
           })
         );
       });
       this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(
          error,
          'Something went wrong!'
        );
        this.blockUI.stop();
      }
    );
  }
  // tslint:disable-next-line: typedef
  onSubmit() {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.addCaseserviceModel.name = this.form.controls.name.value;
    this.addCaseserviceModel.description = this.form.controls.description.value;
    this.addCaseserviceModel.caseTypeIds = this.form.value.case_types.filter(x => x.isSelected).map(x => x.id);
    this.blockUI.start();
    this.caseServiceService.post(this.addCaseserviceModel).subscribe(resposne => {
      setTimeout(() => {
        this.router.navigate(['/setting/cases/services-list']);
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Case Service created successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });

  }


}

