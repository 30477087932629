import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { concat, Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, switchMap } from 'rxjs/operators';
import { ClientAddModel } from 'src/app/models/client/client.model';
import { ClientResponseModel } from 'src/app/models/client/clientResponse.model';
import { ClientService } from 'src/app/services/client.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-edit-client',
  templateUrl: './edit-client.component.html',
  styleUrls: ['./edit-client.component.css']
})
export class EditClientComponent implements OnInit {


  constructor(private router: Router,
              private route: ActivatedRoute,
              private formBuilder: FormBuilder,
              private clientService: ClientService,
              private tosterService: ToasterService, ) {
      this.model = new ClientAddModel();
      this.route.params.subscribe(params => this.model.id = params.id);
     }

  form: FormGroup;
  submitted = false;
  returnUrl: string;
  model: ClientAddModel;

  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab' },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];
  tags$: Observable<any>;
  tagsInput$ = new Subject<string>();
  user: any = [];
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  loadBreadCrumb = false;

  ngOnInit(): void {
    this.loadUser();
    this.form = this.formBuilder.group({
      name: ['',
        Validators.required
      ],
      accountCode: [''],
      salesPersonId: [''],
      caseManagerId: [''],
      webSite: [''],
      billingEmail: [''],
      generalNotes: [''],
      casePolicies: [''],
      updatePolicies: [''],
      invoicePolicies: [''],
      sendOverdueInvoices: false,
      show_Bill_To_Location: false,
      tags: null,
      caseTypeRestriction: []
    });
    this.loadClient();
    this.loadTags();
  }

  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid.
    if (this.form.invalid) {
      this.tosterService.error('Please fill all required fields');
      return;
    }
    const clientModel = new ClientAddModel();
    clientModel.id = this.model.id;
    clientModel.name = this.form.value.name;
    clientModel.accountCode = this.form.value.accountCode;
    clientModel.salesPersonId = this.form.value.salesPersonId;
    clientModel.caseManagerId = this.form.value.caseManagerId;
    clientModel.webSite = this.form.value.webSite;
    clientModel.billingEmail = this.form.value.billingEmail;
    clientModel.generalNotes = this.form.value.generalNotes;
    clientModel.casePolicies = this.form.value.casePolicies;
    clientModel.updatePolicies = this.form.value.updatePolicies;
    clientModel.invoicePolicies = this.form.value.invoicePolicies;
    clientModel.sendOverdueInvoices = this.form.value.sendOverdueInvoices == 'true' ? true : false;
    clientModel.show_Bill_To_Location = this.form.value.show_Bill_To_Location == 'true' ? true : false;
    clientModel.tags = this.form.value.tags;
    clientModel.caseTypeRestriction = this.form.value.caseTypeRestriction;
    this.clientService.editClient(clientModel).subscribe((data: ClientResponseModel) => {
      if (data.success) {
        this.router.navigate(['/client']);
        this.tosterService.success(data.message);
      } else {
        this.tosterService.error(data.message);
      }
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
    });
  }

  private loadUser(): void {
    this.clientService.getUser().subscribe((data: any) => {
      this.user = data;
    }, error => {
    }, () => {
    });
  }

  private loadClient(): void {
    this.clientService.getClient(this.model.id).subscribe((data: any) => {
      this.model = data;
      this.breadCrumbInputs.push({
        key: 'id', value: [data.id, data.name]
      });
      this.loadBreadCrumb = true;
      this.initForm();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.router.navigate(['/client']);
    }, () => {
    });
  }

  private initForm(): void {
    this.form.controls.name.setValue(this.model.name);
    this.form.controls.accountCode.setValue(this.model.accountCode);
    this.form.controls.salesPersonId.setValue(this.model.salesPersonId ?? '');
    this.form.controls.caseManagerId.setValue(this.model.caseManagerId ?? '');

    this.form.controls.webSite.setValue(this.model.webSite);
    this.form.controls.billingEmail.setValue(this.model.billingEmail);
    this.form.controls.generalNotes.setValue(this.model.generalNotes);
    this.form.controls.updatePolicies.setValue(this.model.updatePolicies);

    this.form.controls.casePolicies.setValue(this.model.casePolicies);
    this.form.controls.invoicePolicies.setValue(this.model.invoicePolicies);
    this.form.controls.sendOverdueInvoices.setValue(this.model.sendOverdueInvoices.toString());
    this.form.controls.show_Bill_To_Location.setValue(this.model.show_Bill_To_Location.toString());
    this.form.controls.tags.setValue(this.model.tags);
  }

  addCustomTag = (term) => term;

  private loadTags(): void {
    this.tags$ = concat(
      of([]), // default items
      this.tagsInput$.pipe(
        debounceTime(200),
        filter(res => {
          return res !== null && res.length >= 0;
        }),
        distinctUntilChanged(),
        switchMap(term => {
          return this.clientService.getClientTags(term).pipe(
            catchError(() => of([])) // empty list on error
          );
        })
      )
    );
  }

}
