import { AttachmentTypeOptions, NoteFilterByOptions, NoteSearchOptions } from "../../enums/filters-and-searchs/note.search&filter.options";

export const NoteFilterByOptionsMapping = new Map<number, string>([
    [NoteFilterByOptions.None, 'None'],
    [NoteFilterByOptions.PinnedNotes, 'Pinned Notes'],
    [NoteFilterByOptions.WithAttachments, 'With Attachments'],
    [NoteFilterByOptions.DeletedNotes, 'Deleted Notes'],
]);

export const NoteSearchOptionsMapping = new Map<number, string>([
    [NoteSearchOptions.Body, 'Body'],
    [NoteSearchOptions.AuthorName, 'Author Name'],
    [NoteSearchOptions.AttachmentName, 'Attachment Name'],
]);

export const AttachmentTypeOptionsMapping = new Map<number, string>([
    [AttachmentTypeOptions.Audio, 'Audio'],
    [AttachmentTypeOptions.Document, 'Document'],
    [AttachmentTypeOptions.Image, 'Image'],
    [AttachmentTypeOptions.Pdf, 'Pdf'],
    [AttachmentTypeOptions.SpreadSheet, 'Spreadsheet'],
    [AttachmentTypeOptions.Video, 'Video']
]);