

    <main class="content py-0">
        <div class="container-fluid p-0">
          <div class="row">
            <div class="col-md-12 pt-7">
              <div class="w-100 pt-4">
                <div class="row">
                  <div class="col-md-12 px-0 pb-3">
                    <div class="col-auto mt-n1">
                        <app-breadcrumb *ngIf="loadBreadCrumb" [breadCrumbInputs]="breadCrumbInputs"></app-breadcrumb>
                    </div>
                  </div>
                </div>
                        <div class="row">
                            <div class="col-auto d-none d-sm-block mb-3">
                                <h3 class="page-title"><strong>Edit User</strong> </h3>
                            </div>
                        </div>
                        <div class="row mx-0" *blockUI="'container-blockui-employee'">
                            <div class="col-12 px-0 mb-5">
                                <form class="new-case-form" name="form" [formGroup]="form"  (ngSubmit)="onSubmit()">
                                    <div class="row">
                                        <div class="col-md-12">
                                            <div class="card flex-fill w-100 mb-4">
                                                <div class="card-header rounded p-4 mob-px-4">
                                                    <div class="row">
                                                        <div class="col-md-12">
                                                            <div class="uploder d-flex align-items-center">
                                                                <app-profile-pic-uploader   class="w-100" *ngIf="isImageRender" [imgSrc]="imgSrc" (profileImgCropped) ="onImageCropped($event)">
                                                                </app-profile-pic-uploader>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-7x1">First Name<sup class="text-danger">*</sup></label>
                                                                <input type="text" class="form-control" id="Label-7x1" formControlName="firstName">
                                                                <div *ngIf="submitted && form.controls.firstName.errors" class="text-danger">
                                                                    <div *ngIf="form.controls.firstName.errors.required">
                                                                        First Name is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-7x2">Middle Name</label>
                                                                <input type="text" class="form-control" id="Label-7x2" formControlName="middleName">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-7x3">Last Name</label>
                                                                <input type="text" class="form-control" id="Label-7x3" formControlName="lastName" >
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-8">Title<sup class="text-danger">*</sup></label>
                                                                <input type="text" class="form-control" id="Label-8" formControlName="title">
                                                                <div *ngIf="submitted && form.controls.title.errors" class="text-danger">
                                                                    <div *ngIf="form.controls.title.errors.required">
                                                                        Title is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-9">Manager</label>
                                                                <select class="form-control" id="Label-9" formControlName="manager">
                                                                    <option value="">Select Manager</option>
                                                                    <option *ngFor="let item of managersSelectListItem" [value]="item.id">
                                                                        {{item.value}}
                                                                    </option>
                                                                  </select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-12">
                                                            <div class="form-group has-search">
                                                                <label for="role">Role*</label>

                                                                <div class="input-group position-relative">
                                                                    <select formControlName="role">
                                                                        <option selected disabled>
                                                                            Select
                                                                        </option>
                                                                        <option *ngFor="let item of rolesSelectListItem"
                                                                            [value]="item.id">
                                                                            {{item.value}}
                                                                        </option>
                                                                    </select>
                                                                    <div *ngIf="submitted && form.controls.role.errors" class="text-danger">
                                                                        <div *ngIf="form.controls.role.errors.required">
                                                                            Role is required
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                           
                                                               
                                                           
                                                        </div>
                                                       
                                                        


                                                        <!-- <div class="col-md-6">
                                                            <div class="form-group has-search">

                                                                <div class="input-group position-relative">
                                                                    <select formControlName="role">
                                                                        <option selected disabled>
                                                                            Select
                                                                        </option>
                                                                        <option *ngFor="let item of rolesSelectListItem" [value]="item.id">
                                                                            {{item.value}}
                                                                        </option>
                                                                      </select>
                                                                      <div *ngIf="submitted && form.controls.role.errors" class="text-danger">
                                                                        <div *ngIf="form.controls.role.errors.required">
                                                                            Role is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> -->
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="card flex-fill w-100 mb-4">
                                                <div class="card-header rounded p-4 mob-px-4">
                                                    <div class="user-header mb-4">
                                                        <h4 class="user-title font-weight-bold mb-0">Contact Information</h4>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-26">Email<sup class="text-danger">*</sup></label>
                                                                <input type="email" class="form-control" id="Label-26" formControlName="email">
                                                                <div *ngIf="submitted && form.controls.email.errors" class="text-danger">
                                                                    <div *ngIf="form.controls.email.errors.required">
                                                                        Email is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-18">Address Line 1</label>
                                                                <input type="text" class="form-control" id="Label-18" formControlName="address1">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-19">Address Line 2</label>
                                                                <input type="text" class="form-control" id="Label-19" formControlName="address2">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-20">Address Line 3</label>
                                                                <input type="text" class="form-control" id="Label-20" formControlName="address3">
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label for="country">Country<sup class="text-danger">*</sup></label>
                                                                <div class="input-group position-relative">
                                                                    <select  formControlName="country" (change)="loadStates($event.target.value)">
                                                                        <option *ngFor="let country of countrySelectListItem" [value]="country.name">
                                                                          {{country.name}}
                                                                        </option>
                                                                      </select>
                                                                      <div *ngIf="submitted && form.controls.country.errors" class="text-danger">
                                                                        <div *ngIf="form.controls.country.errors.required">
                                                                            Country is required
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-22">State</label>
                                                                <ng-select  name='stateSelect' formControlName="state">
                                                                    <ng-option *ngFor="let state of stateSelectListItem" [value]="state.name">{{state.name}}</ng-option>
                                                                </ng-select>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-23">Zip</label>
                                                                <input type="text" class="form-control" id="Label-23" formControlName="zip" (keypress)="appUtil._keyUp($event)"  >
                                                            </div>
                                                        </div>

                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-21">City</label>
                                                                <input type="text" class="form-control" id="Label-21" formControlName="city">
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="row">
                                                        <!-- <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-16">Contact Number</label>
                                                                <input type="text" class="form-control" id="Label-16" formControlName="contactNumber">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-27">Home Phone </label>
                                                                <input type="text" class="form-control" id="Label-27" formControlName="homePhone">
                                                            </div>
                                                        </div> -->
                                                        <div class="col-md-6 mb-4">
                                                            <div class="form-group has-search">
                                                                <label id="Label-28">Fax</label>
                                                                <input type="text" class="form-control" id="Label-28" formControlName="fax">
                                                            </div>
                                                        </div>
                                                    </div>

                                                          <!-- new contact number ui -->
                                                            <app-contact-number-widget *ngIf='isContactLoaded' [form]="form" [contactNumbers]="model.contactNos" [isSubmitted]="submitted"></app-contact-number-widget>
                                                          <!-- end -->

                                                </div>
                                            </div>
                                            <div class="card flex-fill w-100 mb-4">
                                                <div class="card-header rounded p-4 mob-px-4">
                                                    <div class="user-header mb-4">
                                                        <h4 class="user-title font-weight-bold mb-0">Other Information</h4>
                                                    </div>
                                                    <div class="row mb-4">
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <label>Date of Birth</label>
                                                                <div class="input-group">
                                                                    <input class="form-control" placeholder="yyyy-mm-dd" formControlName="dob" ngbDatepicker #d="ngbDatepicker">
                                                                    <div class="input-group-append">
                                                                      <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button"></button>
                                                                    </div>
                                                                  </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <label id="Label-18">Account Code</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="An internal employee number or reference ID for this staff member."></i>
                                                                <input type="text" class="form-control" id="Label-18" formControlName="accountCode">
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <label id="Label-19">Tags</label><i class="align-middle fas fa-info-circle ml-2 text-light-black"    placement="top" ngbTooltip="Adjusting your language may change the way the system formats dates, times, and numbers."></i>
                                                                <ng-select
                                                                [items]="tags$ | async"
                                                                name="car2"
                                                                [hideSelected]="true"
                                                                multiple="true"
                                                                [typeahead]="tagsInput$"
                                                                bindLabel="name" [addTag]="addCustomTag" class="custom-select" formControlName="tags">
                                                                    <!-- <ng-option *ngFor="let car of cars2" [value]="car.name">{{car.name}}</ng-option> -->
                                                                </ng-select>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card flex-fill w-100 mb-4">
                                                <div class="card-header rounded p-4 mob-px-4">
                                                    <div class="user-header mb-4">
                                                        <h4 class="user-title font-weight-bold mb-0">Notes</h4>
                                                    </div>
                                                    <div class="row">
                                                        <div class="col-md-6 mb-4">
                                                            <label>General Notes:</label>
                                                            <textarea class="form-control p-3" formControlName="generalNotes"  rows="5" placeholder="Add terms &amp; conditions here..."></textarea>
                                                        </div>
                                                        <div class="col-md-6 mb-4">
                                                            <label>Admin Notes</label>
                                                            <textarea class="form-control p-3" formControlName="adminNotes" rows="5" placeholder="Add terms &amp; conditions here..."></textarea>
                                                        </div>
                                                        <div class="col-md-6 mb-4">
                                                            <label>Email signature:</label>
                                                            <textarea class="form-control p-3" formControlName="emailSignature" rows="5" placeholder="Thank you for giving us the opportunity to provide our services as we truly appreciate your business."></textarea>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card flex-fill w-100 mb-4">
                                                <div class="card-header rounded p-4 mob-px-4">
                                                    <div class="user-header mb-4">
                                                        <h4 class="user-title font-weight-bold mb-0">Work Preferences</h4>
                                                    </div>
                                                    <div class="row mb-4">
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <label for="service">Preferred Services</label>
                                                                <div class="input-group position-relative">
                                                                    <select name="selectrole" formControlName="preferedServices">
                                                                        <option *ngFor="let option of options" [value]="option.id">
                                                                          {{option.name}}
                                                                        </option>
                                                                      </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6">
                                                            <div class="form-group has-search">
                                                                <label for="region">Preferred Regions</label>
                                                                <div class="input-group position-relative">
                                                                    <select name="selectrole" formControlName="preferedRegions">
                                                                        <option *ngFor="let option of options" [value]="option.id">
                                                                          {{option.name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="row mb-4">
                                                        <div class="col-md-6">

                                                            <div class="form-group has-search">
                                                                <label id="track">Track Last Location</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="When enabled, the system will periodically capture and store geo-coordinates while using the mobile app."></i>
                                                                <div>
                                                                    <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                        <input type="radio" id="customRadio" class="custom-control-input" formControlName="isTrackLastLocation"  name="isTrackLastLocation" [value]='true'>
                                                                        <label class="custom-control-label" for="customRadio">Yes</label>
                                                                    </div>
                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                        <input type="radio" id="customRadio2" class="custom-control-input" formControlName="isTrackLastLocation"  name="isTrackLastLocation" [value]='false'>
                                                                        <label class="custom-control-label" for="customRadio2">No</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-md-6 mb-2">

                                                            <div class="custom-control custom-switch">
                                                                <input type="checkbox" formControlName="is_active" class="custom-control-input" id="is_active">
                                                                <label class="custom-control-label"  for="is_active"><b>Set as "Active"</b><i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="Set As Active and De-Active"></i></label>
                                                            </div>

                                                           
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group w-100">
                                            <div class="col-lg-12 text-right">
                                                <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button" [routerLink]="['/staff']"> Cancel</button>
                                                <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="submit">Save <span class="fa fa-arrow-right"></span></button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>


