import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';


import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';

import { OverlayModule } from '@angular/cdk/overlay';

//for bootstrap table
import { CommonModule, DatePipe } from '@angular/common';
import { NgbActiveModal, NgbDateParserFormatter, NgbDatepickerModule, NgbModalModule, NgbModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbPaginationModule, NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';
import '@angular/localize/init';

// for custom multidropdown https://ng-select.github.io/ng-select
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { FooterComponent } from './components/shared/footer/footer.component';
import { SideBarComponent } from './components/shared/side-bar/side-bar.component';
import { HeaderComponent } from './components/shared/header/header.component';
import { CaseListComponent } from './components/case/case-list/case-list.component';
import { CaseRequestsComponent } from './components/case/case-requests/case-requests.component';
import { CaseSubjectsComponent } from './components/case/case-subjects/case-subjects.component';
import { CaseUpdatesComponent } from './components/case/case-updates/case-updates.component';
import { CaseReviewsComponent } from './components/case/case-reviews/case-reviews.component';
import { AddClientComponent } from './components/Clients/add-client/add-client.component';
import { ClientListComponent } from './components/Clients/client-list/client-list.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



import { ChangePasswordComponent } from './components/change-password/change-password.component';



import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ClientContactsComponent } from './components/Clients/client-contacts/client-contacts.component';
import { ContactNotesListComponent } from './components/Clients/contact-notes/contact-notes.component';
import { ContactDetailNotesComponent } from './components/Clients/client-contacts/contact-detail/contact-notes/contact-notes.component';
import { StaffListComponent } from './components/staff/staff-list/staff-list.component';
import { InvestigatorSnapshotComponent } from './components/staff/Investigator-Snapshot/investigator-snapshot.component';
import { LicensesComponent } from './components/staff/Licenses/licenses.component';
import { StaffNotesComponent } from './components/staff/staff-notes/staff-notes.component';
import { ExpenseEntriesComponent } from './components/Expenses/expense-entries/expense-entries.component';
import { ExpenseEntriesStaffComponent } from './components/Expenses/expense-entries-staff/expense-entries-staff.component';
import { PaymentSipsComponent } from './components/Expenses/payment-slips/payment-slips.component';
import { InvoiceListComponent } from './components/invoices/invoice-list/invoice-list.component';
import { PaymentHistoryComponent } from './components/invoices/payment-history/payment-history.component';
import { RetainersListComponent } from './components/invoices/retainers/retainers-list.component';
import { ActivityFulfillmentComponent } from './components/calendar/activity-fulfillment/activity-fulfillment.component';
import { EventListComponent } from './components/calendar/event-list/event-list.component';
import { ScheduleComponent } from './components/calendar/schedule/schedule.component';
import { TaskListComponent } from './components/calendar/task-list/task-list.component';
import { CalendarsComponent } from './components/calendar/calendars/calendars.component';
import { EventMapComponent } from './components/calendar/event-map/event-map.component';
import { AddCaseComponent } from './components/case/add-case/addCase.component';
import { NewVendorComponent } from './components/staff/add-vendor/new-vendor.component';
import { NewExpenseEntryComponent } from './components/Expenses/new_expense_entry/new-expense-entry.component';
import { NewTaskComponent } from './components/calendar/new-task/new-task.component';
import { AppModuleDeclarations } from './modules/app-module.declarations';
import { AuthenticatedUserGuard, UnauthenticatedUserGuard } from './guards';
import { AuthenticationService } from './services/authentication.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { ToasterService } from './services/toater.service';
import { ToastrModule } from 'ngx-toastr';
import { BlockUIModule } from 'ng-block-ui';
import { LogoutComponent } from './components/authenticate/logout/logout.component';
import { AppInitializer } from './services/app.initializer';
import { GoogleLoginProvider, SocialAuthService, SocialAuthServiceConfig } from 'angularx-social-login';
import { environment } from 'src/environments/environment';
import { DataTablesModule } from 'angular-datatables';
import { StaffService } from './services/staff.serrvice';
import { RoleService } from './services/role.service';
import { ClientService } from './services/client.service';
import { EditClientComponent } from './components/Clients/edit-client/edit-client.component';
import { CountryService } from './services/country.service';
import { ClientListDetailComponent } from './components/Clients/client-list-detail/client-list-detail.component';
import { ResetPasswordComponent } from './components/authenticate/reset-password/reset-password.component';
import { AppUtils } from './helpers/app.utils';
import { ClocationComponent } from './components/Clients/client-list-detail/clocation/clocation.component';
import { ContactsComponent } from './components/Clients/client-list-detail/contacts/contacts.component';
import { CaffiliatesComponent } from './components/Clients/client-list-detail/caffiliates/caffiliates.component';
import { PinvestigatorComponent } from './components/Clients/client-list-detail/pinvestigator/pinvestigator.component';
import { CpricelistComponent } from './components/Clients/client-list-detail/cpricelist/cpricelist.component';
import { CinvoiceComponent } from './components/Clients/client-list-detail/cinvoice/cinvoice.component';
import { CnotesComponent } from './components/Clients/client-list-detail/cnotes/cnotes.component';
import { ChistoryComponent } from './components/Clients/client-list-detail/chistory/chistory.component';
import { FilterResultsComponent } from './components/shared/filter-results/filter-results.component';
import { FilterAndSearchComponent } from './components/staff/staff-list/filter-and-search/filter-and-search.component';
import { BreadcrumbComponent } from './components/shared/breadcrumb/breadcrumb.component';
import { ModalService } from './services/modal.service';
import { LocationDetailComponent } from './components/Clients/client-list-detail/clocation/location-detail/location-detail.component';

import { AddLocationComponent } from './components/Clients/client-list-detail/clocation/add-location/add-location.component';
import { SettingComponent, UserRolesComponent, NewUserRoleComponent, UserAccessGroupsComponent, NewAccessGroupComponent } from './components/setting';
import { UsersRolesComponent } from './components/setting/users-roles/users-roles.component';
import { HasPermissionDirective } from './components/shared/has-permission/has-permission.directive';
import { LocationService } from './services/location.service';
import { EditLocationComponent } from './components/Clients/client-list-detail/clocation/edit-location/edit-location.component';
import { AddNewContactComponent } from './components/Clients/client-list-detail/contacts/add-new-contact/add-new-contact.component';
import { ClientFilterAndSearchComponent } from './components/Clients/client-list/filter-and-search/filter-and-search.component';
import { ClientNewNoteComponent } from './components/Clients/client-list-detail/cnotes/client-new-note/client-new-note.component';
import { EditUserRoleComponent } from './components/setting/users-roles/user-roles/edit-user-role/edit-user-role.component';
import { UserAccessGroupSearchAndFilterComponent } from './components/setting/users-roles/user-access-groups/user-access-group-search-and-filter/user-access-group-search-and-filter.component';
import { UsersRoleSearchAndFilterComponent } from './components/setting/users-roles/user-roles/users-role-search-and-filter/users-role-search-and-filter.component';
import { NewInvoiceRuleComponent } from './components/Clients/client-list-detail/cinvoice/new-invoice-rule/new-invoice-rule.component';
import { EditAccessGroupComponent } from './components/setting/users-roles/user-access-groups/edit-access-group/edit-access-group.component';
import { AddAffiliateComponent } from './components/Clients/client-list-detail/caffiliates/add-affiliate/add-affiliate.component';
import { GeneralGlobalSettingComponent } from './components/setting/global/general-global-setting/general-global-setting.component';
import { EditEmployeeComponent } from './components/staff/edit-employee/edit-employee.component';
import { NgbDateFRParserFormatter } from './helpers/ngb.date.parser';
import { CoverviewComponent } from './components/Clients/client-list-detail/coverview/coverview.component';
import { StaffDetailComponent } from './components/staff/staff-detail/staff-detail.component';
import { StaffOverviewComponent } from './components/staff/staff-detail/staff-overview/staff-overview.component';
import { StaffPermissionComponent } from './components/staff/staff-detail/staff-permission/staff-permission.component';
import { RouterModule } from '@angular/router';
import { StaffLicenseComponent } from './components/staff/staff-detail/staff-license/staff-license.component';
import { NewStaffLicenseComponent } from './components/staff/staff-detail/staff-license/new-staff-license/new-staff-license.component';
import { CustomizedListComponent } from './components/shared/customized-list/customized-list.component';
import { StaffAgendaComponent } from './components/staff/staff-detail/staff-agenda/staff-agenda.component';
import { ModalComponent } from './components/common/modal/modal.component';
import { StaffPriceListComponent } from './components/staff/staff-detail/staff-price-list/staff-price-list.component';
import { StaffNotificationComponent } from './components/staff/staff-detail/staff-notification/staff-notification.component';
import { AssignSalespersonComponent } from './components/Clients/assign-salesperson/assign-salesperson.component';
import { StaffPoliciesComponent } from './components/staff/staff-detail/staff-policies/staff-policies.component';
import { StaffHistoryComponent } from './components/staff/staff-detail/staff-history/staff-history.component';
import { StaffDetailNoteComponent } from './components/staff/staff-detail/staff-notes/staff-notes.component';
import { AssignManagerComponent } from './components/staff/assign-manager/assign-manager.component';
import { NewStaffNoteComponent } from './components/staff/staff-detail/staff-notes/new-staff-note/new-staff-note.component';
import { ProfileComponent } from './components/profile/profile.component';
import { OverviewComponent } from './components/profile/overview/overview.component';
import { PermissionComponent } from './components/profile/permission/permission.component';
import { GeneralCaseSettingsComponent } from './components/setting/cases/general-case-settings/general-case-settings.component';
import { CaseAssignmentTypesComponent } from './components/setting/cases/case-assignment-types/case-assignment-types.component';
import { CaseFlagsComponent } from './components/setting/cases/case-flag/case-flag-list/case-flags.component';
import { NewCaseFlagComponent } from './components/setting/cases/case-flag/new-case-flag/new-case-flag.component';
import { CaseRegionsListComponent } from './components/setting/cases/case-regions/case-regions-list/case-regions-list.component';
import { NewCaseRegionComponent } from './components/setting/cases/case-regions/new-case-region/new-case-region.component';
import { CaseRegionsFiltersComponent } from './components/setting/cases/case-regions/case-regions-list/filters/filters.component';
import { CaseServicesListComponent } from './components/setting/cases/case-services/case-services-list/case-services-list.component';
import { CaseServiceFilterComponent } from './components/setting/cases/case-services/case-services-list/case-service-filter/case-service-filter.component';
import { NewCaseServiceComponent } from './components/setting/cases/case-services/new-case-service/new-case-service.component';
import { CaseTypeListComponent } from './components/setting/cases/case-types/case-type-list/case-type-list.component';
import { ProfilePicUploaderComponent } from './components/staff/profile-pic-uploader/profile-pic-uploader.component';
import { ImageCropperComponent } from './components/shared/Modals/image-cropper/image-cropper.component';
import { NewCaseTypeComponent } from './components/setting/cases/case-types/new-case-type/new-case-type.component';
import { NotesComponent } from './components/shared/notes/notes.component';
import { NewNoteComponent } from './components/shared/notes/new-note/new-note.component';
import { CaseTypeListFilterComponent } from './components/setting/cases/case-types/case-type-list/case-type-list-filter/case-type-list-filter.component';
import { AvatarPhotoComponent } from './components/shared/avatar-photo/avatar-photo.component';
import { CaseUpdatePresetListComponent } from './components/setting/cases/case-update-presets/case-update-preset-list/case-update-preset-list.component';
import { NewCaseUpdatePresetComponent } from './components/setting/cases/case-update-presets/new-case-update-preset/new-case-update-preset.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CaseStatusListComponent } from './components/setting/cases/case-status/case-status-list/case-status-list.component';
import { NewCaseStatusComponent } from './components/setting/cases/case-status/new-case-status/new-case-status.component';
import { FiltersCaseStatusComponent } from './components/setting/cases/case-status/case-status-list/filters-case-status/filters-case-status.component';
import { EditCaseStatusTriggerComponent } from './components/setting/cases/case-status-triggers/edit-case-status-trigger/edit-case-status-trigger.component';
import { EditNoteComponent } from './components/shared/notes/edit-note/edit-note.component';
import { ClientEditNoteComponent } from './components/Clients/client-list-detail/cnotes/client-edit-note/client-edit-note.component';
import { CaseReviewListComponent } from './components/setting/cases/case-reviews/case-review-list/case-review-list.component';

import { NewCaseReviewComponent } from './components/setting/cases/case-reviews/new-case-review/new-case-review.component';
import { FolderTemplateListComponent } from './components/setting/cases/case-folder-template/folder-template-list/folder-template-list.component';
import { FolderTemplateFilterComponent } from './components/setting/cases/case-folder-template/folder-template-list/folder-template-filter/folder-template-filter.component';
import { CaseFlagFilterComponent } from './components/setting/cases/case-flag/case-flag-list/case-flag-filter/case-flag-filter.component';
import { NotesFilterAndSearchComponent } from './components/shared/notes/notes-filter-and-search/notes-filter-and-search.component';
import { NewFolderTemplateComponent } from './components/setting/cases/case-folder-template/new-folder-template/new-folder-template.component';
import { CaseSubjectTypeListComponent } from './components/setting/cases/case-subjects/case-subject-type-list/case-subject-type-list.component';
import { CaseSubjectTypeFilterComponent } from './components/setting/cases/case-subjects/case-subject-type-list/case-subject-type-filter/case-subject-type-filter.component';
import { StaffLicenseService } from './services/staff.license.service';
import { EditStaffLicenseComponent } from './components/staff/shared/license/edit-staff-license/edit-staff-license.component';
import { NewCaseSubjectComponent } from './components/setting/cases/case-subjects/new-case-subject/new-case-subject.component';
import { ReferralSourcesListComponent } from './components/setting/cases/case-referral/referral-sources-list/referral-sources-list.component';
import { NewReferralSourceComponent } from './components/setting/cases/case-referral/new-referral-source/new-referral-source.component';
import { CustomFieldsListComponent } from './components/setting/global/custom-fields/custom-fields-list/custom-fields-list.component';
import { NewCustomFieldComponent } from './components/setting/global/custom-fields/new-custom-field/new-custom-field.component';
import { CustomFieldFilterComponent } from './components/setting/global/custom-fields/custom-fields-list/custom-field-filter/custom-field-filter.component';
import { AddonsListComponent } from './components/setting/global/addons-integration/addons-list/addons-list.component';
import { UploadLogoEditComponent } from './components/setting/global/upload-logos/upload-logo-edit/upload-logo-edit.component';
import { DocumentTemplateListComponent } from './components/setting/global/document-template/document-template-list/document-template-list.component';
import { NewDocumentTemplateComponent } from './components/setting/global/document-template/new-document-template/new-document-template.component';
import { DocumentTemplateFilterComponent } from './components/setting/global/document-template/document-template-list/document-template-filter/document-template-filter.component';
import { ClientLocationOverviewComponent } from './components/Clients/client-list-detail/clocation/location-detail/client-location-overview/client-location-overview.component';
import { ClientLocationContactComponent } from './components/Clients/client-list-detail/clocation/location-detail/client-location-contact/client-location-contact.component';
import { ClientLocationNotesComponent } from './components/Clients/client-list-detail/clocation/location-detail/client-location-notes/client-location-notes.component';
import { AddClientLocationNotesComponent } from './components/Clients/client-list-detail/clocation/location-detail/client-location-notes/add-client-location-notes/add-client-location-notes.component';
import { EditClientLocationNotesComponent } from './components/Clients/client-list-detail/clocation/location-detail/client-location-notes/edit-client-location-notes/edit-client-location-notes.component';
import { CompanyLocationListComponent } from './components/setting/global/company-location/company-location-list/company-location-list.component';
import { NewCompanyLocationComponent } from './components/setting/global/company-location/new-company-location/new-company-location.component';
import { CompanyLocationFilterComponent } from './components/setting/global/company-location/company-location-list/company-location-filter/company-location-filter.component';
import { DateFormatPipe } from './helpers/pipes/date-format.pipe';
import { PhoneMaskDirective } from './helpers/directives/phone-mask';
import { StaffLicenseSearchAndFilterComponent } from './components/staff/Licenses/staff-license-search-and-filter/staff-license-search-and-filter.component';
import { NotesByCategoryComponent } from './components/shared/notes-by-category/notes-by-category.component';
import { GeneralFinanceSettingComponent } from './components/setting/finance/general-finance-setting/general-finance-setting.component';
import { PinnedNotesListViewComponent } from './components/shared/pinned-notes-list-view/pinned-notes-list-view.component';
import { ReferralSourcesFilterComponent } from './components/setting/cases/case-referral/referral-sources-list/referral-sources-filter/referral-sources-filter.component';
import { InvoiceAndExpenseListComponent } from './components/setting/finance/invoice-and-expense/invoice-and-expense-list/invoice-and-expense-list.component';
import { NewInvoiceAndExpenseItemComponent } from './components/setting/finance/invoice-and-expense/new-invoice-and-expense-item/new-invoice-and-expense-item.component';
import { InvoiceAndExpenseFilterComponent } from './components/setting/finance/invoice-and-expense/invoice-and-expense-list/invoice-and-expense-filter/invoice-and-expense-filter.component';

import { RenewStaffLicenseComponent } from './components/staff/shared/license/renew-staff-license/renew-staff-license.component';
import { ItemCodesListComponent } from './components/setting/finance/item-codes/item-codes-list/item-codes-list.component';
import { NewItemCodeComponent } from './components/setting/finance/item-codes/new-item-code/new-item-code.component';
import { ItemCodeFilterComponent } from './components/setting/finance/item-codes/item-codes-list/item-code-filter/item-code-filter.component';
import { PaymentMethodsListComponent } from './components/setting/finance/payment-method/payment-methods-list/payment-methods-list.component';
import { NewPaymentMethodComponent } from './components/setting/finance/payment-method/new-payment-method/new-payment-method.component';
import { PaymentMethodFilterComponent } from './components/setting/finance/payment-method/payment-methods-list/payment-method-filter/payment-method-filter.component';
import { TaxRateListComponent } from './components/setting/finance/tax-rate/tax-rate-list/tax-rate-list.component';
import { NewTaxRateComponent } from './components/setting/finance/tax-rate/new-tax-rate/new-tax-rate.component';
import { TaxRateFilterComponent } from './components/setting/finance/tax-rate/tax-rate-list/tax-rate-filter/tax-rate-filter.component';
import { TrackingCategoriesListComponent } from './components/setting/finance/tracking-categories/tracking-categories-list/tracking-categories-list.component';
import { NewTrackingCategoryComponent } from './components/setting/finance/tracking-categories/new-tracking-category/new-tracking-category.component';
import { TrackingCategoryFilterComponent } from './components/setting/finance/tracking-categories/tracking-categories-list/tracking-category-filter/tracking-category-filter.component';
import { StaffLicenseDetailComponent } from './components/staff/shared/license/staff-license-detail/staff-license-detail.component';
import { LicenseOverviewComponent } from './components/staff/shared/license/staff-license-detail/license-overview/license-overview.component';
import { LicenseHistoryComponent } from './components/staff/shared/license/staff-license-detail/license-history/license-history.component';
import { PermissionGuard } from './guards/permission.guard';
import { Error403Component, Error404Component } from './components/error';

import { EditStaffNoteComponent } from './components/staff/staff-detail/staff-notes/edit-staff-note/edit-staff-note.component';
import { UserSecuritySettingComponent } from './components/setting/users-roles/user-security-setting/user-security-setting.component';
import { CompanyPoliciesListComponent } from './components/setting/users-roles/company-policies/company-policies-list/company-policies-list.component';
import { NewCompanyPolicyComponent } from './components/setting/users-roles/company-policies/new-company-policy/new-company-policy.component';
import { CompanyPoliciesFiltersComponent } from './components/setting/users-roles/company-policies/company-policies-list/company-policies-filters/company-policies-filters.component';
import { DashboardTabPresetListComponent } from './components/setting/dashboard-tab-preset/dashboard-tab-preset-list/dashboard-tab-preset-list.component';
import { DashboardTabPresetFilterComponent } from './components/setting/dashboard-tab-preset/dashboard-tab-preset-list/dashboard-tab-preset-filter/dashboard-tab-preset-filter.component';
import { ProfileNotesComponent } from './components/profile/notes/notes.component';
import { NewProfileNoteComponent } from './components/profile/notes/new-note/new-note.component';
import { EditProfileNoteComponent } from './components/profile/notes/edit-note/edit-note.component';
import { ExpenseEntriesFilterComponent } from './components/Expenses/expense-entries/expense-entries-filter/expense-entries-filter.component';
import { ExpenseEntriesStaffFilterComponent } from './components/Expenses/expense-entries-staff/expense-entries-staff-filter/expense-entries-staff-filter.component';
import { PaymentSlipFilterComponent } from './components/Expenses/payment-slips/payment-slip-filter/payment-slip-filter.component';
import { PaymentSlipOverviewComponent } from './components/Expenses/payment-slips/payment-slip-detail/payment-slip-overview/payment-slip-overview.component';
import { PaymentSlipDetailComponent } from './components/Expenses/payment-slips/payment-slip-detail/payment-slip-detail.component';
import { PaymentSlipEntriesComponent } from './components/Expenses/payment-slips/payment-slip-detail/payment-slip-entries/payment-slip-entries.component';
import { PaymentSlipHistoryComponent } from './components/Expenses/payment-slips/payment-slip-detail/payment-slip-history/payment-slip-history.component';
import { PaymentSlipHistoryFilterComponent } from './components/Expenses/payment-slips/payment-slip-detail/payment-slip-history/payment-slip-history-filter/payment-slip-history-filter.component';
import { ClientContactsFilterComponent } from './components/Clients/client-contacts/client-contacts-filter/client-contacts-filter.component';
import { ContactDetailComponent } from './components/Clients/client-contacts/contact-detail/contact-detail.component';
import { ContactOverviewComponent } from './components/Clients/client-contacts/contact-detail/contact-overview/contact-overview.component';
import { ContactPermissionComponent } from './components/Clients/client-contacts/contact-detail/contact-permission/contact-permission.component';
import { ContactNotificationComponent } from './components/Clients/client-contacts/contact-detail/contact-notification/contact-notification.component';
import { ContactHistoryComponent } from './components/Clients/client-contacts/contact-detail/contact-history/contact-history.component';
import { NewClientContactNoteComponent } from './components/Clients/client-contacts/contact-detail/contact-notes/new-client-contact-note/new-client-contact-note.component';
import { ClientContactNotesFilterComponent } from './components/Clients/contact-notes/client-contact-notes-filter/client-contact-notes-filter.component';
import { NewInvoiceComponent } from './components/invoices/new-invoice/new-invoice.component';
import { NewContactPopupComponent } from './components/shared/new-contact-popup/new-contact-popup.component';
import { EditContactComponent } from './components/Clients/shared/edit-contact/edit-contact.component';
import { EditClientContactComponent } from './components/Clients/client-list-detail/contacts/edit-client-contact/edit-client-contact.component';
import { NewContactComponent } from './components/Clients/shared/new-contact/new-contact.component';

import { NewRetainerComponent } from './components/invoices/new-retainer/new-retainer.component';
import { InvoiceListFilterComponent } from './components/invoices/invoice-list/invoice-list-filter/invoice-list-filter.component';
import { RetainersFilterComponent } from './components/invoices/retainers/retainers-filter/retainers-filter.component';
import { PaymentHistoryFiltersComponent } from './components/invoices/payment-history/payment-history-filters/payment-history-filters.component';
import { EditCaseRegionsComponent } from './components/setting/cases/case-regions/edit-case-regions/edit-case-regions.component';
import { AssignContactSalespersonComponent } from './components/Clients/client-contacts/assign-contact-salesperson/assign-contact-salesperson.component';
import { PaymentSlipEnterPaymentPopupComponent } from './components/shared/Modals/payment-slip-enter-payment-popup/payment-slip-enter-payment-popup.component';
import { EditCaseAssignmentTypesComponent } from './components/setting/cases/case-assignment-types/edit-case-assignment-types/edit-case-assignment-types.component';
import { CaseRequestsFilterComponent } from './components/case/case-requests/case-requests-filter/case-requests-filter.component';
import { CaseSubjectFiltersComponent } from './components/case/case-subjects/case-subject-filters/case-subject-filters.component';
import { CaseReviewFiltersComponent } from './components/case/case-reviews/case-review-filters/case-review-filters.component';
import { CaseUpdatesFilterComponent } from './components/case/case-updates/case-updates-filter/case-updates-filter.component';
import { NewLocationComponent } from './components/Clients/shared/location/new-location.component';
import { CaseListFilterComponent } from './components/case/case-list/case-list-filter/case-list-filter.component';
import { CaseDetailComponent } from './components/case/case-detail/case-detail.component';
import { EditClientContactNoteComponent } from './components/Clients/client-contacts/contact-detail/contact-notes/edit-client-contact-note/edit-client-contact-note.component';
import { CaseOverviewComponent } from './components/case/case-detail/case-overview/case-overview.component';
import { CaseDetailAssignedComponent } from './components/case/case-detail/case-detail-assigned/case-detail-assigned.component';
import { EditCaseFlagComponent } from './components/setting/cases/case-flag/edit-case-flag/edit-case-flag.component';

import { CaseDetailFilesComponent } from './components/case/case-detail/case-detail-files/case-detail-files.component';
import { CaseDetailUpdatesComponent } from './components/case/case-detail/case-detail-updates/case-detail-updates.component';
import { CaseDetailHistoryComponent } from './components/case/case-detail/case-detail-history/case-detail-history.component';
import { ContactNumberWidgetComponent } from './components/shared/contact-number-widget/contact-number-widget.component';
import { CaseDetailHistoryFilterComponent } from './components/case/case-detail/case-detail-history/case-detail-history-filter/case-detail-history-filter.component';
import { CaseDetailReviewsComponent } from './components/case/case-detail/case-detail-reviews/case-detail-reviews.component';
import { CaseDetailBillingComponent } from './components/case/case-detail/case-detail-billing/case-detail-billing.component';
import { CaseDetailExpensesComponent } from './components/case/case-detail/case-detail-expenses/case-detail-expenses.component';
import { CaseDetailExpensesFilterComponent } from './components/case/case-detail/case-detail-expenses/case-detail-expenses-filter/case-detail-expenses-filter.component';
import { EditCustomFieldComponent } from './components/setting/global/custom-fields/edit-custom-field/edit-custom-field.component';
import { NewCaseUpdateComponent } from './components/case/case-updates/new-case-update/new-case-update.component';
import { CaseSubjectDetailComponent } from './components/case/case-subjects/case-subject-detail/case-subject-detail.component';
import { CaseSubjectDetailOverviewComponent } from './components/case/case-subjects/case-subject-detail/case-subject-detail-overview/case-subject-detail-overview.component';
import { CaseSubjectDetailConnectionComponent } from './components/case/case-subjects/case-subject-detail/case-subject-detail-connection/case-subject-detail-connection.component';
import { CaseSubjectDetailHistoryComponent } from './components/case/case-subjects/case-subject-detail/case-subject-detail-history/case-subject-detail-history.component';
import { CaseSubjectDetailHistoryFilterComponent } from './components/case/case-subjects/case-subject-detail/case-subject-detail-history/case-subject-detail-history-filter/case-subject-detail-history-filter.component';
import { EditCaseServicesComponent } from './components/setting/cases/case-services/edit-case-services/edit-case-services.component';
import { RxReactiveFormsModule } from '@rxweb/reactive-form-validators';
import { EditFolderTemplateComponent } from './components/setting/cases/case-folder-template/edit-folder-template/edit-folder-template.component';
import { CaseRequestDetailComponent } from './components/case/case-requests/case-request-detail/case-request-detail.component';
import { CaseRequestDetailOverviewComponent } from './components/case/case-requests/case-request-detail/case-request-detail-overview/case-request-detail-overview.component';
import { CaseRequestDetailSubjectsComponent } from './components/case/case-requests/case-request-detail/case-request-detail-subjects/case-request-detail-subjects.component';
import { CaseRequestDetailSubjectFilterComponent } from './components/case/case-requests/case-request-detail/case-request-detail-subjects/case-request-detail-subject-filter/case-request-detail-subject-filter.component';
import { EditCaseTypeComponent } from './components/setting/cases/case-types/edit-case-type/edit-case-type.component';
import { CaseRequestDetailHistoryComponent } from './components/case/case-requests/case-request-detail/case-request-detail-history/case-request-detail-history.component';
import { CaseRequestDetailFilesComponent } from './components/case/case-requests/case-request-detail/case-request-detail-files/case-request-detail-files.component';
import { NewCustomFieldSharedComponent } from './components/shared/setting/custom-field/new-custom-field-shared/new-custom-field-shared.component';
import { EditCaseSubjectComponent } from './components/setting/cases/case-subjects/edit-case-subject/edit-case-subject.component';
import { EditReferralSourceComponent } from './components/setting/cases/case-referral/edit-referral-source/edit-referral-source.component';
import { EditCaseStatusComponent } from './components/setting/cases/case-status/edit-case-status/edit-case-status.component';
import { CaseReviewsFilterComponent } from './components/setting/cases/case-reviews/case-review-list/case-reviews-filter/case-reviews-filter.component';
import { EditCaseReviewComponent } from './components/setting/cases/case-reviews/edit-case-review/edit-case-review.component';
import { CaseUpdatePresetFilterComponent } from './components/setting/cases/case-update-presets/case-update-preset-list/case-update-preset-filter/case-update-preset-filter.component';
import { EditCaseUpdatePresetComponent } from './components/setting/cases/case-update-presets/edit-case-update-preset/edit-case-update-preset.component';
import { CaseUpdatesDetailComponent } from './components/case/case-updates/case-updates-detail/case-updates-detail.component';
import { CaseUpdateDetailOverviewComponent } from './components/case/case-updates/case-updates-detail/case-update-detail-overview/case-update-detail-overview.component';
import { CaseUpdateDetailHistoryComponent } from './components/case/case-updates/case-updates-detail/case-update-detail-history/case-update-detail-history.component';
import { CaseMapComponent } from './components/case/case-map/case-map.component';
import { EditDocumentTemplateComponent } from './components/setting/global/document-template/edit-document-template/edit-document-template.component';
import { TemplateVariableComponent } from './components/shared/setting/document-template-popup/template-variable/template-variable.component';
import { EventListFiltersComponent } from './components/calendar/event-list/event-list-filters/event-list-filters.component';
import { EventListOverviewComponent } from './components/calendar/event-list/event-list-overview/event-list-overview.component';
import { CompanyPolicyOverviewComponent } from './components/setting/users-roles/company-policies/company-policy-overview/company-policy-overview.component';
import { PolicyOverviewComponent } from './components/setting/users-roles/company-policies/company-policy-overview/policy-overview/policy-overview.component';
import { PolicyHistoryComponent } from './components/setting/users-roles/company-policies/company-policy-overview/policy-history/policy-history.component';
import { NewPolicyVersionComponent } from './components/setting/users-roles/company-policies/new-policy-version/new-policy-version.component';
import { EditCompanyPolicyComponent } from './components/setting/users-roles/company-policies/edit-company-policy/edit-company-policy.component';
import { PolicyOveriewFilterComponent } from './components/setting/users-roles/company-policies/company-policy-overview/policy-overview/policy-overiew-filter/policy-overiew-filter.component';
import { EditPolicyVersionComponent } from './components/setting/users-roles/company-policies/edit-policy-version/edit-policy-version.component';
import { ChartsModule } from 'ng2-charts';
import { EditCompanyLocationComponent } from './components/setting/global/company-location/edit-company-location/edit-company-location.component';
import { HistoryFilterAndSearchComponent } from './components/shared/history/history-filter-and-search/history-filter-and-search.component';
import { HistoryComponent } from './components/shared/history/history.component';
import { ClientLocationHistoryComponent } from './components/Clients/client-list-detail/clocation/location-detail/client-location-history/client-location-history.component';
import { AddCaseSecondComponent } from './components/case/add-case-second/add-case-second.component';
import { NewClientComponent } from './components/Clients/shared/new-client/new-client.component';
import { NgbDateCustomParserFormatter } from './helpers/NgbDateCustomParserFormatter';
import { AddCaseThirdComponent } from './components/case/add-case-third/add-case-third.component';
import { AddCaseCaseSummaryComponent } from './components/case/add-case-case-summary/add-case-case-summary.component';
import { EditCaseSecondComponent } from './components/case/edit-case-second/edit-case-second.component';
import { EditCaseComponent } from './components/case/edit-case/edit-case.component';
import { EditCaseThirdComponent } from './components/case/edit-case-third/edit-case-third.component';
import { AnswerReviewComponent } from './components/case/case-reviews/answer-review/answer-review.component';
import { CaseDetailSubjectsComponent } from './components/case/case-detail/case-detail-subjects/case-detail-subjects.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CalendarViewComponent } from './components/calendar/calendars/calendar-view/calendar-view.component';
import { CalendarFiltersComponent } from './components/calendar/calendars/calendar-filters/calendar-filters.component';
import { TaskListFilterComponent } from './components/calendar/task-list/task-list-filter/task-list-filter.component';
import { FlatpickrModule } from 'angularx-flatpickr';
import { EventMapFiltersComponent } from './components/calendar/event-map/event-map-filters/event-map-filters.component';
import { CasefilterResultsComponent } from './components/shared/casefilter-results/casefilter-results.component';
import { ActivityFulfillmentFiltersComponent } from './components/calendar/activity-fulfillment/activity-fulfillment-filters/activity-fulfillment-filters.component';
import { NewEventComponent } from './components/calendar/new-event/new-event.component';
//import { EditCaseFirstComponent } from './components/case/edit-case-first/edit-case-first.component';

@NgModule({
  declarations: [
    AppModuleDeclarations,
    NewLocationComponent,
    AppComponent,
    DashboardComponent,
    FooterComponent,
    SideBarComponent,
    HeaderComponent,
    AddCaseComponent,
    CaseListComponent,
    CaseRequestsComponent,
    CaseSubjectsComponent,
    CaseUpdatesComponent,
    CaseReviewsComponent,
    EditCustomFieldComponent,
    AddClientComponent,
    ClientListComponent,
    ClientContactsComponent,
    ContactNotesListComponent,
    StaffListComponent,
    InvestigatorSnapshotComponent,
    LicensesComponent,
    StaffNotesComponent,
    ExpenseEntriesComponent,
    ExpenseEntriesStaffComponent,
    PaymentSipsComponent,
    InvoiceListComponent,
    PaymentHistoryComponent,
    RetainersListComponent,
    ActivityFulfillmentComponent,
    CalendarsComponent,
    EventListComponent,
    EventMapComponent,
    ScheduleComponent,
    TaskListComponent,
    NewVendorComponent,
    NewExpenseEntryComponent,
    NewTaskComponent,

    ChangePasswordComponent,

    EditClientComponent,

    ClientListDetailComponent,

    ClocationComponent,

    ContactsComponent,

    CaffiliatesComponent,

    PinvestigatorComponent,

    CpricelistComponent,

    CinvoiceComponent,

    CnotesComponent,

    ChistoryComponent,

    SettingComponent,

    UsersRolesComponent,

    UserRolesComponent,

    FilterResultsComponent,

    FilterAndSearchComponent,
    BreadcrumbComponent,
    UserAccessGroupsComponent,

    NewUserRoleComponent,
    LocationDetailComponent,
    AddLocationComponent,
    NewAccessGroupComponent,
    HasPermissionDirective,
    EditLocationComponent,
    AddNewContactComponent,
    ClientFilterAndSearchComponent,
    NewNoteComponent,
    EditUserRoleComponent,
    UserAccessGroupSearchAndFilterComponent,
    UsersRoleSearchAndFilterComponent,
    NewInvoiceRuleComponent,
    EditAccessGroupComponent,
    AddAffiliateComponent,
    GeneralGlobalSettingComponent,
    EditEmployeeComponent,
    CoverviewComponent,
    StaffDetailComponent,
    StaffOverviewComponent,
    StaffPermissionComponent,
    StaffLicenseComponent,
    NewStaffLicenseComponent,
    CustomizedListComponent,
    StaffAgendaComponent,
    ModalComponent,
    StaffPriceListComponent,
    StaffNotificationComponent,
    AssignSalespersonComponent,
    StaffPoliciesComponent,
    StaffHistoryComponent,
    StaffDetailNoteComponent,
    AssignManagerComponent,
    NewStaffNoteComponent,
    ProfileComponent,
    OverviewComponent,
    PermissionComponent,
    GeneralCaseSettingsComponent,
    CaseAssignmentTypesComponent,
    CaseFlagsComponent,
    NewCaseFlagComponent,
    CaseRegionsListComponent,
    NewCaseRegionComponent,
    CaseRegionsFiltersComponent,
    CaseServicesListComponent,
    CaseServiceFilterComponent,
    NewCaseServiceComponent,
    ProfilePicUploaderComponent,
    ImageCropperComponent,
    CaseTypeListComponent,
    NewCaseTypeComponent,
    EditCaseTypeComponent,
    CaseTypeListFilterComponent,
    AvatarPhotoComponent,
    NotesComponent,
    ClientNewNoteComponent,
    CaseUpdatePresetListComponent,
    NewCaseUpdatePresetComponent,
    CaseStatusListComponent,
    NewCaseStatusComponent,
    EditCaseStatusComponent,
    FiltersCaseStatusComponent,
    EditCaseStatusTriggerComponent,
    EditNoteComponent,
    ClientEditNoteComponent,
    CaseReviewListComponent,
    NewCaseReviewComponent,
    EditCaseReviewComponent,
    FolderTemplateListComponent,
    FolderTemplateFilterComponent,
    CaseFlagFilterComponent,
    NotesFilterAndSearchComponent,
    NewFolderTemplateComponent,
    CaseSubjectTypeListComponent,
    EditStaffLicenseComponent,
    CaseSubjectTypeFilterComponent,
    NewCaseSubjectComponent,
    EditCaseSubjectComponent,
    ReferralSourcesListComponent,
    NewReferralSourceComponent,
    EditReferralSourceComponent,
    CustomFieldsListComponent,
    NewCustomFieldComponent,
    CustomFieldFilterComponent,
    AddonsListComponent,
    UploadLogoEditComponent,
    DocumentTemplateListComponent,
    NewDocumentTemplateComponent,
    DocumentTemplateFilterComponent,
    ClientLocationOverviewComponent,
    ClientLocationContactComponent,
    ClientLocationNotesComponent,
    AddClientLocationNotesComponent,
    EditClientLocationNotesComponent,
    CompanyLocationListComponent,
    NewCompanyLocationComponent,
    EditCompanyLocationComponent,
    CompanyLocationFilterComponent,
    DateFormatPipe,
    PhoneMaskDirective,
    StaffLicenseSearchAndFilterComponent,
    NotesByCategoryComponent,
    GeneralFinanceSettingComponent,
    PinnedNotesListViewComponent,
    ReferralSourcesFilterComponent,
    InvoiceAndExpenseListComponent,
    NewInvoiceAndExpenseItemComponent,
    InvoiceAndExpenseFilterComponent,
    RenewStaffLicenseComponent,
    ItemCodesListComponent,
    NewItemCodeComponent,
    ItemCodeFilterComponent,
    PaymentMethodsListComponent,
    NewPaymentMethodComponent,
    PaymentMethodFilterComponent,
    TaxRateListComponent,
    NewTaxRateComponent,
    TaxRateFilterComponent,
    TrackingCategoriesListComponent,
    NewTrackingCategoryComponent,
    StaffLicenseDetailComponent,
    LicenseOverviewComponent,
    LicenseHistoryComponent,
    Error403Component,
    Error404Component,
    TrackingCategoryFilterComponent,
    EditStaffNoteComponent,
    UserSecuritySettingComponent,
    CompanyPoliciesListComponent,
    NewCompanyPolicyComponent,
    EditCompanyPolicyComponent,
    CompanyPoliciesFiltersComponent,
    DashboardTabPresetListComponent,
    DashboardTabPresetFilterComponent,
    ProfileNotesComponent,
    NewProfileNoteComponent,
    EditProfileNoteComponent,
    ExpenseEntriesFilterComponent,
    ExpenseEntriesStaffFilterComponent,
    PaymentSlipFilterComponent,
    PaymentSlipOverviewComponent,
    PaymentSlipDetailComponent,
    PaymentSlipEntriesComponent,
    PaymentSlipHistoryComponent,
    PaymentSlipHistoryFilterComponent,
    ClientContactsFilterComponent,
    ContactDetailComponent,
    ContactOverviewComponent,
    ContactPermissionComponent,
    ContactNotificationComponent,
    ContactHistoryComponent,
    NewClientContactNoteComponent,
    ClientContactNotesFilterComponent,
    ContactDetailNotesComponent,
    NewInvoiceComponent,
    NewContactPopupComponent,
    NewRetainerComponent,
    InvoiceListFilterComponent,
    RetainersFilterComponent,
    EditContactComponent,
    EditClientContactComponent,
    NewContactComponent,
    PaymentHistoryFiltersComponent,
    EditCaseRegionsComponent,
    AssignContactSalespersonComponent,
    PaymentSlipEnterPaymentPopupComponent,
    EditCaseAssignmentTypesComponent,
    CaseRequestsFilterComponent,
    CaseSubjectFiltersComponent,
    CaseReviewFiltersComponent,
    CaseUpdatesFilterComponent,
    CaseListFilterComponent,
    CaseDetailComponent,
    EditClientContactNoteComponent,
    CaseOverviewComponent,
    CaseDetailAssignedComponent,
    EditCaseFlagComponent,
    CaseDetailUpdatesComponent,
    CaseDetailFilesComponent,
    CaseDetailHistoryComponent,
    ContactNumberWidgetComponent,
    CaseDetailHistoryFilterComponent,
    CaseDetailReviewsComponent,
    CaseDetailBillingComponent,
    CaseDetailExpensesComponent,
    CaseDetailExpensesFilterComponent,
    NewCaseUpdateComponent,
    EditCustomFieldComponent,
    CaseSubjectDetailComponent,
    CaseSubjectDetailOverviewComponent,
    CaseSubjectDetailConnectionComponent,
    CaseSubjectDetailHistoryComponent,
    CaseSubjectDetailHistoryFilterComponent,
    EditCaseServicesComponent,
    EditFolderTemplateComponent,
    CaseRequestDetailComponent,
    CaseRequestDetailOverviewComponent,
    CaseRequestDetailSubjectsComponent,
    CaseRequestDetailSubjectFilterComponent,
    CaseRequestDetailHistoryComponent,
    CaseRequestDetailFilesComponent,
    CaseReviewsFilterComponent,
    NewCustomFieldSharedComponent,
    CaseUpdatePresetFilterComponent,
    EditCaseUpdatePresetComponent,
    CaseUpdatesDetailComponent,
    CaseUpdateDetailOverviewComponent,
    CaseUpdateDetailHistoryComponent,
    CaseMapComponent,
    EditDocumentTemplateComponent,
    TemplateVariableComponent,
    EventListFiltersComponent,
    EventListOverviewComponent,
    CompanyPolicyOverviewComponent,
    PolicyOverviewComponent,
    PolicyHistoryComponent,
    NewPolicyVersionComponent,
    PolicyOveriewFilterComponent,
    EditPolicyVersionComponent,
    HistoryFilterAndSearchComponent,
    HistoryComponent,
    ClientLocationHistoryComponent,
    AddCaseSecondComponent,
    NewClientComponent,
    AddCaseThirdComponent,
    AddCaseCaseSummaryComponent,
    EditCaseSecondComponent,
    EditCaseComponent,
    EditCaseThirdComponent,
    AnswerReviewComponent,
    CaseDetailSubjectsComponent,
    CalendarViewComponent,
    CalendarFiltersComponent,
    TaskListFilterComponent,
    EventMapFiltersComponent,
    CasefilterResultsComponent,
    ActivityFulfillmentFiltersComponent,
    NewEventComponent,
   // EditCaseFirstComponent
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    DataTablesModule,
    RxReactiveFormsModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    BlockUIModule.forRoot(),

    CommonModule,
    NgbModule,
    NgbDatepickerModule,
    NgbModalModule,
    NgbPaginationModule,
    NgbAlertModule,
    NgbNavModule,
    NgSelectModule,
    NgOptionHighlightModule,
    ImageCropperModule,
    ChartsModule,
    NgSelectModule,
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    
  ],
  exports: [
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,

    OverlayModule,
    PortalModule,
    ScrollingModule,
  ],
  providers: [
    AppUtils,
    // Guards
    AuthenticatedUserGuard,
    UnauthenticatedUserGuard,
    PermissionGuard,
    SocialAuthService,
    // services
    ClientService,
    AuthenticationService,
    DatePipe,
    DateFormatPipe,
    PhoneMaskDirective,
    ToasterService,
    StaffService,
    ClientService,
    RoleService,
    CountryService,
    ModalService,
    LocationService,
    StaffLicenseService,
    NgbDateFRParserFormatter,
    NgbDateCustomParserFormatter,
    NgbActiveModal,
    { provide: APP_INITIALIZER, useFactory: AppInitializer, multi: true, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleClientId
            ),
          }
        ],
      } as SocialAuthServiceConfig,
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
