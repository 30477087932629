<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12 px-0 pb-5">
                            <div class="col-auto mt-n1">
                                <app-breadcrumb></app-breadcrumb>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto d-none d-sm-block mb-3">
                            <h3 class="page-title"><strong>New Invoice</strong> </h3>
                            <!-- <p>Create new client detail here.</p> -->
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
                                          
                                            <div class="card-body">
                                                 <div class="user-header mb-4">
                                                    <h4 class=" font-weight-bold mb-0">
                                                        <div class="custom-control custom-switch">
                                                            <input type="checkbox" formArrayName="orders" formControlName="customSwitch1" class="custom-control-input" id="customSwitch1">
                                                            <label class="custom-control-label"  for="customSwitch1"><b>Draft Invoice</b> </label>
                                                        </div>
                                                        </h4>
                                                </div> 
                                              <div class="card-text">

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                   
                                                        <div class="input-group position-relative">
                                                            
                                                            <label id="Label-24">Invoice number<sup class="clsStric">*</sup></label>
                                                            <div class="input-group position-relative">
                                                                <input name="invoiceNumber" type="text" class="form-control mb-3" formControlName="invoiceNumber" class="form-control" 
                                                                autocomplete="off" placeholder="Client Name" [ngClass]="{ 'is-invalid': submitted && form.controls.invoiceNumber.errors }" /> 
                                                                <div *ngIf="submitted && form.controls.invoiceNumber.errors" class="invalid-feedback">
                                                                    <div *ngIf="form.controls.invoiceNumber.errors.required" style="font-size: larger;">
                                                                        Invoice number is required
                                                                    </div>
                                                                </div>
                                                                <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="createdOn">Created on <sup class="clsStric">*</sup></label>
                                                            <!-- <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Sales Person"></i> -->
                                                            <div class="input-group position-relative">
                                                                <input type="date"  name="createdOn" class="form-control mb-2" formControlName="createdOn" placeholder="Created On" autocomplete="off">
                                                                
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="dueOn">Due on <sup class="clsStric">*</sup></label>
                                                            <!-- <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Sales Person"></i> -->
                                                            <div class="input-group position-relative">
                                                                <input type="date"   name="dueOn" class="form-control" formControlName="dueOn" placeholder="Due On" autocomplete="off">
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr>

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="billTo"> Bill To</label>
                                                            <div class="input-group position-relative">
                                                               
                                                                <select formControlName="billTo">
                                                                    <option value="" selected>Select a Client</option>
                                                                    <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="location"> Location <sup class="clsStric">*</sup> <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Select a Client first ↑"></i> </label>
                                                            <div class="input-group position-relative">
                                                               
                                                                <select formControlName="location" [attr.disabled]="isDisabled">
                                                                    <option value="" selected>Select a client First</option>
                                                                    <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="contact"> Contact<i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Select a Client first ↑"></i> </label>
                                                            <div class="input-group position-relative">
                                                               
                                                                <select formControlName="contact"  [attr.disabled]="isDisabled">
                                                                    <option value="" selected>Select a client first</option>
                                                                    <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <hr>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label for="billemail">Billing Email 
                                                                <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top" title="Payment receipts and late payment reminders will be sent to this email."></i>
                                                            </label>
                                                            <input type="text" class="form-control" id="billingEmail" name="billingEmail" formControlName="billingEmail" placeholder="Billing Email"  [attr.disabled]="isDisabled" >
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="ReferenceNumber1">Reference Number 1</label>
                                                            <input name="ReferenceNumber1" type="text" class="form-control mb-3" formControlName="ReferenceNumber1" class="form-control" placeholder="Reference Number 1" autocomplete="off"  />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="ReferenceNumber2">Reference Number 2</label>
                                                            <input name="ReferenceNumber2" type="text" class="form-control mb-3" formControlName="ReferenceNumber2" class="form-control" placeholder="Reference Number 2" autocomplete="off"  />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6 mt-2">
                                                        <div class="form-group has-search">
                                                            <label id="ReferenceNumber3">Reference Number 3</label>
                                                            <input name="ReferenceNumber3" type="text" class="form-control mb-3" formControlName="ReferenceNumber3" class="form-control" placeholder="Reference Number 3" autocomplete="off"  />
                                                        </div>
                                                    </div>

                                                    
                                                </div>

                                                <hr>

                                                <div class="row">
                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="salesperson"> Salesperson </label>
                                                            <div class="input-group position-relative">
                                                               
                                                                <select formControlName="salesperson" >
                                                                    <option value="" selected>Select a client First</option>
                                                                    <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-6">
                                                        <div class="form-group has-search">
                                                            <label id="trackingCategory"> Tracking Category </label>
                                                            <div class="input-group position-relative">
                                                               
                                                                <select formControlName="trackingCategory">
                                                                    <option value="" selected>Select a client First</option>
                                                                    <option value="{{list.id}}" *ngFor="let list of user">{{list.name}}</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <table class="table table-striped border">
                                                            <thead>
                                                            <tr>
                                                              <th scope="col">Date</th>
                                                              <th scope="col">Code</th>
                                                              <th scope="col">Item</th>

                                                              <th scope="col">Staff</th>
                                                              <th scope="col">Description</th>
                                                              <th scope="col">Qty</th>

                                                              <th scope="col">Rate</th>
                                                              <th scope="col">Disc</th>

                                                              <th scope="col">Tax</th>
                                                              <th scope="col"  class="text-right">Total</th>
                                                              <th></th>
                                                            </tr>
                                                            </thead>
                                                            <tbody  formArrayName="dynamicRows" >
                                                            <tr *ngFor="let itemrow of form.controls.dynamicRows['controls']; index as i; let l = last" 	[formGroupName]="i">
                                                           
                                                              <td>
                                                                <input type="date" formControlName="name" class="form-control">
                                                               
                                                              </td>
                                                              <td>
                                                                <select class="form-control" formControlName="code">
                                                                    <option>1
                                                                    </option>
                                                                </select>
                                                              </td>
                                                              <td>
                                                                <select class="form-control" formControlName="items">
                                                                    <option>1
                                                                    </option>
                                                                </select>
                                                              </td>
                                                              <td>
                                                                <select class="form-control" formControlName="staffs">
                                                                    <option>1
                                                                    </option>
                                                                </select>
                                                              </td>

                                                              <td>
                                                                <textarea class="form-control" formControlName="description"></textarea>
                                                              </td>
                                                              <td>
                                                                <input type="text" class="form-control" formControlName="qty">
                                                              </td>
                                                              <td>
                                                                <input type="text" class="form-control" formControlName="rate">
                                                              </td>

                                                              <td>
                                                                <input type="text" class="form-control" formControlName="discount">
                                                              </td>

                                                              <td>
                                                                <select class="form-control" formControlName="staffs">
                                                                    <option selected>Tax
                                                                    </option>

                                                                    <option >0.00% - Tax Exempt
                                                                    </option>
                                                                    <option >8.00% - CA
                                                                    </option>
                                                                </select>
                                                              </td>

                                                              <td>
                                                              1000
                                                              </td>

                                                              <td>
                                                                <button *ngIf="form.controls.dynamicRows['controls'].length > 1" (click)="deleteRow(i)" class=" border-0 btn btn-danger"><i class="fas fa-trash-alt"></i></button>
                                                            </td>
                                                              
                                                            </tr>
                                                            </tbody>
                                                          </table>

                                                          
                                                    </div>
                                                </div>

                                                <div class="row justify-content-between">
                                                    <div class="col-md-3">
                                                        <button type="button" class="btn btn-primary" (click)="addNewRows()">Add Line Item</button>
                                                    </div>
                                                    <div class="col-md-3 mr-2">
                                                      
                                                      <div class="row">
                                                        <div class="col-md-6"><h5 class="font-weight-bold">Wage Total:</h5></div>
                                                        <div class="col-md-6"><h5 class="font-weight-bold text-right">$137.75</h5></div>
                                  
                                                        <div class="col-md-6"><h5 class="font-weight-bold">Expense Total:</h5></div>
                                                        <div class="col-md-6"><h5 class="font-weight-bold text-right">$57.75</h5></div>
                                  
                                                        <div class="col-md-6"><h5 class="font-weight-bold">Payment Slip Total:</h5></div>
                                                        <div class="col-md-6"><h5 class="font-weight-bold text-right">$818.05</h5></div>
                                  
                                                      </div>
                                  
                                  
                                                      <div class="row border-top pt-3  border-4">
                                                        <div class="col-md-6"><h5 class="font-weight-bold">Amount Due:</h5></div>
                                                        <div class="col-md-6"><h5 class="font-weight-bold text-right">$818.75</h5></div>
                                  
                                                      </div>
                                                    </div>
                                                   
                                                   
                                                  </div>

                                              </div>
                                             
                                            </div>
                                          </div>
                                    </div>
                              

                                  
                                    <div class="col-md-12">
                              
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="user-header mb-4">
                                                    <h4 class="user-title font-weight-bold mb-0">Notes</h4>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-6 mb-4">
                                                        <label>Terms</label>
                                                        <textarea class="form-control p-3" name="notes_terms" id="notes_terms" rows="5" placeholder="Add terms & conditions here..." formControlName="notes_terms"></textarea>
                                                    </div>
                                                    <div class="col-md-6 mb-4">
                                                        <label>Public Notes  (visible to client)</label>
                                                        <textarea class="form-control p-3" name="publicNotes" id="publicNotes" rows="5" placeholder="Add terms & conditions here..." formControlName="publicNotes"></textarea>
                                                    </div>
                                                    <div class="col-md-6 mb-4">
                                                        <label>Internal Notes  (not visible to client)</label>
                                                        <textarea class="form-control p-3" name="internalNotes" id="internalNotes" rows="5" formControlName="internalNotes" placeholder="Thank you for giving us the opportunity to provide our services as we truly appreciate your business."></textarea>
                                                    </div>
                                                   
                                                </div>
                                            </div>
                                        </div>
                                   
                                    </div>
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  [routerLink]="['/']"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="sub">Save Invoice <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main> 