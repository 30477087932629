import { Component, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HistoryFor } from 'src/app/helpers/enums/history/historyFor';

@Component({
  selector: 'app-staff-history',
  templateUrl: './staff-history.component.html',
  styleUrls: ['./staff-history.component.css']
})
export class StaffHistoryComponent implements OnInit {
    entityId: string;
    moduleId: number;
     constructor(private route: ActivatedRoute) { }
                 ngOnInit(): void {
                   this.moduleId = HistoryFor.Staff;
                   this.route.parent.params.subscribe(param => this.entityId = param.id);
                 }
}
