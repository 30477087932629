<main class="content py-0 " >
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-md-12 pt-7">
        <div class="w-100 pt-4">
          <div class="row">
            <div class="col-md-12">
              <app-breadcrumb></app-breadcrumb>
            </div>
          </div>

          <div class="row mt-4 mb-4">
            <div class="col-auto d-none d-sm-block">
              <h3 class="page-title"><strong>Upload Logos</strong></h3>
            </div>
          </div>

          <div class="row mx-0" *blockUI="'blockui-upload-logo-div'">
            <div class="col-12 px-0 mb-5">
              <form class="new-case-form" name="form" [formGroup]="form">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <div class="card-body">
                        <div class="card-text">
                          <div class="row">
                            <div class="col-md-12">
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <div class="form-group has-search">
                                      <label for="system_logo"
                                        >System Logo
                                        <i
                                          class="
                                            align-middle
                                            fas
                                            fa-info-circle
                                            ml-2
                                            text-light-black
                                          "
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          placement="top"
                                          ngbTooltip="Maximum size is 285 x 145 pixels. Larger logos will automatically be resized."
                                        ></i
                                      ></label>
                                      <input
                                        class="form-control pt-2"
                                        type="file"
                                        id="system_logo"
                                        name="attachment" accept=".jpeg,.jpg,.png"
                                        (change)="onSystemFileSelected($event)"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-6" *ngIf="systemId > 0">
                                  <div class="form-group has-search">
                                    <div
                                      class="
                                        card
                                        text-center
                                        pb-2
                                        pt-2
                                        p-3
                                        mb-5
                                        shadow-sm
                                        border
                                        rounded
                                      "
                                      style="width: 18rem"
                                    >
                                      <img
                                        class="card-img-top img-fluid p-4"
                                        [src]="systemUrl"
                                        alt="Card image cap"
                                      />
                                      <div class="card-body">
                                        <p class="card-text"></p>
                                        <button (click)="deleteLogo(systemId)" class="btn btn-danger">
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <hr />

                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <div class="form-group has-search">
                                      <label for="print_logo"
                                        >Print Logo<i
                                          class="
                                            align-middle
                                            fas
                                            fa-info-circle
                                            ml-2
                                            text-light-black
                                          "
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          placement="top"
                                          ngbTooltip="Maximum size is 285 x 145 pixels. Larger logos will automatically be resized."
                                        ></i>
                                      </label>
                                      <input
                                        class="form-control pt-2"
                                        type="file"
                                        id="print_logo"
                                        name="attachment" accept=".jpeg,.jpg,.png"
                                        (change)="onPrintFileSelected($event)"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-6" *ngIf="printId > 0">
                                  <div class="form-group has-search">
                                    <div
                                      class="
                                        card
                                        text-center
                                        pb-2
                                        pt-2
                                        p-3
                                        mb-5
                                        shadow-sm
                                        border
                                        rounded
                                      "
                                      style="width: 18rem"
                                    >
                                      <img
                                        class="card-img-top img-fluid p-4"
                                        [src]="printUrl"
                                        alt="Card image cap"
                                      />
                                      <div class="card-body">
                                        <p class="card-text"></p>
                                        <button (click)="deleteLogo(printId)" class="btn btn-danger">
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <hr />
                              <div class="row">
                                <div class="col-md-6">
                                  <div class="form-group has-search">
                                    <div class="form-group has-search">
                                      <label for="email_logo"
                                        >Email Logo
                                        <i
                                          class="
                                            align-middle
                                            fas
                                            fa-info-circle
                                            ml-2
                                            text-light-black
                                          "
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          placement="top"
                                          ngbTooltip="Maximum size is 285 x 145 pixels. Larger logos will automatically be resized."
                                        ></i
                                      ></label>
                                      <input
                                        class="form-control pt-2"
                                        type="file"
                                        id="email_logo"
                                        name="attachment" accept=".jpeg,.jpg,.png"
                                        (change)="onEmailFileSelected($event)"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div class="col-md-6" *ngIf="emailId > 0">
                                  <div class="form-group has-search">
                                    <div
                                      class="
                                        card
                                        text-center
                                        pb-2
                                        pt-2
                                        p-3
                                        mb-5
                                        shadow-sm
                                        border
                                        rounded
                                      "
                                      style="width: 18rem"
                                    >
                                      <img
                                        class="card-img-top img-fluid p-4"
                                        [src]="emailUrl"
                                        alt="Card image cap"
                                      />
                                      <div class="card-body">
                                        <p class="card-text"></p>
                                        <button (click)="deleteLogo(emailId)" class="btn btn-danger">
                                          Remove
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="form-group w-100">
                    <div class="col-lg-12 text-right">
                      <button
                        class="
                          btn
                          font-weight-500
                          px-4
                          py-2
                          bg-white
                          text-dark-blue
                          border-btn
                        "
                        type="button"
                        (click)="onCancel()"
                      >
                        Cancel
                      </button>
                      <button
                        class="
                          btn
                          bg-blue
                          text-white
                          px-4
                          py-2
                          font-weight-500
                          ml-2
                        "
                        (click)="onSubmit()"
                        type="submit"
                      >
                        Save Logos <span class="fa fa-arrow-right"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>
