<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>New Item</strong> </h3>
                        </div>

                        <!-- <div class="col-md-auto fa-client-title">
                            1213
                        </div> -->
                    </div>


                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form"  [formGroup]="form" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
                                          
                                            <div class="card-body">
                                          
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search" >
                                                                    <label for="itemName" >Item Name<sup class="clsStric">*</sup></label>
                                                                    
                                                                    <input type="text" formControlName="itemName" class="form-control mb-3" id="itemName" name="itemName" autocomplete="off">
                                                                </div>
                                                            </div>

                                                          
                                                        </div>

                                                   

                                                        <div class="row">
                                                            <div class="col-md-12">

                                                                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-1"   class="da-setting-card">
                                                                    <ngb-panel title="aasdasd" id="ngb-panel-1">
                                                
                                                                       <ng-template ngbPanelHeader let-opened="opened">
                                                                            <div class="d-flex align-items-center justify-content-between">
                                                                              <h5 class="m-0 font-weight-bold">Use as Expense Item</h5>
                                                                               <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> 
                                                                                   <!-- <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i>  -->
                                                                                   <input ngbPanelToggle type="checkbox" checked></button>

                                                                              
                                                                            </div>
                                                                       </ng-template>
                                                
                                                                      <ng-template ngbPanelContent>
                                                                        
                                                                       <div class="row ">
                                                                        <div class="col-md-6">
                                                                            <div class="form-group has-search" >
                                                                                <label for="expenseAlias" >Expense alias <i class="align-middle fas fa-info-circle ml-2 text-light-black"  placement="top" ngbTooltip="Reflects how this item will appear to staff when submitting expenses."></i>
                                                                                
                                                                                <span  class="inline-block float-end"> Override alias? <input type="checkbox"></span> 
                                                                                </label>
                                                                                
                                                                                <input type="text" formControlName="expenseAlias" class="form-control mb-3" id="expenseAlias" name="expenseAlias" autocomplete="off">
                                                                            </div>
                                                                        </div>

                                                                        <div class="col-md-6">
                                                                            <div class="form-group has-search">
                                                                                <label id="Label-18">Rate Type</label>
                                                                                <div>
                                                                                    <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                                        <input type="radio" id="customRadio31" class="custom-control-input" formControlName="rateType" name="rateType" value="true">
                                                                                        <label class="custom-control-label" for="customRadio31">Hourly Rate</label>
                                                                                    </div>
                                                                                    <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                                        <input type="radio" id="customRadio41" class="custom-control-input" formControlName="rateType" name="rateType" >
                                                                                        <label class="custom-control-label" for="customRadio41">Fixed Rate</label>
                                                                                    </div>

                                                                                    <div class="custom-control custom-radio custom-control-inline">
                                                                                        <input type="radio" id="customRadio42" class="custom-control-input" formControlName="rateType" name="rateType" value="false">
                                                                                        <label class="custom-control-label" for="customRadio42">Variable Rate</label>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                       </div>

                                                                       <div class="row">
                                                                        <div class="col-md-6 mb-4">
                                                                            <label>Default Expense Description</label>
                                                                            <textarea class="form-control p-3" name="casePolicies" id="casePolicies" rows="5" placeholder="Add description here..." formControlName="defaultExpenseNote"></textarea>
                                                                        </div>
                                                                       </div>
                                                                   
                                                                      </ng-template>
                                                                    </ngb-panel>
                                                                  
                                                                  </ngb-accordion>

                                                            </div>
                                                         
                                                        </div>


                                                        <div class="row">
                                                            <div class="col-md-12">

                                                                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-2"   class="da-setting-card">
                                                                    <ngb-panel title="aas" id="ngb-panel-2">
                                                
                                                                       <ng-template ngbPanelHeader let-opened="opened">
                                                                            <div class="d-flex align-items-center justify-content-between">
                                                                              <h5 class="m-0 font-weight-bold">Use as Invoice Item</h5>
                                                                               <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> 
                                                                                   <!-- <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i>  -->
                                                                                   <input ngbPanelToggle type="checkbox" checked></button>

                                                                              
                                                                            </div>
                                                                       </ng-template>
                                                
                                                                      <ng-template ngbPanelContent>
                                                                        
                                                                       <div class="row">
                                                                        <div class="col-md-6">
                                                                            <div class="form-group has-search" >
                                                                                <label for="invoiceAlias" >Invoice alias <i class="align-middle fas fa-info-circle ml-2 text-light-black"  placement="top" ngbTooltip="Reflects how this item will appear on an invoice."></i>
                                                                                
                                                                                <span  class="inline-block float-end"> Override alias? <input type="checkbox"></span> 
                                                                                </label>
                                                                                
                                                                                <input type="text" formControlName="expenseAlias" class="form-control mb-3" id="expenseAlias" name="expenseAlias" autocomplete="off">
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-md-6">
                                                                            <div class="form-group has-search" >
                                                                                <label for="invoiceUnit" >Invoice unit rate  <i class="align-middle fas fa-info-circle ml-2 text-light-black"  placement="top" ngbTooltip="Leave blank to use the expense rate instead, if variable."></i>
                                                                                 
                                                                                </label>
                                                                              
                                                                                <input type="text" formControlName="invoiceUnit" class="form-control mb-3" id="invoiceUnit" name="invoiceUnit" autocomplete="off" placeholder="$">
                                                                            </div>
                                                                        </div>
                                                                        

                                                                       </div>

                                                                       <div class="row">
                                                                        <div class="col-md-6">
                                                                            <div class="form-group has-search" >
                                                                                <label for="invoiceUnitDiscount" >Invoice unit discount <i class="align-middle fas fa-info-circle ml-2 text-light-black"  placement="top" ngbTooltip="Applied anytime this item is linked to an invoice via expenses."></i>
                                                                                </label>
                                                                                
                                                                                <input type="text" formControlName="invoiceUnitDiscount" class="form-control mb-3" id="invoiceUnitDiscount" name="invoiceUnitDiscount" autocomplete="off" placeholder="%">
                                                                            </div>
                                                                        </div>


                                                                        <div class="col-md-6 mb-2">
                                                                            <div class="form-group has-search">
                                                                                <label for="defaultCurrency">Default currency <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="The default currency that Trackops uses to calculate and format currency."></i></label>
                                                                                
                                                                                <select class="form-control" formControlName="defaultCurrency">
                                                                                    <option *ngFor="let items of users; let i=index">
                                                                                        {{i}}
                                                                                    </option>
                                                                                </select>
                                                                            </div>
                                                                        </div>
                                                                        

                                                                       </div>
                                                                   
                                                                       <div class="row">
                                                                        <div class="col-md-6 mb-4">
                                                                            <label>Default Invoice Description</label>
                                                                            <textarea class="form-control p-3" name="defultInvoiceDescrip" id="defultInvoiceDescrip" rows="5" placeholder="Add description here..." formControlName="defultInvoiceDescrip"></textarea>
                                                                        </div>
                                                                       </div>
                                                                      </ng-template>
                                                                    </ngb-panel>
                                                                  
                                                                  </ngb-accordion>

                                                            </div>
                                                         
                                                        </div>


                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search" >
                                                                    <label for="classCode" > Classification Code  <i class="align-middle fas fa-info-circle ml-2 text-light-black"  placement="top" ngbTooltip="	
                                                                        For LEDES compatibility, this field represents an activity code or expense code."></i></label>
                                                                    
                                                                    <input type="text" formControlName="classCode" class="form-control mb-3" id="classCode" name="classCode" autocomplete="off">
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group has-search" >
                                                                    <label for="referenceId" > Reference ID <i class="align-middle fas fa-info-circle ml-2 text-light-black"  placement="top" ngbTooltip="An optional reference number or lookup code for this item."></i></label>
                                                                    
                                                                    <input type="text" formControlName="referenceId" class="form-control mb-3" id="referenceId" name="referenceId" autocomplete="off">
                                                                </div>
                                                            </div>

                                                          
                                                        </div>

                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>




                                    </div>


                                
                                  
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  [routerLink]="['..']"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="sub">Save Item <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
