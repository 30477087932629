import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CaseRegionModel } from '../models/caseregion/case-region.model';
import { AddOrEditCaseRegionModelModel } from '../models/caseregion/add.case-region.model';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';

@Injectable({
    providedIn: 'root'
})
export class CaseRegionService {
    constructor(private http: HttpClient) {
    }
    public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/caseregion/query`, dataTablesParameters, {});
    }

    public post(model: AddOrEditCaseRegionModelModel): Observable<CaseRegionModel> {
        return this.http.post<CaseRegionModel>(`${environment.apiBaseUrl}/caseregion`, model, {});
    }

    public get(id: number): Observable<AddOrEditCaseRegionModelModel> {
        return this.http.get<AddOrEditCaseRegionModelModel>(`${environment.apiBaseUrl}/caseregion/${id}`);
    }
    public getAll(): Observable<AddOrEditCaseRegionModelModel[]> {
        return this.http.get<AddOrEditCaseRegionModelModel[]>(`${environment.apiBaseUrl}/caseregion/get`);
    }

    public put(model: AddOrEditCaseRegionModelModel): Observable<CaseRegionModel> {
        return this.http.put<CaseRegionModel>(`${environment.apiBaseUrl}/caseregion`, model, {});
    }
    public delete(ids: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/caseregion?ids=${ids.join('&ids=')}`);
    }
    public undelete(ids: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/caseregion/undelete?ids=${ids.join('&ids=')}`);
    }
}
