export class CaseDtColumnName {
    public static readonly caseNumber = 'caseNumber';
    public static readonly dueDate = 'dueDate';
    public static readonly caseType = 'caseType';
    public static readonly caseRegion = 'caseRegion';
    public static readonly hours = 'hours';
    public static readonly budgetMoney = 'budgetMoney';
   // public static readonly adminNotes = 'adminNotes';
    public static readonly schedulingNotes = 'schedulingNotes';
    //public static readonly instructionNotes = 'instructionNotes';
    public static readonly flags = 'flags';
    public static readonly caseServicesInCases = 'caseServicesInCases';
    public static readonly subjects = 'subjects';
    public static readonly createdOn = 'createdOn';
    public static readonly clientName = 'clientName';
    public static readonly claimNumber = 'claimNumber';
    public static readonly pinnedSubject = 'pinnedSubject';
    public static readonly dol = 'dol';
    public static readonly lastStatusChange = 'lastStatusChange';
    public static readonly primaryInvestigator = 'primaryInvestigator';
    public static readonly caseManager = 'caseManager';
    public static readonly salesPerson = 'salesPerson';
    public static readonly expenseTotal = 'expenseTotal';
    public static readonly invoiceEstimate = 'invoiceEstimate';
    public static readonly invoiceTotal = 'invoiceTotal';
    public static readonly scheduledDate = 'scheduledDate';
    public static readonly lastUpdate = 'lastUpdate';
    public static readonly avgRating = 'avgRating';
    public static readonly clientLocation = 'clientLocation';
    public static readonly clientContact = 'clientContact';
    public static readonly referralSource = 'referralSource';
   public static readonly caseStatus = 'caseStatus';
   public static readonly caseStatusColour = 'caseStatusColour';
    public static readonly caseTag = 'caseTag';        
    public static readonly caseLocation = 'caseLocation'; 
    public static readonly state = 'state'; 
    public static readonly county = 'county'; 
    public static readonly systemCreatedOn = 'systemCreatedOn'; 
    
    
}