import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { ClientService } from 'src/app/services/client.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';

@Component({
  selector: 'app-case-subject-detail',
  templateUrl: './case-subject-detail.component.html',
  styleUrls: ['./case-subject-detail.component.css']
})
export class CaseSubjectDetailComponent implements OnInit {


  constructor(private router: Router, private clientService: ClientService,
    private route: ActivatedRoute) {
    this.route.params.subscribe(params => this.id = params.id);
  }

  selectedTags = [];
  active = 1;
  tabDetails = [];
  currentPath: string;
  activeTab = 1;
  clientSubscription: Subscription;
  clientName: string;
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  loadBreadCrumb = false;
  id: string;
  permissionType = Permission;
  hasPermissionType = HasPermission;
  clientId: number;

  name = 'Angular';

  ngOnInit(): void {
    this.tabDetails = [
      { id: 1, title: 'Overview', path: 'overview' },
      { id: 2, title: 'Connection', path: 'connection' },
      { id: 3, title: 'History', path: 'history' }
    ];
    this.clientSubscription = this.clientService.clientListDetail$.subscribe((data) => {
      if (data == null || data === undefined) {
        return;
      }
      this.clientName = data.name;
      this.breadCrumbInputs = [{ key: 'id', value: [data.id.toString(), data.name] }];
      this.loadBreadCrumb = true;
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.activeTab = changeEvent.nextId;
  }

  componentAdded($evt): void {
    this.currentPath = $evt.route.snapshot.routeConfig.path;
  }

  // sticky money
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (document.getElementById('stickyoney')) {
      if (document.body.scrollTop > 132 ||
        document.documentElement.scrollTop > 132) {
        document.getElementById('stickyoney').classList.add('stick_me');
      } else {
        document.getElementById('stickyoney').classList.remove('stick_me');
      }
    }
  }

  ngOnDestroy(): void {
    this.clientSubscription.unsubscribe();
  }

  onNewContactClick(): void {
    this.clientService.clientDetailSubject$.next({ id: this.clientId, name: this.clientName });
    this.router.navigate([`client/${this.id}/contact/add`]);
  }

  onNewLocation(): void {
    this.router.navigate([`client/${this.id}/location/add`]);
  }
  onNewNote(): void {
    this.router.navigate([`client/${this.id}/notes/add`]);
  }


}
