<main class="content py-0">

    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">

                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-auto d-none d-sm-block">
                            <div class="d-flex mb-2">
                                <h3 class="page-title"><strong>Licenses</strong> </h3>
                                <span class="dropdown subject-dropdown show ml-2">
                                    <a class="btn text-orange dropdown-toggle" href="#" role="button"
                                        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true"
                                        aria-expanded="false">
                                        All Licenses
                                    </a>

                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </span>
                            </div>
                            <p>Get all the Licenses info at one place </p>
                        </div>

                        <div class="col-auto ml-auto text-right mt-n1">
                            <nav aria-label="breadcrumb">
                                <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                                    <li class="filter-item">
                                        <div ngbDropdown class="d-inline-block">
                                            <button class="btn btn-outline-primary btn-lg bulkaction-dropdown"
                                                id="dropdownBasic1" ngbDropdownToggle
                                                [disabled]="isBulkOperationButtonDisabled">Bulk Action</button>
                                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                                                <div *hasPermissions="[permissionType.DeleteStaffLicenses]">
                                                    <button
                                                        *ngIf="filterBy !== staffLicenseFilterOptions.DeletedLicenses"
                                                        ngbDropdownItem (click)="onBulkDelete()">Delete</button>
                                                    <button
                                                        *ngIf="filterBy === staffLicenseFilterOptions.DeletedLicenses"
                                                        ngbDropdownItem (click)="onBulkUnDelete()">UnDelete</button>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                    <li class="filter-item">
                                        <button class="btn btn-lg bg-blue text-white" data-toggle="modal"
                                            data-target="#export">
                                            <i class="align-middle fas fa-external-link-alt"></i> Export Data
                                        </button>
                                    </li>
                                    <li class="filter-item px-0 filter-icon" (click)="filterNavStatus = true">
                                        <button class="btn btn-lg bg-blue text-white">
                                            <i class="align-middle fas fa-filter"></i>
                                        </button>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <!-- Filter right sidenav -->
                        <app-staff-license-search-and-filter (onApplyFilter)="applyFilter($event)"
                            (resetFilters)="onResetFilters()" [filterStatusParent]="filterNavStatus"
                            (closeBtnFilterStatus)="closeFilterEvent($event)">
                        </app-staff-license-search-and-filter>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-10"></div>

                    <div class="col-md-2">
                        <div class="form-group form-inline float-right mb-0">
                            <button class="dropdown-toggle btn  btn-lg  bulkaction-dropdown"
                                (click)="openCustomizedListModal()"> Customize List&nbsp; </button>

                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="case-table table-responsive mt-0" *blockUI="'container-blockui-manage'">
                            <div>
                                <table class="neu-table table table-striped neu-table w-auto  table-responsive"
                                    [ngStyle]="{'visibility' : isDtVisible ? 'visible' : 'hidden'  }" datatable
                                    [dtOptions]="dtOptions">
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>