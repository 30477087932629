import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddCaseModel } from '../models/case/add-case-model';
import { CaseBudgetModel } from '../models/case/caseBudgetModel';
import { CaseViewModel } from '../models/case/caseViewModel';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';

@Injectable({
  providedIn: 'root'
})
export class CaseService {

constructor(private http: HttpClient) { }
public caseDetailSubject$ = new BehaviorSubject<{ id: string, name: string,casetypeid:string}>(null);
public caseDetai$ = this.caseDetailSubject$.asObservable();

public post(model: AddCaseModel): Observable<CaseViewModel> {
  return this.http.post<CaseViewModel>(`${environment.apiBaseUrl}/case`, model, {});
}
public postCaseBudget(model: CaseBudgetModel): Observable<CaseBudgetModel> {
  console.log('budget',model)
  return this.http.post<CaseBudgetModel>(`${environment.apiBaseUrl}/case/casebudget`, model, {});
}
public put(model: AddCaseModel): Observable<CaseViewModel> {
  console.log('put',model)
  return this.http.put<CaseViewModel>(`${environment.apiBaseUrl}/case/put`, model, {});
  
}
public updateCaseStatus(model: any): Observable<any> {
  console.log('put',model)
  return this.http.put<any>(`${environment.apiBaseUrl}/case/updatecasestatus`, model, {});
  
}
public addSupportingFiles(model: any): Observable<string>{
  return this.http.post<string>(`${environment.apiBaseUrl}/case/supporting-files`, model, {});
}
public getCaseSummary(caseId: number): Observable<any>{
return this.http.get<any>(`${environment.apiBaseUrl}/Case?id=${caseId}`);
}

public getCase(dataTablesParameters: any): Observable<DataTableResponseModel> {
  return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/case/GetCases`, dataTablesParameters, {});
}
public deleteCase(ids: number[]): Observable<any> {
  return this.http.delete(`${environment.apiBaseUrl}/case?ids=${ids.join('&ids=')}`);
}

public undeleteCase(ids: number[]): Observable<any> {
  return this.http.delete(`${environment.apiBaseUrl}/case/undelete?ids=${ids.join('&ids=')}`);
}
}
