export class StaffLicenseDtColumnName {
    public static readonly id = 'id';
    public static readonly licenseName = 'name';
    public static readonly staff = 'staff';
    public static readonly staffId = 'staffId';
    public static readonly license = 'license';
    public static readonly state = 'state';
    public static readonly country = 'country';
    public static readonly expiration = 'expiration';
    public static readonly lastRenewed = 'lastRenewed';
    public static readonly created = 'created';
}