import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

import { AppUtils } from 'src/app/helpers';
import { clientListColumnDetails } from 'src/app/helpers/constants/staff/grid.column.detail';
import { FilterBy, FilterInputModel, FilterModel, SearchByModel } from 'src/app/models/shared/filters.model';
import { SearchModel } from 'src/app/models/shared/search.model';
import { StaffService } from 'src/app/services/staff.serrvice';

@Component({
  selector: 'app-retainers-filter',
  templateUrl: './retainers-filter.component.html',
  styleUrls: ['./retainers-filter.component.css']
})
export class RetainersFilterComponent implements OnInit {



  constructor(
    private appUtils: AppUtils,
    private staffService: StaffService) {
    clientListColumnDetails.forEach((key, value) => this.searchBy.push({ id: value, name: key }));
  }



  filterBy: FilterInputModel[] = [];
  filterIn: FilterInputModel[] = [];
  isFilterInVisible = false;

  @Output()
  onApplyFilter = new EventEmitter();

  @Output()
  resetFilters = new EventEmitter();

  searchBy: SearchByModel[] = [];

  sendFilterStat: string;

  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();

  ngOnInit(): void {
    this.loadFilterOptions();
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onResetFilters(): void {
    this.resetFilters.emit();
  }


  private loadFilterOptions(): void {
    this.staffService.getSalespersons().subscribe((data: any) => {
      const filterOptions = [
        { name: 'None', id: '0', group: undefined },
        { name: 'Deleted Clients', id: '1', group: undefined },
      ];
      data.forEach(user => { filterOptions.push({ name: user.value, id: '2_' + user.id, group: 'Salesperson' }); });
      this.filterBy = filterOptions;
    }, error => {
    }, () => {
    });
  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }

}
