import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormArray, FormGroup } from '@angular/forms';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';
import {  Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { CaseFlagService } from 'src/app/services/caseflag.service';
import { ToasterService } from 'src/app/services/toater.service';
import { CasetypeService } from 'src/app/services/casetype.service';
import { CaseFlagAddModel } from 'src/app/models/caseflag/caseFlagAddModel';

@Component({
  selector: 'app-new-case-flag',
  templateUrl: './new-case-flag.component.html',
  styleUrls: ['./new-case-flag.component.css']
})
export class NewCaseFlagComponent implements OnInit {

  @BlockUI('container-blockui-new-case-flag') blockUI: NgBlockUI;

  // model: CaseFlagAddModel;
  addCaseFlagModel: CaseFlagAddModel = new CaseFlagAddModel();


  constructor(private formBuilder: FormBuilder,
              private router: Router,
              // private activeRoute: ActivatedRoute,
              private toasterService: ToasterService,
              private caseFlagService: CaseFlagService,
              private caseTypeService: CasetypeService) {
    // this.model = new AddOrEditCaseFlagModel();
    // this.activeRoute.params.subscribe(params => this.model.id = params.id);
  }


  form: FormGroup;
  submitted = false;
  isSelected = false;

  ngOnInit(): void {
    
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      colors: [''],
      description: [''],
      case_types: new FormArray([], minSelectedCheckboxes())
    });
    this.loadDefaultData();
  }
  private async loadDefaultData(): Promise<void> {
    
    this.blockUI.start();
    forkJoin([this.caseTypeService.getSelectListItem()]).subscribe(
      ([caseTypesdata]) => {
       // loading case types
       const ct: FormArray = this.caseTypesToFormArray;
       ct.clear();
       caseTypesdata.forEach((d) => {
         ct.push(
           this.formBuilder.group({
             id: [d.id],
             name: [d.value],
             isSelected: [false],
           })
         );
       });
       this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(
          error,
          'Something went wrong!'
        );
        this.blockUI.stop();
      }
    );
  }
  get caseTypesToFormArray(): FormArray{
    return this.form.controls.case_types as FormArray;
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.addCaseFlagModel.name = this.form.controls.name.value;
    this.addCaseFlagModel.color = this.form.controls.colors.value;
    this.addCaseFlagModel.description = this.form.controls.description.value;
    this.addCaseFlagModel.caseTypeIds = this.form.value.case_types.filter(x => x.isSelected).map(x => x.id);
    this.blockUI.start();
    this.caseFlagService.post(this.addCaseFlagModel).subscribe(resposne => {
      setTimeout(() => {
        this.router.navigate(['/setting/cases/flag-list']);
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Case Flag created successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });

  }


}

