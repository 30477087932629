import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-case-map',
  templateUrl: './case-map.component.html',
  styleUrls: ['./case-map.component.css']
})
export class CaseMapComponent implements OnInit {

  positionMap = {
    street: 'Brookline',
    num: '123',
    city: 'NewYork'
  };
  mapsURL = `https://maps.google.com/maps?q=${this.positionMap.street}%20${this.positionMap.num}%20%${this.positionMap.city}&t=&z=20&ie=UTF8&iwloc=&output=embed`;
  constructor(public sanitizer: DomSanitizer) { }

  ngOnInit(): void {

  }

}
