import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { CaseStatusViewModel } from 'src/app/models/case-status/caseStatusViewModel';
import { EditCaseStatusModel } from 'src/app/models/case-status/editCaseStatusModel';
import { CasestatusService } from 'src/app/services/casestatus.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-edit-case-status',
  templateUrl: './edit-case-status.component.html',
  styleUrls: ['./edit-case-status.component.scss']
})
export class EditCaseStatusComponent implements OnInit {

  @BlockUI('container-blockui-new-folder-template') blockUI: NgBlockUI;

  form: FormGroup;
  submitted = false;
  model: EditCaseStatusModel;
  caseStatusesList: CaseStatusViewModel[] = [];
  rankList: {id: string, name: string}[] = [];
  constructor(
    private caseStatusService: CasestatusService,
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.model = new EditCaseStatusModel();
    this.route.params.subscribe(
      (params) => (this.model.id = Number(params.id))
    );
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl(null, Validators.required),
      colors: new FormControl(null, Validators.required),
      rank_below: new FormControl('', Validators.required),
      description: new FormControl(''),
      webhookURL: new FormControl(''),
      is_active: new FormControl(false),
      is_monitor_due_date: new FormControl(false),
      is_reopenable: new FormControl(false),
    });
    this.loadDefaults();
  }
  loadDefaults(): void {
    this.blockUI.start();

    forkJoin([
      this.caseStatusService.getAll(),
      this.caseStatusService.get(this.model.id),
    ]).subscribe(([
      caseStatusAll,
      editCaseStatusViewModeldata
    ]) => {
      this.caseStatusesList = []; // .reset dropdown
      this.caseStatusesList = caseStatusAll.filter(x => x.id !== this.model.id);
      this.rankList = this.caseStatusesList.map(x => ({id: x.rank.toString() , name: x.name }));
      // load form data
      this.form.controls.name.setValue(editCaseStatusViewModeldata.name);
      this.form.controls.description.setValue(editCaseStatusViewModeldata.description);
      this.form.controls.colors.setValue(editCaseStatusViewModeldata.color);
      this.form.controls.webhookURL.setValue(editCaseStatusViewModeldata.webhookUrl);
      this.form.controls.is_active.setValue(editCaseStatusViewModeldata.isActive);
      this.form.controls.is_monitor_due_date.setValue(editCaseStatusViewModeldata.isMonitorDueDate);
      this.form.controls.is_reopenable.setValue(editCaseStatusViewModeldata.isReopenable);
      const rank_below = editCaseStatusViewModeldata.rank;
      this.form.controls.rank_below.setValue(rank_below - 1);
      // this.form.controls.rank_below.setValue(editCaseStatusViewModeldata.caseSatusId + '_' + editCaseStatusViewModeldata.rank, true);

    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.onCancel();
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }

    const controls = this.form.controls;
    this.model.name = controls.name.value;
    this.model.description = controls.description.value;
    this.model.color = controls.colors.value;
    this.model.webhookUrl = controls.webhookURL.value;
    this.model.isActive = controls.is_active.value;
    this.model.isMonitorDueDate = controls.is_monitor_due_date.value;
    this.model.isReopenable = controls.is_reopenable.value;
    this.model.rank = Number(controls.rank_below.value);
    this.caseStatusService.put(this.model).subscribe(
      (resposne) => {
        setTimeout(() => {
          this.onCancel();
        }, 10);
        setTimeout(() => {
          this.toasterService.success('Case status updated successfully');
          this.onCancel();
        }, 300);
        this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }
  onCancel(): void {
    this.router.navigate(['/setting/cases/case-status-list']);
  }
}
