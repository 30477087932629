<main class="content py-0">
    <div class="position-fixed menu-btn">
        <span class="curve"></span>
        <a class="sidebar-toggle justify-content-center d-flex rounded-circle bg-orange position-absolute">
            <i class="text-white fas fa-chevron-left align-self-center"></i>
        </a>
    </div>
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-auto d-none d-sm-block mb-3">
                            <h3 class="page-title"><strong>Calander New Task</strong> </h3>
                            <p>Create new calendar task here.</p>
                        </div>
                    </div>
                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form">
                                <div class="row">
                                    <div class="col-md-6">
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="row">
                                                    <div class="col-md-12 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-26">Title</label>
                                                            <input type="text" class="form-control" id="Label-26" name="location" value="AmTrust-GL Loss- Contact 2 Witnesses-Frederick">
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row mb-4">
                                                    <div class="col-md-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-24">Case</label>
                                                            <div class="input-group position-relative">
                                                                <select id="search2">
                                                                    <option value="" selected>Private Investigator</option>
                                                                    <option value="AD">Abc</option>
                                                                    <option value="BD">Xyz</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-25">Service</label>
                                                            <div class="input-group position-relative">
                                                                <select id="search3">
                                                                    <option value="" selected>Activity Check</option>
                                                                    <option value="AD">Abc</option>
                                                                    <option value="BD">Xyz</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group has-search">
                                                            <label id="tags">Assigned  To</label>
                                                            <input type="text" class="form-control" value="" data-role="tagsinput" placeholder="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="row">
                                                    <div class="col-md-12 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-1">Address Line 1</label>
                                                            <input type="text" class="form-control" id="Label-1" name="Address1" value="4051  Hardman Road, Michigan">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-2">Address Line 2</label>
                                                            <input type="text" class="form-control" id="Label-2" name="Address2" value="4051  Hardman Road, Michigan">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-3">Address Line 3</label>
                                                            <input type="text" class="form-control" id="Label-3" name="Address3" value="4051  Hardman Road, Michigan">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-4">
                                                        <div class="form-group has-search">
                                                            <label>Country</label>
                                                            <div class="input-group position-relative">
                                                                <select id="search6">
                                                                    <option value="" selected>United States</option>
                                                                    <option value="AD">Abc</option>
                                                                    <option value="BD">Xyz</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-3">City</label>
                                                            <input type="text" class="form-control" id="Label-3" name="city" value="Hardman Road">
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4 mb-4">
                                                        <div class="form-group has-search">
                                                            <label>State</label>
                                                            <div class="input-group position-relative">
                                                                <select id="search8">
                                                                    <option value="" selected>Hardman Road</option>
                                                                    <option value="AD">Abc</option>
                                                                    <option value="BD">Xyz</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3 mb-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-3">Zip</label>
                                                            <input type="text" class="form-control" id="Label-3" name="city" value="20152">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-24">Event Status</label>
                                                            <div class="input-group position-relative">
                                                                <select id="search4">
                                                                    <option value="" selected>Events</option>
                                                                    <option value="AD">Abc</option>
                                                                    <option value="BD">Xyz</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-4">
                                                        <div class="form-group has-search">
                                                            <label id="Label-25">Status</label>
                                                            <div class="input-group position-relative">
                                                                <select id="search5">
                                                                    <option value="" selected>Not yet started</option>
                                                                    <option value="AD">Abc</option>
                                                                    <option value="BD">Xyz</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group has-search">
                                                            <div class="custom-control custom-checkbox">
                                                                <input type="checkbox" class="custom-control-input" id="customCheck" name="example1">
                                                                <label class="custom-control-label" for="customCheck">All Day Event</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <hr>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group has-search">
                                                            <label>Start At</label>
                                                            <div id="newCasedatepicker7" class="input-group date case-new-date" data-date-format="d-mm-yyyy">
                                                                <input class="form-control border-0 bg-transparent" type="text" readonly="">
                                                                <span class="input-group-addon"><i class="far fa-calendar-alt"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 d-flex align-items-center">
                                                        <div class="form-group has-search mb-0">
                                                            <label class="mb-0">at</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group has-search">
                                                            <label>Choose Time</label>
                                                            <div class="input-group bootstrap-timepicker timepicker border pr-2 align-items-center">
                                                                <input id="timepicker1" type="text" class="form-control input-small border-0">
                                                                <span class="input-group-addon">
                                                                    <span class="far fa-clock"></span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-md-4">
                                                        <div class="form-group has-search">
                                                            <label>End Time</label>
                                                            <div id="newCasedatepicker8" class="input-group date case-new-date" data-date-format="d-mm-yyyy">
                                                                <input class="form-control border-0 bg-transparent" type="text" readonly="">
                                                                <span class="input-group-addon"><i class="far fa-calendar-alt"></i></span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-1 d-flex align-items-center">
                                                        <div class="form-group has-search mb-0">
                                                            <label class="mb-0">at</label>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-3">
                                                        <div class="form-group has-search">
                                                            <label>Choose Time</label>
                                                            <div class="input-group bootstrap-timepicker timepicker border pr-2 align-items-center">
                                                                <input id="timepicker2" type="text" class="form-control input-small border-0">
                                                                <span class="input-group-addon">
                                                                    <span class="far fa-clock"></span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group has-search">
                                                            <label for="role">Time Zone</label>
                                                            <div class="input-group position-relative">
                                                                <select id="search1">
                                                                    <option value="" selected>Time zone in Kolkata, West Bengal (GMT+5:30) </option>
                                                                    <option value="AD">Abc</option>
                                                                    <option value="BD">Xyz</option>
                                                                </select>
                                                                <div class="input-group-append position-absolute append-btn">
                                                                    <button class="border-0 bg-transparent" type="button">
                                                                    <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="card flex-fill w-100 mb-4">
                                            <div class="card-header rounded p-4 mob-px-4">
                                                <div class="row">
                                                    <div class="col-md-12 mb-4">
                                                        <label>Notes</label>
                                                        <textarea class="form-control p-3" name="addComment" id="characteristics" rows="5" placeholder="">Add terms & conditions here...</textarea>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="button">Save Task <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
