import { ClientContactFilterOptions, ClientContactSearchOptions } from "../../enums/filters-and-searchs/client.contact.search.&.filter.options";

export const ClientContactFilterOptionsMapping = new Map<number, string>([
    [ClientContactFilterOptions.None, 'None'],
    [ClientContactFilterOptions.DeletedContacts, 'Deleted Contacts'],
]);

export const ClientContactSearchOptionMapping = new Map<number, string>([
    [ClientContactSearchOptions.Name, 'Name'],
    [ClientContactSearchOptions.Email, 'Email'],
    [ClientContactSearchOptions.Title, 'Title'],
    [ClientContactSearchOptions.officePhone, 'Office Phone'],
    [ClientContactSearchOptions.Tags, 'Tag'],
    [ClientContactSearchOptions.AccountCode, 'Account Code'],
    [ClientContactSearchOptions.BirthDay, 'Date Of Birth'],
    [ClientContactSearchOptions.StateOrProvince, 'State/Province'],
    [ClientContactSearchOptions.Location, 'Location'],
    [ClientContactSearchOptions.ClientName, 'Client Name'],
    [ClientContactSearchOptions.ClientCity, 'Client City'],
    [ClientContactSearchOptions.ClientStateOrProvince, 'Client State/Province'],
    [ClientContactSearchOptions.ClientTag, 'Client Tag'],
    [ClientContactSearchOptions.CaseManager, 'Case Manager']
]);
