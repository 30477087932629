import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { NoteAddModel } from 'src/app/models/note/note.add.model';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { NoteService } from 'src/app/services/note.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-new-note',
  templateUrl: './new-note.component.html',
  styleUrls: ['./new-note.component.css']
})
export class NewNoteComponent implements OnInit {

  @Input() moduleId: number;
  @Input() entityId = '';
  @Input() isCurrentUser = false;
  @BlockUI() blockUI: NgBlockUI;
  form: FormGroup;
  accessGroups: Array<SelectListItem<number>> = [];
  isSubmitted: boolean;
  Attachment: File;

  // reset file input
  @ViewChild('fileInput')
  myInputVariable: ElementRef;

  @Input() breadCrumbInputs: { key: string, value: string[] }[];
  loadBreadCrumb = false;


  constructor(private router: Router,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private accessGroupService: AccessGroupService,
    private noteService: NoteService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      date: ['', Validators.required],
      accessGroup: ['', Validators.required],
      generalNotes: ['', Validators.required],
      attachment: null,
      isPinnedNote: [false]
    });
    this.loadAccessGroups();
    this.loadBreadCrumb = true;
  }

  private async loadAccessGroups(): Promise<void> {
    this.blockUI.start();
    this.accessGroupService.getCurrentUserAccessGroups().subscribe((data: Array<SelectListItem<number>>) => {
      if (data && data.length > 0) {
        this.accessGroups = data;
      }
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  onSubmit(): void {
    this.isSubmitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }

    const model = new NoteAddModel();
    model.id = 0;
    model.accessGroupId = Number(this.form.controls.accessGroup.value);
    model.date = this.form.controls.date.value;
    model.noteText = this.form.controls.generalNotes.value;
    model.isPinnedNote = this.form.controls.isPinnedNote.value;
    model.attachment = this.form.controls.attachment.value;
    model.entityId = this.entityId;
    model.module = this.moduleId;
    model.isCurrentUser = this.isCurrentUser;
    this.blockUI.start();
    this.noteService.post(model).subscribe((data) => {
      this.toasterService.success(data);
      this.router.navigate(['../'], { relativeTo: this.route });
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }

  onFileSelected(event: any): void {
    const fileList = event.target.files as FileList;
    if (!fileList || fileList.length === 0) {
      this.form.controls.attachment.setValue(null);
      return;
    }
    if (fileList.item(0).name.split('.').length > 2) {
      this.toasterService.error('Invalid file!');
      return
    }
    this.form.controls.attachment.setValue(fileList.item(0));
  }

  fileInputReset(): void {
    this.myInputVariable.nativeElement.value = '';
  }
}
