import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { forkJoin } from 'rxjs';

import { AppUtils } from 'src/app/helpers';
import { ClientContactFilterOptionsMapping, ClientContactSearchOptionMapping } from 'src/app/helpers/constants/filter-and-search/client.contact.search.&.filter.mapping';
import { clientListColumnDetails } from 'src/app/helpers/constants/staff/grid.column.detail';
import { ClientContactFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/client.contact.search.&.filter.options';
import { RoleAvailableFor } from 'src/app/helpers/enums/roles/role.available.for';
import { FilterBy, FilterInputModel, FilterModel, SearchByModel } from 'src/app/models/shared/filters.model';
import { SearchModel } from 'src/app/models/shared/search.model';
import { RoleService } from 'src/app/services/role.service';
import { StaffService } from 'src/app/services/staff.serrvice';


@Component({
  selector: 'app-client-contacts-filter',
  templateUrl: './client-contacts-filter.component.html',
  styleUrls: ['./client-contacts-filter.component.css']
})
export class ClientContactsFilterComponent implements OnInit {


  filterBy: FilterInputModel[] = [];
  filterIn: FilterInputModel[] = [];
  isFilterInVisible = true;

  @Output()
  onApplyFilter = new EventEmitter();

  @Output()
  resetFilters = new EventEmitter();

  @Output()
  closeFilterNav = new EventEmitter();

  searchBy: SearchByModel[] = [];

  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();

  constructor(
    private staffService: StaffService,
    private roleService: RoleService) {
    ClientContactSearchOptionMapping.forEach((key, value) => this.searchBy.push({ id: value, name: key }));
  }

  ngOnInit(): void {
    this.loadFilterOptions();
  }

  private loadFilterOptions(): void {
    forkJoin([this.staffService.getSalespersons(),
    this.roleService.getRolesByAvailableFor(RoleAvailableFor.clientContact)])
      .subscribe(([salesPerson, roles]) => {
        const filterByOptions = [];
        ClientContactFilterOptionsMapping.forEach((key, value) =>
          filterByOptions.push({ name: key, id: `${value}`, group: undefined, isSelected: value === ClientContactFilterOptions.None })
        );
        salesPerson.forEach((item) =>
        filterByOptions.push({ name: item.value, id: `${ClientContactFilterOptions.SalesPerson}_${item.id}`, group: 'Sales Persons' }));
        this.filterBy = filterByOptions;
        this.filterIn = roles.map((item => ({ id: item.id, name: item.value })));
        this.filterIn.unshift({ id: '-1', name: 'All Roles', isSelected: true });
      });
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onFilterNavClose(): void {
    // this.showFiltersAndSearch = false;
    this.closeFilterNav.emit();
  }

  onResetFilters(): void {
    this.loadFilterOptions();
    this.resetFilters.emit();
  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }


}
