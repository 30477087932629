import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Console } from 'console';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AddOrEditSubjectTypeInCaseModel } from '../models/subject-type-in-case/addOrEditSubjectTypeInCaseModel';
import { SubjectTypeInCaseViewModel } from '../models/subject-type-in-case/subjectTypeInCaseViewModel';

@Injectable({
  providedIn: 'root'
})
export class SubjectTypeInCaseService {

  constructor(private http: HttpClient) { }

  public post(model: AddOrEditSubjectTypeInCaseModel): Observable<string> {
    const formData = new FormData();
    if (model.photoUrl){
      formData.append('photoUrl', model.photoUrl);
    }
    formData.append('id', JSON.stringify(model.id));
    formData.append('isPinned', JSON.stringify(model.isPinned));
    formData.append('displayValue', model.displayValue);

    formData.append('isPrimary', JSON.stringify(model.isPrimary));
    formData.append('displayValue', model.displayValue);
    formData.append('caseId', JSON.stringify(model.caseId));
    formData.append('subjectTypeId', JSON.stringify(model.subjectTypeId));
    if (model.customFieldSubjectTypeInCases !== null && model.customFieldSubjectTypeInCases !== undefined){
      for (let i = 0; i < model.customFieldSubjectTypeInCases.length; i++) {
        formData.append(`customFieldSubjectTypeInCases[${i}].id`, JSON.stringify(model.customFieldSubjectTypeInCases[i].id));
        formData.append(`customFieldSubjectTypeInCases[${i}].subjectTypeInCaseId`,
             JSON.stringify(model.customFieldSubjectTypeInCases[i].customFieldId));
        formData.append(`customFieldSubjectTypeInCases[${i}].customFieldId`,
            JSON.stringify(model.customFieldSubjectTypeInCases[i].customFieldId));
        formData.append(`customFieldSubjectTypeInCases[${i}].value`, model.customFieldSubjectTypeInCases[i].value);

    }
    }
    if (model.subjectTypeInCaseConnections != null && model.subjectTypeInCaseConnections !== undefined){
      for (let i = 0; i < model.subjectTypeInCaseConnections.length; i++) {
        formData.append(`subjectTypeInCaseConnections[${i}].id`, JSON.stringify(model.subjectTypeInCaseConnections[i].id));
        formData.append(`subjectTypeInCaseConnections[${i}].subjectTypeInCaseFirstId`,
            JSON.stringify(model.subjectTypeInCaseConnections[i].subjectTypeInCaseFirstId));
        formData.append(`subjectTypeInCaseConnections[${i}].subjectTypeInCaseSecondId`,
           JSON.stringify(model.subjectTypeInCaseConnections[i].subjectTypeInCaseSecondId));
    }
    console.log(formData.getAll);
    }
    return this.http.post<string>(`${environment.apiBaseUrl}/subjecttypeincase/post`, formData, {});
  }
  public put(model: AddOrEditSubjectTypeInCaseModel): Observable<string> {
    
   
     (model);
    const formData = new FormData();
    if (model.photoUrl){
      formData.append('photoUrl', model.photoUrl);
    }
    formData.append('id', JSON.stringify(model.id));
    formData.append('isPinned', JSON.stringify(model.isPinned));
    formData.append('displayValue', model.displayValue);

    formData.append('isPrimary', JSON.stringify(model.isPrimary));
    formData.append('displayValue', model.displayValue);
    formData.append('caseId', JSON.stringify(model.caseId));
    formData.append('subjectTypeId', JSON.stringify(model.subjectTypeId));
    if (model.customFieldSubjectTypeInCases !== null && model.customFieldSubjectTypeInCases !== undefined){
      for (let i = 0; i < model.customFieldSubjectTypeInCases.length; i++) {
        formData.append(`customFieldSubjectTypeInCases[${i}].id`, JSON.stringify(model.customFieldSubjectTypeInCases[i].id));
        formData.append(`customFieldSubjectTypeInCases[${i}].subjectTypeInCaseId`,
             JSON.stringify(model.customFieldSubjectTypeInCases[i].customFieldId));
        formData.append(`customFieldSubjectTypeInCases[${i}].customFieldId`,
            JSON.stringify(model.customFieldSubjectTypeInCases[i].customFieldId));
        formData.append(`customFieldSubjectTypeInCases[${i}].value`, model.customFieldSubjectTypeInCases[i].value);

    }
    }
    if (model.subjectTypeInCaseConnections != null && model.subjectTypeInCaseConnections !== undefined){
      for (let i = 0; i < model.subjectTypeInCaseConnections.length; i++) {
        formData.append(`subjectTypeInCaseConnections[${i}].id`, JSON.stringify(model.subjectTypeInCaseConnections[i].id));
        formData.append(`subjectTypeInCaseConnections[${i}].subjectTypeInCaseFirstId`,
            JSON.stringify(model.subjectTypeInCaseConnections[i].subjectTypeInCaseFirstId));
        formData.append(`subjectTypeInCaseConnections[${i}].subjectTypeInCaseSecondId`,
           JSON.stringify(model.subjectTypeInCaseConnections[i].subjectTypeInCaseSecondId));
    }
    
    }

    return this.http.put<string>(`${environment.apiBaseUrl}/subjecttypeincase/put`, formData, {});
  }
  public getByCaseId(caseId: number): Observable<SubjectTypeInCaseViewModel[]>
  {
   return  this.http.get<SubjectTypeInCaseViewModel[]>(`${environment.apiBaseUrl}/subjecttypeincase/getbycaseId?caseId=${caseId}`);
  }
  
}
