<main class="content py-0">
    <div class="container-fluid p-0">
        <table mdbTable>
           <h1>Template Variable</h1>
            <!-- <thead>
                <tr>
                    <th *ngFor="let head of headElements" scope="col">{{head}} </th>
                </tr>
            </thead> -->
            <tbody>
                <tr mdbTableCol *ngFor="let el of variableData">
                    <td>{{el.key}}</td>
                    <td>{{el.value}}</td>
                </tr>
            </tbody>
        </table>
    </div>
</main>