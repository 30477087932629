import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  Validators,
  FormControl,
  FormGroup,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI } from 'ng-block-ui';
import { NgBlockUI } from 'ng-block-ui/models/block-ui.model';
import { ComponentDestoryHelper } from 'src/app/helpers/component-destory.helper';
import { AddCompanyPolicyVersionHistoryModel } from 'src/app/models/company-policy-version-history/addCompanyPolicyVersionHistoryModel';
import { CompanyPolicyVersionHistoryService } from 'src/app/services/companypolicy.versionhistory.service';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-policy-version',
  templateUrl: './new-policy-version.component.html',
  styleUrls: ['./new-policy-version.component.css'],
})
export class NewPolicyVersionComponent
  extends ComponentDestoryHelper
  implements OnInit, OnDestroy
{
  @BlockUI('container-blockui-new-policy-version') blockUI: NgBlockUI;
  companyPolicyId: number;
  model: AddCompanyPolicyVersionHistoryModel;
  form: FormGroup;
  submitted = false;
  breadCrumbInputs: { key: string; value: string[] }[] = [];
  loadBreadCrumb = false;
  currentDate: any;

  constructor(
    private datePipe: DatePipe,
    private route: ActivatedRoute,
    private router: Router,
    private toasterService: ToasterService,
    private companyPolicyVersionHistoryService: CompanyPolicyVersionHistoryService
  ) {
    super();
    this.model = new AddCompanyPolicyVersionHistoryModel();
    this.route.params.subscribe((params) => {
      this.companyPolicyId = +params.id;
    });
    const todayDate = new Date();
    this.currentDate = this.datePipe.transform(
      todayDate,
      environment.dateTimeFormat.date
    );
    const subscription =
      this.companyPolicyVersionHistoryService.policyListDetail$.subscribe(
        (data) => {
          if (data == null || data === undefined) {
            return;
          }
          this.breadCrumbInputs = [
            { key: 'id', value: [data.id.toString(), data.name] },
          ];
          this.loadBreadCrumb = true;
        }
      );
    this.subscriptions.push(subscription);
  }
  ngOnDestroy(): void {
    this.ngAfterOnDestroy();
  }
  onFileSelected(event: any): void {
    const fileList = event.target.files as FileList;
    if (!fileList || fileList.length === 0) {
      this.form.controls.version_file.setValue(null);
      return;
    }
    if (fileList.item(0).name.split('.').length > 2) {
      this.toasterService.error('Invalid file!');
      return;
    }
    this.form.controls.version_file.setValue(fileList.item(0));
  }
  ngOnInit(): void {
    this.form = new FormGroup({
      version: new FormControl('', Validators.required),
      version_notes: new FormControl(),
      is_current_version: new FormControl(true),
      version_file: new FormControl(null, Validators.required),
    });
    this.form.controls.version.setValue(this.currentDate);
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.id = 0;
    this.model.companyPolicyId = this.companyPolicyId;
    this.model.version = this.form.controls.version.value;
    this.model.versionNotes = this.form.controls.version_notes.value;
    this.model.versionFile = this.form.controls.version_file.value;
    this.model.isCurrentVersion = this.form.controls.is_current_version.value;
    this.blockUI.start();
    this.companyPolicyVersionHistoryService.post(this.model).subscribe(
      (resposne) => {
        setTimeout(() => {
          this.onCancel();
        }, 10);
        setTimeout(() => {
          this.toasterService.success('Policy version created successfully');
        }, 300);
        this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }
  onCancel(): void {
    this.router.navigate([
      `company-policy-detail/${this.companyPolicyId}/overview`,
    ]);
  }
}
