<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>


                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>General Case Settings</strong> </h3>
                        </div>

                        <!-- <div class="col-md-auto fa-client-title">
                            1213
                        </div> -->
                    </div>


                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form"  [formGroup]="form" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >

                                        <div class="card-header pb-0">
                                                <div class="user-header mb-4">
                                                    <h4 class="user-title font-weight-bold mb-0">Case </h4>
                                                </div>
                                            </div>
                                          
                                            <div class="card-body">
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="casenumber">Case Number Format <sup class="clsStric">*</sup>
                                                                        <i
                                                                        class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                        data-toggle="tooltip" data-placement="top" placement="top"
                                                                        [ngbTooltip]="tipContent"></i></label>
                                                                    <input name="casenumber" formControlName="casenumber" type="text" class="form-control mb-3"  placeholder="" autocomplete="off"/>
                                                                    
                                                                </div>

                                                                <ng-template #tipContent>
                                                                    The available variables are as follows:
                                                                    <br><code ngNonBindable class="codes">{{Case.year_created_2}} - Year Created (2-Digit)<br>
                                                                    {{Case.year_created_4}} - Year Created (4-Digit)<br>
                                                                    {{Case.year_created_month}} - Month Created<br>
                                                                    {{Case.case_type_tag}} - Case Type Tag<br>
                                                                    {{Case.series_number}} - Case Series Number<br>
                                                                    {{Case.series_instance}} - Case Instance </code></ng-template>
                                                                
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="case_series_number">Next Case Series Number<sup class="clsStric">*</sup><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="Note: If this number is lower than the 'actual' next number, it will be ignored."></i></label>
                                                                    <input name="case_series_number" type="text" class="form-control mb-3" formControlName="case_series_number"  placeholder="" autocomplete="off"/>
                                                                    
                                                                </div>
                                                            </div>
                                                        </div>

                                                       

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="recent_case_count">Recent case count<sup class="clsStric">*</sup><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="The number of recently viewed cases to display."></i></label>

                                                                    <div class="input-group position-relative">
                                                                        <select name="recent_case_count" formControlName="recent_case_count">
                                                                            <option value="Select Role" selected>Select Role</option>
                                                                            <option value="{{list.id}}" *ngFor="let list of users">{{list.name}}</option>
                                                                        </select>

                                                                        <div class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent" type="button">
                                                                            <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                   
                                                                </div>
                                                            </div>


                                                      
                                                        </div>

                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-12">
                                        <div class="card" >
                                            <div class="card-header pb-0">
                                                <div class="user-header mb-4">
                                                    <h4 class="user-title font-weight-bold mb-0">Case Request</h4>
                                                </div>
                                            </div>
                                          
                                            <div class="card-body">
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6 mb-4">
                                                                <label for="case_request_inst">Case request instructions <sup class="clsStric">*</sup><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top"  ngbTooltip="Instructions & Notes to the client when entering a case request."></i></label>
                                                                <textarea class="form-control p-3" name="case_request_inst" id="generalNotes" rows="5"  placeholder="Instructions & Notes to the client when entering a case request." formControlName="case_request_inst"></textarea>
                                                            </div>
                                                        </div>

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="case_request_num_format" ngNonBindable>Case Request Number Format<sup class="clsStric">*</sup><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top"  ngbTooltip="The available variables are as follows: {{Case.series_number}} - Case Series Number"></i></label>
                                                                    <input name="case_request_num_format" type="text" class="form-control mb-3" formControlName="case_request_num_format"  placeholder="" autocomplete="off"/>
                                                                    
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6 mb-4">
                                                                <div class="form-group has-search">
                                                                    <label for="case_request_series_num" id="Label-16">Next Case Request Series Number <sup class="clsStric">*</sup><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top"  ngbTooltip="If this number is less than the 'actual' next case request number, it will be ignored."></i></label>
                                                                    <input name="case_request_series_num" type="text" class="form-control mb-3" formControlName="case_request_series_num" class="form-control" autocomplete="off"   placeholder="Office Phone"/>
                                                                   
                                                                   
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="public_case_request_aceess">Public Case Request Access Group<sup class="clsStric">*</sup><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top"  ngbTooltip="The access group to be applied to files submitted through the public case request form."></i></label>

                                                                    <div class="input-group position-relative">
                                                                        <select name="public_case_request_aceess" formControlName="public_case_request_aceess">
                                                                            <option value="Select Role" selected>Select Role</option>
                                                                            <option value="{{list.id}}" *ngFor="let list of users">{{list.name}}</option>
                                                                        </select>

                                                                        <div class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent" type="button">
                                                                            <i class="text-white rounded-circle bg-orange align-middle" data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                   
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-md-12">
                                        <div class="card" >
                                            <div class="card-header pb-0">
                                                <div class="user-header mb-4">
                                                    <h4 class="user-title font-weight-bold mb-0">Case Update</h4>
                                                </div>
                                            </div>
                                          
                                            <div class="card-body">
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label id="track" for="default_include_attachment"> Default to Include Attachments<sup class="clsStric">*</sup></label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="When enabled, Trackops will pre-select the option to include files (if available) when sending case updates."></i>
                                                                    <div>
                                                                        <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                            <input type="radio" id="customRadio6" class="custom-control-input" formControlName="default_include_attachment"  name="default_include_attachment" value="true">
                                                                            <label class="custom-control-label" for="customRadio6">Yes</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" id="customRadio7" class="custom-control-input" formControlName="default_include_attachment"  name="default_include_attachment" value="false">
                                                                            <label class="custom-control-label" for="customRadio7">No</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label id="track" for="inbound_email_update"> Inbound Email Update Replies<sup class="clsStric">*</sup></label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="When enabled, Trackops will automatically capture replies to case update emails and populate them into the system."></i>
                                                                    <div>
                                                                        <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                            <input type="radio" id="customRadio3" class="custom-control-input" formControlName="inbound_email_update"  name="inbound_email_update" value="true">
                                                                            <label class="custom-control-label" for="customRadio3">Yes</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" id="customRadio4" class="custom-control-input" formControlName="inbound_email_update"  name="inbound_email_update" value="false">
                                                                            <label class="custom-control-label" for="customRadio4">No</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            


                                                           
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="inbound_email_catch">Inbound Email Catch-All<sup class="clsStric">*</sup><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="If the system is unable to process an inbound update reply due to it being sent by an unauthorized user, choose who should receive the email reply instead."></i></label>
                                                                    
                                                                    <select name="inbound_email_catch" id="language" formControlName="inbound_email_catch" class="form-control">
                                                                        <option value="" selected disabled >Select Language</option>
                                                                        <option value="ab">Abkhazian</option>
                                                                        
                                                                    </select>


                                                                    
                                                                </div>
                                                            </div>
                                                        </div>

                                                     

                                                       

                                                        

                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div class="col-md-12">
                                        <div class="card" >
                                            <div class="card-header pb-0">
                                                <div class="user-header mb-4">
                                                    <h4 class="user-title font-weight-bold mb-0">Files</h4>
                                                </div>
                                            </div>
                                          
                                            <div class="card-body">
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="image_attach_style">Image Attachment Style<i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="If enabled, the system will display image files inline instead of attaching them to emails. Please note, the Physically Attach Files to Emails setting must be turned on to activate this setting."></i></label>
                                                                    
                                                                    <select name="image_attach_style" id="language" formControlName="image_attach_style" class="form-control">
                                                                        <option value="" selected disabled >Select Language</option>
                                                                        <option value="ab">Abkhazian</option>
                                                                        
                                                                    </select>
                                                                    


                                                                    
                                                                </div>
                                                            </div>


                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label id="track" for="physcially_attach_files">Physically Attach Files to Emails<sup class="clsStric">*</sup></label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="If enabled, the system will attempt to attach files to outgoing emails if they are under the Max Email Attachment Size limit.
                                                                    If disabled, or the file is greater than the limit, the email will contain a link in the email to download the file instead."></i>
                                                                    <div>
                                                                        <div class="custom-control custom-radio custom-control-inline mr-5">
                                                                            <input type="radio" id="customRadio2" class="custom-control-input" formControlName="physcially_attach_files"  name="physcially_attach_files" value="true">
                                                                            <label class="custom-control-label" for="customRadio2">Yes</label>
                                                                        </div>
                                                                        <div class="custom-control custom-radio custom-control-inline">
                                                                            <input type="radio" id="customRadio1" class="custom-control-input" formControlName="physcially_attach_files"  name="physcially_attach_files" value="false">
                                                                            <label class="custom-control-label" for="customRadio1">No</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            


                                                           
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="file_download">File Download Expiration (Hours)<sup class="clsStric">*</sup><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="The amount of time, in hours, until file download links are no longer valid. The maximum expiration is 4320 hours (~180 days), however for higher security keep this setting as short as possible."></i></label>
                                                                    
                                                                    <input name="file_download" type="text" class="form-control mb-3" formControlName="file_download"  placeholder="" autocomplete="off"/>


                                                                    
                                                                </div>
                                                            </div>
                                                        </div>

                                                     

                                                       

                                                        

                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                               
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  [routerLink]="['/clientList']"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="sub">Save Settings <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</main>