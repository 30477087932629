import { Component, OnInit , HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { ClientService } from 'src/app/services/client.service';
import { LocationService } from 'src/app/services/location.service';
@Component({
  selector: 'app-location-detail',
  templateUrl: './location-detail.component.html',
  styleUrls: ['./location-detail.component.css']
})
export class LocationDetailComponent implements OnInit, OnDestroy {

  activeTab = 1;
  currentPath: string;
  tabDetails = [];
  locationSubscription: Subscription;
  locationName: string;
  loadBreadCrumbs = false;
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  locationId:number;
  clientId:number;
  clientName:string;

  constructor(private locationService: LocationService,
              private router: Router,
              private clientService:ClientService) {
  }

  ngOnInit(): void {
    this.tabDetails = [
      { id: 1, title: 'Overview', path: 'overview' },
      { id: 2, title: 'Contacts', path: 'contact' },
      { id: 3, title: 'Notes', path: 'notes' },
      { id: 4, title: 'History', path: 'history' },

    ];
    this.locationSubscription = this.locationService.locationListDetail$.subscribe((data) => {
      if (data == null) {
        return;
      }
      this.locationId = data.locationId;
      this.clientId = data.clientId;
      this.clientName = data.clientName;
      this.locationName = `${data.locationName} (${data.clientName})`;
      this.breadCrumbInputs = [
        { key: 'id', value: [data.clientId.toString(), data.clientName] },
        { key: 'locationId', value: [data.locationId.toString(), data.locationName] }
      ];
      this.loadBreadCrumbs = true;
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.activeTab = changeEvent.nextId;
  }

  componentAdded($evt): void {
    this.currentPath = $evt.route.snapshot.routeConfig.path;
  }

  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (document.body.scrollTop > 132 || document.documentElement.scrollTop > 132) {
      document.getElementById('stickyoney').classList.add('stick_me');
    }else{
      document.getElementById('stickyoney').classList.remove('stick_me')
    }
  }

  ngOnDestroy(): void {
    this.locationSubscription.unsubscribe();
  }

  onclickEditProfile()
  {
    this.edit(this.locationId);
  }

  private edit(id: number): void {
    this.locationService.setReturnUrl(this.router.url);
    this.router.navigate(['client/location/edit', id]);
  }

  onNewContactClick(): void {
    this.clientService.clientDetailSubject$.next({
        id: this.clientId,
        name: this.clientName,
      });
    this.router.navigate([`client/${this.clientId}/contact/add`],{ queryParams:{locationId:this.locationId}});
  }

  onClickForNote():void{
    this.router.navigate([`client/location/${this.locationId}/notes/add`]);
  }

}
