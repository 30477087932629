import { FormArray, ValidatorFn } from '@angular/forms';

export function minSelectedCheckboxes(checkForIsSelected = true, min = 1): ValidatorFn {
    const validator: ValidatorFn = (formArray: FormArray) => {
        const values = formArray.controls
            // get a list of checkbox values (boolean)
            .map(control => control.value);
        // total up the number of checked checkboxes
        const totalSelected = checkForIsSelected ? values.filter(x => x.isSelected).length
            : values.reduce((prev, next) => next ? prev + next : prev, 0);

        // if the total is not greater than the minimum, return the error message
        return totalSelected >= min ? null : { required: true };
    };
    return validator;
}
