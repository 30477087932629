import { HttpClient, JsonpClientBackend } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ClientContactAddModel } from '../models/client-contact/client.contact.add.model';
import { ClientContactDetailModel } from '../models/client-contact/client.contact.detail.model';
import { ClientContactEditModel } from '../models/client-contact/client.contact.edit.model';
import { SalesPersonAssignModel } from '../models/client-contact/salesperson.assign.model';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';
import { SelectListItem } from '../models/shared/select.list.item';
import { EmployeeEditModel } from '../models/staff/employee.edit.model';

@Injectable({
    providedIn: 'root'
})
export class ClientContactService {

    private returnUrl: string;
    public contactDetailSubject$ = new BehaviorSubject<{ id: string, name: string, clientId: number, clientName: string }>(null);
    public contactDetail$ = this.contactDetailSubject$.asObservable();

    public setReturnUrl = (url: string) => this.returnUrl = url;

    public getReturnUrl = () => this.returnUrl;

    constructor(private http: HttpClient) {
    }

    public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/client-contacts/query`, dataTablesParameters, {});
    }

    public queryForDetails(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>
            (`${environment.apiBaseUrl}/client-contacts/query-for-details`, dataTablesParameters, {});
    }

    public post(model: ClientContactAddModel): Observable<any> {
        const formData = new FormData();
        formData.append('firstName', model.firstName);
        formData.append('middleName', model.middleName);
        formData.append('lastName', model.lastName);
        formData.append('email', model.email);
        formData.append('title', model.title);
        formData.append('role', model.role);
        formData.append('country', model.country);
        formData.append('fax', model.fax);
        formData.append('dob', model.dob);
        formData.append('accountCode', model.accountCode);
        formData.append('salesPerson', model.salesPerson);
        formData.append('caseManager', model.caseManager);
        formData.append('clientId', JSON.stringify(model.clientId));
        formData.append('locationId', JSON.stringify(model.locationId));
        if (model.tags !== null && model.tags !== undefined) {
            model.tags.forEach((tag) => formData.append('tags[]', tag));
        }
        if (model.contactNos !== null && model.contactNos !== undefined) {
            for (let i = 0; i < model.contactNos.length; i++) {
                formData.append(`contactNos[${i}].contactTypeId`, JSON.stringify(model.contactNos[i].contactTypeId));
                formData.append(`contactNos[${i}].contactNumber`, model.contactNos[i].contactNumber);
                formData.append(`contactNos[${i}].extensionNote`, model.contactNos[i].extensionNote);
            }
        }
        formData.append('generalNotes', model.generalNotes);
        formData.append('adminNotes', model.adminNotes);
        if (model.profileImgFile != null && model.profileImgFile !== undefined) {
            formData.append('profileImgFile', model.profileImgFile, model.profileImgFile.name);
        }
        return this.http.post<any>(`${environment.apiBaseUrl}/client-contacts`, formData, {});
    }

    public put(model: ClientContactEditModel): Observable<any> {
        const formData = new FormData();
        formData.append('id', model.id);
        formData.append('firstName', model.firstName);
        formData.append('middleName', model.middleName);
        formData.append('lastName', model.lastName);
        formData.append('email', model.email);
        formData.append('title', model.title);
        formData.append('role', model.role);
        formData.append('country', model.country);
        formData.append('fax', model.fax);
        formData.append('dob', model.dob);
        formData.append('accountCode', model.accountCode);
        formData.append('salesPerson', model.salesPerson);
        formData.append('caseManager', model.caseManager);
        formData.append('clientId', JSON.stringify(model.clientId));
        formData.append('locationId', JSON.stringify(model.locationId));
        if (model.tags !== null && model.tags !== undefined) {
            model.tags.forEach((tag) => formData.append('tags[]', tag));
        }
        formData.append('generalNotes', model.generalNotes);
        formData.append('adminNotes', model.adminNotes);
        if (model.profileImgFile != null && model.profileImgFile !== undefined) {
            formData.append('profileImgFile', model.profileImgFile, model.profileImgFile.name);
        }
        if (model.contactNos !== null && model.contactNos !== undefined) {
            for (let i = 0; i < model.contactNos.length; i++) {
                formData.append(`contactNos[${i}].contactTypeId`, JSON.stringify(model.contactNos[i].contactTypeId));
                formData.append(`contactNos[${i}].contactNumber`, model.contactNos[i].contactNumber);
                formData.append(`contactNos[${i}].id`, JSON.stringify(model.contactNos[i].id));
                formData.append(`contactNos[${i}].extensionNote`, model.contactNos[i].extensionNote);
            }
        }
        return this.http.put<any>(`${environment.apiBaseUrl}/client-contacts`, formData, {});
    }

    public get(id: string): Observable<any> {
        return this.http.get<any>(`${environment.apiBaseUrl}/client-contacts/${id}`);
    }

    public getOverview(id: string): Observable<ClientContactDetailModel> {
        return this.http.get<ClientContactDetailModel>(`${environment.apiBaseUrl}/client-contacts/overview/${id}`);
    }

    public delete(userIds: string[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/client-contacts?contacts=${userIds.join('&contacts=')}`);
    }

    public undelete(userIds: string[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/client-contacts/undelete-contacts?contacts=${userIds.join('&contacts=')}`);
    }

    public getPermissions(id: string): Observable<any> {
        return this.http.get(`${environment.apiBaseUrl}/client-contacts/permissions/${id}`);
    }

    public assignContactSalesperson(model: SalesPersonAssignModel): Observable<any> {
        return this.http.patch(`${environment.apiBaseUrl}/client-contacts/assign-salesperson`, model);
    }

    public getContactListByClient(clientId: number): Observable<SelectListItem<string>[]>{
        return this.http.get<SelectListItem<string>[]>(`${environment.apiBaseUrl}/client-contacts/get-contact-list-by-client/${clientId}`);
    }

}
