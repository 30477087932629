export enum StaffLicenseFilterByOptions {
    None = 0,
    ExpiringLicenses = 1,
    ExpiredLicenses = 2,
    DeletedLicenses = 3,
    StaffManagers = 4
}

export enum StaffLicenseSearchOptions {
    LicenseNumber = 0,
    LicenseName = 1,
    StaffName = 2,
    StateOrProvince = 3
}