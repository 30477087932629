import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { forkJoin } from 'rxjs';
import { StaffLicenseFilterOptionMapping, StaffLicenseSearchOptionMapping } from 'src/app/helpers/constants/filter-and-search/staff.license.fiter.and.search.mapping';
import { StaffLicenseFilterByOptions } from 'src/app/helpers/enums/filters-and-searchs/staff.license.search.and.filter.options';
import { FilterInputModel, SearchByModel } from 'src/app/models/shared/filters.model';
import { StaffLicenseService } from 'src/app/services/staff.license.service';
import { StaffService } from 'src/app/services/staff.serrvice';

@Component({
  selector: 'app-staff-license-search-and-filter',
  templateUrl: './staff-license-search-and-filter.component.html',
  styleUrls: ['./staff-license-search-and-filter.component.css']
})
export class StaffLicenseSearchAndFilterComponent implements OnInit {

  filterBy: FilterInputModel[] = [];
  filterIn: FilterInputModel[] = [];
  isFilterInVisible = true;

  @Output()
  onApplyFilter = new EventEmitter();

  @Output()
  resetFilters = new EventEmitter();

  @Output()
  closeFilterNav = new EventEmitter();

  searchBy: SearchByModel[] = [];


  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();

  constructor(
    private staffLicenseService: StaffLicenseService,
    private staffService: StaffService) {
    StaffLicenseSearchOptionMapping.forEach((key, value) => this.searchBy.push({ id: value, name: key }));
  }

  ngOnInit(): void {
    this.loadFilterOptions();
  }

  private loadFilterOptions(): void {
    this.staffService.getManagers()
      .subscribe((managers) => {
        const filterByOptions = [];
        StaffLicenseFilterOptionMapping.forEach((key, value) =>
          filterByOptions.push({ name: key, id: `${value}`, group: undefined, isSelected: value === StaffLicenseFilterByOptions.None })
        );
        managers.forEach((item) => filterByOptions.push({
          name: item.value,
          id: `${StaffLicenseFilterByOptions.StaffManagers}_${item.id}`, group: 'Staff Managers'
        }));
        this.filterBy = filterByOptions;
      });
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onFilterNavClose(): void {
    this.closeFilterNav.emit();
  }

  onResetFilters(): void {
    this.loadFilterOptions();
    this.resetFilters.emit();
  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }
}
