import { CustomFieldEditModel } from './../../../../../models/custom-fields/CustomFieldEditModel';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomfieldService } from './../../../../../services/customfield.service';
import { CountryModel } from './../../../../../models/shared/country.model';
import { CountryService } from './../../../../../services/country.service';
import { ToasterService } from './../../../../../services/toater.service';
import { CustomFieldTypes } from './../../../../../helpers/enums/settings/customField/CustomFieldTypes';
import { Component, OnInit } from '@angular/core';
import { CustomFieldCaseCategoriesLabel } from 'src/app/helpers/constants/CustomFields/CustomFieldCaseCategoriesLabel';
import { CustomFieldTypesLabel } from 'src/app/helpers/constants/CustomFields/CustomFieldTypesLabel';
import { Validators, FormBuilder, FormArray, FormControl, FormGroup, AbstractControl } from '@angular/forms';
import { AppUtils } from 'src/app/helpers';
import { CustomFieldAddModel } from 'src/app/models/custom-fields/CustomFieldAddModel';
import { CustomFieldRedactMethodLabel } from 'src/app/helpers/constants/CustomFields/CustomFieldRedactMethodLabel';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { CustomFieldCaseCategories } from 'src/app/helpers/enums/settings/customField/CustomFieldCaseCategories';

@Component({
  selector: 'app-edit-custom-field',
  templateUrl: './edit-custom-field.component.html',
  styleUrls: ['./edit-custom-field.component.css']
})
export class EditCustomFieldComponent implements OnInit {

  @BlockUI('container-blockui-new-case-flag') blockUI: NgBlockUI;
  customFieldCaseCategoriesLabel: { id: number, name: string }[] = [];
  customFieldCaseTypesLabel: { id: number, name: string }[] = [];
  customFieldRedactMethodLabel: { id: number, name: string }[] = [];
  countries: CountryModel[] = [];
  model: CustomFieldEditModel;

  form: FormGroup;
  submitted = false;
  isSelected = false;
  selectedValue: any;

  constructor(private formBuilder: FormBuilder, public appUtil: AppUtils,
              private toasterService: ToasterService, private countryService: CountryService,
              private customFieldService: CustomfieldService, private router: Router,
              private route: ActivatedRoute) {
    this.model = new CustomFieldEditModel();
    this.route.params.subscribe(params => this.model.id = Number(params.id));
  }

  loadDefaultData(): void {
    for (const [key, value] of CustomFieldCaseCategoriesLabel) {
      this.customFieldCaseCategoriesLabel.push({ id: key, name: value });
    }
    for (const [key, value] of CustomFieldRedactMethodLabel) {
      this.customFieldRedactMethodLabel.push({ id: key, name: value });
    }
    for (const [key, value] of CustomFieldTypesLabel) {
      this.customFieldCaseTypesLabel.push({ id: key, name: value });
    }
    this.blockUI.start();
    forkJoin([
      this.countryService.getCountries(),
      this.customFieldService.get(this.model.id)
    ]).subscribe(([
      countryData,
      cfModel
    ]) => {
      this.countries = countryData;
      // setting up the form
      this.form.controls.redact_Yesno.setValue(cfModel.redact != null ? cfModel.redact.toString() : 'false', { onlySelf: true });
      this.form.controls.require_or_not.setValue(cfModel.required.toString(), { onlySelf: true });
      this.form.controls.internal.setValue(cfModel.internal != null ? cfModel.internal.toString() : 'false', { onlySelf: true });

      this.form.controls.redactMethod.setValue(cfModel.redactMethod ? cfModel.redactMethod : '1', { onlySelf: true });

      this.form.controls.label.setValue(cfModel.label);
      this.form.controls.help_text.setValue(cfModel.helpText);
      this.form.controls.use_for.setValue(cfModel.useForId);
      this.form.controls.field_type.setValue(cfModel.fieldTypeId);
      this.setDefaultJsonValue({ typeId: cfModel.fieldTypeId, jsonValue: cfModel.defaultJsonValue });
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate(['/setting/global/custom-field-list']);
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
  }
  // tslint:disable-next-line: typedef
  get GetCustomFieldCaseType() {
    return CustomFieldTypes;
  }

  get GetCustomFieldCaseCategories() {
    return CustomFieldCaseCategories;
  }

  setDefaultJsonValue({ typeId, jsonValue }: { typeId: number; jsonValue: string; }): void {
    const types = CustomFieldTypes;
    switch (typeId) {
      case types.Address || types.Date || types.DateBirthday || types.EmailAddress ||
        types.NameFirstMiddleLast || types.Section || types.WebsiteUrl || types.RatingNumber: {
          break;
        }
      case types.CountryDropdown: {
        this.form.controls.dform_Country.setValue(jsonValue);
        break;

      }
      case types.InputLongAnswer: {
        this.form.controls.dform_Longinput.setValue(jsonValue);
        break;

      }
      case types.InputShortAnswer: {
        this.form.controls.dform_Shortinput.setValue(jsonValue);
        break;
      }
      case types.Money: {
        this.form.controls.dform_Money.setValue(jsonValue);
        break;

      }
      case types.MultipleChoiceDropdown: {
        const value = JSON.parse(jsonValue);
        if (value && value.length > 0) {
          value.forEach(element => {
            const item = this.formBuilder.group({
              // tslint:disable-next-line: triple-equals
              default_value_forMultiple: element.default_value_forMultiple == 'true' ? true : false,
              dform_multiplechoices: element.dform_multiplechoices,
            });
            this.multipleChoiceDropdown().push(item);
          });
        }
        break;
      }
      case types.MultipleChoiceRadio: {
        const value = JSON.parse(jsonValue);
        if (value && value.length > 0) {
          value.forEach(element => {
            const item = this.formBuilder.group({
              default_value_forMultipleRadio: element.default_value_forMultipleRadio,
              dform_multipleChoiceRadio: element.dform_multipleChoiceRadio,
            });
            this.multipleChoiceRadio().push(item);
          });
        }
        break;
      }
      case types.Number: {
        this.form.controls.dform_Number.setValue(jsonValue);
        break;

      }
      case types.YesOrNo: {
        if (jsonValue != null) {
          this.form.controls.dform_YesnoPart2.setValue(jsonValue);
        }
        break;

      }
      case types.YesNoOrNA: {
        this.form.controls.dform_Yesnona.setValue(jsonValue);
        break;
      }
      case types.RatingMultipleChoiceDropdown: {
        const value = JSON.parse(jsonValue);
        if (value && value.length > 0) {
          value.forEach(element => {
            const item = this.formBuilder.group({
              default_value_forRatingDrop: element.default_value_forRatingDrop === 'true' ? true : false,
              dform_ratingMultiDrop: element.dform_ratingMultiDrop,
              dform_ratingMultiDrop_scale: element.dform_ratingMultiDrop_scale
            });
            this.ratingMultipleChoiceDrop().push(item);
          });
        }
        break;
      }
      case types.RatingYesNoOrNA: {
        this.form.controls.dform_RatingYesno.setValue(jsonValue);
        break;

      }
      default: {
      }
    }
  }


  ngOnInit(): void {
    this.loadDefaultData();
    this.defaultForm();
  }
  defaultForm(): void {
    this.form = this.formBuilder.group({
      label: new FormControl('', Validators.required),
      help_text: new FormControl(),
      use_for: new FormControl('', Validators.required),
      field_type: new FormControl('', Validators.required),
      require_or_not: new FormControl('', Validators.required),
      internal: new FormControl('', Validators.required),
      customSwitch0: new FormControl(),
      customSwitch1: new FormControl(),
      customSwitch2: new FormControl(),

      dform_Country: new FormControl(),
      text_field: new FormControl(),
      redact_Yesno: new FormControl(),
      dform_Longinput: new FormControl(),
      dform_Shortinput: new FormControl(),
      dform_Money: new FormControl(),
      dform_Number: new FormControl(),
      dform_YesnoPart2: new FormControl(),
      dform_Yesnona: new FormControl(),
      dform_RatingYesno: new FormControl(),

      redactMethod: new FormControl(),

      multipleChoiceDropdown: this.formBuilder.array([]),
      multipleChoiceRadio: this.formBuilder.array([]),
      ratingMultipleChoiceDrop: this.formBuilder.array([])

    });

    // this.form.controls.dform_YesnoPart2.setValue('false', { onlySelf: true });

    this.form.controls.redact_Yesno.setValue('false', { onlySelf: true });

    this.form.controls.redact_Yesno.setValue('false', { onlySelf: true });
    this.form.controls.require_or_not.setValue('false', { onlySelf: true });
    this.form.controls.internal.setValue('false', { onlySelf: true });

    this.form.controls.redactMethod.setValue('1', { onlySelf: true });
    this.form.controls.dform_Country.setValue('1', { onlySelf: true });


    this.form.controls.dform_Yesnona.setValue('na', { onlySelf: true });
    this.form.controls.dform_RatingYesno.setValue('na', { onlySelf: true });
  }
  // for dynamic text field

  multipleChoiceDropdown(): FormArray {
    return this.form.get('multipleChoiceDropdown') as FormArray;
  }


  newEmployee(): FormGroup {
    return this.formBuilder.group({
      default_value_forMultiple: '',
      dform_multiplechoices: '',
    });
  }

  addEmployee(): void {
    const arr = this.multipleChoiceDropdown();
    const length = arr.value.length;
    if (length > 0 && arr.value.some(x => !x.dform_multiplechoices)) {
      this.toasterService.error('value cannot be empty!');
      return;
    }
    arr.push(this.newEmployee());
  }
  removeAll(index: number): void {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.form.controls.multipleChoiceDropdown.value.length; i++) {
      this.form.controls.multipleChoiceDropdown.value[i].default_value_forMultiple = 'false';
    }
    this.form.controls.multipleChoiceDropdown.value[index].default_value_forMultiple = 'true';
  }

  removeEmployee(empIndex: number): void {
    this.multipleChoiceDropdown().removeAt(empIndex);
  }

  // dynamic radio buttons
  multipleChoiceRadio(): FormArray {
    return this.form.get('multipleChoiceRadio') as FormArray;
  }
  addMultipleChoiceRadio(): void {
    const arr = this.multipleChoiceRadio();
    const length = arr.value.length;
    if (length > 0 && arr.value.some(x => !x.dform_multipleChoiceRadio)) {
      this.toasterService.error('value cannot be empty!');
      return;
    }
    arr.push(this.newEmployee2());
  }
  newEmployee2(): FormGroup {
    return this.formBuilder.group({
      default_value_forMultipleRadio: '',
      dform_multipleChoiceRadio: '',
    });
  }

  removeEmployee2(empIndex: number): void {
    this.multipleChoiceRadio().removeAt(empIndex);
  }
  removeAll2(index: number): void {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.form.controls.multipleChoiceRadio.value.length; i++) {
      this.form.controls.multipleChoiceRadio.value[i].default_value_forMultiple = 'false';
    }
    this.form.controls.multipleChoiceRadio.value[index].default_value_forMultiple = 'true';
  }

  // dynamic rating dropdown
  ratingMultipleChoiceDrop(): FormArray {
    return this.form.get('ratingMultipleChoiceDrop') as FormArray;
  }
  addRatingDrop(): void {

    const arr = this.ratingMultipleChoiceDrop();
    const length = arr.value.length;
    if (length > 0 && arr.value.some(x => !x.dform_ratingMultiDrop || !x.dform_ratingMultiDrop_scale)) {
      this.toasterService.error('value cannot be empty!');
      return;
    }
    arr.push(this.ratingDropForm());
  }

  ratingDropForm(): FormGroup {
    return this.formBuilder.group({
      default_value_forRatingDrop: 'false',
      dform_ratingMultiDrop: '1',
      dform_ratingMultiDrop_scale: '100'
    });
  }

  removeRatingDrop(empIndex: number): void {
    this.ratingMultipleChoiceDrop().removeAt(empIndex);
  }
  removeallRatingDrop(index: number): void {
    // tslint:disable-next-line: prefer-for-of
    for (let i = 0; i < this.form.controls.ratingMultipleChoiceDrop.value.length; i++) {
      this.form.controls.ratingMultipleChoiceDrop.value[i].default_value_forRatingDrop = 'false';
    }
    this.form.controls.ratingMultipleChoiceDrop.value[index].default_value_forRatingDrop = 'true';
  }


  onCancel(): void {
    this.router.navigate(['/setting/global/custom-field-list']);
  }


  onSubmit(): void {
    this.submitted = true;
    // stop here if form is invalid.
    if (this.form.invalid) {
      this.toasterService.error('Please fill all required fields');
      return;
    }
    this.blockUI.start();
    const controls = this.form.controls;
    const jsonValue = this.customFieldService.getDefaultValue(controls.field_type.value, this.form);
    if (!jsonValue.isValid) {
      this.toasterService.error('Value cannot be empty!');
      return;
    }
    const redactResponse = this.customFieldService.getRedactValue(controls.field_type.value, this.form);
    const internal = Number(controls.use_for.value) === CustomFieldCaseCategories.Reviews ||
      Number(controls.use_for.value) === CustomFieldCaseCategories.Staff ? null :
      (controls.internal.value == 'true' ? true : false);
    this.model.label = controls.label.value;
    this.model.helpText = controls.help_text.value;
    this.model.useForId = Number(controls.use_for.value);
    this.model.fieldTypeId = Number(controls.field_type.value);
    // tslint:disable-next-line: triple-equals
    this.model.required = controls.require_or_not.value == 'true' ? true : false;;
    // tslint:disable-next-line: triple-equals
    this.model.internal = internal;
    this.model.defaultJsonValue = jsonValue.value;
    this.model.redact = redactResponse.redact;
    this.model.redactMethod = redactResponse.method;
    this.customFieldService.put(this.model).subscribe(resposne => {
      setTimeout(() => {
        this.router.navigate(['/setting/global/custom-field-list']);
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Custom field updated successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }


}
