<main class="content pt-7">
    <section>
        <div>
            <p>
               <strong>  404: Not Found</strong>
            </p>
            <p>
                There may be a misspelling in the URL entered, or the page you are looking for may no longer exist
            </p>
        </div>
    </section>
</main>