import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Callbacks } from 'jquery';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { delay } from 'rxjs/operators';
import { AppUtils } from 'src/app/helpers';
import { ComponentDestoryHelper } from 'src/app/helpers/component-destory.helper';
import { CustomFieldTypes } from 'src/app/helpers/enums/settings/customField/CustomFieldTypes';
import { CaseService } from 'src/app/services/case.service';
import { CasetypeService } from 'src/app/services/casetype.service';
import { SubjectTypeInCaseService } from 'src/app/services/subject.type.in.case.service';
import { SubjectTypeService } from 'src/app/services/subjecttype.service';
import { ToasterService } from 'src/app/services/toater.service';
import { callbackify } from 'util';

@Component({
  selector: 'app-add-case-case-summary',
  templateUrl: './add-case-case-summary.component.html',
  styleUrls: ['./add-case-case-summary.component.css']
})
export class AddCaseCaseSummaryComponent extends ComponentDestoryHelper implements OnInit, OnDestroy {
  @BlockUI('add-case-second-ui') blockUI: NgBlockUI;
  submitted: boolean;
  caseTypeId = 0;
  caseId = 0;
 
  
  caseSummaryData: any = {
    dueDate: [null]
  };
  
  constructor(private router: Router, private route: ActivatedRoute, private caseTypeService: CasetypeService,
              private subjectTypeInCaseService: SubjectTypeInCaseService , private caseService: CaseService,
              private appUtil: AppUtils,
              private toasterService: ToasterService, private subjectTypeService: SubjectTypeService) {
      super();
      this.route.queryParams.subscribe((parmas) => {
        this.caseTypeId = parmas.caseTypeId;
        this.caseId = parmas.caseId;
      });

     }
  ngOnDestroy(): void {
    this.ngAfterOnDestroy();
  }

  ngOnInit(): void {
    
    this.loadCaseSummary(this.caseId);
  }
    // loadCaseById(): void{
    //   const subscription = this.caseService.
    // }

    loadCaseSummary(caseId: number): void{
     
      this.blockUI.start();

      const subscription = this.caseService.getCaseSummary(caseId).subscribe((data: any) => {
        
        if (data){
        this.caseSummaryData = data;
        console.log(this.caseSummaryData);
        console.log('customphone',this.caseSummaryData.clientPhone);
        
        
        
       

        
        }
      }, (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
      }, () => {
        this.blockUI.stop();
      });
      this.subscriptions.push(subscription);
    }
  loadDefaults(): void{
    forkJoin([
      this.caseTypeService.getById(this.caseTypeId),
      this.subjectTypeInCaseService.getByCaseId(this.caseId),
    ]).subscribe(
      ([caseTypeData, subjecttypeInCaseData]) => {
      },
      (error: any) => {
        this.toasterService.ProcessErrorResponse(error);
      },
      () => this.blockUI.stop()
    );
  }
  get GetCustomFieldCaseType() {
    return CustomFieldTypes;
  }
  converttodecimal(data:any) {
   
    return parseFloat(data).toFixed(2);
     
  }
  displayCustomField(data: any): string{
    
    if (data.fieldTypeId === this.GetCustomFieldCaseType.Address){
      const parsedValue = JSON.parse(data.value);
      let value = '';
      if (parsedValue.address1) {
        value += `${parsedValue.address1} `;
      }
      if (parsedValue.address2) {
        value += `${parsedValue.address2} `;
      }
      if (parsedValue.address3) {
        value += `${parsedValue.address3} `;
      }
      if (parsedValue.city) {
        value += `${parsedValue.city}, `;
      }
      if (parsedValue.state) {
        value += `${parsedValue.state} `;
      }
      if (parsedValue.zip_code) {
        value += `${parsedValue.zip_code} `;
      }
      return value;
    }
    if (
      data.fieldTypeId ===
      this.GetCustomFieldCaseType.NameFirstMiddleLast
    ) {
      const parsedValue = JSON.parse(data.value);
      const value = `${parsedValue.last},${parsedValue.first}`;
      if (parsedValue.middle) {
        return `${value} ${parsedValue.middle}`;
      }
      return value;
    }
    if (
      data.fieldTypeId ===
        this.GetCustomFieldCaseType.DateBirthday ||
      data.fieldTypeId === this.GetCustomFieldCaseType.Date
    ) {
      const parsedValue = JSON.parse(data.value);

      const d = new Date(
        parsedValue.year,
        parsedValue.month,
        parsedValue.day
      );
      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d);
      const mo = new Intl.DateTimeFormat('en', { month: 'short' }).format(d);
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d);
      return `${mo} ${da},${ye}`;
    }
    if (data.value === 'null'){
    return '';
    }
    if (data && data.value){
    return data.value;
    }
    return '';
  }
 
  btnClick= function () {
    this.router.navigate(['/edit-case-second-steps'], {
      queryParams: {
        caseTypeId: this.caseTypeId,
        caseId: this.caseId,
        isSubjectType:true,
        panelStep:4
      }
    });
    
  }
  
  btnCaseRedirectionClick= function () {
    
    this.router.navigate(['/edit-case-steps'], {
      queryParams: {
        caseId: this.caseId,
        caseTypeId: this.caseTypeId

      }
    });
    
  }
  
  Navigatetsupportingfile= function () {
   
    this.router.navigate(['/edit-case-third-steps'], {
      queryParams: {
        caseTypeId: this.caseTypeId,
        caseId: this.caseId,
       
      }
    });
    
  }
  btnredirecttofirststep=function(){
 
    this.router.navigate(['/edit-case-first-steps'], {
      queryParams: {
        caseTypeId: this.caseTypeId,
        caseId: this.caseId,
       
      }
    });
  }
    RedirectToCaseList=function(){
   
    this.router.navigate(['/case-list'], { 
    });
  }
  showmessage=function(){
    debugger;
   
    setTimeout(() => {
      this.toasterService.success('Case created successfully');
     
    }, 50);
     this.RedirectToCaseList();
      
  }
  getFormattedName(data: any[]):string{
   
    let name = "";
    if(data.length>0) {
      data.forEach((val,index)=>{
        name = name + val.value;
        if(index < data.length-1){
          name = name + ",";
        }
      })
    }
   
    return name;
  }
  
  displayreferencevalue(data: any):string{
    debugger;
     
     var result = this.isJsonString(data)
     if(result==true)
     {
      const parsedValue = JSON.parse(data);
      const d = new Date(
        parsedValue.year,
        parsedValue.month,
        parsedValue.day
      );
       return `${parsedValue.month}/${parsedValue.day}/${parsedValue.year}`

     }
    
     return data;
   }
   isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
  Contact(data: any[]):string{
   debugger;
    let name = "";
    if(data.length>0) {
      data.forEach((val,index)=>{
        name = name + val.contactNumber;
        if(index < data.length-1){
          name = name + ",";
        }
      })
    }
   
    return name;
  }

}
