<div class="row mt-4">
    <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-2 bigSize"  [innerHTML]="appUtils.genrateIconExtension('asd.html')">
                       
                    </div>
                    <div class="col-8  d-block my-auto">
                        <h4 class="font-weight-bold wrapMe">ClaimNotes.html</h4>  
                    </div>
                    <div class="col-2 text-right align-self-end justify-content-end">
                        <input type="checkbox" >
                    </div>
                </div>
                <div class="row mt-5" >
                    <div class="col-12">
                        <p>Related Case Files</p>
                    </div>
                    <div class="col-12">
                        <p>Created on: 6/18/20 2:06 PM</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-2 bigSize"  [innerHTML]="appUtils.genrateIconExtension('asd.pdf')">
                       
                    </div>
                    <div class="col-8  d-block my-auto">
                        <h4 class="font-weight-bold wrapMe">ClaimNotes.pdf</h4>  
                    </div>
                    <div class="col-2 text-right align-self-end justify-content-end">
                        <input type="checkbox" >
                    </div>
                </div>
                <div class="row mt-5" >
                    <div class="col-12">
                        <p>Related Case Files</p>
                    </div>
                    <div class="col-12">
                        <p>Created on: 6/18/20 2:06 PM</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-2 bigSize"  [innerHTML]="appUtils.genrateIconExtension('asd.png')">
                       
                    </div>
                    <div class="col-8  d-block my-auto">
                        <h4 class="font-weight-bold wrapMe">ClaimNotes.png</h4>  
                    </div>
                    <div class="col-2 text-right align-self-end justify-content-end">
                        <input type="checkbox" >
                    </div>
                </div>
                <div class="row mt-5" >
                    <div class="col-12">
                        <p>Related Case Files</p>
                    </div>
                    <div class="col-12">
                        <p>Created on: 6/18/20 2:06 PM</p>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-2 bigSize"  [innerHTML]="appUtils.genrateIconExtension('asd.txt')">
                       
                    </div>
                    <div class="col-8  d-block my-auto">
                        <h4 class="font-weight-bold wrapMe">ClaimNotes.txt</h4>  
                    </div>
                    <div class="col-2 text-right align-self-end justify-content-end">
                        <input type="checkbox" >
                    </div>
                </div>
                <div class="row mt-5" >
                    <div class="col-12">
                        <p>Related Case Files</p>
                    </div>
                    <div class="col-12">
                        <p>Created on: 6/18/20 2:06 PM</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-2 bigSize"  [innerHTML]="appUtils.genrateIconExtension('asd.txt')">
                       
                    </div>
                    <div class="col-8  d-block my-auto">
                       <h4 class="font-weight-bold wrapMe">ClaimNotes.txt</h4>
                    </div>
                    <div class="col-2 text-right align-self-end justify-content-end">
                        <input type="checkbox" >
                    </div>
                </div>
                <div class="row mt-5" >
                    <div class="col-12">
                        <p>Folder, 0 Items (Internal)</p>
                    </div>
                    <div class="col-12">
                        <p>Created on: 6/18/20 2:06 PM</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-2 bigSize"  [innerHTML]="appUtils.genrateIconExtension('asd.other')">
                       
                    </div>
                    <div class="col-8  d-block my-auto">
                        <a class="link m-r-10" [routerLink]="['/case-list']"><h4 class="font-weight-bold wrapMe">ClaimNotes</h4>  </a> 
                    </div>
                    <div class="col-2 text-right align-self-end justify-content-end">
                        <input type="checkbox" >
                    </div>
                </div>
                <div class="row mt-5" >
                    <div class="col-12">
                        <p>Related Case Folder</p>
                    </div>
                    <div class="col-12">
                        <p>Created on: 6/18/20 2:06 PM</p>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="col-md-3">
        <div class="card">
            <div class="card-body">
                <div class="row">
                    <div class="col-2 bigSize"  >
                        <i class="fas fa-folder"></i>
                    </div>
                    <div class="col-8  d-block my-auto">
                        <a class="link m-r-10" [routerLink]="['/case-list']"><h4 class="font-weight-bold wrapMe">New Folder</h4>  </a> 
                    </div>
                    <div class="col-2 text-right align-self-end justify-content-end">
                        <input type="checkbox" >
                    </div>
                </div>
                <div class="row mt-5" >
                    <div class="col-12">
                        <p>Related Case Folder</p>
                    </div>
                    <div class="col-12">
                        <p>Created on: 6/18/20 2:06 PM</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
  
</div>
