import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ClientService } from 'src/app/services/client.service';

@Component({
  selector: 'app-client-edit-note',
  templateUrl: './client-edit-note.component.html',
  styleUrls: ['./client-edit-note.component.css']
})
export class ClientEditNoteComponent implements OnInit, OnDestroy {

  clientSubscription: Subscription;
  breadCrumbInputs: { key: string, value: string[] }[] = [];

  constructor(private clientService: ClientService) {

    this.clientSubscription = this.clientService.clientListDetail$.subscribe((data) => {
      if (data == null || data === undefined) {
        return;
      }
      this.breadCrumbInputs.push({ key: 'id', value: [data.id.toString(), data.name] });
    });
   }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.clientSubscription.unsubscribe();
  }
}
