import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-answer-review',
  templateUrl: './answer-review.component.html',
  styleUrls: ['./answer-review.component.css']
})
export class AnswerReviewComponent implements OnInit {

  user = [];
  submitted: boolean;
  public form: FormGroup;

  constructor(private fb: FormBuilder) {
    this.form = this.fb.group({
      date: ['', Validators.required],
      salesPersonId: ['', Validators.required],
      
    });
   }

  ngOnInit(): void {
  }
  
  onSubmit(): void{

  }

}
