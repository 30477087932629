import { CdkDragDrop } from '@angular/cdk/drag-drop';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators, AbstractControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { CustomFieldCaseCategories } from 'src/app/helpers/enums/settings/customField/CustomFieldCaseCategories';
import { CaseTypeEditModel } from 'src/app/models/casetypes/caseTypeEditModel';
import { CaseTypeViewModel } from 'src/app/models/casetypes/caseTypeViewModel';
import { CustomFieldModel } from 'src/app/models/custom-fields/CustomFieldModel';
import { SubjectTypeViewModel } from 'src/app/models/subjectTypes/subjectTypeViewModel';
import { CaseReviewService } from 'src/app/services/case.review.service';
import { CaseFlagService } from 'src/app/services/caseflag.service';
import { CaseServicesService } from 'src/app/services/caseservice.service';
import { CasetypeService } from 'src/app/services/casetype.service';
import { CustomfieldService } from 'src/app/services/customfield.service';
import { DocumentTemplateService } from 'src/app/services/document.template.service';
import { FolderTemplateService } from 'src/app/services/foldertemplate.service';
import { ModalService } from 'src/app/services/modal.service';
import { SubjectTypeService } from 'src/app/services/subjecttype.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-edit-case-type',
  templateUrl: './edit-case-type.component.html',
  styleUrls: ['./edit-case-type.component.css']
})
export class EditCaseTypeComponent implements OnInit {
  @BlockUI('container-blockui-edit-case-type') blockUI: NgBlockUI;
  form: FormGroup;
  viewModel: CaseTypeViewModel;
  editModel: CaseTypeEditModel;
  submitted = false;
  customCases: CustomFieldModel[] = [];
  subjectTypes: SubjectTypeViewModel[] = [];
  dummy = [];

  referenceData = [
    {
      value: '',
      isCopyToInvoice: true,
      isReferenceType: true,
    },
    {
      value: '',
      isCopyToInvoice: true,
      isReferenceType: true,
    },
    {
      value: '',
      isCopyToInvoice: true,
      isReferenceType: true,
    },
  ];

  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private folderTemplateService: FolderTemplateService,
    private caseTypeService: CasetypeService,
    private router: Router,
    private route: ActivatedRoute,
    private caseFlagService: CaseFlagService,
    private customFieldService: CustomfieldService,
    private subjectTypeService: SubjectTypeService,
    private modalService: ModalService,
    private caseServicesService: CaseServicesService,
    private caseReviewService: CaseReviewService,
    private documentTemplateService: DocumentTemplateService

  ) {
    this.editModel = new CaseTypeEditModel();
    this.viewModel = new CaseTypeViewModel();
    this.route.params.subscribe((data) => {
    this.viewModel.id = data.id;
    });
  }

  get caseReviewsToFormArray(): FormArray {
    return this.form.controls.case_reviews as FormArray;
  }

  get caseSubjectTypeToFormArray(): FormArray {
    return this.form.controls.case_subject_type as FormArray;
  }

  get folderTemplateToFormArray(): FormArray {
    return this.form.controls.folderTemplate as FormArray;
  }
  get caseFlagsToFormArray(): FormArray {
    return this.form.controls.caseFlags as FormArray;
  }
  get referencesToFormArray(): FormArray {
    return this.form.controls.references as FormArray;
  }
  get caseTypeCaseCustomFieldformArray(): FormArray {
    return this.form.get('customCaseFields') as FormArray;
  }
  get caseServicesToFormArray(): FormArray {
    return this.form.controls.case_services as FormArray;
  }
  tagKeyPress(event: KeyboardEvent): void {
    if (this.form.controls.tag.value.length >= 2){
        event.preventDefault();
    }
}
loadCaseCustomFields(): void{
  this.customFieldService.getByUserForId(CustomFieldCaseCategories.Case).subscribe((data) => {
    if (data && data.length !== this.customCases.length){
      data.forEach((element, index) => {
        if (this.customCases.filter(x => x.id === element.id).length === 0){
          this.customCases.splice(index, 0 , element);
        }
      });
      }
  }, (error: any) => {
     this.toasterService.ProcessErrorResponse(error);
  });
}
loadCaseReview(caseTypeViewData: CaseTypeViewModel): void{
  this.caseReviewService.getAll().subscribe(data => {
  // load case review type start
  const st: FormArray = this.caseReviewsToFormArray;
  st.clear();
  data.forEach((d) => {
      st.push(
        this.formBuilder.group({
          id: [d.id],
          name: [d.name],
          isSelected: [caseTypeViewData.caseTypeCaseReviews.map(x => x.caseReviewId).includes(d.id)],
        })
      );
    });
    // load case review type end
  }, (error: any) => {
    this.toasterService.ProcessErrorResponse(error);
  });
}

  loadDefaultFormData(): void{
    this.blockUI.start();
    forkJoin([
      this.folderTemplateService.getAll(),
      this.caseTypeService.getById(this.viewModel.id),
      this.caseFlagService.getAll(),
      this.caseServicesService.getAll(),
      this.subjectTypeService.getAll(),
      this.customFieldService.getByUserForId(CustomFieldCaseCategories.Case),
    ])
    // tslint:disable-next-line: max-line-length
    .subscribe(([folderTemplateAll, caseTypeViewData, caseFlagAll, caseServicesAll, subjectTypeAll,
       customFieldCasesAll]) => {
      this.viewModel = caseTypeViewData;
      this.customCases = customFieldCasesAll;
      // foldertemplate start
      const ft: FormArray = this.folderTemplateToFormArray;
      const referenceData = caseTypeViewData.caseTypeReferences;
      ft.clear();
      folderTemplateAll.forEach((d) => {
          ft.push(
            this.formBuilder.group({
              id: [d.id],
              name: [d.name],
              isSelected: [caseTypeViewData.caseTypeFolderTemplates.map(x => x.folderTemplateId).includes(d.id)],
            })
          );
        });
      // foldertemplate end
       // casetypecustomcasefield start
      const ctcc: FormArray = this.caseTypeCaseCustomFieldformArray;
      ctcc.clear();
      caseTypeViewData.caseTypeCustomFields.forEach((d) => {
           ctcc.push(
             this.formBuilder.group({
              id: d.id,
              customCaseId: [Number(d.customFieldId), [RxwebValidators.unique(), Validators.required]],
              isOpenable: d.isReopenable
             })
           );
         });
       // casetypecustomcasefield end
      // case flags start
      const ct: FormArray = this.caseFlagsToFormArray;
      ct.clear();
      caseFlagAll.forEach((d) => {
          ct.push(
            this.formBuilder.group({
              id: [d.id],
              name: [d.name],
              isSelected: [caseTypeViewData.caseTypeCaseFlags.map(x => x.caseFlagId).includes(d.id)],
            })
          );
        });
      // case flags end
      // loadreferences start
      const rd: FormArray = this.referencesToFormArray;
      rd.clear();
      referenceData.forEach((d) => {
      rd.push(this.formBuilder.group({
         value: [d.name],
         isCopyToInvoice: [d.isCopyToInvoice],
         isReferenceType: [d.isRefNumberRequired],
         id: d.id
      }));
      });
      // loadreferences end
      // load case service start
      const cs: FormArray = this.caseServicesToFormArray;
      cs.clear();
      caseServicesAll.forEach((d) => {
        cs.push(
          this.formBuilder.group({
            id: [d.id],
            name: [d.name],
            isSelected: [caseTypeViewData.caseTypeCaseServices.map(x => x.caseServiceId).includes(d.id)],
          })
        );
      });
      // load case service end
      // load case subject type start
      this.subjectTypes = subjectTypeAll;
      const cst: FormArray = this.caseSubjectTypeToFormArray;
      cst.clear();
      let indexx = 0;
      subjectTypeAll.forEach((d) => {
        const isExist = caseTypeViewData.caseTypeSubjectTypes.map(x => x.subjectTypeId).includes(d.id);
        cst.push(
          this.formBuilder.group({
            id: [d.id],
            name: [d.name],
            isSelected: [isExist],
          })
        );
        if (isExist){
        this.dummy.push({ index: indexx, id: d.id, name: d.name });
        indexx++;
        }
      });
      // load case subject type end
      this.loadCaseReview(caseTypeViewData);
      this.loadDocumentTemplate(caseTypeViewData);
      // load form data
      this.form.controls.name.setValue(this.viewModel.name);
      this.form.controls.tag.setValue(this.viewModel.tag);
      this.form.controls.description.setValue(this.viewModel.description);
      this.form.controls.option_budget_tracking.setValue(this.viewModel.isBugetTracking);
      this.form.controls.option_public_request.setValue(this.viewModel.isPublicCaseRequest);
      this.form.controls.option_admin_notes.setValue(this.viewModel.isAdminNotes);
      this.form.controls.option_case_services.setValue(this.viewModel.isCaseServices);
      this.form.controls.option_notes_instructions.setValue(this.viewModel.isNotesInstructions);
      const defaultSubjectType = this.viewModel.caseTypeSubjectTypes.filter(x => x.isDefault).map(x => Number(x.subjectTypeId));
      this.form.controls.default_subject_type.setValue(defaultSubjectType.length > 0 ? defaultSubjectType[0] : '');

      // load form data end
      this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(
          error,
          'Something went wrong!'
        );
        this.blockUI.stop();
      }
    );
  }
  loadDocumentTemplate(caseTypeViewData: CaseTypeViewModel): void{
    this.blockUI.start();
    this.documentTemplateService.getAll().subscribe((data) => {
      if (!data) {
      return;
      }
  // load documenttemplate start
      const dt: FormArray = this.documentTemplateToFormArray;
      dt.clear();
      data.forEach((d) => {
   dt.push(
       this.formBuilder.group({
         id: [d.id],
         name: [d.name],
         isSelected: [caseTypeViewData.caseTypeDocumentTemplates.map(x => x.documentTemplateId).includes(d.id)],
       })
     );
   });
   // load documenttemplate end
      this.blockUI.stop();
    }, (error: any) => {
   this.blockUI.stop();
   this.toasterService.ProcessErrorResponse(error);
 }, () => {
   this.blockUI.stop();
 });
  }
  get documentTemplateToFormArray(): FormArray {
    return this.form.controls.documentTemplate as FormArray;
  }
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      tag: new FormControl('', [Validators.required, Validators.maxLength(2)]),
      option_budget_tracking: new FormControl(false),
      option_public_request: new FormControl(false),
      option_admin_notes: new FormControl(false),
      option_case_services: new FormControl(false),
      option_notes_instructions: new FormControl(false),
      references: new FormArray([]),
      folderTemplate: new FormArray([]),
      documentTemplate: new FormArray([]),
      caseFlags: new FormArray([]),
      customCaseFields: this.formBuilder.array([]),
      case_services: new FormArray([]),
      case_reviews: new FormArray([]),
      case_subject_type: new FormArray([]),
      default_subject_type: new FormControl(''),
      // phones: this.formBuilder.array([this.formBuilder.control(null)]),
    });
    this.loadDefaultFormData();
  }
// on change of select remove and add array
onChange(indexx: number, idx: number, namex: string, isChecked: boolean): void {
  if (isChecked) {
    this.dummy.push({ index: indexx, id: idx, name: namex });
  } else {
    this.RemoveElementFromObjectArray(indexx);
    if (this.dummy.length === 0) {
      this.form.controls.default_subject_type.setValue('');
    }
  }
}
// remove particular array by index
RemoveElementFromObjectArray(key: number): void {
  this.dummy.forEach((value, index) => {
    if (value.index === key) { this.dummy.splice(index, 1); }
  });
}
  addNewRows(): void {
    this.caseTypeCaseCustomFieldformArray.push(this.initRows());
  }

  deleteRow(index: number): void {
    this.caseTypeCaseCustomFieldformArray.removeAt(index);
  }

  initRows() {
    return this.formBuilder.group({
      id: 0,
      isOpenable: [false],
      customCaseId: [this.customCases.length > 0 ? this.customCases[0].id : '', [RxwebValidators.unique(), Validators.required]],
    });
  }

  addPhone(): void {
    (this.form.get('phones') as FormArray).push(this.formBuilder.control(null));
  }
  getPhonesFormControls(): AbstractControl[] {
    return (this.form.get('phones') as FormArray).controls;
  }

  removePhone(index): void {
    (this.form.get('phones') as FormArray).removeAt(index);
  }
  OnCancel(): void {
    this.router.navigate(['/setting/cases/case-type-list']);
  }
  OnSubmit(): void {
    const caseTypeModel = new CaseTypeEditModel();
    const controls = this.form.controls;
    this.submitted = true;
    if (this.form.invalid){
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    caseTypeModel.id = this.viewModel.id;
    caseTypeModel.name = controls.name.value;
    caseTypeModel.tag = controls.tag.value;
    caseTypeModel.description = controls.description.value;
    caseTypeModel.caseTypeReferences = [];
    caseTypeModel.caseTypeCustomFields = [];
    caseTypeModel.isAdminNotes = controls.option_admin_notes.value;
    caseTypeModel.isBugetTracking = controls.option_budget_tracking.value;
    caseTypeModel.isCaseServices = controls.option_case_services.value;
    caseTypeModel.isNotesInstructions = controls.option_notes_instructions.value;
    caseTypeModel.isPublicCaseRequest = controls.option_public_request.value;
    caseTypeModel.caseFlagIds = controls.caseFlags.value.filter(x => x.isSelected === true).map(x => x.id);
    caseTypeModel.folderTemplateIds = controls.folderTemplate.value.filter(x => x.isSelected === true).map(x => x.id);
    caseTypeModel.documentTemplateIds = controls.documentTemplate.value.filter(x => x.isSelected === true).map(x => x.id);
    caseTypeModel.caseServiceIds = controls.case_services.value
      .filter((x) => x.isSelected === true)
      .map((x) => x.id);
    caseTypeModel.subjectTypeIds = controls.case_subject_type.value
      .filter((x) => x.isSelected === true)
      .map((x) => x.id);
    caseTypeModel.caseReviewIds  = controls.case_reviews.value
      .filter((x) => x.isSelected === true)
      .map((x) => x.id);
    this.form.value.references.map(x => {
      caseTypeModel.caseTypeReferences.push({
        id: x.id,
        name: x.value,
        isCopyToInvoice: x.isCopyToInvoice,
        isRefNumberRequired: x.isReferenceType
      });
    });
        // load custom case field start
    controls.customCaseFields.value.map((x, index) => {
          caseTypeModel.caseTypeCustomFields.push({
            id: x.id,
            caseTypeId: this.viewModel.id,
            customFieldId: Number(x.customCaseId),
            isReopenable: x.isOpenable,
            sequence: index + 1
          });
        });
    if (caseTypeModel.subjectTypeIds.length > 0 && !controls.default_subject_type.value)
    {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    caseTypeModel.defaultSubjectTypeId = controls.default_subject_type.value === '' ? null : Number(controls.default_subject_type.value);
    // load custom case field end
    this.blockUI.start();
    this.caseTypeService.put(caseTypeModel).subscribe((data) => {
      setTimeout(() => {
        this.OnCancel();
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Case-type updated successfully');
      }, 300);
      this.blockUI.stop();

    }, (error: any) => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();

    });
  }
  openCustomFieldPopup(): void{
    this.modalService.openNewCustomFieldPopup(CustomFieldCaseCategories.Case).subscribe((data: any) => {
      if (data){
        this.loadCaseCustomFields();
      }
    }, (error: any) => {
      this.toasterService.ProcessErrorResponse(error);
    });
   }
   onDrop(event: CdkDragDrop<string[]>): void {
    const prevValue  = this.caseTypeCaseCustomFieldformArray.at(event.previousIndex);
    this.deleteRow(event.previousIndex);
    this.caseTypeCaseCustomFieldformArray.insert(event.currentIndex, prevValue);
 }
 addRowAtIndex(index: number): void {
  this.caseTypeCaseCustomFieldformArray.insert(index + 1, this.initRows());
}
}
