import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { RoleAvailableFor } from 'src/app/helpers/enums/roles/role.available.for';
import { AddOrEditRoleRequestModel } from 'src/app/models/roles/add.role.request.model';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { RoleService } from 'src/app/services/role.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';

@Component({
  selector: 'app-edit-user-role',
  templateUrl: './edit-user-role.component.html',
  styleUrls: ['./edit-user-role.component.css']
})
export class EditUserRoleComponent implements OnInit {

  closeResult = '';
  submitted: boolean;
  rolesSelectListItem: SelectListItem<string>[] = [];
  accessGroups: Array<SelectListItem<number>> = [];
  defaultGroupItems: any[] = [];
  model: AddOrEditRoleRequestModel;
  roleCategorySelectListItem: Array<SelectListItem<number>> = [];

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private toasterService: ToasterService,
    private accessGroupService: AccessGroupService,
    private ref: ChangeDetectorRef) {
    this.model = new AddOrEditRoleRequestModel();
    this.activeRoute.params.subscribe(params => this.model.id = params.id);
  }
  defaultval = true;

  @BlockUI() blockUI: NgBlockUI;
  form: FormGroup;
  get roleAvailableFor(): any {
    return RoleAvailableFor;
  }
  get permissionFormArray(): FormArray {
    return this.form.controls.permissions as FormArray;
  }
  get accessGroupFormArray(): FormArray {
    return this.form.controls.accessGroups as FormArray;
  }
  get selectedAccessGroups(): any {
    return this.form.value.accessGroups.filter(x => x.isSelected).map(x => ({ id: x.id, value: x.name }));
  }
  get permissionCategoryValues(): any {
    return this.form.value.permissions.map(x => ({ value: x.id, text: x.permissionCategory }));
  }


  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      rankBelow: [''],
      roleCategory: [''],
      availableFor: [null],
      accessGroups: this.formBuilder.array([], [minSelectedCheckboxes()]),
      defaultGroup: [null, Validators.required],
      permissions: this.formBuilder.array([])
    });
    this.loadData();
  }

  private async loadData(): Promise<void> {
    this.blockUI.start();
    forkJoin([
      this.roleService.getRoles(),
      this.accessGroupService.getAccessGroups(),
      this.roleService.getRoleCategories(),
      this.roleService.get(this.model.id)
    ]).subscribe(async ([
      roles,
      accessGroups,
      roleCategories,
      roleEditModel
    ]) => {
      this.model = roleEditModel;
      this.rolesSelectListItem = roles;
      this.roleCategorySelectListItem = roleCategories;

      this.accessGroups = accessGroups;
      const fa: FormArray = this.accessGroupFormArray;
      fa.clear();

      this.form.controls.name.setValue(this.model.name);
      this.form.controls.description.setValue(this.model.description);
      this.form.controls.rankBelow.setValue(this.model.rankBelow);
      this.form.controls.availableFor.setValue(this.model.availableFor);
      this.form.controls.defaultGroup.setValue(this.model.defaultAccessGroup);
      this.form.controls.roleCategory.setValue(this.model.roleCategory);
      accessGroups.forEach(d => {
        fa.push(this.formBuilder.group({
          id: [d.id],
          name: [d.value],
          isSelected: [this.model.accessGroupIds.includes(d.id)]
        }));
      });
      await this.loadPermissions(this.model.availableFor);
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate(['/setting/role']);
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
  }
  private async loadRolecategories(): Promise<void> {
    this.blockUI.start();
    this.roleService.getRoleCategories().subscribe((data: any) => {
      this.roleCategorySelectListItem = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  private gerPermmissionsFormArray(values: Array<any>): Array<any> {
    const formGroups = [];
    values.forEach(x => formGroups.push(this.formBuilder.group({
      permission: [x.permission],
      isSelected: [this.model.rolePermissions.includes(x.permission)],
      name: [x.name],
      description: [x.description]
    })));
    return formGroups;
  }

  public loadPermissions(roleAvailableFor: RoleAvailableFor): any {
    this.blockUI.start();
    this.roleService.getPermissions(roleAvailableFor).subscribe((permissions: any) => {
      Object.entries(permissions).forEach(
        ([key, value], index) => {
          this.permissionFormArray.push(this.formBuilder.group({
            id: index,
            permissionCategory: [key],
            isSelected: [(value as any[]).every(x => this.model.rolePermissions.includes(x.permission))],
            permissions: this.formBuilder.array(this.gerPermmissionsFormArray(value as any[]))
          }));
        }
      );
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.id = this.model.id;
    this.model.name = this.form.value.name;
    this.model.description = this.form.value.description;
    this.model.rankBelow = this.form.value.rankBelow;
    this.model.availableFor = this.form.value.availableFor;
    this.model.accessGroupIds = this.form.value.accessGroups.filter(x => x.isSelected).map(x => x.id);
    this.model.defaultAccessGroup = Number(this.form.value.defaultGroup);
    this.model.roleCategory = Number(this.form.value.roleCategory);
    this.model.rolePermissions = this.form.value.permissions.flatMap(x => x.permissions)
      .filter(x => x.isSelected).map(x => x.permission);
    this.blockUI.start();
    this.roleService.put(this.model).subscribe(resposne => {
      setTimeout(() => {
        this.router.navigate(['/setting/role']);
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Role updated successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }

  trackByFn(index, item): any {
    return index; // or item.id
  }




  scrollUp() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  selectAll(isChecked: boolean, id: number): void {
    this.form.value.permissions.filter(x => x.id === id)
      .map(x => { x.permissions.map(y => y.isSelected = isChecked); });
  }
  onPermissionChange(permission: any, id: number): void {
    permission.isSelected = !permission.isSelected;
    const categorizedPermission = this.form.value.permissions.find(x => x.id === id);
    if (categorizedPermission != null) {
      categorizedPermission.isSelected = categorizedPermission.permissions.every(x => x.isSelected);
    }
  }

  scroll(el) {
    const e = document.getElementById(el);
    e.scrollIntoView({ behavior: 'smooth', block: 'center' });

  }
}
