import { AppComponent } from '../app.component';
import { DashboardComponent } from '../components/dashboard/dashboard.component';
import { FooterComponent } from '../components/shared/footer/footer.component';
import { HeaderComponent } from '../components/shared/header/header.component';
import { SideBarComponent } from '../components/shared/side-bar/side-bar.component';
import { CaseListComponent } from '../components/case/case-list/case-list.component';
import { CaseRequestsComponent } from '../components/case/case-requests/case-requests.component';
import { CaseSubjectsComponent } from '../components/case/case-subjects/case-subjects.component';
import { CaseUpdatesComponent } from '../components/case/case-updates/case-updates.component';
import { CaseReviewsComponent } from '../components/case/case-reviews/case-reviews.component';
import { ClientListComponent } from '../components/Clients/client-list/client-list.component';
import { ClientContactsComponent } from '../components/Clients/client-contacts/client-contacts.component';
import { ClientNotesComponent } from '../components/Clients/client-notes/client-notes.component';
import { ContactNotesListComponent } from '../components/Clients/contact-notes/contact-notes.component';
import { StaffListComponent } from '../components/staff/staff-list/staff-list.component';
import { InvestigatorSnapshotComponent } from '../components/staff/Investigator-Snapshot/investigator-snapshot.component';
import { LicensesComponent } from '../components/staff/Licenses/licenses.component';
import { StaffNotesComponent } from '../components/staff/staff-notes/staff-notes.component';
import { ExpenseEntriesComponent } from '../components/Expenses/expense-entries/expense-entries.component';
import { ExpenseEntriesStaffComponent } from '../components/Expenses/expense-entries-staff/expense-entries-staff.component';
import { PaymentSipsComponent } from '../components/Expenses/payment-slips/payment-slips.component';
import { InvoiceListComponent } from '../components/invoices/invoice-list/invoice-list.component';
import { PaymentHistoryComponent } from '../components/invoices/payment-history/payment-history.component';
import { RetainersListComponent } from '../components/invoices/retainers/retainers-list.component';
import { ActivityFulfillmentComponent } from '../components/calendar/activity-fulfillment/activity-fulfillment.component';
import { EventListComponent } from '../components/calendar/event-list/event-list.component';
import { ScheduleComponent } from '../components/calendar/schedule/schedule.component';
import { TaskListComponent } from '../components/calendar/task-list/task-list.component';
import { CalendarsComponent } from '../components/calendar/calendars/calendars.component';
import { EventMapComponent } from '../components/calendar/event-map/event-map.component';
import { AddCaseComponent } from '../components/case/add-case/addCase.component';
import { NewVendorComponent } from '../components/staff/add-vendor/new-vendor.component';
import { NewExpenseEntryComponent } from '../components/Expenses/new_expense_entry/new-expense-entry.component';
import { NewTaskComponent } from '../components/calendar/new-task/new-task.component';
import { AddClientComponent } from '../components/Clients/add-client/add-client.component';
import { LoginComponent } from '../components/authenticate/login/login.component';
import { LogoutComponent } from '../components/authenticate/logout/logout.component';
import { AddEmployeeComponent } from '../components/staff/add-employee/add-employee.component';
import { EditClientComponent } from '../components/Clients/edit-client/edit-client.component';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { CreatePasswordComponent } from '../components/authenticate/create-password/create-password.component';
import { ClientListDetailComponent } from '../components/Clients/client-list-detail/client-list-detail.component';
import { ResetPasswordComponent } from '../components/authenticate/reset-password/reset-password.component';
import { NewEventComponent } from '../components/calendar/new-event/new-event.component';




export const AppModuleDeclarations = [
    AppComponent,
    DashboardComponent,
    FooterComponent,
    SideBarComponent,
    HeaderComponent,
    AddCaseComponent,
    CaseListComponent,
    CaseRequestsComponent,
    CaseSubjectsComponent,
    CaseUpdatesComponent,
    CaseReviewsComponent,
    AddClientComponent,
    ClientListComponent,
    ClientContactsComponent,
    ClientNotesComponent,
    ContactNotesListComponent,
    StaffListComponent,
    InvestigatorSnapshotComponent,
    LicensesComponent,
    StaffNotesComponent,
    ExpenseEntriesComponent,
    ExpenseEntriesStaffComponent,
    PaymentSipsComponent,
    InvoiceListComponent,
    PaymentHistoryComponent,
    RetainersListComponent,
    ActivityFulfillmentComponent,
    CalendarsComponent,
    EventListComponent,
    EventMapComponent,
    ScheduleComponent,
    TaskListComponent,
    NewVendorComponent,
    NewExpenseEntryComponent,
    NewTaskComponent,
    LoginComponent,
    LogoutComponent,
    AddEmployeeComponent,
    EditClientComponent,
    ChangePasswordComponent,
    CreatePasswordComponent,
    ClientListDetailComponent,
    ResetPasswordComponent,
    NewEventComponent
];
