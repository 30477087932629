import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AddOrEditCaseAssignmentType } from "../models/case-settings/case-assignment-type/case.assignment.type.edit.model";
import { DataTableResponseModel } from "../models/shared/datatable.response.model";

@Injectable({
    providedIn: 'root'
})
export class CaseAssignmentTypeService {
    constructor(private http: HttpClient) {
    }

    public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/case-assignment-types/query`, dataTablesParameters, {});
    }

    public get(id: number): Observable<AddOrEditCaseAssignmentType> {
        return this.http.get<AddOrEditCaseAssignmentType>(`${environment.apiBaseUrl}/case-assignment-types/${id}`);
    }
    public put(model: AddOrEditCaseAssignmentType): Observable<any> {
        return this.http.put<any>(`${environment.apiBaseUrl}/case-assignment-types/`, model);
    }
}
