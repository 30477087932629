import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, ActivationEnd, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Console } from 'console';
import { Subscription } from 'rxjs';
import { distinctUntilChanged, filter } from 'rxjs/operators';
import { IBreadCrumb } from './breadcrumb.interface';


@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit, OnChanges, OnDestroy {

  breadcrumbs: IBreadCrumb[];
  navigationSubscription: Subscription;


  @Input() breadCrumbInputs?: { key: string, value: string[] }[];


  constructor(private router: Router, private route: ActivatedRoute) {
    this.navigationSubscription = this.router.events.pipe(
      filter(event => event instanceof ActivationEnd),
      distinctUntilChanged(),
    ).subscribe((evt: any) => {
      this.breadcrumbs = [];
      this.buildBreadCrumbs();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.breadcrumbs = [];
    this.buildBreadCrumbs();
  }

  ngOnInit(): void {
  }

  private buildBreadCrumbs(): void {
    const snapShot = this.route.snapshot;

    if (snapShot !== undefined && snapShot !== null) {

      const breadcrumbs = snapShot.data.breadcrumbs;

      if (breadcrumbs !== undefined && breadcrumbs !== null) {

        for (const breadcrumb of breadcrumbs.parents) {

          let breadcrumbLabel; let path;

          const dataUrl = this.router.config.filter(x => x.data !== undefined && x.data.breadcrumbs !== undefined)
            .find(x => x.data.breadcrumbs.name?.toLowerCase() === breadcrumb.toLowerCase());

          if (dataUrl !== null && dataUrl !== undefined) {
            path = dataUrl.path;
            breadcrumbLabel = breadcrumb;
          }

          const dataChildUrl = this.router.config.find(x => x.children && x.children.length > 0 && x.children
            .some(y => y.data && y.data.breadcrumbs && y.data.breadcrumbs.name?.toLowerCase() === breadcrumb.toLowerCase()));

          if (dataChildUrl !== null && dataChildUrl !== undefined) {
            const parentUrl = dataChildUrl.path;
            const childUrl = dataChildUrl.children.find(x => x.data
              && x.data.breadcrumbs.name.toLowerCase() === breadcrumb.toLowerCase()).path;
            path = `${parentUrl}/${childUrl}`;
          }

          const input = this.breadCrumbInputs?.find(x => path.includes(`:${x.key}`));
          if (input !== undefined && input.value.length > 0) {
            const lastRoutePart = path.split('/').pop();
            const isDynamicRoute = lastRoutePart.startsWith(':');
            path = path.replace(`:${input.key}`, input.value[0]);
            breadcrumbLabel = isDynamicRoute ? input.value[1] : breadcrumb;
          }
          this.breadcrumbs.push({ label: breadcrumbLabel, url: path });

        }

        if (this.route.children && this.route.children.length > 0) {
          this.route.children.forEach(item => {
            this.breadcrumbs.push({ label: item.snapshot.data.breadcrumbs?.name, url: null });
          });
        }

        else {
          this.breadcrumbs.push({ label: breadcrumbs.name, url: null });
        }
      }
    }

  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

}
