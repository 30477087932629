<button class="btn btn-lg bg-blue text-white filter-btn-nav  float-right"  (click)="filterNavStatus = true">
    <i class="align-middle fas fa-filter"></i>
</button>
<button class="btn btn-lg bg-white float-right mr-3" routerLink="./add"><i class="align-middle  fas fa-plus-circle"></i>
    New Note</button>
<div ngbDropdown class="d-inline-block float-right mr-3">
    <button class="btn btn-outline-primary btn-lg bulkaction-dropdown" id="dropdownBasic1" ngbDropdownToggle
        [disabled]="isBulkOperationButtonDisabled">Bulk Action</button>
    <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
        <button ngbDropdownItem (click)="onBulkDelete()" *ngIf="filterBy != noteFilterOptions.DeletedNotes">Delete</button>
        <button ngbDropdownItem (click)="onBulkUnDelete()" *ngIf="filterBy == noteFilterOptions.DeletedNotes">Undelete</button>
    </div>
</div>
<div class="case-table table-responsive">
    <div>
        <table class="neu-table table table-striped neu-table w-100" datatable [dtOptions]="dtOptions" data-page="2">
        </table>
    </div>
</div>
<app-notes-filter-and-search (onApplyFilter)="applyFilter($event)" (resetFilters)="onResetFilters()"
    (closeFilterNav)="isShowFilterAndSearch = !isShowFilterAndSearch"  [filterStatusParent]="filterNavStatus" 
    (closeBtnFilterStatus)="closeFilterEvent($event)">
</app-notes-filter-and-search>