import { StaffFilterOptions, StaffSearchOptions } from "../../enums/filters-and-searchs/staff-filter.options";

export const StaffFilterOptionsMapping = new Map<number, string>([
    [StaffFilterOptions.None, 'None'],
    [StaffFilterOptions.DeletedStaffs, 'Deleted Staff'],
]);

export const StaffSearchOptionMapping = new Map<number, string>([
    [StaffSearchOptions.Name, 'Name'],
    [StaffSearchOptions.Email, 'Email'],
    [StaffSearchOptions.Title, 'Title'],
    [StaffSearchOptions.officePhone, 'Office Phone'],
    [StaffSearchOptions.Tags, 'Tag'],
    [StaffSearchOptions.AccountCode, 'Account Code'],
    [StaffSearchOptions.BirthDay, 'Date Of Birth'],
    [StaffSearchOptions.StateOrProvince, 'State/Province']
  ]);