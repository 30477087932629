<main class="content py-0">

    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12 px-0 pb-5">
                            <div class="col-auto mt-n1">
                                <nav aria-label="breadcrumb">
                                    <ol class="breadcrumb bg-transparent custom-breadcrumb p-0 mt-1 mb-0">
                                        <li class="breadcrumb-item"><a routerLink="/case-list">Cases</a></li>
                                        <!-- <li class="breadcrumb-item"><a href="case_list_detail.html">Case List</a></li> -->
                                        <li class="breadcrumb-item active" aria-current="page">New Case</li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto d-none d-sm-block mb-3">
                            <h3 class="page-title"><strong>New Case</strong> </h3>
                            <p>Create new case detail here.</p>
                        </div>
                    </div>
                    <div class="row mx-0" *blockUI="'add-case-ui'">
                        <div class="col-12 px-0 mb-5">
                            <form name="basicform" id="basicform" method="post" action="" novalidate="novalidate"
                                [formGroup]="form" (ngSubmit)="onSubmit()" class="new-case-form">
                                <div id="sf1" class="frm p-4 bg-white rounded shadow-sm" *ngIf="panelStep === 1">
                                    <fieldset>
                                        <legend>Step 1/4
                                            <h3 class="text-orange title-legend">Client Information</h3>
                                        </legend>
                                        <div class="row  align-items-center mb-3">
                                            <div class="col-md-3">
                                                <div class="form-group has-search pt-3 position-relative">
                                                    <label for="client">Client<span class="clsStric">*</span></label>
                                                    <div class="input-group position-relative">
                                                        <ng-select (change)="onChangeClient($event)" bindValue="id"
                                                            name="clientNameId" formControlName="clientNameId"
                                                            [items]="clientListItem" bindLabel="value">
                                                        </ng-select>
                                                        <div class="input-group-append position-absolute append-btn">
                                                            <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                    data-feather="chevron-down"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="submitted && form.controls.clientNameId.errors && form.controls.clientNameId.errors.required"
                                                        class="text-danger">
                                                        Client is required!
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="px-3 pt-4">
                                                <p class="mb-0">OR</p>
                                            </div>
                                            <div class="col-3 pt-4">
                                                <div class="col-lg-10 col-lg-offset-2">
                                                    <button (click)="openNewClientPopup()"
                                                        class="btn bg-orange text-white font-weight-500"
                                                        type="button">Create New Client</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row  mb-3  align-items-center  mb-3">
                                            <div class="col-md-3">
                                                <div class="form-group has-search">
                                                    <label for="location">Location<span
                                                            class="clsStric">*</span></label>
                                                    <div class="input-group position-relative">
                                                        <ng-select bindValue="id" name="clientLocationId"
                                                            (change)="onChangeClientLocation($event)"
                                                            formControlName="clientLocationId"
                                                            [items]="clientLocationListItem" bindLabel="value">
                                                        </ng-select>
                                                        <div class="input-group-append position-absolute append-btn">
                                                            <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                    data-feather="chevron-down"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="submitted && form.controls.clientLocationId.errors && form.controls.clientLocationId.errors.required"
                                                        class="text-danger">
                                                        Location is required!
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="px-3 pt-4" *ngIf="form.controls.clientNameId.value">
                                                <p class="mb-0">OR</p>
                                            </div>
                                            <div class="col-3 pt-4" *ngIf="form.controls.clientNameId.value">
                                                <div class="col-lg-10 col-lg-offset-2">
                                                    <button (click)="openClientAddLocationPopup()"
                                                        class="btn bg-orange text-white font-weight-500"
                                                        type="button">Add Location</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row   align-items-center  mb-3">
                                            <div class="col-md-3">
                                                <div class="form-group has-search">
                                                    <label for="contact">Primary Contact<span
                                                            class="clsStric">*</span></label>
                                                    <div class="input-group position-relative">
                                                        <ng-select bindValue="id"
                                                            (change)="onChangeClientContact($event)"
                                                            name="clientContactId" formControlName="clientContactId"
                                                            [items]="clientContactListItem" bindLabel="value">
                                                        </ng-select>
                                                        <div class="input-group-append position-absolute append-btn">
                                                            <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                    data-feather="chevron-down"></i>
                                                            </button>
                                                        </div>
                                                        <div *ngIf="submitted && form.controls.clientContactId.errors && form.controls.clientContactId.errors.required"
                                                            class="text-danger">
                                                            Primary contact is required!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="px-3 pt-4" *ngIf="form.controls.clientNameId.value">
                                                <p class="mb-0">OR</p>
                                            </div>
                                            <div class="col-3 pt-4">
                                                <div class="col-lg-10 col-lg-offset-2"
                                                    *ngIf="form.controls.clientNameId.value">
                                                    <button (click)="openClientAddContactPopup()"
                                                        class="btn bg-orange text-white font-weight-500"
                                                        type="button">Add Contact</button>
                                                </div>
                                            </div>

                                        </div>
                                        <div class="row">
                                            <div class="col-md-3">
                                                <div class="form-group has-search">
                                                    <label for="caseType">Case Type<span
                                                            class="clsStric">*</span></label>
                                                    <div class="input-group position-relative">
                                                        <select id="select_caseType" (change)="onChangeCaseType($event)"
                                                            formControlName="caseTypeId" name="caseTypeId">
                                                            <option></option>
                                                            <option *ngFor="let ct of caseTypeList" [value]="ct.id">
                                                                {{ct.name}}</option>
                                                        </select>
                                                        <div class="input-group-append position-absolute append-btn">
                                                            <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                    data-feather="chevron-down"></i>
                                                            </button>
                                                        </div>
                                                        <div *ngIf="submitted && form.controls.caseTypeId.errors && form.controls.caseTypeId.errors.required"
                                                            class="text-danger">
                                                            Case type is required!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3">
                                                <div class="form-group has-search">
                                                    <label for="mySelf">Assign a Role</label>
                                                    <div class="input-group position-relative">
                                                        <!-- <select (change)="onChange($event)" id="search5" formControlName="assignMyselfAs">
                                                            <option value="none" selected>None</option>
                                                            <option value="casemanager">Case Manager</option>
                                                            <option value="salesperson">Salesperson</option>
                                                            <option value="additionalemployee">Additional Employee
                                                            </option>
                                                            <option value="investigator">Investigator</option>
                                                        </select> -->
                                                        <select (change)="onChange($event)" formControlName="assignMyselfAs">
                                                            <option value="">Select Role</option>
                                                            <option *ngFor="let item of rolesSelectListItem" [value]="item.value">
                                                                {{item.value}}
                                                            </option>
                                                          </select>
                                                        <div class="input-group-append position-absolute append-btn">
                                                            <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                    data-feather="chevron-down"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                             <div class="col-md-3" *ngIf="isExistsInFilterOptions('Case Manager for Staff')">
                                                <div class="form-group has-search">
                                                    <label for="mySelf">Case Manager</label>
                                                    <div class="input-group position-relative">
                                                        <select id="select_casemanager" 
                                                        formControlName="casemanager" name="casemanager">
                                                        <option></option>
                                                        <option *ngFor="let ct of managersSelectListItem" [value]="ct.value">
                                                            {{ct.value}}</option>
                                                    </select>
                                                        <div class="input-group-append position-absolute append-btn">
                                                            <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                    data-feather="chevron-down"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3" *ngIf="isExistsInFilterOptions('Salesperson')">
                                                <div class="form-group has-search">
                                                    <label for="mySelf">Sales Person</label>
                                                    <div class="input-group position-relative">
                                                        <select id="select_salesperson" 
                                                        formControlName="salesperson" name="salesperson">
                                                        <option></option>
                                                        <option *ngFor="let ct of salespersonsSelectListItem" [value]="ct.value">
                                                            {{ct.value}}</option>
                                                    </select>
                                                        <div class="input-group-append position-absolute append-btn">
                                                            <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                    data-feather="chevron-down"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-3" *ngIf="isExistsInFilterOptions('Investigator')">
                                                <div class="form-group has-search">
                                                    <label for="mySelf">Investigator</label>
                                                    <div class="input-group position-relative">
                                                        <select id="select_investigator" 
                                                        formControlName="investigators" name="investigators">
                                                        <option></option>
                                                        <option *ngFor="let ct of investigatorSelectListItem" [value]="ct.value">
                                                            {{ct.value}}</option>
                                                    </select>
                                                        <div class="input-group-append position-absolute append-btn">
                                                            <button class="border-0 bg-transparent" type="button">
                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                    data-feather="chevron-down"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="col-lg-12 text-right">
                                                <button
                                                    class="btn bg-white px-4 py-2 font-weight-500 open2"
                                                    type="button" (click)="goBacktoCases()">Cancel <span
                                                        class="fa fa-arrow-right"></span></button>

                                                <button class="btn bg-blue text-white open1 px-4 py-2 ml-2  font-weight-500"
                                                    type="button" (click)="nextStep(2)">Continue <span
                                                        class="ml-1 fas fa-arrow-right"></span></button>

                                                        
                                            </div>
                                        </div>


                                    </fieldset>
                                </div>

                                <div id="sf2" class="frm" *ngIf="panelStep === 2">
                                    <fieldset>
                                        <legend>Step 2/4
                                            <h3 class="text-orange title-legend">Case Details</h3>
                                        </legend>
                                        <div class="row ">
                                            <div class="col-md-6 col-xl-6 col-xxl-6">
                                                <div class="card flex-fill w-100 mb-4">
                                                    <div class="card-header rounded p-4 mob-px-4">
                                                        <p class="mb-0">Requested by</p>
                                                        <p class="font-15 black-light-text font-weight-500">
                                                            {{form.controls.clientName.value}}</p>
                                                    </div>
                                                    <div class="card-body">
                                                        <div class="row">
                                                            <div class="col-md-5">
                                                                <p class="mb-0">Contact</p>
                                                                <p class="font-15 black-light-text font-weight-500">
                                                                    {{appUtils.getFormattedName(form.controls.clientContact.value)}}
                                                                </p>
                                                            </div>
                                                            <div class="col-md-7">
                                                                <p class="mb-0">Location</p>
                                                                <p class="font-15 black-light-text font-weight-500">
                                                                    {{form.controls.clientLocation.value}}</p>
                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                        <div class="col-md-12">
                                                            <label class="mb-0">Client Policies</label>
                                                            <div class="clientPolicies" *ngIf="casePolicies" >
                                                                {{casePolicies}} 
                                                            </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                </div>
                                                <div class="card flex-fill w-100 mb-4">
                                                    <div class="card-header rounded p-4 mob-px-4">
                                                        <div class="row mb-4">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label>Created On<span
                                                                            class="clsStric">*</span></label>
                                                                    <div class="input-group date"
                                                                        data-date-format="mm-dd-yyyy">
                                                                        <input
                                                                        class="form-control"
                                                                        placeholder="mm-dd-yyyy"
                                                                        formControlName="createdOn"
                                                                        ngbDatepicker
                                                                        #d="ngbDatepicker"
                                                                        [minDate]="{
                                                                          year: 1920,
                                                                          month: 1,
                                                                          day: 1
                                                                        }"
                                                                      />
                                                                      <div class="input-group-append">
                                                                        <button
                                                                          class="
                                                                            btn btn-outline-secondary
                                                                            calendar
                                                                          "
                                                                          (click)="d.toggle()"
                                                                          type="button"
                                                                        ></button>
                                                                      </div>
                                                                    </div>
                                                                    <div *ngIf="submitted && form.controls.createdOn.errors && form.controls.createdOn.errors.required"
                                                                        class="text-danger">
                                                                        Created On is required!
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label>Due Date</label>
                                                                    <div class="input-group date"
                                                                        data-date-format="mm-dd-yyyy">
                                                                        <input
                                                                        class="form-control"
                                                                        placeholder="mm-dd-yyyy"
                                                                        formControlName="dueDate"
                                                                        ngbDatepicker
                                                                        #dt="ngbDatepicker"
                                                                        [minDate]="{
                                                                          year: 1920,
                                                                          month: 1,
                                                                          day: 1
                                                                        }"
                                                                      />
                                                                      <div class="input-group-append">
                                                                        <button
                                                                          class="
                                                                            btn btn-outline-secondary
                                                                            calendar
                                                                          "
                                                                          (click)="dt.toggle()"
                                                                          type="button"
                                                                        ></button>
                                                                      </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="location">Referral</label>
                                                                    <div class="input-group position-relative">
                                                                        <select id="search6"
                                                                            formControlName="referralSource">
                                                                            <option value="" selected></option>
                                                                            <option
                                                                                *ngFor="let rs of referralSourceList"
                                                                                [value]="rs.id">{{rs.name}}</option>
                                                                        </select>
                                                                        <div
                                                                            class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent"
                                                                                type="button">
                                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                                    data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="location">Case Flags</label>
                                                                    <div class="input-group position-relative">
                                                                        <ng-select bindValue="id" name="caseFlags"
                                                                            formControlName="caseFlags"
                                                                            [closeOnSelect]="false" [multiple]="true"
                                                                            [items]="caseFlagList" bindLabel="value">
                                                                        </ng-select>
                                                                        <div
                                                                            class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent"
                                                                                type="button">
                                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                                    data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card flex-fill w-100 mb-4">
                                                    <div class="card-header rounded p-4 mob-px-4">
                                                        <div class="row mb-4">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="caseType">Case Type</label>
                                                                    <div class="input-group position-relative">
                                                                        <label>{{form.controls.caseType.value}}</label>
                                                                        <div
                                                                            class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent"
                                                                                type="button">
                                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                                    data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="caseServices">Case Services</label>
                                                                    <div class="input-group position-relative">
                                                                        <ng-select bindValue="id" name="caseServices"
                                                                            formControlName="caseServices"
                                                                            [closeOnSelect]="false" [multiple]="true"
                                                                            [items]="caseServiceList" bindLabel="value">
                                                                        </ng-select>
                                                                        <div
                                                                            class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent"
                                                                                type="button">
                                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                                    data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="companyLocation">Company
                                                                        Location</label>
                                                                    <div class="input-group position-relative">
                                                                        <select id="search6"
                                                                            formControlName="companyLocationId">
                                                                            <option value="" selected></option>
                                                                            <option
                                                                                *ngFor="let cll of companyLocationList"
                                                                                [value]="cll.id">{{cll.name}}</option>
                                                                        </select>
                                                                        <div
                                                                            class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent"
                                                                                type="button">
                                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                                    data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="caseRegion">Case Region</label>
                                                                    <div class="input-group position-relative">

                                                                        <ng-select bindValue="id" name="caseRegionId"
                                                                            formControlName="caseRegionId"
                                                                            [items]="caseRegionList" bindLabel="name">
                                                                        </ng-select>
                                                                        <div
                                                                            class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent"
                                                                                type="button">
                                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                                    data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div *ngIf="submitted && form.controls.caseRegionId.errors && form.controls.caseRegionId.errors.required"
                                                                        class="text-danger">
                                                                        Case Region is required!
                                                                    </div> -->
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="caseLocation">Case Location</label>
                                                                    <div class="input-group">
                                                                        <input type="text"
                                                                            formControlName="caseLocation"
                                                                            class="form-control rounded"
                                                                            id="caseLocation">
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="companyLocation">Case Status</label>
                                                                    <div class="input-group position-relative">
                                                                        <select id="search6"
                                                                            formControlName="caseStatus">
                                                                            <option value="" selected></option>
                                                                            <option
                                                                                *ngFor="let cll of casestatusList"
                                                                                [value]="cll.id">{{cll.name}}</option>
                                                                        </select>
                                                                        <div
                                                                            class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent"
                                                                                type="button">
                                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                                    data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div> -->
                                                        </div>

                                                    </div>
                                                </div>
                                                <div class="card flex-fill w-100 mb-4">
                                                    <div class="card-header rounded p-4 mob-px-4">
                                                        <div class="user-header mb-4">
                                                            <h4 class="user-title font-weight-bold mb-0"><span
                                                                    class="mr-2"><img src="img/icons/money-bag.png"
                                                                        alt=""></span>Custom Field</h4>
                                                        </div>
                                                        <div class="row mb-4" formArrayName="customFields">
                                                            <div class="col-md-12"
                                                                *ngFor="let cf of customFieldToFormArray.controls;let i=index"
                                                                [formGroupName]="i">
                                                                <div class="form-group has-search">
                                                                    <label for="referralSource">{{cf.value.label}} <span
                                                                            *ngIf="cf.value.required" class="clsStric">*
                                                                        </span>
                                                                        <i *ngIf="cf.value.helpText"
                                                                            class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                            data-toggle="tooltip" data-placement="top"
                                                                            placement="top"
                                                                            [ngbTooltip]="cf.value.helpText"></i>
                                                                    </label>
                                                                    <!-- Number 2 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.CountryDropdown"
                                                                        class="input-group position-relative">
                                                                        <select id="multipleChoiceDropdown_{{i}}"
                                                                            formControlName="data">
                                                                            <option *ngFor="let country of countryList"
                                                                                [value]="country.id">{{country.name}}
                                                                            </option>
                                                                        </select>
                                                                        <div
                                                                            class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent"
                                                                                type="button">
                                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                                    data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 3 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.Date"
                                                                        class="form-group has-search">
                                                                        <div class="input-group date"
                                                                        data-date-format="mm-dd-yyyy">
                                                                        <input
                                                                        class="form-control"
                                                                        placeholder="mm-dd-yyyy"
                                                                        formControlName="data"
                                                                        ngbDatepicker
                                                                        #d="ngbDatepicker"
                                                                        [minDate]="{
                                                                          year: 1920,
                                                                          month: 1,
                                                                          day: 1
                                                                        }"
                                                                      />
                                                                      <div class="input-group-append">
                                                                        <button
                                                                          class="
                                                                            btn btn-outline-secondary
                                                                            calendar
                                                                          "
                                                                          (click)="d.toggle()"
                                                                          type="button"
                                                                        ></button>
                                                                      </div>
                                                                    </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 4 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.DateBirthday"
                                                                        class="form-group has-search">
                                                                        <div class="input-group date"
                                                                        data-date-format="mm-dd-yyyy">
                                                                        <input
                                                                        class="form-control"
                                                                        placeholder="mm-dd-yyyy"
                                                                        formControlName="data"
                                                                        ngbDatepicker
                                                                        #d="ngbDatepicker"
                                                                        [minDate]="{
                                                                          year: 1920,
                                                                          month: 1,
                                                                          day: 1
                                                                        }"
                                                                      />
                                                                      <div class="input-group-append">
                                                                        <button
                                                                          class="
                                                                            btn btn-outline-secondary
                                                                            calendar
                                                                          "
                                                                          (click)="d.toggle()"
                                                                          type="button"
                                                                        ></button>
                                                                      </div>
                                                                    </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 5 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.EmailAddress"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <input type="email" formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}">
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                        </div>
                                                                    <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors && !form.controls.customFields.controls[i].controls.data.errors.required"
                                                                    class="text-danger">
                                                                    {{cf.value.label}} should be in correct format!
                                                                    </div>
                                                                 
                                                                    </div>

                                                                    <!-- Number 6 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.InputLongAnswer"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <textarea formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}" cols="40"
                                                                                rows="5"></textarea>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 7 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.InputShortAnswer"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <textarea formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}" cols="20"
                                                                                rows="5"></textarea>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>

                                                                    <!-- Number 8 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.Money"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <input type="number" formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}">
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 9 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.MultipleChoiceDropdown"
                                                                        class="input-group position-relative">
                                                                        <select id="multipleChoiceDropdown_{{i}}"
                                                                            formControlName="data">
                                                                            <option
                                                                                *ngFor="let mcd of defaultCustomFieldValues[i]"
                                                                                [value]="mcd.dform_multiplechoices">
                                                                                {{mcd.dform_multiplechoices}}</option>
                                                                        </select>
                                                                        <div
                                                                            class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent"
                                                                                type="button">
                                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                                    data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 10-->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.MultipleChoiceRadio"
                                                                        class="input-group position-relative">
                                                                        <div
                                                                            *ngFor="let mcr of defaultCustomFieldValues[i];let count=index" >
                                                                            <input id="radiobutton_{{count}}"
                                                                                [value]="mcr.dform_multipleChoiceRadio"
                                                                                type="radio" formControlName="data">
                                                                            <label class="pl-2 pr-2"
                                                                                for="{{mcr.dform_multipleChoiceRadio}}">{{mcr.dform_multipleChoiceRadio}}</label>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 11 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.NameFirstMiddleLast"
                                                                        class="form-group has-search">
                                                                        <div formGroupName="data">
                                                                            <input type="text" formControlName="first"
                                                                                class="form-control rounded mb-2" placeholder="First Name"
                                                                                id="text_{{counter}}">
                                                                                <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.controls.first.errors && form.controls.customFields.controls[i].controls.data.controls.first.errors.required"
                                                                                class="text-danger">
                                                                                First name is required!
                                                                                </div>
                                                                            <input type="text" formControlName="middle"
                                                                                class="form-control rounded  mb-2" placeholder="Middle Name"
                                                                                id="text_{{counter+1}}">
                                                                            <input type="text" formControlName="last" placeholder="Last Name"
                                                                                class="form-control rounded"
                                                                                id="text_{{counter+2}}">
                                                                                <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.controls.last.errors && form.controls.customFields.controls[i].controls.data.controls.last.errors.required"
                                                                                class="text-danger">
                                                                                Last name is required!
                                                                                </div>
                                                                        </div>
                                                                       
                                                                       
                                                                    </div>
                                                                    <!-- Number 12 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.Number"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <input type="number" formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}">
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 13 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.Section"
                                                                        class="form-group has-search">
                                                                        <hr>
                                                                    </div>
                                                                    <!-- Number 14-->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.WebsiteUrl"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <input type="text" formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}">
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 15-->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.YesOrNo"
                                                                        class="input-group position-relative">
                                                                        <div>
                                                                            <input id="radiobutton_yes" type="radio"
                                                                                value="true" formControlName="data">
                                                                            <label for="Yes" class="pl2- pr-2">Yes</label>
                                                                            <input id="radiobutton_no" type="radio"
                                                                                value="false" formControlName="data">
                                                                            <label for="no" class="pl-2 pr-2">No</label>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 16-->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.YesNoOrNA"
                                                                        class="input-group position-relative">
                                                                        <div>
                                                                            <input id="radiobutton_yes" type="radio"
                                                                                value="yes" formControlName="data">
                                                                            <label for="Yes" class="pl-2 pr-2">Yes</label>
                                                                            <input id="radiobutton_no" type="radio"
                                                                                value="no" formControlName="data">
                                                                            <label for="no" class="pl-2 pr-2">No</label>
                                                                            <input id="radiobutton_na" type="radio"
                                                                                value="na" formControlName="data">
                                                                            <label for="na" class="pl-2 pr-2">N/A</label>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 17 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.RatingMultipleChoiceDropdown"
                                                                        class="input-group position-relative">
                                                                        <select id="multipleChoiceDropdown_{{i}}"
                                                                            formControlName="data">
                                                                            <option
                                                                                *ngFor="let mcd of defaultCustomFieldValues[i]"
                                                                                [value]="mcd.dform_ratingMultiDrop_scale">
                                                                                {{mcd.dform_ratingMultiDrop}}</option>
                                                                        </select>
                                                                        <div
                                                                            class="input-group-append position-absolute append-btn">
                                                                            <button class="border-0 bg-transparent"
                                                                                type="button">
                                                                                <i class="text-white rounded-circle bg-orange align-middle"
                                                                                    data-feather="chevron-down"></i>
                                                                            </button>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>
                                                                    <!-- Number 18 -->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.RatingNumber"
                                                                        class="form-group has-search">
                                                                        <div class="input-group">
                                                                            <input type="text" formControlName="data"
                                                                                class="form-control rounded"
                                                                                id="number_{{i}}"> %
                                                                        </div>
                                                                    </div>
                                                                    <!-- Number 19-->
                                                                    <div *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.RatingYesNoOrNA"
                                                                        class="input-group position-relative">
                                                                        <div>
                                                                            <input id="radiobutton_yes" type="radio"
                                                                                value="yes" formControlName="data">
                                                                            <label for="Yes" class="pl-2 pr-2">Yes</label>
                                                                            <input id="radiobutton_no" type="radio"
                                                                                value="no" formControlName="data">
                                                                            <label for="no" class="pl-2 pr-2">No</label>
                                                                            <input id="radiobutton_na" type="radio"
                                                                                value="na" formControlName="data">
                                                                            <label for="na" class="pl-2 pr-2">N/A</label>
                                                                        </div>
                                                                        <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                        class="text-danger">
                                                                        {{cf.value.label}} is required!
                                                                    </div>
                                                                    </div>

                                                                     <!-- Number 1-->
                                                                     <div  *ngIf="cf.value.fieldTypeId == GetCustomFieldCaseType.Address" 
                                                                     class="input-group position-relative">
                                                                     <div formGroupName="data">
                                                                        <div class="row" >
                                                                            <div class="col-md-12">
                                                                                <div class="form-group has-search">
                                                                                    <label for="country">Country<sup class="clsStric">*</sup></label>
                                                                                    
                                                                                    <select formControlName="country" class="form-control" (change)="loadStates($event.target.value)">
                                                                                        <option *ngFor="let country of countryList"
                                                                                        [value]="country.id">{{country.name}}
                                                                                    </option>
                                                                                    </select>
                                                                                </div>
                                                                                <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.controls.country.errors && form.controls.customFields.controls[i].controls.data.controls.country.errors.required"
                                                                                class="text-danger">
                                                                                Country is required!
                                                                                </div>
                                                                            </div>
                  
                                                                            <div class="col-md-12">
                                                                                <div class="form-group has-search">
                                                                                    <label for="address_1">Address 1
                                                                                        </label>
                  
                                                                                        <input type="text" formControlName="address1" class="form-control" placeholder="Address 1" name="address_1" id="address_1">
                                                                                </div>
                                                                            </div>
                  
                                                                          
                                                                        </div>
                  
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <div class="form-group has-search">
                                                                                    <label for="address_2">Address 2
                                                                                    </label>
                  
                                                                                    <input type="text" formControlName="address2" class="form-control" placeholder="Address 2" name="address_2" id="address_2">
                                                                                </div>
                                                                            </div>
                  
                                                                            <div class="col-md-12 mb-2">
                                                                                <div class="form-group has-search">
                                                                                    <label for="address_3">Address 3</label>
                                                                                    <input type="text" formControlName="address3" class="form-control" placeholder="Address 3" name="address_3" id="address_3">
                                                                                </div>
                                                                            </div>
                                                                        </div>
                  
                  
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <div class="form-group has-search">
                                                                                    <label for="city">City
                                                                                    </label>
                  
                                                                                    <input type="text" formControlName="city" name="city" id="city" class="form-control" placeholder="">
                                                                                </div>
                                                                            </div>
                  
                                                                            <div class="col-md-12 mb-2">
                                                                                <div class="form-group has-search">
                                                                                    <label for="select_state">State/Province</label>
                                                                                    
                                                                                    <select name="select_state" id="select_state" type="text" class="form-control mb-3" formControlName="state" placeholder="State" class="form-control" >
                                                                                        <option value="" selected="selected">Select State</option>
                                                                                        <option *ngFor="let state of stateSelectListItem" [value]="state.name">{{state.name}}</option>
                                                                                        </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                  
                  
                                                                        <div class="row">
                                                                            <div class="col-md-12">
                                                                                <div class="form-group has-search">
                                                                                    <label for="zip_code">Zip/Postal Code
                                                                                    </label>
                  
                                                                                    <input type="text" formControlName="zip_code" class="form-control" placeholder="" id="zip_code" name="zip_code">
                                                                                </div>
                                                                            </div>
                  
                                                                            <div class="col-md-6 mb-2">
                                                                                
                                                                            </div>
                                                                        </div>
                                                                     </div>
                                                                     <!-- <div *ngIf="submitted && form.controls.customFields.controls[i].controls.data.errors && form.controls.customFields.controls[i].controls.data.errors.required"
                                                                     class="text-danger">
                                                                     {{cf.value.label}} is required!
                                                                 </div> -->
                                                                 </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-6 col-xl-6 col-xxl-6">
                                                <div class="card flex-fill w-100 mb-4">
                                                    <div class="card-header rounded p-4 mob-px-4">
                                                        <div class="user-header mb-4">
                                                            <h4 class="user-title font-weight-bold mb-0"><i
                                                                    class="align-middle far fa-file-alt mr-2 fx-2 text-orange"></i>Notes
                                                            </h4>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-12 mb-4">
                                                                <div class="form-group has-search">

                                                                    <label>Notes & instructions
                                                                        <i 
                                                                            class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                            data-toggle="tooltip" data-placement="top"
                                                                            placement="top"
                                                                            ngbTooltip="Specific details regarding the circumstances or handling of this case."></i>
                                                                    </label>
                                                                    <textarea class="form-control p-3" formControlName="notes" name="addComment"
                                                                        id="addComment2" rows="6"
                                                                        >
                                                                    </textarea>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-12 mb-4">
                                                                <div class="form-group has-search">
                                                                    <label>Scheduling Notes
                                                                        <i 
                                                                        class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                        data-toggle="tooltip" data-placement="top"
                                                                        placement="top"
                                                                        ngbTooltip="Specific days, times, or other scheduling requirements."></i>
                                                                    </label>
                                                                    <textarea class="form-control p-3" formControlName="schedulingnotes" name="addComment"
                                                                        id="addComment3" rows="6" placeholder="">
                                                                    </textarea>
                                                                </div>

                                                            </div>
                                                            <div class="col-md-12 mb-4">
                                                                <div class="form-group has-search">


                                                                    <label>Admin Notes
                                                                        <i 
                                                                        class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                        data-toggle="tooltip" data-placement="top"
                                                                        placement="top"
                                                                        ngbTooltip="Admin notes are hidden from clients and employees without permission."></i>
                                                                    </label>
                                                                    <textarea class="form-control p-3" formControlName="adminnotes"  name="addComment"
                                                                        id="addComment3" rows="6"></textarea>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card flex-fill w-100 mb-4">
                                                    <div class="card-header rounded p-4 mob-px-4">
                                                        <div class="user-header mb-4">
                                                            <h4 class="user-title font-weight-bold mb-0"><span
                                                                    class="mr-2"><img src="img/icons/money-bag.png"
                                                                        alt=""></span>Budget</h4>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-md-6 mb-4">
                                                                <div class="form-group has-search">
                                                                    <label id="Label-1">Budget</label>
                                                                    

                                                                        <div class="input-group input-group d-flex flex-row align-items-center">
                                                                            <span class="input-group-addon pr-2">$</span>
                                                                            <input type="number" 
                                                                             class="form-control" formControlName="budgetprice" id="Label"
                                                                            name="Product">
                                                                          </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-6 mb-4">
                                                                <div class="form-group has-search">
                                                                    <label id="Label-2">Hours</label>
                                                                    <input type="number" class="form-control" formControlName="budgethours" id="Label-2"
                                                                        name="Product">
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="card flex-fill w-100 mb-4">
                                                    <div class="card-header rounded p-4 mob-px-4">
                                                        <div class="user-header mb-4">
                                                            <h4 class="user-title font-weight-bold mb-0"><span
                                                                    class="mr-2"><img src="img/icons/money-bag.png"
                                                                        alt=""></span>Reference</h4>
                                                        </div>
                                                        <div class="row" formArrayName="references">
                                                            <div class="col-md-6 mb-4"
                                                                *ngFor="let ref of referencesToFormArray.controls;let i=index"
                                                                [formGroupName]="i">
                                                                <div class="form-group has-search">
                                                                    <label id="Label-4">{{ref.value.name}} <span
                                                                            *ngIf="ref.value.isRefNumberRequired"
                                                                            class="clsStric">*</span></label>
                                                                    <input *ngIf="ref.value.name!='DOL(Date of Loss)'" type="text" class="form-control"
                                                                        id="Label-{{i}}" name="value"
                                                                        formControlName="value">
                                                                  <div *ngIf="ref.value.name=='DOL(Date of Loss)'" 
                                                                        class="form-group has-search">
                                                                        <div class="input-group date"
                                                                        data-date-format="mm-dd-yyyy">
                                                                        <input
                                                                        class="form-control"
                                                                        placeholder="mm-dd-yyyy"
                                                                        formControlName="value"
                                                                        ngbDatepicker
                                                                        #d="ngbDatepicker"
                                                                        [minDate]="{
                                                                          year: 1920,
                                                                          month: 1,
                                                                          day: 1
                                                                        }"
                                                                      />
                                                                      <div class="input-group-append">
                                                                        <button
                                                                          class="
                                                                            btn btn-outline-secondary
                                                                            calendar
                                                                          "
                                                                          (click)="d.toggle()"
                                                                          type="button"
                                                                        ></button>
                                                                      </div>
                                                                    </div>
                                                                        
                                                                    </div>
                                                                    <div *ngIf="submitted && form.controls.references.controls[i].controls.value.errors && form.controls.references.controls[i].controls.value.errors.required"
                                                                        class="text-danger">
                                                                        {{form.controls.references.controls[i].controls.name.value}}
                                                                        is required
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <div class="col-lg-12 text-right">
                                                <button
                                                    class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn back2"
                                                    type="button" (click)="nextStep(1)"> <span
                                                        class="fa fa-arrow-left"></span> Back</button>
                                                <button
                                                    class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2 open2"
                                                    type="submit">Save & Continue <span
                                                        class="fa fa-arrow-right"></span></button>
                                            </div>
                                        </div>
                                    </fieldset>
                                </div>


                            </form>
                            <!-- {{form.value | json}} -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>