import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  FormArray,
  AbstractControl,
} from '@angular/forms';
import { Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { CaseStatusTriggerModel } from 'src/app/models/case-status-trigger/caseStatusTriggerModel';
import { CaseStatusViewModel } from 'src/app/models/case-status/caseStatusViewModel';
import { CaseStatusTriggerService } from 'src/app/services/case.status.trigger.service';
import { CasestatusService } from 'src/app/services/casestatus.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-edit-case-status-trigger',
  templateUrl: './edit-case-status-trigger.component.html',
  styleUrls: ['./edit-case-status-trigger.component.css'],
})
export class EditCaseStatusTriggerComponent implements OnInit {
  @BlockUI('container-blockui-new-case-type') blockUI: NgBlockUI;
  model: CaseStatusTriggerModel;
  caseStatusesList: CaseStatusViewModel[] = [];
  form: FormGroup;
  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private caseStatusService: CasestatusService,
    private caseStatusTriggerService: CaseStatusTriggerService,
    private router: Router
  ) {
    this.model = new CaseStatusTriggerModel();
    this.model.id = 0;
  }

loadDefaultData(): void{
  this.blockUI.start();
  forkJoin([
    this.caseStatusService.getAll(),
    this.caseStatusTriggerService.get()
  ]).subscribe(
    ([caseStatusAll, casetriggers]) => {
      this.caseStatusesList = []; // .reset dropdown
      this.caseStatusesList = caseStatusAll;
      if (casetriggers) {
      this.model = casetriggers;
      this.form.controls.new_invoice.setValue(casetriggers.newInvoice);
      this.form.controls.all_outstanding.setValue(casetriggers.outStandingInvoice);
      this.form.controls.primary_inve.setValue(casetriggers.piAssignedToCase);
      this.form.controls.primary_inve_confirmed.setValue(casetriggers.piConfiredAssignedToCase);
      }
      this.blockUI.stop();
    },
    (error) => {
      this.toasterService.ProcessErrorResponse(
        error,
        'Something went wrong!'
      );
      this.blockUI.stop();
    });
}
  ngOnInit(): void {
    this.form = this.formBuilder.group({
      primary_inve: new FormControl(0),
      primary_inve_confirmed: new FormControl(0),
      all_outstanding: new FormControl(0),
      new_invoice: new FormControl(0)
    });
    this.loadDefaultData();
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.newInvoice = Number(this.form.controls.new_invoice.value);
    this.model.outStandingInvoice =  Number(this.form.controls.all_outstanding.value);
    this.model.piAssignedToCase =  Number(this.form.controls.primary_inve.value);
    this.model.piConfiredAssignedToCase =  Number(this.form.controls.primary_inve_confirmed.value);

    this.caseStatusTriggerService.AddOrUpdate(this.model).subscribe(
      (resposne) => {
        setTimeout(() => {
          this.onCancel();
        }, 10);
        setTimeout(() => {
          this.toasterService.success('Case status trigger updated successfully');
          this.onCancel();
        }, 300);
        this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }
  onCancel(): void {
    this.router.navigate(['/setting']);
  }

}
