<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>Edit Case Status Triggers</strong> </h3>
                        </div>
                    </div>

                    <div class="row mx-0">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form"  [formGroup]="form" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
                                          
                                            <div class="card-body">
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row  align-items-center">
                                                            <div class="col-md-6 mb-4 pt-4 pb-4 card-header"><strong>When...</strong></div>
                                                            <div class="col-md-6 mb-4  pt-4 pb-4 card-header"><strong>	Change case status to...</strong></div>

                                                            <div class="col-md-6 ">
                                                                <label for="new_invoice">A new invoice is created.</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <select id="new_invoice" class="form-control" formControlName="new_invoice">
                                                                        <option value="0"  selected>Do not change case status</option>

                                                                        <option *ngFor="let data of caseStatusesList; let i=index" [value]="data.id">
                                                                            {{data.name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <label for="all_outstanding">All outstanding invoices for a case are paid in full.</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    
                                                                    <select class="form-control" id="all_outstanding" formControlName="all_outstanding">
                                                                        <option value="0"  selected>Do not change case status</option>

                                                                        <option *ngFor="let data of caseStatusesList; let i=index" [value]="data.id">
                                                                            {{data.name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>


                                                            <!-- last row -->
                                                            <div class="col-md-6">
                                                                <label for="primary_inve">The primary investigator is assigned to a case.</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    
                                                                    <select id="primary_inve" class="form-control"  formControlName="primary_inve">
                                                                        <option value="0"  selected>Do not change case status</option>

                                                                        <option *ngFor="let data of caseStatusesList; let i=index" [value]="data.id">
                                                                            {{data.name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                            <!-- last row -->
                                                            <div class="col-md-6">
                                                                <label for="primary_inve_confirmed">The primary investigator has confirmed their assignment to a case.</label>
                                                            </div>
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    
                                                                    <select id="primary_inve_confirmed" class="form-control"  formControlName="primary_inve_confirmed">
                                                                        <option value="0"  selected>Do not change case status</option>

                                                                        <option *ngFor="let data of caseStatusesList; let i=index" [value]="data.id">
                                                                            {{data.name}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>

                                                        </div>

                                                        <div class="row mt-5">
                                                            <div class="col-md-12">
                                                                <p class="text-black-50">NOTE: Triggers will not fire if a case has reached or is beyond the selected status.</p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button" (click)="onCancel()" > Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="submit" (click)="onSubmit()" >Save Case Status Triggers <span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
