<main class="content py-0">
  <div class="container-fluid p-0">
    <div class="row">
      <div class="col-md-12 pt-7">
        <div class="w-100 pt-4">
          <div class="row">
            <div class="col-md-12 px-0 pb-5">
              <div class="col-auto mt-n1">
                <app-breadcrumb></app-breadcrumb>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-auto d-none d-sm-block mb-3">
              <h3 class="page-title">
                <strong>General Global Settings</strong>
              </h3>
            </div>
          </div>

          <form
            class="new-case-form"
            name="form"
            [formGroup]="form"
            (ngSubmit)="onSubmit()"
          >
            <div class="row" *blockUI="'blockui-general-global-setting'">
              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="card-text">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group has-search">
                            <label for="role"
                              >Customer Name <sup class="text-danger">*</sup>
                              <i
                                class="
                                  align-middle
                                  fas
                                  fa-info-circle
                                  ml-2
                                  text-light-black
                                "
                                data-toggle="tooltip"
                                data-placement="top"
                                placement="top"
                                ngbTooltip="The name of your company, displayed throughout the system."
                              ></i
                            ></label>
                            <input
                              name="customer_name"
                              type="text"
                              class="form-control mb-3"
                              formControlName="customer_name"
                              placeholder="Customer Name"
                              autocomplete="off"
                            />
                            <div
                              *ngIf="
                                submitted && form.controls.customer_name.errors
                              "
                              class="text-danger"
                            >
                              <div
                                *ngIf="
                                  form.controls.customer_name.errors.required
                                "
                              >
                                Customer name is required
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group has-search">
                            <label for="url"
                              >Company Website
                              <i
                                class="
                                  align-middle
                                  fas
                                  fa-info-circle
                                  ml-2
                                  text-light-black
                                "
                                data-toggle="tooltip"
                                data-placement="top"
                                placement="top"
                                ngbTooltip="e.g. https://www.example.com"
                              ></i
                            ></label>

                            <div class="input-group position-relative">
                              <input
                                type="text"
                                formControlName="customer_website"
                                class="form-control mb-3"
                                id="webs"
                                name="customer_website"
                                placeholder="e.g. https://www.example.com"
                                autocomplete="off"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12">
                <div class="card">
                  <div class="card-body">
                    <div class="user-header mb-4">
                      <h4 class="user-title font-weight-bold mb-0">General</h4>
                    </div>
                    <div class="card-text">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group has-search">
                            <label for="account_subdomain"
                              >Account Subdomain <sup class="text-danger">*</sup
                              ><i
                                class="
                                  align-middle
                                  fas
                                  fa-info-circle
                                  ml-2
                                  text-light-black
                                "
                                data-toggle="tooltip"
                                data-placement="top"
                                placement="top"
                                ngbTooltip="Warning: Changing your subdomain will change the access URL of your system, causing existing bookmarks to no longer be valid."
                              ></i
                            ></label>
                            <input
                              name="account_subdomain"
                              type="text"
                              class="form-control mb-3"
                              formControlName="account_subdomain"
                              placeholder=""
                              autocomplete="off"
                            />
                            <div
                              *ngIf="
                                submitted &&
                                form.controls.account_subdomain.errors
                              "
                              class="text-danger"
                            >
                              <div
                                *ngIf="
                                  form.controls.account_subdomain.errors
                                    .required
                                "
                              >
                                Account subdomain is required
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group has-search">
                            <label for="url"
                              >Default country<sup class="clsStric">*</sup
                              ><i
                                class="
                                  align-middle
                                  fas
                                  fa-info-circle
                                  ml-2
                                  text-light-black
                                "
                                data-toggle="tooltip"
                                data-placement="top"
                                placement="top"
                                ngbTooltip="The default country that is used throughout the system unless otherwise specified."
                              ></i
                            ></label>

                            <div class="position-relative">
                              <select
                                name="country"
                                class="form-control"
                                formControlName="country"
                                id="country"
                              >
                              <option value="">Select</option>
                              <option *ngFor="let country of countrySelectListItem" [value]="country.name">
                                {{country.name}}
                              </option>
                            </select>
                              <div
                                *ngIf="
                                  submitted && form.controls.country.errors
                                "
                                class="text-danger mt-3"
                              >
                                <div
                                  *ngIf="form.controls.country.errors.required"
                                >
                                  Default country is required
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group has-search">
                                <label for="url"
                                  >Default language<sup class="clsStric">*</sup>
                                  <i
                                    class="
                                      align-middle
                                      fas
                                      fa-info-circle
                                      ml-2
                                      text-light-black
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    placement="top"
                                    ngbTooltip="The default language for Trackops to use."
                                  ></i
                                ></label>

                                <div class="position-relative">
                                  <select
                                  name="language"
                                  formControlName="language"
                                  id="language"
                                >
                                <option value="">Select</option>
                                <option *ngFor="let language of languageListItem" [value]="language.value">
                                  {{language.key}}
                                </option>
                                  </select>
                                  <div
                                    *ngIf="
                                      submitted && form.controls.language.errors
                                    "
                                    class="text-danger mt-3"
                                  >
                                    <div
                                      *ngIf="
                                        form.controls.language.errors.required
                                      "
                                    >
                                     Default language is required
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group has-search">
                                <label for="page_size_limit"
                                  >Default list limit<sup class="clsStric"
                                    >*</sup
                                  ><i
                                    class="
                                      align-middle
                                      fas
                                      fa-info-circle
                                      ml-2
                                      text-light-black
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    placement="top"
                                    ngbTooltip="The default limit for each page of results."
                                  ></i
                                ></label>

                                <div class="position-relative">
                                  <select
                                    name="page_size_limit"
                                    formControlName="page_size_limit"
                                  >
                                    <option value="" selected>
                                      Select
                                    </option>
                                    <option
                                      value="{{ item }}"
                                      *ngFor="let item of pageLimit"
                                    >
                                      {{ item }}
                                    </option>
                                  </select>
                                  <div
                                    *ngIf="
                                      submitted &&
                                      form.controls.page_size_limit.errors
                                    "
                                    class="text-danger mt-3"
                                  >
                                    <div
                                      *ngIf="
                                        form.controls.page_size_limit.errors
                                          .required
                                      "
                                    >
                                      Default list limit is required
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group has-search">
                                <label for="measurement_unit">
                                  Distance measurement unit<sup class="clsStric"
                                    >*</sup
                                  ><i
                                    class="
                                      align-middle
                                      fas
                                      fa-info-circle
                                      ml-2
                                      text-light-black
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    placement="top"
                                    ngbTooltip="Determines the unit of measurement when calculating distances between locations."
                                  ></i
                                ></label>

                                <div class="position-relative">
                                  <select
                                    name="measurement_unit"
                                    formControlName="measurement_unit"
                                    id="measurement_unit"
                                  >
                                    <option value="" selected>
                                      Select
                                    </option>
                                    <option
                                      ]value]="{{ list.value }}"
                                      *ngFor="let list of measurementListItem"
                                    >
                                      {{ list.key }}
                                    </option>
                                  </select>
                                  <div
                                    *ngIf="
                                      submitted &&
                                      form.controls.measurement_unit.errors
                                    "
                                    class="text-danger mt-3"
                                  >
                                    <div
                                      *ngIf="
                                        form.controls.measurement_unit.errors
                                          .required
                                      "
                                    >
                                    Distance measurement unit is required
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div class="col-md-6">
                              <div class="form-group has-search">
                                <label for="login_exp_hour">
                                  Login Request Expiration Hours<sup
                                    class="clsStric"
                                    >*</sup
                                  ><i
                                    class="
                                      align-middle
                                      fas
                                      fa-info-circle
                                      ml-2
                                      text-light-black
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    placement="top"
                                    ngbTooltip="The number of hours until login request links expire"
                                  ></i
                                ></label>

                                <div class="position-relative">
                                  <input
                                    type="number"
                                    formControlName="login_exp_hour"
                                    class="form-control"
                                  />
                                  <div
                                    *ngIf="
                                      submitted &&
                                      form.controls.login_exp_hour.errors
                                    "
                                    class="text-danger mt-3"
                                  >
                                    <div
                                      *ngIf="
                                        form.controls.login_exp_hour.errors
                                          .required
                                      "
                                    >
                                      Login request expiration hours is required
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group has-search">
                                <label for="map_url">
                                  Map Url<sup class="clsStric">*</sup
                                  ><i
                                    class="
                                      align-middle
                                      fas
                                      fa-info-circle
                                      ml-2
                                      text-light-black
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    placement="top"
                                    ngbTooltip="The external URL that requests for maps are passed to."
                                  ></i
                                ></label>

                                <div class="position-relative">
                                  <input
                                    type="text"
                                    class="form-control"
                                    formControlName="map_url"
                                    name="map_url"
                                  />
                                  <div
                                    *ngIf="
                                      submitted && form.controls.map_url.errors
                                    "
                                    class="text-danger mt-3"
                                  >
                                    <div
                                      *ngIf="
                                        form.controls.map_url.errors.required
                                      "
                                    >
                                      Map url is required
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-6">
                              <div class="form-group has-search">
                                <label for="system_timezone">
                                  System Timezone<sup class="clsStric">*</sup
                                  ><i
                                    class="
                                      align-middle
                                      fas
                                      fa-info-circle
                                      ml-2
                                      text-light-black
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    placement="top"
                                    ngbTooltip="The timezone that the system uses to display dates and times."
                                  ></i
                                ></label>

                                <div class="position-relative">
                                  <select
                                  name="system_timezone"
                                  id="system_timezone"
                                  formControlName="system_timezone"
                                >
                                <option value="">Select</option>
                                <option
                                ]value]="{{ list.value }}"
                                *ngFor="let list of systemTimezoneListItem"
                              >
                                {{ list.key }}
                              </option>
                              </select>
                                  <div
                                    *ngIf="
                                      submitted &&
                                      form.controls.system_timezone.errors
                                    "
                                    class="text-danger mt-3"
                                  >
                                    <div
                                      *ngIf="
                                        form.controls.system_timezone.errors
                                          .required
                                      "
                                    >
                                      System timezone is required
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row">
                            <div class="col-md-6">
                              <div class="form-group has-search">
                                <label for="day">
                                  Work Week Start Day<sup class="clsStric"
                                    >*</sup
                                  ><i
                                    class="
                                      align-middle
                                      fas
                                      fa-info-circle
                                      ml-2
                                      text-light-black
                                    "
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    placement="top"
                                    ngbTooltip="The day of the week that your work week starts (in terms of scheduling and payroll)."
                                  ></i
                                ></label>

                                <div class="position-relative">
                                  <select name="day" formControlName="day">
                                    <option value="" selected>
                                      Select
                                    </option>
                                    <option
                                      [value]="list.value"
                                      *ngFor="let list of weekStartDayListItem"
                                    >
                                      {{ list.key }}
                                    </option>
                                  </select>
                                  <div
                                    *ngIf="
                                      submitted && form.controls.day.errors
                                    "
                                    class="text-danger mt-3"
                                  >
                                    <div
                                      *ngIf="form.controls.day.errors.required"
                                    >
                                    Work week start day is required
                                    </div>
                                  </div>
                                  <div
                                    class="
                                      input-group-append
                                      position-absolute
                                      append-btn
                                    "
                                  >
                                    <button
                                      class="border-0 bg-transparent"
                                      type="button"
                                    >
                                      <i
                                        class="
                                          text-white
                                          rounded-circle
                                          bg-orange
                                          align-middle
                                        "
                                        data-feather="chevron-down"
                                      ></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group w-100">
                <div class="col-lg-12 text-right">
                  <button
                    class="
                      btn
                      font-weight-500
                      px-4
                      py-2
                      bg-white
                      text-dark-blue
                      border-btn
                    "
                    type="button"
                    (click)="onCancel()"
                  >
                    Cancel
                  </button>
                  <button
                    class="
                      btn
                      bg-blue
                      text-white
                      px-4
                      py-2
                      font-weight-500
                      ml-2
                    "
                    type="submit"
                
                  >
                    Save Setting <span class="fa fa-arrow-right"></span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</main>
