import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { PhoneCategoryMapping } from 'src/app/helpers/enums/phoneCategory/phoneCategoryFor';
import { ContatcNoInEntity } from 'src/app/models/shared/contactNoInEntity';
import { SelectListItem } from 'src/app/models/shared/select.list.item';

@Component({
  selector: 'app-contact-number-widget',
  templateUrl: './contact-number-widget.component.html',
  styleUrls: ['./contact-number-widget.component.css']
})
export class ContactNumberWidgetComponent implements OnInit {
  @Input() form: FormGroup;
  @Input() isForEdit = false;
  @Input() contactNumbers: ContatcNoInEntity<string>[] = [];
  @Input() isSubmitted = false;
  public contactTypesEnum: SelectListItem<number>[] = [];

  constructor(private formBuilder: FormBuilder) {
    PhoneCategoryMapping.forEach((key, value) => this.contactTypesEnum.push({ id: value, value: key }));

  }

  ngOnInit(): void {
    this.form.addControl('dynamicRows',
    new FormArray([]));
    if (this.contactNumbers && this.contactNumbers.length > 0) {
      this.createRowInEditMode();
    }
    else {
      this.createRow();
    }
  }

  get formArr(): FormArray {
    return this.form.get('dynamicRows') as FormArray;
  }

  addNewRows(): void {
    this.createRow();
  }

  deleteRow(index: number): void {
    this.formArr.removeAt(index);
  }
  createRow(): void {
    this.formArr.push(this.formBuilder.group({
      phoneNum: ['', [Validators.required,RxwebValidators.unique(), Validators.minLength(14), Validators.maxLength(14)]],
      phoneType: ['', Validators.required],
      extn: ['', [Validators.maxLength(25)]],
      id: [0]
    }));
  }
  createRowInEditMode(): void {
    this.contactNumbers.forEach(d => {
      this.formArr.push(this.formBuilder.group({
        id: [d.id],
        phoneNum: [d.contactNumber, [Validators.required,RxwebValidators.unique(), Validators.minLength(14), Validators.maxLength(14)]],
        phoneType: [d.contactTypeId, Validators.required],
        extn: [d.extensionNote, [Validators.maxLength(25)]]
      }));
    });
  }

  _contactTele(event: any): any {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 40 && charCode !== 45 && charCode !== 41) {
        return false;
    }
    return true;
}
}
