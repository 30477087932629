import { Subscription } from 'rxjs';

export abstract class ComponentDestoryHelper {
  subscriptions: Subscription[];

  constructor() {
    this.subscriptions = [];
  }
  ngAfterOnDestroy(): void {
    if (this.subscriptions && this.subscriptions.length > 0) {
      this.subscriptions.every((subscription: Subscription) => {
        subscription.unsubscribe();
      });
    }
  }
}
