import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { AddOrEditFolderTemplateModel } from 'src/app/models/folder-template/add.folder-template.model';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { CasetypeService } from 'src/app/services/casetype.service';
import { FolderTemplateService } from 'src/app/services/foldertemplate.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';

@Component({
  selector: 'app-edit-folder-template',
  templateUrl: './edit-folder-template.component.html',
  styleUrls: ['./edit-folder-template.component.css']
})
export class EditFolderTemplateComponent implements OnInit {

  model: AddOrEditFolderTemplateModel;
  @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
  accessGroupSelectListItem: SelectListItem<number>[] = [];

  constructor(private router: Router,
              private activeRoute: ActivatedRoute,
              private formBuilder: FormBuilder,
              private toasterService: ToasterService,
              private folderTemplateService: FolderTemplateService,
              private caseTypeService: CasetypeService,
              private accessGroupService: AccessGroupService) {
              this.model = new AddOrEditFolderTemplateModel();
              this.activeRoute.params.subscribe(params => this.model.id = params.id);
            }
  form: FormGroup;
  submitted = false;
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  loadBreadCrumb = false;

  get caseTypesToFormArray(): FormArray{
    return this.form.controls.case_types as FormArray;
  }



ngOnInit(): void {
  this.form = this.formBuilder.group({
    name: ['', [Validators.required]],
    access_grp: ['', [Validators.required]],
    default_notes: [''],
    case_types: new FormArray([], minSelectedCheckboxes())
  });
  // this.loadAccessGroup();
  // this.loadData();
  this.loadDefaultData();
  }
  // private loadData(): void {
  //   this.folderTemplateService.get(this.model.id).subscribe((data: any) => {
  //     this.model = data;
  //     this.initForm();
  //   }, error => {
  //     this.toasterService.ProcessErrorResponse(error);
  //     this.router.navigate(['/setting/cases/folder-templates']);
  //   }, () => {
  //   });
  // }

  private initForm(): void {
    this.form.controls.name.setValue(this.model.name);
    this.form.controls.access_grp.setValue(this.model.accessGroupId);
    this.form.controls.default_notes.setValue(this.model.defaultNote);
  }

  // private async loadAccessGroup(): Promise<void> {
  //   this.blockUI.start();
  //   this.accessGroupService.getAccessGroups().subscribe((data: any) => {
  //     this.accessGroupSelectListItem = data;
  //     const defaultAccessGroup = this.accessGroupSelectListItem.find(x => x.value);
  //     this.form.controls.access_grp.setValue(defaultAccessGroup.id);
  //   }, error => {
  //     this.toasterService.ProcessErrorResponse(error);
  //   }, () => {
  //     this.blockUI.stop();
  //   });
  // }

  private loadDefaultData(): void{
    forkJoin([
      this.accessGroupService.getAccessGroups(),
      this.folderTemplateService.get(this.model.id),
      this.caseTypeService.get()
    ]).subscribe(([
      accessGroupData,
      folderTemplateData,
      caseTypesdata
    ]) => {
      this.accessGroupSelectListItem = accessGroupData;
      const defaultAccessGroup = this.accessGroupSelectListItem.find(x => x.value);
      this.form.controls.access_grp.setValue(defaultAccessGroup.id);
      this.model = folderTemplateData;
      this.initForm();

      const ct: FormArray = this.caseTypesToFormArray;
      ct.clear();
      caseTypesdata.forEach((d) => {
        ct.push(
          this.formBuilder.group({
            id: [d.id],
            name: [d.name],
            isSelected: [folderTemplateData.caseTypeIds.includes(d.id)],
          })
        );
      });
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate(['/setting/cases/folder-templates']);
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.id = this.model.id;
    this.model.name = this.form.value.name;
    this.model.accessGroupId = Number(this.form.value.access_grp);
    this.model.defaultNote = this.form.value.default_notes;
    this.model.caseTypeIds = this.form.value.case_types.filter(x => x.isSelected).map(x => x.id);

    this.blockUI.start();
    this.folderTemplateService.put(this.model).subscribe(response => {
      setTimeout(() => {
        this.router.navigate(['/setting/cases/folder-templates']);
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Folder template updated successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );

  }

}
