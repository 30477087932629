import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CategorizedPermissionsViewModel, PermissionViewModel } from 'src/app/models/roles/permissions/categorized.permissions.view.model';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { roleAvailableFor } from 'src/app/helpers/constants/roles/available.for.description';
import { RoleAvailableFor } from 'src/app/helpers/enums/roles/role.available.for';
import { RoleService } from 'src/app/services/role.service';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { ToasterService } from 'src/app/services/toater.service';
import { AddOrEditRoleRequestModel } from 'src/app/models/roles/add.role.request.model';
import { Router } from '@angular/router';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';
import { promise } from 'protractor';
import { allowedNodeEnvironmentFlags } from 'process';
import { ClientContactsComponent } from 'src/app/components/Clients/client-contacts/client-contacts.component';
import { Console } from 'console';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-new-user-role',
  templateUrl: './new-user-role.component.html',
  styleUrls: ['./new-user-role.component.css']
})
export class NewUserRoleComponent implements OnInit {
  closeResult = '';
  categorizedPermissionsViewModel: CategorizedPermissionsViewModel[] = [];
  submitted: boolean;
  rolesSelectListItem: SelectListItem<string>[] = [];
  addRoleRequestModel: AddOrEditRoleRequestModel = new AddOrEditRoleRequestModel();
  accessGroups: Array<SelectListItem<number>> = [];
  roleCategorySelectListItem: Array<SelectListItem<number>> = [];
  defaultGroupItems: any[] = [];

  constructor(
    private modalService: NgbModal,
    private router: Router,
    private formBuilder: FormBuilder,
    private roleService: RoleService,
    private toasterService: ToasterService,
    private accessGroupService: AccessGroupService) {
  }
  defaultval = true;

  @BlockUI() blockUI: NgBlockUI;
  form: FormGroup;

  get roleAvailableFor(): any {
    return RoleAvailableFor;
  }
  get permissionFormArray(): FormArray {
    return this.form.controls.permissions as FormArray;
  }
  get accessGroupFormArray(): FormArray {
    return this.form.controls.accessGroups as FormArray;
  }
  get selectedAccessGroups(): any {
    return this.form.value.accessGroups.filter(x => x.isSelected).map(x => ({ id: x.id, value: x.name }));
  }
  get permissionCategoryValues(): any {
    return this.form.value.permissions.map(x => ({ value: x.id, text: x.permissionCategory }));
  }


  async ngOnInit(): Promise<void> {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      rankBelow: [''],
      roleCategory: [null, Validators.required],
      availableFor: [RoleAvailableFor.employee],
      accessGroups: this.formBuilder.array([], [minSelectedCheckboxes()]),
      defaultGroup: [null, Validators.required],
      permissions: this.formBuilder.array([])
    });
    this.loadData();
    //this.loadRoles();
    //this.loadRolecategories();
    //this.loadAccessGroups();
    //this.loadPermissions(RoleAvailableFor.employee);
    document.getElementById("switchrole").click();
  }

  private loadData(): void {
    this.blockUI.start();
    forkJoin([
      this.roleService.getRoles(),
      this.accessGroupService.getAccessGroups(),
      this.roleService.getPermissions(RoleAvailableFor.employee),
      this.roleService.getRoleCategories(),
    ]).subscribe(([
      roles,
      accessGroups,
      permissions,
      roleCategories
    ]) => {
      this.rolesSelectListItem = roles;

      this.accessGroups = accessGroups;
      const fa: FormArray = this.form.get('accessGroups') as FormArray;
      fa.clear();
      accessGroups.forEach(d => {
        fa.push(this.formBuilder.group({
          id: [d.id],
          name: [d.value],
          isSelected: [false]
        }));
      });

      this.permissionFormArray.clear();
      Object.entries(permissions).forEach(
        ([key, value], index) => {
          this.permissionFormArray.push(this.formBuilder.group({
            id: index,
            permissionCategory: [key],
            isSelected: [false],
            permissions: this.formBuilder.array(this.gerPermmissionsFormArray(value as any[]))
          }));
        }
      );

      this.roleCategorySelectListItem = roleCategories;

    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate(['/setting/role']);
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
  }

  private async loadRoles(): Promise<void> {
    this.blockUI.start();
    this.roleService.getRoles().subscribe((data: any) => {
      this.rolesSelectListItem = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }
  private async loadRolecategories(): Promise<void> {
    this.blockUI.start();
    this.roleService.getRoleCategories().subscribe((data: any) => {
      this.roleCategorySelectListItem = data;
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }
  private async loadAccessGroups(): Promise<void> {
    this.blockUI.start();
    this.accessGroupService.getAccessGroups().subscribe((data: Array<SelectListItem<number>>) => {
      this.accessGroups = data;
      const fa: FormArray = this.form.get('accessGroups') as FormArray;
      fa.clear();
      data.forEach(d => {
        fa.push(this.formBuilder.group({
          id: [d.id],
          name: [d.value],
          isSelected: [false]
        }));
      });
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }


  public loadPermissions(roleAvailableFor: RoleAvailableFor): any {
    this.blockUI.start();
    this.roleService.getPermissions(roleAvailableFor).subscribe((data: any) => {
      this.permissionFormArray.clear();
      Object.entries(data).forEach(
        ([key, value], index) => {
          this.permissionFormArray.push(this.formBuilder.group({
            id: index,
            permissionCategory: [key],
            isSelected: [false],
            permissions: this.formBuilder.array(this.gerPermmissionsFormArray(value as any[]))
          }));
        }
      );
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
    }, () => {
      this.blockUI.stop();
    });
  }


  private gerPermmissionsFormArray(values: Array<any>): Array<any> {
    const formGroups = [];
    values.forEach(x => formGroups.push(this.formBuilder.group({
      permission: [x.permission],
      isSelected: [false],
      name: [x.name],
      description: [x.description]
    })));
    return formGroups;
  }

  //enable default group
  selectedCheck(event) {
    this.defaultval = !event.target.checked;
  }

  open(content): void {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onSubmit(): void {
    
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.addRoleRequestModel.name = this.form.value.name;
    this.addRoleRequestModel.description = this.form.value.description;
    this.addRoleRequestModel.rankBelow = this.form.value.rankBelow;
    this.addRoleRequestModel.availableFor = this.form.value.availableFor;
    this.addRoleRequestModel.accessGroupIds = this.form.value.accessGroups.filter(x => x.isSelected).map(x => x.id);
    this.addRoleRequestModel.defaultAccessGroup = Number(this.form.value.defaultGroup);
    this.addRoleRequestModel.roleCategory = Number(this.form.value.roleCategory);
    this.addRoleRequestModel.rolePermissions = this.form.value.permissions.flatMap(x => x.permissions)
      .filter(x => x.isSelected).map(x => x.permission);
    this.blockUI.start();
    this.roleService.post(this.addRoleRequestModel).subscribe(resposne => {
      setTimeout(() => {
        this.router.navigate(['/setting/role']);
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Role created successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }

  getFormValidationErrors(): void {
    Object.keys(this.form.controls).forEach(key => {

      const controlErrors: ValidationErrors = this.form.get(key).errors;
      if (controlErrors != null) {
        Object.keys(controlErrors).forEach(keyError => {
        });
      }
    });
  }


  trackByFn(index, item): any {
    return index; // or item.id
  }



  scrollUp(): void {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  selectAll(isChecked: boolean, id: number): void {
    this.form.value.permissions.filter(x => x.id === id)
      .map(x => { x.permissions.map(y => y.isSelected = isChecked); });
  }
  onPermissionChange(permission: any, id: number): void {
    permission.isSelected = !permission.isSelected;
    const categorizedPermission = this.form.value.permissions.find(x => x.id === id);
    if (categorizedPermission != null) {
      categorizedPermission.isSelected = categorizedPermission.permissions.every(x => x.isSelected);
    }
  }

  scroll(el): void {
    const e = document.getElementById(el);
    e.scrollIntoView({ behavior: 'smooth', block: 'center' });

  }
}
