<nav class="fixed-nav navbar navbar-expand navbar-light navbar-bg fixed-top">
  <form class="form-inline d-none d-sm-inline-block col-md-3">
    <div class="input-group input-group-navbar">
      <div class="input-group-append">
        <button class="btn rounded-left search-btn" type="button">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
            class="feather feather-search align-middle"
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
        </button>
      </div>
      <input
        type="text"
        class="form-control pl-0"
        placeholder="Search company, individual, etc."
        aria-label="Search"
      />
    </div>
  </form>


  <div class="navbar-collapse" [class.collapse]="collapsed" id="navbarContent">
    <ul class="navbar-nav ml-auto">
      <li class="nav-item mr-2" ngbDropdown display="dynamic" container="body" placement="bottom-right" >
        <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown1" role="button">
          <div class="position-relative">
              <i class="align-middle fa fa-life-ring"></i>
            </div>
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown1" class="dropdown-menu common-header-dropdown" placement="bottom" >
         
          <div class="dropdown-menu-lg dropdown-menu-right py-0">
            <div class="dropdown-menu-header">
              <div class="position-relative">4 New Messages</div>
            </div>
              <div class="list-group">
                <a href="#" class="list-group-item">
                  <div class="row no-gutters align-items-center">
                    <div class="col-2">
                      <img
                        onerror=""
                        src="img/avatars/avatar-5.jpg"
                        class="avatar img-fluid rounded-circle"
                        alt="Vanessa Tucker"
                      />
                    </div>
                    <div class="col-10 pl-2">
                      <div class="text-dark">Vanessa Tucker</div>
                      <div class="text-muted small mt-1">
                        Nam pretium turpis et arcu. Duis arcu tortor.
                      </div>
                      <div class="text-muted small mt-1">15m ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="list-group-item">
                  <div class="row no-gutters align-items-center">
                    <div class="col-2">
                      <img
                        onerror=""
                        src="img/avatars/avatar-2.jpg"
                        class="avatar img-fluid rounded-circle"
                        alt="William Harris"
                      />
                    </div>
                    <div class="col-10 pl-2">
                      <div class="text-dark">William Harris</div>
                      <div class="text-muted small mt-1">
                        Curabitur ligula sapien euismod vitae.
                      </div>
                      <div class="text-muted small mt-1">2h ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="list-group-item">
                  <div class="row no-gutters align-items-center">
                    <div class="col-2">
                      <img
                        onerror=""
                        src="img/avatars/avatar-4.jpg"
                        class="avatar img-fluid rounded-circle"
                        alt="Christina Mason"
                      />
                    </div>
                    <div class="col-10 pl-2">
                      <div class="text-dark">Christina Mason</div>
                      <div class="text-muted small mt-1">
                        Pellentesque auctor neque nec urna.
                      </div>
                      <div class="text-muted small mt-1">4h ago</div>
                    </div>
                  </div>
                </a>
                <a href="#" class="list-group-item">
                  <div class="row no-gutters align-items-center">
                    <div class="col-2">
                      <img
                        onerror=""
                        src="img/avatars/avatar-3.jpg"
                        class="avatar img-fluid rounded-circle"
                        alt="Sharon Lessman"
                      />
                    </div>
                    <div class="col-10 pl-2">
                      <div class="text-dark">Sharon Lessman</div>
                      <div class="text-muted small mt-1">
                        Aenean tellus metus, bibendum sed, posuere ac, mattis non.
                      </div>
                      <div class="text-muted small mt-1">5h ago</div>
                    </div>
                  </div>
                </a>
             </div>
            <div class="dropdown-menu-footer">
              <a href="#" class="text-muted">Show all messages</a>
            </div>
          </div>
          
        </div>
      </li>

      <li class="nav-item mr-2" ngbDropdown  container="body" display="dynamic" placement="bottom-right" >
        <a class="nav-link" tabindex="0"   ngbDropdownToggle id="navbarDropdown2" role="button">
             <div class="position-relative">
            <i class="align-middle fa fa-bell"></i>
            <span class="indicator badge-success"></span>
          </div>
        </a>
        <div ngbDropdownMenu aria-labelledby="navbarDropdown2"  container="body" placement="bottom" class="dropdown-menu dropdown-menu-right common-header-dropdown">
          
          <div class="dropdown-menu-lg dropdown-menu-right py-0"   aria-labelledby="alertsDropdown"       >
            <div class="dropdown-menu-header">4 New Notifications</div>
            <div class="list-group">
              <a href="#" class="list-group-item">
                <div class="row no-gutters align-items-center">
                  <div class="col-2">
                    <i
                      class="fas fa-exclamation-circle text-danger"
                      data-feather="alert-circle"
                    ></i>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">Update completed</div>
                    <div class="text-muted small mt-1">Restart server 12 to</div>
                    <div class="text-muted small mt-1">30m ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row no-gutters align-items-center">
                  <div class="col-2">
                    <i class="fas fa-bell text-warning" data-feather="bell"></i>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">Lorem ipsum</div>
                    <div class="text-muted small mt-1">
                      Aliquam ex eros, imperdiet vet.
                    </div>
                    <div class="text-muted small mt-1">2h ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row no-gutters align-items-center">
                  <div class="col-2">
                    <i class="fas fa-home" data-feather="home"></i>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">Login from 192.186.1.8</div>
                    <div class="text-muted small mt-1">5h ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row no-gutters align-items-center">
                  <div class="col-2">
                    <i
                      class="fas fa-user-plus text-success"
                      data-feather="user-plus"
                    ></i>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">New connection</div>
                    <div class="text-muted small mt-1">
                      Christina accepted your request.
                    </div>
                    <div class="text-muted small mt-1">14h ago</div>
                  </div>
                </div>
              </a>
            </div>
            <div class="dropdown-menu-footer">
              <a href="#" class="text-muted">Show all notifications</a>
            </div>
         </div>
          

        </div>
      </li>

      <li class="nav-item" ngbDropdown  container="body" display="dynamic" placement="bottom">
        <a class="nav-link" tabindex="0" ngbDropdownToggle id="navbarDropdown3" role="button">
          <img [src]="profileImg"
            onerror=""
            class="avatar img-fluid rounded-circle mr-1"
            alt="Barie Larson"
          />
          <span class="text-dark header-admin-name">{{user?.name}}</span>
        </a>
        <div ngbDropdownMenu placement="left-bottom"  aria-labelledby="navbarDropdown3" class="dropdown-menu  mr-7">
        


           <a class="dropdown-item" routerLink="/myProfile"
            ><i class="fas fa-user"></i> Profile</a
          >
          <a class="dropdown-item" href="#"
            ><i class="fas fa-chart-pie"></i>  Analytics</a
          >
          <a class="dropdown-item" href="#" routerLink="/change-password"
            ><i class="fas fa-unlock"></i>  Change Password</a
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/setting"
            ><i class="fas fa-cog"></i>  Settings
            & Privacy</a
          >
          <a class="dropdown-item" href="#"
            ><i class="fas fa-info-circle"></i>  Help
            Center</a
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/account/logout"
            >Log out <i class="fas fa-sign-out-alt"></i></a>
        </div>
      </li>
    </ul>
  </div>


 
</nav>
