import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticateResponseModel } from 'src/app/models/authenticate/authenticate.response.model';
import { AuthenticationService } from 'src/app/services/authentication.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],

  host: {
    '(error)': 'updateUrl()',
    '(load)': 'load()',
    '[src]': 'src'
  }
})
export class HeaderComponent implements OnInit {
  user: AuthenticateResponseModel;
  profileImg: string;

  constructor(private authenticationService: AuthenticationService) {
    this.authenticationService.user$.subscribe((user) => {
      this.user = user;
      this.profileImg = this.user?.profileImgUrl ?? '/assets/img/avatars/user.svg';
    });
  }

  @Input() src: string;
  @Input() default: string;
  @HostBinding('class') className

  updateUrl() {
    this.src = this.default;
  }
  load() {
    this.className = 'image-loaded';
  }

  pic: string;


  //ng dropdown
  collapsed = true;

  setDefaultPic() {
    this.pic = "../../../../assets/img/no-image.jpg";
  }


  ngOnInit(): void {


  }

}
