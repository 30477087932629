<div class="row">

    <div class="col-md-8">
        <div class="card">
            <div class="card-body">
                <div class="card-title border-bottom pb-2 pt-2">
                    <div class="row justify-content-between">
                        <div class="col-md-9">
                            <h4 class="font-weight-bold"> REQ-18213 (Parada- Rodriguez, Jose)</h4>
                        </div>
                        <div class="col-md-3 text-right">
                            <div class="detail-client-title font-weight-bold mr-3"><span>Status: </span><span
                                    class="badge badge-success">Enabled </span></div>
                        </div>
                    </div>

                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div id="update-body">
                            <p>From: Shereen Fahrai &lt;<a
                                    href="mailto:shereen@arguswest.com">shereen@arguswest.com</a>&gt;<br> Date: Wed, Oct
                                6, 2021 at 5:17 PM<br> Subject: 30217427016-0001 Fernandez, Mercedes - Medical Canvass
                                Report<br> To: &lt;<a
                                    href="mailto:Christina.Morales@sedgwick.com">Christina.Morales@sedgwick.com</a>&gt;
                            </p>
                            <p>Good afternoon, Christina.</p>
                            <p>In a few moments, I will fax over the Medical Canvass Report and our Service Invoice.</p>
                            <p>We found eight hits:<br> Rite Aid Pharmacy, Mail to: 650 Walnut Ave., Greenfield, CA
                                93927<br> Salinas Valley Memorial Hospital, Mail to: 450 East Romie Lane, Salinas, CA
                                93901-4098<br> Pacific Eye, Mail to: 3855 Broad St., Ste. B, San Luis Obispo, CA
                                93401<br> George L. Mee Memorial Hospital, Mail to: 300 Canal St., King City, CA
                                93930-3431<br> Mission Center Health Care, Mail to: 2524 H Dela Rosa Sr. St., Soledad,
                                CA 93960<br> CVS Pharmacy, Mail to: 347 E Alisal St., Salinas, CA 93901<br> Clinica De
                                Salud Del Valle, Mail to: 10561 Merritt St., Castroville CA 95012-3310<br> Doctors On
                                Duty, Mail to: 558 Abbott St., Ste. A, Salinas, CA 93901</p>
                            <p>The following require HIPAA's:<br> Dignity Health Community Hospital of San Bernardino,
                                Mail to: 1805 Medical Center Drive, San Bernardino, CA 92411<br> Laurel Family Practice
                                Clinic, Mail to: 1441 Constitution Blvd., Salinas, CA 93906<br> Arrowhead Regional
                                Medical Center, Mail to: 400 North Pepper Avenue, Colton, CA 92324-1819<br> Community
                                Hospital Monterey Peninsula, Mail to: HIM Legal Desk, PO Box HH, Monterey, CA 93942<br>
                                St. Bernardine Medical Center, Mail to: 2101 North Waterman Avenue, San Bernardino, CA
                                92404<br> Natividad Medical Center, Mail to: 1441 Constitution Boulevard, Salinas, CA
                                93906-3100</p>
                            <p>Thank you,</p>
                            <p>Shereen Fahrai</p>
                            <p>Case Management</p>
                            <p>Argus West Investigative Services</p>
                            <p>O (619) 502-9519</p>
                            <p><a href="mailto:shereen@arguswest.com">shereen@arguswest.com</a></p>
                            <p>&nbsp;</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-4">
        <div class="card">
            <div class="card-body">
                <div class="card-title pb-2 pt-2">
                    <h4 class="font-weight-bold">Posted Details</h4>
                </div>

                <div class="row">
                    <div class="col-md-12">
                        <div class="row mb-3">
                            <div class="col-md-4 da-font-bold font-weight-bold">
                                Posted by
                            </div>
                            <div class="col-md-8">
                                <a href="#" class="link m-r-10">FAHRAI, SHEREEN</a>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-md-4 da-font-bold font-weight-bold">
                                Date & Time
                            </div>
                            <div class="col-md-8">
                                10/6/21 5:17 PM
                            </div>
                        </div>
                        <div class="row mb-3">
                            <div class="col-md-4 da-font-bold font-weight-bold">
                                Regarding
                            </div>
                            <div class="col-md-8">
                                <a href="#" class="link m-r-10">Sandoval Ruiz, Jose J</a>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-md-4 da-font-bold font-weight-bold">
                                Access Group
                            </div>
                            <div class="col-md-8">
                                <a href="#" class="link m-r-10">Internal</a>
                            </div>
                        </div>
                        <hr>

                        <div class="row  mb-3">
                            <div class="col-md-4 da-font-bold font-weight-bold">
                                Validated by
                            </div>
                            <div class="col-md-8">
                                <a href="#" class="link m-r-10"> STUBENVOLL, MIKE</a>
                            </div>
                        </div>

                        <div class="row mb-3">
                            <div class="col-md-4 da-font-bold font-weight-bold">
                                Date & Time
                            </div>
                            <div class="col-md-8">
                                October 6, 2021 9:12 PM
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>