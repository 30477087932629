<main class="content py-0">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-md-12 pt-7">
          <div class="w-100">
            <div class="row">
              <div class="col-md-12 px-0 pb-5">
                <div class="col-auto mt-n1">
                  <app-breadcrumb></app-breadcrumb>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto d-none d-sm-block mb-3">
                <h3 class="page-title">
                  <strong>Client Contacts </strong>
                </h3>
              </div>


              <div class="col-auto ml-auto text-right mt-n1">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                      <li class="filter-item" *hasPermissions='[permissionType.CreateClientContacts]'>
                        <button class="btn btn-lg bg-blue text-white" (click)="openNewContactPopup()">
                          <i class="align-middle fas fa-plus-circle"></i> New Contact
                        </button>
                      </li>
                      <li class="filter-item" *hasPermissions='[permissionType.CreateClientContacts]'>
                        <button class="btn btn-lg bg-blue text-white" [routerLink]="['/setting/role/new-company-policy']">
                          <i class="fas fa-file-export"></i> Export
                        </button>
                      </li>
                      <li class="filter-item">
                        <div ngbDropdown class="d-inline-block">
                          <button class="btn btn-outline-primary btn-lg bulkaction-dropdown" id="dropdownBasic1" ngbDropdownToggle
                            [disabled]="isBulkActionDisabled"
                            *ngIf="filterBy == clientContactFilterOptions.DeletedContacts ? hasPermissionType.validate([permissionType.DeleteClientContacts]) : hasPermissionType.validate([permissionType.DeleteClientContacts, permissionType.AssignOrUnassignSalespeople])">Bulk
                            Action</button>
                          <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
                            <button ngbDropdownItem (click)="OnBulkAssignSalesperson()"  *ngIf="filterBy != clientContactFilterOptions.DeletedContacts && hasPermissionType.validate([permissionType.AssignOrUnassignSalespeople])">
                                                      Assign Salesperson</button>
                            <button ngbDropdownItem (click)="onBulkDelete()"
                              *ngIf="filterBy != clientContactFilterOptions.DeletedContacts && hasPermissionType.validate([permissionType.DeleteClientContacts])">Delete</button>
                            <button ngbDropdownItem (click)="onBulkUnDelete()"
                              *ngIf="filterBy == clientContactFilterOptions.DeletedContacts && hasPermissionType.validate([permissionType.DeleteClientContacts])">Undelete</button>
                          </div>
                        </div>
                      </li>
                      <li class="filter-item px-0 filter-icon" (click)="filterNavStatus = true">
                        <button class="btn btn-lg bg-blue text-white">
                          <i class="align-middle fas fa-filter"></i>
                        </button>
                      </li>
                    </ol>
                </nav>
            </div>

            

           
            <!-- Filter right sidenav -->
            <app-client-contacts-filter (onApplyFilter)="applyFilter($event)" 
                (resetFilters)="onResetFilters()" [filterStatusParent]="filterNavStatus"  (closeBtnFilterStatus)="closeFilterEvent($event)"  ></app-client-contacts-filter>
            </div>

            <div class="row mt-3">
                <div class="col-md-10"></div>
                <div class="col-md-2  ">
                    <div class="form-group form-inline float-right">
                        <button class="dropdown-toggle btn  btn-lg  bulkaction-dropdown"
                        (click)="openCustomizedListModal()"> Customize List&nbsp; </button>  
                    </div>
                </div>
            </div>
          
  
           
              <div class="row table-responsive">
                <div class="col-md-12">
                  <table
                  class="
                    neu-table
                    table table-striped
                    neu-table
                    w-auto
                    table-responsive
                  "
                  [ngStyle]="{ visibility: isDtVisible ? 'visible' : 'hidden' }"
                  datatable
                  [dtOptions]="dtOptions"
                ></table>
                </div>
              </div>

              
  
             
              </div>
           
          </div>
        </div>
      </div>
  </main>
  