import { AuthenticationService } from "./authentication.service";


export function AppInitializer(authenticationService: AuthenticationService) {
    return () => new Promise(resolve => {
      // attempt to refresh token on app start up to auto authenticate
      authenticationService.refreshToken()
        .subscribe()
        .add(resolve);
    });
  }