import { Component, ViewChild, OnInit, OnDestroy, Renderer2, AfterViewInit, Directive, PipeTransform, EventEmitter, Input, Output, QueryList, ViewChildren, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { ClientService } from 'src/app/services/client.service';
import { FormGroup, FormArray } from '@angular/forms';
import { Page } from 'src/app/helpers/enums/customize-column/page';
import { ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AppUtils } from 'src/app/helpers';
// for filters
import { ModalService } from 'src/app/services/modal.service';
import { ClientDtColumnName } from 'src/app/helpers/constants/client/client.column.name';
import { CaseDtColumnName } from 'src/app/helpers/constants/Case/client.column.name';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';

import * as moment from 'moment';
import { CaseService } from 'src/app/services/case.service';
import { date } from '@rxweb/reactive-form-validators';

@Component({
  selector: 'app-case-list',
  templateUrl: './case-list.component.html',
  styleUrls: ['./case-list.component.css']
})

export class CaseListComponent {

  constructor(private caseService: CaseService,
    private tosterService: ToasterService,
    private renderer: Renderer2,
    private router: Router,
    private appUtil: AppUtils,
    private modalService: ModalService,
    public cdr: ChangeDetectorRef) {

  }

  // for filters
  formodel: string;
  frm: FormGroup;
  filterOptions: any;

  // for ng modal
  items: FormArray;

  // for angular datatable
  @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;
  dtRendered = true;
  visibleColumns = [];
  isDtVisible = false;
  isDtInitialized = false;
  permissionType = Permission;
  hasPermissionType = HasPermission;

  // for filters
  private searchBy?: number;
  private searchFor: string;
  private searchFrom: string;
  private filterByOptionId = 0;
  private filterByItemId: string;

  get filterByOption(): number {
    return this.filterByOptionId;
  }

  users: any = [];
  isBulkActionDisabled = true;

  // capture filter open and close events
  filterNavStatus = false;
  closeFilterEvent(event: any): void {
    this.filterNavStatus = event;
  }

  ngOnInit(): void {
    this.initDataTable();
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtInstance = dtInstance;
      const header = dtInstance.columns().header();
      $(header).find('[action-type="select-unSelect-all"]')
        .off('change').on('change', (e: any) => {
          this.onSelectAllRow(e.target.checked);
        });
    });
  }
  getFormattedName(data: any[]):string{

    let name = "";
    if(data.length>0) {
      data.forEach((val,index)=>{
        name = name + val.value;
        if(index < data.length-1){
          name = name + ",";
        }
      })
    }
   
    return name;
  }
  openCustomizedListModal(): void {
    debugger;
    this.modalService.openModalForConfiguringCustomizedList(Page.CaseList).subscribe(() => {
      this.isDtVisible = false;
      this.isDtInitialized = false;
      this.dtInstance.ajax.reload(null, false);
      this.tosterService.success('Customized list has been updated successfully');
    });
  }

  private showColumns(): void {
    const visibleColumnsSelector = this.visibleColumns.map(item => `${item.toLowerCase()}:name`)
      .concat(['action:name', 'select:name']);
    const visibleIndexes = this.dtInstance.columns(visibleColumnsSelector).indexes().toArray();
    this.dtInstance.columns().every(function () {
      const colIndex = this.index();
      this.visible(visibleIndexes.includes(colIndex));
    });
    this.isDtVisible = true;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  private initDataTable(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      destroy: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },
      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,
      order: [[1, 'asc']],
      autoWidth: false,
      ajax: (dataTablesParameters: any, callback) => {
        self.blockUI.start();
        debugger;
        dataTablesParameters.searchBy = self.searchBy;
        dataTablesParameters.searchFor = self.searchFor;
        dataTablesParameters.searchFrom = self.searchFrom;
        dataTablesParameters.filterOptionId = self.filterByOptionId;
        dataTablesParameters.filterItemId = self.filterByItemId;
        dataTablesParameters.isDtInitialized = self.isDtInitialized;
        self.caseService.getCase(dataTablesParameters)
          .subscribe(resp => {
            debugger;
            this.visibleColumns = resp.visibleColumns;
            self.dtInstance.page.len(resp.pageLength);
            self.isDtInitialized = true;
            self.showColumns();
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            self.blockUI.stop();
          });
      },
      columns: [
        {
          data: null,
          title: `<input type="checkbox" action-type="select-unSelect-all" />`,
          name: 'select',
          orderable: false,
          render: (data, type, row) => {
            return `<input type='checkbox' value = '${row.id}' action-type= "select-row"/>`;
          }
        },
        {
          data: CaseDtColumnName.caseNumber,
          name: CaseDtColumnName.caseNumber.toLowerCase(),
          title: 'Case Number',
          render: (data, type, row) => {
            const html = HasPermission.validate([Permission.ViewClients]) && !row.isDeleted ?
              `<a class='link m-r-10' action-type='link-detail'>${row.caseNumber}</a>` : `<span>${row.caseNumber}</span>`;
            return html;
          }
        },
        {
          data: CaseDtColumnName.claimNumber,
          name: CaseDtColumnName.claimNumber.toLowerCase(),
          title: 'Claim Number',
          render: (data, type, row) => {
            let html = "";
             data.forEach((claimNumber)=>{
               html = html + `<span style=border-radius:4px;padding:2px 4px;width:auto !important;max-width:300px !important'>
               ${claimNumber.value}
               </span>`;
             })
             
             return html;
           }
          
        },
        {
          data: CaseDtColumnName.pinnedSubject,
          name: CaseDtColumnName.pinnedSubject.toLowerCase(),
          title: 'Insured',
          render: (data, type, row) => {
            let html = "";
             data.forEach((pinnedSubject)=>{
               html = html + `<span style=border-radius:4px;padding:2px 4px;width:auto !important;max-width:300px !important'>
               ${pinnedSubject.value}
               </span>`;
             })
             
             return html;
           }
          
          
        },
        {
          data: CaseDtColumnName.dol,
          name: CaseDtColumnName.dol.toLowerCase(),
          title: 'DOL (Date of Loss)',
          render: (data, type, row) => {
            let html = "";
            
             data.forEach((dol)=>{
             var result = this.isJsonString(dol.value)
             if(result==false)
              {
                html = html + `<span style=border-radius:4px;padding:2px 4px;width:auto !important;max-width:300px !important'>
               
               
                </span>`;
                return html;
              }
              const parsedValue = JSON.parse(dol.value);
              const d = new Date(
                parsedValue.year,
                parsedValue.month,
                parsedValue.day
              );
              
             
               html = html + `<span style=border-radius:4px;padding:2px 4px;width:auto !important;max-width:300px !important'>
               
               ${parsedValue.month}/${parsedValue.day}/${parsedValue.year}
               </span>`;
             })
             
             return html;
           }
          
          
          
        },
        {
          data: CaseDtColumnName.caseStatus,
          name: CaseDtColumnName.caseStatus.toLowerCase(),
          title: 'Case Status',
          render: (data, type, row) => {
            let html = "";
           
               html = html + `<span class='link m-r-10 case-status-label' style='background-color:${row.caseStatusColour}; [data-pos]='this.appUtil.lightOrDark(cf.colourId)'>
               ${data}
               </span>`;
            
             
             return html;
           }
         
        },
        {
          data: CaseDtColumnName.lastStatusChange,
          name: CaseDtColumnName.lastStatusChange.toLowerCase(),
          title: 'Last Status Change',
           render: (data, type, row) => {
            return moment(row.lastUpdate).format('MMM DD, YYYY');
          }
          
          
        },
        {
          data: CaseDtColumnName.referralSource,
          name: CaseDtColumnName.referralSource.toLowerCase(),
          title: 'Referral',
          
        },
        {
          
          data: CaseDtColumnName.subjects,
          name: CaseDtColumnName.subjects.toLowerCase(),
          title: 'Subjects',
          render: (data, type, row) => {
            let html = "";
             data.forEach((subject)=>{
               html = html + `<span style=border-radius:4px;padding:2px 4px;width:auto !important;max-width:300px !important'>
               ${subject.value}
               </span>`;
             })
             
             return html;
           }
          
        },
        {
          data: CaseDtColumnName.clientName,
          name: CaseDtColumnName.clientName.toLowerCase(),
          title: 'Client',
          
        },
        {
          data: CaseDtColumnName.clientLocation,
          name: CaseDtColumnName.clientLocation.toLowerCase(),
          title: 'Client Location',
          
          
        },
        {
          data: CaseDtColumnName.state,
          name: CaseDtColumnName.state.toLowerCase(),
          title: 'State',
          
          
        },
        {
          data: CaseDtColumnName.county,
          name: CaseDtColumnName.county.toLowerCase(),
          title: 'County',
          
          
        },
        {
          data: CaseDtColumnName.clientContact,
          name: CaseDtColumnName.clientContact.toLowerCase(),
          title: 'Primary Client Contact',
          
        },
        {
          data: CaseDtColumnName.primaryInvestigator,
          name: CaseDtColumnName.primaryInvestigator.toLowerCase(),
          title: 'Primary Investigator',
          
        },
        {
          data: CaseDtColumnName.caseManager,
          name: CaseDtColumnName.caseManager.toLowerCase(),
          title: 'Primary Case Manager',
          
        },
        {
          data: CaseDtColumnName.salesPerson,
          name: CaseDtColumnName.salesPerson.toLowerCase(),
          title: 'Primary Salesperson',
          
        },
        {
          data: CaseDtColumnName.caseType,
          name: CaseDtColumnName.caseType.toLowerCase(),
          title: 'Case Type',
        },
        {
          data: CaseDtColumnName.caseTag,
          name: CaseDtColumnName.caseTag.toLowerCase(),
          title: 'Case Type(Tag)',
          
        },
        {
          data: CaseDtColumnName.caseRegion,
          name: CaseDtColumnName.caseRegion.toLowerCase(),
          title: 'Case Region',
        },
        {
          data: CaseDtColumnName.caseLocation,
          name: CaseDtColumnName.caseLocation.toLowerCase(),
          title: 'Case Location',
          
        },
        {
          data: CaseDtColumnName.hours,
          name: CaseDtColumnName.hours.toLowerCase(),
          title: 'Budget (Hours)',
          render: (data, type, row) => {
            return this.converttodecimal(row.hours);
          }
        },
        {
          data: CaseDtColumnName.budgetMoney,
          name: CaseDtColumnName.budgetMoney.toLowerCase(),
          title: 'Budget (Money)',
          // render: (data, type, row) => {
          //   return this.converttodecimal(row.hours);
          // }
        },
        {
          data: CaseDtColumnName.expenseTotal,
          name: CaseDtColumnName.expenseTotal.toLowerCase(),
          title: 'Expense Total',
          
        },
        {
          data: CaseDtColumnName.invoiceEstimate,
          name: CaseDtColumnName.invoiceEstimate.toLowerCase(),
          title: 'Invoice Estimate',
          
        },
        {
          data: CaseDtColumnName.invoiceTotal,
          name: CaseDtColumnName.invoiceTotal.toLowerCase(),
          title: 'Invoice Total',
          
        },
        {
          
          data: CaseDtColumnName.caseServicesInCases,
          name: CaseDtColumnName.caseServicesInCases.toLowerCase(),
          title: 'Services',
          render: (data, type, row) => {
           let html = "";
           
              html = html + `<span style='border-radius:4px;padding:2px 4px;width:auto !important;max-width:300px !important'>
              ${this.getFormattedName(data)}
              </span>`;
            
            
            return html;
          }
          
        },
        {
          
          data: CaseDtColumnName.flags,
          name: CaseDtColumnName.flags.toLowerCase(),
          title: 'Flags',
          render: (data, type, row) => {
           let html = "";
            data.forEach((flag)=>{
              html = html + `<span style='background-color:${flag.colourId};border-radius:4px;padding:2px 4px;color:#000 !important;width:auto !important;max-width:fit-content' class='link m-r-10  ml-2 mt-1' [data-pos]='this.appUtil.lightOrDark(cf.colourId)'>
              ${flag.value}
              </span>`;
            })
            
            return html;
          }
          
        },
        
        {
          data: CaseDtColumnName.dueDate,
          name: CaseDtColumnName.dueDate.toLowerCase(),
          title: 'Due Date',
          render: (data, type, row) => {
            if(row.dueDate==null)
            {
              return '';
            }
            return moment(row.dueDate).format('MMM DD, YYYY');
          }
        },
       
        {
          data: CaseDtColumnName.scheduledDate,
          name: CaseDtColumnName.scheduledDate.toLowerCase(),
          title: 'Scheduled Date',
          
        },
       
        // {
        //   data: CaseDtColumnName.adminNotes,
        //   name: CaseDtColumnName.adminNotes.toLowerCase(),
        //   title: 'Admin Notes',
        // },
        {
          data: CaseDtColumnName.schedulingNotes,
          name: CaseDtColumnName.schedulingNotes.toLowerCase(),
          title: 'Scheduling Notes',
          
        },
        // {
        //   data: CaseDtColumnName.instructionNotes,
        //   name: CaseDtColumnName.instructionNotes.toLowerCase(),
        //   title: 'Instruction Notes',
          
        // },
       
        
        {
          data: CaseDtColumnName.createdOn,
          name: CaseDtColumnName.createdOn.toLowerCase(),
          title: 'Date Created',
          render: (data, type, row) => {
            return moment(row.createdOn).format('MMM DD, YYYY');
          }
        },
       
        {
          data: CaseDtColumnName.systemCreatedOn,
          name: CaseDtColumnName.systemCreatedOn.toLowerCase(),
          title: 'Date Created (System)',
          render: (data, type, row) => {
            return moment(row.systemCreatedOn).format('MMM DD, YYYY');
          }
          
        }, 
       
        {
          data: CaseDtColumnName.lastUpdate,
          name: CaseDtColumnName.lastUpdate.toLowerCase(),
          title: 'Last Update',
          render: (data, type, row) => {
            return moment(row.lastUpdate).format('MMM DD, YYYY');
          }
          
        },
        {
          data: CaseDtColumnName.avgRating,
          name: CaseDtColumnName.avgRating.toLowerCase(),
          title: 'Avg Rating',
          
        },
       
        {
          data: 'name',
          title: (HasPermission.validate([Permission.DeleteClients]) || HasPermission.validate([Permission.EditClients])) ? 'Action' : '',
          name: 'action',
          orderable: false,
          render: (data, type, row) => {
            const permissionDelete = HasPermission.validate([Permission.DeleteClients]);
            const permissionEdit = HasPermission.validate([Permission.EditClients]);
            const deleteButton = permissionDelete ? `<button  class='btn del-btn btn-lg btn-table' action-type="delete">
            <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
              </button>`
              : '';
            const undeleteButton = permissionDelete ? `<button  class='btn del-btn btn-lg btn-table' action-type="un-delete">
              <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-restore-alt  fa-1x p-2 text-danger'></i>
              </button>`
              : '';
            const editButton = !row.isDeleted && permissionEdit
              ? `<button  class='btn del-btn btn-lg btn-table' action-type="edit" alt="undelete">
              <i class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
                </button>`
              : '';

            return `<div class='fa-edit'>${editButton}
                    ${row.isDeleted ? undeleteButton : deleteButton}
                    </div>`;
          }
        },
      ],
      rowCallback: (row, data: any) => {
        const _row = $(row);

        _row.find('[action-type="link-detail"]')
          .off('click')
          .on('click', () => {
            self.linkDetail(data.id);
          });
        _row.find('[action-type="edit"]')
          .off('click')
          .on('click', () => {
            self.edit(data.id);
          });
        _row.find('[action-type="delete"]')
          .off('click')
          .on('click', () => {
            self.delete([data.id]);
          });
        _row.find('[action-type="un-delete"]')
          .off('click')
          .on('click', () => {
            self.unDelete([data.id]);
          });
        _row.find('[action-type = "select-row"]')
          .off('click').on('click', (e: any) => {
            this.onSelectedOrUnselectRow(e);
          });
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
    };
  }

  private reloadDataTable(): void {
    this.dtInstance.ajax.reload();
    this.resetSelectAllCheckBox();
  }
  

  // for filters
  applyFilter(filterData: any): void {
    this.searchBy = Number(filterData.searchBy);
    this.searchFor = filterData.searchFor;
    this.searchFrom=filterData.searchFrom;

    const filterIdArr = filterData.filter?.split('_');
    if (filterIdArr && filterIdArr.length > 0) {
      this.filterByOptionId = Number(filterIdArr[0]);
      if (filterIdArr.length > 1) {
        this.filterByItemId = filterIdArr[1];
      }
    }
    this.reloadDataTable();
  }

  onResetFilters(): void {
    this.searchBy = null;
    this.searchFor = '';
    this.searchFrom = '';
    this.filterByOptionId = 0;
    this.filterByItemId = '';
    this.reloadDataTable();
  }

  private openAssignSalepersonModal(): void {
    this.modalService.openClientAssignSalespersonModel(this.getSelectedClientIds()).subscribe(
      next => {
        this.dtInstance.ajax.reload(null, false);
      });
  }

  delete(ids: number[]): void {
    if (!confirm('Are you sure you want to delete selected case(s)?')) {
      return;
    }
    this.blockUI.start();
    this.caseService.deleteCase(ids).subscribe(() => {
      this.reloadDataTable();
      this.tosterService.success('Case(s) has been deleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  unDelete(ids: number[]): void {
    if (!confirm('Are you sure you want to undelete selected case(s)?')) {
      return;
    }
    this.blockUI.start();
    this.caseService.undeleteCase(ids).subscribe(() => {
      this.reloadDataTable();
      this.tosterService.success('Case(s) has been undeleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  onSelectAllRow(isChecked: boolean): void {
    const rows = this.dtInstance.rows().nodes();
    const checkBoxes = [...rows.$('[action-type= "select-row"]')];
    if (isChecked) {
      checkBoxes.forEach(row => {
        this.renderer.addClass(row, 'checked');
        (row as HTMLInputElement).checked = true;
      });
    } else {
      checkBoxes.forEach(row => {
        this.renderer.removeClass(row, 'checked');
        (row as HTMLInputElement).checked = false;
      });
    }
    this.enableOrDisableDeleteButton();
  }

  onSelectedOrUnselectRow(event: any): void {
    if (event.target.checked) {
      this.renderer.addClass(event.target, 'checked');
    } else {
      this.renderer.removeClass(event.target, 'checked');
    }
    this.toggleControls();
  }

  onBulkDelete(): void {
    this.delete(this.getSelectedClientIds());
  }

  onBulkUnDelete(): void {
    this.unDelete(this.getSelectedClientIds());
  }

  OnBulkAssignSalesperson(): void {
    this.openAssignSalepersonModal();
  }

  linkDetail(id: number): void {
    // this.router.navigate(['/client/', id]);
    this.router.navigate(['/case-detail/', id]);
  }

  edit(id: number): void {
    this.router.navigate(['/client/edit-client/', id]);
  }

  private enableOrDisableDeleteButton(): void {

    const rows = this.dtInstance.rows().nodes();
    this.isBulkActionDisabled = [...rows.$('.checked')].length === 0;
  }

  private getSelectedClientIds(): any[] {
    const clientIds: number[] = [];
    const rows = this.dtInstance.rows().nodes();
    [...rows.$('.checked')].forEach(item => clientIds.push(Number($(item).val())));
    return clientIds;
  }

  private toggleControls(): void {
    this.setSelectAllCheckbox();
    this.enableOrDisableDeleteButton();
  }

  private setSelectAllCheckbox(): void {
    const rows = this.dtInstance.rows().nodes();
    if (rows !== null) {
      const header = this.dtInstance.columns().header();
      const selectAllElement = $(header).find('[action-type="select-unSelect-all"]')[0] as any;
      const selelectedRows = [...rows.$('[action-type= "select-row"]')];
      selectAllElement.checked = selectAllElement != null && selelectedRows.length > 0
        && selelectedRows.every(row => row.classList.contains('checked'));
    }
  }
  converttodecimal(data:any) {
   
    return parseFloat(data).toFixed(2);
     
  }
   isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}
  private resetSelectAllCheckBox(): void {
    const header = this.dtInstance.columns().header();
    const selectAllElement = $(header).find('[action-type="select-unSelect-all"]')[0] as any;
    if (selectAllElement && selectAllElement.checked) {
      selectAllElement.checked = false;
    }
  }
  

}




