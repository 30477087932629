<div class="row pt-5 pb-4">
    <div class="col-md-6">
        <div class="row  align-items-center">
            <div class="col-md-3">
                <div class="">
                    <img  class="rounded-circle border border-orange-color img-thumbnail mb-4 staff-employee-photo"
                        onerror="" [src]="imgSrc" alt="">
                </div>

            </div>
            <div class="col-md-9">
                <div class="field ">
                    <label for="files" button="" type="file" name="image"
                        class="btn bg-blue text-white px-4 py-2 font-weight-500 mb-0">Upload
                        Photo </label>
                    <input #inputFile style="display: none;" type="file" id="files" (change)="onFileSelected($event)" accept=".jpg, .jpeg, .png">
                </div>
            </div>
        </div>
    </div>
</div>