import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services/authentication.service';
import { ToasterService } from '../services/toater.service';

@Injectable()
export class UnauthenticatedUserGuard implements CanActivate {

    constructor(private router: Router, private authenticationService: AuthenticationService,
                private toasterService: ToasterService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // If user is authenticated and token not expired then proceed to home page
        if (this.authenticationService.isLoggedIn() && !this.authenticationService.isTokenExpired()) {
            this.router.navigate(['/']);
            this.toasterService.error('You are trying to access an unauthenticated page. Please logout from the app and try again');
            // this.router.navigate(['/'], { queryParams: { returnUrl: state.url } });
            return false;
        }

        return true;
    }
}
