import {Component, OnInit} from '@angular/core';
@Component({
    selector: 'app-new-expense-entry',
    templateUrl: './new-expense-entry.component.html',
    styleUrls: ['./new-expense-entry.component.css']
})

export class NewExpenseEntryComponent{
    constructor(){

    }
}

