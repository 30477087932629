<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12 px-0 pb-5">
                            <div class="col-auto mt-n1">
                                <app-breadcrumb></app-breadcrumb>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-auto d-none d-sm-block mb-3">
                            <h3 class="page-title"><strong>Edit User Role</strong> </h3>
                        </div>
                    </div>
                    <form class="new-case-form" name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="card">

                                    <div class="card-body">

                                        <div class="card-text">

                                            <div class="row">

                                                <div class="col-md-6">
                                                    <div class="col-md-12">
                                                        <div class="form-group has-search">
                                                            <label for="role">Name <sup
                                                                    class="text-danger">*</sup></label>
                                                            <input name="accountCode" type="text"
                                                                class="form-control mb-3" formControlName="name"
                                                                placeholder="Name" autocomplete="off" />
                                                            <div *ngIf="submitted && form.controls.name.errors"
                                                                class="text-danger">
                                                                <div *ngIf="form.controls.name.errors.required">
                                                                    Name is required
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group has-search">
                                                            <label for="role">Description</label>
                                                            <input name="accountCode" type="text"
                                                                class="form-control mb-3" formControlName="description"
                                                                placeholder="Description" autocomplete="off" />

                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group has-search">
                                                            <label for="role">Rank Below</label>

                                                            <div class="input-group position-relative">
                                                                <select formControlName="rankBelow">
                                                                    <option selected disabled>
                                                                        Select
                                                                    </option>
                                                                    <option *ngFor="let item of rolesSelectListItem"
                                                                        [value]="item.id">
                                                                        {{item.value}}
                                                                    </option>
                                                                </select>
                                                            </div>


                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="form-group has-search">
                                                            <label for="billemail">Jump to</label>
                                                            <div class="input-group position-relative">
                                                                <select class="form-group"
                                                                    (change)="scroll($event.target.value)">
                                                                    <option selected disabled>Select</option>
                                                                    <option [value]="item.value"
                                                                        *ngFor="let item of permissionCategoryValues;">
                                                                        {{item.text}}
                                                                    </option>

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group has-search">
                                                            <label for="billemail">Role Categroy</label>
                                                            <div class="input-group position-relative">
                                                                <select formControlName="roleCategory">
                                                                    <option selected disabled>
                                                                        Select
                                                                    </option>
                                                                    <option
                                                                        *ngFor="let item of roleCategorySelectListItem"
                                                                        [value]="item.id">
                                                                        {{item.value}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">

                                                    <div class="col-md-12">
                                                        <div class="form-group has-search">
                                                            <label for="role">Available For <sup
                                                                    class="text-danger">*</sup></label>
                                                            <div class="input-group position-relative">

                                                                <div class="da-radio-grip">
                                                                    <fieldset [disabled]=true>
                                                                        <div class="form-check">
                                                                            <input class="form-check-input" type="radio"
                                                                                name="availableFor"
                                                                                formControlName="availableFor"
                                                                                id="inlineRadio1"
                                                                                [value]="roleAvailableFor.employee">
                                                                            <label class="form-check-label"
                                                                                for="inlineRadio1">Employee</label>
                                                                        </div>
                                                                        <div class="form-check ">
                                                                            <input class="form-check-input" type="radio"
                                                                                name="availableFor"
                                                                                formControlName="availableFor"
                                                                                id="inlineRadio2"
                                                                                [value]="roleAvailableFor.clientContact">
                                                                            <label class="form-check-label"
                                                                                for="inlineRadio2">Client
                                                                                Contact</label>
                                                                        </div>
                                                                        <div class="form-check ">
                                                                            <input class="form-check-input" type="radio"
                                                                                name="availableFor"
                                                                                formControlName="availableFor"
                                                                                id="inlineRadio3"
                                                                                [value]="roleAvailableFor.vendor">
                                                                            <label class="form-check-label"
                                                                                for="inlineRadio3">Vendor</label>
                                                                        </div>
                                                                    </fieldset>
                                                                </div>



                                                            </div>


                                                        </div>
                                                    </div>
                                                    <div class="col-md-12">
                                                        <div class="form-group has-search">
                                                            <label for="website">Access Groups<sup
                                                                    class="text-danger">*</sup></label>

                                                            <div class="input-group position-relative">
                                                                <div class="da-radio-grip" formArrayName="accessGroups">
                                                                    <div class="form-check mt-2"
                                                                        *ngFor="let accessGroup of accessGroupFormArray.controls; let i = index"
                                                                        [formGroupName]="i">
                                                                        <input class="form-check-input" type="checkbox"
                                                                            formControlName="isSelected"
                                                                            id="chkPrevillage{{i}}">
                                                                        <label class="form-check-label"
                                                                            for="chkPrevillage{{i}}">
                                                                            {{accessGroup.value.name}}
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div *ngIf="submitted && form.controls.accessGroups.errors">
                                                                <div *ngIf="submitted && form.controls.accessGroups.errors.required"
                                                                    class="text-danger">
                                                                    Please select atleast one access group
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-12">
                                                        <div class="form-group has-search">
                                                            <label for="billemail">Default Group<sup
                                                                    class="text-danger">*</sup></label>
                                                            <div class="input-group position-relative">
                                                                <select class="form-select"
                                                                    formControlName="defaultGroup">
                                                                    <option disabled>Select</option>
                                                                    <option value="{{item.id}}"
                                                                        *ngFor="let item of selectedAccessGroups; trackBy: trackByFn">
                                                                        {{item.value}}
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div *ngIf="submitted && form.controls.defaultGroup.errors">
                                                                <div *ngIf="submitted && form.controls.defaultGroup.errors.required"
                                                                    class="text-danger">
                                                                    Please select default group
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="width:100%" formArrayName="permissions">
                                <div *ngFor="let permission of permissionFormArray.controls; let i = index"
                                    [formGroupName]="i">
                                    <div class="col-md-12" id="{{permission.value.id}}">
                                        <div class="card">
                                            <div class="card-header">
                                                <div class="form-check">
                                                    <input class="form-check-input" type="checkbox"
                                                        [checked]="permission.value.isSelected"
                                                        id="{{permission.value.id}}"
                                                        (change)="selectAll($event.target.checked, permission.value.id)">
                                                    {{permission.value.permissionCategory}}
                                                </div>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-sm-12 mb-2"
                                                        *ngFor="let item of permission.value.permissions">
                                                        <div class="form-check">
                                                            <input class="form-check-input" type="checkbox" value=""
                                                                id="assigngrp_{{item.permission}}"
                                                                (change)="onPermissionChange(item, permission.value.id)"
                                                                [checked]="item.isSelected">
                                                            <label class="form-check-label"
                                                                for="assigngrp_{{item.permission}}">
                                                                {{item.name}}
                                                            </label> &nbsp;
                                                            <i class="fas fa-info-circle"
                                                                ngbTooltip="{{item.description}}" placement="right"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="form-group w-100">
                                <div class="col-lg-12 text-right">
                                    <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn"
                                        type="button" routerLink="/setting/role"> Cancel</button>
                                    <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2"
                                        type="submit">Update User
                                        Role <span class="fa fa-arrow-right"></span></button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <div class="da-scroll-top shadow " (click)="scrollUp()">
        <i class="fas fa-chevron-up"></i>
    </div>
    <!--     
    <hr>
    
    <pre>{{ closeResult }}</pre> -->


</main>