import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { RxwebValidators } from '@rxweb/reactive-form-validators';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { ActivityStatusTypeDetails } from 'src/app/helpers/constants/settings/reiew-type-detail/activityStatusType.details';
import { CalendarTypeDetails } from 'src/app/helpers/constants/settings/reiew-type-detail/calendar.type.details';
import { ReviewTypeDetails } from 'src/app/helpers/constants/settings/reiew-type-detail/review.type.detail';
import { CustomFieldCaseCategories } from 'src/app/helpers/enums/settings/customField/CustomFieldCaseCategories';
import { ReviewType } from 'src/app/helpers/enums/settings/review-type/reviewType';
import { AddCaseReviewModel } from 'src/app/models/case-review/addCaseReviewModel';
import { EditCaseReviewModel } from 'src/app/models/case-review/editCaseReviewModel';
import { CaseStatusViewModel } from 'src/app/models/case-status/caseStatusViewModel';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { CaseReviewService } from 'src/app/services/case.review.service';
import { CasestatusService } from 'src/app/services/casestatus.service';
import { CasetypeService } from 'src/app/services/casetype.service';
import { CustomfieldService } from 'src/app/services/customfield.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';

@Component({
  selector: 'app-edit-case-review',
  templateUrl: './edit-case-review.component.html',
  styleUrls: ['./edit-case-review.component.css']
})
export class EditCaseReviewComponent implements OnInit {

  @BlockUI('container-blockui-new-case-review') blockUI: NgBlockUI;
  accessGroupSelectListItem: SelectListItem<number>[] = [];
  caseStatusSelectListItem: CaseStatusViewModel[] = [];
  reviewsTypes: { id: number, name: string }[] = [];
  calendarListItem: { id: number, name: string }[] = [];
  activityStatusListItem: { id: number, name: string }[] = [];
  model: EditCaseReviewModel;
  customReview: any;
  form: FormGroup;
  submitted = false;
  constructor(
    private formBuilder: FormBuilder,
    private caseReviewService: CaseReviewService,
    private toasterService: ToasterService,
    private accessGroupService: AccessGroupService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private caseTypeService: CasetypeService,
    private caseStatusService: CasestatusService,
    private customFieldService: CustomfieldService
  ) {
    this.model = new EditCaseReviewModel();
    this.route.params.subscribe((data) => {
      this.model.id = Number(data.id);
      });
    ReviewTypeDetails.forEach((key, value) => this.reviewsTypes.push({ id: value, name: key }));
    CalendarTypeDetails.forEach((key, value) => this.calendarListItem.push({ id: value, name: key }));
    ActivityStatusTypeDetails.forEach((key, value) => this.activityStatusListItem.push({ id: value, name: key }));
  }

// tslint:disable-next-line: typedef
get reviewTypes() {
  return ReviewType;
}
  get caseTypesToFormArray(): FormArray {
    return this.form.controls.case_types as FormArray;
  }
  get formArr() {
    return this.form.get('customReviewFields') as FormArray;
  }
  addNewRows(): void {
    this.formArr.push(this.initRows());
  }

  deleteRow(index: number): void {
    this.formArr.removeAt(index);
  }
  initRows() {
    return this.formBuilder.group({
      id: 0,
      isDateEnabled: [false],
      customReviewId: [this.customReview.length > 0 ? this.customReview[0].id : '', [RxwebValidators.unique(), Validators.required]],
    });
  }
  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required), //
      access_grp: new FormControl(null, Validators.required), //
      case_status: new FormControl(null, Validators.required),
      review_type: new FormControl(null, Validators.required),
      description: new FormControl(), //
      calendarId: new FormControl(1), //
      activityStatusId: new FormControl(1), //
      customReviewFields: this.formBuilder.array([]),
      case_types: new FormArray([], minSelectedCheckboxes()), //
    });
    this.loadData();
    this.loadReviewCustomFields();
  }
  loadReviewCustomFields(): void {
    this.customFieldService
      .getByUserForId(CustomFieldCaseCategories.Reviews)
      .subscribe(
        (data) => {
          this.customReview = data;
        },
        (error: any) => {
          this.toasterService.ProcessErrorResponse(error);
        }
      );
  }
  private loadData(): void {
    this.blockUI.start();
    forkJoin([
      this.caseTypeService.get(),
      this.accessGroupService.getAccessGroups(),
      this.caseStatusService.getAll(),
      this.caseReviewService.get(this.model.id)
    ]).subscribe(
      ([casetypeData, accessGroupsData, caseStatusData, caseReviewData]) => {
        // loading case types
        const ct: FormArray = this.caseTypesToFormArray;
        ct.clear();
        casetypeData.forEach((d) => {
          ct.push(
            this.formBuilder.group({
              id: [d.id],
              name: [d.name],
              isSelected: [caseReviewData.caseTypeIds.includes(d.id)],
            })
          );
        });
          // casetypecustomreviewfield start
        const ctcc: FormArray = this.formArr;
        ctcc.clear();
        caseReviewData.caseReviewCustomFields.forEach((d) => {
           ctcc.push(
             this.formBuilder.group({
              id: d.id,
              customReviewId: [Number(d.customFieldId), [RxwebValidators.unique(), Validators.required]],
              caseReviewId: this.model.id,
              isDateEnabled: d.isDateEnabled
             })
           );
         });
       // casetypecustomreviewfield end
        // loading access groups
        this.accessGroupSelectListItem = accessGroupsData;
         // loading case status

        this.caseStatusSelectListItem = caseStatusData;

         // load form data
        this.form.controls.access_grp.setValue(caseReviewData.accessGroupId);
        this.form.controls.review_type.setValue(caseReviewData.reviewTypeId);
        this.form.controls.case_status.setValue(caseReviewData.caseStatusId);
        this.form.controls.name.setValue(caseReviewData.name);
        this.form.controls.description.setValue(caseReviewData.description);
        if (caseReviewData.calendarId){
        this.form.controls.calendarId.setValue(caseReviewData.calendarId);
        }
        if (caseReviewData.activityStatusId){
        this.form.controls.activityStatusId.setValue(caseReviewData.activityStatusId);
        }
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
        this.onCancel();
        this.blockUI.stop();
      },
      () => {
        this.blockUI.stop();
      }
    );
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    const controls = this.form.controls;
    this.model.caseReviewCustomFields = [];
    this.model.name = controls.name.value;
    this.model.description = controls.description.value;
    this.model.accessGroupId = Number(controls.access_grp.value);
    this.model.caseStatusId = Number(controls.case_status.value);
    this.model.reviewTypeId = Number(controls.review_type.value);
    this.model.calendarId = null;
    this.model.activityStatusId = null;
    if (Number(controls.review_type.value) === ReviewType.AutoGenerateOnePerCaseAssignment ||
      Number(controls.review_type.value) === ReviewType.AutoGenerateOnePerTaskOrAssigment ){
       this.model.calendarId = Number(controls.calendarId.value);
      }
    if (Number(controls.review_type.value) === ReviewType.AutoGenerateOnePerTaskOrAssigment ){
         this.model.activityStatusId = Number(controls.activityStatusId.value);
        }
    this.model.caseTypeIds = this.form.value.case_types
    .filter((x) => x.isSelected)
    .map((x) => x.id);

    controls.customReviewFields.value.map((x) => {
      this.model.caseReviewCustomFields.push({
        id: x.id,
        caseReviewId: this.model.id,
        customFieldId: Number(x.customReviewId),
        isDateEnabled: x.isDateEnabled
      });
    });
    this.caseReviewService.put(this.model).subscribe(
      (resposne) => {
        setTimeout(() => {
          this.onCancel();
        }, 10);
        setTimeout(() => {
          this.toasterService.success('Case review updated successfully');
          this.onCancel();
        }, 300);
        this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }

  onCancel(): void {
    this.router.navigate(['/setting/cases/case-review-list']);
  }

  openCustomFieldPopup(): void {
    this.modalService
      .openNewCustomFieldPopup(CustomFieldCaseCategories.Reviews)
      .subscribe(
        (data: any) => {
          this.loadReviewCustomFields();
        },
        (error: any) => {
          this.toasterService.ProcessErrorResponse(error);
        }
      );
  }
}
