import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription, forkJoin } from 'rxjs';
import { CustomFieldCaseCategories } from 'src/app/helpers/enums/settings/customField/CustomFieldCaseCategories';
import { EditCaseUpdatePresetModel } from 'src/app/models/case-update-preset/editCaseUpdatePresetModel';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { CaseupdatepresetService } from 'src/app/services/caseupdatepreset.service';
import { CustomfieldService } from 'src/app/services/customfield.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-edit-case-update-preset',
  templateUrl: './edit-case-update-preset.component.html',
  styleUrls: ['./edit-case-update-preset.component.css']
})
export class EditCaseUpdatePresetComponent implements OnInit, OnDestroy {
  @BlockUI('container-blockui-edit-case-update') blockUI: NgBlockUI;
  accessGroupSelectListItem: SelectListItem<number>[] = [];
  model: EditCaseUpdatePresetModel;
  nameSubscription: Subscription;
  variableData: {key: string, value: string}[] = [];

  constructor(private accessGroupService: AccessGroupService, private customFieldService: CustomfieldService,
              private router: Router, private route: ActivatedRoute,
              private toasterService: ToasterService, private caseUpdatePresetService: CaseupdatepresetService) { 
                this.model = new EditCaseUpdatePresetModel();
                this.route.params.subscribe((data) => {
                  this.model.id = Number(data.id);
                  });
              }

  form: FormGroup;
  submitted = false;
  errors: any;

  onVariableChange(event): void {
    const controls = this.form.controls;
    const  value = event.target.value;
    if (value){
    const body = controls.body.value ? controls.body.value : '';
    controls.body.setValue(body + `{{${value}}}`);
    }
    // reset variable
    controls.variables.setValue('');
  }
  OnonCustomTitleClick(event): void{
    if (event.currentTarget.checked){
      this.form.controls.title.enable();
    }
    else{
      this.form.controls.title.disable();
    }
  }
  ngOnInit(): void {
    this.form = new FormGroup({
      access: new FormControl(),
      title: new FormControl({value: '', disabled: true}, Validators.required),
      name: new FormControl('', Validators.required),
      custom_title: new FormControl(),
      body: new FormControl('', Validators.required),
      variables: new FormControl(''),
      restrict_use: new FormControl(),
    });
    // this.form.controls.title.disable();
    this.loadData();
    // copy name into title if title checkbox is checked
    this.nameSubscription = this.form.controls.name.valueChanges.subscribe((data) => {
       if (!this.form.controls.custom_title.value){
       this.form.controls.title.setValue(data);
      }
    });
  }
  private loadData(): void {
    this.blockUI.start();
    forkJoin([
      this.accessGroupService.getAccessGroups(),
      this.caseUpdatePresetService.get(this.model.id),
      this.customFieldService.getByUserForId(CustomFieldCaseCategories.Case),
      this.customFieldService.getByUserForId(CustomFieldCaseCategories.Subject)
    ]).subscribe(
      ([accessGroupsData, caseUpdatePresetData, customFieldCase, customFieldSubject]) => {
        const element = [...customFieldCase, ...customFieldSubject];
        this.variableData = this.customFieldService.getVariables(element);
       // loading access groups
        this.accessGroupSelectListItem = accessGroupsData;
        this.form.controls.name.setValue(caseUpdatePresetData.name);
        this.form.controls.title.setValue(caseUpdatePresetData.title);
        this.form.controls.body.setValue(caseUpdatePresetData.body);
        this.form.controls.restrict_use
                .setValue(caseUpdatePresetData.restrictAccessGroupId ? caseUpdatePresetData.restrictAccessGroupId : '');
        this.form.controls.access.setValue(caseUpdatePresetData.accessGroupId ? caseUpdatePresetData.accessGroupId : '');
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
        this.onCancel();
        this.blockUI.stop();
      },
      () => {
        this.blockUI.stop();
      }
    );
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    const controls = this.form.controls;
    this.model.name = controls.name.value;
    this.model.body = controls.body.value;
    this.model.title = controls.title.value;
    if (!controls.custom_title.value && !controls.title.value){
    this.model.title = this.model.name;
    }
    this.model.accessGroupId = controls.access.value ? Number(controls.access.value) : null;
    this.model.restrictAccessGroupId = controls.restrict_use.value ? Number(controls.restrict_use.value) : null;

    this.caseUpdatePresetService.put(this.model).subscribe(
      (resposne) => {
        setTimeout(() => {
          this.onCancel();
        }, 10);
        setTimeout(() => {
          this.toasterService.success('Case update preset updated successfully');
          this.onCancel();
        }, 300);
        this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );
  }

  onCancel(): void {
    this.router.navigate(['/setting/cases/update-preset-list']);
  }
  ngOnDestroy(): void{
  this.nameSubscription.unsubscribe();
  }

}
