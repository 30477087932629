import { Component, OnInit, ChangeDetectorRef, ViewChild, Renderer2, OnDestroy, AfterViewInit } from '@angular/core';

import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';


import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';


import { Page } from 'src/app/helpers/enums/customize-column/page';
import { ModalService } from 'src/app/services/modal.service';
import { ClientContactService } from 'src/app/services/client.contact.service';
import { ClientContactFilterOptions, ClientContactSearchOptions } from 'src/app/helpers/enums/filters-and-searchs/client.contact.search.&.filter.options';
import { ClientContactDtColumnName } from 'src/app/helpers/constants/client-contacts/client.contact.column.name';
import { ADTSettings } from 'angular-datatables/src/models/settings';
import { ClientService } from 'src/app/services/client.service';
import { SalesPersonAssignModel } from 'src/app/models/client-contact/salesperson.assign.model';

@Component({
  selector: 'app-client-contacts',
  templateUrl: './client-contacts.component.html',
  styleUrls: ['./client-contacts.component.css']
})

export class ClientContactsComponent implements OnInit, AfterViewInit {

  constructor(
    private clientContactService: ClientContactService,
    private tosterService: ToasterService,
    private renderer: Renderer2,
    private router: Router,
    private modalService: ModalService,
    private clientService: ClientService) { }

  // for filters
  formodel: string;
  frm: FormGroup;
  filterOptions: any;

  @BlockUI('container-blockui-manage') blockUI: NgBlockUI;
  @ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
  dtOptions: ADTSettings = {};
  dtInstance: DataTables.Api;
  rowIndex = 0;
  private searchBy?: ClientContactSearchOptions;
  private searchFor: string;
  filterBy?: ClientContactFilterOptions = null;
  filterByValue?: string = null;
  filterInRole?: string = null;
  visibleColumns = [];
  isDtVisible = false;
  isDtInitialized = false;
  permissionType = Permission;
  hasPermissionType = HasPermission;
  clientId: number;
  users: any = [];
  isBulkActionDisabled = true;
  clientContactFilterOptions = ClientContactFilterOptions;

  // capture filter open and close events
  filterNavStatus = false;

  closeFilterEvent(event: any): void {
    this.filterNavStatus = event;
  }

  ngOnInit(): void {
    this.initDataTable();
  }

  ngAfterViewInit(): void {
    this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
      this.dtInstance = dtInstance;
      const header = dtInstance.columns().header();
      $(header).find('[action-type="select-unSelect-all"]')
        .off('change').on('change', (e: any) => {
          this.onSelectAllRow(e.target.checked);
        });
    });
  }

  private initDataTable(): void {
    const self = this;
    this.dtOptions = {
      dom: '<"top">rt<"bottom"lip><"clear">',
      serverSide: true,
      destroy: true,
      processing: true,
      language: {
        loadingRecords: '&nbsp;',
        processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
        paginate: {
          first: '<i class="fa fa-angle-double-left">',
          last: '<i class="fa fa-angle-double-right">',
          previous: '<i class="fa fa-angle-left">',
          next: '<i class="fa fa-angle-right">'
        }
      },
      displayStart: 0,
      paging: true,
      pagingType: 'full_numbers',
      pageLength: environment.paging.defaultPageSize,
      lengthMenu: environment.paging.lengthMenu,
      order: [[1, 'asc']],
      autoWidth: false,
      ajax: (dataTablesParameters: any, callback) => {
        self.blockUI.start();
        dataTablesParameters.filterBy = self.filterBy;
        dataTablesParameters.filterByValue = self.filterByValue;
        dataTablesParameters.filterInRole = self.filterInRole;
        dataTablesParameters.searchBy = self.searchBy;
        dataTablesParameters.searchFor = self.searchFor;
        dataTablesParameters.isDtInitialized = self.isDtInitialized;
        self.clientContactService.query(dataTablesParameters)
          .subscribe(resp => {
            self.visibleColumns = resp.visibleColumns ?? [];
            self.dtInstance.page.len(resp.pageLength);
            self.isDtInitialized = true;
            // if (this.visibleColumns != null && this.visibleColumns.length > 0) {
            self.showColumns();
            // }
            callback({
              recordsTotal: resp.recordsTotal,
              recordsFiltered: resp.recordsFiltered,
              data: resp.data
            });
            self.blockUI.stop();
          }, error => {
            self.tosterService.ProcessErrorResponse(error);
            self.blockUI.stop();
          });
      },
      columns: [
        {
          data: null,
          title: `<input type="checkbox" action-type="select-unSelect-all"/>`,
          name: 'select',
          orderable: false,
          render: (data, type, row) => {
            // const disabled = '';
            return `<input type='checkbox' value = '${row.id}' action-type= "select-row" />`;
          }
        },
        {
          data: ClientContactDtColumnName.clientContactName,
          name: ClientContactDtColumnName.clientContactName.toLowerCase(),
          title: 'Name',
          render: (data, type, row) => {
            const src = row.profileImgUrl ?? '/assets/img/avatars/user.svg';
            const html = HasPermission.validate([Permission.ViewClientContacts])
              && this.filterBy !== ClientContactFilterOptions.DeletedContacts ?
              ` <a class='link m-r-10' action-type='detail'><img src="${src}"  onerror="" class='${row.profileImgUrl ? 'shimmer' : ''} avatar img-fluid rounded-circle mr-1'> ${row.name}</a>` : `<span>${row.name}</span>`;
            return html;
          }
        },
        {
          data: ClientContactDtColumnName.title,
          name: ClientContactDtColumnName.title.toLowerCase(),

          title: 'Title'
        },
        {
          data: ClientContactDtColumnName.clientName,
          name: ClientContactDtColumnName.clientName.toLowerCase(),

          title: 'Client'
        },
        {
          data: ClientContactDtColumnName.location,
          name: ClientContactDtColumnName.location.toLowerCase(),

          title: 'Location'
        },
        {
          data: ClientContactDtColumnName.email,
          name: ClientContactDtColumnName.email.toLowerCase(),

          title: 'Email'
        },
        {
          data: ClientContactDtColumnName.role,
          name: ClientContactDtColumnName.role.toLowerCase(),
          orderable: false,

          title: 'Role'
        },
        // {
        //   data: ClientContactDtColumnName.manager,
        //   name: ClientContactDtColumnName.manager.toLowerCase(),
        //   orderable: false,

        //   title: 'Manager'
        // },
        {
          data: ClientContactDtColumnName.accountCode,
          name: ClientContactDtColumnName.accountCode.toLowerCase(),

          title: 'Account Code'
        },
        // {
        //   data: ClientContactDtColumnName.contactNumber,
        //   name: ClientContactDtColumnName.contactNumber.toLowerCase(),
        //   title: 'Contact Number',
        //   orderable: false,
        // },
        // {
        //   data: ClientContactDtColumnName.homephone,
        //   name: ClientContactDtColumnName.homephone.toLowerCase(),
        //   title: 'Home Phone',
        //   orderable: false,
        // },
        {
          data: ClientContactDtColumnName.fax,
          name: ClientContactDtColumnName.fax.toLowerCase(),
          title: 'Fax',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.fulladdress,
          name: ClientContactDtColumnName.fulladdress.toLowerCase(),
          title: 'Full Address',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.address1,
          name: ClientContactDtColumnName.address1.toLowerCase(),
          title: 'Address Line 1',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.address2,
          name: ClientContactDtColumnName.address2.toLowerCase(),
          title: 'Address Line 2',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.address3,
          name: ClientContactDtColumnName.address3.toLowerCase(),
          title: 'Address Line 3',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.state,
          name: ClientContactDtColumnName.state.toLowerCase(),
          title: 'State',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.city,
          name: ClientContactDtColumnName.city.toLowerCase(),
          title: 'City',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.zip,
          name: ClientContactDtColumnName.zip.toLowerCase(),
          title: 'Zip',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.country,
          name: ClientContactDtColumnName.country.toLowerCase(),
          title: 'Country',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.dateOfBirth,
          name: ClientContactDtColumnName.dateOfBirth.toLowerCase(),
          title: 'Birthday',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.created,
          name: ClientContactDtColumnName.created.toLowerCase(),
          orderable: false,
          title: 'Avg. Rating'
        },
        {
          data: ClientContactDtColumnName.lastlogin,
          name: ClientContactDtColumnName.lastlogin.toLowerCase(),
          title: 'Last Login',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.isActiveLogin,
          name: ClientContactDtColumnName.isActiveLogin.toLowerCase(),
          title: 'Active Login',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.lastPasswordChange,
          name: ClientContactDtColumnName.lastPasswordChange.toLowerCase(),
          title: 'Last Password Change',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.salesPerson,
          name: ClientContactDtColumnName.salesPerson.toLowerCase(),
          title: 'Sales Person',
          orderable: false,
        },
        {
          data: ClientContactDtColumnName.caseManger,
          name: ClientContactDtColumnName.caseManger.toLowerCase(),
          title: 'Case Manager',
          orderable: false,
        },
        {
          data: null,
          title: 'Action ',
          name: 'action',
          orderable: false,
          render: (data, type, row) => {
            const editHtml = HasPermission.validate([Permission.EditClientContacts])
              && this.filterBy !== ClientContactFilterOptions.DeletedContacts ?
              `<div class='fa-edit'>
                    <button  class='btn del-btn btn-lg btn-table'>
                    <i  action-type="edit" class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
                    </button>` : '';

            const deleteHtml = HasPermission.validate([Permission.DeleteClientContacts]) ?
              `<button  class='btn del-btn btn-lg btn-table'>
                    <i action-type="${this.filterBy !== ClientContactFilterOptions.DeletedContacts ? 'delete' : 'un-delete'}"
                    class='align-middle rounded-circle bg-delete bg-delete-btn fas ${this.filterBy !== ClientContactFilterOptions.DeletedContacts ? 'fa-trash-alt' : 'fa-trash-restore-alt'}
                     fa-1x p-2 text-danger'></i>
                    </button>
                    </div>` : '';

            return `${editHtml} ${deleteHtml}`;
          }
        },
      ],
      rowCallback: (row, data: any) => {
        const _row = $(row);
        _row.find('[action-type="detail"]')
          .off('click')
          .on('click', () => {
            this.linkDetail(data.id);
          });

        _row.find('[action-type="edit"]')
          .off('click')
          .on('click', () => {
            this.edit(data.id, data.clientId, data.clientName);
          });
        _row.find('[action-type="delete"]')
          .off('click')
          .on('click', () => {
            self.delete([data.id]);
          });
        _row.find('[action-type="un-delete"]')
          .off('click')
          .on('click', () => {
            self.unDelete([data.id]);
          });
        _row.find('[action-type = "select-row"]')
          .off('click').on('click', (e: any) => {
            this.onSelectedOrUnselectRow(e);
          });
      },
      // Use this attribute to enable the responsive extension
      responsive: true,
    };
  }

  onSelectedOrUnselectRow(event: any): void {
    if (event.target.checked) {
      this.renderer.addClass(event.target, 'checked');
    } else {
      this.renderer.removeClass(event.target, 'checked');
    }
    this.toggleControls();
  }

  private toggleControls(): void {
    this.setSelectAllCheckbox();
    this.enableOrDisableDeleteButton();
  }

  private setSelectAllCheckbox(): void {
    const rows = this.dtInstance.rows().nodes();
    if (rows !== null) {
      const header = this.dtInstance.columns().header();
      const selectAllElement = $(header).find('[action-type="select-unSelect-all"]')[0] as any;
      const selelectedRows = [...rows.$('[action-type= "select-row"]')];
      selectAllElement.checked = selectAllElement != null && selelectedRows.length > 0
        && selelectedRows.every(row => row.classList.contains('checked'));
    }
  }

  openCustomizedListModal(): void {
    this.modalService.openModalForConfiguringCustomizedList(Page.ClientContactsList).subscribe(() => {
      this.isDtVisible = false;
      this.isDtInitialized = false;
      this.dtInstance.ajax.reload(null, false);
      this.tosterService.success('Customized list has been updated successfully');
    });
  }

  private showColumns(): void {
    const visibleColumnsSelector = this.visibleColumns.map(item => `${item.toLowerCase()}:name`)
      .concat(['action:name', 'select:name']);
    const visibleIndexes = this.dtInstance.columns(visibleColumnsSelector).indexes().toArray();
    this.dtInstance.columns().every(function () {
      const colIndex = this.index();
      this.visible(visibleIndexes.includes(colIndex));
    });
    this.isDtVisible = true;
  }

  // for filters
  applyFilter(filterData: any): void {
    const filterIdArr = filterData.filter?.split('_');
    if (filterIdArr && filterIdArr.length > 0) {
      this.filterBy = Number(filterIdArr[0]);
      if (filterIdArr.length > 1) {
        this.filterByValue = filterIdArr[1];
      }
    }
    this.filterInRole = filterData.filterIn === '-1' ? null : filterData.filterIn;
    this.searchBy = filterData.searchBy;
    this.searchFor = filterData.searchFor;
    this.reload();
  }

  reload(): void {
    this.dtInstance.ajax.reload();
    this.resetSelectAllCheckBox();
  }

  onResetFilters(): void {
    this.filterBy = null;
    this.filterByValue = null;
    this.filterInRole = null;
    this.searchBy = null;
    this.searchFor = '';
    this.reload();
  }

  openAssignSalesPersonModal(): void {
    const selectedContactsCount = this.getSelectedContactsIds().length;
    this.modalService.openSalesPersonAssignModel(selectedContactsCount).subscribe((salesPersonId) => {
      const model = new SalesPersonAssignModel();
      model.salesPersonId = salesPersonId;
      model.contactIds = this.getSelectedContactsIds();
      this.blockUI.start();
      this.clientContactService.assignContactSalesperson(model).subscribe(() => {
        this.modalService.closeAllModals();
        this.dtInstance.ajax.reload(null, false);
        this.resetSelectAllCheckBox();
        this.tosterService.success('SalesPerson has been assigned successfully to selected contacts');
        this.blockUI.stop();
      }, error => {
        this.tosterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
    });
  }

  delete(contacts: string[]): void {
    if (!confirm('Are you sure you want to delete selected contact(s)?')) {
      return;
    }
    this.blockUI.start();
    this.clientContactService.delete(contacts).subscribe(() => {
      this.reload();
      this.tosterService.success('Contact(s) deleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  unDelete(contacts: string[]): void {
    if (!confirm('Are you sure you want to undelete selected contact(s)?')) {
      return;
    }
    this.blockUI.start();
    this.clientContactService.undelete(contacts).subscribe(() => {
      this.reload();
      this.tosterService.success('Contact(s) undeleted successfully.');
      this.blockUI.stop();
    }, error => {
      this.tosterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  onSelectAllRow(isChecked: boolean): void {
    const rows = this.dtInstance.rows().nodes();
    const checkBoxes = [...rows.$('[action-type= "select-row"]')];
    if (isChecked) {
      checkBoxes.forEach(row => {
        this.renderer.addClass(row, 'checked');
        (row as HTMLInputElement).checked = true;
      });
    } else {
      checkBoxes.forEach(row => {
        this.renderer.removeClass(row, 'checked');
        (row as HTMLInputElement).checked = false;
      });
    }
    this.enableOrDisableDeleteButton();
  }

  private enableOrDisableDeleteButton(): void {
    const rows = this.dtInstance.rows().nodes();
    this.isBulkActionDisabled = [...rows.$('.checked')].length === 0;
  }

  edit(id: string, clientId, clientName): void {
    this.clientContactService.setReturnUrl(this.router.url);
    this.clientService.clientDetailSubject$.next({ id: clientId, name: clientName });
    this.router.navigate(['/client/contact/edit/', id]);
  }


  linkDetail(id: string): void {
    this.router.navigate(['/client/contact-show', id, 'overview']);
  }

  onBulkDelete(): void {
    this.delete(this.getSelectedContactsIds());
  }

  onBulkUnDelete(): void {
    this.unDelete(this.getSelectedContactsIds());
  }

  OnBulkAssignSalesperson(): void {
    this.openAssignSalesPersonModal();
  }

  private getSelectedContactsIds(): any[] {
    const contacts = [];
    const rows = this.dtInstance.rows().nodes();
    [...rows.$('.checked')].forEach(item => contacts.push($(item).val()));
    return contacts;
  }

  private resetSelectAllCheckBox(): void {
    const header = this.dtInstance.columns().header();
    const selectAllElement = $(header).find('[action-type="select-unSelect-all"]')[0] as any;
    if (selectAllElement && selectAllElement.checked) {
      selectAllElement.checked = false;
    }
  }

  openNewContactPopup(): void {
    this.modalService.openNewContactPopup();
  }

}
