export const StaffBreadCrumb = {
    // Staff
    STAFF: 'Staff',
    Add_Staff: 'Add User',
    EDIT_STAFF: 'Edit User',
    STAFF_DETAILS: 'Staff Details',
    STAFF_OVERVIEW: 'Overview',
    STAFF_PERMISSION: 'Permission',
    STAFF_LICENSE: 'Licenses',
    STAFF_PRICELIST: 'Price List',
    STAFF_AGENDA: 'Agenda',
    STAFF_NOTIFICATION: 'Notification',
    STAFF_POLICIES: 'Policies',
    STAFF_NOTES: 'Staff Notes',
    STAFF_HISTORY: 'History',

    // Staff License
    NEW_LICENSE: 'New License',
    EDIT_LICENSE: 'Edit License',
    LICENSE_DETAILS: 'Staff License Details',
    LICENSE_OVERVIEW: 'Staff License Overview',
    LICENSE_HISTORY: 'Staff License History',
    NEW_NOTE: 'New Note',
    EDIT_NOTE: 'Edit Note'
} as const;
