<app-filter-results
  [filterBy]="filterBy"
  [searchBy]="searchBy"
  [isFilterInAvailable]="false"
  [isSearchingAvailable]="true"
  (onApplyFilter)="applyFilter($event)"
  (resetFilters)="onResetFilters()"
  [filterOpen]="filterStatusParent"
  (closeNavEvent)="closeEvent($event)"
>
</app-filter-results>
