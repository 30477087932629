<main class="content py-0">

    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-12   pt-7">

                <div class="row">
                    <div class="col-md-12">
                        <app-breadcrumb></app-breadcrumb>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto d-none d-sm-block mb-3">
                        <h3 class="page-title"><strong>Edit Case Assignment Type
                            </strong> </h3>
                    </div>
                </div>
                <div class="row mx-0" *blockUI="'container-blockui-new-access-group'">
                    <div class="col-12 px-0 mb-5">
                        <form class="new-case-form" name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card flex-fill w-100 mb-2">
                                        <div class="card-header rounded p-4 mob-px-4">
                                            <div class="row">
                                                <div class="col-md-6 mb-2">
                                                    <div class="form-group has-search">
                                                        <label id="Label-7x1"> Name ({{defaultName}})<sup
                                                                class="clsStric">*</sup> <i
                                                                class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                data-toggle="tooltip" data-placement="top"
                                                                placement="top"
                                                                ngbTooltip="The label or term that best represents this assignment type. Please note, some parts of the system will always refer to the default name."></i></label>
                                                        <input type="text" class="form-control" id="Label-7x1"
                                                            formControlName="name">
                                                        <div *ngIf="submitted && form.controls.name.errors">
                                                            <div *ngIf="submitted && form.controls.name.errors.required"
                                                                class="text-danger">
                                                                Name is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>
                                            <hr />
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group has-search">
                                                        <!-- <label id="track">Required Validation?</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"   placement="top" ngbTooltip="If enabled, case updates created with this access group will require manual validation. If disabled, they will be automatically validated."></i> -->
                                                        <div>
                                                            <div class="custom-control custom-checkbox mb-3">
                                                                <label class="custom-control-label"
                                                                    for="isSetAsDefaultRegion">Update/ File
                                                                    Notices
                                                                
                                                                    <i
                                                                    class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                    data-toggle="tooltip" data-placement="top"
                                                                    placement="top"
                                                                    ngbTooltip="
                                                                    If enabled, the system will automatically send a 'New Case Update Notice' each time a new case update is posted, and a 'New File Upload Notice' each time a new file is uploaded.
                                                                    Note 1: Notices for invalid case updates (i.e. not auto-validated) will not be sent to users without permission to 'View Invalid Updates'.
                                                                    Note 2: Notices for case updates and/or uploaded files in access groups that are not included in the user's role will not be sent.
                                                                    Note 3: If the 'New Case Update Notice' and/or 'New File Upload Notice' email templates are disabled, the respective email notice will not be sent, regardless of this setting."></i>
                                                                </label>
                                                                <input type="checkbox" id="isSetAsDefaultRegion"
                                                                    class="custom-control-input-checkbox"
                                                                    formControlName="isUpdateNoticesEnabled"
                                                                    name="isUpdateNoticesEnabled">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group has-search">
                                                        <!-- <label id="track">Required Validation?</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"   placement="top" ngbTooltip="If enabled, case updates created with this access group will require manual validation. If disabled, they will be automatically validated."></i> -->
                                                        <div>
                                                            <div class="custom-control custom-checkbox mb-3">
                                                                <label class="custom-control-label"
                                                                    for="isSetAsDefaultRegion">Assignment
                                                                    Notices
                                                                    <i
                                                                    class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                    data-toggle="tooltip" data-placement="top"
                                                                    placement="top"
                                                                    ngbTooltip="If enabled, the system will default to send 'Case Assignment Notice' emails to users who are assigned to cases as this assignment type.
                                                                    Note: If the 'Case Assignment Notice' email template is disabled, no notifications will be sent, regardless of this setting."></i>
                                                                </label>
                                                                <input type="checkbox" id="isAssignmentNoticesEnabled"
                                                                    class="custom-control-input-checkbox"
                                                                    formControlName="isAssignmentNoticesEnabled"
                                                                    name="isAssignmentNoticesEnabled">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group has-search">
                                                        <!-- <label id="track">Required Validation?</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"   placement="top" ngbTooltip="If enabled, case updates created with this access group will require manual validation. If disabled, they will be automatically validated."></i> -->
                                                        <div>
                                                            <div class="custom-control custom-checkbox mb-3">
                                                                <label class="custom-control-label"
                                                                    for="isSetAsDefaultRegion">Confirm Assignments
                                                                    <i
                                                                    class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                    data-toggle="tooltip" data-placement="top"
                                                                    placement="top"
                                                                    ngbTooltip="If enabled, users assigned as this type will be asked to confirm the receipt of each assignment they receive via the 'Case Assignment Notice'.
                                                                    Note: If 'Assignment Notices' is unchecked, or the 'Case Assignment Notice' email template is disabled, no notifications will be sent."></i>
                                                                </label>
                                                                <input type="checkbox"
                                                                    id="isConfirmAssignmentNoticesEnabled"
                                                                    class="custom-control-input-checkbox"
                                                                    formControlName="isConfirmAssignmentNoticesEnabled"
                                                                    name="isConfirmAssignmentNoticesEnabled">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-6">
                                                    <div class="form-group has-search">
                                                        <!-- <label id="track">Required Validation?</label><i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"   placement="top" ngbTooltip="If enabled, case updates created with this access group will require manual validation. If disabled, they will be automatically validated."></i> -->
                                                        <div>
                                                            <div class="custom-control custom-checkbox mb-3">
                                                                <label class="custom-control-label"
                                                                    for="isSetAsDefaultRegion">Assign to
                                                                    Reopens
                                                                
                                                                    <i
                                                                    class="align-middle fas fa-info-circle ml-2 text-light-black"
                                                                    data-toggle="tooltip" data-placement="top"
                                                                    placement="top"
                                                                    ngbTooltip="If enabled, users assigned as this type will be automatically assigned to reopened cases if they were previously assigned to the last instance of that case series."></i>
                                                                </label>
                                                                <input type="checkbox" id="isAssignToReopenEnabled"
                                                                    class="custom-control-input-checkbox"
                                                                    formControlName="isAssignToReopenEnabled"
                                                                    name="isAssignToReopenEnabled">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group w-100">
                                    <div class="col-lg-12 text-right">
                                        <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn"
                                            type="button" routerLink="/setting/cases/assigntype">
                                            Cancel</button>
                                        <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2"
                                            type="submit">Save Assignment Type <span
                                                class="fa fa-arrow-right"></span></button>
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>



            </div>
        </div>
    </div>
</main>