import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoteFor } from 'src/app/helpers/enums/notes/noteFor';
import { StaffService } from 'src/app/services/staff.serrvice';

@Component({
  selector: 'app-new-staff-note',
  templateUrl: './new-staff-note.component.html',
  styleUrls: ['./new-staff-note.component.css']
})
export class NewStaffNoteComponent implements OnInit, OnDestroy {

  staffId: string;
  moduleId: number;
  staffSubscription: Subscription;
  breadCrumbInputs: { key: string, value: string[] }[] = [];

  constructor(private staffService: StaffService,
              private route: ActivatedRoute) {
    this.route.params.subscribe(params => this.staffId = params.id);
    this.moduleId = NoteFor.Staff;
    this.staffSubscription = this.staffService.staffDetai$.subscribe((data) => {
      if (data == null || data === undefined) {
        return;
      }
      this.breadCrumbInputs.push({ key: 'id', value: [data.id, data.name] });
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.staffSubscription.unsubscribe();
  }
}