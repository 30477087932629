import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';

import { AppUtils } from 'src/app/helpers';
import { CompanyLocationFilterOptionsDetails, CompanyLocationSearchOptionsDetails } from 'src/app/helpers/constants/filter-and-search/company.location.filter&search.options.detail';
import { clientListColumnDetails } from 'src/app/helpers/constants/staff/grid.column.detail';
import { CompanyLocationFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/company.location.search&filter.options';
import { FilterBy, FilterInputModel, FilterModel, SearchByModel } from 'src/app/models/shared/filters.model';
import { SearchModel } from 'src/app/models/shared/search.model';
import { StaffService } from 'src/app/services/staff.serrvice';


@Component({
  selector: 'app-company-location-filter',
  templateUrl: './company-location-filter.component.html',
  styleUrls: ['./company-location-filter.component.css']
})
export class CompanyLocationFilterComponent implements OnInit {

  filterBy: FilterInputModel[] = [];
  searchBy: SearchByModel[] = [];
  isFilterInVisible = true;
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onApplyFilter = new EventEmitter();

  @Output()
  resetFilters = new EventEmitter();


  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();


  constructor() {
  }

  ngOnInit(): void {
    this.loadFilterOptions();
  }

  private loadFilterOptions(): void {
    this.filterBy = [];
    this.searchBy = [];

    // filterby
    CompanyLocationFilterOptionsDetails.forEach((key, value) =>
    this.filterBy.push({ name: key, id: `${value}`, group: undefined, isSelected: value === CompanyLocationFilterOptions.None })
    );

    // search by
    CompanyLocationSearchOptionsDetails.forEach((x, y) => {
      this.searchBy.push({
        id: y,
        name: x
      });
    });
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onResetFilters(): void {
    this.loadFilterOptions();
    this.resetFilters.emit();
  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }

}
