<main class="content py-0">
 
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 col-lg-12 col-xl-12 col-xxl-12   pt-7">

                <div class="row">
                    <div class="col-md-12">
                        <app-breadcrumb></app-breadcrumb>
                    </div>
                </div>
                <div class="row">
                    <div class="col-auto d-none d-sm-block mb-3">
                        <h3 class="page-title"><strong>Edit Case Flag 
                        </strong> </h3>
                        
                    </div>
                </div>
                <div class="row mx-0" *blockUI="'container-blockui-new-case-flag'">
                    <div class="col-12 px-0 mb-5">
                        <form class="new-case-form" name="form" [formGroup]="form" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="card flex-fill w-100 mb-2">
                                        <div class="card-header rounded p-4 mob-px-4">
                                            <div class="row">
                                                <div class="col-md-6 mb-2">
                                                    <div class="form-group has-search">
                                                        <label id="Label-7x1"> Name<sup class="clsStric">*</sup></label>
                                                        <input type="text" class="form-control" id="Label-7x1" formControlName="name">
                                                        <div *ngIf="submitted && form.controls.name.errors">
                                                            <div *ngIf="submitted && form.controls.name.errors.required" class="text-danger">
                                                                    Name is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-md-6">
                                                    <div class="form-group has-search">
                                                        <label for="colors">Colors<sup class="clsStric">*</sup> <i class="align-middle fas fa-info-circle ml-2 text-light-black" placement="top" ngbTooltip="Choose a color that black text reads well on."></i></label>
                                                    
                                                        <input type="color" formControlName="colors" class="form-control mb-3  form-control-color" id="colors" name="colors" autocomplete="off">
                                                        <div *ngIf="submitted && form.controls.colors.errors">
                                                            <div *ngIf="submitted && form.controls.colors.errors.required" class="text-danger">
                                                                    Color is required
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                                <div class="col-md-6 mb-2">
                                                    <div class="form-group has-search">
                                                        <label id="Label-8x1">Description</label>
                                                        <textarea class="form-control p-3" name="updatePolicies" id="updatePolicies" rows="5" formControlName="description" placeholder=""></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr/>
                                            <div class="row">
                                                <div class="col-md-6 mb-2">
                                                    <label><b><sup class="clsStric">*</sup> Make this flag available on the following case types:</b></label>
                                                    <div class="form-check mt-2" formArrayName="case_types">
                                                        <div class="form-check mt-2"
                                                            *ngFor="let ct of caseTypesToFormArray.controls; let i = index"
                                                            [formGroupName]="i">
                                                            <input class="form-check-input" type="checkbox"
                                                                formControlName="isSelected" id="casetypes_{{i}}">
                                                            <label class="form-check-label" for="lbl_casetypes_{{i}}">
                                                                {{ct.value.name}}
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="submitted && form.controls.case_types.errors">
                                                        <div *ngIf="submitted && form.controls.case_types.errors.required"
                                                            class="text-danger">
                                                            Please select atleast one case type
                                                        </div>
                                                    </div> 
                                                </div>
                                               </div>
                                         
                                            <hr/>
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group w-100">
                                    <div class="col-lg-12 text-right">
                                        <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button" routerLink="/setting/cases/flag-list"> Cancel</button> 
                                        <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="submit">Save<span class="fa fa-arrow-right"></span></button> 
                                    </div>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
                
                </div>
         </div>
    </div>
</main>

