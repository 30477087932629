import { Injectable } from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";

@Injectable()
export class NgbDateFRParserFormatter extends NgbDateParserFormatter {
    parse(value: string): NgbDateStruct {

        if (value === null || value === undefined || value.length === 0) {
            return;
        }
        const date = value.split('/');

        if (date.length === 0) {
            return;
        }
        return { year: +date[2], month: +date[0], day: +date[1] }

    }

    format(date: NgbDateStruct): string {
        if (date === null || date === undefined) {
            return;
        }
        return `${date.month}/${date.day}/${date.year}`;
    }
}
