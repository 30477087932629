import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { mapTo, tap, catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AffiliateAddModel } from '../models/client/affiliate.add.model';
import { AffiliateResponseModel } from '../models/client/affiliate.response.model';
import { AssignSalespersonModel } from '../models/client/assign-salesperson';
import { ClientAddModel } from '../models/client/client.model';
import { ClientResponseModel } from '../models/client/clientResponse.model';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';
import { SelectListItem } from '../models/shared/select.list.item';

@Injectable()
export class ClientService {

    private loadAffiliatesSubject = new Subject<any>();
    public clientDetailSubject$ = new BehaviorSubject<{ id: number, name: string }>(null);
    public clientListDetail$ = this.clientDetailSubject$.asObservable();

    constructor(private http: HttpClient) {
        this.loadAffiliatesSubject = new Subject<any>();
    }

    public addClient(model: ClientAddModel): Observable<ClientResponseModel> {
      return this.http.post<any>(`${environment.apiBaseUrl}/client/addClient`, model)
            .pipe(
                map((user: ClientResponseModel) => {
                    return user;
                }),
                catchError(error => {
                    throw error;
                }));
    }

    public getUser() {
        return this.http.get(`${environment.apiBaseUrl}/staff/GetKeyValueList`);
    }

    public getClients(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/Client/GetClients`, dataTablesParameters, {});
    }

    public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/ClientContacts/query`, dataTablesParameters, {});
    }
    public getClient(id: number): Observable<ClientAddModel> {
        return this.http.get<ClientAddModel>(`${environment.apiBaseUrl}/client/GetClient/${id}`);
    }

    public editClient(model: ClientAddModel): Observable<ClientResponseModel> {
        return this.http.post<any>(`${environment.apiBaseUrl}/client/EditClient`, model)
            .pipe(
                map((user: ClientResponseModel) => {
                    return user;
                }),
                catchError(error => {
                    throw error;
                }));
    }

    public deleteClient(ids: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/client?ids=${ids.join('&ids=')}`);
    }

    public undeleteClient(ids: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/client/undelete?ids=${ids.join('&ids=')}`);
    }

    public getAffiliatesToAdd(id: number): Observable<SelectListItem<number>[]> {
        return this.http.get<SelectListItem<number>[]>(`${environment.apiBaseUrl}/client/getAffiliates/${id}`);
    }

    public addAffiliates(model: AffiliateAddModel): Observable<string> {
        return this.http.post<string>(`${environment.apiBaseUrl}/client/addAffiliates`, model, {});
    }

    public getSelectedAffiliates(clientId: any): Observable<AffiliateResponseModel[]> {
        return this.http.get<AffiliateResponseModel[]>(`${environment.apiBaseUrl}/client/getSelectedAffiliates/${clientId}`);
    }

    public assignSalesperson(model: AssignSalespersonModel): Observable<string> {
        return this.http.patch<string>(`${environment.apiBaseUrl}/client/AssignSalesperson`, model, {});
    }

    public notifyToLoadAffiliates(): void {
        this.loadAffiliatesSubject.next();
    }

    public listenToLoadAffiliates(): Observable<any> {
        return this.loadAffiliatesSubject.asObservable();
    }

    public deleteAffiliate(id: number): Observable<string> {
        return this.http.delete<string>(`${environment.apiBaseUrl}/client/deleteAffiliate?id=${id}`);
    }

    public getClientTags(text: string): Observable<Array<string>> {
        return this.http.get<Array<string>>(`${environment.apiBaseUrl}/client/get-tags/${text}`);
    }

    public getClientList(): Observable<SelectListItem<number>[]> {
        return this.http.get<SelectListItem<number>[]>(`${environment.apiBaseUrl}/client/get-client-list`);
    }

    public getClientSalespersonList(isDeleted = false): Observable<SelectListItem<number>[]> {
        return this.http.get<SelectListItem<number>[]>
            (`${environment.apiBaseUrl}/client/client-salesperson-list${isDeleted ? 'isDeleted=true' : ''}`);
    }
}
