<main class="content py-0">
    <div class="container-fluid p-0">
      <div class="row">
        <div class="col-md-12 pt-7">
          <div class="w-100 pt-4">
            <div class="row">
              <div class="col-md-12 px-0 pb-5">
                <div class="col-auto mt-n1">
                  <app-breadcrumb></app-breadcrumb>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-auto d-none d-sm-block mb-3">
                <h3 class="page-title">
                  <strong>Case Services  </strong>
                </h3>
              </div>


              <div class="col-auto ml-auto text-right mt-n1">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb bg-transparent p-0 mt-1 mb-0">
                      <li class="filter-item" *hasPermissions='[permissionType.CreateClients]'>
                          <button class="btn btn-lg bg-blue text-white" [routerLink]="['/setting/cases/new-service']">
                              <i class="align-middle fas fa-plus-circle"></i> New Case Service
                          </button>
                      </li>
                      <li class="filter-item">
                        <div class="btn-group dropleft dropleft-btn">
                            <div ngbDropdown class="d-inline-block">
                                <!-- <button class="btn btn-lg   btn btn-danger" id="dropdownBasic1">Delete</button> -->
                                <button *ngIf="filterBy !== caseServiceFilterOptions.DeletedCaseServices" class="btn btn-lg btn btn-danger" (click) = "onBulkDelete()"  [disabled]="isBulkActionDisabled" id="dropdownBasic1">Delete</button>  
                                <button *ngIf="filterBy === caseServiceFilterOptions.DeletedCaseServices" class="btn btn-lg btn btn-danger" (click) = "onBulkUnDelete()"  [disabled]="isBulkActionDisabled" id="dropdownBasic1">Undelete</button>  
                            </div>
                        </div>
                     </li>
                        <li class="filter-item px-0 filter-icon"   (click)="filterNavStatus = true">
                            <button class="btn btn-lg bg-blue text-white">
                                <i class="align-middle fas fa-filter"></i>
                            </button>
                        </li>
                    </ol>
                </nav>
            </div>
            <!-- Filter right sidenav -->
            <app-case-service-filter (onApplyFilter)="applyFilter($event)" 
                (resetFilters)="onResetFilters()"  [filterStatusParent]="filterNavStatus"  (closeBtnFilterStatus)="closeFilterEvent($event)"></app-case-service-filter>
            </div>

          
  
           
              <div class="row">
                <div class="col-md-12">
                  <table class="neu-table table table-striped neu-table w-100" datatable [dtOptions]="dtOptions" data-page="2" ></table>
                </div>
              </div>

              
  
             
              </div>
           
          </div>
        </div>
      </div>
  </main>
  