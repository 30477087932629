import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';

import { AppUtils } from 'src/app/helpers';
import { StaffLicenseOverviewModel } from 'src/app/models/staff-license/staff.license.overview.model';
import { StaffLicenseService } from 'src/app/services/staff.license.service';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-license-overview',
  templateUrl: './license-overview.component.html',
  styleUrls: ['./license-overview.component.css']
})
export class LicenseOverviewComponent implements OnInit, OnDestroy {
  model = new StaffLicenseOverviewModel();
  public staffName: string;

  get environment(): any {
    return environment;
  }

  @BlockUI() blockUI: NgBlockUI;
  navigationSubscription: Subscription;
  staffServiceSubscription: Subscription;
  @Output()
  setStaffName = new EventEmitter<string>();


  constructor(
    private staffLicenseService: StaffLicenseService,
    private toasterService: ToasterService,
    private router: Router,
    private route: ActivatedRoute,
    private appUtils: AppUtils) {
    this.route.parent.params.subscribe(params => this.model.id = params.licenseId);
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.loadOverview();
      }
    });


  }

  ngOnInit(): void {

  }

  private loadOverview(): void {
    this.blockUI.start();
    this.staffServiceSubscription = this.staffLicenseService.get(this.model.id)
      .subscribe((data: StaffLicenseOverviewModel) => {
        this.staffLicenseService.staffDetailSubject.next({
          licenseId: data.id, licenseName: data.name,
          staffId: data.staffId, staffName: data.staffName
        });
        this.model = data;
      }, error => {
        this.toasterService.ProcessErrorResponse(error);
        this.router.navigate([`/staff/${this.model.id}/license`]);
        this.blockUI.stop();
      }, () => {
        this.blockUI.stop();
      });
  }

  ngOnDestroy(): void {
    this.navigationSubscription.unsubscribe();
  }

  downloadAttachment(): void {
    this.blockUI.start();
    this.staffLicenseService.downloadAttachment(this.model.id, this.model.fileName)
      .subscribe((isSuccess: boolean) => {
        this.blockUI.stop();
      },
        error => {
          this.toasterService.ProcessErrorResponse(error);
          this.blockUI.stop();
        });
  }


}
