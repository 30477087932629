import { Component, OnInit, HostListener, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { StaffBreadCrumb } from 'src/app/helpers/constants/breadcrumbs/staff-breadcrumb';
import { NoteFor } from 'src/app/helpers/enums/notes/noteFor';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';
import { StaffDetailModel } from 'src/app/models/staff/staff.detail.model';
import { StaffService } from 'src/app/services/staff.serrvice';
@Component({
  selector: 'app-staff-detail',
  templateUrl: './staff-detail.component.html',
  styleUrls: ['./staff-detail.component.css']
})
export class StaffDetailComponent implements OnInit, OnDestroy {
  activeTab = 1;
  tabDetails = [];
  staffSubscription: Subscription;
  staffName: string;
  currentPath: string;
  breadCrumbInputs: { key: string, value: string[] }[] = [];
  loadBreadCrumb = false;
  id: string;
  staffId: string;



  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private staffService: StaffService){
  }


  ngOnInit(): void {
    this.tabDetails = [
      { id: 1, title: 'Overview', path: 'overview' },
      { id: 2, title: 'Permission', path: 'permission' },
      { id: 3, title: 'Licenses', path: 'license' },
      { id: 4, title: 'Price List', path: 'price-list' },
      { id: 5, title: 'Agenda', path: 'agenda' },

      { id: 6, title: 'Notifications', path: 'notifications' },
      { id: 7, title: 'Policies', path: 'policies' },
      { id: 8, title: 'Notes', path: 'notes' },
      { id: 9, title: 'History', path: 'history' }
    ];
    this.staffSubscription = this.staffService.staffDetai$.subscribe((data) => {
      if (data) {
        this.staffId = data.id;
        this.breadCrumbInputs = [{ key: 'id', value: [data?.id, data?.name] }];
        this.loadBreadCrumb = true;
      }
    });
  }

  onNavChange(changeEvent: NgbNavChangeEvent): void {
    this.activeTab = changeEvent.nextId;
  }

  // sticky money
  @HostListener('window:scroll', [])
  onWindowScroll(): void {
    if (document.getElementById('stickyoney')) {
      if (document.body.scrollTop > 132 ||
        document.documentElement.scrollTop > 132) {
        document.getElementById('stickyoney').classList.add('stick_me');
      } else {
        document.getElementById('stickyoney').classList.remove('stick_me');
      }
    }
  }
  componentAdded($evt): void {
    this.currentPath = $evt.route.snapshot.routeConfig.path;
  }

  ngOnDestroy(): void {
    this.staffSubscription.unsubscribe();
  }


  openForNote(): void{
    this.router.navigate([`/staff/${this.staffId}/notes/add`]);
  }

  openForEditProfile(): void{
    this.router.navigate([`/staff/edit-employee/${this.staffId}`]);
    //this.router.navigate(['staff/edit-emplpyee/', this.staffId]);
  }

}
