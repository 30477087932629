<main class="content py-0 changepwd">
    <div class="container-fluid p-0 ">
        <!-- <div class="text-center mb-5">
            <img src="../../../assets/img/arguswest-logo.png" width="200" alt="" />
          </div>   -->
        <div class="row mt-5 h-100 justify-content-center align-items-center" *blockUI="'blockui-frm-create-pwd'">          
            <form class="col-5 mt-5 new-case-form" [formGroup]="form" (ngSubmit)="form.valid && onSubmit()">
                <div class="card">
                    <div class="card-body">
                      <h2 class="card-title">Create Password</h2>
                      <h5 class="card-subtitle mb-2 text-muted">In order to protect your account, make sure your password is strong.</h5>
                      <div class="card-text">
                        <div class="form-groupx">
                          </div>
                         <div class="has-search">
                            <!-- first passowrd-->
                             <!--password input filed-->
                             <div class="row">
                                <div class="col-12 mt-3">
                                  <div class="row">
                                    <div class="col-md-10">
                                      <input type="{{toggleEye ? 'text' : 'password' }}" class="form-control newclp w-100"
                                        style="max-width:100% !important" (keyup)="onKeyup($event)" formControlName="password" placeholder="Password">
                                    </div>
                                    <div class="col-md-2 text-center my-auto fs-3">
                                      <button type="button" class="btn shadow-none" (click)="changeEye()" title="Show/Hide Passowrd">
                                        <i class="fas " [ngClass]="toggleEye? 'fa-eye' : 'fa-eye-slash' "></i>
                                      </button>
                                    </div>
                                  </div>
                                </div>                                                           
                                <div class="col-12 mt-3"  >
                                  <div id="message">
                                    <p id="letter"  [ngClass]= "chklow ? 'valid':'invalid'" >Contains at least one <b>lower character</b></p>
                                    <p id="capital" [ngClass]= "chkcaps ? 'valid' : 'invalid'">Contains at least one <b>upper character</b> </p>
                                    <p id="number"  [ngClass]= "chknum ? 'valid' : 'invalid'">Contains at least one <b>number</b></p>
                                    <p id="special" [ngClass]= "chkSpec ? 'valid' : 'invalid'">Contains at least one of the <b>special characters !@#$%^&*()</b></p>
                                    <p id="length"  [ngClass]= "chklen ? 'valid' : 'invalid'">Contains at least <b>12 characters</b></p>
                                  </div>
                                </div>
                                <div class="col-12 mt-3 mb-3">
                                  <div class="row">
                                    <div class="col-md-10">
                                      <input type="{{toggleEye2 ? 'text' : 'password' }}"
                                        [attr.disabled]="chklen && chknum && chklow && chkcaps && chkSpec ? null : ''" formControlName="confirmPassword"
                                        class="form-control w-100" placeholder="Confirm Password" style="max-width:100% !important">
                                    </div>
                                    <div class="col-md-2 text-center my-auto fs-3">
                                      <button type="button" class="btn shadow-none" (click)="changeEye2()" title="Show/Hide Passowrd">
                                        <i class="fas " [ngClass]="toggleEye2? 'fa-eye' : 'fa-eye-slash' "></i>
                                      </button>
                                    </div>
                                  </div>
                                  <div *ngIf="isPasswordValid && form.controls.confirmPassword.touched && form.controls.confirmPassword.errors" class="text-danger">
                                    <div *ngIf="form.controls.confirmPassword.errors.required">Confirm Password is required
                                    </div>
                                    <div *ngIf="form.controls.confirmPassword.errors.match">Password and Confirm Password
                                      must match</div>
                                  </div>
                                  <div *ngIf="form.controls.password.touched && !isPasswordValid" class="text-danger">
                                    Password must conform to all the listed criteria</div>
                                </div>
                              </div>
                         </div>
                      </div>
                      <button type="submit" [disabled]="form.invalid" class="btn btn-primary btn-lg changebtn w-100">
                        Create Password
                      </button>
                    </div>
                  </div>

               
             
            </form>   
          </div>
    </div>
</main>

