import { Component, Input, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { AppUtils } from 'src/app/helpers';
import { NoteEditModel } from 'src/app/models/note/note.edit.model';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { NoteService } from 'src/app/services/note.service';
import { ToasterService } from 'src/app/services/toater.service';




@Component({
  selector: 'app-edit-note',
  templateUrl: './edit-note.component.html',
  styleUrls: ['./edit-note.component.css']
})
export class EditNoteComponent implements OnInit {

  @Input() moduleId: number;
  @Input() entityId: string;
  @BlockUI() blockUI: NgBlockUI;
  form: FormGroup;
  accessGroups: Array<SelectListItem<number>> = [];
  isSubmitted: boolean;
  Attachment: File;
  note: NoteEditModel;
  isAttachmentDivVisible = false;


  // reset file input
  @ViewChild('fileInput')
  myInputVariable: ElementRef;

  @Input() breadCrumbInputs: { key: string, value: string[] }[];
  loadBreadCrumb = false;
  returnUrl: string;

  constructor(
    private router: Router,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private accessGroupService: AccessGroupService,
    private noteService: NoteService,
    private route: ActivatedRoute,
    private appUtils: AppUtils) {
    this.note = new NoteEditModel();
    this.route.params.subscribe(params => this.note.id = params.noteId);
    this.returnUrl = this.noteService.getReturnUrl();
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      date: ['', Validators.required],
      accessGroup: ['', Validators.required],
      generalNotes: ['', Validators.required],
      attachment: null,
      isPinnedNote: [false]
    });
    this.loadData();
    this.loadBreadCrumb = true;
  }

  private async loadData(): Promise<void> {
    this.blockUI.start();
    forkJoin([
      this.accessGroupService.getCurrentUserAccessGroups(),
      this.noteService.get(this.note.id)
    ]).subscribe(([accessGroups, note]) => {
      if (accessGroups && accessGroups.length > 0) {
        this.accessGroups = accessGroups;
      }
      this.note = note;
      this.form.controls.accessGroup.setValue(this.note.accessGroupId);
      this.form.controls.date.setValue(this.note.date);
      this.form.controls.generalNotes.setValue(this.note.noteText);
      this.form.controls.isPinnedNote.setValue(this.note.isPinnedNote);
      this.isAttachmentDivVisible = this.note.fileName && this.note.fileName.length > 0;

      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  onSubmit(): void {
    this.isSubmitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.note.accessGroupId = Number(this.form.controls.accessGroup.value);
    this.note.date = this.form.controls.date.value;
    this.note.noteText = this.form.controls.generalNotes.value;
    this.note.isPinnedNote = this.form.controls.isPinnedNote.value;
    this.note.attachment = this.form.controls.attachment.value;
    this.blockUI.start();
    this.noteService.put(this.note).subscribe((data) => {
      this.toasterService.success(data);
      this.router.navigate([this.returnUrl]);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }

  onFileSelected(event: any): void {
    const fileList = event.target.files as FileList;
    if (!fileList || fileList.length === 0) {
      this.form.controls.attachment.setValue(null);
      return;
    }
    this.form.controls.attachment.setValue(fileList.item(0));
  }

  downloadAttachment(): void {
    this.blockUI.start();
    this.noteService.downloadAttachment(this.note.id).subscribe((blob: File) => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = this.note.fileName;
      link.style.display = 'none';
      link.href = blobUrl;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobUrl);
        link.remove();
      }, 100);
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }

  removeAttachment(event): void {
    this.isAttachmentDivVisible = false;
    this.note.isAttachmentDeleted = true;
  }

  fileInputReset(): void {
    this.myInputVariable.nativeElement.value = '';
  }
}
