import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { Subscription } from 'rxjs';
import { AppUtils } from 'src/app/helpers';
import { CompanyPolicyVersionHistoryViewModel } from 'src/app/models/company-policy-version-history/companyPolicyVersionHistoryViewModel';
import { EditCompanyPolicyVersionHistoryModel } from 'src/app/models/company-policy-version-history/editCompanyPolicyVersionHistoryModel';
import { CompanyPolicyVersionHistoryService } from 'src/app/services/companypolicy.versionhistory.service';
import { ToasterService } from 'src/app/services/toater.service';

@Component({
  selector: 'app-edit-policy-version',
  templateUrl: './edit-policy-version.component.html',
  styleUrls: ['./edit-policy-version.component.scss']
})
export class EditPolicyVersionComponent implements OnInit, OnDestroy {
  @BlockUI('container-blockui-edit-policy')blockUI: NgBlockUI;
  companyPolicyId: number;
  subscriptions: Subscription[];
  breadCrumbInputs: { key: string; value: string[] }[] = [];
  loadBreadCrumb = false;
  model: EditCompanyPolicyVersionHistoryModel;
  viewModel: CompanyPolicyVersionHistoryViewModel;
  form: FormGroup;
  submitted = false;

  constructor(private route: ActivatedRoute, private router: Router,
              public appUtils: AppUtils, private toasterService: ToasterService,
              private companyPolicyVersionHistoryService: CompanyPolicyVersionHistoryService,
    ) {
    this.subscriptions = [];
    this.model = new EditCompanyPolicyVersionHistoryModel();
    this.viewModel = new CompanyPolicyVersionHistoryViewModel();
    this.route.params.subscribe((params) => {
      this.companyPolicyId = +params.id;
      this.model.id = +params.vid;
     });
    const subscription =
     this.companyPolicyVersionHistoryService.policyListDetail$.subscribe(
       (data) => {
        if (data == null || data === undefined) {
           return;
         }
        this.breadCrumbInputs = [
           { key: 'id', value: [data.id.toString(), data.name] },

         ];
        this.loadBreadCrumb = true;
       }
     );
    this.subscriptions.push(subscription);
  }
  ngOnDestroy(): void {
   if (this.subscriptions && this.subscriptions.length > 0){
        this.subscriptions.every((subscription: Subscription) => subscription.unsubscribe());
   }
  }
  onFileSelected(event: any): void{
    const fileList = event.target.files as FileList;
    if (!fileList || fileList.length === 0) {
      this.form.controls.version_file.setValue(null);
      return;
    }
    if (fileList.item(0).name.split('.').length > 2) {
      this.toasterService.error('Invalid file!');
      return;
    }
    this.form.controls.version_file.setValue(fileList.item(0));
  }
  ngOnInit(): void {
    this.form = new FormGroup({
      version: new FormControl('', Validators.required),
      version_notes: new FormControl(),
      is_current_version: new FormControl(true),
      version_file: new FormControl(null),
    });
    this.loadDefaultData();
  }
  downloadAttachment(): void {
    this.blockUI.start();
    this.companyPolicyVersionHistoryService.downloadAttachment(this.viewModel.id).subscribe((blob: File) => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = this.viewModel.fileName;
      link.style.display = 'none';
      link.href = blobUrl;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobUrl);
        link.remove();
      }, 100);
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }
  loadDefaultData(): void{
    this.blockUI.start();
    const subscription = this.companyPolicyVersionHistoryService.get(this.model.id).subscribe((data) => {
    this.form.controls.version.setValue(data.version);
    this.form.controls.is_current_version.setValue(data.isCurrentVersion);
    this.form.controls.version_notes.setValue(data.versionNotes);
    this.viewModel = data;
    // this.breadCrumbInputs = [
    //         { key: 'id', value: [data.id.toString(), 'TestData'] },
    //         { key: 'vid', value: [data.id.toString(), this.model.version] }
    //       ];
    // this.loadBreadCrumb = true;
    this.blockUI.stop();
    }, (error: any) => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
      this.router.navigate(['/error/404']);
    });

    this.subscriptions.push(subscription);
  }
  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.companyPolicyId = this.companyPolicyId;
    this.model.version = this.form.controls.version.value;
    this.model.versionNotes = this.form.controls.version_notes.value;
    this.model.versionFile = this.form.controls.version_file.value;
    this.model.isCurrentVersion = this.form.controls.is_current_version.value;
    this.blockUI.start();
    this.companyPolicyVersionHistoryService.put(this.model).subscribe(resposne => {
      setTimeout(() => {
        this.onCancel();
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Policy version created successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }
  onCancel(): void {
    this.router.navigate([`company-policy-detail/${this.companyPolicyId}/overview`]);
  }
}
