import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { CompanyPolicyViewModel } from 'src/app/models/company-policy/companyPolicyViewModel';
import { EditCompanyPolicyModel } from 'src/app/models/company-policy/editCompanyPolicyModel';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { CompanyPolicyService } from 'src/app/services/company-policy.service';
import { RoleService } from 'src/app/services/role.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';

@Component({
  selector: 'app-edit-company-policy',
  templateUrl: './edit-company-policy.component.html',
  styleUrls: ['./edit-company-policy.component.css']
})
export class EditCompanyPolicyComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  viewData: CompanyPolicyViewModel;
  accessGroupSelectListItem: SelectListItem<number>[] = [];
  @BlockUI('container-blockui-new-folder-template')blockUI: NgBlockUI;
  model: EditCompanyPolicyModel;
 // reset file input
 @ViewChild('fileInput')
 myInputVariable: ElementRef;
  constructor(private formBuilder: FormBuilder,
              private accessGroupService: AccessGroupService,
              private toasterService: ToasterService,
              private router: Router,
              private roleService: RoleService,
              private route: ActivatedRoute,
              private companyPolicyService: CompanyPolicyService){
              this.model = new EditCompanyPolicyModel();
              this.model.isDeleted = false;

              this.viewData = new CompanyPolicyViewModel();
              this.route.params.subscribe((data) => {
                this.model.id = Number(data.id);
                });
               }

get companyRolesToFormArray(): FormArray{
  return this.form.controls.company_roles as FormArray;
}

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      company_roles: new FormArray([], minSelectedCheckboxes())
    });
    this.loadDefaultData();
  }

  private async loadDefaultData(): Promise<void> {
    this.blockUI.start();
    forkJoin([
      this.companyPolicyService.get(this.model.id),
      this.roleService.getRoles()]).subscribe(
      ([companyPolicyData, roleData]) => {
       // loading case types
       const ct: FormArray = this.companyRolesToFormArray;
       ct.clear();
       this.viewData = companyPolicyData;
      // loading form control
       this.form.controls.name.setValue(companyPolicyData.name);
       this.form.controls.description.setValue(companyPolicyData.description);
       roleData.forEach((d) => {
         ct.push(
           this.formBuilder.group({
             id: [d.id],
             name: [d.value],
             isSelected: [companyPolicyData.companyPolicyRoles.map(x => x.roleId).includes(d.id)],
           })
         );
       });

       this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(
          error,
          'Something went wrong!'
        );
        this.blockUI.stop();
      }
    );
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.name = this.form.controls.name.value;
    this.model.description = this.form.controls.description.value;
    this.model.roleIds = this.form.value.company_roles.filter(x => x.isSelected).map(x => x.id);

    this.blockUI.start();
    this.companyPolicyService.put(this.model).subscribe(resposne => {
      setTimeout(() => {
        this.onCancel();
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Company policy updated successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }

  onCancel(): void {
    this.router.navigate(['/setting/role/company-policies']);
  }
}
