import { CaseFlagInCaseModel } from '../case-flag-in-case/case-flag-in-case-model';
import { CaseServicesInCaseModel } from '../case-services-in-case/case-services-in-case-model';
import { CaseTypeCaseReferenceLableInCaseModel } from '../case-type-reference-label-in-case/case-type-case-reference-lable-in-case-model';
import { CustomFieldInCaseModel } from '../custom-field-in-case/custom-field-in-case-model';

export class AddCaseModel {
    public id: number;
    public clientId: number;
    public locationInClientId: number;
    public caseTypeId: number;
    public caseRegionId: number;
    public caseStatusId?:number;
    public companyLocationId?: number;
    public referralSourceId?: number;
    public contactId: string;
    public assignedMyselfAs: string;
    public caseManager:string;
    public salesPersons:string;
    public investigator:string;
    public caseLocation: string;
    public instructionNote: string;
    public schedulingNote: string;
    public adminNote: string;
    public budget: string;
    public hours: string;
    public createdOn: Date;
    public dueDate?: Date;
    public caseFlagInCase: CaseFlagInCaseModel[];
    public caseServicesInCase: CaseServicesInCaseModel[];
    public caseTypeReferenceLabelInCase: CaseTypeCaseReferenceLableInCaseModel[];
    public customFieldInCase: CustomFieldInCaseModel[];
}
