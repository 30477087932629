import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddOrEditLogoModel } from '../models/logo/addOrEditLogoModel';
import { LogoViewModel } from '../models/logo/logoViewModel';

@Injectable({
  providedIn: 'root'
})
export class LogoService {

  constructor(private http: HttpClient) { }

  public post(model: AddOrEditLogoModel): Observable<string> {
    const formData = new FormData();
    if (model.emailFile){
      formData.append('emailFile', model.emailFile);
    }
    if (model.printFile){
      formData.append('printFile', model.printFile);
    }
    if (model.systemFile){
      formData.append('systemFile', model.systemFile);
    }
    formData.append('printId', JSON.stringify(model.printId));
    formData.append('systemId', JSON.stringify(model.systemId));
    formData.append('emailId', JSON.stringify(model.emailId));
    return this.http.post<string>(`${environment.apiBaseUrl}/logo/post`, formData, {});
  }

  public getAll(): Observable<LogoViewModel[]> {
    return this.http.get<LogoViewModel[]>(`${environment.apiBaseUrl}/logo/get`);
  }

  public delete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/logo/delete?ids=${ids.join('&ids=')}`);
  }

}
