import {Component, OnInit} from '@angular/core';
import { NoteCategory } from 'src/app/helpers/enums/notes/noteCategory';
@Component({
    selector: 'app-staff-notes',
    templateUrl: './staff-notes.component.html',
    styleUrls: ['./staff-notes.component.css']
})

export class StaffNotesComponent{

  category: NoteCategory;

  constructor() {
    this.category = NoteCategory.Staff;
  }

}

