export enum HistorySearchOptions {
    Log = 1,
    LastName = 2,
    IpAddress = 3
}

export enum HistoryFilterOptions {
    None = 1,
    Edited = 2
}
