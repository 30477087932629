import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NoteFor } from 'src/app/helpers/enums/notes/noteFor';
import { ClientContactService } from 'src/app/services/client.contact.service';

@Component({
  selector: 'app-new-client-contact-note',
  templateUrl: './new-client-contact-note.component.html',
  styleUrls: ['./new-client-contact-note.component.css']
})
export class NewClientContactNoteComponent implements OnInit, OnDestroy {

  entityId: string;
  moduleId: number;
  contactSubscription: Subscription;
  breadCrumbInputs: { key: string, value: string[] }[] = [];

  constructor(private clientContactService: ClientContactService,
              private route: ActivatedRoute) {
    this.route.params.subscribe(params => this.entityId = params.contactId);
    this.moduleId = NoteFor.ClientContact;
    this.contactSubscription = this.clientContactService.contactDetail$.subscribe((data) => {
      if (data == null || data === undefined) {
        return;
      }

      this.breadCrumbInputs = [
        { key: 'contactId', value: [data.id, data.name] },
        { key: 'id', value: [data.clientId.toString(), data.clientName] }
      ];
    });
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.contactSubscription.unsubscribe();
  }

}
