
<main  class="content py-0">
    <div class="container-fluid p-0">
        <div class="row mt-5 pt-7">
            <div class="col-md-12"> </div>
        </div>

        <div class="row">
            <div class="col-auto d-none d-sm-block">
                <div class="d-flex mb-2">
                    <h3 class="page-title"><strong>Setting</strong> </h3>
                 
                </div>
            </div>
        </div>
        
        <!-- for masonry grid-->

        <div class="card-columns">


            <div class="cardx" *ngFor="let item of daSetting; index as i">
             
              <div class="card-bodyx">

                <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-{{i}}"   class="da-setting-card">
                    <ngb-panel title="{{item.title}}" id="ngb-panel-{{i}}">

                       <ng-template ngbPanelHeader let-opened="opened">
                            <div class="d-flex align-items-center justify-content-between">
                              <h5 class="m-0 font-weight-bold">{{item.title}} </h5>
                              <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                            </div>
                       </ng-template>

                      <ng-template ngbPanelContent>
                        
                        <div class="list-group list-group-flush"  *ngFor="let sitem of daSetting[i].submenu; index as x">
                            <a routerLink="{{sitem.routerlnk}}" routerLinkActive="router-link-active"  class="list-group-item list-group-item-action " aria-current="true">
                              <div class="d-flex w-100 justify-content-between">
                                <h5 class="mb-1"> {{sitem.name}}</h5>
                              </div>
                              <small class="text-muted"> {{sitem.subtitle}}</small>
                              
                            </a>
                           
                         
                          </div>
                   
                      </ng-template>
                    </ngb-panel>
                  
                  </ngb-accordion>

              </div>
            </div>
           
        </div>

        <!-- for row col grid-->
        <!--
        <div class="row mt-3">
            <div class="col-md-4" *ngFor="let item of daSetting; index as i">
                
            

                        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-{{i}}"   class="da-setting-card">
                            <ngb-panel title="{{item.title}}" id="ngb-panel-{{i}}">
    
                               <ng-template ngbPanelHeader let-opened="opened">
                                    <div class="d-flex align-items-center justify-content-between">
                                      <h5 class="m-0 font-weight-bold">{{item.title}} </h5>
                                      <button ngbPanelToggle class="btn btn-link p-0 shadow-none"> <i class="fas  {{ opened ? 'fa-chevron-up' : 'fa-chevron-down' }}"></i></button>
                                    </div>
                               </ng-template>
    
                              <ng-template ngbPanelContent>
                                
                                <div class="list-group list-group-flush"  *ngFor="let sitem of daSetting[i].submenu; index as x">
                                    <a routerLink="{{sitem.routerlnk}}" routerLinkActive="router-link-active"  class="list-group-item list-group-item-action " aria-current="true">
                                      <div class="d-flex w-100 justify-content-between">
                                        <h5 class="mb-1"> {{sitem.name}}</h5>
                                      </div>
                                      <small class="text-muted"> {{sitem.subtitle}}</small>
                                      
                                    </a>
                                   
                                 
                                  </div>
                           
                              </ng-template>
                            </ngb-panel>
                          
                          </ngb-accordion>
      
            </div>
           
        </div>

        -->
    </div>
</main>