import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CaseServiceModel } from '../models/caseservice/case-service.model';
import { AddCaseServiceModel } from '../models/caseservice/add.case-service.model';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';
import { CaseServiceViewModel } from '../models/caseservice/case-service-view-model';
import { EditCaseServiceModel } from '../models/caseservice/edit.case-service.modal';
import { SelectListItem } from '../models/shared/select.list.item';

@Injectable({
    providedIn: 'root'
})
export class CaseServicesService {
    constructor(private http: HttpClient) {
    }
    public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
        return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/caseservice/query`, dataTablesParameters, {});
    }

    public post(model: AddCaseServiceModel): Observable<CaseServiceViewModel> {
        return this.http.post<CaseServiceViewModel>(`${environment.apiBaseUrl}/caseservice`, model, {});
    }
    public getByCaseTypeId(caseTypeId: number): Observable<SelectListItem<number>[]>{
        return this.http.get<SelectListItem<number>[]>(`${environment.apiBaseUrl}/caseservice/query-caseservices-by-casetypeId/${caseTypeId}`);
    }
    public get(id: number): Observable<CaseServiceViewModel> {
        return this.http.get<CaseServiceViewModel>(`${environment.apiBaseUrl}/caseservice/${id}`);
    }
    public getAll(): Observable<CaseServiceViewModel[]> {
        return this.http.get<CaseServiceViewModel[]>(`${environment.apiBaseUrl}/caseservice/get`);
    }
    public put(model: EditCaseServiceModel): Observable<CaseServiceViewModel> {
        return this.http.put<CaseServiceViewModel>(`${environment.apiBaseUrl}/caseservice`, model, {});
    }
    public delete(ids: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/caseservice?ids=${ids.join('&ids=')}`);
    }
    public undelete(ids: number[]): Observable<any> {
        return this.http.delete(`${environment.apiBaseUrl}/caseservice/undelete?ids=${ids.join('&ids=')}`);
    }
}
