import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ModalService } from 'src/app/services/modal.service';

declare const myTest: any;

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.css']
})
export class SideBarComponent {

  sideNavtoggle: boolean;
  @Output() sideToggleEvent = new EventEmitter<boolean>();

  sideToggle(): void {
    this.sideNavtoggle = !this.sideNavtoggle;
    this.sideToggleEvent.emit(this.sideNavtoggle);
  }
  constructor(private modalService: ModalService) {
  }

  openNewContactPopup(): void {
    this.modalService.openNewContactPopup();
  }

}
