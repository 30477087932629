import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { forkJoin } from 'rxjs';
import { AttachmentTypeOptionsMapping, NoteFilterByOptionsMapping, NoteSearchOptionsMapping } from 'src/app/helpers/constants/filter-and-search/note.search&filter.option.mapping';
import { AttachmentTypeOptions, NoteFilterByOptions } from 'src/app/helpers/enums/filters-and-searchs/note.search&filter.options';
import { FilterInputModel, SearchByModel } from 'src/app/models/shared/filters.model';
import { AccessGroupService } from 'src/app/services/access-group.service';

@Component({
  selector: 'app-notes-filter-and-search',
  templateUrl: './notes-filter-and-search.component.html',
  styleUrls: ['./notes-filter-and-search.component.css']
})
export class NotesFilterAndSearchComponent implements OnInit {

  filterBy: FilterInputModel[] = [];
  filterIn: FilterInputModel[] = [];
  isFilterInVisible = true;

  @Output()
  onApplyFilter = new EventEmitter();

  @Output()
  resetFilters = new EventEmitter();

  @Output()
  closeFilterNav = new EventEmitter();

  searchBy: SearchByModel[] = [];

  // capture filter open and close events
  @Input() filterStatusParent: boolean;
  @Output() closeBtnFilterStatus = new EventEmitter<boolean>();

  constructor(private accessGroupService: AccessGroupService) {
    this.loadSearchOptions();
  }

  ngOnInit(): void {
    this.loadFilterOptions();
  }

  private loadFilterOptions(): void {
    NoteFilterByOptionsMapping.forEach((key, value) =>
      this.filterBy.push({ name: key, id: `${value}`, group: undefined, isSelected: false })
    );

    AttachmentTypeOptionsMapping.forEach((key, value) =>
      this.filterBy.push({ name: key, id: `${NoteFilterByOptions.AttachmentType}_${value}`, group: 'Attachment Type', isSelected: false })
    );

    if (this.filterBy.length >= 1) {
      this.filterBy[0].isSelected = true;
    }

    this.accessGroupService.getCurrentUserAccessGroups().subscribe((accessGroups) => {
      if (accessGroups && accessGroups.length > 0) {
        this.filterIn = accessGroups.map((item => ({ id: item.id, name: item.value })));
        this.filterIn.unshift({ id: '-1', name: 'All Groups', isSelected: true });
      }
    });
  }

  private loadSearchOptions(): void {
    NoteSearchOptionsMapping.forEach((key, value) => this.searchBy.push({ id: value, name: key }));
  }

  applyFilter(filterData: any): void {
    this.onApplyFilter.emit(filterData);
  }

  onFilterNavClose(): void {
    // this.showFiltersAndSearch = false;
    this.closeFilterNav.emit();
  }

  onResetFilters(): void {
    this.loadSearchOptions();
    this.loadFilterOptions();
    this.resetFilters.emit();
  }

  // capture filter open and close events
  closeEvent(somedata: boolean): void {
    this.filterStatusParent = somedata;
    this.closeBtnFilterStatus.emit(somedata);
  }
}
