import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { AddOrEditCaseRegionModelModel } from 'src/app/models/caseregion/add.case-region.model';
import { CaseRegionService } from 'src/app/services/caseregion.service';
import { RoleService } from 'src/app/services/role.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';

@Component({
  selector: 'app-edit-case-regions',
  templateUrl: './edit-case-regions.component.html',
  styleUrls: ['./edit-case-regions.component.css']
})
export class EditCaseRegionsComponent implements OnInit {

  @BlockUI() blockUI: NgBlockUI;

  model: AddOrEditCaseRegionModelModel;

  constructor(
    private router: Router,
    private activeRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private toasterService: ToasterService,
    private caseregionservice: CaseRegionService,
    private roleService: RoleService) {
    this.model = new AddOrEditCaseRegionModelModel();
    this.activeRoute.params.subscribe(params => this.model.id = params.id);
  }

  form: FormGroup;
  submitted = false;
  roles: { id: string, value: string }[] = [];

  get limitAccessToFormArray(): FormArray {
    return this.form.controls.limitAccessTo as FormArray;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      isSetAsDefaultRegion: true,
    });
    this.loadData();
  }

  private loadData(): void {
    this.blockUI.start();
    forkJoin([
       this.roleService.getRoles(),
      this.caseregionservice.get(this.model.id)
    ]).subscribe(([
      roles,
      CaseRegionEditModel
    ]) => {
      this.model = CaseRegionEditModel;

      this.form.controls.name.setValue(this.model.name);
      this.form.controls.description.setValue(this.model.description);
      this.form.controls.isSetAsDefaultRegion.setValue(this.model.isSetAsDefaultRegion);


    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.router.navigate(['/setting/cases/regions-list']);
      this.blockUI.stop();
    }, () => {
      this.blockUI.stop();
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.id = this.model.id;
    this.model.name = this.form.value.name;
    this.model.description = this.form.value.description;
    this.model.isSetAsDefaultRegion = this.form.value.isSetAsDefaultRegion;

    this.blockUI.start();
    this.caseregionservice.put(this.model).subscribe(resposne => {
      setTimeout(() => {
        this.router.navigate(['/setting/cases/regions-list']);
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Case region updated successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      }
    );

  }

}


