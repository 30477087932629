import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { forkJoin } from 'rxjs';
import { DocumentTemplateViewModel } from 'src/app/models/document-templates/documentTemplateViewModel';
import { EditDocumentTemplateModel } from 'src/app/models/document-templates/editDocumentTemplateModel';
import { SelectListItem } from 'src/app/models/shared/select.list.item';
import { AccessGroupService } from 'src/app/services/access-group.service';
import { CasetypeService } from 'src/app/services/casetype.service';
import { DocumentTemplateService } from 'src/app/services/document.template.service';
import { ModalService } from 'src/app/services/modal.service';
import { ToasterService } from 'src/app/services/toater.service';
import { minSelectedCheckboxes } from 'src/app/validators/min-selected-checkboxes.validator';

@Component({
  selector: 'app-edit-document-template',
  templateUrl: './edit-document-template.component.html',
  styleUrls: ['./edit-document-template.component.scss']
})
export class EditDocumentTemplateComponent implements OnInit {

  form: FormGroup;
  submitted = false;
  viewData: DocumentTemplateViewModel;
  accessGroupSelectListItem: SelectListItem<number>[] = [];
  @BlockUI('container-blockui-new-folder-template')blockUI: NgBlockUI;
  model: EditDocumentTemplateModel;
 // reset file input
 @ViewChild('fileInput')
 myInputVariable: ElementRef;
  constructor(private formBuilder: FormBuilder,
              private accessGroupService: AccessGroupService,
              private toasterService: ToasterService,
              private router: Router,
              private route: ActivatedRoute,
              private modalService: ModalService,
              private documentTemplateService: DocumentTemplateService,
              private caseTypeService: CasetypeService){
              this.model = new EditDocumentTemplateModel();
              this.model.isFileRemoved = false;

              this.viewData = new DocumentTemplateViewModel();
              this.route.params.subscribe((data) => {
                this.model.id = Number(data.id);
                });
               }

get caseTypesToFormArray(): FormArray{
  return this.form.controls.case_types as FormArray;
}

onFileSelected(event: any): void{
  const fileList = event.target.files as FileList;
  if (!fileList || fileList.length === 0) {
    this.form.controls.template_file.setValue(null);
    return;
  }
  if (fileList.item(0).name.split('.').length > 2) {
    this.toasterService.error('Invalid file!');
    return;
  }
  this.form.controls.template_file.setValue(fileList.item(0));
  this.model.isFileRemoved = true;
  this.form.controls.template_file.setValidators(Validators.required);
}
  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      description: new FormControl(''),
      customSwitch1: new FormControl(true),
      template_file: new FormControl(null),
      restrict_use: new FormControl(''),
      case_types: new FormArray([], minSelectedCheckboxes())
    });
    this.loadDefaultData();
  }

  private async loadDefaultData(): Promise<void> {
    this.blockUI.start();
    forkJoin([this.caseTypeService.get(),
      this.accessGroupService.getAccessGroups(),
    this.documentTemplateService.get(this.model.id)]).subscribe(
      ([caseTypesdata, accessGroupsdata, documetemplateData]) => {
       // loading case types
       const ct: FormArray = this.caseTypesToFormArray;
       ct.clear();
       caseTypesdata.forEach((d) => {
        ct.push(
          this.formBuilder.group({
            id: [d.id],
            name: [d.name],
            isSelected: [documetemplateData.caseTypeIds.includes(d.id)],
          })
        );
      });
       this.viewData = documetemplateData;
      // loading form control
       this.form.controls.name.setValue(documetemplateData.name);
       this.form.controls.restrict_use.setValue(documetemplateData.accessGroupId);
       this.form.controls.description.setValue(documetemplateData.description);
       this.form.controls.customSwitch1.setValue(documetemplateData.isUseMergeFields);
        // loading access groups
       this.accessGroupSelectListItem = accessGroupsdata;
       this.blockUI.stop();
      },
      (error) => {
        this.toasterService.ProcessErrorResponse(
          error,
          'Something went wrong!'
        );
        this.blockUI.stop();
      }
    );
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.invalid) {
      this.toasterService.error('Please fill all the required fields!');
      return;
    }
    this.model.name = this.form.controls.name.value;
    this.model.accessGroupId = this.form.controls.restrict_use.value ? Number(this.form.controls.restrict_use.value) : null ;
    this.model.description = this.form.controls.description.value;
    this.model.templateFile = this.form.controls.template_file.value;
    this.model.isUseMergeFields = this.form.controls.customSwitch1.value;
    this.model.caseTypeIds = this.form.value.case_types.filter(x => x.isSelected).map(x => x.id);
    this.blockUI.start();
    this.documentTemplateService.put(this.model).subscribe(resposne => {
      setTimeout(() => {
        this.onCancel();
      }, 10);
      setTimeout(() => {
        this.toasterService.success('Document template updated successfully');
      }, 300);
      this.blockUI.stop();
    },
      error => {
        this.toasterService.ProcessErrorResponse(error);
        this.blockUI.stop();
      });
  }
  downloadAttachment(): void {
    this.blockUI.start();
    this.documentTemplateService.downloadAttachment(this.model.id).subscribe((blob: File) => {
      const blobUrl = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.download = this.viewData.fileName;
      link.style.display = 'none';
      link.href = blobUrl;
      // this is necessary as link.click() does not work on the latest firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        // For Firefox it is necessary to delay revoking the ObjectURL
        window.URL.revokeObjectURL(blobUrl);
        link.remove();
      }, 100);
      this.blockUI.stop();
    }, error => {
      this.toasterService.ProcessErrorResponse(error);
      this.blockUI.stop();
    });
  }
  onCancel(): void {
    this.router.navigate(['/setting/global/document-template-list']);
  }
  fileInputReset(): void {
    this.myInputVariable.nativeElement.value = '';
  }
  viewTemplate(): void {
    this.modalService.openDocumentTemplateVariablePopup();
  }
}
