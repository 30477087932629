import { StaffLicenseFilterByOptions, StaffLicenseSearchOptions } from "../../enums/filters-and-searchs/staff.license.search.and.filter.options";

export const StaffLicenseFilterOptionMapping = new Map<number, string>([
    [StaffLicenseFilterByOptions.None, 'None'],
    [StaffLicenseFilterByOptions.ExpiringLicenses, 'Expiring Licenses: Next 30 Days'],
    [StaffLicenseFilterByOptions.ExpiredLicenses, 'Expired Licenses'],
    [StaffLicenseFilterByOptions.DeletedLicenses, 'Deleted Licenses'],
]);
export const StaffLicenseSearchOptionMapping = new Map<number, string>([
    [StaffLicenseSearchOptions.LicenseNumber, 'License Number'],
    [StaffLicenseSearchOptions.LicenseName, 'License Name'],
    [StaffLicenseSearchOptions.StaffName, 'Staff Name'],
    [StaffLicenseSearchOptions.StateOrProvince, 'State/Province']
]);
