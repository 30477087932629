<main class="content py-0">
    <div class="container-fluid p-0">
        <div class="row">
            <div class="col-md-12 pt-7">
                <div class="w-100 pt-4">
                    <div class="row">
                        <div class="col-md-12">
                            <app-breadcrumb *ngIf="loadBreadCrumb" [breadCrumbInputs]="breadCrumbInputs"></app-breadcrumb>
                        </div>
                    </div>

                    <div class="row mt-4 mb-4">
                        <div class="col-auto d-none d-sm-block">
                            <h3 class="page-title"><strong>New Policy Version
                            </strong> </h3>
                        </div>

                    </div>


                    <div class="row mx-0" *blockUI="'container-blockui-new-policy-version'">
                        <div class="col-12 px-0 mb-5">
                            <form class="new-case-form" name="form"  [formGroup]="form" >
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="card" >
                                          
                                            <div class="card-body">
                                              
                                              <div class="card-text">

                                                <div class="row">

                                                    <div class="col-md-12">

                                                        <div class="row">
                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="name">Version<sup class="clsStric">*</sup></label>
                                                                    
                                                                    <input type="text" formControlName="version" class="form-control mb-3" id="version" name="version" autocomplete="off">
                                                                    <div
                                                                    *ngIf="submitted && form.controls.version.errors && form.controls.version.errors.required"
                                                                    class="text-danger mb-2"
                                                                  >
                                                                    Version is required
                                                                  </div>
                                                                  <input type="checkbox" class="ml-1" formControlName="is_current_version" name="is_current_version"><span> Make this the current version</span>

                                                                </div>
                                                            </div>

                                                            <div class="col-md-6">
                                                                <div class="form-group has-search">
                                                                    <label for="description">Version Notes <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="Version notes should include important changes included in this revision."></i></label>
                                                                    
                                                                    <textarea formControlName="version_notes" class="form-control mb-3" id="version_notes" name="version_notes" autocomplete="off"  rows="4" cols="50"></textarea>

                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div class="row">

                                                          
                                                            <div class="col-md-6 mb-2">
                                                                <label>Version File<sup class="clsStric">*</sup> <i class="align-middle fas fa-info-circle ml-2 text-light-black" data-toggle="tooltip" data-placement="top"  placement="top" ngbTooltip="Policy documents must be uploaded in PDF format."></i></label>
                                                                <div class="form-group has-search" >
                                                                    <input class="form-control  pt-2" type="file" accept=".pdf"
                                                                    id="version_file" name="attachment"
                                                                    (change)="onFileSelected($event)">
                                                                    <div
                                                                    *ngIf="submitted && form.controls.version_file.errors">
                                                                    <div
                                                                      *ngIf="submitted && form.controls.version_file.errors.required"
                                                                      class="text-danger mt-2"
                                                                    >
                                                                      Version file is required
                                                                    </div>
                                                                  </div>

                                                                  
                                                                </div>
                                                            </div>
                                                         
                                                        </div>


                                                    </div>
                                                </div>
                                              </div>
                                            </div>
                                        </div>
                                    </div>


                                
                                  
                                    <div class="form-group w-100">
                                        <div class="col-lg-12 text-right">
                                            <button class="btn font-weight-500 px-4 py-2 bg-white text-dark-blue border-btn" type="button"  (click)="onCancel()"> Cancel</button> 
                                            <button class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" type="submit" (click)="onSubmit()">Save Policy Version<span class="fa fa-arrow-right"></span></button> 
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</main>
