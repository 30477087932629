<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Crop Image :</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <image-cropper [imageFile]="imageFile"   [maintainAspectRatio]="true"
    [aspectRatio]="4 / 3"
    [resizeToWidth]="128"
    format="png"
    (imageCropped)="imageCropped($event)"
    >
    </image-cropper>
    <div class="modal-footer border-0">
        <button type="button" class="btn btn-outline-dark" (click)="onCancel()">Cancel</button>
        <button type="button" class="btn bg-blue text-white px-4 py-2 font-weight-500 ml-2" (click)="onCrop()">Crop
        </button>
    </div>
</div>