import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { AddCaseUpdatePresetModel } from '../models/case-update-preset/addCaseUpdatePresetModel';
import { CaseUpdatePresetViewModel } from '../models/case-update-preset/caseUpdatePresetViewModel';
import { EditCaseUpdatePresetModel } from '../models/case-update-preset/editCaseUpdatePresetModel';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';

@Injectable({
  providedIn: 'root'
})
export class CaseupdatepresetService {

  constructor(private http: HttpClient) { }
  
  public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
    return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/caseupdatepreset/query`, dataTablesParameters, {});
  }

  public post(model: AddCaseUpdatePresetModel): Observable<string> {
    return this.http.post<string>(`${environment.apiBaseUrl}/caseupdatepreset/post`, model, {});
  }

  public get(id: number): Observable<CaseUpdatePresetViewModel> {
    return this.http.get<CaseUpdatePresetViewModel>(`${environment.apiBaseUrl}/caseupdatepreset/${id}`);
  }
  public getAll(): Observable<CaseUpdatePresetViewModel[]> {
    return this.http.get<CaseUpdatePresetViewModel[]>(`${environment.apiBaseUrl}/caseupdatepreset/get`);
  }
  public put(model: EditCaseUpdatePresetModel): Observable<CaseUpdatePresetViewModel> {
    return this.http.put<CaseUpdatePresetViewModel>(`${environment.apiBaseUrl}/caseupdatepreset/put`, model, {});
  }
  public delete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/caseupdatepreset/delete?ids=${ids.join('&ids=')}`);
  }
  public undelete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/caseupdatepreset/undeleted?ids=${ids.join('&ids=')}`);
  }

}
