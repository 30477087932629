
import { CaseFilterByOptions,CaseSearchOptions } from "../../enums/filters-and-searchs/case.search&filter.option";

export const CaseSearchOptionsMapping = new Map<object, string>([
  
    [{val:CaseSearchOptions.SubjectName, group: 'Subject Info'}, 'Subject Name'],
    [{val:CaseSearchOptions.CaseNumber, group:'Reference Numbers'},'Case Number'],
    [{val:CaseSearchOptions.ClaimNumber, group:'Reference Numbers'},'Claim Number'],
    [{val:CaseSearchOptions.DOL, group:'Reference Numbers'},'DOL (Date of Loss)'],
    [{val:CaseSearchOptions.Insured, group:'Reference Numbers'},'Insured'],
    [{val:CaseSearchOptions.InsuredPolicy, group:'Reference Numbers'},'Insured & Policy #'],
    [{val:CaseSearchOptions.SIUNumber, group:'Reference Numbers'},'SIU Number'],
    [{val:CaseSearchOptions.AssignedUserAll, group:'Users'},'Assigned User (All)'],
    [{val:CaseSearchOptions.AssignedUserPrimary, group:'Users'},'Assigned User (Primary)'],
    [{val:CaseSearchOptions.PrimaryClientContact, group:'Users'},'Primary Client Contact Name'],
    [{val:CaseSearchOptions.PrimaryClientContactTag, group:'Users'},'Primary Client Contact Tag'],
    [{val:CaseSearchOptions.PrimaryInvestigatorName, group:'Users'},'Primary Investigator Name'],
    [{val:CaseSearchOptions.PrimaryInvestigatorTag, group:'Users'},'Primary Investigator Tag'],
    [{val:CaseSearchOptions.PrimaryCaseManagerName, group:'Users'},'Primary Case Manager Name'],
    [{val:CaseSearchOptions.PrimaryCaseManagerTag, group:'Users'},'Primary Case Manager Tag'],
    [{val:CaseSearchOptions.BudgetAddedBy, group:'Users'},'Budget Added By'],
    [{val:CaseSearchOptions.CaseLocation, group:'Others'},'Case Location'],
    [{val:CaseSearchOptions.ClientName, group:'Others'},'Client Name'],
    [{val:CaseSearchOptions.ClientLocationName, group:'Others'},'Client Location Name'],
    [{val:CaseSearchOptions.ClientState, group:'Others'},'Client State/Province'],
   // [{val:CaseSearchOptions.ClientTag, group:'Others'},'Client Tag'],
    [{val:CaseSearchOptions.InstructionNotes, group:'Others'},'Notes & Instructions'],
    [{val:CaseSearchOptions.ReferralSource, group:'Others'},'Referral Source'],
    [{val:CaseSearchOptions.SchedulingNotes, group:'Others'},'Scheduling Notes'],
   
]);

export const CaseFilterByOptionsMapping = new Map<number, string>([
    [CaseFilterByOptions.None, 'None'],
    [CaseFilterByOptions.Pastdue, 'Past due'],
    [CaseFilterByOptions.Duetoday, 'Due today'],
    [CaseFilterByOptions.Duetomorrow, 'Due tomorrow'],
    [CaseFilterByOptions.Duenextseven, 'Due in the next 7 days'],
    [CaseFilterByOptions.Duefuture, 'Due in the future'],
    [CaseFilterByOptions.Noduedate, 'No Due Date'],
    [CaseFilterByOptions.Reopenedcases, 'Re-Opened Cases'],
    [CaseFilterByOptions.Unsavedcases, 'Unsaved Cases'],
    [CaseFilterByOptions.Unassignedinvestigator, 'Unassigned Investigator'],
    [CaseFilterByOptions.Unassignedcasemanager, 'Unassigned Case Manager'],
    [CaseFilterByOptions.Unassignedsalesperson, 'Unassigned Salesperson'],
    [CaseFilterByOptions.Withvendorassigned, 'With Vendors Assigned'],
    [CaseFilterByOptions.Unbilledexpense, 'Unbilled Expenses'],
    [CaseFilterByOptions.Uninvoiced, 'Uninvioced / No Revenue'],
    [CaseFilterByOptions.Deletedcases, 'Deleted Cases'],
    //[CaseFilterByOptions.Casemanager, 'Case Manager']
    
]);