import { Component, OnInit, ChangeDetectorRef, ViewChild, Renderer2 } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { Permission } from 'src/app/helpers/enums/roles/permission';
import { HasPermission } from 'src/app/helpers/has-permission.helper';
import { CaseSubjectTypeFilterOptions } from 'src/app/helpers/enums/filters-and-searchs/caseSubjectTypeFilterOptions';
import { SubjectTypeService } from 'src/app/services/subjecttype.service';

@Component({
  selector: 'app-case-subject-type-list',
  templateUrl: './case-subject-type-list.component.html',
  styleUrls: ['./case-subject-type-list.component.css']
})
export class CaseSubjectTypeListComponent implements OnInit {

  constructor(private subjectTypeService: SubjectTypeService,
              private tosterService: ToasterService,
              private renderer: Renderer2,
              private router: Router,
              public cdr: ChangeDetectorRef) { }

    get caseSubjectTypeFilterOptions(): any {
      return CaseSubjectTypeFilterOptions;
}

// for filters
formodel: string;
frm: FormGroup;
filterOptions: any;

@BlockUI('container-blockui-manage') blockUI: NgBlockUI;
@ViewChild(DataTableDirective, { static: false }) datatableElement: DataTableDirective;
dtOptions: DataTables.Settings = {};
dtInstance: DataTables.Api;
rowIndex = 0;
isDeleteButtonDisabled = true;
dtRendered = true;
filterBy: any;
visibleColumns = [];
isDtVisible = false;
isDtInitialized = false;
permissionType = Permission;
hasPermissionType = HasPermission;

// for filters
private filterByOptionId = 0;

get filterByOption(): number {
return this.filterByOptionId;
}

private getSelectedIds(): any[] {
const caseserviceIds = [];
const rows = this.dtInstance.rows().nodes();
[...rows.$('.checked')].forEach(item => caseserviceIds.push($(item).val()));
return caseserviceIds;
}

// tslint:disable-next-line: member-ordering
users: any = [];
// tslint:disable-next-line: member-ordering
isBulkActionDisabled = true;

// capture filter open and close events
filterNavStatus = false;
closeFilterEvent(event: any): void {
this.filterNavStatus = event;
}

ngOnInit(): void {
this.initDataTable();
}

// tslint:disable-next-line: use-lifecycle-interface
ngAfterViewInit(): void {
this.datatableElement.dtInstance.then((dtInstance: DataTables.Api) => {
this.dtInstance = dtInstance;
const header = dtInstance.columns().header();
$(header).find('[action-type="select-unSelect-all"]')
.off('change').on('change', (e: any) => {
this.onSelectAllRow(e.target.checked);
});
});
}

private initDataTable(): void {
const self = this;
this.dtOptions = {
dom: '<"top">rt<"bottom"lip><"clear">',
serverSide: true,
destroy: true,
processing: true,
language: {
loadingRecords: '&nbsp;',
processing: '<div class="block-ui-spinner"><div class="loader"></div></div>',
paginate: {
first: '<i class="fa fa-angle-double-left">',
last: '<i class="fa fa-angle-double-right">',
previous: '<i class="fa fa-angle-left">',
next: '<i class="fa fa-angle-right">'
}
},
displayStart: 0,
paging: true,
pagingType: 'full_numbers',
pageLength: environment.paging.defaultPageSize,
lengthMenu: environment.paging.lengthMenu,
order: [[1, 'asc']],
autoWidth: false,
ajax: (dataTablesParameters: any, callback) => {
self.blockUI.start();
dataTablesParameters.filterOptionId = this.filterBy ?? CaseSubjectTypeFilterOptions.None;
self.subjectTypeService.query(dataTablesParameters)
.subscribe(resp => {
  callback({
    recordsTotal: resp.recordsTotal,
    recordsFiltered: resp.recordsFiltered,
    data: resp.data
  });
  self.blockUI.stop();
}, error => {
  self.tosterService.ProcessErrorResponse(error);
  self.blockUI.stop();
}, () => {this.toggleControls(); });
},
columns: [
{
data: null,
title: `<input type="checkbox" action-type="select-unSelect-all" />`,
name: 'select',
orderable: false,
render: (data, type, row) => {
  return `<input type='checkbox' value = '${row.id}' action-type= "select-row"/>`;
}
},
{
data: 'name',
title: 'Name',
render: (data, type, row) => {
  // return `<a  detail-person-id="${row.id}" class='link m-r-10' action-type='link-detail'>${row.name}</a>`;
  return `${row.name}`;
}
},
{
data: 'description',
title: 'Description',
render: (data, type, row) => {
  return `<div class="note-text-wrap">${row.description ? row.description : ''}</div>`;
}
},
{
data: null,
title: 'Used In',
orderable: false,
searchable: false,
render: (data, type, row) => {
  return `<div class="note-text-wrap">${row.caseTypeSubjectTypes}</div>`;
}
},
{
data: 'name',
title: (HasPermission.validate([Permission.DeleteClients]) || HasPermission.validate([Permission.EditClients])) ? 'Action' : '',
name: 'action',
orderable: false,
render: (data, type, row) => {
  const disabled = '';
  const deleteButton = `<button  class='btn del-btn btn-lg btn-table' action-type="delete" ${disabled}>
  <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-alt fa-1x p-2 text-danger'></i>
  </button>`;
  const undeleteButton = `<button  class='btn del-btn btn-lg btn-table' action-type="un-delete">
  <i  class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-trash-restore-alt  fa-1x p-2 text-danger'></i>
  </button>`;
  const editButton = this.filterBy !== CaseSubjectTypeFilterOptions.Deleted
    ? `<button  class='btn del-btn btn-lg btn-table' alt="undelete">
  <i action-type='edit' class='align-middle rounded-circle bg-delete bg-delete-btn fas fa-pencil-alt btn-edit fa-1x p-2'></i>
  </button>` : '';

  return `<div class='fa-edit'>${editButton} ${this.filterBy !== CaseSubjectTypeFilterOptions.Deleted
    ? deleteButton : undeleteButton}
    </div>`;
}
},
],
rowCallback: (row, data: any) => {
// tslint:disable-next-line: variable-name
const _row = $(row);
_row.find('[action-type="edit"]')
.off('click')
.on('click', () => {
  self.router.navigate(['/setting/cases/edit-subject-type', data.id]);
  // self.edit(data.id);
});
_row.find('[action-type="delete"]')
.off('click')
.on('click', () => {
  self.delete([data.id]);
});
_row.find('[action-type="un-delete"]')
.off('click')
.on('click', () => {
  self.unDelete([data.id]);
});
_row.find('[action-type = "select-row"]')
.off('click').on('click', (e: any) => {
  this.onSelectedOrUnselectRow(e);
});
},
// Use this attribute to enable the responsive extension
responsive: true,
};
}

onSelectedOrUnselectRow(event: any): void {
if (event.target.checked) {
this.renderer.addClass(event.target, 'checked');
} else {
this.renderer.removeClass(event.target, 'checked');
}
this.toggleControls();
}

private toggleControls(): void {
this.setSelectAllCheckbox();
this.enableOrDisableDeleteButton();
}
private setSelectAllCheckbox(): void {
const rows = this.dtInstance.rows().nodes();
if (rows !== null) {
const header = this.dtInstance.columns().header();
const selectAllElement = $(header).find('[action-type="select-unSelect-all"]')[0] as any;
const selelectedRows = [...rows.$('[action-type= "select-row"]')];
selectAllElement.checked = selectAllElement != null && selelectedRows.length > 0
&& selelectedRows.every(row => row.classList.contains('checked'));
}
}

onBulkDelete(): void {
this.delete(this.getSelectedIds());
}

onBulkUnDelete(): void {
this.unDelete(this.getSelectedIds());
}

private reloadDataTable(): void {
this.dtInstance.ajax.reload();
}

applyFilter(filterData: any): void {
this.filterBy = filterData.filter;
this.dtInstance.ajax.reload();
}

onResetFilters(): void {
this.filterBy = CaseSubjectTypeFilterOptions.None;
this.dtInstance.ajax.reload();
}

delete(ids: number[]): void {
if (!confirm('Are you sure you want to delete selected Subject type(s)?')) {
return;
}
this.blockUI.start();
this.subjectTypeService.delete(ids).subscribe(() => {
this.reloadDataTable();
this.tosterService.success('Subject type(s) has been deleted successfully.');
this.blockUI.stop();
}, error => {
this.tosterService.ProcessErrorResponse(error);
this.blockUI.stop();
});
}

unDelete(ids: number[]): void {
if (!confirm('Are you sure you want to undelete selected Subject type(s)?')) {
return;
}
this.blockUI.start();
this.subjectTypeService.undelete(ids).subscribe(() => {
this.reloadDataTable();
this.tosterService.success('Subject type(s) has been undeleted successfully.');
this.blockUI.stop();
}, error => {
this.tosterService.ProcessErrorResponse(error);
this.blockUI.stop();
});
}

onSelectAllRow(isChecked: boolean): void {
const rows = this.dtInstance.rows().nodes();
const checkBoxes = [...rows.$('[action-type= "select-row"]')];
if (isChecked) {
checkBoxes.forEach(row => {
this.renderer.addClass(row, 'checked');
(row as HTMLInputElement).checked = true;
});
} else {
checkBoxes.forEach(row => {
this.renderer.removeClass(row, 'checked');
(row as HTMLInputElement).checked = false;
});
}
this.enableOrDisableDeleteButton();
}
private enableOrDisableDeleteButton(): void {

const rows = this.dtInstance.rows().nodes();
this.isBulkActionDisabled = [...rows.$('.checked')].length === 0;
}

edit(id: number): void {
this.router.navigate(['/setting/cases/edit-subject-type', id]);
}

}
