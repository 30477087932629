import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AddCaseReviewModel } from '../models/case-review/addCaseReviewModel';
import { CaseReviewViewModel } from '../models/case-review/caseReviewViewModel';
import { EditCaseReviewModel } from '../models/case-review/editCaseReviewModel';
import { DataTableResponseModel } from '../models/shared/datatable.response.model';

@Injectable({
  providedIn: 'root'
})
export class CaseReviewService {

  constructor(private http: HttpClient) { }
  public query(dataTablesParameters: any): Observable<DataTableResponseModel> {
    return this.http.post<DataTableResponseModel>(`${environment.apiBaseUrl}/casereview/query`, dataTablesParameters, {});
  }

  public post(model: AddCaseReviewModel): Observable<string> {
    return this.http.post<string>(`${environment.apiBaseUrl}/casereview/post`, model, {});
  }

  public get(id: number): Observable<CaseReviewViewModel> {
    return this.http.get<CaseReviewViewModel>(`${environment.apiBaseUrl}/casereview/${id}`);
  }
  public getAll(): Observable<CaseReviewViewModel[]> {
    return this.http.get<CaseReviewViewModel[]>(`${environment.apiBaseUrl}/casereview/get`);
  }
  public put(model: EditCaseReviewModel): Observable<CaseReviewViewModel> {
    return this.http.put<CaseReviewViewModel>(`${environment.apiBaseUrl}/casereview/put`, model, {});
  }
  public delete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/casereview/delete?ids=${ids.join('&ids=')}`);
  }
  public undelete(ids: number[]): Observable<any> {
    return this.http.delete(`${environment.apiBaseUrl}/casereview/undeleted?ids=${ids.join('&ids=')}`);
  }

}
