import { Component, OnInit, ViewChild, Renderer2 } from '@angular/core';

import { ActivatedRoute, Router } from '@angular/router';

// for datatable
import { DataTableDirective } from 'angular-datatables';
import { BlockUI, NgBlockUI } from 'ng-block-ui';
import { StaffService } from 'src/app/services/staff.serrvice';
import { ToasterService } from 'src/app/services/toater.service';
import { environment } from 'src/environments/environment';
import { ClientService } from 'src/app/services/client.service';
import { NoteFor } from 'src/app/helpers/enums/notes/noteFor';

@Component({
  selector: 'app-contact-notes',
  templateUrl: './contact-notes.component.html',
  styleUrls: ['./contact-notes.component.css']
})
export class ContactDetailNotesComponent implements OnInit {

  moduleId: number;
  entityId: string;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.route.parent.params.subscribe(params => this.entityId = params.contactId);
    this.moduleId = NoteFor.ClientContact;
  }

}
